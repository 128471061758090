import React from 'react'
import { CommonUtil } from '../../utils/CommonUtil'

const InspectionStatusRenderer = (props:any) => {

  const onClickResend = ()=>{
    if(props.context){
        props.context.onClickResend(props.data);
    }
}

let formatedString:any  = ""
switch(props.value){
  case "Active":
  case CommonUtil.INSPECTION_STATUS_ACTIVE:
    formatedString = <p style={{color:'#15C39A'}}>{(props.value)}</p>
  break;
  
  case "INACTIVE":
  case CommonUtil.IN_ACTIVE:
    formatedString = <p style={{color:'#E21E1E'}}>{(props.value)}</p>
    break;

    case "Invited":
    case CommonUtil.INVITED:
        formatedString = <><span style={{color:'#E21E1E'}}>{(props.value)}</span><button className="btn btn-success in-active-status" style={{margin:"2px"}} onClick={onClickResend}>{"Resend"}</button></>
      break;  

}

  return (
    <div className={"cellRenderHeader"}>
            {formatedString}  
    </div>
  )
}

export default InspectionStatusRenderer