const DriverCellRenderer = (props: any) => {
    const onClickEditDriver = () => {
        if (props.context) {
            props.context.onClickEditDriver(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickEditDriver} className={"cellRender"}>{props.value}</span></a>
        </div>

    )
}

export default DriverCellRenderer;