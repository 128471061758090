import React from 'react';

const ApiOverview = ({xml}: any) => {
    return (
        <>
            <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 right-content p-4 bg-white">
                <div className="row">
                    <div className="col-12">
                        <h1>{xml?.h1}</h1>
                        <b>{xml?.p}</b>
                    </div>
                    <div className="col-12">
                        <br />
                        <ul>
                        {xml && xml?.list?.map((li: any, index: any) => {
                            return (
                                <li key={index}><label>{li['label']}</label> &nbsp; <span>{li['contentent']}</span></li>
                            )
                        })}
                        </ul>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ApiOverview;