import React from "react";

const OfferDetailsRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            //onClickLinkDetails(props.data);
            props.context.onClickLinkDetails(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <a ><span onClick={onClickUser} className={"cellRender"}>{"Details"}</span></a>
        </div>

    )
}

export default OfferDetailsRenderer;