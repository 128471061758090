import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import useStateWithCallback from "use-state-with-callback";
import AGGrid from "../../Components/AGGrid";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import EquipDamageRenderer from "../Organization/EquipDamageRenderer";
import BusyLoader from "../../Components/BusyLoader";
import {NetworkManager} from "../../Components/NetworkManager";
import DraggableComponent from "../../Components/DraggableComponent";
import {Modal} from "react-bootstrap";
import {Constants} from "../Languages/Constants";
import $ from "jquery";

var attchIdx = 0;
var attchments = [];
var equiImages:any = [];
var docs:any = [];
const ReservationProcessDamages = (props:any)=>{
    const [equipDocs,setEquipData] = useStateWithCallback<any>([],()=>{});

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [showImagePopup,setShowImagePopup] = useState(false);
    const [imgSource,setImageSource] = useState("");

    const [eqpColumnDefs] = useState([
        {field: 'unitNumber', headerName: 'Damage', resizable: true, width: 200, sortable: true, cellRenderer: EquipDamageRenderer, filter: 'agTextColumnFilter'},
        {field: 'reservationCode', headerName: 'Reservation Code', resizable: true, width: 150, flex: 1,sortable: true, filter: 'agTextColumnFilter'},
        {field: 'attachmentDescription', headerName: 'Description', resizable: true, width: 150, flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'attachmentCategory', headerName: 'Context', resizable: true, width: 150, flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'creationDate', headerName: 'Date', resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'attachmentStatus',headerName: 'Status',resizable: true,width: 150,flex: 1,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const gridRef = useRef<IAGrid>(null);

    useEffect(()=>{
        //console.log(props);
        getEquipDamages();
    },[]);

    let getEquipDamages = ()=>{
        attchments = [];
        equiImages = [];
        docs = [];
        if(props.equipData && props.equipData.attachments){
            if(Array.isArray(props.equipData.attachments)){
                attchments = props.equipData.attachments;
            }else{
                attchments.push(props.equipData.attachments);
            }

            if(attchments.length>0){
                //let docs:any = [];
                attchments.forEach((docItem:any,idx:number)=>{
                    if(docItem.documentType == CommonUtil.EQUIP_DAMAGES){
                        docs.push(docItem);
                    }
                });
                if (docs.length > 0) {
                    attchIdx = 0;
                    BusyLoader.showLoader();
                    showAttachments();
                }
                //console.log(docs);
                //setEquipData(docs);
            }

        }
    }

    let showAttachments = () => {
        if (docs.length > attchIdx) {
            let qParam: string = 'objectPath=' + docs[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    //obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj;
                    obj.attachementId = docs[attchIdx].attachementId;
                    obj.Description = docs[attchIdx].attachmentDescription;
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                ()=>{},
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }

    let onCloseImagePopup = ()=>{
        setShowImagePopup(false);
        setImageSource("");
    }

    let onClickUploadDamage = ()=>{

    }

    let onClickThumbnail = (path:string)=>{
        setShowImagePopup(true);
        setTimeout(()=>{
            setImageSource(path);
        });

    }
    let onDamagesPopup = () => {
        setTimeout(function () {
            setPopupCenter('devDamagesPopup', 'devDamagesContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            //let popupTop = windowHeight - 600
            //popupTop = popupTop / 2
            //let popupLeft = windowWidth - 1300
           // popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({margin: '0px',left: 20})
        }
    }

    return(
        <>
            <Modal show={showImagePopup} id="devDamagesPopup" dialogAs={DraggableComponent} onShow={onDamagesPopup}>
                <div className="modal-content" id="devDamagesContentPopup" style={{width: '300px', height: '300px'}}>
                    <div className="modal-header">

                    </div>
                    <div className="modal-body">
                        <img  src={imgSource} style={{width: '300px', height: '300px'}}/>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button"
                                className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={onCloseImagePopup}>
                            {CommonUtil.getMessageText(Constants.CANCEL, 'Cancel')}
                        </button>

                    </div>
                </div>
            </Modal>
            <div className="container-fluid">
                <section>
                    <div className="row">
                        <div className="col-12">
                            <h6 className="mb-4">Known Damages</h6>
                        </div>
                    </div>
                        {equipImagesPath.map((item: any, idx: number) => {
                            var list = (
                                <div className="row pt-2 pb-2">
                                    <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <img className="mobile-thumbnail" style={{marginTop: "0px"}}
                                             src={item.Path} onClick={()=>{onClickThumbnail(item.Path)}} />
                                    </div>
                                    <div className="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                        {item.Description}
                                    </div>
                                </div>

                            )
                            return list
                        })}
                </section>
            </div>
        </>
    );
}

export default ReservationProcessDamages;