import React from 'react'
import CarouselComponent from '../CarouselComponent'
import '../Interchange.css'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'

const ExitTiresInspection = () => {

    const [tireDetails,setTireDetails] = useState<any>([])
    const [attachments,setAttachments] = useState<any>([])
   const [galleryPop,setGalleryPop] = useState(false);
   const [galleryPopStartIndex,setGalleryPopStartIndex] = useState(0); 
   const [attachmentUploads,setAttachmentUploads] = useState<any>([]);
    const [attachmentUploadsPop,setAttachmentUploadsPop] = useState(false);
   const [damageDes,setDamageDes] = useState("")

    let getTiresInspectionDetails = () =>{

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetTiresInspectionDetails,onLoginError);
      
      }

      useEffect(()=>{
        getTiresInspectionDetails();
      },[])

      let onGetTiresInspectionDetails = (dataObj:any)=>{
        if(dataObj){
            var restData = JSON.parse(dataObj);
            console.log(restData)
            setDamageDes(restData?.tiresInspection?.attachments?.attachmentDamageDescription)
            if(restData.tiresInspection.tyres){
                var visual = [];
            if(Array.isArray(restData.tiresInspection.tyres)){
                visual = restData.tiresInspection.tyres;
            }else{
                visual.push(restData.tiresInspection.tyres); 
            }
            setTireDetails(visual)
            setAttachments(restData?.tiresInspection?.attachments);
        }
        }
      }



      let onLoginError = (err:any)=>{
            console.log(err)
      }

      const showAttachedUploads = (list:any)=>{
        // console.log(list);
            const uploads = list.map((item:any)=>item.fullFilePath)
            // console.log(uploads)
            setAttachmentUploads(uploads);
            setAttachmentUploadsPop(true);
        }



      let Capitalize = (str:any)=>{
        str = String(str);
        let strText = str.charAt(0).toUpperCase() + str.slice(1);
        
        return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
        
        }

        let attachImages:any = [];
        try{
            attachImages = attachments.attachmentUploads.map((ele:any)=> ele.fullFilePath)
            console.log(attachImages)
        }catch(e){
            attachImages = [];
        }

  return (
    <div>
        <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="tire-details">
                                        <div className="row">
                                        <ReactImageGallery attachedImages={attachmentUploads} showPop={attachmentUploadsPop} handleClose={()=>{setAttachmentUploadsPop(false)}} />
                                        {tireDetails.map((data:any)=>{
                                                      return(
                                                      <>
                                                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="flex justify-content-left">
                                                            <img src="../../../images/tire.svg" className="img-fluid"/>
                                                            {data.position}
                                                        </div>
                                                        
                                                    </div>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                            <p>Asset Type</p>{data?.assetType}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Brand Name</p>{data?.brand || data?.tyreDetails?.brand}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Size</p>{data?.size || data?.tyreDetails?.size}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Thread Depth</p>{data?.threadDepth || data?.tyreDetails?.threadDepth}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Condition</p>{(data?.type || data?.tyreDetails?.type) ? "Recap Tyre" : "Original Tyre" }
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Tyre Status</p><span className="fair">{data?.condition || data?.tyreDetails?.condition}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <p>Description</p>{data?.attachments?.attachmentDamageDescription}
                                                        </li>
                                                        <li className="list-group-item">                                    
                                                            <a onClick={(e)=>{showAttachedUploads(data?.attachments?.attachmentUploads)}}><span className="badge bg-danger-light">Attached Images</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                      </div>
                                                      </>)  
                                            })}
                                        </div>
                                    </div>
                                </div>
                                
                               
                            </div>
    </div>
  )
}

export default ExitTiresInspection