import React, { useState } from 'react'
import { CommonUtil } from '../../utils/CommonUtil'
import AddCarrierDetailsModal from './AddCarrierDetailsModal'


const EditCarrierDetailsRenderer = (props: any, getAllCarrierDetails:any) => {

    const [openEditDoc, setOpenEditDoc] = useState(false)
    const [mode, setMode] = useState("new")

    const onClickEditCarrier = () => {
        setMode("edit");
        setOpenEditDoc(true);
        CommonUtil.setCarrierId(props.data.carrierId);
    }
    const onCloseEditCarrier = () => {
        setOpenEditDoc(false)
    }

    return (
        <div>
            <div className={"inspectionRenderHeader"} onClick={onClickEditCarrier}>{props.data.carrierName}</div>
            {openEditDoc && <AddCarrierDetailsModal mode={mode} open={true} handleClose={onCloseEditCarrier} getAllCarrierDetails={()=>getAllCarrierDetails()} />}
            </div>
    )
 
}

export default EditCarrierDetailsRenderer
