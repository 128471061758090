import React, {useEffect, useState} from 'react';
import {CommonUtil} from "../../utils/CommonUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {NetworkManager} from "../../Components/NetworkManager";
import OffersList from './OffersList';
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import AGGrid from "../../Components/AGGrid";
import OfferDetailsRenderer from "./offerDetailsRenderer";
import {data} from "jquery";
import OfferCreate from "../Equipments/OfferCreate";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {subscribe} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import DetectDevice from '../../utils/DetectDevice';
import MGrid from '../../Components/MGrid';
import {Constants} from "../Languages/Constants";

const OffersReceived = (props:any) => {
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [isCompanyVisible, setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)
    const [offersList, setOffersList] = useState<any>([])
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [resultObj,setResultObj] = useState<any>({});
    const [page,setPage] = useState("");

    const [offersColumns] = useState([
        {
            field: 'availabilitydetails',
            headerName: CommonUtil.getMessageText(Constants.OFFER_DETAILS,"Offer Details"),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: OfferDetailsRenderer
        },
        {
            field: 'unitNumber',
            headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"),
            resizable: true,
            width: 250,
            sortable: true,
            filter: 'agTextColumnFilter',

        },
        {
            field: 'equipmentType',
            headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'brand',
            headerName: CommonUtil.getMessageText(Constants.BRAND,"Brand"),
            resizable: true,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'reservationType',
            headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TYPE,"Availability Type"),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'startDate',
            headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_FROM_DATE,"Availability From Date"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter',

        },
        {
            field: 'endDate',
            headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TO_DATE,"Availability To Date"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter',

        },
        {
            field: 'creationDate',
            headerName: CommonUtil.getMessageText(Constants.OFFER_RECEIVED_ON,"Offer Received On"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'},
        {
            field: 'renterName',
            headerName: CommonUtil.getMessageText(Constants.OFFER_MADE_BY,"Offer Made By"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'},
        {
            field: 'offerStatus',
            headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'},
        {
            field: 'equipmentrating',
            headerName: CommonUtil.getMessageText(Constants.EQUIPMENT_RATINGS,"Equipment Ratings"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'}
    ])

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }
    const getSelectedOfferDetails = (list:any,offerId:any)=>{
        try{
            const base64String = offerId//String(localStorage.getItem("offerId")); // Base64-encoded string  
            const decodedString = atob(base64String).split(":")[0]; // Decoded string  
            console.log(decodedString); 
            const offerData = list.filter((ele:any)=>{
                return (ele.equipmentReservationOfferId == decodedString);
            })[0];
            onClickLinkDetails(offerData);
            localStorage.setItem("offerId","")
        }catch(e){}
    }
    let onSuccessOffers = (data:any) => {
        if (data) {
            let restData = CommonUtil.parseData(data);
            if (restData) {
                restData.forEach((item:any,idx:number)=>{
                    item.unitNumber = item.unitNumber?item.unitNumber:item.genericEquipmentName;
                });
                setOffersList(restData);
                if(!modalShow)
                    getSelectedOfferDetails(restData,localStorage.getItem("offerId"));
            }
        }
    }
    let onFailOffers = (data:any) => {
        console.log(data.errorMessage);

    }

    useEffect(()=>{
        if(localStorage.getItem("offerView")==="true"){
            if(CommonUtil.getSelectedOrgId()){
                getAllOffers();
            }
        }
        setTimeout(()=>{
            localStorage.setItem("offerView","");
        },3000);
    },[props.isLogin])
    
    let getAllOffers = () => {
        let qParam = selCompanyObj?.orgId ? selCompanyObj.orgId : CommonUtil.getSelectedOrgId();
        let usrUrl = "api/offers/owner";
        let payload = {saasOrgId:qParam};
        NetworkManager.sendJsonRequest(usrUrl, payload,'', "POST", true, onSuccessOffers, onFailOffers);
    }
    let handleModalOpen = () => {
        setModalShow(true)
    }
    let handleModalClose = () => {
        setModalShow(false);
        getAllOffers();
    }
    let handleSubmit = () => {

    }
    useEffect(()=>{
        CommonUtil.SCREEN_NAME = CommonUtil.OWNER_OFFERS;
       // subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
        getAllOffers();
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
            // document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        }
    },[])
    let onSwitchCustomer = ()=>{
        //console.log("swich customer");
        setOffersList([]);
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
            setSelCompanyObj(null);
        }else{
            getAllOffers();
        }

        setCompanyVisible(CommonUtil.isRootUser());
        setPage(""+Math.random()*10000);
        Events.dispatchBreadCrumbEvent();
    }

    let onClickLinkDetails = (dataObj:any)=>{
        CommonUtil.SCREEN_FROM = CommonUtil.OFFER_RECEIVED;
        CommonUtil.SELECT_EQUIP_ID = dataObj.equipmentId;
        CommonUtil.SELECT_EQUIP_AVAIL_ID = dataObj.equipmentListingId;
        CommonUtil.SELECT_EQUIPMENT_TYPE = dataObj.equipmentType;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = dataObj.isGeneric;
        console.log(dataObj)
        setResultObj(dataObj);
        setModalShow(true);

    }
    return (
        <>
            <OfferCreate  modalShow={modalShow} handleClose={handleModalClose} response={resultObj} />
            <div className={'container-fluid'} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                <div className="row" style={{display:(isCompanyVisible?"":"none")}}>
                    <div className="col-md-3 col-12 pt-2" style={{paddingLeft: '5px', zIndex: 999}}>
                        <span className="black-text css-16ihq8s">Company</span>
                        <SingleSelectComp
                            dataSource={orgData}
                            selectedValue={selCompanyObj}
                            width={'100%'}
                            handleChange={onCompanyChange}
                            value={'orgId'} label={'orgName'}
                        />

                    </div>

                    <div className="col-md-12 col-lg-1 row">
                        <div className="col-md-1" style={isMobile ? {} : {paddingTop: '22px'}}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                                    onClick={getAllOffers}>
                                Search
                            </button>
                        </div>
                    </div>

                </div>
                <div className={`row fs-7 ${isMobile ? '' : 'pt-3'}`}>
                    {
                        isMobile ? (
                        <MGrid id="notificationList" cols={offersColumns} rows={offersList} pageSize={10}
                                addNew={false} tooltip={"Offers"} //addNewRecord={onClickNewNotification}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} method={{onClickLinkDetails}}
                                />
                        ):(
                        <div className="ag-theme-alpine gridHeight"
                             style={{height:(isCompanyVisible?"calc(100vh - 180px)":"calc(100vh - 110px)"), width: "100%"}}>
                        <AGGrid id="notificationList" cols={offersColumns} rows={offersList} pageSize={500}
                                addNew={false} tooltip={"Offers"} //addNewRecord={onClickNewNotification}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} method={{onClickLinkDetails}}
                                />
                    </div>
                        )
                    }
                </div>

            </div>
            {/* <OffersList handleModalOpen={handleModalOpen} handleModalClose={handleModalClose} offersList={offersList} />*/}


        </>
    )
}
export default OffersReceived
