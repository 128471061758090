import React, { useState } from 'react'
import { useReleaseInspectorContext } from '../../../Components/ReleaseContext';

const NewReleaseCellRender = (params: any) => {
    const [newRelease, setNewRelease] = useState<any>(false);
    const [addNewItem, setAddNewItem] = useState<any>(1)
    const [inspectionData, setInspectionData] = useState<any>({});
    const [fromDetails,setFromDetails]=useState<any>(false);

    const { state, dispatch } = useReleaseInspectorContext();

    const onOpenNewRelease = () => {
      console.log(params);
      
      dispatch({type: "OPEN_SHOWDETAILS_POPUP",  payload:params?.displayName});
    }

    let data:any = {};
    try{
      data = params.columnApi.columnModel.columnDefs.filter((ele:any)=>(
        ele.field == params?.displayName
      ))[0]
      console.log(data);
    }catch(e){}


  return (
    <>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <div className='f-12 mx-2'>{params?.displayName}</div>
        <div className='f-12 mx-2'>Date: <b>{data?.date}</b></div>
        <div className='f-12 mx-2'><button className='btn btn-primary btn-sm' style={{ padding: "0.2rem .5rem" }} onClick={onOpenNewRelease}>Details</button></div>
      </div>
    </>
  );

};

export default NewReleaseCellRender;