import React, { forwardRef, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { Animal } from './IInterfaces'
import Page1 from './Page1'
import { CommonUtil } from "../utils/CommonUtil";
import { Constants } from "../Pages/Languages/Constants";

/*let animals: any[] = [
    {
        name: 'Tom',
        type: 'dinosaur',
        age: 25,
    },
    {
        name: 'Alfred',
        type: 'turtle',
        age: 12,
    },
    {
        name: "Jeff",
        type: "snail",
        age: 19
    }
];*/

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fas fa-angle-down black-text fa-3xs"></i>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = (props: any) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="fa-solid fa-close black-text fa-3xs"></i>
        </components.ClearIndicator>
    )
}

const SingleSelectComp = (props: any, ref: any) => {
    let handleChange = (txt: any) => {
        // console.log(txt);
        props.handleChange(txt)
    }

    const [selectedOption, setselectedOption] = useState(props.selectedValue)

    useEffect(() =>
    //setAnimals(props.dataSource);
    {
    }, [])

    //const selectedOption = {name: "Jeff", type: "snail"};//animals.find(option => option.type === "snail");

    // console.log(selectedOption);

    const styles = {
        menuList: (base: any) => ({
            ...base,
            // '::-webkit-scrollbar': {
            //     // width: '0px',
            //     // height: '0px',
            // },
            //    "::-webkit-scrollbar": {
            //       width: "4px",
            //       height: "0px",
            //     },
            //     "::-webkit-scrollbar-track": {
            //       background: "#f1f1f1"
            //     },
            //     "::-webkit-scrollbar-thumb": {
            //       background: "#888"
            //     },
            //     "::-webkit-scrollbar-thumb:hover": {
            //       background: "#555"
            //     }
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: state.isSelected ? 'white' : 'black',
            fontSize: '13px',
            paddingLeft: '12px',
            padding: '0px 2px 1px',
            minHeight: '1.2em',
            paddingBottom: '5px',
        }),
        control: (base: any, state: any) => ({
            ...base,
            minHeight: '30px',
            height: '30px',
            paddingLeft: '2px',
            alignContent: 'center',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        clearIndicator: (base: any) => ({
            ...base,
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            alignSelf: 'center',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: '13px',
            alignSelf: 'center',
            color: 'black',
        }),
        placeholder: (base: any) => ({
            ...base,
            fontSize: '13px',
            alignSelf: 'center',
        }),
        noOptionsMessage: (base: any) => ({
            ...base,
            fontSize: '13px',
            paddingLeft: '10px',
            padding: '0px 2px 1px',
            minHeight: '1.2em',
        }),
    }

    return (
        <div className="section" style={{ width: props.width ? props.width : '100%' }}>
            <Select
                options={props.dataSource}
                getOptionLabel={(dataObj: any) => dataObj[props.label]}
                isClearable={true}
                isMulti={props.multiple ? true : false}
                isDisabled={props.disabled ? true : false}
                value={props.selectedValue}
                getOptionValue={(dataObj: any) => dataObj[props.value]}
                backspaceRemovesValue={true}
                components={{ DropdownIndicator, ClearIndicator }}
                isSearchable={true} maxMenuHeight={130}
                //required={props.required ? true : false}
                onChange={(e: any) => {
                    handleChange(e)
                }}
                placeholder={CommonUtil.getMessageText(Constants.SELECT, "Select")}
                className={props.className ? `${props.className}` : 'rounded'}
                styles={styles}
            />
        </div>
    )
}

export default forwardRef(SingleSelectComp);
