import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const deleteInsurancePolicy = (props: any) => {
    const onClickRemovePolicy = () => {
        if (props.context) {
            props.context.deleteInsurPolicy(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <button className="btn btn-success in-active-status" disabled={CommonUtil.isRootUser()?false:true} onClick={onClickRemovePolicy}>{"Remove"}</button>
        </div>

    )
}

export default deleteInsurancePolicy;
