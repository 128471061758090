import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonUtil } from "../../../utils/CommonUtil";
import { Constants } from "../../Languages/Constants";
import DraggableComponent from "../../../Components/DraggableComponent";
import Form from "react-bootstrap/Form";
import ImageLoader from "../ImageLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import AddClaimModal from "../AddClaimModal";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
const AddNewDetailsModal = ({
  showNewDetails,
  onCloseNewDetails,
  data,
  inspectionEvents,
}: any) => {
  const [show, setShow] = useState<any>(false);
  const [formData, setFormData] = useState<any>({
    eventCode: { label: "Event Code", value: "" },
    eventId: { label: "Event ID", value: "" },
    eventStartTime: { label: "Event Start Time", value: "" },
    eventEndTime: { label: "Event End Time", value: "" },
    driverName: { label: "Driver Name", value: "" },
    brokerName: { label: "Broker Name", value: "" },
    carrierName: { label: "Carrier Name", value: "" },
    docId: { label: "Doc ID", value: "" },
    equipmentId: { label: "Equipment ID", value: "" },
    inspectorId: { label: "Inspection ID", value: "" },
    inspectionOrgId: { label: "Inspection Org ID", value: "" },
    truckNumber: { label: "Truck Number", value: "" },
    manifestNumber: { label: "Manifest Number", value: "" },
    sealNumber: { label: "Seal Number", value: "" },
    driverPhoneNumber: { label: "Driver Phone Number", value: "" },
    loadStatus: { label: "Load Status", value: "" },
    status: { label: "Status", value: "" },
    time: { label: "Time", value: "" },
    date: { label: "Date", value: "" },
    sealPicturePath: { label: "Seal Picture", value: "" },
    creationDate: { label: "Creation Date", value: "" },
    createdBy: { label: "Created By", value: "" },
    lastUpdateDate: { label: "Last Update Date", value: "" },
    lastUpdatedBy: { label: "Last Updated By", value: "" },
    signature: { label: "Signature", value: "" },
    driverSignatureFile: { label: "Driver Signature", value: "" },
  });

  const onCloseModal = () => {
    setShow(false);
  };
  const { state, dispatch } = useReleaseInspectorContext();

  const getPdfDocumentDetails = async () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var eventId = formData.eventId.value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (localStorage.getItem("token")) {
      var token = "Bearer " + localStorage.getItem("token");
      myHeaders.append("Authorization", token);
    }
    await fetch(
      NetworkManager.serverPath +
        `api/InspectionDocuments/getMultiReleasePdf?docId=${qparam}&eventIds=${eventId}&status=ENTRY`,
      { method: "GET", headers: myHeaders }
    )
      .then((response: any) => {
        response.blob().then((blob: any) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${
            formData?.eventCode?.value
          }-${CommonUtil.getInterchangeDocumentId()}.pdf`;
          a.click();
        });
      })
      .catch((error) => console.log("error", error));
  };



  const onPdfDocumentDetails = (dataObj: any) => {
    const blob = new Blob([dataObj], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "document.pdf";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const [openAddClaim, setOpenAddClaim] = useState(false);
  const handlePdfDownload = () => {
    getPdfDocumentDetails();
  };

  useEffect(() => {
    const eventData = { ...data, ...data.inspectionEvents };
    const newFormData: any = { ...formData };
    Object.keys(formData)?.map((ele: any) => {
      newFormData[ele].value = eventData[ele];
    });
    setFormData(newFormData);
    dispatch({ type: "setEventId", payload: newFormData?.eventId?.value });
    dispatch({ type: "setEventCode", payload: newFormData?.eventCode?.value });
  }, [data]);



  const onClickAddClaim = () => {
    setOpenAddClaim(true);
    onCloseNewDetails();
  };

  let onCloseDocPopup = () => {
    setOpenAddClaim(false);
  };

  return (
    <div>
      <Modal
        show={showNewDetails}
        dialogAs={DraggableComponent}
        centered
        size="xl"
      >
        <div className="modal-content">
          <Modal.Header className="modal-header" closeButton={false}>
            <h5 className="modal-title mt-0">
              {CommonUtil.getMessageText(
                Constants.RELEASE_INSPECTION,
                "Release Details"
              )}
            </h5>
            <button
              type="button"
              className="close"
              style={{ marginLeft: "0px" }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseNewDetails}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Form>
            <div className="modal-footer">
              <button
                data-toggle="modal"
                data-target=".bs-example-modal-center-new-claim"
                type="button"
                className="btn btn-warning waves-effect"
                data-dismiss="modal"
                onClick={onClickAddClaim}
              >
                {CommonUtil.getMessageText(Constants.ADD_CLAIM, "Add Claim")}
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={(e: any) => {
                  onCloseNewDetails();
                  handlePdfDownload();
                }}
              >
                Pdf Download
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["eventCode"].label} </b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["eventId"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["eventStartTime"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["eventEndTime"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["eventCode"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["eventId"].value}</b>
                      </div>
                      <div
                        className="col-lg-3 col-md-3"
                        style={{ wordBreak: "break-all" }}
                      >
                        <b>{formData["eventStartTime"].value}</b>
                      </div>
                      <div
                        className="col-lg-3 col-md-3"
                        style={{ wordBreak: "break-all" }}
                      >
                        <b>{formData["eventEndTime"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["driverName"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["brokerName"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["carrierName"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["docId"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["driverName"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["brokerName"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["carrierName"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["docId"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["equipmentId"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["inspectorId"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["inspectionOrgId"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["truckNumber"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["equipmentId"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["inspectorId"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["inspectionOrgId"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["truckNumber"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["manifestNumber"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["sealNumber"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["loadStatus"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["status"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["manifestNumber"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["sealNumber"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["loadStatus"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["status"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3 ">
                        <b>{formData["time"].label} </b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["date"].label}</b>
                      </div>
                      {/* <div className="col-lg-3 col-md-3">
                        <b>{formData["sealPicturePath"].label}</b>
                      </div> */}
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["creationDate"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["time"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["date"].value}</b>
                      </div>
                      {/* <div className="col-lg-3 col-md-3">
                        <b>{formData["sealPicturePath"].value}</b>
                      </div> */}
                      <div
                        className="col-lg-3 col-md-3"
                        style={{ wordBreak: "break-all" }}
                      >
                        <b>{formData["creationDate"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["createdBy"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["lastUpdateDate"].label}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["lastUpdatedBy"].label}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div
                        className="col-lg-3 col-md-3"
                        style={{ wordBreak: "break-all" }}
                      >
                        <b>{formData["createdBy"].value}</b>
                      </div>
                      <div
                        className="col-lg-3 col-md-3"
                        style={{ wordBreak: "break-all" }}
                      >
                        <b>{formData["lastUpdateDate"].value}</b>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["lastUpdatedBy"].value}</b>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <div className="main">
                    <div className="row" style={{ color: "#758C9F" }}>
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["driverPhoneNumber"].label}</b>
                      </div>
                      {formData.driverSignatureFile.value && (
                        <div className="col-lg-3 col-md-3">
                          <b>{formData["driverSignatureFile"].label}</b>
                        </div>
                      )}

                      {formData.signature.value && (
                        <div className="col-lg-3 col-md-3">
                          <b>{formData["signature"].label}</b>
                        </div>
                      )}

                      {formData.sealPicturePath.value && (
                        <div className="col-lg-3 col-md-3">
                          <b>{formData["sealPicturePath"].label}</b>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="row mt-2">
                      <div className="col-lg-3 col-md-3">
                        <b>{formData["driverPhoneNumber"].value}</b>
                      </div>
                      {formData.driverSignatureFile.value && (
                        <div
                          className="col-lg-3 col-md-3"
                          style={{ wordBreak: "break-all" }}
                        >
                          <ImageLoader
                            path={formData["driverSignatureFile"].value || ""}
                          />
                        </div>
                      )}

                      {formData.signature.value && (
                        <div className="col-lg-3 col-md-3">
                          <ImageLoader
                            path={formData["signature"].value || ""}
                          />
                        </div>
                      )}

                      {formData.sealPicturePath.value && (
                        <div className="col-lg-3 col-md-3">
                          <ImageLoader
                            path={formData["sealPicturePath"].value || ""}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {/* <AddNewReleaseModal
        show={show}
        onCloseModal={onCloseModal}
        data={data}
        data1={data1}
        data2={data2}
        onSubmitDocument={() => {
          onSubmitDocument();
          setShow(false);
        }}
      /> */}
      <AddClaimModal show={openAddClaim} handleClose={onCloseDocPopup} carrier={formData?.carrierName?.value}/>
    </div>
  );
};

export default AddNewDetailsModal;
