import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";

export default class ToastManager extends Component {

    static showToast(msg: string, title: string) {
        let dealyTime = 3000;
        let hideProgres = true;
        if (title == "Error") {
            //className = "toastErrorClass";
            toast.error(msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: dealyTime,
                hideProgressBar: hideProgres,
            });
        } else if (title == "Success") {
            //className = "toastSuccessClass";
            toast.success(msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: dealyTime,
                hideProgressBar: hideProgres,
            });
        } else if (title == "Info") {
            //className = "toastInfoClass";
            toast.info(msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: dealyTime,
                hideProgressBar: hideProgres,
            });
        } else if (title == "Warn") {
            //className = "toastInfoClass";
            toast.warn(msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: dealyTime,
                hideProgressBar: hideProgres,
            });
        }
    }

    render() {
        return (
            <>
                <ToastContainer limit={1} position="top-right" autoClose={3000} hideProgressBar={true}
                                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
            </>
        )
    }
}