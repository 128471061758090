import React, {useEffect, useRef, useState} from "react";
import useStateWithCallback from "use-state-with-callback";
import Flatpickr from "react-flatpickr";
import {CommonUtil} from "../../utils/CommonUtil";
import {IAGrid} from "../../Components/IInterfaces";
import {useNavigate} from "react-router-dom";
import ReservationCellRenderer from "../Organization/ReservationCellRenderer";
import ReservationEquipCellRenderer from "../Organization/ReservationEquipCellRenderer";
import {subscribe} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import {NetworkManager} from "../../Components/NetworkManager";
import $ from "jquery";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import AGGrid from "../../Components/AGGrid";
import {Constants} from "../Languages/Constants";
import ToastManager from "../../Components/ToastManager";



const ProcessReservations = ()=>{
    const [orgData, setOrgData] = useState(null)
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {
        /*if(gridRef.current){
            gridRef.current.columnFittoSize();
        }*/
    })

    const [showEquipPopup, setShowEquipPopup] = useState(false);

    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(CommonUtil.DATE_FORMAT);

    const gridRef = useRef<IAGrid>(null)

    const navigate = useNavigate();
    const [isCompanyVisible,setCompanyVisible] = useState(CommonUtil.isRootUser());

    const [eqpColumnDefs] = useState([
        {field: 'reservationCode', headerName: 'Reservation Number', resizable: true, width: 200, sortable: true, cellRenderer: ReservationCellRenderer, filter: 'agTextColumnFilter'},
        {field: 'genericEquipmentName', headerName: 'Equipment/Unit Number', resizable: true, width: 220, sortable: true, filter: 'agTextColumnFilter',cellRenderer:ReservationEquipCellRenderer},
        {field: 'isGeneric', headerName: 'Is Generic', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: 'Brand', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'model', headerName: 'Model', resizable: true, width: 150, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'equipmentType', headerName: 'Equipment Type', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'assetType', headerName: 'Asset Type', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'scheduledStartDate', headerName: 'Start Date',resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'scheduledEndDate', headerName: 'End Date', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'renterName', headerName: 'Renter\'s Name', resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'reservationStatus',headerName: 'Status',resizable: true,width: 150,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const [companies, setCompanies] = useState([])
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null)

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [brands, setBrands] = useState<any>([]) //CommonUtil.TRAILER_BRAND
    const [selBrandObj, setSelBransObj] = useState<any>(null)

    const [modals, setModals] = useState<any>([]) //CommonUtil.EQUiP_MODELS
    const [selModalObj, setSetModalObj] = useState<any>(null)
    const [page,setPage] = useState("");

    const [upComingCount,setUpComingCount] = useState(0);
    const [inProgressCount,setInProgressCount] = useState(0);
    const [completeCount,setCompleteCount] = useState(0);

    //const [rowData,setRowData] = useState([]);

    useEffect(() => {
        //subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
           // Events.dispatchLoadProcessReservation();
            const params = new URLSearchParams(window.location.search) // id=123
            //console.log(token);
            if(params){
               // console.log( encodeURI(params.toString()));
                //var encodeUrlParams = new URLSearchParams(encodeURI(window.location.search))
                var encodeUrlParams = new URLSearchParams(encodeURI(window.location.search))
                //console.log(encodeUrlParams);
                var token = encodeUrlParams.get('token');
                //console.log(token);
                if(token){
                    CommonUtil.RESERVATION_TOKEN = "/?requestor=reservation&token="+(token);
                    CommonUtil.RP_KEY = (token);//"O93IGEb0ALW252BXDsrG4b5UHAee1OjTNcYICQdZiP7BFA253D";
                    getReservationList();
                }
            }

            /*NetworkManager.serverPath = "http://54.224.154.228:8080/";
            CommonUtil.RP_KEY = "O93IGEb0ALW%252BXDsrG4b5UHAee1OjTNcYICQdZiP7BFA%253D";

            getnerateReservationKey();*/
            setTimeout(()=>{
                //navigate('processview');
            },10000)

        }, []);

    let getnerateReservationKey = ()=>{
        //NetworkManager.serverPath = "http://54.224.154.228:8080/";
       // let equipAllAPI: string = 'api/reservation/getReservationProcessKey?driverId=51&reservationCode=NRCCURO';// + qParams;
      //NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGenerateReservationKey, onLoginError);
    }

    let onGenerateReservationKey = (dataObj:any)=>{
        console.log(dataObj);
    }

    let getReservationList = () => {
        let qParams: string = "";
        if(CommonUtil.isRootUser()){
            qParams= 'saasOrgId=' + (selCompanyObj ? selCompanyObj.orgId : '');
        }else{
            qParams= 'renterOrgId=' + CommonUtil.getSelectedOrgId();
        }

        setEquipData([]);
        let equipAllAPI: string = 'api/session/rp/reservation/all?rpKey='+CommonUtil.RP_KEY;// + qParams;
        //let equipAllAPI: string = 'api/session/rp/reservation/all?rpKey=O93IGEb0ALW%252BXDsrG4b5UHAee1OjTNcYICQdZiP7BFA%253D';//+CommonUtil.RP_KEY;// + qParams;
        let obj: any = {};
        obj.renterOrgId = CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj: any) => {
        console.log(dataObj);
        let upcomingCount = 0;
        let inprogressCount = 0;
        let completeCount = 0;

        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                equips.forEach((eItem:any,eIdx:number)=>{
                    if(eItem.reservationStatus == CommonUtil.RESERVATION_UPCOMING || eItem.reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                        upcomingCount = upcomingCount+1;
                    }else if(eItem.reservationStatus == CommonUtil.RESERVATION_INPROGRESS || eItem.reservationStatus == CommonUtil.RESERVATION_DE_ACTIVATION_DUE || eItem.reservationStatus == CommonUtil.RESERVATION_LATE_ACTIVATION){
                        inprogressCount = inprogressCount+1;
                    }else if(eItem.reservationStatus == CommonUtil.RESERVATION_COMPLETED){
                        completeCount = completeCount+1;
                    }
                })
                setUpComingCount(upcomingCount);
                setInProgressCount(inprogressCount);
                setCompleteCount(completeCount);
                setEquipData(equips);
            }
        }
    }

    let deSelectButtons = ()=>{
        $("#lnkInProgress").removeClass("active");
        $("#lnkUpcoming").removeClass("active");
        $("#lnkCompleted").removeClass("active");
        $("#InProgress").removeClass("active");
        $("#Upcoming").removeClass("active");
        $("#Completed").removeClass("active");
    }



    let onClickReservation = (item:any)=>{
        console.log(item);

        if(item.VIN){
            CommonUtil.IS_VIN_EXIST = true;
        }else{
            CommonUtil.IS_VIN_EXIST = false;
        }
        CommonUtil.SELECT_RESERVATION_ID = item.reservationId;//"5";
        CommonUtil.SELECT_RESERVATION_IS_ANY_AVAILABLE = item.isEnableAnyAvailble == "Y"?true:false;
        CommonUtil.SELECT_EQUIP_ID = item.equipmentId;
        CommonUtil.EQUIP_RESERVE_ASSET_TYPE = item.assetType;
        CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = item.equipmentType;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = item.isGeneric;
        CommonUtil.SELECT_EQUIP_AVAIL_ID = item.equipmentListingId;
        CommonUtil.SELECT_EQUIP_AVAIL_TYPE = item.reservationType;
        CommonUtil.SELECT_RESERVATION_CODE = item.reservationCode;
        CommonUtil.SELECT_RESERVATION_STATUS= item.reservationStatus;
        CommonUtil.SCHEDULED_START_DATE_MILLI_SECONDS = item.scheduledStartDateMilliSeconds;
        if(item.reservationStatus == CommonUtil.RESERVATION_UPCOMING || item.reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
            CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_ACTIVATION;
        }else if(item.reservationStatus == CommonUtil.RESERVATION_INPROGRESS || item.reservationStatus == CommonUtil.RESERVATION_DE_ACTIVATION_DUE || item.reservationStatus == CommonUtil.RESERVATION_LATE_ACTIVATION){
            CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_IN_ACTIVATION;
        }
        navigate('processview');


    }

    let onClickInProgress = ()=>{
        deSelectButtons();
        $("#lnkInProgress").addClass("active");
        $("#InProgress").addClass("active");
    }

    let onClickUpcoming = ()=>{
        deSelectButtons();
        $("#lnkUpcoming").addClass("active");
        $("#Upcoming").addClass("active");
    }

    let onClickCompleted = ()=>{
        deSelectButtons();
        $("#lnkCompleted").addClass("active");
        $("#Completed").addClass("active");
    }


    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <div className="page-content-wrapper">
                <div className="resa-vation bg-dark text-white p-2 mb-3 d-block">
                    <div className="row m-0">
                        <div className="col-6"><h6 className="m-0">My Reservations</h6></div>
                        <div className="col-6"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="reservation-tabs">
                        <ul className="nav nav-tabs nav-justified bg-primary pl-3 pr-3" role="tablist">
                            <li className="nav-item waves-effect waves-light">
                                <a className="nav-link active" id={"lnkInProgress"} data-toggle="tab"  role="tab" onClick={onClickInProgress}>In Progress ({inProgressCount})</a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                                <a className="nav-link" data-toggle="tab"  id={"lnkUpcoming"} role="tab" onClick={onClickUpcoming}>Upcoming ({upComingCount})</a>
                            </li>
                            <li className="nav-item waves-effect waves-light" style={{display:"none"}}>
                                <a className="nav-link" data-toggle="tab"  id={"lnkCompleted"} role="tab" onClick={onClickCompleted}>Completed ({completeCount})</a>
                            </li>
                        </ul>
                        <div className="tab-content p-1" id="myTabContent" style={{width:"100%"}}>
                            <div role="tabpanel" className="tab-pane fade active show mt-2" id="InProgress"
                                 aria-labelledby="public-tab" aria-expanded="true">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{paddingLeft:'0px',paddingRight:"0px"}}>
                                            {equipData.map((item: any, idx: number) => {
                                                if(item.reservationStatus == CommonUtil.RESERVATION_INPROGRESS || item.reservationStatus == CommonUtil.RESERVATION_DE_ACTIVATION_DUE || item.reservationStatus == CommonUtil.RESERVATION_LATE_ACTIVATION){
                                                    var list = (
                                                        <a style={{textDecoration:"none",color:"#000"}} onClick={()=>{onClickReservation(item)}}>
                                                            <section>
                                                                <div className="row">
                                                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-center p-0">
                                                                        <img className="img-fluid" src="../../profile-image.png" alt="" />
                                                                    </div>
                                                                    <div className="col-10 col-sm-10 col-md-10">
                                                                        <h6 className="m-0">VIN: {item.VIN?item.VIN:"-"}</h6>
                                                                        <h6 className="m-0" style={{paddingTop:"5px"}}>Unit Id: {item.unitNumber?item.unitNumber:"-"}</h6>
                                                                        <p className="m-0" style={{paddingTop:"5px"}}>{item.equipmentType}</p>
                                                                    </div>
                                                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-center p-0"></div>
                                                                    <div className="col-10 col-sm-10 col-md-10">
                                                                        <h6 className="m-0">{item.reservationCode}</h6>
                                                                        <p className="m-0">{item.scheduledStartDate} - {item.scheduledEndDate}</p>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </a>
                                                    )
                                                    return list
                                                }

                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane  mt-2" id="Upcoming" role="tabpanel"
                                 aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{paddingLeft:'0px',paddingRight:"0px"}}>
                                            {equipData.map((item: any, idx: number) => {
                                                if(item.reservationStatus == CommonUtil.RESERVATION_UPCOMING || item.reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                                                    var list = (
                                                        <a style={{textDecoration:"none",color:"#000"}} onClick={()=>{onClickReservation(item)}}>
                                                            <section>
                                                                <div className="row">
                                                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-center p-0">
                                                                        <img className="img-fluid" src="../../profile-image.png" alt="" />
                                                                    </div>
                                                                    <div className="col-10 col-sm-10 col-md-10">
                                                                        <h6 className="m-0">VIN: {item.VIN?item.VIN:"-"}</h6>
                                                                        <h6 className="m-0" style={{paddingTop:"5px"}}>Unit Id: {item.unitNumber?item.unitNumber:"-"}</h6>
                                                                        <p className="m-0" style={{paddingTop:"5px"}}>{item.equipmentType}</p>
                                                                    </div>

                                                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-center p-0"></div>
                                                                    <div className="col-10 col-sm-10 col-md-10">
                                                                        <h6 className="m-0">{item.reservationCode}</h6>
                                                                        <p className="m-0">{item.scheduledStartDate} - {item.scheduledEndDate}</p>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </a>
                                                    )
                                                    return list
                                                }

                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane  mt-2" id="Completed" role="tabpanel"
                                 aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{paddingLeft:'0px',paddingRight:"0px"}}>
                                            {equipData.map((item: any, idx: number) => {
                                                if(item.reservationStatus == CommonUtil.RESERVATION_COMPLETED){
                                                    var list = (
                                                        <a style={{textDecoration:"none",color:"#000"}} onClick={()=>{onClickReservation(item)}}>
                                                            <section>
                                                                <div className="row">
                                                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-center p-0">
                                                                        <img className="img-fluid" src="../../profile-image.png" alt="" />
                                                                    </div>
                                                                    <div className="col-4 col-sm-5 col-md-5">
                                                                        <h6 className="m-0">{item.VIN?item.VIN:item.genericEquipmentName}</h6>
                                                                        <p className="m-0">{item.equipmentType}</p>
                                                                        <p className="m-0" style={{display:"none"}}>1243411414113</p>
                                                                    </div>
                                                                    <div className="col-6 col-sm-4 col-md-4 text-right p-0">
                                                                        <h6 className="m-0">{item.reservationCode}</h6>
                                                                        <p className="m-0">{item.scheduledStartDate} - {item.scheduledEndDate}</p>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </a>
                                                    )
                                                    return list
                                                }

                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProcessReservations;
