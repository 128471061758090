import React, {useEffect, useRef, useState} from 'react';
import NotificationList from './NotificationList'
import {NetworkManager} from "../../Components/NetworkManager";
import '../Notification/Notification.css'
import NotificationModal from "./NotificationModal";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {CommonUtil} from "../../utils/CommonUtil";
import ToastManager from "../../Components/ToastManager";
import {IAGrid} from "../../Components/IInterfaces";
import DetectDevice from '../../utils/DetectDevice';
import { Constants } from '../Languages/Constants';

const Notification = () => {
    const { isMobile } = DetectDevice();
    const [modalShow, setModalShow] = useState(false);
    const [notification, setNotification] = useState({});
    const [mode, setMode] = useState('');
    const [usersList, setUsersList] = useState();
    const [notificationList, setNotificationList] = useState([]);
    const [isCompanyVisible, setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)
    const [orgData, setOrgData] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const userGridRef = useRef<IAGrid>(null);
    const handleShow = (mode: any) => {
        setModalShow(true);
        setMode(mode);
    }

    const deselectALL = () => {
        let gridApi = userGridRef.current?.getGridAPI();
        if (gridApi) {
            gridApi.deselectAll();
        }
    }

    const handleClose = () => {
        setModalShow(false);
        setNotification({});
        setSelectedUsers([])
    }

    let onGetAllUsers = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    setUsersList(restData.users);
                }
            }
        }
    }

    let onLoginError = (err: any) => {
        console.log(err);
    }
    let onGetNotifications = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    console.log(restData, 'restData')
                    setNotificationList(restData.notificationList)
                }
            }
        }
    }
    let getAllNotifications = () => {
        let qParam = CommonUtil.getSelectedOrgId();
        let usrUrl = "api/notification/userAssociationsCount?saasOrgId=" + qParam;
        NetworkManager.sendJsonRequest(usrUrl, "", "", "GET", true, onGetAllUsers, onLoginError);
    }

    let getAllMasters = () => {
        NetworkManager.sendJsonRequest('api/notification/all', "", "", "GET", true, onGetNotifications, onLoginError);
    }
    let getUserAssociation = (data: any) => {
        let userId = data.userId;
        NetworkManager.sendJsonRequest('api/notification/userAssociations?userId=' + userId, "", "", "GET", true, onGetNotifications, onLoginError);
    }
    useEffect(() => {
        getAllNotifications();
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
    }, []);
    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }
    // let handleSelect = useCallback((data:any, isSelected:any) => {
    //
    // },[])
    let onClickHandle = (e: any, index: any) => {
        let rawdata: any = [...notificationList];
        let checkStatus = rawdata[index].notificationAssocStatus;
        rawdata[index].notificationAssocStatus = checkStatus === 'Y' ? 'N' : 'Y'
        setNotificationList(rawdata);
    }
    let onSuccess = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    setUsersList(restData.users);
                    setSelectedUsers([]);
                    deselectALL();
                    handleClose();
                    ToastManager.showToast('Notification configured', 'Success')
                }
            }
        }
        handleClose();
    }
    let onError = (data: any) => {
        console.log(data, 'data')
    }
    let handleSubmit = () => {
        let payload: any = {};
        let arrayofids: any = [];
        selectedUsers.map((id: any) => arrayofids.push(id.userId));
        payload.userIds = arrayofids;
        payload.notifications = notificationList;
        let qParam = localStorage.getItem("saasorgId");
        let saveAssociation = 'api/notification/saveUserAssociations?saasOrgId=' + qParam;
        NetworkManager.sendJsonRequest(saveAssociation, payload, '', 'POST', true, onSuccess, onError)
    }
    return (
        <>
            <div className={'container-fluid px-3'}>
                <div className="row" style={{display: (isCompanyVisible ? "" : "none")}}>
                    <div className="col-md-4 col-12 pt-2" style={{paddingLeft: '5px', zIndex: 99}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY, 'Company')}</span>
                        <SingleSelectComp
                            dataSource={orgData}
                            selectedValue={selCompanyObj}
                            width={'100%'}
                            handleChange={onCompanyChange}
                            value={'orgId'} label={'orgName'}
                        />

                    </div>

                    <div className="col-md-12 col-lg-1 row">
                        <div className="col-md-1" style={isMobile ? {} : {paddingTop: '22px'}}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                                    onClick={getAllNotifications}
                            >
                                {CommonUtil.getMessageText(Constants.SEARCH, 'search')}
                            </button>
                        </div>
                    </div>

                </div>

            </div>
            <NotificationList
                setNotification={setNotification}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                handleShow={handleShow}
                notificationList={usersList}
                getAllMasters={getAllMasters}
                getUserAssociation={getUserAssociation}
                userGridRef={userGridRef}
                getAllNotifications={getAllNotifications}
            />
            <NotificationModal
                modalShow={modalShow}
                notificationList={notificationList}
                selectedUsers={selectedUsers}
                handleClose={handleClose}
                onClickHandle={onClickHandle}
                handleSubmit={handleSubmit}
            />

        </>
    );
}

export default Notification;
