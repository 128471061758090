import React, { useEffect, useState, useRef } from "react";
import { Table } from "react-bootstrap";
import NewEntryLightsValues from "./NewEntryLightsValues";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import Form from "react-bootstrap/Form";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import $ from "jquery";
import { CommonUtil } from "../../../utils/CommonUtil";

const NewEntryLightsInsp = ({ data, inspectionItem }: any) => {
  const { state, dispatch } = useReleaseInspectorContext();
  const fileref3 = useRef<HTMLInputElement>(null);
  let qPath = CommonUtil.getInterchangeDocumentId();
  let fileType: string = "";
  const lightInspection: any = state?.masterData?.lightInspection?.task
    ? [...state?.masterData?.lightInspection?.task]
    : [];
  const submitHandler = (event: any) => {
    event.preventDefault();
    const getImageData = state?.lightInspection?.task.filter(
      (items: any) => items.taskStatus === "fail"
    );
    const chekImage = getImageData?.filter(
      (items: any) => items.attachments?.attachmentUploads.length === 0
    );

    if (chekImage?.length > 0) {
      ToastManager.showToast("Please upload the images", "Error");
    } else {
      dispatch({
        type: "lightInspection",
        payload: {
          isCompleted: true,
        },
      });
      ToastManager.showToast("Successfully saved Lights inspection", "Success");
    }
  };

  const onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/${qPath}/${state?.eventId}/${data?.taskId}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;
              const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "addattachments",
                    payload: {
                      taskId: state?.taskID,
                      category: "lightInspection",
                      attachments: {
                        attachmentUploads: {
                          fullFilePath: imagePath,
                        },
                      },
                    },
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };
  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };
  const onAddPictures = (cId: string, fType: string) => {
    fileType = fType;
    if (fileref3.current) {
      fileref3.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile3").click();
    }
  };
  return (
    <>
      <Form onSubmit={submitHandler}>
        <div
          className="section"
          style={{
            paddingRight: "12px",
            height: "420px",
            minHeight: "420px",
            overflowY: "scroll",
          }}
        >
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>INSPECTION ITEM</th>
                <th style={{ width: "120px" }}>
                  <div className="d-flex">
                    <div>PASS</div>
                    <div style={{ marginLeft: "32px" }}>FAIL</div>
                  </div>
                </th>
                <th style={{ width: "250px" }}>NOTE</th>
                <th style={{ width: "100px" }}>COUNT</th>
                <th style={{ width: "250px" }}>TYPE</th>
                <th style={{ width: "250px" }}>ATTACHMENTS</th>
              </tr>
            </thead>
            <tbody>
              {lightInspection?.map((data: any, i: number) => {
                return (
                  <NewEntryLightsValues
                    data={data}
                    key={data.taskId}
                    inspectionItem={inspectionItem}
                    onAddPictures={onAddPictures}
                  />
                );
              })}
            </tbody>
          </Table>
          <input
            type="file"
            ref={fileref3}
            className="custom-file-doc-input"
            multiple={false}
            id="inputGroupFile3"
            accept="image/*;pdf/*"
            style={{ display: "none" }}
            onChange={(e) => {
              onSelectFiles(e);
            }}
          />

          <div className="text-right">
            <button type="submit" className="btn btn-success btn-lg">
              Save
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default NewEntryLightsInsp;
