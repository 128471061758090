import React, {Component, useCallback} from "react";
import $ from "jquery";
import {CommonUtil} from "../../utils/CommonUtil";
import {Constants} from "../Languages/Constants";

let customCheckout = null;
let customCheckout1 = null;
var self = null;
var cardNumber = null;
var cvv = null;
var expiry = null;
var flag = false;
export default class CreditCardDetails extends Component{

    componentDidMount() {
        self = this;
        //flag = false;
        if(!self.flag) {

        }
        console.log(customCheckout);
        customCheckout = window.customcheckout() || null;
        this.showCardDetails();

    }

    componentWillUnmount() {
        //  flag = false;
        customCheckout = null;
        console.log("unmount");
        if(cardNumber){
           // cardNumber.unmount();
        }
        if(cvv){
           // cvv.unmount();
        }
        if(expiry){
           // expiry.unmount();
        }
    }

    showCardDetails = ()=>{
        //alert("credit card");
        //console.log(this.props.isLoaded);
        // $("#card-number").innerHTML = "";
        setTimeout(()=>{
            // self.flag = false;
        },10000);
        if(!self.flag){
            //this.props.isLoaded = true;
            //this.props.onLoaded(true);
            //self.flag  = true;
            cardNumber = customCheckout.create('card-number');
            cardNumber.mount('#card-number');
            //options.placeholder = "CVV";
            cvv = customCheckout.create("cvv");
            cvv.mount("#card-cvv");
            //cvv.unmount

            expiry = customCheckout.create("expiry");
            expiry.mount("#card-expiry");

            customCheckout.on('error', function(event) {
                console.log('errors: ' + JSON.stringify(event));
                if (event.field === 'card-number') {
                    self.showInvalidMessage("card-number");
                    //self.showInvalidMessage("card-number");
                } else if (event.field === 'cvv') {
                    self.showInvalidMessage("card-cvv");
                    // self.showInvalidMessage("card-cvv");
                } else if (event.field === 'expiry') {
                    self.showInvalidMessage("card-expiry");
                    // self.showInvalidMessage("card-expiry");
                }
            });
            /*customCheckout.on('complete', function(event) {
                console.log('complete: ' + JSON.stringify(event));
                if (event.field === 'card-number') {
                    self.showValidMessage("card-number");
                } else if (event.field === 'cvv') {
                    self.showValidMessage("card-cvv");
                } else if (event.field === 'expiry') {
                    self.showValidMessage("card-expiry");
                }
            });*/
            /*customCheckout.on('brand', function(event) {
                //console.log('brand: ' + JSON.stringify(event));
                var cardLogo = 'none';
                if (event.brand && event.brand !== 'unknown') {
                    var filePath = 'https://cdn.na.bambora.com/downloads/images/cards/' + event.brand + '.svg';
                    cardLogo = 'url(' + filePath + ')';
                }
                //document.getElementById('card-number').style.backgroundImage = cardLogo;
            });*/
        }
    }

    showInvalidMessage = (divId)=>{
        if(!$("#"+divId).hasClass("is-invalid")){
            $("#"+divId).addClass("is-invalid");
        }
    }
    showValidMessage = (divId)=>{
        if($("#"+divId).hasClass("is-invalid")){
            $("#"+divId).removeClass("is-invalid");
            $("#"+divId).addClass("is-valid");
        }else{
            $("#"+divId).addClass("is-valid");
        }
    }

    static setCreditCardHolderName(strName){
        $("#cardHolderName").val(strName);
    }
    static setCreditCardHolderNo(strName){
        $("#spnCardNum").text(strName);
    }
    static setCreditCardHolderCVV(strName){
        $("#spnCardCvv").text(strName);
    }
    static setCreditCardHolderExpDate(strName){
        $("#spnCardDate").text(strName);
    }

    static getCardHoderName = ()=> {
        var strName = $("#cardHolderName").val();
        strName = strName.trim();
        if (!strName) {
            /*if(!strName){
               // this.showInvalidMessage("cardHolderName");
                if(!$("#cardHolderName").hasClass("is-invalid")){
                    $("#cardHolderName").addClass("is-invalid");
                   // $("#cardHolderName").addClass("is-invalid");
                }
            }else{
                //this.showValidMessage("cardHolderName")
                if($("#cardHolderName").hasClass("is-invalid")){
                    $("#cardHolderName").removeClass("is-invalid");
                    $("#cardHolderName").addClass("is-valid");
                }else{
                    $("#cardHolderName").addClass("is-valid");
                }
            }
            }*/

        }
        return strName;
    }
        static getCardDetails = (fun)=>{
            var response1 = null;
            let restObj = {};

            customCheckout.createToken((response)=>{
               // fun(res);
                //response1 = res;
                restObj.US = response;
                customCheckout.createToken((res)=>{
                    restObj.CANADA = res;
                    fun(restObj);
                });
            });
        }
        usCallback = function(response){
             console.log("US");
            console.log(response);
            return response;
        }
    canadaCallback = function(response){
        console.log("Canada");
        console.log(response);
        return response;
    }
    static getTokenInfo = (results)=>{
        var response1 = null;
        let restObj = {};
        //console.log(customCheckout)
       // customCheckout1 = null;
       // console.log(customCheckout1)
        //console.log("1");
        let dataArray = [];
          customCheckout.createToken((response)=>{
              dataArray.push(response);
          });

       let timer =  setTimeout( ()=>{
           customCheckout.createToken((response)=>{
               dataArray.push(response);
               setTimeout(()=>{
                   if(dataArray.length == 2){
                       let copyArray = [...dataArray];
                       dataArray = [];
                       //console.log(dataArray);
                       results(copyArray);

                   }

               },1000);
           });
             clearTimeout(timer);
           // customCheckout = null;
        },1000);

       // console.log(resukt);
        console.log("2");
        /*customCheckout.createToken((response)=>{
            // fun(res);
            //response1 = res;
            restObj.US = response;
            //results(restObj);
            customCheckout1 = customCheckout;
            customCheckout1.createToken((res)=>{
                restObj.CANADA = res;
                results(restObj);
                restObj = {};
                //return restObj;
               // fun(restObj);
            });
        });*/
    }
    static getCanadaCard = (fun)=>{
        customCheckout.createToken((res)=>{
            fun(res);
        });
    }

        onClickPay = ()=>{
            customCheckout.createToken((res)=>{
                console.log(res);
            });
        }



        render() {
            return(
                <>
                    <div className="row fs-7 clsFormStandardTopMargin">

                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.CARD_HOLDER_NAME,"Card Holder Name")}</label>
                            <input  id="cardHolderName" type="text" className="form-control" placeholder="Card Holder Name"   />

                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.CARD_NUMBER,"Card Number")}</label>
                            <div id="card-number" className="field form-control"
                                 placeholder="Card Number" style={{backgroundRepeat:"no-repeat"}}></div>
                            <span style={{color:"red"}} id={"spnCardNum"}></span>
                        </div>
                        <div className="col-md-2">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.CARD_CVV,"Card CVV")}</label>
                            <div id="card-cvv" className="field form-control" placeholder="Card CVV"></div>
                            <span style={{color:"red"}} id={"spnCardCvv"}></span>
                        </div>
                        <div className="col-md-2">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.EXPIRY_DATE,"Expiry Date")}</label>
                            <div id="card-expiry" className="field form-control" placeholder="Expiry Date"></div>
                            <span style={{color:"red"}} id={"spnCardDate"}></span>
                        </div>
                    </div>

                    {/*<form id="checkout-form">
                    <div className="col-md-6">
                        <label htmlFor="card-number">Card number</label>
                        <div id="card-number" className="field form-control"></div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="card-cvv">CVV</label>
                        <div id="card-cvv" className="field form-control"></div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="card-expiry">Expiry date</label>
                        <div id="card-expiry" className="field form-control"></div>
                    </div>
                </form>
                <input type={"button"} className={"btn btn-success"} value={"Values"} style={{display:"none"}} onClick={this.showCardDetails}/>*/}
                    <input type={"button"} className={"btn btn-success"} value={"Send"} style={{display:"none"}}  onClick={this.onClickPay}/>
                </>
            )
        }
    }
