import { useState } from "react";
import ReactImageGallery from "../ReactImageGallery";
import CarouselComponent from "../CarouselComponent";
import { Modal } from "react-bootstrap";

const AttachmentsCellRenderer = (params: any) => {
  const [attachmentsView, setAttachmentsView] = useState(false);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(false);

  const onClickEditDriver = () => {
    console.log(params);
    setAttachmentsView(true);
  };

  const handleClose = () => {
    setAttachmentsView(false);
  };
  console.log(params.data, params.value);
  
  const attachments: any = (params.fromParam ? params.data[params.value]:params.data[
    params.colDef.field
  ])?.attachmentUploads?.map(({ fullFilePath }: any) => fullFilePath);
  const attachmentDesc: any =
  (params.fromParam ? params.data[params.value]:params.data[
    params.colDef.field
  ])?.attachmentDamageDescription;
    const inspectionItemName: string = params.data.fullName;
  return (
    <>
      {attachmentsView && (
        <>
          <Modal
            show={attachmentsView}
            onHide={handleClose}
            className={"modal-dialog-centered modal-lg"}
            style={{width:"400px", marginLeft:"600px"}}
          >
            <Modal.Header closeButton>
              <Modal.Title>{"Attachments"}</Modal.Title>
            </Modal.Header>
            <div className="modal-body attachment">
            <div className="col-lg-12">
              <div className="text-center">
                <div className="gallery-carousel">
                  <h2 className="title-head my-3">{inspectionItemName} Damage Photos</h2>
                  <CarouselComponent
                    images={attachments}
                    callBack={(idx: any) => {
                      setGalleryPop(true);
                      setGalleryPopStartIndex(idx);
                    }}
                  />
                </div>
              </div>

              <div className="gallery-form form">
                <h2 className="title-head my-4 mb-2 text-center">
                  {inspectionItemName} Damage Description
                </h2>
                <div className="form-floating">
                  <div
                    className="card p-3"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ minHeight: "100px", height: "auto" }}
                  >
                    {attachmentDesc}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </Modal>

          <ReactImageGallery
            startIndex={galleryPopStartIndex}
            attachedImages={attachments}
            showPop={galleryPop}
            handleClose={() => {
              setGalleryPop(false);
            }}
          />
        </>
      )}

      <div className={"cellRenderHeader"}>
        <a>
          <span onClick={onClickEditDriver} className={"cellRender"}>
            {attachments?.length ? <>{attachments?.length} <i className="fa fa-paperclip" aria-hidden="true"></i></> : ""}
          </span>
        </a>
      </div>
    </>
  );
};

export default AttachmentsCellRenderer;
