import React, { useEffect, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import '../../header.css'
import { publish, subscribe, unsubscribe } from "./CustomEvents";
import { Constants } from "../Languages/Constants";
import { Events } from "../Languages/Events";
import { useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";

const EquipmentHeader = (props:any) => {
    const navigate = useNavigate();
    const [languages, setLanguages] = useState(CommonUtil.languages);
    const [selLanguage, setSelLanguage] = useState(CommonUtil.SEL_LANGUAGE_TEXT);
    const [page, setPage] = useState(0);
    const [showModalPopup,setShowModalPopup] = useState((props.isLoginPopup?true:false));
    const [isLogout,setIsLogout] = useState("false");

    let onClickLanOption = (item: any) => {
        //console.log(item);
        CommonUtil.SELECTED_LANGUAGE = item.Key;
        if (item.Key == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.ENG_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.ENG_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.FR_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.FR_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.FR_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.SP_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.SPANISH_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.SPANISH_LANGUAGE_TEXT);
        }

        localStorage.setItem("Language",item.Key);

        publish(Events.LANGUAGE_CHANGE_EVENT, { data: item.Key });



        //showMessages();
    }
    useEffect(() => {
        subscribe(Events.LANGUAGE_CHANGE_EVENT, (data: any) => {
            // console.log(CommonUtil.SELECTED_LANGUAGE);
            showLanguageMessages();
        });
        return () => {
            unsubscribe(Events.LANGUAGE_CHANGE_EVENT, {});
        }
        console.log(props);
        if(props.isLoginPopup  && props.isLoginPopup == "true"){
            setTimeout(()=>{
                //setShowModalPopup(true);
            },1000);

        }
    }, []);

    let showLanguageMessages = () => {
        setPage(Math.random() * 100000);
    }

    let onClickSearchDB = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = true;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        navigate("/searchdashboard");
    }

    let onClickLogin = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = true;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        let newloc = window.location.origin;
        //window.location.href = newloc;
        setShowModalPopup(true);
        //setShowModalPopup(true);
       // navigate("/login");
    }

    let onClickPLogin = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = true;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        let newloc = window.location.origin;
        //window.location.href = newloc;
        setShowModalPopup(true);
        // navigate("/login");
    }



    let  onClickSignUp = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = false;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        let newloc = window.location.origin;
        navigate("/signup");
    }

    let onLoginSuccess = ()=>{
        setIsLogout("true");
       // navigate("/menu");
        props.onLoginSuccess();
       // navigate("/menu");
    }

    let closeLoginPopup = ()=>{
        setShowModalPopup(false);
    }

    let onClickLogout = ()=>{
        CommonUtil.onPLogout();
        setIsLogout("false");
        props.onLogoutSuccess();
    }

    const gotoMenu = () => {
        navigate('/')
    }
    return (
        <>
            <LoginModal showModal={showModalPopup} onLogin={onLoginSuccess} closeModal={closeLoginPopup}></LoginModal>
            <div id="wrapper" className="enlarged">
                <div className="content-page">
                    <div className="content">
                        <div className="topbar" style={{height:"40px"}}>
                            <div className="row ml-0">
                                <div className="col-md-3 col-lg-2">
                                    <div className="topbar-left	d-none d-md-block" style={{ paddingLeft: "10px" }}>
                                        <div className="row">
                                            <div className="col-sm-10 col-md-9 col-lg-9 col-xl-10">
                                                {/* <a href="index.html" className="logo color"><img src="assets/images/logo.png"  alt="vHub" /></a> */}
                                                <a href="#" className="logo" onClick={gotoMenu}>
                                                    <img src={"../../images/logo.png"} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-lg-10 align-self-center">
                                    <nav className="navbar-custom">
                                        <ul className="list-inline mb-0 navi">
                                            <li className="list-inline-item dropdown notification-list  calendar1"
                                                style={{display:(props.isSearchDB == "true")?"none":"none"}}>
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                                                    <i className="fa fa-search noti-icon fa-2xs"style={{cursor:"pointer"}} onClick={onClickSearchDB}></i>
                                                    <label onClick={onClickSearchDB}>{CommonUtil.getMessageText(Constants.SEARCH, "Search")}</label>
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list  calendar1"
                                                style={{display:(props.isLogin == "true")?"":"none"}}>
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                                                    <label onClick={onClickLogin}>{"Login"}</label>
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list  calendar1"
                                                style={{display:(props.isPLogin == "true")?"":"none"}}>
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>

                                                    <label onClick={onClickPLogin}>{"Login"}</label>
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list  calendar1"
                                                style={{display:(props.isSignUp == "true")?"":"none"}}>
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>

                                                    <label onClick={onClickSignUp}>{"SignUp"}</label>
                                                </a>
                                            </li>

                                            <li style={{display:((isLogout == "true" || (props.isLogout == "true")))?"":"none"}} className="list-inline-item dropdown author-list">
                                                <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                                                   id="dropDownProfile" data-bs-toggle="dropdown" aria-expanded="false"
                                                   href="#" role="button" aria-haspopup="false">
                                                    <div className="user-info d-none d-lg-inline-block">
                                                        <p className="user-name mb-0 mr-2">
                                                            {localStorage.getItem('user')}{' '}
                                                        </p>
                                                        <p className="user-name mb-0 mr-2" style={{ textAlign: 'right' }}>
                                                            {localStorage.getItem('orgName')}
                                                        </p>
                                                    </div>
                                                    <i className="fa fa-chevron-down" style={{ paddingLeft: '5px', paddingTop: '16px' }}></i>
                                                </a>

                                                <div className="dropdown-menu dropdown-menu-right profile-dropdown dropdownstyle"
                                                     x-placement="bottom-end" aria-labelledby="dropDownProfile">

                                                    <a className="dropdown-item myProfilePadding" href="#"
                                                       style={{ textDecoration: 'none', color: '#000' }}
                                                       onClick={onClickLogout} >
                                                        <i className="fa-solid fa-right-from-bracket "></i>
                                                        {CommonUtil.getMessageText(Constants.LOGOUT, 'Logout')}
                                                    </a>
                                                </div>
                                            </li>


                                        </ul>

                                        <div className="clearfix"></div>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EquipmentHeader;
