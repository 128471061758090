import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import DetectDevice from "../../utils/DetectDevice";

import RegistrationHeader from "../Login/RegistrationHeader";
import SearchDashboardCopy from "./SearchDashboardCopy";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
var policyId = "";
var reservationStatus:string = "";
var assignEquipId = "";
const PublicSearchDashboard = ()=>{
    const {isMobile} = DetectDevice();
    const [isLogin,setIsLogin] = useState(false);
    const [page,setPage] = useState(0);
    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);

    useEffect(()=>{
        if(CommonUtil.getSelectedOrgId()){
            setIsLogin(true);
        }
    },[]);

    let onLoginSuccess = ()=>{
        console.log("login success");
        localStorage.setItem("reservationview","true");
        setIsLogin(true);
        setPage(Math.random()*1000000);
    }

    return(
        <>
            <RegistrationHeader isLogin={"true"} isSearchDB={"false"} isSignUp={"true"} />
            <div style={{paddingTop:"50px"}}>
               <SearchDashboardCopy />
            </div>

        </>
    )
}

export default PublicSearchDashboard;
