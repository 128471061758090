import React from "react";

const UserCheckBoxRender = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickUser(props.data);
        }
    }

    const onClickResend = () => {
        if (props.context) {
            props.context.onClickResend(props.data);
        }
    }

    return (
        <div style={{marginTop: "-3px"}}>
            <div data-label="Name" style={{width: '200px'}}>
                {
                    props.isMobile ?  (
                <a href={'#'} onClick={onClickUser} style={{textDecoration: 'none'}}>
                    <strong>{props.data.firstName + " " + props.data.lastName}</strong>
                  </a>
                    ):(
                    <strong>{props.data.firstName + " " + props.data.lastName}</strong>
                    )
                }
                {/*<a href={'#'} onClick={onClickUser}*/}
                {/*   style={{textDecoration: 'none'}}>{props.data.firstName + " " + props.data.lastName}</a>*/}
            </div>
        </div>

    )
}

export default UserCheckBoxRender;