import React, { useEffect, useRef } from 'react';
import SingleSelectComp from '../../Components/SingleSelectComp';
import { CommonUtil } from '../../utils/CommonUtil';
import Flatpickr from "react-flatpickr";
import {Constants} from "../Languages/Constants";

const FromTo = ({dateObj,setDateObj, dateOptions, setFromDate, setToDate }:any) => {
    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
        let onChangeFromDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getReservationDateString(dateValue)
        setFromDate(strDate);
        setDateObj(null)
    }

    let onChangeToDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setToDate(strDate);
        setDateObj(null)
    }
    let onChangeDates = () => {
        if(fromRef.current && toRef.current){
            var startDate = fromRef.current.flatpickr.selectedDates[0];
            var endDate = toRef.current.flatpickr.selectedDates[0];
            if (endDate) {
                endDate = new Date(endDate);
                fromRef.current.flatpickr.set("maxDate",endDate);
            } else if (startDate) {
                toRef.current.flatpickr.set("minDate",new Date(startDate));
            }
        }
    }
    let onDateChange = (dataObj:any)=>{
        setDateObj(()=>{
            return dataObj
        });
        if(fromRef.current){
                setFromDate("");
                fromRef.current.flatpickr.setDate("", false);
                fromRef.current.flatpickr.set("maxDate", '');
            }
        if(toRef.current){
                setToDate("");
                toRef.current.flatpickr.setDate("", false);
                toRef.current.flatpickr.set("minDate", '');
            }
        if(dataObj){
            setChangeDates(dataObj);
        }else{

        }

    }
    let setChangeDates = (dataObj:any)=>{
        let sDate = null,eDate = null;
        let val = dataObj.key;
        switch (val){
            case "1":// today
                sDate = new Date();
                eDate = sDate;
                break;
            case "2":// today
                sDate = new Date();
                sDate.setDate(sDate.getDate() - 1);
                eDate = sDate;
                break;
            case "3":// current week
                var curr = new Date();
                var val1 = (curr.getDay() == 0) ? 7 : curr.getDay();
                sDate = new Date(curr.setDate(curr.getDate() - (val1)));
                eDate = new Date();
                break;
            case "4":// previous week
                var curr = new Date();
                sDate = new Date(curr.setDate(curr.getDate() - (curr.getDay()+7)));
                curr = new Date();
                eDate = new Date(curr.setDate(curr.getDate() - (curr.getDay()+1)));
                break;
            case "5":// last 7 days
                var curr = new Date();
                sDate = new Date(curr.setDate(curr.getDate() - (7)));
                eDate = new Date();
                break;
            case "6":// current month
                var curr = new Date();
                sDate = new Date(curr.setDate(1));
                eDate = new Date();
                break;
            case "7":// previous month
                var curr = new Date();
                sDate = new Date(curr.setMonth(curr.getMonth() - (1)));
                sDate = new Date(sDate.setDate(1));
                curr = new Date();
                curr = new Date(curr.setMonth(curr.getMonth() - (1)));
                eDate = new Date()
                eDate = new Date(curr.setDate(new Date(sDate.getFullYear(), sDate.getMonth()+1, 0).getDate()));
                break;
            case "8":// last 1 month
                var curr = new Date();
                sDate = new Date(curr.setMonth(curr.getMonth() - (1)));
                sDate = new Date(sDate.setDate(sDate.getDate() + (1)));
                eDate = new Date();
                break;
            case "9":// current quarter
                var curr = new Date();
                var currMonth = curr.getMonth();
                var arr = [1,1,1,4,4,4,7,7,7,10,10,10];
                currMonth = arr[currMonth];
                sDate = new Date(curr.setMonth(currMonth-1));
                sDate = new Date(sDate.setDate(1));
                eDate = new Date();
                break;
            case "10":// previous quarter
                var curr = new Date();
                var currMonth = curr.getMonth();
                var arr = [1,1,1,4,4,4,7,7,7,10,10,10];
                currMonth = (currMonth < 3) ? 10 : arr[currMonth-3];
                if(curr.getMonth() < 3)
                    sDate = new Date(curr.setFullYear(curr.getFullYear()-1));
                sDate = new Date(curr.setMonth(currMonth-1));
                sDate = new Date(sDate.setDate(1));
                eDate = new Date(curr.setMonth(currMonth+1));
                eDate = new Date(eDate.setDate(new Date(eDate.getFullYear(), currMonth+2, 0).getDate()));
                break;
            case "11":// current half year
                var curr = new Date();
                var currMonth = curr.getMonth();
                currMonth = (currMonth > 6) ? 7 : 1;
                sDate = new Date(curr.setMonth(currMonth-1));
                sDate = new Date(sDate.setDate(1));
                eDate = new Date();
                break;
            case "12":// previous half year
                var curr = new Date();
                var currMonth = curr.getMonth();
                var arr = [1,1,1,1,4,4,7,7,7,10,10,10];
                currMonth = (currMonth > 6) ? 1 : 7;
                var currYear = curr.getFullYear();
                currYear = (currMonth == 7) ? currYear-1 : currYear;
                sDate = new Date(curr.setFullYear(currYear));
                sDate = new Date(curr.setMonth(currMonth-1));
                sDate = new Date(sDate.setDate(1));
                curr = new Date();
                eDate = new Date(curr.setFullYear(currYear));
                eDate = new Date(curr.setMonth(currMonth+4));
                eDate = new Date(eDate.setDate(new Date(eDate.getFullYear(), currMonth+5, 0).getDate()));
                break;
            case "13":// last 6 months
                var curr = new Date();
                sDate = new Date(curr.setMonth(curr.getMonth() - (6)));
                sDate = new Date(sDate.setDate(sDate.getDate() + (1)));
                eDate = new Date();
                break;
            case "14":// current year
                var curr = new Date();
                sDate = new Date(curr.setMonth(0));
                sDate = new Date(sDate.setDate(1));
                eDate = new Date();
                break;
            case "15":// last year
                var curr = new Date();
                sDate = new Date(curr.setFullYear(curr.getFullYear()-1));
                sDate = new Date(curr.setMonth(0));
                sDate = new Date(sDate.setDate(1));
                eDate = new Date(curr.setFullYear(curr.getFullYear()));
                eDate = new Date(curr.setMonth(11));
                eDate = new Date(eDate.setDate(new Date(eDate.getFullYear(), 12, 0).getDate()));
                break;
            case "16":// last 1 year
                var curr = new Date();
                sDate = new Date(curr.setFullYear(curr.getFullYear()-1));
                sDate = new Date(sDate.setDate(sDate.getDate() + (1)));
                eDate = new Date();
                break;
        }
        var stDate:any = "";
        var endDate:any = "";

        if(sDate && eDate){
            stDate = new Date(sDate.getFullYear(),sDate.getMonth(),sDate.getDate());
            endDate = new Date(eDate.getFullYear(),eDate.getMonth(),eDate.getDate());
        }
        if(fromRef.current){
            fromRef.current.flatpickr.setDate(stDate, false);
            setFromDate(CommonUtil.getReservationDateString(stDate))
        }
        if(toRef.current){
            toRef.current.flatpickr.setDate(endDate, false);
            setToDate(CommonUtil.getReservationDateString(endDate))
        }

        setTimeout(()=>{
            onChangeDates();
        },1000);
        //
    }

  return (
    <>
        <div className="col-md-2 col-6 pt-1" style={{zIndex: '9'}}>
            <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.DURATION,"Duration")}</span>
            <SingleSelectComp dataSource={CommonUtil.CUSTOM_DATES} selectedValue={dateObj}
                              handleChange={onDateChange} width={'100%'} value={'key'} label={'value'} />
        </div>
        <div className="col-md-2 col-6 pt-2" style={{zIndex: '9'}}>
            <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.RESERVATION_START_DATE,"Reservation start date")}</span>
            <div className="input-group">
                <Flatpickr placeholder="From Date" ref={fromRef} options={dateOptions} onChange={onChangeFromDate}
                           className="form-control"  />
            </div>
        </div>
        <div className="col-md-2 col-6 pt-2" style={{zIndex: '9'}}>
            <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation end date")}</span>
            <div className="input-group">
                <Flatpickr placeholder="To Date" ref={toRef} options={dateOptions} onChange={onChangeToDate}
                           className="form-control"  />
            </div>
        </div>
    </>
  )
}

export default FromTo;
