import React, { useEffect } from 'react'
import { useState,useRef } from 'react';
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import { NetworkManager } from '../../Components/NetworkManager';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import { useNavigate } from 'react-router';

const IncEqpDetails = () => {

    const [users, setUsers] = useStateWithCallback([], () => {
        if (userGridRef1.current) {
            userGridRef1.current.columnFittoSize();
        }
    });
    const userGridRef1 = useRef<IAGrid>(null);
    
    const [userColumns] = useState([
        // { field: 'equipmentId', headerName: CommonUtil.getMessageText(Constants.EQUIPMENT_ID, 'Equipment Id'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'vinNumber', headerName:CommonUtil.getMessageText(Constants.VIN_NO,'Vin Number'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
        { field: 'unitNumber', headerName:CommonUtil.getMessageText(Constants.UNIT_NUMBER,'Unit Number'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
        { field: 'eqpValidationDateTime', headerName: CommonUtil.getMessageText(Constants.VALIDATIONDATETIME, 'Validation DateTime'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'inspectorName', headerName: CommonUtil.getMessageText(Constants.INSPECTORNAME, 'inspectorName'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'comment', headerName: CommonUtil.getMessageText(Constants.COMMENTS, 'Comments From the Inspector'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
       
    ]);

    let navigate = useNavigate();

    let onClickUserDetails = (userData: any) => {
        console.log(userData)
        localStorage.setItem("documentId", userData.documentId);
        navigate("/menu/InterchangeApplication/interchangeDocumentDetails");
    }

    let getAllIncEqupDetails = () =>{
        var IncEqpUrl = `api/InspectionDocuments/validatedEquipments/${CommonUtil.getSaasOrgId()}`
        NetworkManager.sendJsonRequest(IncEqpUrl,"","","GET",true,onGetAllIncEqupDetails,onLoginError);
      }
    
    useEffect(()=>{
        getAllIncEqupDetails();
    }, []);

    let onGetAllIncEqupDetails = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            if(restData){
                restData.map((org:any)=>{
                    console.log(org.documentId)
                })
            }
            setUsers(restData)
            if(restData){
                var users = [];
                if(Array.isArray(restData)){
                    users = restData;
                }else{
                    users.push(restData);
                    
                }
                let ascendingUsers:any = [...restData].sort((a,b)=>{
                    return b.documentId - a.documentId
                  }) 
                  setUsers(ascendingUsers)    
                console.log(users)   
            }
      } 
    }
    let onLoginError = (err:any)=>{
        console.log(err);
    }

  return (
    <div className="container-fluid container-fluid-padding mt-3" style={{backgroundColor:'white'}}>
                    <div className="row fs-7 pt-1">
                    <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%",paddingLeft:"8px",paddingRight:"5px" }}>
                                <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails}}/>
                    </div>
                    </div>
                </div>
  )
}

export default IncEqpDetails