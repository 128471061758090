import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";
import { AttachmentsArea, TopEventSelector } from "./UpdatedVisualCompare";
import { param } from "jquery";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";

let orginalData: any = [];
let eventSelected: any = [];
const UpdatedTiresCompare = () => {
  const [tiresDetails, setTiresDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>(eventSelected);
  const [showCompareAttachments, setShowCompareAttachments] =
    useState<any>(null);
  const gridRef: any = useRef();
  useEffect(() => {
    if (gridRef?.current && !showCompareAttachments) {
      const gridApi: any = gridRef?.current?.getGridAPI();
      gridApi.deselectAll();
    }
  }, [showCompareAttachments, gridRef]);

  const compareTiresInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/compareTyreInspection?docId=${qparam}&eventId=${selectedEvents}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onCompareTiresInspection,
      onLoginError
    );
  };

  useEffect(() => {
    // getEventsOnlyForIdAndStatus();
    compareTiresInspection();
  }, [selectedEvents]);

  let onCompareTiresInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      //   setVisualDetails(restData[0].taskDetailsAttachment);
      let columns: any = [
        {
          field: "taskFullName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 600,
          sortable: true,
          cellStyle: (params: any) => {
            return {
              fontWeight: "600",
              letterSpacing: "0px",
              fontSize: "15px",
              fontFamily: "poppins",
              color: "black",
              paddingTop: "5px",
              background:
                params.data.taskTitle === "title"
                  ? "rgba(170, 170, 170,0.5)"
                  : null,
            };
          },
          headerClass: "header-white",
        },
      ];
      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i][0];
        let groupName: string = inspectionData.entryEventCode;
        inspectionData?.trailer?.map((ele: any) => {
          let obj: any = { ...ele };
          // obj[`same-${groupName}`] = obj.same;
          obj[`same-${groupName}`] = obj.entryValue;
          // obj[`taskTitle`] = "title";
          obj[`taskPlus`] = `title`;
          obj[`taskFullName`] = obj?.tyreName;

          const subTask: any = obj.subTask;
          // rowData.push(obj);
          subTask?.map((subEle: any, idx: number) => {
            let dataObj: any = { ...subEle };
            dataObj[`same-${groupName}`] = dataObj.same;
            dataObj[`entryAttachments-${groupName}`] = obj.entryAttachments;
            dataObj["exitAttachments"] = obj.exitAttachments;
            dataObj[`entryValue-${groupName}`] =
              dataObj.taskName === "type"
                ? String(dataObj.entryValue) !== "true"
                  ? "Original Tyre"
                  : "Recap Tyre"
                : dataObj.entryValue;
            dataObj[`newtaskName`] = obj.tyreName + dataObj.taskName;
            dataObj[`tastgroupName`] = obj.tyreName;

            if (idx === 0 && i == 0) {
              rowData.push({
                taskTitle: "title",
                taskFullName: obj.tyreName,
                [`entryAttachments-${groupName}`]: obj.entryAttachments,
                exitAttachments: obj.exitAttachments,
              });
              // dataObj['taskTitle'] = 'title';
              // dataObj['taskFullName'] = obj.tyreName;
            }
            try {
              const index = rowData.findIndex((item: any) => {
                return item.taskFullName === obj.tyreName;
              });
              if (index !== -1) {
                rowData[index] = {
                  ...rowData[index],
                  [`entryAttachments-${groupName}`]:
                    dataObj[`entryAttachments-${groupName}`],
                };
              }
            } catch (e) {}

            const index = rowData.findIndex((item: any) => {
              return item.newtaskName === dataObj?.newtaskName;
            });

            if (index === -1) {
              rowData.push(dataObj);
            } else {
              rowData[index] = { ...rowData[index], ...dataObj };
            }
          });

          // obj[`entryAttachments-${groupName}`] = obj.attachments
        });
        columns.push({
          field: `same-${groupName}`,
          headerName: groupName,
          resizable: true,
          width: 200,
          sortable: true,
          cellStyle: (params: any) => {
            const status = params.data[params.colDef.field];
            return {
              fontWeight: "400",
              letterSpacing: "0px",
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "poppins",
              color: params.data[params.colDef.field] ? "#28a745" : "#dc3545",
              paddingTop: "5px",
              background:
                params.data.taskTitle === "title"
                  ? "rgba(170, 170, 170,0.5)"
                  : !status
                  ? "rgba(242, 170, 176,0.5)"
                  : null,
            };
          },
          headerClass: "header-white",

          cellRenderer: (params: any) =>
            params.data.taskTitle === "title"
              ? ""
              : params.data[`entryValue-${groupName}`],
          // params.data[params.colDef.field] ? (
          //   <i className="fa-solid fa-check"></i>
          // ) : (
          //   <i className="fa-solid fa-xmark"></i>
          // ),
        });
      }
      columns.push({
        field: `exitValue`,
        headerName: "Receipt",
        resizable: true,
        width: 200,
        sortable: true,
        cellStyle: (params: any) => {
          const status = params.data[params.colDef.field];
          return {
            fontWeight: "400",
            letterSpacing: "0px",
            textAlign: "center",
            fontSize: "20px",
            fontFamily: "poppins",
            color: params.data[params.colDef.field] ? "#28a745" : "#dc3545",
            paddingTop: "5px",
            background:
              params.data.taskTitle === "title"
                ? "rgba(170, 170, 170,0.5)"
                : !status
                ? "rgba(242, 170, 176,0.5)"
                : null,
          };
        },
        headerClass: "header-white",

        cellRenderer: (params: any) =>
          params.data.taskTitle === "title"
            ? ""
            : params?.data?.taskName === "type"
            ? String(params?.data?.exitValue) !== "true"
              ? "Original Tyre"
              : "Recap Tyre"
            : params?.data?.exitValue,
      });
      setUserColumns(columns);

      setTiresDetails(rowData);
      orginalData = rowData;
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }
    eventSelected = checkboxCheck.join(",");

    setSelectedEvents(eventSelected);
  };

  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }
  const enableMismatch = (checked: boolean) => {
    showonlyMismatched(checked ? tiresDetails : orginalData, checked);
  };

  const showonlyMismatched = (rowData: any, allow = false) => {
    if (!allow) {
      setTiresDetails(rowData);
    } else {
      let mismatchedData: any = [];
      for (let i = 0; i < rowData.length; i++) {
        const element: any = rowData[i];
        if (element) {
          for (const key in element) {
            if (
              (key.includes("same-") && element[key] === false) ||
              element[key] === "title"
            ) {
              mismatchedData.push(element);
              break;
            }
          }
        }
        // mismatchedData.push(element);
      }
      setTiresDetails(mismatchedData);
    }
  };

  const onRowSelected = (params: any) => {
    if (!params.node.selected) return;
    let attachments: any = [];

    // if(params.data.taskTitle !== "title"){
    //   setShowCompareAttachments(null);
    //   return;
    // }

    try {
      for (let item in params.data) {
        let arr: any = item.split("entryAttachments-");

        if (arr.length > 1) {
          attachments.push({
            name: `${arr[1]} > ${params.data.tastgroupName}`,
            position: params.data.tastgroupName,
            attachments: { ...params.data[item] },
          });
        }
      }
      if (params.data["exitAttachments"]) {
        attachments.push({
          name: `Receipt > ${params.data.tastgroupName}`,
          position: params.data.tastgroupName,
          attachments: { ...params.data["exitAttachments"] },
        });
      }
    } catch (e) {
      attachments = [];
    }
    setShowCompareAttachments(attachments);
  };

  return (
    <div className="containData">
      <TopEventSelector
        releases={releases}
        checkboxCheck={checkboxCheck}
        setReleases={setReleases}
        eventSelected={eventSelected}
        // updateSelction={updateSelction}
        enableMismatch={enableMismatch}
        setSelectedEvents={setSelectedEvents}
        selectedEvents={selectedEvents}
      />
      <hr className="mb-3" style={{ width: "98%" }} />
      <div style={{ position: "relative" }}>
        <SplitterLayout primaryIndex={0}>
          <div className="row">
            <div
              className="ag-theme-alpine ag-theme-customeTheme gridHeight"
              style={{
                height: "calc(100vh - 330px)",
                width: "100%",
                paddingLeft: "8px",
                paddingRight: "5px",
              }}
            >
              <InspectionGrid
                cols={userColumns}
                rows={tiresDetails}
                pageSize={100}
                addNew={true}
                tooltip={"Add Company"}
                name={"Add Company"}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                // rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                rowHeight={40}
                hideToolbar={true}
                onRowSelected={onRowSelected}
                ref={gridRef}
              />
            </div>
          </div>
          {showCompareAttachments && (
            <AttachmentsArea
              setShowCompareAttachments={setShowCompareAttachments}
              showCompareAttachments={showCompareAttachments}
            />
          )}
        </SplitterLayout>
      </div>
    </div>
  );
};

export default UpdatedTiresCompare;
