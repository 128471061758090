
import React, { useEffect, useRef, useState } from 'react'
import InspectionSelect from '../InspectionGrid/InspectionSelect'
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { CommonUtil } from '../../utils/CommonUtil'
import Select from 'react-select'
import { NetworkManager } from '../../Components/NetworkManager'
import $ from 'jquery'
import ToastManager from '../../Components/ToastManager'
import { StatusCodeUtil } from '../../utils/StatusCodeUtil'
import BusyLoader from '../../Components/BusyLoader'
import { loadMapApi } from '../../utils/GoogleMapsUtils'
import PhoneInput from 'react-phone-input-2'
import DraggableComponent from '../../Components/DraggableComponent'
import SingleSelectComp from '../../Components/SingleSelectComp';
import { Modal } from 'react-bootstrap'
import AGGrid from '../../Components/AGGrid'
import OrgCellRenderer from '../Organization/OrgCellRenderer'
import { IAGrid } from '../../Components/IInterfaces'
import useStateWithCallback from 'use-state-with-callback'
import {Constants} from "../Languages/Constants";
import {Events} from "../Languages/Events";
import {useNavigate} from "react-router-dom";
import DetectDevice from '../../utils/DetectDevice'
import MGrid from '../../Components/MGrid'
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import AssignedOrganization from './AssignedOrganization'
import UserDetails from './UserDetails'
import './Inspection.css'
import CompanyDetailsBlack from './CompanyDetailsBlack.svg'
import CompanyDetailsRed from './CompanyDetailsRed.svg'
import usersBlack from './usersBlack.svg'
import usersRed from './usersRed.svg'
import AssignedOrganizationsBlack from './AssignedOrganizationsBlack.svg'
import AssignedOrganizationsRed from './AssignedOrganizationsRed.svg' 



var defCountryObj = { Key: 'Canada', Value: 'Canada' }
const options = {
  fields: ['formatted_address', 'geometry', 'name'],
  strictBounds: false,
  types: ['establishment'],
}

const EditCompany = () => {

  const [users,setUsers] = useState([]);
  const [usdot,Setusdot] = useState('');
  const [rawAddress,setRawAddress] = useState("");
  const [email,setEmailId] = useState("");
  const [contactName,setContactName] = useState("");
  const [contactNumber,setContactNumber] = useState("");
  const [instructions,setInstructions] = useState("")
  const [inspectionOrgId,setInspectionOrgId] = useState(3);
  const [inspectionOrgAddressDto, setInspectionOrgAddressDto] = useState(null)
  const [inspectionOrgName,setInspectionOrgName] = useState("");
  const [insObj,setInsObj] = useState<any>(null);

  const [status,setStatues] = useState(CommonUtil.INSPECTION_STATUS);
  const [statusObj,setStatusObj]  = useState<any>(null);

  const [country, setCountry] = useState(CommonUtil.country);
  const [countryObj, setCountryObj] = useState<any>(null);
  const [countryClass, setCountryClass] = useState("mandatory");
  const [inspectionType,setInspectionType] = useState(CommonUtil.inspectionType);
  const [insTypeObj,setInsTypeObj] = useState<any>(null);
  const [insObjClass,setInsObjClass] = useState("mandatory");
  const [insTypeClass,setInsTypeClass] = useState("mandatory");
  const [statusClass,setStatusClass] = useState("mandatory");
  const [userStatus,setUserStatus] = useState("");
  const [genInfo, setGenInfo] = useState(true);

  const [user,setUser] = useState(false);
  const [assignedOrgs,setAssignedOrgs] = useState(false);

  const [userInfo,setUserInfo] = useState(true);
  const [assignedInfo,setAssignedInfo] = useState(true);

  const [inspectorInfo,setInspectorInfo] = useState(false);

  const [disable,setDisable] = useState(false);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [phoneClass, setPhoneClass] = useState('mandatory');



  const [inspectionImg,setInspectionImg] = useState(true);
  const [userImg,setUserImg] = useState(true);
  const [AssignedOrgsImg,setAssignedOrgsImg] = useState(true);

  const { isMobile } = DetectDevice();

  const navigate = useNavigate();

  const inputRef = useRef<any>(null);


    const usRef = useRef<HTMLInputElement>(null);
    const insRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const companyRef = useRef<HTMLInputElement>(null);
    const statusRef = useRef<HTMLSelectElement>(null);
    const addressRef = useRef<HTMLInputElement>(null)

// if(CommonUtil.getOrganizationType() === "OWNER"){
//   setUser(false);
//   setAssignedOrgs(false);
// }    

useEffect(()=>{
  loadGooglePlaces();
},[])

    let deSelectButtons = () => {
      setGenInfo(false);
      setUser(false);
      setAssignedOrgs(false);
  
      let links = ['navInspection','navUsers','navAssignedOrgs'];
      links.forEach((item:any,idx:number)=>{
        $("#"+item).removeClass("active");
      });
    }

    let onClickInspection = ()=>{
      deSelectButtons()
      setGenInfo(true)  
      setInspectionImg(true);
      setUserImg(true);
      setAssignedOrgsImg(true);
      $("#navInspection").addClass("active");
    }

    let onClickUsers = ()=>{
      deSelectButtons()
      setUser(true)
      setInspectionImg(false);
      setUserImg(false);
      setAssignedOrgsImg(true);
      $("#navUsers").addClass("active");
    }
  
    let onClickAssignedOrgs = ()=>{
      deSelectButtons()
      setAssignedOrgs(true)
      setAssignedOrgsImg(false);
      setUserImg(true);
      setInspectionImg(false);
      $("#navAssignedOrgs").addClass("active");

    }

    let getOrgType = ()=>{
      if(localStorage.getItem('orgType')=="OWNER" || localStorage.getItem('orgType')=="OWNER_RENTER"){
        setAssignedInfo(false)
        setUserInfo(false);
        setDisable(true);
      }else if(localStorage.getItem('orgType')=="INSPECTION_COMPANY"){
        setAssignedInfo(false);
        setInspectorInfo(true);
        setUserInfo(true);
        setDisable(true);
      }
    }

    useEffect(()=>{
      getOrgType();
    })
    
 
    let onInsTypeChange = (data:any)=>{
        setInsTypeObj(data);
        (data)? setInsTypeClass("select-box-valid"): setInsTypeClass("select-box-invalid");
    }
  
  let onStatusChange = (data: any) => {
    setStatusObj(data);
    (data)?setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
  }

  let onCountryChange = (data:any)=>{
    setCountryObj(data);
    (data)? setCountryClass("select-box-valid"): setCountryClass("select-box-invalid");

}


  let getInspectionCompany = ()=>{
    var qParam = CommonUtil.getInspectionSaasOrgId();
    var orgUrl = "api/InspectionOrganization/"+qParam;
    NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
}

useEffect(()=>{
  getInspectionCompany();
},[])

let onOrgGetAllUsers = (dataObj:any)=>{
  if(dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData)
      if(restData){
            setEmailId(restData.userEmail);
            Setusdot(restData.usDot);
            setContactNumber(restData.phoneNumber);
            setRawAddress(restData.rawAddress);
            setContactName(restData.firstName);
            setInspectionOrgName(restData.inspectionOrgName)
            setInspectionOrgId(restData.inspectionOrgId);
            setInsTypeObj({Key:restData.inspectionType,Value:restData.inspectionType})
            setStatusObj({key:restData.organizationStatus,value:restData.organizationStatus});
            setInspectionOrgAddressDto(restData.inspectionOrgAddressDto);
            setCountryObj({Key:restData.country, Value:restData.country})
            
      }

}
  

}


  let onClickSave = () => {
   
    var isLn:boolean = CommonUtil.isMandatory(lnRef);
    var isUs:boolean = CommonUtil.isMandatory(usRef);
    var isIns:boolean = CommonUtil.isMandatory(insRef);
    var isContact:boolean = true;
    var isMail:boolean = CommonUtil.isEmailMandatory(emailRef);
    (statusObj) ? setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
    (insTypeObj)? setInsObjClass('select-box-valid') : setInsObjClass('select-box-invalid');

    if (!contactNumber.trim() || contactNumber.length < 11) {
      isContact = false
      setPhoneClass('is-invalid')
  }

    if (isUs && isIns && isLn && isContact && isMail && statusObj && insTypeObj) {
      var restObj: any = {}
      console.log(restObj)
            restObj.usDOT = usdot;
            restObj.rawAddress = rawAddress;
            restObj.userEmail = email;
            restObj.phoneNumber = contactNumber;
            restObj.firstName = contactName;
            restObj.lastName = "";
            restObj.department = "Administrative";
            restObj.inspectionOrgName = inspectionOrgName;
            restObj.inspectionType = insTypeObj?"Interchange":"";
            restObj.country = countryObj?countryObj.Key:"";
            restObj.companyStatus = statusObj ? statusObj.key : ''

      BusyLoader.showLoader();
      var qParam = CommonUtil.getInspectionSaasOrgId();
      var orgUrl: string = 'api/InspectionOrganization/'+qParam;
      NetworkManager.sendJsonRequest(orgUrl, restObj, '', 'PUT', false, onOrgUpdateSuccess, onLoginError);
    }
  }

  let onOrgUpdateSuccess = (dataObj: any) => {
    BusyLoader.hideLoader()
    let strOrgUpdated = CommonUtil.getMessageText(Constants.INSPECTION_UPDATED,"Inspection Company updated successfully");
    navigate("/menu/inspectionCompany");
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        ToastManager.showToast(strOrgUpdated,"Info");
      }
    }
  }

  let onClickCancel = ()=>{
    navigate("/menu/inspectionCompany");
  }
  
  let onLoginError = (err: any) => {
    console.log(err)
  }

  let loadGooglePlaces = () => {
    if (window.google) {
        setScriptLoaded(true);
        getGooglePlaces();
    } else {
        const googleMapScript = loadMapApi();
        if (googleMapScript) {
            googleMapScript.addEventListener('load', function () {
                setScriptLoaded(true);
                getGooglePlaces();
            })
        }
    }
}


let getGooglePlaces = () => {

  const address = document.getElementById("address") as HTMLInputElement;
  CommonUtil.getPlaceAddressByMap(address,(addr:any)=>{
      //console.log(result);
      setRawAddress(addr['rawAddress']);
      setCountryObj({Key: addr['country'], Value: addr['country']});
  });
}

  return (
    <>
      <div className="page-content-wrapper mt-3" style={{paddingLeft:"5px",paddingRight:"5px"}}>
        <div className="container-fluid container-fluid-padding" style={{background:"#f9f9f9"}}>
            <div className={`inspection-tabs row`} style={{ justifyContent:'left',marginLeft:'20px' }}>
                              <div className="scroller scroller-left float-left" onClick={leftClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                                    {userInfo?(<>
                                      <nav className="nav nav-tabs col-6 px-3" id="myTab" role="tablist">
                                      <li style={{border:'1px solid #EDF0F0',borderBottom:'0px',background:'white',display:'flex',cursor:'pointer'}}>
                                        <a id={"navInspection"} className={`nav-item nav-link active rounded-0 ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={onClickInspection}>{inspectionImg?<img src="../../images/CompanyDetailsRed.svg" style={{height:'20px',width:'20px'}}/>:<img src="../../images/CompanyDetailsBlack.svg" style={{height:'20px',width:'20px'}}/>}&nbsp;&nbsp;&nbsp;<b>{CommonUtil.getMessageText(Constants.COMPANY_DETAILS,"Company Details")}</b>
                                      </a>
                                      </li>&nbsp;&nbsp;
                                     <li style={{border:'1px solid #EDF0F0',borderBottom:'0px',background:'white',display:'flex',cursor:'pointer'}}>
                                      <a id={"navUsers"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={onClickUsers}>{userImg?<img src="../../images/usersBlack.svg" style={{height:'20px',width:'20px'}}/>:<img src="../../images/usersRed.svg" style={{height:'20px',width:'20px'}}/>}&nbsp;&nbsp;&nbsp;<b>{CommonUtil.getMessageText(Constants.USERS,"Users")}</b>
                                      </a>
                                      </li>&nbsp;&nbsp;
                                      {inspectorInfo?"":(
                                      <>
                                        <li style={{border:'1px solid #EDF0F0',borderBottom:'0px',background:'white',display:'flex',cursor:'pointer'}}>
                                        <a id={"navAssignedOrgs"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={onClickAssignedOrgs}>{AssignedOrgsImg?<img src="../../images/AssignedOrganizationsBlack.svg" style={{height:'20px',width:'20px'}}/>:<img src="../../images/AssignedOrganizationsRed.svg" style={{height:'20px',width:'20px'}}/>}&nbsp;&nbsp;&nbsp;<b>{CommonUtil.getMessageText(Constants.ASSIGNED_ORGANIZATIONS,"Assigned Organizations")}</b>
                                        </a>
                                      </li>
                                      </>)}
                                                                         
                                    </nav></>):(<>
                                      <nav className="nav nav-tabs col-6 px-3" id="myTab" role="tablist">
                                      <li style={{border:'1px solid #EDF0F0',borderBottom:'0px',background:'white',display:'flex',cursor:'pointer'}}>
                                        <a id={"navInspection"} className={`nav-item nav-link active rounded-0 ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={onClickInspection}>{inspectionImg?<img src="../../images/CompanyDetailsRed.svg" style={{height:'20px',width:'20px'}}/>:<img src="../../images/CompanyDetailsBlack.svg" style={{height:'20px',width:'20px'}}/>}&nbsp;&nbsp;&nbsp;<b>{CommonUtil.getMessageText(Constants.COMPANY_DETAILS,"Company Details")}</b>
                                      </a>
                                      </li>
                                    </nav></>)}
                                    
            </div>
        </div>
      </div>
      <div className="tab-content p-0" id="myTabContent" style={{background:"#FFF",width:"100%"}}>
          {genInfo && (
              <div className="container-fluid">
                <section style={{width:"99%"}}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSPECTION_COMPANY,"Inspection Company Name")}
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <div className="inputf">
                            <input
                                  ref={insRef}
                                  disabled={disable}
                                  type="text"
                                  readOnly
                                  className="form-control clsStandardText  css-2rsrq9 clsFormInputControl"
                                  placeholder={CommonUtil.getMessageText(Constants.COMPANY_NAME,"Company Name")}
                                  value={inspectionOrgName}
                                  aria-label="Company Name"
                                  onChange={(e)=>{setInspectionOrgName(e.currentTarget.value)}}
                              />
                            </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATUS,"Status")}
                            <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                              <SingleSelectComp disabled={disable} ref={statusRef} dataSource={status}
                                  selectedValue={statusObj} handleChange={onStatusChange} value={'key'}
                                  label={'value'} className={statusClass}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.USDOT,"USDOT")} 
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <div className="inputf">
                              <input
                                  ref={usRef}
                                  disabled={disable}
                                  type="text"
                                  className="form-control clsStandardText  css-2rsrq9 clsFormInputControl"
                                  placeholder={CommonUtil.getMessageText(Constants.USDOT,"USDOT")}
                                  value={usdot}
                                  aria-label="USDOT"
                                  onChange={(e)=>{Setusdot(e.currentTarget.value)}}
                              />
                            </div>
                            </div>
                          </div>
                        </div>


                      <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOCATION,"Location")}
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <div className="inputf">
                            <input type="text" id={'address'} ref={lnRef}
                                            className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.LOCATION, 'Location')}
                                            aria-label="Location"
                                            disabled={disable}
                                            value={rawAddress}
                                            onChange={(e)=>{setRawAddress(e.currentTarget.value);CommonUtil.isMandatory(addressRef);}} 
                                        />
                            </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSPECTION_TYPE,"Inspection Type")}
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <SingleSelectComp ref={companyRef} disabled={disable} dataSource={inspectionType}
                                  selectedValue={insTypeObj} handleChange={onInsTypeChange} value={'Key'}
                                  label={'Value'} className={insTypeClass}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")}
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <div className="inputf">
                              <input 
                                  ref={emailRef}
                                  type="text"
                                  disabled={disable}
                                  className="form-control css-2rsrq9 clsStandardText clsFormInputControl"
                                  placeholder={CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")}
                                  aria-label="Email"
                                  value={email}
                                  onChange={(e) => {
                                    setEmailId(e.currentTarget.value)
                                  }}
                              />
                            </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CONTACT_NAME,"Contact Name")}</label>
                            <div className="col-sm-12">
                            <div className="inputf">
                            <input  type="text" disabled={disable}
                                               className="form-control clsStandardText  clsInputControl"
                                               placeholder="Enter Contact Name"  aria-label="Contact Name" value={contactName}
                                               onChange={(e)=>{setContactName(e.currentTarget.value)}} />
                             
                            </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label"> {CommonUtil.getMessageText(Constants.PHONE_NO,"Phone Number")} 
                            <span className="text-danger"></span></label>
                            <div className="col-sm-12">
                            <div className="inputf">
                              <PhoneInput
                                  country={'us'}
                                  countryCodeEditable={true}
                                  disabled={disable}
                                  value={contactNumber}
                                  containerClass={phoneClass}
                                  placeholder="Enter Phone Number"
                                  inputClass={`input-phone ${contactNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                  onChange={(e) => {
                                    //console.log(e)
                                    if (contactNumber == '') {
                                      e = '' + e
                                    }
                                    setContactNumber(e)
                                  }}
                                  disableCountryGuess={false}
                                  disableDropdown={true}
                                  disableSearchIcon={true}
                              />
                            </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COUNTRY,"Country")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                    <SingleSelectComp dataSource={country} selectedValue={countryObj} disabled={disable} handleChange={onCountryChange} value={"Key"} label={"Value"}
                                                           className={countryClass} width={"100%"} placeholder="Select Status"/>
                                    </div>
                                </div>
                            </div>
                        
                        <div className="col-md-12 col-12 text-right pt-4 pb-2">
                          <button type="submit" className="btn btn-success" disabled={disable} onClick={onClickSave}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>&nbsp;&nbsp;
                          <button type="submit" className="btn btn-danger" onClick={onClickCancel}>{CommonUtil.getMessageText(Constants.CANCEL,"Cancel")}</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
              </div>
                
          )}
          {(user && userInfo) && (
              <div className="container-fluid canvasBg">
              <section style={{width:"99%"}}>
              <UserDetails />
                </section>
                </div>
          )}
          {(assignedOrgs && assignedInfo)&&(
              <div className="container-fluid">
              <section style={{width:"99%"}}>
              <AssignedOrganization />
                </section>
                </div>
          )}
      </div>
    </>
  )
}

export default EditCompany;
