import React from 'react'
import CarouselComponent from '../CarouselComponent'
import '../Interchange.css'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'
import { Constants } from '../../Languages/Constants'

const EntryCargoInspection = () => {

    const [cargoDetails,setCargoDetails] = useState<any>([])
    const [attachments,setAttachments] = useState<any>([])
    const [damageDes,setDamageDes] = useState("") 

    const [galleryPop,setGalleryPop] = useState(false);
    const [galleryPopStartIndex,setGalleryPopStartIndex] = useState(0);

    let getCargoInspectionDetails = () =>{

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=ENTRY`
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetCargoInspectionDetails,onLoginError);
      
      }

      useEffect(()=>{
        getCargoInspectionDetails();
      },[])

      let onGetCargoInspectionDetails = (dataObj:any)=>{
        if(dataObj){
            var restData = JSON.parse(dataObj);
            console.log(restData)
            setDamageDes(restData.cargoInspection.attachments.attachmentDamageDescription)
            if(restData.cargoInspection.taskDetails){
                var cargo = [];
            if(Array.isArray(restData.cargoInspection.taskDetails)){
                cargo = restData.cargoInspection.taskDetails;
            }else{
                cargo.push(restData.cargoInspection.taskDetails); 
            }
            setCargoDetails(cargo)
            setAttachments(restData?.cargoInspection?.attachments);
        }
        }
      }



      let onLoginError = (err:any)=>{
            console.log(err)
      }

      let Capitalize = (str:any)=>{
        str = String(str);
        let strText = str.charAt(0).toUpperCase() + str.slice(1);
        
        return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
        
        }

        let attachImages = [];
        try{
            attachImages = attachments.attachmentUploads.map((ele:any)=> ele.fullFilePath)
            console.log(attachImages)
        }catch(e){
            attachImages = [];
        }

        let filteredArray = cargoDetails.filter((task:any)=>{
            if(task.name == "trailerSealed" || task.name == "sealSecured"){
                return task
            }
        })

        let filteredArray2 = cargoDetails.filter((task:any)=>{
            if(task.name == "cargoAreaClean" || task.name == "productLeakage" || task.name == "shiftOrPalletLearning" || task.name == "loadPlacement" || task.name == "loadSecure"){
                return task
            }
        })


      
        console.log(filteredArray)
        console.log(filteredArray2)

  return (
    <div>
         <div className="row">
                            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                                <div className="inner-table-data table-responsive mb-3">
                                    <table className="table table-bordered">
                                        <tbody>
                                            {filteredArray.map((data:any)=>{
                                                return(
                                                <>
                                                <tr>
                                                <td>{Capitalize(data.name)}</td>
                                                <td className={`${data.status === "No"?"text-danger text-uppercase":"text-uppercase"}`}>{data.status === "No"?"NO":"YES"}</td>
                                                </tr>
                                                </>)

                                            })}

                                        </tbody>
                                    </table>
                                </div>

                                <div className="inner-table-data table-responsive mb-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">INSPECTION ITEM</th>
                                                <th scope="col">PASS</th>
                                                <th scope="col">FAIL</th>
                                                <th scope="col">NOTE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredArray2.map((data:any)=>{
                                                return(
                                                <>
                                                <tr>    
                                                <td>{Capitalize(data.name)}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.FAIL?"text-danger text-uppercase table-danger":"text-success text-uppercase"}`}>{String(data.status).toUpperCase() === Constants.FAIL?"NO":"YES"}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.OK?"text-success text-uppercase":"text-danger text-uppercase table-danger"}`}>{String(data.status).toUpperCase() === Constants.OK?"NO":"YES"}</td>
                                                <td>{data.note !== ""?data.note:""}</td>
                                                </tr>
                                                </>)
                                            })}
                                            </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-1 col-md-6 col-sm-12 text-center">
                            </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="mx-auto my-auto justify-content-center">
                                        <div className="gallery-carousel">
                                            <h2 className="title-head my-3">Release Damage Photos</h2>
                                            <CarouselComponent images={attachImages} callBack={(idx:any)=>{
                                                setGalleryPop(true);
                                                setGalleryPopStartIndex(idx);
                                            }}/>
                                         <ReactImageGallery startIndex={galleryPopStartIndex} attachedImages={attachImages} showPop={galleryPop} handleClose={()=>{setGalleryPop(false)}} />
                                        </div>
                                    </div>

                                    <div className="gallery-form form">
                                        <h2 className="title-head my-4 mb-2">Release Damage Description</h2>
                                        <div className="form-floating">
                                            <div className="form-control" placeholder="Leave a comment here"
                                                id="floatingTextarea2"
                                                style={{minHeight:'100px',height:"auto"}}>{damageDes}</div>
                                                
                                        </div>
                                    </div>
                                </div>
                        </div>
    </div>
  )
}

export default EntryCargoInspection