import React, {useMemo, useState} from 'react';
import AGGrid from '../../Components/AGGrid';
import UserCheckBoxRender from "./userCheckBoxrender";
import UserStatusRenderer from "./userStatusRenderer";
import ToastManager from "../../Components/ToastManager";
import {NetworkManager} from "../../Components/NetworkManager";
import DraggableComponent from '../../Components/DraggableComponent';
import {Modal} from "react-bootstrap";
import DetectDevice from '../../utils/DetectDevice';
import MGrid from '../../Components/MGrid';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';

const NotificationList = ({
                              handleShow,
                              notificationList,
                              selectedUsers,
                              setSelectedUsers,
                              getAllMasters,
                              getUserAssociation,
                              userGridRef,
                              getAllNotifications
                          }: any) => {

    const [subData, setSubData] = useState<any>({});
    const [confirm, setConfirm] = useState<boolean>(false);
    const { isMobile } = DetectDevice();
    const onClickUser = (data: any) => {
        if(selectedUsers.length > 0 && data.no !== 0){
            return ToastManager.showToast("Configure for this use is separately", 'info');
        }
            setSelectedUsers([])
            let rawdata: any = [{userId: data.userId, name: `${data.firstName} ${data.lastName}`}];
            setTimeout(() => {
                getUserAssociation(data);
                setSelectedUsers(rawdata);
                handleShow();
            }, 200)
    }
    const onError = (data: any) => {
        setConfirm(false)
    }
    const onSuccess = (data: any) => {
        getAllNotifications();
        setConfirm(false)
        ToastManager.showToast('User subscription status changed', 'Success');
    }
    const onClickActiveUser = (data: any) => {
        setSubData(data)
        setConfirm(true);
    }

    const changeStatus = () => {
        let query = `userId=${subData.userId}&&status=${subData.notificationSubscriptionStatus === 'Active' ? 'Inactive' : 'Active'}`
        NetworkManager.sendJsonRequest('api/notification/changeUserSubscriptionStatus?' + query, '', '', 'POST', true, onSuccess, onError)
    }
    const [notificationColumns] = useState([
        {
            field: 'firstName',
            headerName: CommonUtil.getMessageText(Constants.NAME, 'Name'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            cellRenderer: UserCheckBoxRender,
        },
        {
            field: 'loginId',
            headerName: CommonUtil.getMessageText(Constants.EMAIL, 'Email'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'Designation',
            headerName: CommonUtil.getMessageText(Constants.DESTINATION, 'Designation'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'department',
            headerName: CommonUtil.getMessageText(Constants.DEPARTMENT, 'Department'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'notificationCount',
            headerName: CommonUtil.getMessageText(Constants.NOTIFICATION, 'Notification'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'orgName',
            headerName: CommonUtil.getMessageText(Constants.COMPANY, 'Company'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'notificationSubscriptionStatus',
            headerName: CommonUtil.getMessageText(Constants.STATUS, 'Status'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: UserStatusRenderer
        },
    ]);
    const onClickNewNotification = () => {
        if (selectedUsers.length > 0) {
            if (selectedUsers.length > 1) {
                let find = selectedUsers.find((selected: any) => selected.notificationCount > 0);
                if (find) {
                    ToastManager.showToast('Please select the users notification count equals to 0, or else configure individually', 'Info')
                } else {
                    getAllMasters();
                    handleShow(true);
                    handleShow('new');
                }
            } else {
                if (selectedUsers[0].notificationCount === 0) {
                    getAllMasters();
                } else {
                    getUserAssociation(selectedUsers[0]);
                }
                handleShow();
            }
        } else {
            ToastManager.showToast('Please select users', 'Info')
        }
    }
    const isRowSelectable = useMemo(() => {
        // return (params: any) => {
        //     return !!params.data && params.data.notificationCount === 0;
        // };
    }, []);

    const onRowSelected = (event: any) => {

        let newData: any = [...selectedUsers];
        if (event.node.isSelected()) {
            newData.push({
                userId: event?.node?.data?.userId,
                name: `${event.node.data.firstName} ${event.node.data.lastName}`,
                notificationCount: event?.node?.data?.notificationCount
            });
            setSelectedUsers(newData)
        } else {
            let findIndex = newData.findIndex((selected: any) => selected.userId === event?.node?.data?.userId)
            newData.splice(findIndex, 1);
            setSelectedUsers(newData);
        }
    };
    return (
        <div className={`fs-7 ${isMobile ? '' : 'pt-3'}`}>
            <Modal  show={confirm} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px","height":"200px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{"Change status"}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setConfirm(false)}} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <span className="dark-text fs-7">Do you want to change subscription status?</span>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={()=>{setConfirm(false)}}>Cancel</button>
                        <button type="button" className="btn btn-success" onClick={changeStatus}>Ok</button>
                    </div>
                </div>

            </Modal>
            {
                isMobile ? (
                <div className='px-3' >
                    <MGrid id="notificationList" cols={notificationColumns} rows={notificationList} pageSize={10}
                        addNew={false} tooltip={"Edit Notifications"} rowSelection={'multiple'}
                        onRowSelected={onRowSelected} isRowSelectable={isRowSelectable}
                        headerHeight={35} rowHeight={40} ref={userGridRef} method={{onClickUser, onClickActiveUser}}
                        addNewRecord={onClickNewNotification} 
                        suppressRowClickSelection={true}/>
                </div>
                ) : (
                    <div className="ag-theme-alpine gridHeight"
                 style={{height: "calc(100vh - 180px)", width: "100%", paddingLeft: "8px", paddingRight: "5px"}}>
                <AGGrid id="notificationList" cols={notificationColumns} rows={notificationList} pageSize={500}
                        addNew={true} tooltip={"Edit Notifications"} rowSelection={'multiple'}
                        onRowSelected={onRowSelected} isRowSelectable={isRowSelectable}
                        headerHeight={35} rowHeight={40} ref={userGridRef} method={{onClickUser, onClickActiveUser}}
                        addNewRecord={onClickNewNotification} suppressRowClickSelection={true}/>
            </div>
                )
            }
        </div>
    );
}

export default NotificationList;
