import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import PhoneInput from "react-phone-input-2";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";

const AddBrokerDetailsModal = ({
  mode,
  open,
  handleClose,
  getDashboardCount,
  getAllBrokerDetails,
}: any) => {
  const [broName, setBroName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [phoneClass, setPhoneClass] = useState("mandatory");
  const [email, setEmail] = useState("");
  const [usdotNumber, setUsDotNumber] = useState("");
  const [status, setStatus] = useState("Active");

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const mobileRef = useRef(null);

  let onSuccess = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (mode === "edit") {
          getAllBrokerDetails();
        } else {
          getDashboardCount();
        }
        resetData();
        ToastManager.showToast(
          mode === "edit" ? "Broker updated." : "Broker created.",
          "Success"
        );
      }
    }
  };

  const handleCancel = () => {
    resetData();
  };

  const resetData = () => {
    handleClose();
    setBroName("");
    setPhoneNumber("");
    setEmail("");
    setUsDotNumber("");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let isName: boolean = CommonUtil.isMandatory(nameRef);
    let isPhone: boolean = true;
    let isEmail: boolean = true;
    if (email && email.length > 0) {
      isEmail = CommonUtil.isValidEmail(email);
    }
    // if(!phoneNumber.trim() || phoneNumber.length < 11){
    //     isPhone = false
    //     setPhoneClass('is-invalid')
    // }
    if (isName && phoneNumber) {
      let newData = {
        brokerName: broName.trimStart().trimEnd(),
        phoneNumber,
        email: email,
        dotNumber: usdotNumber,
        brokerStatus: status,
      };
      if (mode === "edit") {
        let orgUrl = `api/broker/?saasOrgId=${CommonUtil.getSaasOrgId()}&brokerId=${CommonUtil.getBrokerId()}`;
        NetworkManager.sendJsonRequest(
          orgUrl,
          newData,
          "",
          "PUT",
          true,
          onSuccess,
          onLoginError
        );
      } else {
        let orgUrl = "api/broker/?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(
          orgUrl,
          newData,
          "",
          "POST",
          true,
          onSuccess,
          onLoginError
        );
      }
      handleClose();
    }
  };

  const onGetBrokerDetails = (data: any) => {
    if (data) {
      let restData = JSON.parse(data);
      console.log(restData);
      setBroName(restData.brokerName);
      setPhoneNumber(restData.phoneNumber);
      setEmail(restData.email);
      setUsDotNumber(restData.dotNumber);
      setStatus(restData.brokerStatus);
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      let orgUrl = "api/broker/?brokerId=" + CommonUtil.getBrokerId();
      NetworkManager.sendJsonRequest(
        orgUrl,
        "",
        "",
        "GET",
        true,
        onGetBrokerDetails,
        onLoginError
      );
    }
  }, [mode]);

  let onLoginError = (err: any) => {
    console.log(err);
  };
  const handleInputPhone = (e: any) => {
    setPhoneNumber(e);
  };
  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
    let flag: boolean = CommonUtil.isValidEmail(e.target.value);
    if (flag) {
      emailRef.current?.classList.add("is-valid");
      emailRef.current?.classList.remove("is-invalid");
    } else {
      emailRef.current?.classList.add("is-invalid");
      emailRef.current?.classList.remove("is-valid");
    }
  };
  const handleUSDOTNumber = (e: any) => {
    setUsDotNumber(e.target.value);
  };
  const handleInputStatus = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <Modal show={open} dialogAs={DraggableComponent} centered>
      <div className="modal-content" style={{ width: "600px" }}>
        <Modal.Header className="modal-header" closeButton={false}>
          <h5 className="modal-title mt-0">
            {mode === "new"
              ? CommonUtil.getMessageText(
                  Constants.CREATE_BROKER,
                  "Create Broker"
                )
              : CommonUtil.getMessageText(
                  Constants.UPDATE_BROKER,
                  "Edit Broker"
                )}
          </h5>
          <button
            type="button"
            className="close"
            style={{ marginLeft: "0px" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <div className="modal-body">
          <div className="col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.BROKER_NAME,
                  "Broker Name"
                )}{" "}
                <span className="text-danger"></span>
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  ref={nameRef}
                  name="brokerName"
                  className="form-control clsFormInputControl"
                  placeholder={CommonUtil.getMessageText(
                    Constants.ENTER_BROKER_NAME,
                    "Enter Broker Name"
                  )}
                  value={broName}
                  onChange={(e: any) => {
                    setBroName(e.target.value);
                    CommonUtil.isMandatory(nameRef);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.MOBILE_NUMBER,
                  "Mobile Number"
                )}{" "}
                <span className="text-danger"></span>
              </label>
              <div className="col-sm-12" style={{ zIndex: 9 }}>
                <PhoneInput
                  country={"us"}
                  countryCodeEditable={false}
                  containerClass={phoneClass}
                  value={phoneNumber}
                  placeholder={CommonUtil.getMessageText(
                    Constants.ENTER_MOBILE_NUMBER,
                    "Enter Phone Number"
                  )}
                  inputClass={`input-phone ${
                    phoneNumber.toString().length === 11 ? "" : "inputMandatory"
                  }`}
                  //   inputClass={`input-phone ${phoneClass}`}
                  onChange={handleInputPhone}
                  containerStyle={{
                    width: "100%",
                  }}
                  disableCountryGuess={true}
                  disableDropdown={true}
                  disableSearchIcon={true}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.EMAIL_OPTIONAL,
                  "Email (Optional)"
                )}
              </label>
              <div className="col-sm-12">
                <input
                  className="form-control"
                  ref={emailRef}
                  placeholder={CommonUtil.getMessageText(
                    Constants.EMAIL_OPTIONAL,
                    "Email (Optional)"
                  )}
                  type={"email"}
                  name="email"
                  value={email}
                  onChange={handleInputEmail}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {"USDOT Number (Optional)"}
              </label>
              <div className="col-sm-12">
                <input
                  className="form-control"
                  placeholder="USDOT Number (Optional)"
                  type="text"
                  maxLength={20}
                  value={usdotNumber}
                  onChange={handleUSDOTNumber}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(Constants.STATUS, "Status")}{" "}
                <span className="text-danger"></span>
              </label>
              <div className="col-sm-12">
                <select
                  name="status"
                  className={"form-control"}
                  onChange={handleInputStatus}
                  value={status}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-dark"
            data-dismiss="modal"
            onClick={handleCancel}
          >
            {CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit}
          >
            {CommonUtil.getMessageText(Constants.SAVE, "Save")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddBrokerDetailsModal;
