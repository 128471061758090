import React from "react";

const deleteAssignedOrganization  = (props: any) => {
    const onClickRemoveOrganization = () => {
        if (props.context) {
            props.context.removeOrganization(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <button className="btn btn-danger" onClick={onClickRemoveOrganization}>{"Remove"}</button>
        </div>

    )
}

export default deleteAssignedOrganization;
