import React, { useEffect, useRef, useState } from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import { IAGrid } from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import { CommonUtil } from "../../utils/CommonUtil";
import OrgUserFieldRenderer from "../Organization/OrgUserFieldRenderer";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import { Constants } from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Events} from "../Languages/Events";

const OwnerServiceFree = () => {

    const feeGridRef = useRef<IAGrid>(null);

    const [owner, setOwner] = useState("");
    const [renter, setRenter] = useState("");
    const [cad, setCAD] = useState("");
    const [percent, setPercent] = useState("");
    const [claims, setClaims] = useState("");

    const [serviceFeeDta, setServiceFeeDta] = useStateWithCallback<any>([], () => {
        if (feeGridRef.current) {
            feeGridRef.current.columnFittoSize();
        }
    });
    const [blockOrgColumns] = useState([
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE, "Equipment Type"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'ownerServiceFee', headerName: CommonUtil.getMessageText(Constants.OWNER_SERVICE_FEE, "Owner Service Fee"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: OrgUserFieldRenderer }
    ]);
    const { isMobile } = DetectDevice();
    var priceArr: any[] = [
        { equipmentType: "Dry Van", ownerServiceFee: "20" },
        { equipmentType: "Plate Dry Van", ownerServiceFee: "30" },
        { equipmentType: "Storage Van", ownerServiceFee: "40" },
        { equipmentType: "Reefer", ownerServiceFee: "50" },
        { equipmentType: "Heated Van", ownerServiceFee: "60" },
        { equipmentType: "Flatbed", ownerServiceFee: "50" }
    ];

    useEffect(() => {
        setTimeout(() => {
            //setOrgs(priceArr);
            getOrgServiceFees();
            document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        })
    }, []);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            getOrgServiceFees();
        }
    }

    let getOrgServiceFees = () => {
        setServiceFeeDta([]);
        let listAPI: string = "api/organization/getServiceFees?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGetEquipmentTypePricing, onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj: any) => {
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                var orgs = [];
                setOwner(restData.ownerRentalPercentage);
                setRenter(restData.renterRentalPercentage);
                setCAD(restData.ownerRepositioningMinimumCost);
                setPercent(restData.ownerRepositioningPercentage);
                setClaims(restData.claimFeePercentage);

                if (restData.equipmentTypeServiceFees) {
                    if (Array.isArray(restData.equipmentTypeServiceFees)) {
                        orgs = restData.equipmentTypeServiceFees;
                    } else {
                        orgs.push(restData.equipmentTypeServiceFees);
                    }
                }

                setServiceFeeDta(orgs);
            }
        }

        setTimeout(() => {
            if (feeGridRef.current) {
                // feeGridRef.current.columnFittoSize();
            }
        }, 2000);
    }

    let saveDailyPrices = () => {
        var prices: any[] = [];
        var gridAPI: any = feeGridRef.current?.getGridAPI();
        if (gridAPI) {
            //console.log(priceGridRef.current?.getGridAPI());
            var rows: any[] = gridAPI.rowModel.rowsToDisplay;
            rows.forEach((item, idx) => {
                //console.log(item.data);
                prices.push(item.data);
            })
        }

        var reqObj: any = {};
        reqObj["claimFeePercentage"] = claims;
        reqObj["ownerRentalPercentage"] = owner;
        reqObj["renterRentalPercentage"] = renter;
        reqObj["ownerRepositioningMinimumCost"] = cad;
        reqObj["ownerRepositioningPercentage"] = percent;
        reqObj.equipmentTypeServiceFees = prices;

        var listAPI: string = "api/organization/saveServiceFees?saasOrgId=" + CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(listAPI, reqObj, "", "POST", true, onSaveServicePricing, onLoginError);
    }

    let onSaveServicePricing = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strUpdated = CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY, "Updated Successfully");
                ToastManager.showToast(strUpdated, "Info");
            }
        }
    }

    let onLoginError = (err: any) => {
        console.log(err);
    }

    return (
        <>
            <div className="container-fluid container-fluid-padding" style={{ background: "#f9f9f9" }}>
                <br />
                <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-3  mb-3 h-100">
                        <table className="owner-service-fees-table w-100" style={{ background: "#FFF" }}>
                            <tr>
                                <th colSpan={2} className="pl-4 pt-3" style={{ paddingLeft: "1.5rem" }}>{CommonUtil.getMessageText(Constants.RENTAL_SERVICE_FEE, "Rental Service Fee")}</th>
                            </tr>
                            <tr>
                                <td className="text-center pl-4 pr-4 pb-4"><p className="m-0">{CommonUtil.getMessageText(Constants.FOR_RENTER, "For Renter")}</p>
                                    <input type="form-control" className="service-input" value={renter} disabled={CommonUtil.isRootUser() ? false : true}
                                        onChange={(e) => { setRenter(e.currentTarget.value) }} />
                                    <span style={{ fontWeight: "bold" }} > %</span>
                                </td>
                                <td className="text-center pl-4 pr-4 pb-4"><p className="m-0">{CommonUtil.getMessageText(Constants.FOR_OWNER, "For Owner")}</p>
                                    <input type="form-control" className="service-input" value={owner} disabled={CommonUtil.isRootUser() ? false : true}
                                        onChange={(e) => { setOwner(e.currentTarget.value) }} /> <span style={{ fontWeight: "bold" }} > %</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6  mb-3 h-100">
                        <table className="owner-service-fees-table w-100" style={{ background: "#FFF" }}>
                            <thead>
                                <th colSpan={3} className="pl-4 pt-3" style={{ paddingLeft: "1.5rem" }}>{CommonUtil.getMessageText(Constants.REPOSITION_SERVICE, "Repositioning Service")}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="pl-4 pb-4" style={{ paddingLeft: "1.5rem" }}><p className="m-0" />{CommonUtil.getMessageText(Constants.VHUB_SERVICE_FEE, "The vHub Service fee is equal to the highest of")}
                                        <br />&nbsp; {CommonUtil.getMessageText(Constants.MIN_COST_AMOUNT, "(i) the minimum cost amount and")}<br />&nbsp; {CommonUtil.getMessageText(Constants.PERCENT_SERVICE_AMOUNT, "(ii) the percentage of the positioning service amount")} </td>
                                    <td className="text-center pb-4"><p style={{ marginBottom: "0px" }}>CAD</p>
                                        <input type="form-control" className="service-input" value={cad}
                                            disabled={CommonUtil.isRootUser() ? false : true} onChange={(e) => { setCAD(e.currentTarget.value) }}
                                            style={{ width: "60%" }} />
                                    </td>
                                    <td className="text-center pr-4 pb-4">
                                        <p style={{ marginBottom: "0px" }}>{CommonUtil.getMessageText(Constants.PERCENT, "Percent")}</p>
                                        <input type="form-control" className="service-input" value={percent}
                                            disabled={CommonUtil.isRootUser() ? false : true} onChange={(e) => { setPercent(e.currentTarget.value) }}
                                            style={{ width: "60%" }} /> <span style={{ fontWeight: "bold" }} > %</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 mb-3 h-100">
                        <table className="owner-service-fees-table w-100" style={{ background: "#FFF" }}>
                            <thead>
                                <tr>
                                    <th className="pl-4 pt-3" style={{ paddingLeft: "1.5rem" }}>{CommonUtil.getMessageText(Constants.CLAIM_SERVICE_FEE, "Claim Service Fee (%)")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center pl-4 pr-4 pb-4"><p style={{ marginBottom: "0px" }}>{CommonUtil.getMessageText(Constants.CLAIMS, "Claims")}</p>
                                        <input type="form-control" className="service-input" value={claims}
                                            disabled={CommonUtil.isRootUser() ? false : true} onChange={(e) => { setClaims(e.currentTarget.value) }} /> <span style={{ fontWeight: "bold" }} > %</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*<div className="row" style={{display:"none"}}>
                    <div className="col-md-3 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",width:"103%",height:"100%"}}>
                            <div className="col-md-12 renter-service" style={{height:"40px",borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Rental Service Fee (%)</span>
                            </div>
                            <div className="col-md-12" style={{display:"flex",marginTop:"-50px"}}>
                                <div className="col-md-6 service-box" style={{borderRight:"1px solid #0dcaf0"}}>
                                    <span className="clsCustProfDefPricingTxt">For Renter </span>
                                    <span className="clsCustProfDefPricingTxt fw-600 ptop-5">
                                        <input type="form-control" className="service-input" value={renter} disabled={CommonUtil.isRootUser()?false:true}
                                               onChange={(e)=>{setRenter(e.currentTarget.value)}} />
                                    </span>
                                </div>
                                <div className="col-md-6 service-box">
                                    <span className="clsCustProfDefPricingTxt ptop-5">For Owner </span>
                                    <input type="form-control" className="service-input" value={owner} disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e)=>{setOwner(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",width:"101%",height:"100%"}}>
                            <div className="col-md-12 service-box-height renter-service" style={{borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Repositioning Service</span>
                            </div>
                            <div className="col-md-12" style={{paddingLeft:"2px",display:"flex",height:"100px"}}>
                                <div className="col-md-6 service-box" style={{borderRight:"1px solid #0dcaf0",paddingRight:"5px",paddingLeft:"5px"}}>
                                    <span className="clsCustProfDefPricingTxt" style={{textAlign:"justify"}}>The vHub Service fee is equal to the highest of (i) the minimum cost amount and (ii) the percentage of the positioning service amount </span>
                                </div>
                                <div className="col-md-3 service-box" style={{borderRight:"1px solid #0dcaf0"}}>
                                    <span className="clsCustProfDefPricingTxt">CAD </span>
                                    <input type="form-control" className="service-input" value={cad} style={{paddingLeft:"10px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setCAD(e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-3 service-box">
                                    <span className="clsCustProfDefPricingTxt">Percent </span>
                                    <input type="form-control" className="service-input" value={percent} style={{paddingLeft:"10px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setPercent(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",height:"100%"}}>
                            <div className="col-md-12  renter-service" style={{height:"40px",borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Claim Service Fee (%)</span>
                            </div>
                            <div className="col-md-12" style={{display:"flex",marginTop:"-50px"}}>
                                <div className="col-md-12 service-box">
                                    <span className="clsCustProfDefPricingTxt">Claims </span>
                                    <input type="form-control" className="service-input" value={claims} style={{paddingLeft:"30px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setClaims(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className="row fs-7 pt-4">
                    <div className="col-md-12" style={{ paddingLeft: "0px", paddingBottom: "5px" }}>
                        <label style={{ display: "none" }}>Rental Service Fee(%) </label>
                    </div>
                    {
                        isMobile ? (
                            <MGrid id="CustOwnerServiceFeeGrid" cols={blockOrgColumns} rows={serviceFeeDta} pageSize={10}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={feeGridRef} />
                        ) : (
                            <div className="ag-theme-alpine gridHeight" style={{ paddingLeft: "0px", paddingRight: "0px", height: "calc(100vh - 420px)", width: "100%" }}>
                                <AGGrid id="CustOwnerServiceFeeGrid" cols={blockOrgColumns} rows={serviceFeeDta} pageSize={500}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={feeGridRef} />
                            </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="">
                        <div className="my-3 float-end">
                            <button style={{ display: "none" }} className="btn btn-outline-dark">Discard Changes</button>
                            &nbsp;&nbsp;
                            {CommonUtil.getSelectedOrgName() == "vHub Inc" ?
                                <button type="button" className="btn btn-success" onClick={saveDailyPrices}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>
                                : ""}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OwnerServiceFree;
