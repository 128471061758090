import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import useStateWithCallback from "use-state-with-callback";
import EquipDamageRenderer from "../Organization/EquipDamageRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import CustomDateRange from "../../Components/CustomDateRange";
import Flatpickr from "react-flatpickr";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Constants} from "../Languages/Constants";


var dateformat: object = {};
const EquipmentReservation = (props:any)=>{
    const { isMobile } = DetectDevice();
    const [equipDocs,setEquipData] = useStateWithCallback<any>([],()=>{});

    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(dateformat)

    const [eqpColumnDefs] = useState([
        {field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'equipmentRating', headerName: CommonUtil.getMessageText(Constants.RATING,"Rating"), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'scheduledStartDate', headerName: CommonUtil.getMessageText(Constants.START_DATE,"Start Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.END_DATE,"End Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTUAL_START_DATE,"Actual Start Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'actualEndDate', headerName: CommonUtil.getMessageText(Constants.ACTUAL_END_DATE,"Actual End Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'reservationStatus',headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"),resizable: true,width: 150,flex: 1,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const gridRef = useRef<IAGrid>(null);

    useEffect(()=>{
        //console.log(props);
       // getReservations();
        console.log("Reservations+5");
        getReservations();
    },[])

    let getReservations = ()=>{
        if(CommonUtil.SELECT_EQUIP_ID){
            let reservationAPI:string = "api/reservation/all";//+CommonUtil.SELECT_EQUIP_ID+"/history";
            let restObj:any = {};
            restObj.startDate = fromDate;
            restObj.equipmentId = CommonUtil.SELECT_EQUIP_ID;
            restObj.endDate = toDate;
            restObj.ownerOrgId = "";//CommonUtil.getSelectedOrgId();
            restObj.renterOrgId = "";
            NetworkManager.sendJsonRequest(reservationAPI,restObj,"","POST",true,onGetReservations,onLoginError);
        }
    }

    let onGetReservations = (dataObj:any)=>{
       // console.log(dataObj);
        let dataArray:any = [];
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    dataArray = restData;
                }else{
                    dataArray.push(restData);
                }
              }
            }

        setEquipData(dataArray);
    }

    let onChangeFromDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setFromDate(strDate);
        onChangeDates();
    }

    let onChangeToDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setToDate(strDate);
        onChangeDates();
    }

    let onChangeDates = ()=>{
        if(fromRef.current && toRef.current){
            var startDate = fromRef.current.flatpickr.selectedDates[0];
            var endDate = toRef.current.flatpickr.selectedDates[0];

            if (endDate) {
                endDate = new Date(endDate);
                fromRef.current.flatpickr.set("maxDate",endDate);
               // stDtOptions.maxDate = new Date(endDate);
            } else if (startDate) {
                toRef.current.flatpickr.set("minDate",new Date(startDate));
                //stDtOptions.minDate = new Date(startDate);
            }
        }

    }

    let onLoginError = (dataObj:any)=>{
        console.log(dataObj);
    }
    return(
        <>
            <div className="container">
               <section>
                   <div style={{display:"none"}} className="row">
                       <div className="col-sm-12"><p className="text-danger">
                           <i className="fa fa-exclamation-circle "></i> Upcoming or pending reservations are visible in the section <a href="#"><u>Availabilities</u></a>.</p>
                       </div>
                   </div>
                   <div style={{display:"none"}} className="row">
                       <div className="col-sm-3 col-6">
                           <div className="form-group row">
                               <label className="col-sm-12 col-form-label mb-0">From Date</label>
                               <div className="col-sm-12">
                                   <div className="input-group">
                                       <Flatpickr placeholder="From Date" ref={fromRef} options={dateOptions} onChange={onChangeFromDate}
                                                  className="form-control"  />
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="col-sm-3 col-6">
                           <div className="form-group row">
                               <label className="col-sm-12 col-form-label mb-0">To Date</label>
                               <div className="col-sm-12">
                                   <div className="input-group">
                                       <Flatpickr placeholder="To Date" ref={toRef} options={dateOptions} onChange={onChangeToDate}
                                                  className="form-control"  />
                                   </div>
                               </div>
                           </div>
                       </div>

                       <div className="col-sm-3 col-12">
                           <div className="form-group row">
                               <label className="col-sm-12 col-form-label mb-0 d-none d-sm-block">&nbsp;</label>
                               <div className="col-sm-12">
                                   <div className="input-group">
                                       <button type="submit" className="btn btn-warning waves-effect waves-light w-100"
                                       onClick={getReservations}>Search
                                       </button>
                                   </div>
                               </div>
                           </div>

                       </div>
                   </div>

                   <div className="row" style={isMobile ? {}:{paddingTop:"10px"}}>
                       {
                        isMobile ? (
                            <MGrid id="OrgEquipDamagesGrid" cols={eqpColumnDefs}
                                   rows={equipDocs} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                   rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                   ref={gridRef} addNew={false}
                           />
                        ) : (
                            <div className="ag-theme-alpine gridHeight"
                            style={{height: 'calc(100vh - 250px)', width: '100%', paddingLeft: '0px', paddingRight: '0px'}} >
                           <AGGrid id="OrgEquipDamagesGrid" cols={eqpColumnDefs}
                                   rows={equipDocs} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                   rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                   ref={gridRef} addNew={false}
                           />
                       </div>
                        )
                       }
                   </div>
               </section>
            </div>
        </>
    );
}

export default EquipmentReservation;
