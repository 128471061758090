import React from 'react'
import { NetworkManager } from '../../Components/NetworkManager';

const DownloadCSVFileRenderer = (params: any) => {

  const handleDownloadFile = async () => {

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    if (localStorage.getItem('token')) {
      var token = 'Bearer ' + localStorage.getItem('token')
      myHeaders.append('Authorization', token);
    }
    await fetch(NetworkManager.serverPath + `api/session/downloadUrlForCSV?objectPath=${params.data.filePath}`, { method: 'GET', headers: myHeaders })
      .then(response => response.text())
      .then(result => {
        window.open(result);
        let a = document.createElement('a');
        // a.href = url;
        a.download = `${params.data.filePath}.csv`;
        a.click();
      })
      .catch(error => console.log('error', error));

  }

  return (
    <div className={"cellRenderHeader"}>
      <button type="button" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={handleDownloadFile}><img src="../../images/download.svg" /></button>
    </div>
  )
}

export default DownloadCSVFileRenderer