import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import orgUserStatucRenderer from "../Organization/OrgUserStatucRenderer";
import OrgBlockStatusRenderer from "../Organization/OrgBlockStatucRenderer";
import ReactAutocomplete from "react-autocomplete";
import {Constants} from "../Languages/Constants";
import DeleteNetwork from "./deleteNetwork";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import $ from 'jquery'
import deleteInsurancePolicy from "./deleteInsurancePolicy";
import Confirm from "../../Components/confirm";
import BusyLoader from "../../Components/BusyLoader";
import insurDocRender from "./insurDocRender";
import {Events} from "../Languages/Events";
import { Console } from "console";
import ImportPrivateNetwork from "./impPrivate";
import ImportReservation from "./impReservation";

var selBlockOrg: any = null;
let insurPolicies:any = [];
let activePolicies:any = [];
let imagePath = "";
let fileType = "";
const BlockedOrgs = () => {
    const {isMobile} = DetectDevice();
    const [blockOrgList, setOrgs] = useStateWithCallback([], () => {
        if (userGridRef.current) {
            userGridRef.current.columnFittoSize();
        }
    });
    const [networkOrgList, setNetworkOrgs] = useStateWithCallback([], () => {
        if (networkGridRef.current) {
            networkGridRef.current.columnFittoSize();
        }
    });
    const [insurPlans, setInsurPlans] = useStateWithCallback([], () => {
        if (insurGridRef.current) {
            insurGridRef.current.columnFittoSize();
        }
    });
    const [blockOrgs, setBlockOrgs] = useState([]);
    const [networkOrgs, setNeOrgs] = useState([]);
    const [blockOrg, setBlockOrg] = useState<any>(null);
    const [blockNetWork, setBlockNetWork] = useState<any>(null);
    const [showBlockOrgPopup, setShowBlockOrgPopup] = useState(false);
    const [showBlockNetworkPopup, setShowBlockNetworkPopup] = useState(false);
    const [showInsurPolicyPopup, setShowInsurPolicyPopup] = useState(false);


    const [showUserStatusPopup, setShowUerStatusPopup] = useState(false);
    const [showNetworkRemove, setShowNetworkRemove] = useState(false);
    const [showNetwork, setShowNetwork] = useState(false);
    const [policyId,setPolicyId] = useState<any>(null);
    const userGridRef = useRef<IAGrid>(null);
    const networkGridRef = useRef<IAGrid>(null);
    const insurGridRef = useRef<IAGrid>(null);

    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const reasonRef = useRef<HTMLTextAreaElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);
    const statusRef = useRef<HTMLSelectElement>(null);

    const [firstName, setFirstName] = useState("");
    const [reason, setReason] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmailId] = useState("");
    const [contactNumber, setContactNumber] = useState("");

    const [departments, setDepartments] = useState(CommonUtil.onlyAdminDepartment);
    const [depObj, setDepObj] = useState<any>(null);

    const [statues, setStatues] = useState(CommonUtil.statuses);
    const [statusObj, setStatusObj] = useState<any>(null);
    const [polacies,setPolacies] = useState<any>([]);
    const [policyClass, setPolicyClass] = useState('mandatory');

    const [blockOrgClass, setBlockOrgClass] = useState("mandatory");

    const [platformFeesPaidBy, setPlatformFeesPaidByOwner] = useState<string>("Repositioner");//"Repositioner"|"Owner"|"Renter"
    const defaultPaidBy = "Repositioner";
    const [interchangeMenu, setIntechangeMenu] = useState(false)
    const [isInternlUser, setIsInternlUser] = useState(false)
    const [isInspectionCompany, setIsInspectionCompany] = useState(false)
    const [isAnyAvailable, setIsAnyAvailable] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState<any>();

    const [conditions, setConditions] = useState("");
    const [equipments, setEquipments] = useState("");
    const [value, setValue] = React.useState("");
    const [dotValue, setDotValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [bufferdays, setBufferDays] = React.useState<any>('');
    const [apiKey, setApiKey] = useState('');
    const [dotRemove, setDotRemove] = useState<any>({});
    const bufferRef = useRef<HTMLInputElement>(null);
    const dotRef = useRef<HTMLInputElement>(null);

    const [isInuranceChecked, setInsuranceChecked] = useState(false);
    const [isLiabilityChecked,setLiabilityChecked] = useState(false);
    const [isPhysicalDamageChecked,setPhysicalDamageChecked] = useState(false);

    const [isTwicChecked, setTwicChecked] = useState(false);
    const [isSendDailyInvoices, setIsSendDailyInvoices] = useState(false);
    const [invoiceEmail, setInvoiceEmail] = useState("");
    const [interchangeNotify, setInterchangeNotify] = useState(false);
    const [interchangeInvoiceEmail, setInterchangeInvoiceEmail] = useState("");

    const [insuranceMandatory, setinsuranceMandatory] = useState(false);
    const [insuranceMandatoryForRental, setInsuranceMandatoryForRental] = useState(false);

    const [isInuranceOwnerChecked, setInsuranceOwnerChecked] = useState(false);
    const [isInuranceRenterChecked, setInsuranceRenterChecked] = useState(false);
    const chkInsurance = useRef<HTMLInputElement>(null)
    const chkInterchange = useRef<HTMLInputElement>(null);
    const chkAnyAvailable = useRef<HTMLInputElement>(null);


    const chkOwnerInsurance = useRef<HTMLInputElement>(null);
    const chkRenterInsurance = useRef<HTMLInputElement>(null);
    const chkTwicAccess = useRef<HTMLInputElement>(null);
    const sendDailyCheck = useRef<HTMLInputElement>(null);
    const sendDailyCheckinput = useRef<any>(null);

    const sendInterchangeNotify = useRef<HTMLInputElement>(null);
    const sendInterchangeCheckinput = useRef<HTMLInputElement>(null);

    const fileref1 = useRef<HTMLInputElement>(null);

    const [fileNmae, setFileName] = useState('');
    const [seldoc,selDocument] = useState("");
    const [insurDocPath, setInsurDocPath] = useState<any>([]);
    const [page,setPage] = useState("");
    const [creditTerms,setCreditTerms] = useState<any[]>([])




    const [blockOrgColumns] = useState([
        {
            field: 'targetOrgName',
            headerName: CommonUtil.getMessageText(Constants.ORG_NAME, "Organization Name"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'notes',
            headerName: CommonUtil.getMessageText(Constants.REASON, "Reason"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'changedBy',
            headerName: CommonUtil.getMessageText(Constants.BLOCKED_BY, "Blocked By"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'changedDate',
            headerName: CommonUtil.getMessageText(Constants.BLOCKED_ON, "Blocked On"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.STATUS, "Status"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: OrgBlockStatusRenderer
        },
    ]);

    const [networkColumns] = useState([
        {
            field: 'networkName',
            headerName: CommonUtil.getMessageText(Constants.NETWORK_NAME, "DOT"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'orgName',
            headerName: CommonUtil.getMessageText(Constants.ORG_NAME, "Organization Name"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'changedBy',
            headerName: CommonUtil.getMessageText(Constants.CLOSED_BY, "Changed By"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'changedDate',
            headerName: CommonUtil.getMessageText(Constants.CLOSED_ON, "Changed On"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.STATUS, "Action"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: DeleteNetwork
        },
    ]);
    const [insuranceColumns,setInsuraceColumns] = useState([
        {
            field: 'policyId',
            headerName: CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, "Policy Id"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'insurancePlanType',
            headerName: CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE, "Insurance Plan Type"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'insuranceCompanyName',
            headerName: CommonUtil.getMessageText(Constants.INSURENCE_NAME, "Insurance Company Name"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            tooltip: (params: any) => params.value,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'document',
            headerName: CommonUtil.getMessageText(Constants.ATTACHED_DOCUMENT, "Document"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: insurDocRender
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.ACTION, "Action"),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: deleteInsurancePolicy
        },
    ]);
    const onGetAPIKey = (dataObj: any) => {
        let restData = JSON.parse(dataObj)
        if (restData) {
            setApiKey(restData.apiKey)
        }
    }

    const getApiKey = () => {
        NetworkManager.sendJsonRequest('api/organization/getApiKey?saasOrgId=' + CommonUtil.getSaasOrgId(), '', '', 'GET', true, onGetAPIKey, onLoginError)
    }


    const onGetGeneralConfig = (data: any, fff: any) => {
        let restData = JSON.parse(data);
        setConditions(restData.dnuConditions);
        setEquipments(restData.dnuEqpConditions);
        setBufferDays(restData.bufferTimeBetweenRentals);
        setTwicChecked((restData.twicAccessRequired === 'Y'?true:false));
        setIsSendDailyInvoices(restData.sendDailyInvoices === 'Y' ? true : false);
        setInvoiceEmail(restData.invoiceEmail||"");
        if(restData?.creditTermId)
        {           
            onGetCreditTermID(restData.creditTermId)    
        }

    }

    const onGetCreditTermID = (id:any)=>{
        NetworkManager.sendJsonRequest(`api/creditterms/${id}`, '', '', 'GET', true, (dataObj:any)=>{
            const data = JSON.parse(dataObj);
            console.log({key:data.creditTermId,value:data.creditDescription});
            setPaymentTerms({key:data.creditTermId,value:data.creditDescription})
        }, onLoginError)
        
    }
    const getGeneralConfig = () => {
        NetworkManager.sendJsonRequest('api/organization/getGeneralConfiguration?saasOrgId=' + CommonUtil.getSaasOrgId(), '', '', 'GET', true, onGetGeneralConfig, onLoginError)
    }

    const onGetInterchangeEmails = (data:any) => {
        let restData = JSON.parse(data);
        setInterchangeNotify(restData.interchangeNotificationEnbled === true ? true : false)
        setInterchangeInvoiceEmail(restData.interchangeEmails||"");
    }

    const getInterchangeEmails = () => {
        NetworkManager.sendJsonRequest('api/organization/getInterchangeExtData?saasOrgId=' + CommonUtil.getSaasOrgId(), '', '', 'GET', true, onGetInterchangeEmails, onLoginError)
    }

    useEffect(() => {
        setPlatformFeesPaidByOwner(defaultPaidBy)
        activePolicies = [];
        imagePath = "";
        fileType = CommonUtil.INSURANCE_CUST_DOC;
        showAllDetails();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);

    }, []);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            showAllDetails();
        }
        Events.dispatchBreadCrumbEvent();
    }

    let showAllDetails = ()=>{
        setTimeout(() => {
            insurPolicies = [];
            setOrgs([]);
            setNetworkOrgs([]);
            setInsurPlans([]);
            getOrgInsurancePlanList();
            getInterchangeMenuEnabled();
            getAvailableMenuEnabled();            

            if(!CommonUtil.isRootUser()){
                setInsuraceColumns([{
                    field: 'policyId',
                    headerName: CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, "Policy Id"),
                    resizable: true,
                    flex: 1,
                    width: 100,
                    sortable: true,
                    tooltip: (params: any) => params.value,
                    filter: 'agTextColumnFilter'
                },
                    {
                        field: 'insurancePlanType',
                        headerName: CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE, "Insurance Plan Type"),
                        resizable: true,
                        flex: 1,
                        width: 100,
                        sortable: true,
                        tooltip: (params: any) => params.value,
                        filter: 'agTextColumnFilter'
                    },
                    {
                        field: 'insuranceCompanyName',
                        headerName: CommonUtil.getMessageText(Constants.INSURENCE_NAME, "Insurance Company Name"),
                        resizable: true,
                        flex: 1,
                        width: 100,
                        sortable: true,
                        tooltip: (params: any) => params.value,
                        filter: 'agTextColumnFilter'
                    }]);
            }
        })
        // getAllOrgs();
        getOrgPartners();
        getNetworks();
        getOrgDefaultPrices();
        getApiKey();
        getGeneralConfig();
        getInsurancePlans();
        getPlatformFeePaidBy();
        getInterchangeEmails();
    }
    let getOrgDefaultPrices = () => {
        var listAPI: string = "api/organization/getEquipmentTypePricing?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGetEquipmentTypePricing, onLoginError);
    }
    let onGetEquipmentTypePricing = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    var orgs = [];
                    setConditions(restData.dnuConditions);
                    setEquipments(restData.dnuEqpConditions);

                }
            }
        }
    }

    const getPlatformFeePaidBy= () => {       
        let getAPI: string = `api/organization/getPlatformFeesPaidBy?saasOrgId=${CommonUtil.getSaasOrgId()}`;
        NetworkManager.sendJsonRequest(getAPI, "", "", "GET", true, (dataObj:any)=>{
            console.log(dataObj)
            if (dataObj) {
                var restData = JSON.parse(dataObj);
                if(restData.platformFeesPaidByRenter){
                    setPlatformFeesPaidByOwner("Renter")
                }else if(restData.platformFeesPaidByOwner){
                    setPlatformFeesPaidByOwner("Owner")
                }else {
                    setPlatformFeesPaidByOwner("Repositioner")
                }
            }
        }, onLoginError);
    }

    const onClicksavePlatformFeePaidBy= () => {
        const reqObj = {
            "platformFeesPaidByOwner": platformFeesPaidBy === "Owner",
            "platformFeesPaidByRenter": platformFeesPaidBy=== "Renter",
            "platformFeesPaiByRepositioner": platformFeesPaidBy === "Repositioner",
            "saasOrgId": CommonUtil.getSaasOrgId()
          }
        let saveAPI: string = "api/organization/savePlatformFeePaidBy";
        NetworkManager.sendJsonRequest(saveAPI, reqObj, "", "POST", true, (dataObj:any)=>{
            console.log(dataObj)
            if (dataObj) {
                var restData = JSON.parse(dataObj);
                if (restData.error) {
                    ToastManager.showToast(restData.error, "Error");
                }else{
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY, restData?.status||"Updated Successfully"), "Success");
                }
            }
        }, onLoginError);
    }

    let onClickSaveGeneralConfig = () => {
        let reqObj: any = {};
        reqObj["dnuConditions"] = conditions;
        reqObj["dnuEqpConditions"] = equipments;
        reqObj["bufferTimeBetweenRentals"] = bufferdays;
        reqObj["twicAccessRequired"] = isTwicChecked?"Y":"N";
        reqObj["sendDailyInvoices"] = isSendDailyInvoices?"Y":"N";
        reqObj["invoiceEmail"] = isSendDailyInvoices ? invoiceEmail : "";
        reqObj["creditTermId"] = paymentTerms?paymentTerms.key:"";
        
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // if(isSendDailyInvoices && !invoiceEmail.match(mailformat)){
        //     ToastManager.showToast("Please provide valid Invoices email", "Error");
        //     return;
        // }

         if(isSendDailyInvoices){
            const mails = invoiceEmail?.split(",");
            let flag:any = false;
            let invoiceNewEmails:any = [];
            mails.map(mail => {   
                invoiceNewEmails.push(String(mail).trim())
                if(!String(mail).trim().match(mailformat)){
                    ToastManager.showToast("Please provide valid Invoices email", "Error");
                    flag = true;
                }
            })   
            if(flag)
                return;    
            
            reqObj["invoiceEmail"] = isSendDailyInvoices ? invoiceNewEmails?.join(',') : "";
        }
       

        // let listAPI:string = "api/organization/saveEquipmentTypePricing?saasOrgId="+CommonUtil.getSaasOrgId();
        // NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onSaveEquipmentTypePricing,onLoginError);
        let listAPI: string = "api/organization/saveGeneralConfiguration?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, reqObj, "", "POST", true, onSaveGeneralConfig, onLoginError);
    }

    let onSaveGeneralConfig = (dataObj: any) => {
        console.log(dataObj)
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    getGeneralConfig();
                    window.scrollTo(0, 0)
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY, "Updated Successfully"), "Info");
                }
            }
        }
    }

    const onClickSaveInterchangeEmails = () => {
        let reqObj: any = {};
        reqObj["interchangeNotificationEnbled"] = interchangeNotify? true : false;
        reqObj["interchangeEmails"] = interchangeNotify ? interchangeInvoiceEmail : "";

        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(isSendDailyInvoices && !invoiceEmail.match(mailformat)){
            ToastManager.showToast("Please provide valid Invoices email", "Error");
            return;
        }

        let listAPI: string = "api/organization/saveInterchangeExtData?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, reqObj, "", "POST", true, onSaveInterchangeEmails, onLoginError);
    }

    const onSaveInterchangeEmails = (dataObj:any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    getGeneralConfig();
                    window.scrollTo(0, 0)
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY, "Updated Successfully"), "Info");
                }
            }
        }
    }

    let getAllOrgs = () => {
        NetworkManager.sendJsonRequest("api/organization/listNames", "", "", "GET", true, onGetListNames, onLoginError);
    }

    let getAllPaymentTerms = ()=>{
        NetworkManager.sendJsonRequest("api/creditterms/all", "", "", "GET", true, onGetCreditTerms, onLoginError);
    }

    let onGetCreditTerms = (dataObj:any)=>{
        var restData = JSON.parse(dataObj)
        console.log(restData)
        setCreditTerms(restData)
    }

    useEffect(()=>{
        getAllPaymentTerms()
    },[])

    console.log(creditTerms)


    let onGetListNames = (dataObj: any) => {
        //console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    var orgs = [];
                    if (Array.isArray(restData.organizations)) {
                        orgs = restData.organizations;
                    } else {
                        orgs.push(restData.organizations);
                    }
                    //setBlockOrgs(orgs);
                    var listOrgs: any = [];
                    orgs.forEach((item: any, idx: number) => {
                        if (item.orgId == CommonUtil.getSaasOrgId()) {
                        } else {
                            if (!isOrgIsBlocked(item.orgId)) {
                                listOrgs.push(item);
                            }
                        }
                    })
                    setBlockOrgs(listOrgs);
                }
            }
        }
    }

    let isOrgIsBlocked = (orgId: string) => {
        for (let i = 0; i < blockOrgList.length; i++) {
            let orgItem: any = blockOrgList[i];
            if (orgItem && orgItem.targetOrgId == orgId) {
                return true;
            }
        }
        return false;
    }

    let onClickUserDetails = (userData: any) => {
        //console.log(userData);
    }

    let onClickBlockOrgDetails = (data: any) => {
        //console.log(data);
        selBlockOrg = data;
        setShowUerStatusPopup(true);
    }
    let onClickNetworkDetails = (userData: any) => {
        //console.log(userData);
    }

    let onClickUnBlockOrg = () => {
        // console.log(selBlockOrg);
        if (selBlockOrg) {
            var restObj: any = {};
            restObj.notes = selBlockOrg.notes;
            // restObj.targetOrgId = blockOrg.orgId;
            restObj.targetOrgId = selBlockOrg.targetOrgId;
            restObj.targetOrgName = selBlockOrg.targetOrgName;
            restObj.partnerStatus = "Unblock";
            restObj.ownerOrgId = CommonUtil.getSaasOrgId();

            var url: string = "api/organization/changeOrganizationPartnerStatus?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(url, restObj, "", "POST", true, onGetOrganizationPartnerUnblock, onLoginError);
        }

    }

    let onGetOrganizationPartnerUnblock = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    setShowNetwork(false)
                    setShowUerStatusPopup(false);
                    getOrgPartners();
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.ORG_UNBLOCK_SUCCESS, "Organization Unblocked Successfully"), "Info");
                }
            }
        }
    }

    let getOrgPartners = () => {
        var listAPI: string = "api/organization/getOrganizationPartners?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGeAllBlockOrgs, onLoginError);
    }
    let getNetworks = () => {
        var listAPI: string = "api/organization/getOrganizationCloseNetworks?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGeAllNetworks, onLoginError);
    }

    let onGeAllBlockOrgs = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    var orgs = [];
                    if (Array.isArray(restData.organizationPartners)) {
                        orgs = restData.organizationPartners;
                    } else {
                        orgs.push(restData.organizationPartners);
                    }
                    setOrgs(orgs);
                    //setBlockOrgs(orgs);
                }
            }
        }
    }
    let onGeAllNetworks = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    var orgs = [];
                    if (Array.isArray(restData.organizationCloseNetworks)) {
                        orgs = restData.organizationCloseNetworks;
                    } else {
                        orgs.push(restData.organizationCloseNetworks);
                    }
                    setNetworkOrgs(orgs);
                }
            }
        }
    }
    let deleteDotNetwork = (data: any) => {
        setShowNetworkRemove(true)
        setDotRemove(data)
    }
    const confirmRemoveDot = (data: any) => {
        let restObj = {
            networkName: data.networkName,
            networkOrgId: data.networkOrgId,
            orgCloseNetworkId: data.orgCloseNetworkId,
            orgName: data.orgName
        };
        var url: string = "api/organization/deleteOrganizationCloseNetwork?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(url, restObj, "", "POST", true, onDeleteNetwork, onLoginError);
    }
    let onClickBlockUser = () => {
        setReason("");
        setBlockOrg(null);
        getAllOrgs();
        setShowBlockOrgPopup(true);
    }
    let onClicNetworkAdd = () => {
        setReason("");
        setBlockNetWork(null);
        setShowBlockNetworkPopup(true);
    }
    let onCloseBlockOrgPopup = () => {
        setShowNetworkRemove(false)
        setValue('');
        setDotValue('');
        setOpen(false);
        setShowBlockOrgPopup(false);
        setShowBlockNetworkPopup(false);
    }
    let onCloseUserStausPopup = () => {
        setValue('')
        setOpen(false)
        setShowUerStatusPopup(false);
    }

    let closeNetworkModals = () => {
        setValue('');
        setDotValue('');
        setOpen(false);
        setShowNetwork(false);
        setShowBlockNetworkPopup(false);
    }

    let onChangeInsuranceOwner = (e: any)=>{
        setInsuranceOwnerChecked(e.currentTarget.checked);
    }

    let onChangeInsuranceRenter = (e:any)=>{
        setInsuranceRenterChecked(e.currentTarget.checked);
    }

    let onChangeTwicAccess = (e:any)=>{
        setTwicChecked(e.currentTarget.checked);
    }

    let onChangeLiability = (e:any)=>{
        setLiabilityChecked(e.currentTarget.checked);
    }
    let onChangePhysicalDamage = (e:any)=>{
        setPhysicalDamageChecked(e.currentTarget.checked);
    }

    let onChangeInsurance = (e: any) => {
        setInsuranceChecked(e.currentTarget.checked);
        setInsuranceOwnerChecked(false);
        setInsuranceRenterChecked(false);
        setLiabilityChecked(false);
        setPhysicalDamageChecked(false);
        setInsuranceMandatoryForRental(false);
        setinsuranceMandatory(false);
       // onChangeInsuranceRenter(false);

        $("#divInsurOwner").hide();
        $("#divInsurRenter").hide();
        $("#divInsurGrid").hide();
        $("#divLiability").hide();
        $("#divPhysicalDamage").hide();
        $("#divManInsurReposition").hide();
        $("#divManInsurRenter").hide();

        if(e.currentTarget.checked){
            $("#divInsurOwner").show();
            $("#divInsurRenter").show();
            $("#divInsurGrid").show();
            $("#divLiability").show();
            $("#divPhysicalDamage").show();
            $("#divManInsurReposition").show();
            $("#divManInsurRenter").show();
        }
    }

    let onClickBlockOrg = () => {
        if (blockOrg && reason) {
            var restObj: any = {};
            restObj.notes = reason;
            // restObj.targetOrgId = blockOrg.orgId;
            restObj.targetOrgId = blockOrg.orgId;
            restObj.targetOrgName = blockOrg.orgName;
            restObj.partnerStatus = "Block";
            restObj.ownerOrgId = CommonUtil.getSaasOrgId();

            var url: string = "api/organization/changeOrganizationPartnerStatus?saasOrgId=" + CommonUtil.getSaasOrgId();
            ;
            NetworkManager.sendJsonRequest(url, restObj, "", "POST", true, onGetOrganizationPartnerStatus, onLoginError);
        }

    }
    let onClickAddOrg = () => {
        CommonUtil.isMandatory(dotRef);
        let find = networkOrgList.find((dot: any) => dot.networkName === dotValue);
        if (find) {
            return ToastManager.showToast(CommonUtil.getMessageText(Constants.DOT_ALREADY_EXIST, 'DOT already exits'), 'Info');
        }
        if (dotValue) {
            var restObj: any = {};
            restObj.networkName = dotValue;
            restObj.networkOrgId = "";
            restObj.orgCloseNetworkId = "";
            restObj.orgName = "Network";

            var url: string = "api/organization/addOrganizationCloseNetwork?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(url, restObj, "", "POST", true, onGetNetworkStatus, onLoginError);
        }
        //  else {
        //     dotRef.current?.classList.add('is-invalid')
        // }
    }
    let onGetOrganizationPartnerStatus = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    onCloseBlockOrgPopup();
                    getOrgPartners();
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.ORG_BLOCK_SUCCESS, 'Organization Blocked Successfully'), "Info");
                }
            }
        }
    }
    let onGetNetworkStatus = (dataObj: any) => {
        // console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode === 400) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast("Invalid DOT number OR no organization exists with this DOT number.", "Error");
                } else {
                    onCloseBlockOrgPopup();
                    getNetworks();
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.DOT_ADDED_NETWORK, 'DOT Network added'), "Info");
                }
            }
        }
    }

    let onDeleteNetwork = (dataObj: any) => {
        // console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    onCloseBlockOrgPopup();
                    onGeAllNetworks(dataObj)
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.DOT_REMOVED_NETWORK, 'DOT Network removed Successfully'), "Info");
                }
            }
        }
    }




    let onLoginError = (err: any) => {
        console.log(err);
    }
    const onChangeText = (e: any) => {
        if (e.target.value.length > 0) {
            setOpen(true);
        } else if (e.target.value.length === 0) {
            setOpen(false);
        }
        setValue(e.target.value);
    };
    const onSelect = (value: any, item: any) => {
        setOpen(false);
        setValue(value);
        setBlockOrg(item);
    };
    const onChangeDot = (e: any) => {
        CommonUtil.isMandatory(dotRef);
        setDotValue(e.currentTarget.value)
    }

// const onSelectNetwork = (value:any, item:any) => {
//     setOpen(false);
//     setValue(value);
//     setBlockNetWork(item);
// };
    const renderItem = (item: any, highlighted: any) => (
        <div
            key={item.orgId}
            style={{backgroundColor: highlighted ? "lightblue" : "transparent"}}>            {item.orgName}
        </div>)
    const menustyle: any = {
        top: 27,
        left: 0,
        position: 'absolute',
        zIndex: 999,
        background: '#f5f5f5',
        height: "auto",
        maxHeight: '215px',
        overflowY: 'scroll',
        borderRadius: 8,
        padding: 5,
        width: "100%",
        boxShadow: '35px 35px 70px #e4e4e4 -35px -35px 70px #ffffff'
    }
    let handleInputBuffer = (e: any) => {
        setBufferDays(e.target.value);
        CommonUtil.isMandatory(bufferRef);
    }

    let closeInsurPolicyPopup = ()=>{
        setShowInsurPolicyPopup(false);
    }

    const generateAPIKey = () => {
        NetworkManager.sendJsonRequest('api/organization/generateApiKey?saasOrgId=' + CommonUtil.getSaasOrgId(), '', '', 'POST', true, getApiKey, onLoginError)
    }
    let onClckAddInsurPolicy = () => {
        let flag = true;

        let polacyList = [];
        let j=0;
        for(var i=0;i<activePolicies.length;i++){
            let polObj:any = activePolicies[i];
            if(isPhysicalDamageChecked && !isLiabilityChecked){
                if(activePolicies[i].insurancePlanType == CommonUtil.PHYSICAL_DAMAGE) {
                    flag = false;
                    for (j = 0; j < insurPolicies.length; j++) {
                        if (polObj.policyId == insurPolicies[j].policyId) {
                            flag = true;
                            break;
                        }
                    }
                    if(!flag){
                        polacyList.push(polObj);
                    }
                }
            }else if(isLiabilityChecked && !isPhysicalDamageChecked){
                if(polObj.insurancePlanType == CommonUtil.LIABILITY) {
                    flag = false;
                    for (j = 0; j < insurPolicies.length; j++) {
                        if (polObj.policyId == insurPolicies[j].policyId) {
                            flag = true;
                            break;
                        }
                    }
                    if(!flag){
                        polacyList.push(polObj);
                    }
                }

            }else if(isLiabilityChecked && isPhysicalDamageChecked){
                flag = false;
                for(j=0;j<insurPolicies.length;j++){
                    if(polObj.policyId == insurPolicies[j].policyId){
                        flag = true;
                        break;
                    }
                }
                if(!flag){
                    polacyList.push(polObj);
                }
            }


        }

        setPolacies(polacyList);
        selDocument("");
        // console.log(polacies);
        setShowInsurPolicyPopup(true);
    }

    let getInsurancePlans = () => {
        let equipAllAPI: string = 'api/insurance/allPolicyWithRatePlans';
        let obj: any = {};
        activePolicies = [];
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, (dataObj:any)=> {
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj)
                if (restData) {
                    if (Array.isArray(restData)) {
                        activePolicies = restData;
                    } else {
                        activePolicies.push(restData)
                    }
                }
            }
            activePolicies.forEach((item:any,idx:number)=>{
                item.policyName = item.policyId+"("+item.insurancePlanType+")";
            })
        },onLoginError);
    }
    let onGetAllPlacies = (dataObj:any)=>{
        console.log(dataObj);

    }
    let onChangePolicy = (data: any) => {
        setPolicyId(data);
        (data) ? setPolicyClass('select-box-valid') : setPolicyClass('select-box-invalid');
    }
    let closeInsurancePopup = ()=>{
        setShowInsurPolicyPopup(false);
    }
    let onClickAdInsurance = ()=>{
        if(policyId && seldoc){
            policyId.fullPath = imagePath;
            policyId.documentName = (fileref1&&fileref1.current&&fileref1.current.files)?fileref1.current.files[0].name:"";
            policyId.fullPath = imagePath;
            insurPolicies.push(policyId);
            // console.log(insurPolicies);
            setInsurPlans([]);
            setTimeout(()=>{
                setInsurPlans(insurPolicies);
                setShowInsurPolicyPopup(false);
                setPolicyId(null);
            })

        }
    }
    let onClickSaveCustomerInsurance = ()=>{
        var reqObj:any = {};
        let errorMessage:string = "";
        let lMsg:string = "";

        if(isInuranceChecked){
            if(!isLiabilityChecked && !isPhysicalDamageChecked){
                errorMessage = "Please select the Insurance Type";
                lMsg = CommonUtil.getMessageText(Constants.PLEASE_SELECT_INSURANCE_TYPE, errorMessage)
                ToastManager.showToast(lMsg, "Error");
            }else if(!isInuranceOwnerChecked && !isInuranceRenterChecked){
                errorMessage = "Please select the Insurance will be paid by the Owner or Insurance will be paid by the Renter or both options";
                lMsg = CommonUtil.getMessageText(Constants.ERROR_INSURANCE_MSG, errorMessage)
                ToastManager.showToast(lMsg, "Error");
            }else if(insurPolicies.length == 0){
                errorMessage = "Please add the insurance policies";
                lMsg = CommonUtil.getMessageText(Constants.ERROR_INSURANCE_POLICY, errorMessage)
                ToastManager.showToast(lMsg, "Error");
            }else if(isInuranceChecked && (isInuranceOwnerChecked || isInuranceRenterChecked) && insurPolicies.length>0){

                let isPhysicalDamageExist = false;
                let isLiabilityExist = false;
                if(!isPhysicalDamageChecked){
                    isPhysicalDamageExist = isInsuranceTypeExist(CommonUtil.PHYSICAL_DAMAGE);
                }
                if(!isLiabilityChecked){
                    isLiabilityExist = isInsuranceTypeExist(CommonUtil.LIABILITY);
                }
                if(!isPhysicalDamageExist && !isLiabilityExist){
                    reqObj.isInsuranceEnabled = isInuranceChecked?"Y":"N";
                    reqObj.isInsurancePaidByOwner = isInuranceOwnerChecked?"Y":"N";
                    reqObj.isInsurancePaidByRenter = isInuranceRenterChecked?"Y":"N";
                    reqObj.isPhysicalDamageEnabled = isPhysicalDamageChecked?"Y":"N";
                    reqObj.isLiabilityEnabled = isLiabilityChecked?"Y":"N";

                    // reqObj.insuranceMandatoryForRental = insuranceMandatoryForRental?"Y":"N";
                    reqObj.insuranceMandatory = insuranceMandatory?"Y":"N";

                    let plans:any = [];
                    for(let j=0;j<insurPolicies.length;j++){
                        plans.push({"policyId":insurPolicies[j].id,"documentName":insurPolicies[j].documentName,"fullPath":insurPolicies[j].fullPath,documentCategory:fileType});
                    }
                    reqObj.policies = plans;

                    let equipAllAPI: string = 'api/insurance/saveOrgInsurance?orgId='+CommonUtil.getSelectedOrgId();
                    let obj: any = {};
                    NetworkManager.sendJsonRequest(equipAllAPI, reqObj, '', 'POST', true,onSaveInsurPolicy,onLoginError);
                }else{
                    errorMessage = "Please remove the non-relevant plans from the grid";
                    lMsg = CommonUtil.getMessageText(Constants.NOT_RELEVANT_PLANS, errorMessage)
                    ToastManager.showToast(lMsg, "Error");
                }

            }
        }else{
            reqObj.isInsuranceEnabled = "N";
            reqObj.isInsurancePaidByOwner = "N";
            reqObj.isInsurancePaidByRenter = "N"
            reqObj.isPhysicalDamageEnabled = "N"
            reqObj.isLiabilityEnabled = "N"
            let plans:any = [];
            /*for(let j=0;j<insurPolicies.length;j++){
                plans.push(insurPolicies[j].id);
            }*/
            reqObj.policyId = plans;

            let equipAllAPI: string = 'api/insurance/saveOrgInsurance?orgId='+CommonUtil.getSelectedOrgId();
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipAllAPI, reqObj, '', 'POST', true,onSaveInsurPolicy,onLoginError);
        }

    }

    let isInsuranceTypeExist = (poliycType:string)=>{
        for(let j=0;j<insurPolicies.length;j++){
            if(insurPolicies[j].insurancePlanType == poliycType){
                return true;
            }
        }
        return false;
    }

    let onClickSaveAnyAvailable = ()=>{
        let flag = isAnyAvailable?"Y":"N";
        let availableAPI: string = "api/organization/anyAvailbleEnabled?enableAnyAvaible="+flag+"&saasOrgId="+CommonUtil.getSelectedOrgId();;
        let obj: any = {};
        NetworkManager.sendJsonRequest(availableAPI, '', '', 'POST', true,onSaveAvailableModule,onLoginError);
    }

    let onSaveAvailableModule = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_ANY_AVAILABLE_SUCCESS, 'Successfully enabled any available'), "Info");
            }
        }
    }

    let onClickSaveSaveInterchangeMenu = ()=>{
        let flag = interchangeMenu?"Y":"N";
        let isIUser = isInternlUser?"Y":"N";
        let isICompany = isInspectionCompany?"Y":"N";
        let interchangeAPI: string = `api/organization/interchangeMenuEnabled?interchangeMenu=${flag}&isInternlUser=${isIUser}&isInspectionCompany=${isICompany}&saasOrgId=${CommonUtil.getSelectedOrgId()}`;
        let obj: any = {};
        NetworkManager.sendJsonRequest(interchangeAPI, '', '', 'POST', true,onSaveInterchangeMenu,onLoginError);
    }

    let onSaveInterchangeMenu = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_INTERCHANGE_SUCCESS, 'Successfully updated interchange module'), "Info");
            }
        }
    }
    let onSaveInsurPolicy = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast(CommonUtil.getMessageText(Constants.GEN_CONFIG_INSUR_SAVED, 'Insurance Policies are Updated Successfully'), "Info");
            }
        }
    }
    let getInterchangeMenuEnabled = ()=>{
        let interchangeAPI: string = 'api/organization/interchangeMenuEnabled?saasOrgId='+CommonUtil.getSelectedOrgId();
        let obj: any = {};
      //  http://localhost:8080/api/organization/interchangeMenuEnabled?saasOrgId=45
        NetworkManager.sendJsonRequest(interchangeAPI, '', '', 'GET', true,onGetInterchangeMenuEnabled,onLoginError);
    }

    let onGetInterchangeMenuEnabled = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                setIntechangeMenu(restData.isInterchangeMenuEnabled == "Y"?true:false);
                setIsInternlUser(restData.isInternlUser == "Y"?true:false);
                setIsInspectionCompany(restData.isInspectionCompany == "Y"?true:false);
                setPage(""+Math.random()*100000);
            }
        }
    }

    let getAvailableMenuEnabled = ()=>{
        let avalAPI: string = 'api/organization/anyAvailbleEnabled?saasOrgId='+CommonUtil.getSelectedOrgId();
        let obj: any = {};
        //  http://localhost:8080/api/organization/interchangeMenuEnabled?saasOrgId=45
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true,onGetAnyAvailableEnabled,onLoginError);
    }

    let onGetAnyAvailableEnabled = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                setIsAnyAvailable(restData.isEnableAnyAvailble == "Y"?true:false);
                //setPage(""+Math.random()*100000);
            }
        }
    }


    let getOrgInsurancePlanList = ()=>{
        let equipAllAPI: string = 'api/insurance/orgPolicies?orgId='+CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,onGetOrgInsurancePolicy,onLoginError);
    }

    let onGetOrgInsurancePolicy = (dataObj:any)=>{
        insurPolicies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsuranceChecked((restData.isInsuranceEnabled == "Y"?true:false));
                setInsuranceOwnerChecked((restData.isInsurancePaidByOwner == "Y"?true:false));
                setInsuranceRenterChecked((restData.isInsurancePaidByRenter == "Y"?true:false));
                setLiabilityChecked((restData.isLiabilityEnabled == "Y"?true:false));
                setPhysicalDamageChecked((restData.isPhysicalDamageEnabled == "Y"?true:false));

                // setInsuranceMandatoryForRental((restData.insuranceMandatoryForRental == "Y"?true:false));
                setinsuranceMandatory((restData.insuranceMandatory == "Y"?true:false));

                if(restData.isInsuranceEnabled == "Y"){
                    $("#divInsurOwner").show();
                    $("#divInsurRenter").show();
                    $("#divInsurGrid").show();
                    $("#divLiability").show();
                    $("#divPhysicalDamage").show();
                    $("#divManInsurReposition").show();
                    $("#divManInsurRenter").show();
                }
                if(restData.organizationInsuranceAssociation){
                    let arr = [];
                    if(Array.isArray(restData.organizationInsuranceAssociation)){
                        arr = restData.organizationInsuranceAssociation;
                    }else{
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item:any,idx:number)=>{
                        item.policyName = item.policyId+"("+item.insurancePlanType+")";
                        insurPolicies.push(item);
                    })
                }
            }
        }

        setInsurPlans(insurPolicies);
    }


    let deleteInsurPolicy = (data:any)=>{
        console.log(data);
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE_POLICY,'Do you want remove policy?'), [{label: 'Yes', callback: function () {
                if (data && data.policyId) {
                    let plans:any = [];
                    for(let j=0;j<insurPolicies.length;j++){
                        if(insurPolicies[j].policyId != data.policyId){
                            plans.push(insurPolicies[j]);
                        }
                    }
                    setInsurPlans([]);
                    setTimeout(()=>{
                        setInsurPlans(plans);
                        insurPolicies = plans;
                    })
                }
            },
        },
            {label: 'No'},
        ])
    }

    let onSelectFiles1 = (e: any) => {
        // console.log(e)
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name;
            if(fileName?.length > 64){
                ToastManager.showToast("Uploaded file name is too long", 'Error');
                return;
            }
            setFileName(fileName);
            selDocument(fileName);
            BusyLoader.showLoader()
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            imagePath = 'documents/insurance/customer/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime();
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }
    const onUploadImage = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader()
                var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
                ToastManager.showToast(errMsg, 'Error')
            } else {
                var strUploadPath = dataObj;
                //if (fileType == CommonUtil.INSURANCE_TERMS_CONDITIONS || fileType == CommonUtil.INSURANCE_BROUCHER) {
                    if (fileref1.current && fileref1.current.files) {
                        const formData = new FormData();
                        var myHeaders = new Headers();
                        var file = fileref1.current.files[0];
                        var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                        fetch(strUploadPath, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                //BusyLoader.hideLoader();
                                uploadInsuranceDocs();
                            })
                            .catch((error) => {
                                //  BusyLoader.hideLoader();
                                console.log('error', error)
                            })
                    }
                /*}else{

                }*/

            }
        }
    }

    let uploadInsuranceDocs = ()=>{
        if ((fileref1.current && fileref1.current.files && fileref1.current.files[0])) {
            let reqObj: any = {};
            // reqObj.insuranceAttachmentDTO = {};
            let obj: any = {};
            if(fileref1.current && fileref1.current.files){
                obj.documentName = fileref1.current.files[0].name;
            }
            //restObj.documentName = fileref.current.files[0].name;
           // restObj.documentType = fType;
            //restObj.fullFilePath = "documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileref.current.files[0].name;;//"documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileref.current.files[0].name;
           // restObj.saasOrgId = CommonUtil.getSaasOrgId();

            obj.category = fileType;
            obj.documentType = fileType;
            obj.Type = fileType;
            obj.fullFilePath = imagePath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            //obj.policyId = insurancePolacyId;
            obj.insuranceAttachmentId = "";
            let arr: any = [];
            arr.push(obj);
            reqObj.insuranceAttachmentDTO = arr;
            BusyLoader.hideLoader();

           // let uploadUrl: string = "api/organization/updateFinanceAndInsuranceForms?saasOrgId=" + CommonUtil.getSaasOrgId();
            //NetworkManager.sendJsonRequest(uploadUrl, obj, "", "PUT", false, onUpdateDocument, onLoginError);
        }
    }
    let onUpdateDocument = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData.statusCode) {
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(restData.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {
                ToastManager.showToast("Document uploaded successfully", "Info");
            }
        } else {
            ToastManager.showToast("Document uploaded successfully", "Info");
        }
        //getCustomerInsuranceDetails();
        BusyLoader.hideLoader();
    }
    let getCustomerInsuranceDetails = ()=>{
        let financeAPI: string = "api/organization/finance/getFinanceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(financeAPI, "", "", "GET", true, onGetCustomerInsuranceDetails, onLoginError);
    }

    let onGetCustomerInsuranceDetails = (dataObj:any)=>{
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {

                    let docs = [];
                    if (restData.documents) {
                        if (Array.isArray(restData.documents)) {
                            docs = restData.documents;
                        } else {
                            docs.push(restData.documents);
                        }
                        setInsurDocPath(docs);
                        //setAttachDocs(docs);
                    }
                }
            }
        }
    }
    let onClickBrowse = () => {
        if (fileref1.current) {
            fileref1.current.value = ''
            $('#inputGroupFile1').click()
        }
        setFileName('')
    }
    let onDownloadDoc = (data:any)=>{
        console.log(data);
        if(data && data.fullPath){
            if (data.fullPath) {
                var qParam: string = "objectPath=" + data.fullPath;
                NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
            }
        }
    }

    /*let onDownloadDoc = (filePath:string)=>{
        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }*/
    let onDownloadFP2506Success = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }
    let onRemoveDoc = (attachId: string) => {
        //console.log(attachId)
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE,'Do you want remove document?'), [{label: 'Yes', callback: function () {
                if (attachId) {
                    //fileAttachmentId = attachId;
                    let removeAPI: string = "api/organization/deleteFinanceAndInsuranceForms?attachmentId=" + attachId + "&saasOrgId=" + CommonUtil.getSaasOrgId();
                    //console.log(removeAPI)
                    NetworkManager.sendJsonRequest(removeAPI, '', '', 'DELETE', true, onDeleteInsuranceDoc, onLoginError);
                }
            },
        },
            {label: 'No'},
        ])
    }
    let onDeleteInsuranceDoc = (dataObj:any)=>{
        let flag: boolean = true;
        if (dataObj) {
            //  var restData = JSON.parse(dataObj);
            if (dataObj.statusCode) {
                flag = false;
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {

            }
        }
        if (flag) {
            ToastManager.showToast("File Removed Successfully", "Info");
            getCustomerInsuranceDetails();
        }


    }

    const handlePaymentStatus = (dataObj:any)=>{
        setPaymentTerms(dataObj);
        console.log(dataObj);
        
    }

    return (
        <>
            <Modal show={showUserStatusPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px", "height": "200px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{"Unblock Organization"}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={onCloseUserStausPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <span
                                className="dark-text fs-7">{CommonUtil.getMessageText(Constants.UNBLOCK_ORG, 'This action will allow the user to Unblock the Organization')}</span>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={onCloseUserStausPopup}>{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
                        <button type="button" className="btn btn-success"
                                onClick={onClickUnBlockOrg}>{CommonUtil.getMessageText(Constants.UNBLOCK, 'Unblock')}</button>
                    </div>
                </div>

            </Modal>

            <Modal show={showBlockOrgPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "550px", "height": "500px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.BLOCK_ORGANIZATION, 'Block Ogranization')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={onCloseBlockOrgPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.ORG_NAME, 'Organization')}</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">

                                    {/* <SingleSelectComp  dataSource={blockOrgs} selectedValue={blockOrg}
                                                      handleChange={onOrgChange} value={"orgId"} label={"orgName"} className={blockOrgClass} /> */}
                                    <ReactAutocomplete
                                        open={open}
                                        items={blockOrgs}
                                        shouldItemRender={(item: any, value: any) => {
                                            return item.orgName.toLowerCase().indexOf(value.toLowerCase()) > -1
                                        }
                                        }
                                        getItemValue={(item: any) => item.orgName}
                                        wrapperStyle={{
                                            position: 'relative',
                                            width: '100%',
                                        }} inputProps={{
                                        className: `${blockOrgClass} custom-select rounded`,
                                        style: {
                                            width: '100%',
                                            height: "35px"
                                        },
                                    }}
                                        menuStyle={menustyle}
                                        renderMenu={(items, value) => (
                                            <div
                                                style={menustyle}>{items.length === 0 ? `No matches for ${value}` : items}</div>
                                        )}
                                        renderItem={renderItem}
                                        value={value}
                                        onChange={onChangeText}
                                        onSelect={(value, item) => {
                                            onSelect(value, item)
                                        }}/>

                                </div>
                            </div>
                        </div>
                        <div className="row mt-1 fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.REASON, 'Reason')}</label> <span
                                className="clsFormMandatory"></span>
                                <textarea ref={reasonRef} style={{height: "200px"}}
                                          className="form-control clsStandardText  clsFormInputControl"
                                          placeholder="Enter Reason" aria-label="Email" value={reason}
                                          onChange={(e) => {
                                              setReason(e.currentTarget.value);
                                              CommonUtil.isMandatory(reasonRef);
                                          }}/>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={onCloseBlockOrgPopup}>{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-success"
                                onClick={onClickBlockOrg}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}</button>
                    </div>
                </div>

            </Modal>
            <Modal show={showNetworkRemove} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px", "height": "200px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.REMOVE_NETWORK, 'Remove Network')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                            setShowNetworkRemove(false)
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <span
                                className="dark-text fs-7">{CommonUtil.getMessageText(Constants.ACTION_REMOVE_NETWORK, 'This action will remove Network')}</span>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={() => {
                                    setShowNetworkRemove(false)
                                }}>{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
                        <button type="button" className="btn btn-success" onClick={() => {
                            confirmRemoveDot(dotRemove)
                        }}>{CommonUtil.getMessageText(Constants.REMOVE, 'Remove')}</button>
                    </div>
                </div>

            </Modal>
            <Modal show={showBlockNetworkPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "550px", "height": "auto"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.PRIVATE_NETWORK, 'Private Network')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={closeNetworkModals}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.DOT_NUMBER, 'DOT Number')}</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">
                                    <input name="dotnumber" ref={dotRef} onChange={onChangeDot} value={dotValue}
                                           className={"form-control mandatory"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={closeNetworkModals}>{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-success"
                                onClick={onClickAddOrg}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}</button>
                    </div>
                </div>

            </Modal>

            <Modal show={showInsurPolicyPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "550px", "height": "auto"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.INSURANCE_POLICY_LIST, 'Insurance Plans')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={closeInsurancePopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.INSURANCE_PLAN_NAME, 'Insurance Plan')}</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">
                                    <SingleSelectComp dataSource={polacies} selectedValue={policyId} width={"100%"} value={"policyId"} label={"policyName"}
                                                      handleChange={onChangePolicy} className={policyClass} />
                                </div>
                            </div>
                        </div>
                        <div className="row fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.DOCUMENT, 'Select Document')}</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">
                                    <input  type="text" className="form-control clsFormInputControl" value={seldoc} disabled={true}/>
                                    &nbsp;
                                    <button type="button" className="btn btn-success" style={{zIndex:"0"}}
                                            onClick={onClickBrowse}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, 'Browse')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={closeInsurancePopup}>{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-success"
                                onClick={onClickAdInsurance}>{CommonUtil.getMessageText(Constants.ADD, 'Add')}</button>
                    </div>
                </div>

            </Modal>

            <input type="file" ref={fileref1} className="custom-file-input" id="inputGroupFile1"
                   accept="image/*;application/pdf" style={{display: 'none'}} onChange={(e) => {onSelectFiles1(e)}} ></input>

            <div className="container-fluid container-fluid-padding"
                 style={{background: "#f9f9f9", paddingTop: "15px", paddingBottom: '15px'}}>
                <div className="">
                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                        <div className="col-12 gen-panel">
                            <div className="form-group">
                                <label style={{paddingLeft:"10px",paddingTop:"10px"}}>{CommonUtil.getMessageText(Constants.CONDITIONS_RESTRICTIONS, 'Conditions & Restrictions')}</label>
                                <div>
                                <textarea name={"conditions"} rows={5} className="form-control"
                                          style={{height: "100px", border: "1px solid #dad7d7"}}
                                          value={conditions} onChange={(e) => {
                                    setConditions(e.currentTarget.value)
                                }}/>
                                </div>
                            </div>

                        </div>
                    )}
                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                        <div className="col-12 gen-panel">
                            <div className="form-group">
                                <label style={{paddingLeft:"10px",paddingTop:"10px"}}>{CommonUtil.getMessageText(Constants.EQUIP_GEO_RESTICTIONS, 'Equipment Geo Restrictions')}</label>
                                <div>
                                <textarea name={"equipments"} rows={3} className="form-control"
                                          style={{height: "100px", border: "1px solid #dad7d7"}}
                                          value={equipments} onChange={(e) => {
                                    setEquipments(e.currentTarget.value)
                                }}/>
                                </div>
                            </div>

                        </div>
                    )}
                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                        <div className="col-12 gen-panel">
                            {/*<div className="col-12 m-0 p-0" ><label>{CommonUtil.getMessageText(Constants.BUFFER_CONFIG_SERVER, 'Buffer Configuration Interval')}</label></div>*/}
                            <div className="col-12 " style={{paddingLeft:"10px",paddingTop:"10px"}}>
                                <label>{CommonUtil.getMessageText(Constants.RE_LISTING_DELAY, 'Re-listing Delay')}
                                </label> &nbsp;<i className="fa fa-info-circle"
                                                  style={{"color": "blue", fontSize: "20px"}}
                                                  title="This is the time between when a trailer is dropped off and before it gets relisted. This is to ensure that there will not be an overlap in availability for the asset."></i>
                            </div>
                            <div style={{
                                height: "auto !important",
                                border: "1px solid #dad7d7",
                                backgroundColor: 'white',
                                minHeight: '100px'
                            }} className='col-12 p-2 m-0 row'>
                                <div className="col-md-6 col-12 row pr-0">
                                    <div className=" col-md-4 col-12">
                                        <label style={{
                                            width: 'auto',
                                            textAlign: 'center',
                                            paddingTop: '2px'
                                        }}>{CommonUtil.getMessageText(Constants.BUFFER_RENTALS, 'Buffer interval between Rentals')}</label>
                                    </div>
                                    <div className="col-md-8 col-12 m-0 p-0">
                                        <input value={bufferdays} ref={bufferRef} id="buffer" pattern="[0-9]*"
                                               type="text" name="buffer" onChange={handleInputBuffer} maxLength={6}
                                               className={'form-control'} onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-12 my-3 gen-panel">
                        <div className="col-12 " style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label>{CommonUtil.getMessageText(Constants.API_CONFIG, 'API Configuration')}</label></div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}}
                             className='col-12 p-2 m-0 row'>
                            <div className="col-md-8 col-12 row">
                                <div className="col-md-6 col-12">
                                    <label>{CommonUtil.getMessageText(Constants.ORG_API_KEY, 'Your Organization\'s API key')}:</label>
                                    <p style={{textDecoration: 'underlined'}}>{apiKey}</p>
                                </div>
                                <div className="col-md-6 col-12">
                                    <button className={`btn ${apiKey ? 'btn-warning' : 'btn-success'}`}
                                            onClick={generateAPIKey}>{apiKey ? CommonUtil.getMessageText(Constants.RENEW, 'Renew') : CommonUtil.getMessageText(Constants.GENERATE, 'Generate')}</button>
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-12" >

                            </div> */}
                        </div>
                    </div>
                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER_RENTER?
                    <div className="col-12 my-3 gen-panel">
                        <div className="col-12 " style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label>{CommonUtil.getMessageText(Constants.RESERVATIONS_IMPORT, 'Reservations import')}</label></div>
                            <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}}
                             className='col-12 p-2 m-0 row'>
                            <div className="col-md-8 col-12 row">
                                <div className="col-md-6 col-12">
                                    
                                </div>
                                <div className="col-md-6 col-12">
                                    <ImportReservation />
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-12" >

                            </div> */}
                        </div>
                    </div>:null
                    }

                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER ?null:
                        <div className="col-12 my-3 gen-panel">
                            <div className="col-12 " style={{paddingLeft:"10px",paddingTop:"10px"}}>
                                <label>{CommonUtil.getMessageText(Constants.TWIC_ACCESS, 'Twic Access')}</label>
                            </div>
                            <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}}>
                                <div className="col-12" style={{padding:"10px"}}>
                                    <div className="flex">
                                        <input ref={chkTwicAccess} type="checkbox" className="checkBox"
                                               onChange={(e) => {
                                                   onChangeTwicAccess(e)
                                               }} checked={isTwicChecked ? true : false}
                                        />&nbsp;&nbsp;
                                        <label htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.IS_TWIC_ACCESS, "Can access TWIC Locations")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* {CommonUtil.ORGANIZATION_TYPE !== CommonUtil.ORG_TYPE_OWNER  ? */}
                        <div className="col-12 my-3 gen-panel">
                            <div className="col-12 "  style={{paddingLeft:"10px",paddingTop:"10px"}}>
                                <label>{CommonUtil.getMessageText(Constants.SEND_DAILY_INVOICES, 'Send Daily Invoices')}</label>
                            </div>
                            <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}}>
                                <div className="col-md-12 col-lg-6" style={{padding:"10px"}}>
                                    <div className="flex">
                                        <div className=" flex col-md-4 col-12">
                                            <input ref={sendDailyCheck} type="checkbox" className="checkBox"
                                                   onChange={(e) => {
                                                       setIsSendDailyInvoices(e.target.checked);
                                                   }} checked={isSendDailyInvoices ? true : false}  /> &nbsp;&nbsp;
                                            <label>
                                                {CommonUtil.getMessageText(Constants.IS_SEND_DAILY_INVOICES, "Send daily invoices notification")}
                                            </label>
                                        </div>
                                        <div className=" col-md-6 col-12" style={{display:isSendDailyInvoices?"":"none",paddingLeft:"10px"}}>
                                            <textarea ref={sendDailyCheckinput} className="form-control" style={{minHeight:"80px"}}
                                                onChange={(e) => {
                                                    setInvoiceEmail(e.target.value);
                                                }} value={invoiceEmail}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="my-1  text-right pb-2 pr-2">
                                    {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                        &nbsp;&nbsp; */}
                                    <button type="submit" className="btn btn-primary waves-effect waves-light"
                                            onClick={onClickSaveGeneralConfig}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/* :null} */}
                   
                </div>

                <div className="row  pt-2 mb-2">
                    <div className="col-md-10">
                        <label> {CommonUtil.getMessageText(Constants.BLOCKED_ORG, 'Blocked Organizations')} </label>
                    </div>
                    {
                        isMobile ? (
                            <MGrid id="CustBlockOrgGrid" cols={blockOrgColumns} rows={blockOrgList} pageSize={10}
                                   addNew={true}
                                   tooltip={CommonUtil.getMessageText(Constants.ADD_BLOCK_ORGS, 'Add Block Orgs')}
                                   headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                   ref={userGridRef} method={{onClickUserDetails, onClickBlockOrgDetails}}
                                   addNewRecord={onClickBlockUser}/>
                        ) : (
                            <div className="ag-theme-alpine gridHeight"
                                 style={{paddingLeft: "8px", paddingRight: "5px", height: "250px", width: "100%"}}>
                                <AGGrid id="CustBlockOrgGrid" cols={blockOrgColumns} rows={blockOrgList} pageSize={500}
                                        addNew={true}
                                        tooltip={CommonUtil.getMessageText(Constants.ADD_BLOCK_ORGS, 'Add Block Orgs')}
                                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                        rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef}
                                        method={{onClickUserDetails, onClickBlockOrgDetails}}
                                        addNewRecord={onClickBlockUser}/>
                            </div>
                        )
                    }
                </div>
                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                    <div className="row  pt-2 mb-2">
                        <div className="col-md-10">
                            <label> {CommonUtil.getMessageText(Constants.PRIVATE_NETWORK, 'Private Network')}</label>
                        </div>
                        <div className="col-md-2">
                            <ImportPrivateNetwork onSuccess={getNetworks}/>
                        </div>
                        
                        {
                            isMobile ? (
                                <MGrid id="CustNetWorkOrgGrid" cols={networkColumns} rows={networkOrgList} pageSize={10}
                                       addNew={true}
                                       tooltip={CommonUtil.getMessageText(Constants.ADD_PRIVATE_NETWORK, 'Add Private Network')}
                                       headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                       rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={networkGridRef}
                                       method={{onClickNetworkDetails, deleteDotNetwork}}
                                       addNewRecord={onClicNetworkAdd}/>
                            ) : (
                                <div className="ag-theme-alpine gridHeight"
                                     style={{paddingLeft: "8px", paddingRight: "5px", height: "250px", width: "100%"}}>
                                    <AGGrid id="CustNetWorkOrgGrid" cols={networkColumns} rows={networkOrgList}
                                            pageSize={500} addNew={true}
                                            tooltip={CommonUtil.getMessageText(Constants.ADD_PRIVATE_NETWORK, 'Add Private Network')}
                                            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                            rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={networkGridRef}
                                            method={{onClickNetworkDetails, deleteDotNetwork}}
                                            addNewRecord={onClicNetworkAdd}/>
                                </div>
                            )
                        }
                    </div>
                )}
                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                    <div className="col-md-12 gen-panel">
                        <div className="col-md-10" style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label> {CommonUtil.getMessageText(Constants.INSURANCE_MODULE, 'Insurance Module')}</label>
                        </div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}} className='col-12 p-2 m-0 row'>
                            <div style={{paddingTop: "15px"}} className="row col-md-12 col-12">
                                <div className="flex col-md-3 col-3" >
                                    <input ref={chkInsurance} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               onChangeInsurance(e)
                                           }} checked={isInuranceChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_INSURANCE_MODULE, "Enable Insurance Module")}</label>
                                </div>
                                
                            </div>

                            <div className="flex col-md-6 col-6" id="divLiability" style={{paddingTop: "25px",display:"none"}}>
                                    <input ref={chkInsurance} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               onChangeLiability(e)
                                           }} checked={isLiabilityChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_LIABILITY_INSURANCE, "Enable Liability Insurance")}</label>
                                </div>
                                <div className="flex col-md-6 col-6" id="divPhysicalDamage" style={{paddingTop: "25px",display:"none"}}>
                                    <input ref={chkInsurance} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               onChangePhysicalDamage(e)
                                           }} checked={isPhysicalDamageChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_PHYSICA_DAMAGE_INSURANCE, "Enable Physical Damage Insurance")}</label>
                                </div>
                            
                            <div style={{paddingTop: "25px",display:"none"}} className="col-md-6 col-6" id="divInsurOwner">
                                <div className="flex">
                                    <input ref={chkOwnerInsurance} type="checkbox" className="checkBox" disabled={insuranceMandatory ? true :CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               onChangeInsuranceOwner(e)
                                           }} checked={isInuranceOwnerChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label className=""
                                           htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.INSURANCE_PAID_BY_OWNER, "Insurance will be paid by the Owner")}</label>
                                </div>
                            </div>
                            <div style={{paddingTop: "15px",display:"none"}} className="col-md-6 col-6" id="divInsurRenter">
                                <div className="flex">
                                    <input ref={chkRenterInsurance} type="checkbox" className="checkBox" disabled={insuranceMandatory ? true :CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               onChangeInsuranceRenter(e)
                                           }} checked={isInuranceRenterChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label className=""
                                           htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.INSURANCE_PAID_BY_RENTER, "Insurance will be paid by the Renter")}</label>
                                </div>
                            </div>
                            <div style={{paddingTop: "25px",display:"none"}} className="col-md-6 col-6" id="divManInsurReposition">
                                <div className="flex">
                                    <input ref={chkOwnerInsurance} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setinsuranceMandatory(e.currentTarget.checked);
                                               if(e.currentTarget.checked){
                                                    setInsuranceOwnerChecked(false);
                                                    setInsuranceRenterChecked(true);
                                                }
                                           }} checked={insuranceMandatory ? true : false}
                                    />&nbsp;&nbsp;
                                    <label className=""
                                           htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.INSURANCE_MAN, "Make Insurance Mandatory")}</label>
                                </div>
                            </div>
                            {/* <div style={{paddingTop: "25px",display:"none"}} className="col-md-6 col-6" id="divManInsurRenter">
                                <div className="flex">
                                    <input ref={chkOwnerInsurance} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setInsuranceMandatoryForRental(e.currentTarget.checked);                                               
                                           }} checked={insuranceMandatoryForRental ? true : false}
                                    />&nbsp;&nbsp;
                                    <label className=""
                                           htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.INSURANCE_MAN_RENTAL, "Make Insurance Mandotory for Rentals")}</label>
                                </div>
                            </div> */}
                            <div className="col-md-12 col-12" id="divInsurGrid" style={{paddingTop: "15px", paddingLeft: "0px", paddingRight: "0px",display:"none"}}>
                                {
                                    isMobile ? (
                                        <MGrid id="CustInsurOrgGrid" cols={insuranceColumns} rows={insurPlans}
                                               pageSize={10}
                                               addNew={CommonUtil.isRootUser()?true:false}
                                               tooltip={CommonUtil.getMessageText(Constants.ADD_INSURANCE_PLAN_POLICY, 'Add Insurance Policy')}
                                               headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                               rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={networkGridRef}
                                               method={{deleteInsurPolicy}}
                                               addNewRecord={onClckAddInsurPolicy}/>
                                    ) : <div className="ag-theme-alpine gridHeight" style={{paddingLeft: "8px", paddingRight: "5px", height: "250px", width: "100%"}}>
                                        <AGGrid id="CustInsurOrgGrid" cols={insuranceColumns} rows={insurPlans}
                                                pageSize={500} addNew={CommonUtil.isRootUser()?true:false}
                                                tooltip={CommonUtil.getMessageText(Constants.ADD_INSURANCE_PLAN_POLICY, 'Add Insurance Policy')}
                                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                                rowHeight={50} ref={insurGridRef}
                                                method={{deleteInsurPolicy,onDownloadDoc}}
                                                addNewRecord={onClckAddInsurPolicy}/>
                                    </div>
                                }
                            </div>

                            <div className="col-md-12 col-12 flex" style={{display:"none"}}>
                                <div className="col-md-2 col-12" >
                                    <div className="col-sm-12 cus-input-file-text financial-browse-button">
                                        <button type="button" className="btn btn-outline-primary financial-button"
                                                disabled={CommonUtil.isRootUser()?false:true} onClick={onClickBrowse}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                    </div>
                                    <div className="col-sm-12 cus-input-file p-0">

                                    </div>
                                </div>
                                <div className="col-md-10 col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <div className="gallery flex documents-gallary">

                                                <div style={{display: 'flex',overflow:"auto"}}>
                                                    {insurDocPath.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.INSURANCE_CUST_DOC)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" style={{width:"50px"}} className={"financial-download-image"}
                                                                             onClick={() => { onDownloadDoc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a style={{"visibility":CommonUtil.isRootUser()?"visible":"hidden"}}><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                                                                               onClick={() => { onRemoveDoc(item.attachementId) }} ></i></a>
                                                                        </span>

                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadDoc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="my-1  text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={CommonUtil.isRootUser()?false:true}
                                        onClick={onClickSaveCustomerInsurance}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                    <div className="col-md-12 gen-panel" style={{paddingTop:"10px"}}>
                        <div className="col-md-10" style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label> {CommonUtil.getMessageText(Constants.INTERCHANGE_MODULE, 'Interchange Module')}</label>
                        </div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}} className='col-12 p-2 m-0 row'>
                            <div style={{paddingTop: "15px"}} className="row col-md-12 col-12">
                                <div className="flex col-md-3 col-3" >
                                    <input ref={chkInterchange} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setIntechangeMenu(e.currentTarget.checked)
                                           }} checked={interchangeMenu ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_INTERCHANGE_MODULE, "Enable Interchange Module")}</label>
                                </div>
                            </div>
                            <div style={{paddingTop: "15px"}} className="row col-md-6 col-4">
                                <div className="flex " >
                                    <input ref={chkInterchange} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setIsInternlUser(e.currentTarget.checked)
                                           }} checked={isInternlUser ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_INTERNL_USER, "Enable Internal User")}</label>
                                </div>
                            </div>
                            <div style={{paddingTop: "15px"}} className="row col-md-6 col-4">
                                <div className="flex " >
                                    <input ref={chkInterchange} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setIsInspectionCompany(e.currentTarget.checked)
                                           }} checked={isInspectionCompany ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_INSPECTION_COMPANY, "Enable Inspection Company")}</label>
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="my-1  text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={CommonUtil.isRootUser()?false:true}
                                        onClick={onClickSaveSaveInterchangeMenu}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Adding New Section As Interchange Notifications */}
                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null :(
                    <div className="col-12 my-3 gen-panel">
                        <div className="col-12 " style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                            <label>{CommonUtil.getMessageText(Constants.INTERCHANGE_NOTIFICATIONS, 'Interchange Notifications')}</label>
                        </div>
                        <div style={{ height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white' }}>
                            <div className="col-md-12 col-lg-6" style={{ padding: "10px" }}>
                                <div className="flex">
                                    <div className=" flex col-md-4 col-12">
                                        <input ref={sendInterchangeNotify} type="checkbox" className="checkBox"
                                            onChange={(e) => {
                                                setInterchangeNotify(e.target.checked);
                                            }} checked={interchangeNotify ? true : false} /> &nbsp;&nbsp;
                                        <label>
                                            {CommonUtil.getMessageText(Constants.ENABLE_TO_RECEIVE_NOTIFICATION, "Enable to Receive notification")}
                                        </label>
                                    </div>
                                    <div className=" col-md-8 col-12" style={{ display: interchangeNotify ? "" : "none", paddingLeft: "10px" }}>
                                        <input ref={sendInterchangeCheckinput} type="input" className="form-control"
                                            onChange={(e) => {
                                                setInterchangeInvoiceEmail(e.target.value);
                                            }} value={interchangeInvoiceEmail}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="my-1  text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                        &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light"
                                    onClick={onClickSaveInterchangeEmails}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                    <div className="col-md-12 gen-panel" style={{paddingTop:"10px"}}>
                        <div className="col-md-10" style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label> {CommonUtil.getMessageText(Constants.ANY_AVAILABLE_MODULE, 'Any Available Module')}</label>
                        </div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}} className='col-12 p-2 m-0 row'>
                            <div style={{paddingTop: "15px"}} className="row col-md-12 col-12">
                                <div className="flex col-md-3 col-3" >
                                    <input ref={chkAnyAvailable} type="checkbox" className="checkBox" disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setIsAnyAvailable(e.currentTarget.checked)
                                           }} checked={isAnyAvailable ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ENABLE_ANY_AVAILABLE_MODULE, "Enable Any Available Module")}</label>
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="my-1  text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={CommonUtil.isRootUser()?false:true}
                                        onClick={onClickSaveAnyAvailable}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                { CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null :(
                    <div className="col-md-12 gen-panel" style={{paddingTop:"10px"}}>
                        <div className="col-md-10" style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label> {CommonUtil.getMessageText(Constants.PAYMENT_FEE, 'Platform Fee Calculation For Owner')}</label>
                        </div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}} className='col-12 p-2 m-0 row'>
                            <div style={{paddingTop: "15px"}} className="row col-md-12 col-12">
                                <div className="flex col-md-4 col-4" >
                                    <input type="radio" name="platformFee" className="checkBox" 
                                     disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                            setPlatformFeesPaidByOwner("Owner")
                                           }} checked={platformFeesPaidBy === "Owner"}
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.PLATFORM_FEE_BY_OWNER, "Platform fee to be paid by the Owner - Cost Plus")}</label>
                                </div>
                                <div className="flex col-md-4 col-4" >
                                    <input type="radio" name="platformFee" className="checkBox" 
                                     disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setPlatformFeesPaidByOwner("Renter")
                                           }} checked={platformFeesPaidBy=== "Renter" }
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.PLATFORM_FEE_BY_RENTER, "Platform fee to be paid by the Owner - At Cost")}</label>
                                </div>
                                <div className="flex col-md-4 col-4" >
                                    <input type="radio" name="platformFee" className="checkBox" 
                                     disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e) => {
                                               setPlatformFeesPaidByOwner("Repositioner")
                                           }} checked={platformFeesPaidBy === "Repositioner" }
                                    />&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.PLATFORM_FEE_BY_RENTER_OWNER, "Platform fee to be paid by the Repositioner")}</label>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 ">
                            <div className="my-1 text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={CommonUtil.isRootUser()?false:true}
                                        onClick={onClicksavePlatformFeePaidBy}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? null : (
                    <div className="col-md-12 gen-panel" style={{paddingTop:"10px"}}>
                        <div className="col-md-10" style={{paddingLeft:"10px",paddingTop:"10px"}}>
                            <label> {CommonUtil.getMessageText(Constants.PAYMENT_TERMS, 'Payment Terms')}</label>
                        </div>
                        <div style={{height: "auto !important", border: "1px solid #dad7d7", backgroundColor: 'white'}} className='col-12 p-2 m-0 row'>
                            <div style={{paddingTop: "15px"}} className="row col-md-12 col-12">
                                <div className="flex col-md-3 col-3" >
                                    
                                    <SingleSelectComp
                                    dataSource={creditTerms.map((data:any)=>{ return {key:data.creditTermId,value:data.creditDescription}})}
                                    selectedValue={paymentTerms}
                                    width={'100%'}
                                    handleChange={handlePaymentStatus}
                                    value={'key'} label={'value'}
                                    disabled = {!CommonUtil.isRootUser()}
                                />
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="my-1  text-right pb-2 pr-2">
                                {/* <button className="btn btn-warning waves-effect">Discard Changes</button>
                                &nbsp;&nbsp; */}
                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={CommonUtil.isRootUser()?false:true}
                                        onClick={onClickSaveGeneralConfig}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>

    );
}

export default BlockedOrgs;
