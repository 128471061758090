import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DraggableComponent from '../../Components/DraggableComponent';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import { IAGrid } from '../../Components/IInterfaces';

const CSVFileErrorsModal = ({ open, handleClose, data }: any) => {
    const userGridRef1 = useRef<IAGrid>(null);
    const [errors, setErrors] = useState<any>([])
    const [errorColumns] = useState([
        { field: 'row', headerName: CommonUtil.getMessageText(Constants.ROW_NUMBER, 'Row Number'), resizable: true, width: 120, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER, 'Unit Number'), resizable: true, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER, 'Vin Number'), resizable: true, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', },
        { field: 'error', headerName: CommonUtil.getMessageText(Constants.ERRORS, 'Error'), resizable: true, width: 400, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', },
    ]);
    useEffect(() => {
        try {
            const jErrors = JSON.parse(data?.errors);
            setErrors(jErrors?.error);
        } catch (e) {
            setErrors([]);
        }
    }, [data?.errors]);

    return (
        <Modal show={open} dialogAs={DraggableComponent} centered>
            <div className="modal-content" style={{ "width": "800px" }}>
                <Modal.Header className="modal-header" closeButton={false}>
                    <h5 className="modal-title mt-0"></h5>
                    <button type="button" className="close" style={{ marginLeft: "0px", fontSize: "30px" }} data-dismiss="modal" aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <div className="modal-body">
                    <div className="container-fluid container-fluid-padding mt-3" style={{ backgroundColor: 'white' }}>
                        <div className="row fs-7 pt-1">
                            <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%", paddingLeft: "8px", paddingRight: "5px" }}>
                                <InspectionGrid
                                    cols={errorColumns}
                                    rows={errors}
                                    pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                    ref={userGridRef1}
                                    method={{}} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </Modal>
    );
}

export default CSVFileErrorsModal;
