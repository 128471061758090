import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import $ from "jquery";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";

let imgIndex = 0;
const EquipGalleryModal = ({modalTile,modalShow, onCloseImagePopup, images, idx, imagePath}: any) => {

    const [imgFullPath,setImgPullPath] = useState("");

    useEffect(()=>{
        //console.log(images,idx);
        if(images.length>0 && idx>=0){
            imgIndex = idx;
            setImgPullPath(images[imgIndex].Path);
            showHideButtons();
        }else{
            showHideButtons();
            setImgPullPath(imagePath);
        }
       //

    },[modalShow])



    let onClickLeft = ()=>{
        imgIndex = imgIndex-1;
        setImgPullPath(images[imgIndex].Path);
        showHideButtons();
    }
    let onClickRight = ()=>{
        imgIndex = imgIndex+1;
        setImgPullPath(images[imgIndex].Path);
        showHideButtons();
    }
    let showHideButtons = ()=>{
        $("#liRight").show();
        $("#liLeft").show();
        if(images.length == 0 || images.length == 1){
            $("#liRight").hide();
            $("#liLeft").hide();
        }else if(images.length-1 == imgIndex){
            $("#liRight").hide();
        }else if(imgIndex == 0){
            $("#liLeft").hide();
        }
    }
    return (<>
        <Modal show={modalShow} dialogAs={DraggableComponent}>
            <div className="modal-content" style={{width: '600px',height:"600px"}}>
                <div className="modal-header">
                    <h6 className="modal-title mt-0 fw-bold text-center black-text" style={{width: '97%', marginLeft: '3%'}}>{modalTile}</h6>
                    {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseImagePopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body color-black my-2" style={{paddingTop: '10px',overflow:"auto"}}>
                    <div style={{display:"flex",width:"100%",height:"100%",maxHeight:"100%",maxWidth:"100%"}}>
                        <a style={{position:"absolute",top:"200px"}}>
                            <img id={"liLeft"} src={"../../images/right-arrows-icon.png"}
                                                                          style={{position:"absolute",width:"40px",transform:"rotate(180deg)"}}
                        onClick={onClickLeft}></img></a>
                        <img src={imgFullPath}  style={{margin:"0px",width:"100%",height:"100%"}} />
                        <a style={{position:"absolute",right:"50px",top:"200px"}}>
                            <img id={"liRight"} src={"../../images/right-arrows-icon.png"} style={{position:"absolute",width:"40px"}}
                              onClick={onClickRight}></img></a>
                    </div>


                </div>
                <div className="modal-footer clsModalPermissionsFootr">
                    <button type="button" className="btn btn-outline-dark btn-sm"
                            style={{backgroundColor: '#00CFB4', color: '#fff'}}
                            data-bs-dismiss="modal" onClick={onCloseImagePopup}>
                        {CommonUtil.getMessageText(Constants.CLOSE,"Close")}
                    </button>
                </div>
            </div>
        </Modal>
    </>)
}
export default EquipGalleryModal;
