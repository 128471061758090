import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DraggableComponent from '../../Components/DraggableComponent';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import PhoneInput from 'react-phone-input-2';
import { NetworkManager } from '../../Components/NetworkManager';
import ToastManager from '../../Components/ToastManager';


const AddCarrierDetailsModal = ({ mode, open, handleClose, getDashboardCount, getAllCarrierDetails }: any) => {

    const [carrName, setCarrName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState<any>("")
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const [email, setEmail] = useState("")
    const [usdotNumber, setUsDotNumber] = useState("")
    const [status, setStatus] = useState("Active")


    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    let onSuccess = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (mode === "edit") {
                    getAllCarrierDetails();
                } else {
                    getDashboardCount();
                }
                handleClose()
                ToastManager.showToast(mode === 'edit' ? 'Carrier updated.' : 'Carrier created.', 'Success')
            }
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // event.stopPropagation();

        let isName: boolean = CommonUtil.isMandatory(nameRef);
        let isPhone: boolean = true;
        let isEmail: boolean = true;
        if (email && email.length > 0) {
            isEmail = CommonUtil.isValidEmail(email);
        }
        // if(!phoneNumber.trim() || phoneNumber.length < 11){
        //     isPhone = false
        //     setPhoneClass('is-invalid')
        // }
        if (isName && isEmail && isPhone) {

            let newData = { carrierName: carrName.trimStart().trimEnd(), phoneNumber, email, dotNumber: usdotNumber, carrierStatus: status }
            if (mode === 'edit') {
                let orgUrl = `api/carrier/?saasOrgId=${CommonUtil.getSaasOrgId()}&carrierId=${CommonUtil.getCarrierId()}`;
                NetworkManager.sendJsonRequest(orgUrl, newData, "", "PUT", true, onSuccess, onLoginError);
            } else {
                let orgUrl = "api/carrier/?saasOrgId=" + CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", true, onSuccess, onLoginError);
            }

            handleClose();
        }
    }

    const onGetCarrierDetails = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            console.log(restData);
            setCarrName(restData.carrierName);
            setPhoneNumber(restData.phoneNumber);
            setEmail(restData.email);
            setUsDotNumber(restData.dotNumber);
            setStatus(restData.carrierStatus);
        }
    }

    useEffect(() => {
        if (mode === 'edit') {
            let orgUrl = "api/carrier/?carrierId=" + CommonUtil.getCarrierId();
            NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetCarrierDetails, onLoginError);
        }
    }, [mode])


    let onLoginError = (err: any) => {
        console.log(err);
    }
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e)
    }
    const handleInputEmail = (e: any) => {
        setEmail(e.target.value);
        let flag: boolean = CommonUtil.isValidEmail(e.target.value);
        if (flag) {
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        } else {
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }

    }
    const handleUSDOTNumber = (e: any) => {
        setUsDotNumber(e.target.value)
    }
    const handleInputStatus = (e: any) => {
        setStatus(e.target.value)
    }

    return (
        <Modal show={open} dialogAs={DraggableComponent} centered>
            <div className="modal-content" style={{ "width": "600px" }}>
                <Modal.Header className="modal-header" closeButton={false}>
                    <h5 className="modal-title mt-0">{mode === 'new' ? CommonUtil.getMessageText(Constants.CREATE_CARRIER, 'Create Carrier') : CommonUtil.getMessageText(Constants.UPDATE_CARRIER, 'Edit Carrier')}</h5>
                    <button type="button" className="close" style={{ marginLeft: "0px" }} data-dismiss="modal" aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <div className="modal-body">
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.CARRIER_NAME, 'Carrier Name')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"
                                    ref={nameRef}
                                    name="CarrierName"
                                    className="form-control clsFormInputControl"
                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_CARRIER_NAME, "Enter Carrier Name")}
                                    value={carrName}
                                    onChange={(e: any) => {
                                        setCarrName(e.target.value)
                                        CommonUtil.isMandatory(nameRef);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.MOBILE_NUMBER, 'Mobile Number')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12" style={{ zIndex: 9 }}>

                                <PhoneInput
                                    country={'us'}
                                    countryCodeEditable={false}
                                    containerClass={phoneClass}
                                    value={phoneNumber}
                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, "Enter Phone Number")}
                                    inputClass={`input-phone ${phoneNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                    //   inputClass={`input-phone ${phoneClass}`}
                                    onChange={handleInputPhone}
                                    containerStyle={{
                                        width: '100%',
                                    }}
                                    disableCountryGuess={true}
                                    disableDropdown={true}
                                    disableSearchIcon={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}
                            </label>
                            <div className="col-sm-12">
                                <input className="form-control"
                                    ref={emailRef}
                                    placeholder={CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}
                                    type={'email'} name="email"
                                    value={email}
                                    onChange={handleInputEmail}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {"USDOT Number (Optional)"}
                            </label>
                            <div className="col-sm-12">
                                <input className="form-control"
                                    placeholder="USDOT Number (Optional)"
                                    type='text' maxLength={20}
                                    value={usdotNumber}
                                    onChange={handleUSDOTNumber}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.STATUS, 'Status')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <select name="status" className={'form-control'}
                                    onChange={handleInputStatus}
                                    value={status}
                                >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-dark"
                        data-dismiss="modal"
                        onClick={handleClose}
                    >
                        {CommonUtil.getMessageText(Constants.CANCEL, 'Cancel')}
                    </button>
                    <button type="submit" className="btn btn-success"
                        onClick={handleSubmit}
                    >
                        {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default AddCarrierDetailsModal;
