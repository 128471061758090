import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";

const UpdateLightInspection = () => {
  const [lightDetails, setLightDetails] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);
  const [damageDes, setDamageDes] = useState("");
  const [damageMap, setDamageMap] = useState<any>([]);
  const [fullDamageView, setFullDamageView] = useState<any>([]);
  const [entryDamage, setEntryDamage] = useState("");
  const [showMap, setShowMap] = useState(false);

  let getLightInspectionDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getLightsInspection?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetLightInspectionDetails,
      onLoginError
    );
  };

  useEffect(() => {
    getLightInspectionDetails();
  }, []);

  let onGetLightInspectionDetails = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

    //   setVisualDetails(restData[0].taskDetailsAttachment);
      let columns: any = [
        {
          field: "fullName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 400,
          sortable: true,
          cellStyle: {
            fontWeight: "600",
            letterSpacing: "0px",
            fontSize: "12px",
            fontFamily: "poppins",
            color: "black",
            paddingTop: "5px",
          },
          headerClass: "header-white-bg",
          pinned:"left"
        },
      ];
      let rowData:any = [];
      for (let i = 0; i < restData.length; i++) {
        const groupName:string = restData[i].eventCode;
        
        restData[i].taskDetailsAttachment.map((ele:any)=>{
                let obj:any = {...ele};
                obj[`status-${groupName}`] = obj.status;
                obj[`note-${groupName}`] = obj.note;
                obj[`count-${groupName}`] = obj.count;
                obj[`type-${groupName}`] = obj.selectionOption;
                obj[`attachments-${groupName}`] = obj.attachments;
    
                const index = rowData.findIndex((item:any)=>{
                    return item.name === obj.name;                
                }) 
                if(index === -1){
                    rowData.push(obj);
                }else{
                    rowData[index] = {...rowData[index],...obj};
                }
            })
        columns.push({
          field: restData[i].eventCode,
          date: restData[i].creationDate,
          headerGroupComponent: NewReleaseCellRender,
          children: [
            {
              field: `status-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.NEW_PASS, "PASS"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: (params:any)=>{
                const status = String(params.data[params.colDef.field]).toUpperCase();
                return {
                fontWeight: "500",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
                background: (status === Constants.NO || status === Constants.FAIL) ? "rgba(242, 170, 176,0.5)" : null,
              }},
              headerClass:  "header-white-bg",
             
              valueGetter: (params: any) => {                
                const status = String(params.data[params.colDef.field]).toUpperCase();
                return status === Constants.OK || status === Constants.PASS ?  "Yes": "No";
              },
            },
            {
              field: `status-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.FAIL, "FAIL"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: (params:any)=>{
                const status = String(params.data[params.colDef.field]).toUpperCase();
                return {
                fontWeight: "500",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
                background: (status === Constants.NO || status === Constants.FAIL) ? "rgba(242, 170, 176,0.5)" : null,
              }},
              headerClass:  "header-white-bg",
             
              valueGetter: (params: any) => {
                const status = String(params.data[params.colDef.field]).toUpperCase();
                return status === Constants.NO || status === Constants.FAIL ? "Yes" : "No";
              },
            },
            {
              field: `note-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.NOTE, "NOTE"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",
            
            },
            {
                field: `count-${groupName}`,
                headerName: CommonUtil.getMessageText(Constants.COUNT, "COUNT"),
                resizable: true,
                width: 100,
                sortable: true,
                cellStyle: {
                  fontWeight: "400",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                },
                headerClass:  "header-white-bg",
               
              },
              {
                field: `type-${groupName}`,
                headerName: CommonUtil.getMessageText(Constants.TYPE, "TYPE"),
                resizable: true,
                width: 100,
                sortable: true,
                cellStyle: {
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                },
                headerClass:  "header-white-bg",
             
              },
            {
                field: `attachments-${groupName}`,
                headerName: "Attachments",
                resizable: true,
                width: 100,
                sortable: true,
                cellStyle: {
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                  borderRight: "1px solid #d0d3d7"
                },
                headerClass:  "header-white-bg",
               
                cellRenderer: AttachmentsCellRenderer,
              },
          ],
        });
      }
      setUserColumns(columns);

      setLightDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let attachImages: any = [];
  try {
    attachImages = attachments.attachmentUploads.map(
      (ele: any) => ele.fullFilePath
    );
    console.log(attachImages);
  } catch (e) {
    attachImages = [];
  }

  const [userColumns, setUserColumns] = useState<any>([]);

  console.log(lightDetails);
  console.log(userColumns);
  return (
    <div className="containData">
      <div className="row">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight"
          style={{
            height: "calc(100vh - 330px)",
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          <InspectionGrid
            cols={userColumns}
            rows={lightDetails}
            pageSize={100}
            addNew={true}
            tooltip={"Add Company"}
            name={"Add Company"}
            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
            rowHeight={CommonUtil.GRID_ROW_HEIGHT}
            hideToolbar={true}
          />
        </div>
        {showMap ? (
          <>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 text-center">
              <h2 className="title-head my-3">{entryDamage} Damage View</h2>
              <InnerImage path={fullDamageView} />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="mx-auto my-auto justify-content-center">
                <div className="gallery-carousel">
                  <h2 className="title-head my-3">
                    {entryDamage} Damage Photos
                  </h2>
                  <CarouselComponent
                    images={damageMap}
                    callBack={(idx: any) => {
                      setGalleryPop(true);
                      setGalleryPopStartIndex(idx);
                    }}
                  />
                  <ReactImageGallery
                    startIndex={galleryPopStartIndex}
                    attachedImages={damageMap}
                    showPop={galleryPop}
                    handleClose={() => {
                      setGalleryPop(false);
                    }}
                  />
                </div>
              </div>

              <div className="gallery-form form">
                <h2 className="title-head my-4 mb-2">
                  {entryDamage} Damage Description
                </h2>
                <div className="form-floating">
                  <div
                    className="card p-3"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ minHeight: "100px", height: "auto" }}
                  >
                    {damageDes}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UpdateLightInspection;
