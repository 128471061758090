import React, { useEffect, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import '../../header.css'
import { publish, subscribe, unsubscribe } from "./CustomEvents";
import { Constants } from "../Languages/Constants";
import { Events } from "../Languages/Events";
import { useNavigate } from "react-router-dom";

const SignUpHeader = () => {
    const navigate = useNavigate();
    const [languages, setLanguages] = useState(CommonUtil.languages);
    const [selLanguage, setSelLanguage] = useState(CommonUtil.SEL_LANGUAGE_TEXT);
    const [page, setPage] = useState(0);

    let onClickLanOption = (item: any) => {
        //console.log(item);
        CommonUtil.SELECTED_LANGUAGE = item.Key;
        if (item.Key == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.ENG_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.ENG_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.FR_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.FR_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.FR_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.SP_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.SPANISH_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.SPANISH_LANGUAGE_TEXT);
        }

        publish(Events.LANGUAGE_CHANGE_EVENT, { data: item.Key });



        //showMessages();
    }
    useEffect(() => {
        subscribe(Events.LANGUAGE_CHANGE_EVENT, (data: any) => {
            // console.log(CommonUtil.SELECTED_LANGUAGE);
            showLanguageMessages();
        });
        return () => {
            unsubscribe(Events.LANGUAGE_CHANGE_EVENT, {});
        }
    }, []);

    let showLanguageMessages = () => {
        setPage(Math.random() * 100000);
    }
    let gotoMenu = () => {
        navigate('/')
    }
    let onClickSearchDB = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = true;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        navigate("/searchdashboard");
    }
    return (
        <>
            <div id="wrapper" className="enlarged">
                <div className="content-page">
                    <div className="content">
                        <div className="topbar">
                            <div className="row ml-0">
                                <div className="col-md-3 col-lg-2">
                                    <div className="topbar-left	d-none d-md-block" style={{ paddingLeft: "10px" }}>
                                        <div className="row">
                                            <div className="col-sm-10 col-md-9 col-lg-9 col-xl-10">
                                                {/* <a href="index.html" className="logo color"><img src="assets/images/logo.png"  alt="vHub" /></a> */}
                                                <a href="#" className="logo" onClick={gotoMenu}>
                                                    <img src={"../../images/logo.png"} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-lg-10 align-self-center">
                                    <nav className="navbar-custom">
                                        <ul className="list-inline mb-0 navi">
                                            <li className="list-inline-item dropdown notification-list d-sm-inline-block calendar1">
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                                                    <i onClick={onClickSearchDB} style={{cursor:"pointer"}} className="fa fa-search noti-icon fa-2xs"></i>
                                                    <label onClick={onClickSearchDB}>{CommonUtil.getMessageText(Constants.SEARCH, "Search")}</label>
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list d-sm-inline-block">
                                                <a className="nav-link headerLang" href="#" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span style={{ color: 'white' }}>
                                                        {selLanguage} <i className="fa fa-caret-down" style={{ display: "none" }}></i>
                                                    </span>
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-lg-end lang-ddl darkgrey-text" style={{ fontSize: '14px', display: "none" }}>
                                                    {languages.map((item: any, idx: number) => {
                                                        if (CommonUtil.SELECTED_LANGUAGE != item.Key) {
                                                            if (idx == 0) {
                                                                var list = (
                                                                    <li style={{ marginBottom: '5px', marginTop: '5px' }} key={idx} >
                                                                        <a className="dropdown-item" onClick={() => { onClickLanOption(item) }}>
                                                                            {item.Value}
                                                                        </a>
                                                                    </li>
                                                                )
                                                                return list
                                                            } else {
                                                                var list = (
                                                                    <li style={{ marginBottom: '5px' }} key={idx}>
                                                                        <a className="dropdown-item" onClick={() => { onClickLanOption(item) }}>
                                                                            {item.Value}
                                                                        </a>
                                                                    </li>
                                                                )
                                                                return list
                                                            }
                                                        }
                                                    })}
                                                </ul>
                                            </li>
                                        </ul>

                                        <div className="clearfix"></div>
                                    </nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="topbar-breadcrumbs" style={{ height: "35px" }}>
                                    <div className="row" style={{ paddingTop: "3px" }}>
                                        <div className="col-6">
                                            <span className="breadcrumbs" style={{ padding: "0px", paddingLeft: "20px" }}>
                                                <span className="active">{CommonUtil.getMessageText(Constants.COMPANY_REG_FORM, "Company Registration Form")}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpHeader;
