export class StatusCodeUtil{
    static getCodeMessage(code:number):string{
        let msg:string = "";
        if(code === 400){
            msg = "Bad Request";
        }else if(code === 401){
            msg = "Un Authorised User";
        }else if(code === 402){
            msg = "Payment Required";
        }else if(code === 500){
            msg = " the server encountered an unexpected condition that prevented it from fulfilling the request";
        }
        return msg;
    }
    
}