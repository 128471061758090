import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import Flatpickr from "react-flatpickr";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Modal} from "react-bootstrap";
import BusyLoader from "../../Components/BusyLoader";
import $ from "jquery";
import {Constants} from "../Languages/Constants";

var attachmentList: any = [];
var attchIdx:number = 0;
var equiImages:any = [];
const ReservationProcessDetails = (props:any)=>{

    const [startDate,setStartDate] = useState("");
    const [companyName, setCompanyName] = useState('');
    const [ctq, setCTQ] = useState('');

    const [attachDocs, setAttachDocs] = useState<any>([]);
    const [insurencId,setInsurenceId] = useState("");

    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(CommonUtil.DATE_FORMAT);
    const [days,setDays] = useState(0);
    const [dailyRate,setDailyRate] = useState("");
    const [distanceRate,setDistanceRate] = useState("");
    const [distanceUnit,setDistanceUnit] = useState("km");
    const [engineRate,setEngineRate] = useState("");
    const [quantity,setQuantity] = useState("");
    const [serviceFee,setServiceFee] = useState("");
    const [eDistance,setEstimatedDistance] = useState("");

    const [owner,setOwner] = useState("");
    const [renter,setRenter] = useState("");
    const [cad,setCAD] = useState("");
    const [percent,setPercent] = useState("");
    const [claims,setClaims] = useState("");

    const [phoneNum,setPhoneNum] = useState("");
    const [contactName,setContactName] = useState("");
    const [mail,setMail] = useState("");
    const [imageSrc,setImageSrc] = useState("");

    const [availType,setAVailType] = useState("");
    const [defPrice,setDefPrice] = useState("");

    const [movePrice,setMovePrice] = useState("");
    const [addtionalPrice,setAdditionalPrice] = useState("");

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [departure,setDeparture] = useState("");
    const [arrival,setArrival] = useState("");

    const [departureIns,setDepartureIns] = useState("");
    const [arrivalIns,setArrivalIns] = useState("");

    const [departureMap,setDepartureMap] = useState("");
    const [arrivalMap,setArrivalMap] = useState("");

    const [reservationStartDate,setReservationStartDate] = useState("");
    const [reservationEndDate,setReservationEndDate] = useState("");

    const [reservationCode,setReservationCode] = useState("");
    const [reservationStatus,setReservationStatus] = useState("");

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);

    const [activationOdometer,setActivationOdometer] = useState("");
    const [deactivationOdometer,setDeactivationOdometer] = useState("");

    useEffect(()=>{
        equiImages = [];
        getReservationDetails();
        getOrganizationInfo();
       // getOrgServiceFees();
        getFinanceInfo();
        getUserDetails();
        getAvailabilityDetails();
        getEquipmentDetails();

    },[]);

    let getEquipmentDetails = ()=>{
        if(CommonUtil.SELECT_EQUIP_ID){
            let equipmentAPI:string = "api/session/rp/equipment/"+CommonUtil.SELECT_EQUIP_ID+"?rpKey="+CommonUtil.RP_KEY;
            let obj:any = {};
            NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
        }
    }

    let onGetEquipmentDetails = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setActivative(restData.activationInstructions?restData.activationInstructions:"-");
                setDeActive(restData.deactivationInstructions?restData.deactivationInstructions:"-");

                if (restData.attachments) {
                    attachmentList = [];
                    if (Array.isArray(restData.attachments)) {
                        attachmentList = restData.attachments;
                    } else {
                        attachmentList.push(restData.attachments);
                    }
                    if (attachmentList.length > 0) {
                        attchIdx = 0;
                        let docs:any = [];
                        attachmentList.forEach((docItem:any,idx:number)=>{
                            console.log(CommonUtil.POD_DOC_TYPE,CommonUtil.SELECT_RESERVATION_ID);
                            if(docItem.documentType == CommonUtil.POD_DOC_TYPE && CommonUtil.SELECT_RESERVATION_ID == docItem.reservationId){
                                docs.push(docItem);
                            }
                        });
                        setAttachDocs(docs);
                       // BusyLoader.showLoader();
                        //showAttachments();
                    }
                }
            }
        }
    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }

    let getAvailabilityDetails = ()=>{
        if(CommonUtil.SELECT_EQUIP_AVAIL_ID){
            let parkingAPI:string = "api/session/rp/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?rpKey="+CommonUtil.RP_KEY;
            NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetAvailabilityDetails,onLoginError);
        }
    }
    let onGetAvailabilityDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj){
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                setAVailType(restData.listingType);
                setDefPrice(restData.defaultCurrency);
               // setDeparture(restData.departureParkingLotAddress);
               // setArrival(restData.arrivalParkingLotAddress);
                setArrivalIns(restData.departureParkingLotInstructions);
                setDepartureIns(restData.arrivalParkingLotInstructions);
                setMovePrice(restData.movePrice);
                setAdditionalPrice(restData.additionalPriceExcessDays);
            }
        }

    }

    let getUserDetails = () => {
        let getUserAPI: string = 'api/session/rp/organization/user?rpKey='+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetUserDetails, onLoginError)
    }
    let onGetUserDetails = (dataObj: any) => {
        //console.log(dataObj)
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setContactName(restData.firstName+" "+restData.lastName);
                setMail(restData.loginId);
                //setLastName(restData.lastName);
               // setEmailId(restData.loginId);
            }
        }
    }

    let getFinanceInfo = () => {
        let financeAPI: string = "api/session/rp/organization/finance/getFinanceDetails?rpKey="+CommonUtil.RP_KEY;//+"&saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(financeAPI, "", "", "GET", true, onGetFinanceInfo, onLoginError);
    }

    let onGetFinanceInfo = (data:any)=> {
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                let docs = [];
                if (restData.documents) {
                    if (Array.isArray(restData.documents)) {
                        docs = restData.documents;
                    } else {
                        docs.push(restData.documents);
                    }
                    for(let idx:number=0;idx<docs.length;idx++){
                        if(docs[idx].documentType == CommonUtil.INSUR_DOC_TYPE ){
                            setInsurenceId(docs[idx].fullFilePath);
                            break;
                        }
                    }
                }
            }
        }
    }


    let getOrgServiceFees = ()=>{
        let listAPI:string = "api/organization/getServiceFees?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetEquipmentTypePricing,onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwner(restData.ownerRentalPercentage);
                setRenter(restData.renterRentalPercentage);
                setCAD(restData.ownerRepositioningMinimumCost);
                setPercent(restData.ownerRepositioningPercentage);
                setClaims(restData.claimFeePercentage);
            }
        }
    }

    let getReservationDetails = ()=>{
        let reservationAPI:string = "api/session/rp/reservation/"+CommonUtil.SELECT_RESERVATION_ID+"?rpKey="+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetReservationDetails,onLoginError);
    }

    let onGetReservationDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setStartDate(CommonUtil.getReservationMobileDate(restData.startDate));

                let endDate = CommonUtil.getReservationMobileDate(restData.endDate);
                let stDate = CommonUtil.getReservationMobileDate(restData.startDate);
                let days =  CommonUtil.getDays(stDate,endDate);

                setDays(days);
                setDistanceRate(restData.distanceRate);
                setDailyRate(restData.dailyRate);
                //setMovePrice(restData.movePrice);
                //setAdditionalPrice(restData.additionalPriceExcessDays);
                setServiceFee(restData.serviceFeePercentage);
                setEstimatedDistance(restData.estimatedDistancePerDay);

                setReservationStartDate(restData.startDate);
                setReservationEndDate(restData.endDate);

                setReservationCode(restData.reservationCode);
                setReservationStatus(restData.reservationStatus);

                setActivationOdometer(restData.activationOdometer);
                setDeactivationOdometer(restData.deactivationOdometer);

                setDeparture(restData.departureParkingLotAddress);
                setArrival(restData.arrivalParkingLotAddress);

            }
        }
    }
    let getOrganizationInfo = () => {
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = 'api/session/rp/organization?rpKey='+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError)
    }
    let onOrgDetailsSuccess = (dataObj: any) => {
        //console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setCompanyName(restData.orgName);
                setCTQ(restData.transportCommissionNumber);
                setPhoneNum(restData.phoneNumber);
                localStorage.setItem("country",restData.country);

                if(restData.logoPath){
                    var qParam:string = "objectPath="+restData.logoPath;
                    NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadSuccess,onLoginError);
                }
            }
        }
    }
    let onDownloadSuccess = (data:any)=>{
        setImageSrc(data);
    }

    let onChangeFromDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setFromDate(strDate);
        onChangeDates();
    }

    let onChangeToDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setToDate(strDate);
        onChangeDates();
    }

    let onChangeDates = ()=>{
        if(fromRef.current && toRef.current){
            var startDate = fromRef.current.flatpickr.selectedDates[0];
            var endDate = toRef.current.flatpickr.selectedDates[0];

            if (endDate) {
                endDate = new Date(endDate);
                fromRef.current.flatpickr.set("maxDate",endDate);
                // stDtOptions.maxDate = new Date(endDate);
            } else if (startDate) {
                toRef.current.flatpickr.set("minDate",new Date(startDate));
                //stDtOptions.minDate = new Date(startDate);
            }
        }

    }

    let onDownloadInsurence = (filePath:string)=>{
        //console.log(filePath);
        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }
    let onDownloadFP2506Success = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }



    let onClickSaveDriver = ()=>{

    }

    let totalServiceFree = ()=>{
        return Math.ceil(((days*Number(dailyRate))+(days*Number(eDistance)*Number(distanceRate)))*(Number(serviceFee)/100));
    }
    let totalAmount = ()=>{
        let duration = (days*Number(dailyRate));
        let distance = days*Number(eDistance)*Number(distanceRate);
        let serviceFree = distance+duration+((days*Number(dailyRate))+(days*Number(eDistance)*Number(distanceRate)))*(Number(serviceFee)/100);
        return Math.ceil(serviceFree);
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }
    return(
        <>
            <div className="container-fluid">
                <section>
                <div className="row">
                    <div className="col-12">
                        <h6 className="mb-4">Rental Information</h6>
                    </div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 text-center">
                        <div className="row">
                            <div className="col-12 location1 p-0 "><i className="fa fa-map-marker" style={{color:"red"}}></i></div>
                        </div>
                        <div className="row">
                            <div className="col-12 p-0 "><i className="fa fa-map-marker"></i></div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-6 col-md-6 col-lg-9 col-xl-9">
                        <div className="row">
                            <div className="col-12 p-0"><span style={{fontWeight:"bold"}}>{CommonUtil.getMobileDBDate(reservationStartDate)}</span><br />{departure}</div>
                        </div>
                        <br />
                            <div className="row">
                                <div className="col-12 p-0 "><span style={{fontWeight:"bold"}}>{CommonUtil.getMobileDBDate(reservationEndDate)}</span><br />{arrival}
                                </div>
                            </div>
                    </div>

                </div>
            </section>
                <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Owner Information</h6>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-2 col-sm-2 col-md-1">
                        <img src={imageSrc} alt="" style={{width: "50px"}} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">
                        <div className="row">
                            <div className="col-md-8">
                                <span className="details-head"><strong>{companyName}</strong></span>
                                <div className="rating" style={{display:"none"}}>
                                    <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                    className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                    className="fa fa-star-o"></i> 4 | 39 Ratings
                                </div>

                            </div>
                            <div className="col-md-4" style={{display:"none"}}>
                                <span className="details-head">Insurance Proof</span><label>
                                {insurencId?<a style={{color:"blue"}} onClick={()=>{onDownloadInsurence(insurencId)}}><i className="fa fa-eye"></i>
                                    View Document
                                </a>:""}</label>
                            </div>


                            <div className="col-md-4">
                                <span className="details-head">Contact Name</span><label>{contactName}</label>
                            </div>
                            <div className="col-md-4">
                                <span className="details-head">Contact Email</span><label>{mail}</label>
                            </div>
                            <div className="col-md-4">
                                <span className="details-head">Contact Phone</span><label>{phoneNum}</label>
                            </div>
                            <div className="col-md-4">
                                <span className="details-head">Transport Commission Number</span><label>{ctq}</label>
                            </div>

                        </div>


                    </div>
                </div>

            </section>

                <section>
                    <div className="row">
                        <div className="col-sm-12">
                            <h6>Proof of Delivery Documents</h6>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-7 col-12 mt-2">
                            <div className="form-group row">

                                <div className="col-sm-12">
                                    <div className="gallery flex documents-gallary">
                                        {attachDocs.map((item: any, idx: number) => {
                                            if (item && (item.documentType == CommonUtil.POD_DOC_TYPE)) {
                                                var list = (
                                                    <div className="img-fluid  document-box" key={idx}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                 onClick={() => { onDownloadInsurence(item.fullFilePath) }} />


                                                        </div>
                                                        <label className={"financial-download-label"} onClick={() => { onDownloadInsurence(item.fullFilePath) }}>{item.documentName}</label>
                                                    </div>
                                                )
                                                return list
                                            }
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                <div className="row">
                    <div className="col-12">
                        <h6 className="mb-2">Hubodometer</h6>
                    </div>
                    <div className="col-12">
                        <div className="col-md-4">
                            <span className="details-head">At Activation:</span><label>{activationOdometer}</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="col-md-4">
                            <span className="details-head">At Deactivation:</span><label>{deactivationOdometer}</label>
                        </div>
                    </div>
                </div>
            </section>
            </div>

        </>
    );
}

export default ReservationProcessDetails;
