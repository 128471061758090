import React from 'react'

const BrokerStatusRenderer = (props: any) => {
    return (
        <div className={"cellRenderHeader"}>

            <p className={`badge ${props.value === "Active" ? 'bg-success-light' : 'bg-danger-light'}`}>{(props.value)}</p>

        </div>
    )
}

export default BrokerStatusRenderer;