import React, {useEffect, useRef, useState} from "react";
import {NetworkManager} from "../../Components/NetworkManager";
import {CommonUtil} from "../../utils/CommonUtil";
import $ from "jquery";
import SingleSelectComp from "../../Components/SingleSelectComp";
import SingleSelect from "../../Components/SingleSelect";
import Select from "react-select";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import {useNavigate, useSearchParams} from "react-router-dom";
import BusyLoader from "../../Components/BusyLoader";
import {loadMapApi} from "../../utils/GoogleMapsUtils";
import LoginHeader from "./LoginHeader";
import {Constants} from "../Languages/Constants";



const ResetPage = ()=>{

    const navigate = useNavigate();

    const chkProfile = useRef<HTMLInputElement>(null);
    const chkAuthorize = useRef<HTMLInputElement>(null);


    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);
    var sourceRef = useRef<HTMLInputElement>(null);
    var passRef = useRef<HTMLInputElement>(null);
    var cPassRef = useRef<HTMLInputElement>(null);


    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmailId] = useState("");
    const [contactNumber,setContactNumber] = useState("");
    const [sourceData,setSourceData] = useState(CommonUtil.sourceData);
    const [sourceState,setSourceState] = useState(true);
    const [department,setDepartment] = useState("");
    const [password,setPassword] = useState("");
    const [cPass,setCPass] = useState("");
    const [invitationCode,setInvitationCode] = useState("");

    const [terms,setTerms] = useState("none");
    const [searchParams] = useSearchParams();
    //const [terms,setTerms] = useState("none");

    var token:any = "";
    useEffect(()=>{
        // loadGooglePlaces();
        //console.log(NetworkManager.serverPath);
        const params = new URLSearchParams(window.location.search) // id=123
        token = params.get('token');
        console.log(params);
        console.log(token);

        if(NetworkManager.serverPath){
            getTokenUrl();
        }else{
            getServerPath();
        }


    },[]);

    let getServerPath = ()=>{
        $.ajax({
            url: "server.xml",
            beforeSend: function () {
            },
            success: function (data, statusCode, jqXHR) {
                var xmlResponse = $(data).find('Server');
                for(var i=0;i<xmlResponse.length;i++) {
                    var item = xmlResponse[i];
                    let routeUrl = $(item).find("URL").text();

                    console.log(routeUrl);
                    NetworkManager.serverPath = routeUrl;

                    getTokenUrl();

                }
            },
            error: function () {
            }
        });
    }

    let getTokenUrl = ()=>{
        const params = new URLSearchParams(window.location.search) // id=123
        token = params.get('token');
        console.log("token:"+token);
        // token = "020100bc-615c-4a7b-8336-d8769e9e11d0";
        var inviteUrl:string = "api/session/organizationInvitation?invitationCode="+token;
       //NetworkManager.sendJsonRequest(inviteUrl,"","","GET",false,onGetInfo,onLoginError);
    }

    let onGetInfo = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj){
            var restData = JSON.parse(dataObj);
            if(restData.statusCode){
                ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
            }else{
                setFirstName(restData.firstName);
                setLastName(restData.lastName);
                setDepartment(restData.department);
                setEmailId(restData.loginId);
                setContactNumber(restData.contactNumber);
                setInvitationCode(restData.invitationCode);
            }
        }
    }

    let onClickCreateProfile = ()=>{
       // window.location.assign("http://vhubui.s3-website-us-west-2.amazonaws.com/menu")
       // loginUser();
        /*let siteOrigin:string = window.location.origin;
        // window.location.assign(siteOrigin);
        localStorage.setItem("userLogin","true");
        siteOrigin = siteOrigin+"/menu";
        window.location.assign(siteOrigin);*/

        if(password != cPass){
            ToastManager.showToast("Password and confirm password does not match","Error");
            return;
        }
        //(chkProfile.current?.checked)?setTerms("none"):setTerms("block");
        const params = new URLSearchParams(window.location.search) // id=123
        token = params.get('token');
        if(token && password && cPass && (password == cPass)){
            var obj:any = {};
            obj.token = token;
            obj.oldPassword = password;
            obj.newPassword = cPass;
            //obj.userName = email;
            //obj.sendPromotionalMails = ((chkAuthorize.current?.checked)?"Y":"N");
           // obj.termsConditionsAccepted = ((chkProfile.current?.checked)?"Y":"N");
            NetworkManager.sendJsonRequest("api/session/resetPassword",obj,"","POST",true,onOrgCreateSuccess,onLoginError);
        }
    }

    let onOrgCreateSuccess = (data:any)=>{
       // console.log(data);
        if(data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                ToastManager.showToast("Password reset successfully","Info");
                gotoLoginPage();
            }
        }else{
            ToastManager.showToast("Password reset successfully","Info");
            gotoLoginPage();
        }
    }
    let loginUser = ()=>{
        var restObj:any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
        restObj.userName = CommonUtil.trim(email);
        restObj.password = CommonUtil.trim(password);
        NetworkManager.sendJsonRequest("api/auth/login",restObj,"","POST",true,(dataObj:any)=>{
            if(dataObj){
                var restData = JSON.parse(dataObj);
                if(restData){
                    if(restData.statusCode){
                        //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    }else{
                        // console.log(restData);
                        /*localStorage.setItem("user",email);
                        localStorage.setItem("userLogin","true");
                        localStorage.setItem("token",restData.token);
                        localStorage.setItem("refreshToken",restData.refreshToken);
                        localStorage.setItem("orgId",restData.saasOrgId);
                        localStorage.setItem("saasorgId",restData.saasOrgId);
                        localStorage.setItem("orgName",restData.orgName);
                        localStorage.setItem("userId", restData.userId);
                        localStorage.setItem("root",restData.root);
                        localStorage.setItem("ViewTab", "false");*/

                        gotoLoginPage();
                    }
                }
            }

        },onLoginError);
    }
    let gotoLoginPage = ()=>{
        ToastManager.showToast("User Activated Successfully","Info");
        // navigate("/");
        setTimeout(()=>{
            let siteOrigin:string = window.location.origin;
           // window.location.assign(siteOrigin);
            siteOrigin = siteOrigin;//+"/?requestor=menu";//+"/menu";
            window.location.assign(siteOrigin);
        });
    }


    let onLoginError = (err:any)=>{
        console.log(err);
    }

    return(
        <div style={{overflow:"hidden"}}>
            <div className="row">
                <div className="row">
                    <LoginHeader />
                </div>
                <div className="row">
                    <div className="page-content-wrapper" style={{marginTop:"50px"}}>

                        <div className={"row"}>
                            <div className="container" style={{width: "60%"}}>
                                <section className="mt-3">
                                    <h6 className="text-center">Reset Password Page</h6>
                                </section>
                                <section className="mt-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{CommonUtil.getMessageText(Constants.PASS,"Password")}</label> <span className="clsFormMandatory"></span>
                                                <input ref={passRef} type="password" className="form-control clsStandardText clsFormInputControl"
                                                       placeholder={CommonUtil.getMessageText(Constants.PASS,"Password")} value={password} onChange={(e)=>{
                                                    setPassword(e.currentTarget.value);CommonUtil.isMandatory(passRef);}} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <span className="grey-text css-16ihq8s">{CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD,"Confirm Password")}</span> <span className="clsFormMandatory"></span>
                                                <input ref={cPassRef} type="password" className="form-control clsStandardText clsFormInputControl"
                                                       placeholder={CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD,"Confirm Password")} value={cPass}  onChange={(e)=>{
                                                    setCPass(e.currentTarget.value);CommonUtil.isMandatory(cPassRef);}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 clsAlignRight" >
                                            <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickCreateProfile}>{CommonUtil.getMessageText(Constants.SUBMIT,"Submit")}</button>
                                        </div>

                                    </div>
                                </section>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*<div className="container-fluid" style={{display:"none",background:"#f0f0f0",overflow:"hidden",height:"100vh",padding:"0px"}}>
                <div style={{width:"100%",background:"#FFF",padding:"0px"}} className="container-fluid">
                    <div className="row fs-7 topbar-breadcrumbs" style={{paddingLeft:"3px",paddingTop:"7px"}}>
                        <br />
                        <span>Reset Password Page</span>
                    </div>
                </div >
                <div className="container-fluid" style={{width:"600px",background:"#FFF",paddingRight:"0px",paddingLeft:"40px",paddingBottom:"40px"}}>
                    <div  className="container-fluid">
                        <div className="row fs-7" style={{display:"none"}}>
                            <nav className="navbar fixed-top bg-white">
                                <div className="container-fluid">
                                    <a className="navbar-brand"  target="_blank">
                                        <img src="../../logo.png"  />
                                    </a>
                                    <div className="navbar-nav ms-md-auto pe-0">
                                        <p className=" grey-text fs-7" style={{marginTop: "0.3125rem",marginBottom: "0.3125rem;"}}></p>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="row fs-7" style={{marginTop:"15px",paddingTop:"20px"}}>
                        </div>
                    </div>
                    <div  className="container-fluid" style={{paddingTop:"20px"}}>
                    </div>
                    <div  className="container-fluid" >





                        <div className="col-md-2"></div>


                    </div>
                </div>

            </div>*/}
        </div>
    )
}

export default ResetPage;