import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import DraggableComponent from "../../Components/DraggableComponent";
import AddNewReleaseModal from "./AddNewReleaseModal";
import { NetworkManager } from "../../Components/NetworkManager";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { useReleaseInspectorContext } from "../../Components/ReleaseContext";
import ToastManager from "../../Components/ToastManager";
import PhoneInput from "react-phone-input-2";
const AddNewReleaseDetailsModal = ({
  showNewRelease,
  onCloseNewRelease,
  data,
  data1,
  data2,
  onSubmitDocument,
  fromDetails,
  closeModal,
  closeModalWindow,
  show,
  openSecondModal,
}: any) => {
  //const [show, setShow] = useState<any>(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [careers, setCareers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const qparam = CommonUtil.getInterchangeDocumentId();
  const { state, dispatch } = useReleaseInspectorContext();
  const initState = {
    inspectionOrgId: "",
    equipmentId: "",
    inspectorId: "",
    docId: "",
    driverName: "",
    truckNumber: "",
    loadStatus: "",
    manifestNumber: "",
    brokerName: "",
    carrierName: "",
    sealNumber: "",
    brokerId: "",
    carrierId: "",
    otherCarrier: "",
    otherBroker: "",
    driverPhoneNumber: "",
  };
  const [formData, setFormData] = useState(initState);
  const onCloseModal = () => {
    //setShow(false);
    //closeModalWindow();
    setShowConfirm(true);
  };

  useEffect(() => {
    getCareerList();
    getBrokerList();
  }, []);

  let getGeneralDocDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getGeneralDocDetails?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetGeneralDocDetails,
      onLoginError
    );
  };

  const onGetGeneralDocDetails = (dataObj: any) => {
    try {
      if (dataObj) {
        let restData: any = JSON.parse(dataObj);
        dispatch({ type: "isReferRequired", payload: restData?.isReefer });
      }
    } catch (e) {}
  };

  const getCareerList = () => {
    var orgUrl = `api/webInspectionDetails/carriers?saasOrgId=${CommonUtil.getSaasOrgId()}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetAllCareers,
      onLoginError
    );
  };

  const getBrokerList = () => {
    var orgUrl = `api/webInspectionDetails/brokers?saasOrgId=${CommonUtil.getSaasOrgId()}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetAllBrokers,
      onLoginError
    );
  };

  const onGetAllCareers = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);

      if (restData) {
        setCareers(restData);
      }
    }
  };

  const onGetAllBrokers = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);

      if (restData) {
        setBrokers(restData);
      }
    }
  };

  const onGetReleaseDetails = (dataObj: any) => {
    let resp = JSON.parse(dataObj);
    if (resp?.statusCode === 500 || resp?.status === 404) {
      ToastManager.showToast("Something went wrong please try again", "Error");
    } else {
      //dispatch({ type: "clearData" });
      dispatch({
        type: "loadStatus",
        payload: resp.loadStatus === "unloaded" ? false : true,
      });
      onCloseNewRelease(resp);
      openSecondModal();
      setFormData(initState);
      getMasterData();
    }
  };

  const getLastRelease = () => {
    NetworkManager.sendJsonRequest(
      `api/webInspectionDetails/getLastReleasePayload?docId=${qparam}`,
      "",
      "",
      "GET",
      true,
      onLatestReleaseTasks,
      onLoginError
    );
  };

  const getMasterData = () => {
    NetworkManager.sendJsonRequest(
      `api/webInspectionDetails/getMasterTasks`,
      "",
      "",
      "GET",
      true,
      onMasterTasks,
      onLoginError
    );
  };

  // Latest
  const onLatestReleaseTasks = (dataObj: any) => {
    //dispatch({ type: "isOpen", payload: true });
    if (dataObj) {
      let restData: any = CommonUtil.parseData(dataObj);
      if (restData) {
        dispatch({ type: "UPDATELATESTDATA", payload: restData });
      }
    }
  };

  // Master data
  const onMasterTasks = (dataObj: any) => {
    dispatch({ type: "isOpen", payload: true });
    if (dataObj) {
      let restData: any = CommonUtil.parseData(dataObj);
      if (restData) {
        dispatch({ type: "UPDATEMASTERDATA", payload: restData });
        getLastRelease();
      }
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setFormData(initState);
    setShowConfirm(false);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    //setShow(false);
    closeModalWindow();
    setFormData(initState);
    dispatch({ type: "clearData" });
  };

  const finalSuccess = () => {
    //setShow(false);
    closeModalWindow();
  };

  const handleloadStatus = (selectedLoadStatus: any) => {
    const loadStatusValue = selectedLoadStatus ? selectedLoadStatus.value : "";
    setFormData({ ...formData, loadStatus: loadStatusValue });
  };

  const handleBrokerChange = (selectedBroker: any) => {
    if (selectedBroker?.brokerName !== "Others") {
      setFormData({
        ...formData,
        brokerName: selectedBroker.brokerName,
        brokerId: selectedBroker.brokerId,
      });
    } else {
      setFormData({
        ...formData,
        brokerName: selectedBroker.brokerName,
        brokerId: selectedBroker.brokerId,
        otherBroker: "",
      });
    }
  };

  const handleCarrierChange = (selectedCarrier: any) => {
    if (selectedCarrier?.carrierName !== "Others") {
      setFormData({
        ...formData,
        carrierName: selectedCarrier.carrierName,
        carrierId: selectedCarrier.carrierId,
      });
    } else {
      setFormData({
        ...formData,
        carrierName: selectedCarrier.carrierName,
        carrierId: selectedCarrier.carrierId,
        otherCarrier: "",
      });
    }
  };

  const options = [
    { label: "Loaded", value: "loaded" },
    { label: "Unloaded", value: "unloaded" },
  ];

  const handleSubmit = (event: any) => {
    event.preventDefault();
    getGeneralDocDetails();
    if (
      formData.driverName &&
      formData.truckNumber &&
      formData.loadStatus &&
      formData.brokerName &&
      formData.carrierName &&
      formData.driverPhoneNumber &&
      formData.sealNumber &&
      formData.manifestNumber &&
      (formData.carrierName === "Others" ? formData.otherCarrier : true) &&
      (formData.brokerName === "Others" ? formData.otherBroker : true)
    ) {
      const requestBody = {
        driverName: formData.driverName,
        truckNumber: formData.truckNumber,
        loadStatus: formData.loadStatus,
        docId: CommonUtil.getInterchangeDocumentId(),
        manifestNumber: formData.manifestNumber,
        brokerName: formData.brokerName,
        carrierName: formData.carrierName,
        sealNumber: formData.sealNumber,
        inspectorId: formData.inspectorId,
        brokerId: formData.brokerId,
        carrierId: formData.carrierId,
        otherCarrier: formData.otherCarrier,
        otherBroker: formData.otherBroker,
        driverPhoneNumber: formData.driverPhoneNumber,
      };
      NetworkManager.sendJsonRequest(
        `api/webInspectionDetails/createEvents?docId=${requestBody.docId}`,
        requestBody,
        "",
        "POST",
        true,
        onGetReleaseDetails,
        onLoginError
      );
    } else {
      ToastManager.showToast("Please fill all the mandatory fields", "Error");
    }
  };

  return (
    <div>
      <Modal
        show={showNewRelease}
        dialogAs={DraggableComponent}
        centered
        size="lg"
      >
        <div className="modal-content">
          <Modal.Header className="modal-header" closeButton={false}>
            <h5 className="modal-title mt-0">
              {CommonUtil.getMessageText(
                Constants.RELEASE_INSPECTION,
                " Add New Release"
              )}
            </h5>
            <button
              type="button"
              className="close"
              style={{ marginLeft: "0px" }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="container">
                <div className="col-lg-12 ">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Driver Name
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            name="driverName"
                            className="form-control clsFormInputControl"
                            placeholder={CommonUtil.getMessageText(
                              Constants.ENTER_DRIVER_NAME,
                              "Enter Driver"
                            )}
                            value={formData?.driverName}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Truck Number
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            name="truckNumber"
                            className="form-control clsFormInputControl"
                            placeholder={CommonUtil.getMessageText(
                              Constants.ENTER_TRUCK_NUMBER,
                              "Enter Truck Number"
                            )}
                            value={formData?.truckNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Load Status
                        </label>
                        <div className="col-sm-12">
                          <SingleSelectComp
                            selectedValue={options.find(
                              (option) => option.value === formData.loadStatus
                            )}
                            handleChange={handleloadStatus}
                            dataSource={options}
                            label="label"
                            value="value"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Manifest/Order
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            name="manifestNumber"
                            className="form-control clsFormInputControl"
                            placeholder={CommonUtil.getMessageText(
                              Constants.ENTER_MANIFEST_NUMBER,
                              "Enter Manifest/Order"
                            )}
                            value={formData?.manifestNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="col-sm-12 col-form-label">
                        Broker Name
                      </label>
                      <SingleSelectComp
                        dataSource={brokers}
                        label="brokerName"
                        value="brokerId"
                        selectedValue={{
                          brokerId: formData.brokerId,
                          brokerName: formData.brokerName,
                        }}
                        handleChange={handleBrokerChange}
                      />
                      <br />
                      {formData?.brokerName == "Others" && (
                        <input
                          type="text"
                          className="form-control"
                          value={formData.otherBroker}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              otherBroker: e.target.value,
                            })
                          }
                          placeholder="Others Broker"
                        />
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label className="col-sm-12 col-form-label">
                        Carrier Name
                      </label>
                      <SingleSelectComp
                        dataSource={careers}
                        label="carrierName"
                        value="carrierId"
                        selectedValue={{
                          carrierId: formData.carrierId,
                          carrierName: formData.carrierName,
                        }}
                        handleChange={handleCarrierChange}
                      />
                      <br />
                      {formData.carrierName == "Others" && (
                        <input
                          type="text"
                          className="form-control"
                          value={formData.otherCarrier}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              otherCarrier: e.target.value,
                            })
                          }
                          placeholder="Others Career"
                        />
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          {CommonUtil.getMessageText(
                            Constants.PHONE_NO,
                            "Phone Number"
                          )}
                        </label>
                        <div className="col-sm-12">
                          <PhoneInput
                            country={"us"}
                            countryCodeEditable={false}
                            value={formData.driverPhoneNumber}
                            placeholder={CommonUtil.getMessageText(
                              Constants.ENTER_PHONE_NO,
                              "Enter Phone Number"
                            )}
                            inputClass={"input-phone"}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                driverPhoneNumber: e,
                              });
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            disableCountryGuess={true}
                            disableDropdown={true}
                            disableSearchIcon={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Seal Number
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="number"
                            name="sealNumber"
                            onChange={handleInputChange}
                            className="form-control clsFormInputControl"
                            placeholder={CommonUtil.getMessageText(
                              Constants.ENTER_SEL_NUMBER,
                              "Enter Seal Number"
                            )}
                            value={formData.sealNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {!fromDetails ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => {
                      closeModal();
                      setFormData(initState);
                    }}
                  >
                    {CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save and Proceed
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onCloseNewRelease}
                >
                  Pdf Download
                </button>
              )}
            </div>
          </Form>
        </div>
      </Modal>

      <AddNewReleaseModal
        show={show}
        //show={showNewRelease}
        onCloseModal={onCloseModal}
        finalSuccess={finalSuccess}
        data={data}
        data1={data1}
        data2={data2}
        onSubmitDocument={() => {
          onSubmitDocument();
          //setShow(false);
        }}
      />

      {/* confirm modal */}

      <Modal show={showConfirm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to stop creating inspection process?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            NO
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewReleaseDetailsModal;
