import React, { useState, useRef } from "react";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import AttachmentsViewer from "./AttachmentsViewer";

const NewReeferValues = ({ data, onAddPictures }: any) => {
  const { state, dispatch } = useReleaseInspectorContext();
  const [fuelValue, setFuelValue] = useState<any>(0);

  const initState = [
    {
      taskStatus: "",
    },
  ];
  const [formData, setFormData] = useState(initState);
  let qPath = CommonUtil.getInterchangeDocumentId();
  let taskItem: any = {
    taskId: data.taskId,
    taskStatus: "",
  };
  let attachments: any = {
    attachmentUploads: [],
    attachmentDamageDescription: null,
  };
  try {
    const item = state?.reeferInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    taskItem = {
      taskId: item.taskId,
      taskStatus: item?.taskStatus,
    };
  } catch (e) {
    taskItem = {
      taskId: data.taskId,
      taskStatus: "",
    };
  }

  try {
    const item = state?.reeferInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    attachments = {
      attachmentUploads: { fullFilePath: item?.attachments.attachmentUploads },
      attachmentDamageDescription: item?.attachents.attachmentDamageDescription,
    };
  } catch (e) {
    attachments = {
      attachmentUploads: [],
      attachmentDamageDescription: "",
    };
  }

  const handleChange = (value: any) => {
    dispatch({
      type: "reeferInspection",
      payload: {
        taskId: value.taskId,
        taskStatus: value.taskStatus,
        status: value.status,
        description: value.description,
      },
    });
  };

  return (
    <>
      <tr>
        <td>{data?.taskName}</td>
        <td>
          <div className="d-flex text-center" style={{ marginLeft: "10px" }}>
            {data?.taskName === "SwitchStatus" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    //value={taskItem?.taskStatus}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                        taskName: data?.taskName,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    //value={taskItem?.taskStatus}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}

            {data?.taskName === "Presets" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Temprature" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Existing Fault Code" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Pretrip" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Belts And Battery" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Oil Level" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}
            {data?.taskName === "Fuel Tank" && (
              <>
                <div>
                  <input
                    className="radio-size"
                    required
                    type="radio"
                    name={data?.taskName}
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "ok"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "ok",
                        taskId: data?.taskId,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    className="radio-size"
                    required
                    name={data?.taskName}
                    type="radio"
                    checked={
                      state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0].taskStatus === "fail"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChange({
                        ...taskItem,
                        taskStatus: "fail",
                        taskId: data?.taskId,
                      })
                    }
                    style={{ marginLeft: "45px" }}
                  />
                </div>
              </>
            )}

            {/* {data?.taskName === "Temp Control" && (
              <input
                type="text"
                className="form-control"
                required
                value={
                  state?.reeferInspection.task.filter(
                    (item: any) => item.taskId === data?.taskId
                  )[0].status
                }
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    status: e.target.value,
                    taskId: data?.taskId,
                  })
                }
              />
            )} */}
            {data?.taskName === "Temp Control" && (
              <div
                className="form-check form-switch"
                style={{ paddingLeft: "0px", textAlign: "left" }}
              >
                <div className="d-flex justify-content-between">
                  <div style={{ width: "110px" }}>
                    <label
                      htmlFor="flexSwitchCheckDefaultTwo"
                      style={{ fontWeight: "normal" }}
                    >
                      Discharge
                    </label>
                  </div>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefaultTwo"
                      onChange={(e) =>
                        handleChange({
                          ...taskItem,
                          taskStatus: e.target.checked
                            ? "Returns Air"
                            : "Discharge",
                          taskId: data?.taskId,
                        })
                      }
                      defaultValue={"Discharge"}
                      checked={
                        state?.reeferInspection?.task?.filter(
                          (item: any) => item.taskId === data?.taskId
                        )[0].taskStatus === "Returns Air"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefaultTwo"
                      style={{ fontWeight: "normal" }}
                    >
                      Returns Air
                    </label>
                  </div>
                </div>
              </div>
            )}
            {/* {data?.taskName === "Run Mode" && (
              <input
                type="text"
                className="form-control"
                required
                value={
                  state?.reeferInspection.task.filter(
                    (item: any) => item.taskId === data?.taskId
                  )[0].status
                }
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    status: e.target.value,
                    taskId: data?.taskId,
                  })
                }
              />
            )} */}

            {data?.taskName === "Run Mode" && (
              <div
                className="form-check form-switch"
                style={{ paddingLeft: "0px", textAlign: "left" }}
              >
                <div className="d-flex justify-content-between">
                  <div style={{ width: "120px" }}>
                    <label
                      htmlFor="flexSwitchCheckDefault"
                      style={{ fontWeight: "normal" }}
                    >
                      Continuous
                    </label>
                  </div>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      onChange={(e) =>
                        handleChange({
                          ...taskItem,
                          taskStatus: e.target.checked
                            ? "Start Stop"
                            : "Continuous",
                          taskId: data?.taskId,
                        })
                      }
                      defaultValue={"Continuous"}
                      checked={
                        state?.reeferInspection?.task?.filter(
                          (item: any) => item.taskId === data?.taskId
                        )[0].taskStatus === "Start Stop"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                      style={{ fontWeight: "normal" }}
                    >
                      Start Stop
                    </label>
                  </div>
                </div>
              </div>
            )}

            {data?.taskName === "Presets Value" && (
              <input
                type="text"
                className="form-control"
                required
                value={
                  state?.reeferInspection.task.filter(
                    (item: any) => item.taskId === data?.taskId
                  )[0].taskStatus
                }
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: e.target.value,
                    taskId: data?.taskId,
                  })
                }
              />
            )}
            {data?.taskName === "Engine Hours" && (
              <input
                type="text"
                className="form-control"
                required
                value={
                  state?.reeferInspection.task.filter(
                    (item: any) => item.taskId === data?.taskId
                  )[0].taskStatus
                }
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: e.target.value,
                    taskId: data?.taskId,
                  })
                }
              />
            )}
            {data?.taskName === "Engine Temperature" && (
              <input
                type="number"
                className="form-control"
                required
                value={
                  state?.reeferInspection.task.filter(
                    (item: any) => item.taskId === data?.taskId
                  )[0].taskStatus
                }
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: e.target.value,
                    taskId: data?.taskId,
                  })
                }
              />
            )}
            {data?.taskName === "Fuel Level" && (
              <>
                <tr>
                  <td>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      step="0.5"
                      id="customRange3"
                      defaultValue={0}
                      required
                      value={
                        state?.reeferInspection.task.filter(
                          (item: any) => item.taskId === data?.taskId
                        )[0].taskStatus
                      }
                      onChange={(e) =>
                        handleChange({
                          ...taskItem,
                          taskStatus: e.target.value,
                          taskId: data?.taskId,
                        })
                      }
                      style={{ width: "60%" }}
                    />
                    <input
                      type="number"
                      required
                      value={
                        state?.reeferInspection.task.filter(
                          (item: any) => item.taskId === data?.taskId
                        )[0].taskStatus
                      }
                      //value={fuelValue}
                      style={{
                        width: "30%",
                        marginLeft: "10px",
                        position: "relative",
                        top: "-10px",
                      }}
                      onChange={(e) =>
                        handleChange({
                          ...taskItem,
                          taskStatus:
                            +e.target.value > 100 ? "100" : e.target.value,
                          taskId: data?.taskId,
                        })
                      }
                    />
                    &nbsp; &nbsp; &nbsp;
                    <div className="d-flex justify-content-start">
                      {state?.reeferInspection.task.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0]?.attachments?.attachmentUploads?.length ? (
                        <div
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            padding: "4px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            borderRadius: "3px",
                            marginRight: "5px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            AttachmentsViewer.show({
                              attachmentUploads:
                                state?.reeferInspection?.task?.filter(
                                  (item: any) => item.taskId === data?.taskId
                                )[0]?.attachments?.attachmentUploads,
                              attachmentDamageDescription:
                                state?.reeferInspection?.task?.filter(
                                  (item: any) => item.taskId === data?.taskId
                                )[0]?.attachments?.attachmentDamageDescription,
                              isDeleteButton: true,
                              resetData: "reeferInspection",
                            })
                          }
                        >
                          {
                            state?.reeferInspection?.task?.filter(
                              (item: any) => item.taskId === data?.taskId
                            )[0]?.attachments?.attachmentUploads?.length
                          }
                        </div>
                      ) : null}

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onAddPictures(data?.taskId, CommonUtil.RELEASES_PICS);
                          dispatch({
                            type: "setTaskId",
                            payload: data?.taskId,
                          });
                        }}
                      >
                        &nbsp;
                        <i className="fa-solid fa-cloud-arrow-up"></i>
                      </span>
                      {state?.reeferInspection?.task?.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0]?.attachments?.attachmentUploads?.length > 0 && (
                        <input
                          type="textarea"
                          required
                          value={
                            state?.reeferInspection?.task?.filter(
                              (item: any) => item.taskId === data?.taskId
                            )[0]?.attachments?.attachmentDamageDescription
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "reeferInspection",
                              payload: {
                                taskId: data?.taskId,
                                attachments: {
                                  attachmentDamageDescription: e.target.value,
                                },
                              },
                            })
                          }
                          style={{
                            width: "80%",
                            marginLeft: "10px",
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default NewReeferValues;
