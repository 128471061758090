import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { getSelectedRangeRate, CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import Flatpickr from "react-flatpickr";
import SingleSelectComp from "../../Components/SingleSelectComp";
import $ from "jquery";
import AGGrid from "../../Components/AGGrid";
import { NetworkManager } from "../../Components/NetworkManager";
import { useNavigate } from "react-router-dom";
import { Events } from "../Languages/Events";
import BusyLoader from "../../Components/BusyLoader";
import ToastManager from "../../Components/ToastManager";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";

var dateformat: object = { dateFormat: "m-d-Y", altFormat: 'M-d-Y' };
var equipmentId: string = "";
var insurPolicies: any = [];
var selEquipmentId: string = "";
let equips: any = [];
let saasOrg: string = "";
var bankAccounts: any = [];
var bank1Currency = "";
var bank2Currency = "";
var isTaxExcemptionAvail: boolean = true;

const AvailabilityCreateModal = ({ modalShow, onCloseAvailPopup, availabilityType }: any) => {

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);
    const [stDate, setStDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateOptions, setDateOptions] = useState(dateformat);
    const [isDisabled, setDisabled] = useState(false);

    const qtyRef = useRef<HTMLInputElement>(null);
    const [quantity, setQuantity] = useState("");

    const noOfDayRef = useRef<HTMLInputElement>(null);
    const [noOfDays, setNoOfDays] = useState("");

    const [loadTypes, setLoadTypes] = useState(CommonUtil.LOAD_TYPE);
    const [loadTypeObj, setLoadTypeObj] = useState<any>(null);

    const [parkingLots, setParkingLots] = useState<any>([]);
    const [selDepartureObj, setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj, setSelArrivalObj] = useState<any>(null);

    const [currencies, setCurrencies] = useState(CommonUtil.DEF_CURRENCY);
    const [currencyObj, setCurrencyObj] = useState<any>(null);

    const chkAllowRef = useRef<HTMLInputElement>(null);
    const chkTerms = useRef<HTMLInputElement>(null);
    const priceRef = useRef<HTMLInputElement>(null);
    const [addtionalPrice, setAdditionalPrice] = useState("");

    const dailyRef = useRef<HTMLInputElement>(null);
    const [dailyRateAvl, setDailyRateAvl] = useState("");

    const weeklyRef = useRef<HTMLInputElement>(null);
    const [weeklyRateAvl, setWeeklyRateAvl] = useState("");

    const monthlyRef = useRef<HTMLInputElement>(null);
    const [monthlyRateAvl, setMonthlyRateAvl] = useState("");

    const distanceRef = useRef<HTMLInputElement>(null);
    const [distanceRate, setDistanceRate] = useState("");

    const [availableRental, setAvailableRental] = useState(CommonUtil.AVAILABLE_RENTAL);

    const movePriceRef = useRef<HTMLInputElement>(null);
    const [movePrice, setMovePrice] = useState<any>("");

    const [platformFeesPaidBy, setPlatformFeesPaidBy] = useState<string>("Repositioner");

    const engineRef = useRef<HTMLInputElement>(null);
    const [engineRate, setEngineRate] = useState("");

    const eDisRef = useRef<HTMLInputElement>(null);
    const [eDisRate, setEDisRate] = useState("");

    const priceKmRef = useRef<HTMLInputElement>(null);
    const [priceKm, setPriceKm] = useState("");

    const [defDailyRate, setDefDailyRate] = useState("");
    const [defWeeklyRate, setDefWeeklyRate] = useState("");
    const [defMonthlyRate, setDefMonthlyRate] = useState("");
    const [defDistanceRate, setDefDistanceRate] = useState("");
    const [defEngineRate, setDefEngineRate] = useState("");
    const [equipmentType, setEquipmentType] = useState("");

    const [equipData, setEquipData] = useState([]);
    const [equipmentObj, setEquipmentObj] = useState<any>(null);

    const [isGenericReposition, setGenericReposition] = useState(false);
    const [isGenericRental, setGenericRental] = useState(false);
    const [isSpecificReposition, setSpecificReposition] = useState(false);
    const [isSpecificRental, setSpecificRental] = useState(false);

    const [showEquipPopup, setShowEquipPopup] = useState(false);
    const [modalParkingLot, setModalParkingLot] = useState(false);

    const [parkingLotName, setParkingLotName] = useState<any>('');
    const [parkingLotCodeName, setParkingLotCodeName] = useState<any>('');
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [contactName, setContactName] = useState<any>('');
    const [currency, setCurrency] = useState<any>("");
    const [status, setStatus] = useState<any>('Active');
    const [weeklyRate, setWeeklyRate] = useState<any>('');
    const [address, setAddress] = useState<any>('');
    const [city, setCity] = useState<any>('');
    const [country, setCountry] = useState<any>('');
    const [latitude, setLatitude] = useState<any>('');
    const [longitude, setLongitude] = useState<any>('');
    const [postalCode, setPostalCode] = useState<any>('');
    const [rawAddress, setRawAddress] = useState<any>('');
    const [state, setState] = useState<any>('');
    const [timeZone, setTimeZone] = useState<any>('');
    const [utcOffsetMinutes, setUtcOffsetMinutes] = useState<any>('');
    const [mode, setMode] = useState<any>('new');
    const [parkingList, setParkingList] = useState<any>([]);
    const [address1, setAddress1] = useState('');
    const [poiCapture, setPoiCapture] = useState<any>('');
    const [parkingLotId, setParkingLotId] = useState<any>('')

    const [twicRequire, seTwicRequire] = useState<any>('N');

    const [showEstValuePopup, setSowEstValuePopup] = useState(false);

    const parkingLotCodeNameRef = useRef<HTMLInputElement>(null);

    const lotNameRef = useRef<HTMLInputElement>(null);
    const addressRef = useRef<HTMLInputElement>(null);
    const hourRef = useRef<HTMLInputElement>(null);
    const dailyRefLot = useRef<HTMLInputElement>(null);
    const weekRef = useRef<HTMLInputElement>(null);
    const monthRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const poicaptureRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    const rdPDmgPaidByOwner = useRef<HTMLInputElement>(null);
    const rdPDmgPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByOwner = useRef<HTMLInputElement>(null);

    const [instructions, setInstructions] = useState<any>('');
    const [dailyRate, setDailyRate] = useState<any>('');

    const [email, setEmail] = useState<any>('');
    const [payToPark, setPayToPark] = useState<any>('N');
    const [hourlyRate, setHourlyRate] = useState<any>('');
    const [monthlyRate, setMonthlyRate] = useState<any>('');
    const [is24hoursAccessAvailable, setIs24hoursAccessAvailable] = useState<any>('Y');
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const contactRef = useRef<HTMLInputElement>(null);

    const [isInuranceChecked, setInsuranceChecked] = useState(false);

    const [isInuranceOwnerChecked, setInsuranceOwnerChecked] = useState(false);
    const [isInuranceRenterChecked, setInsuranceRenterChecked] = useState(false);
    const [insurPlans, setInsurPlans] = useState<any>([]);


    const [selPolicyObj, setSelPolicyObj] = useState<any>(null);
    const [perdayRate, setRatePerDay] = useState("");

    const [isLiabilityChecked, setLiabilityChecked] = useState(false);
    const [isPhysicalDamageChecked, setPhysicalDamageChecked] = useState(false);

    const [liabilityPaidByOwner, setLiabilityPaidByOwner] = useState<any>(false);
    const [liabilityPaidByRenter, setLiabilityPaidByRenter] = useState<any>(false);
    const [physicalDamagePaidByOwner, setPhysicalDamagePaidByOwner] = useState<any>(false);
    const [physicalDamagePaidByRenter, setPhysicalDamagePaidByRenter] = useState<any>(false);

    const [isLiabilityPaidByOwner, setIsLiabilityPaidByOwner] = useState<any>(false);
    const [isLiabilityPaidByRenter, setIsLiabilityPaidByRenter] = useState<any>(false);
    const [isPhysicalDamagePaidByOwner, setIsPhysicalDamagePaidByOwner] = useState<any>(false);
    const [isPhysicalDamagePaidByRenter, setIsPhysicalDamagePaidByRenter] = useState<any>(false);

    const onClickOrgRemove = (params:any)=>{
        let rowData:any = [];
        params.api.forEachNode((node:any) => rowData.push(node.data));
        const selectedarr:any = rowData.filter((ele:any)=>ele.orgCloseNetworkId !== params.data.orgCloseNetworkId)        
        setSpecificCloseNetwork(selectedarr)
    }

    const [physicalDamagePolicies, setPhysicalDamagePolicies] = useState([]);
    const [liabilityPolicies, setLiabilityPolicies] = useState([]);
    const [selPhysicalDamage, setSelPhysicalDamage] = useState<any>(null);
    const [selLiability, setSelLiability] = useState<any>(null);

    const [liabilityClass, setLiabilityClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [physicalDamageClass, setPhysicalDamageClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [avlTypeClass, setAvlTypeClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [avlCarrierClass, setAvlCarrierClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [startDateClass, setStartDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    const [endDateClass, setEndDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    const [estimatedValue, setEstimatedValue] = useState("");
    const estValueRef = useRef<HTMLInputElement>(null);
    const chkPickRef = useRef<HTMLInputElement>(null);

    const [isAnyAvailable, setIsAnyAvailable] = useState(false);

    const [isCurrecyDisable, setCurrencyDisable] = useState(false);
    const [arrivalAddrClass, setArrivalAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [departureAddrClass, setDepartureAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [currencyClass, setCurrencyClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [loadTypeClass, setLoadTypeClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [selectedViewType, setSelectedViewType] = useState("PUBLIC_VIEW");
    const [closeNetworkList, setCloseNetworkList] = useState([]);
    const [selectedcloseNetwork, setSelectedcloseNetwork] = useState<any>("");
    const [specificCloseNetwork, setSpecificCloseNetwork] = useState<any>([]);

    const navigate = useNavigate();


    const [availviewTypeDef] = useState([
        { field: 'orgName', headerName: "Carriers", resizable: true, width: 100, flex: 1, sortable: false, },
        { field: 'action', headerName: "Actions", resizable: true, width: 100, flex: 1, sortable: false, cellRenderer:(params:any)=>(
        <div className={"cellRenderHeader"}>
            <a style={{color:"red"}}><span onClick={(e)=>onClickOrgRemove(params)} className={"cellRender"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>
</span></a>
        </div>
        )},

    ]);


    let onOpenStDate = () => {
        if (stDateRef.current) {
            if (endDateRef.current) {
                var endDate = endDateRef.current.flatpickr.selectedDates[0];
                if (endDate) {
                    stDateRef.current.flatpickr.set("maxDate", new Date(endDate));
                } else {
                    stDateRef.current.flatpickr.set("minDate", new Date());
                }

            } else {
                stDateRef.current.flatpickr.set("minDate", new Date());
            }

        }
    }
    let handleNoOfDays = (e: any) => {
        setNoOfDays(e.currentTarget.value);
        CommonUtil.isMandatory(noOfDayRef);
        /*var addDaysMillis = 3*24*60*60*1000;
        var newNofDaysMiilis = e.currentTarget.value*24*60*60*1000;
        //console.log(noOfDays)
        if(stDateRef.current){
            var startDate = stDateRef.current.flatpickr.selectedDates[0];
            var endDate = new Date(new Date(startDate).getTime() + addDaysMillis + newNofDaysMiilis);
            console.log(endDate)
            if(endDateRef.current){
            var newEedDate: any;
            newEedDate = endDateRef.current.flatpickr.setDate(new Date(endDate));
        }
            setEndDate(newEedDate);
        }*/
        setTimeout(() => {
            //updateEndDate();
        })
    }

    let updateEndDate = () => {
        var addDaysMillis = 3 * 24 * 60 * 60 * 1000;
        if (isSpecificReposition || isGenericReposition) {
            if (stDateRef.current && noOfDayRef.current && noOfDayRef.current.value) {
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                var endDate = new Date(new Date(startDate).getTime() + addDaysMillis + ((Number(noOfDayRef.current.value) - 1) * 24 * 60 * 60 * 1000));
                console.log(endDate)
                if (endDateRef.current) {
                    var newEedDate: any;
                    newEedDate = endDateRef.current.flatpickr.setDate(new Date(endDate));
                }
                // setEndDate(newEedDate);
            }
        }
    }

    let onOpenEDDate = () => {
        if (endDateRef.current) {
            if (stDateRef.current) {
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                if (startDate) {
                    endDateRef.current.flatpickr.set("minDate", new Date(startDate));
                } else {
                    endDateRef.current.flatpickr.set("minDate", new Date());
                }

            } else {
                endDateRef.current.flatpickr.set("minDate", new Date());
            }

        }
    }

    let onChangeStDate = (dateValue: any) => {
        if (stDateRef.current) {
            let strDate: string = CommonUtil.getDate(dateValue);
            setStDate(strDate);
            if (strDate) {
                setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
            } else {
                setStartDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
            }
            onChangeDates();
            setTimeout(() => {
                // updateEndDate();
            })
        }
    }
    let onChangeEdDate = (dataVal: any) => {
        let strDate: string = CommonUtil.getDate(dataVal);
        if (strDate) {
            setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
        } else {
            setEndDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
        }
        setEndDate(strDate);
        onChangeDates();
    }

    let onChangeDates = () => {
        if (stDateRef.current && endDateRef.current) {
            var startDate = stDateRef.current.flatpickr.selectedDates[0];
            var endDate = endDateRef.current.flatpickr.selectedDates[0];

            endDateRef.current.flatpickr.set("minDate", new Date(startDate));
            stDateRef.current.flatpickr.set("maxDate", new Date(endDate));

            if (startDate) {
            }
            if (endDate) {

            }
        }
    }

    let getOrganizationCloseNetworks = () => {
        let avalAPI: string = 'api/organization/getOrganizationCloseNetworks?saasOrgId=' + CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, (dataObj: any) => {
            if (dataObj) {
                const restData: any = CommonUtil.parseData(dataObj);
                setCloseNetworkList(restData?.organizationCloseNetworks || [])
            }
        }, onLoginError)
    }


    const onChangeCloseNetwork = (dataObj: any) => {
        setSelectedcloseNetwork(dataObj);
        // CommonUtil.isDropDownMandatory(dataObj, setAvlCarrierClass);
        // setSelectedcloseNetwork(dataObj?.map(({ orgCloseNetworkId }: any) => ({ renterOrgId: orgCloseNetworkId })))
    }


    let onChangeAvailType = (dataObj: any) => {
        setSelectedViewType(dataObj.key)
    }
    let onChangeLoadType = (dataObj: any) => {

        setLoadTypeObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setLoadTypeClass);
        // (dataObj) ? setLoadTypeClass('select-box-valid') : setLoadTypeClass('select-box-invalid');
    }

    let onChangeDepartureLot = (dataObj: any) => {
        setSelDepartureObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setDepartureAddrClass);
    }

    let onChangeArrivalLot = (dataObj: any) => {
        setSelArrivalObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setArrivalAddrClass);
    }

    let onChangeCurrency = (dataObj: any) => {
        setCurrencyObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setCurrencyClass);
    }

    useEffect(() => {
        console.log("Use effect1" + CommonUtil.SELECT_EQUIP_ID);
        disableAvailableForm();
        setEquipmentObj(null);
        loadDirectionService();
        getPlatformFeePaidBy("");
        setSelectedViewType("PUBLIC_VIEW");
        setSpecificCloseNetwork([]);
        if (modalShow)
            getOrganizationCloseNetworks();
    }, [modalShow])

    let enableAvailableForm = () => {
        $("#divContainer").removeClass("disable-form");
        $("#btnSubmit").removeClass("disable-form");
    }
    let disableAvailableForm = () => {
        // $("#divContainer").addClass("disable-form");
        $("#btnSubmit").addClass("disable-form");
    }

    const getPlatformFeePaidBy = (movePrice: any) => {
        let getAPI: string = `api/organization/getPlatformFeesPaidBy?saasOrgId=${CommonUtil.getSaasOrgId()}`;
        NetworkManager.sendJsonRequest(getAPI, "", "", "GET", true, (dataObj: any) => {
            console.log(dataObj)
            if (dataObj) {
                var restData = JSON.parse(dataObj);
                if (restData.platformFeesPaidByRenter) {
                    setPlatformFeesPaidBy("Renter")
                } else if (restData.platformFeesPaidByOwner) {
                    setPlatformFeesPaidBy("Owner")
                } else {
                    setPlatformFeesPaidBy("Repositioner")
                }
            }
        }, onLoginError);
    }

    let loadDirectionService = () => {
        if (window.google) {
            //setScriptLoaded(true);
            // getGoogleDirection();
            // getGooglePlaces();
            loadGoogleServices();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    // setScriptLoaded(true);
                    // getGoogleDirection();
                    //getGooglePlaces();
                    loadGoogleServices();
                })
            }
        }
    }
    let getGooglePlaces = () => {
        resetStates();
        const input = document.getElementById('address') as HTMLInputElement
        const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: ['us', 'ca', 'in'] }
        })
        autocomplete.addListener('place_changed', () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete);
            if (addr) {
                const typedPOI = $('#address').val();
                console.log('Hello', typedPOI)
                setPoiCapture(typedPOI);
                let str: any = typedPOI?.toString();
                if (str.includes("USA")) {
                    setCurrency(CommonUtil.US_CURRENCY);
                }
                if (str.includes("Canada")) {
                    setCurrency(CommonUtil.CANADA_CURRENCY);
                }

                setTimeout(() => {
                    setAddress(addr['rawAddress'])
                    setAddress1(addr['rawAddress'])
                    setCity(addr['city'])
                    setCountry(addr['country'])
                    setLatitude(addr['lat'])
                    setLongitude(addr['lng'])
                    setPostalCode(addr['postalcode'])
                    setRawAddress(addr['rawAddress'])
                    setState(addr['state'])
                    setTimeZone('');
                    setUtcOffsetMinutes('330')
                    CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    })
                })
            }
        })
    }

    let loadGoogleServices = () => {
        isTaxExcemptionAvail = false
        getPartkingLots();
        getEquipments();
        setAvailabilityType();
        getOrgBanks();
        getTaxInfo();
        getOrganizationInfo();
        // getOrgInsurancePlanList();
    }

    let getOrganizationInfo = () => {
        var qParam = CommonUtil.getSaasOrgId();
        let avalAPI: string = 'api/organization/anyAvailbleEnabled?saasOrgId=' + CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, onGetAnyAvailableEnabled, onLoginError);
    }
    let onGetAnyAvailableEnabled = (dataObj: any) => {
        //console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                setIsAnyAvailable(restData.isEnableAnyAvailble == "Y" ? true : false);
                //setPage(""+Math.random()*100000);
            }
        }
    }
    let getTaxInfo = () => {
        let getCrediDetailsAPI: string = "api/organization/getFinance?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getCrediDetailsAPI, "", "", "GET", true, onGetTaxInfo, onLoginError);
    }
    let onGetTaxInfo = (dataObj: any) => {
        if (dataObj) {
            var restData: any = CommonUtil.parseData(dataObj);
            if (restData) {
                if (restData.taxExemptionReason && restData.taxState && restData.taxStatus) {
                    isTaxExcemptionAvail = true;
                    $("#divConditions").hide();
                    if (chkTerms && chkTerms.current) {
                        chkTerms.current.checked = true;
                    }

                }
            }
        }
    }

    let getOrgBanks = () => {
        bankAccounts = [];
        bank1Currency = "";
        bank2Currency = "";
        let bankAPI: string = 'api/organization/finance/getBankAccounts?saasOrgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(bankAPI, '', '', 'GET', true, onGetAllBanks, onLoginError);
    }

    let onGetAllBanks = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if (Array.isArray(restData)) {
                    bankAccounts = restData;
                } else {
                    bankAccounts.push(restData);
                }
            }
        }
        setCurrencyDropdownDisable();
        setAvailabilityType();
    }

    let setCurrencyDropdownDisable = () => {
        if (bankAccounts.length > 0) {
            bank1Currency = bankAccounts[0].currency;
            if (bankAccounts.length > 1) {
                bank2Currency = bankAccounts[1].currency;
                setCurrencyDisable(false);
                setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
            } else {
                setCurrencyDisable(true);
                setCurrencyObj({ key: bank1Currency, value: bank1Currency });
            }
        } else {
            setCurrencyDisable(false);
            setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        }

        if (bank2Currency && bank1Currency == bank2Currency) {
            setCurrencyDisable(true);
            setCurrencyObj({ key: bank1Currency, value: bank1Currency });
        }
    }



    let getPartkingLots = () => {
        let parkingAPI: string = "api/parkinglot/all?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetParkingLots, onLoginError);
    }

    let onGetParkingLots = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let parkingLots: any = [];
                if (Array.isArray(restData.parkingLots)) {
                    parkingLots = restData.parkingLots;
                } else {
                    parkingLots.push(restData.parkingLots);
                }
                setParkingLots(parkingLots);
            }
        }
    }

    let getEquipments = () => {
        let qParams = 'saasOrgId=' + CommonUtil.getSaasOrgId();
        let equipAllAPI: string = 'api/equipment/all?' + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                //let equips:any = []
                equips = [];
                if (Array.isArray(restData)) {
                    equips = restData;
                } else {
                    equips.push(restData)
                }

                equips.forEach((item: any, idx: number) => {
                    if (item.isGeneric) {
                        // item.isGeneric = item.isGeneric == "Y"?CommonUtil.EQUIP_GENERIC:CommonUtil.EQUIP_SPECIFIC;
                    }
                    item.UnitEquipName = (item.unitNumber ? item.unitNumber : item.genericEquipmentName);
                    //delete item['genericEquipmentName'];
                })

                setEquipData(equips);
                if (CommonUtil.SELECT_EQUIP_ID && CommonUtil.SELECT_EQUIP_NAME) {
                    equipmentId = CommonUtil.SELECT_EQUIP_ID;
                    setEquipmentObj({ "equipmentId": CommonUtil.SELECT_EQUIP_ID, "UnitEquipName": CommonUtil.SELECT_EQUIP_NAME });
                    getEquipmentDetails(CommonUtil.SELECT_EQUIP_ID);
                }
            }
        }
    }

    let resetFromDetails = () => {
        disableAvailableForm();
        setLiabilityPolicies([]);
        setPhysicalDamagePolicies([]);
    }

    let onChangeEquipment = (dataObj: any) => {
        disableAvailableForm();
        setLiabilityPolicies([]);
        setPhysicalDamagePolicies([]);
        setEquipmentObj(dataObj);
        if (dataObj) {
            equipmentId = dataObj.equipmentId;
            CommonUtil.SELECT_EQUIP_ID = equipmentId;
            CommonUtil.SELECT_EQUIP_NAME = dataObj.UnitEquipName;
            CommonUtil.IS_GENERIC_EQUIP = (dataObj.isGeneric == "Y") ? true : false;
            if(selectedViewType === "ASSIGN_RENTER_VIEW" && CommonUtil.IS_GENERIC_EQUIP){
                setSelectedViewType("PUBLIC_VIEW");
            }
            if (dataObj.estimatedValue) {
                enableAvailableForm();
                getEquipmentDetails(dataObj.equipmentId);
            } else {
                setSowEstValuePopup(true);
            }
        } else {
            disableAvailableForm();
        }
    }
    let setAvailabilityType = () => {
        setArrivalAddrClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setLoadTypeClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setDepartureAddrClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setLiabilityClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setPhysicalDamageClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setStartDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS);
        setEndDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS);
        setAvlTypeClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setAvlCarrierClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);

        setGenericReposition(false);
        setGenericRental(false);
        setSpecificReposition(false);
        setSpecificRental(false);

        setQuantity("");
        setPriceKm("");
        setEDisRate("");
        setDailyRate("");
        setDistanceRate("");
        setEngineRate("");
        if (chkAllowRef.current) {
            chkAllowRef.current.checked = false;
        }
        setMovePrice("");
        setAdditionalPrice("");
        setCurrencyObj(null);
        setSelDepartureObj(null);
        setSelArrivalObj(null);
        setNoOfDays("");

        if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
            setCurrencyDropdownDisable();
        } else {
            setCurrencyDisable(false);
            setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        }

        if (CommonUtil.IS_GENERIC_EQUIP) {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setGenericReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setGenericRental(true);
            }
        } else {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setSpecificReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setSpecificRental(true);
            }
        }
    }

    let getEquipmentDetails = (equipId: string) => {
        if (equipId) {
            enableAvailableForm();
            getPolicyDetails(equipId);
            let equipmentAPI: string = "api/equipment/" + equipId;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipmentAPI, "", "", "GET", true, onGetEquipmentDetails, onLoginError);
        }
    }

    let getPolicyDetails = (equipId: string) => {
        /*if(isLiabilityChecked){
            getLiabilityPlans(equipId);
        }
        if(isPhysicalDamageChecked){
            getPhysicalDamagePlans(equipId);
        }*/
        selEquipmentId = equipId;
        getOrgInsurancePlanList();
    }
    let getOrgInsurancePlanList = () => {
        let equipAllAPI: string = 'api/insurance/orgPolicies?orgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetOrgInsurancePolicy, onLoginError);
    }
    let onGetOrgInsurancePolicy = (dataObj: any) => {
        insurPolicies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsuranceChecked((restData.isInsuranceEnabled == "Y" ? true : false));
                setInsuranceOwnerChecked((restData.isInsurancePaidByOwner == "Y" ? true : false));
                setInsuranceRenterChecked((restData.isInsurancePaidByRenter == "Y" ? true : false));
                setLiabilityChecked((restData.isLiabilityEnabled == "Y" ? true : false));
                setPhysicalDamageChecked((restData.isPhysicalDamageEnabled == "Y" ? true : false));


                if (restData.organizationInsuranceAssociation) {
                    let arr = [];
                    if (Array.isArray(restData.organizationInsuranceAssociation)) {
                        arr = restData.organizationInsuranceAssociation;
                    } else {
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item: any, idx: number) => {
                        insurPolicies.push(item);
                    })
                }
                if (restData.isInsuranceEnabled == "Y") {
                    if (restData.isLiabilityEnabled == "Y") {
                        if (restData.isInsurancePaidByOwner == "Y") {
                            setLiabilityPaidByOwner(true);
                            setLiabilityPaidByRenter(false);

                            setIsLiabilityPaidByOwner(true);
                        } if (restData.isInsurancePaidByRenter == "Y") {
                            setIsLiabilityPaidByRenter(true);

                            if (restData.isInsurancePaidByOwner == "N") {
                                setLiabilityPaidByOwner(false);
                                setLiabilityPaidByRenter(true);
                            }

                        }
                    }
                    if (restData.isPhysicalDamageEnabled == "Y") {
                        if (restData.isInsurancePaidByOwner == "Y") {
                            setPhysicalDamagePaidByOwner(true);
                            setPhysicalDamagePaidByRenter(false);
                            setIsPhysicalDamagePaidByOwner(true);
                        } if (restData.isInsurancePaidByRenter == "Y") {
                            setIsPhysicalDamagePaidByRenter(true);

                            if (restData.isInsurancePaidByOwner == "N") {
                                setPhysicalDamagePaidByOwner(false);
                                setPhysicalDamagePaidByRenter(true);
                            }

                        }
                    }
                }

            }
        }

        setInsurPlans(insurPolicies);
        if (restData.isInsuranceEnabled == "Y") {
            if (restData.isLiabilityEnabled == "Y") {
                getLiabilityPlans(selEquipmentId);
            }
            if (restData.isPhysicalDamageEnabled == "Y") {
                getPhysicalDamagePlans(selEquipmentId);
            }
        }
    }

    let getLiabilityPlans = (equipId: string) => {
        let liabilityAPI: string = 'api/insurance/getRatePlanByLiability?equipmentId=' + equipId + '&saasOrgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(liabilityAPI, '', '', 'GET', true, onGetOrgLiabilityPlans, onLoginError);
    }

    let onGetOrgLiabilityPlans = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let liabilityPlans: any = [];
                if (Array.isArray(restData.ratePlan)) {
                    liabilityPlans = restData.ratePlan;
                } else {
                    liabilityPlans.push(restData.ratePlan);
                }

                setLiabilityPolicies(liabilityPlans);
            }
        }
    }

    let getPhysicalDamagePlans = (equipId: string) => {
        let physicalDamageAPI: string = 'api/insurance/getRatePlanByPhysicalDamage?equipmentId=' + equipId + '&saasOrgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(physicalDamageAPI, '', '', 'GET', true, onGetOrgPhysicalDamagePlans, onLoginError);
    }

    let onGetOrgPhysicalDamagePlans = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let physicalDamagePlans: any = [];
                if (Array.isArray(restData.ratePlan)) {
                    physicalDamagePlans = restData.ratePlan;
                } else {
                    physicalDamagePlans.push(restData.ratePlan);
                }

                setPhysicalDamagePolicies(physicalDamagePlans);
            }
        }
    }


    let onGetEquipmentDetails = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                CommonUtil.IS_GENERIC_EQUIP = (restData.isGeneric == "Y" ? true : false);
                CommonUtil.SELECT_EQUIPMENT_TYPE = restData.equipmentType;

                setDefDailyRate(restData.pricing.dailyRate);
                setDefWeeklyRate(restData.pricing.weeklyRate);
                setDefMonthlyRate(restData.pricing.monthlyRate);
                setDefDistanceRate(restData.pricing.distanceRate);
                setDefEngineRate(restData.pricing.engineRate);
                setEquipmentType(restData.equipmentType);
                setAvailabilityType();
            }
        }
    }

    let onCreateEquipment = () => {
        CommonUtil.SELECT_EQUIP_ID = '';
        setShowEquipPopup(true);
    }

    let onCloseEquipPopup = () => {
        setShowEquipPopup(false);
    }
    let onClickSpecific = () => {
        CommonUtil.IS_GENERIC_EQUIP = false;
        onCloseEquipPopup()
        //navigate('equipDetails')
        createEquipment();
    }
    let onClickGeneric = () => {
        CommonUtil.IS_GENERIC_EQUIP = true;
        onCloseEquipPopup()
        //navigate('equipDetails')
        createEquipment();
    }

    let createEquipment = () => {
        localStorage.setItem("IsGeneric", (CommonUtil.IS_GENERIC_EQUIP).toString());
        Events.dispatchCreateEquipment();
        /*localStorage.setItem("CreateEquip","true");
        localStorage.setItem("IsGeneric",(CommonUtil.IS_GENERIC_EQUIP).toString());
        let siteOrigin:string = window.location.origin;
        siteOrigin = siteOrigin+"/?requestor=";//equipview&equipid="+props.data.equipmentId+"&screenType="+CommonUtil.SCREEN_NAME;//+"&token="+ localStorage.getItem("token");;
        let strPath = "createEquip&equipGenType="+CommonUtil.IS_GENERIC_EQUIP;//+"&screenType="+CommonUtil.SCREEN_NAME;
        siteOrigin = siteOrigin+strPath;
        //CommonUtil.addScreensToLastAccessed(strPath);
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();*/
    }
    let onClickSaveAvailabilityAndReservation = () => {

        if (selectedAvailView === "ASSIGN_RENTER_VIEW" && !selectedcloseNetwork?.networkOrgId) {
            let msg = `Please select the renter for reservation`;
            ToastManager.showToast(msg, 'Error');
            return;
        }

        const restObj:any = {
            createReservation:true,
            renterOrgId:selectedcloseNetwork.networkOrgId
        }
        console.log(selectedcloseNetwork.networkOrgId);
        
        onClickSaveAvailability(restObj)

    }
    let onClickSaveAvailability = (rObj:any = null) => {

        let flag: boolean = false;
        let stDate = null;
        let edDate = null;
        if (stDateRef.current) {
            stDate = stDateRef.current.flatpickr.selectedDates[0];
            //console.log(stDate, 'stDate');
        }
        if (endDateRef.current) {
            edDate = endDateRef.current.flatpickr.selectedDates[0];
        }

        (stDate ? setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS) : setStartDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (edDate ? setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS) : setEndDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (selArrivalObj ? setArrivalAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setArrivalAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selDepartureObj ? setDepartureAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setDepartureAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (currencyObj ? setCurrencyClass(CommonUtil.DROPDOWN_VALID_CLASS) : setCurrencyClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selectedViewType ? setAvlTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setAvlTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selectedcloseNetwork ? setAvlCarrierClass(CommonUtil.DROPDOWN_VALID_CLASS) : setAvlCarrierClass(CommonUtil.DROPDOWN_INVALID_CLASS));


        $("#divTerms").hide();

        if (stDate && edDate && selArrivalObj && selDepartureObj && selArrivalObj.parkingLotName && selDepartureObj.parkingLotName && currencyObj) {
            if ((isGenericRental || isGenericReposition)) {
                if (isGenericRental) {
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if (equipmentType == CommonUtil.REEFER_EQUIP_TYPE) {
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if (quantity && dailyRateAvl && distanceRate && engineRate) {
                            flag = true;
                        }
                    } else {
                        if (quantity && dailyRateAvl && distanceRate) {
                            flag = true;
                        }
                    }
                } else if (isGenericReposition) {
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);
                    (loadTypeObj ? setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    if (quantity && noOfDays && movePrice && addtionalPrice && loadTypeObj) {
                        flag = true;
                    }
                }
            } else if (isSpecificRental || isSpecificReposition) {
                if (isSpecificRental) {
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if (equipmentType == CommonUtil.REEFER_EQUIP_TYPE) {
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if (dailyRateAvl && distanceRate && engineRate) {
                            flag = true;
                        }
                    } else {
                        if (dailyRateAvl && distanceRate) {
                            flag = true;
                        }
                    }

                } else if (isSpecificReposition) {
                    (loadTypeObj ? setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);
                    if (loadTypeObj && noOfDays && movePrice && addtionalPrice) {
                        flag = true;
                    }
                }
            }

            if (isGenericReposition || isSpecificReposition) {
                let days = CommonUtil.getDays(stDate, edDate);
                if (Number(noOfDays) > days) {
                    flag = false;
                    let msg = CommonUtil.getMessageText(Constants.NUM_OF_DAY_ALLOWED, "Number of days allowed for repositioning is less than or equal to between days");
                    ToastManager.showToast(msg, 'Error');
                    return;
                }

                if (platformFeesPaidBy === "Renter" && Number(movePrice) < Constants.MIN_PLATFORMFEE) {
                    flag = false;
                    let msg = `Paid Price to Carrier for Repositioning must be greater that ${Constants.MIN_PLATFORMFEE}`;
                    ToastManager.showToast(msg, 'Error');
                    return;
                }
            }
            if (isLiabilityChecked) {
                if (!selLiability) {
                    (selLiability ? setLiabilityClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLiabilityClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }

            }
            if (isPhysicalDamageChecked) {
                if (!selPhysicalDamage && flag) {
                    (selPhysicalDamage ? setPhysicalDamageClass(CommonUtil.DROPDOWN_VALID_CLASS) : setPhysicalDamageClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }
            }


            if (chkTerms.current) {
                if (!chkTerms.current.checked) {
                    $("#divTerms").show();
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_SELECT_TERMS_CONDITIONS, "Please select terms & conditions"), "Error");
                    return;
                } else {

                }
            }

            // if (selectedViewType === "SPECIFIC_VIEW") {
            //     if (specificCloseNetwork?.length == 0){    
            //         flag = false;
            //         let msg = ` Please added atleast one Specific network carrier`;
            //         ToastManager.showToast(msg, 'Error');
            //         return;
            //     }
            // }

            if (flag) {
                //var startDate = stDateRef.current.flatpickr.selectedDates[0];
                //var endDate = endDateRef.current.flatpickr.selectedDates[0];

                let reqObj: any = {};
                reqObj.policyId = selPolicyObj ? selPolicyObj.id : "";
                reqObj.endDate = CommonUtil.getReservationDateString(edDate);
                reqObj.startDate = CommonUtil.getReservationDateString(stDate);
                reqObj.additionalPriceExcessDays = addtionalPrice;
                reqObj.allowOffer = ((chkAllowRef.current?.checked) ? "Y" : "N");
                reqObj.isEnableAnyAvailble = ((chkPickRef.current?.checked) ? "Y" : "N");
                reqObj.arrivalParkingLotId = selArrivalObj ? selArrivalObj.parkingLotId : "";
                reqObj.departureParkingLotId = selDepartureObj ? selDepartureObj.parkingLotId : "";
                reqObj.dailyRate = dailyRateAvl;
                reqObj.weeklyRate = weeklyRateAvl;
                reqObj.monthlyRate = monthlyRateAvl;
                reqObj.defaultCurrency = currencyObj ? currencyObj.key : "";
                // reqObj.distanceRate = distanceRate;
                reqObj.engineRate = engineRate;
                reqObj.equipmentId = equipmentId;
                reqObj.estimatedDistance = eDisRate;
                reqObj.listingType = CommonUtil.EQUIP_AVAIL_TYPE;
                reqObj.loadType = loadTypeObj ? loadTypeObj.key : "";
                reqObj.movePrice = movePrice;
                if ((isGenericReposition || isSpecificReposition)) {
                    reqObj.platformFees = (Number(movePrice) * 0.1);
                    reqObj.platformFeesPaidBy = (platformFeesPaidBy);
                }
                reqObj.noOfDaysAllowed = noOfDays;
                reqObj.pricePerKm = priceKm;

                reqObj.isInsurancePaidByOwnerLiability = "N";
                reqObj.isInsurancePaidByOwnerPhysical = "N";
                reqObj.isInsurancePaidByRenterLiability = "N";
                reqObj.isInsurancePaidByRenterPhysical = "N";
                reqObj.ratePlanIdLiability = "";
                reqObj.ratePlanIdPhysical = "";

                if (isLiabilityChecked) {
                    reqObj.isInsurancePaidByOwnerLiability = (rdLbtPaidByOwner.current && rdLbtPaidByOwner.current.checked ? "Y" : "N");
                    reqObj.isInsurancePaidByRenterLiability = (rdLbtPaidByRenter.current && rdLbtPaidByRenter.current.checked ? "Y" : "N");
                    reqObj.ratePlanIdLiability = selLiability ? selLiability.insuranceRateId : "";
                }

                if (isPhysicalDamageChecked) {
                    reqObj.isInsurancePaidByOwnerPhysical = (rdPDmgPaidByOwner.current && rdPDmgPaidByOwner.current.checked ? "Y" : "N");
                    reqObj.isInsurancePaidByRenterPhysical = (rdPDmgPaidByRenter.current && rdPDmgPaidByRenter.current.checked ? "Y" : "N");
                    reqObj.ratePlanIdPhysical = selPhysicalDamage ? selPhysicalDamage.insuranceRateId : "";
                }

                if (isSpecificReposition) {
                    if (priceKm) {
                        reqObj.distanceRate = priceKm;
                    }
                } else if (isGenericRental || isSpecificRental) {
                    if (distanceRate) {
                        reqObj.distanceRate = distanceRate;
                    }
                } else {
                    reqObj.distanceRate = "";
                }
                if (addtionalPrice) {
                    reqObj.dailyRate = addtionalPrice;
                }

                reqObj.quantity = quantity;
                reqObj.useEquipmentPricing = "";

                reqObj.availabilityViewType = selectedViewType;
                if(selectedViewType !== "PUBLIC_VIEW"){
                    if (specificCloseNetwork?.length >0){               
                        const specificNetworks:any = specificCloseNetwork?.map((ele:any)=>{
                            let obj = {
                                renterOrgId : ele.renterOrgId,
                                orgName:ele.orgName,
                            }
                            return obj;
                        })
                        reqObj.specificNetworks = specificNetworks;
                        // reqObj.availabilityViewType = 'SPECIFIC_VIEW'
                    }else {
                        reqObj.specificNetworks = [];
                        // reqObj.availabilityViewType = 'PRIVATE_VIEW'
                    }
                }
                if(selectedViewType === "ASSIGN_RENTER_VIEW")
                    reqObj.availabilityViewType = 'PRIVATE_VIEW'

                let distanceInMeters = 0;
                let durationInMin = 0;
                BusyLoader.showLoader();
                /* let availCraateApi:string = "";
                 let method:string = "POST";
                 if(CommonUtil.SELECT_EQUIP_AVAIL_ID == ""){
                     availCraateApi = "api/availability/?saasOrgId="+CommonUtil.getSaasOrgId();
                 }else{
                     method = "PUT";
                     availCraateApi = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
                 }
                 NetworkManager.sendJsonRequest(availCraateApi,reqObj,"",method,false,onCreateAvailable,onLoginError);*/

                CommonUtil.getDistanceBetweenPoints(selDepartureObj.address, selArrivalObj.address, (dataObj: any) => {
                    console.log(dataObj);
                    if (dataObj && dataObj.distance && dataObj.distance.value) {
                        distanceInMeters = dataObj.distance.value;
                    }
                    if (dataObj && dataObj.distance && dataObj.duration.value) {
                        durationInMin = dataObj.duration.value;///60;
                    }

                    reqObj.distanceBetweenLots = distanceInMeters;
                    reqObj.travelTimeBetweenLots = durationInMin;

                    if(rObj){
                        reqObj = {...reqObj,...rObj};
                    }

                    let availCraateApi: string = "";
                    let method: string = "POST";
                    availCraateApi = "api/availability/?saasOrgId=" + CommonUtil.getSaasOrgId();
                    /*if(CommonUtil.SELECT_EQUIP_AVAIL_ID == ""){

                    }else{
                        method = "PUT";
                        availCraateApi = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
                    }*/
                    NetworkManager.sendJsonRequest(availCraateApi, reqObj, "", method, false, onCreateAvailable, onLoginError);
                });
            } else {
                displayErrorMessage();
            }
        } else {
            displayErrorMessage();
        }
    }

    let displayErrorMessage = () => {
        ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
    }

    let onCreateAvailable = (dataObj: any) => {
        // console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                onCloseAvailPopupshow();
                // onCloseAvailPopup();
                // getEquipmentAvailabilities();
                if (CommonUtil.SELECT_EQUIP_AVAIL_ID == "") {
                    if(restData.reservationCode && restData.reservationId){
                        ToastManager.showToast(`Reservation ${restData.reservationCode} and Availability Created Successfully`, "Info");
                    }else
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.AVAIL_CREATED, "Availability Created Successfully"), "Info");
                } else {
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.AVAIL_UPDATED, "Availability Updated Successfully"), "Info");
                }
            }
        }

    }

    let handleCloseParkingLot = () => {
        resetStates();
        setModalParkingLot(false);
    }
    let handleParkingLotName = (e: any) => {
        setParkingLotName(e.target.value)
        CommonUtil.isMandatory(lotNameRef);
    }

    let handleEstimatedValue = (e: any) => {
        setEstimatedValue(e.target.value)
        CommonUtil.isMandatory(estValueRef);
    }

    let handleInputStatus = (e: any) => {
        setStatus(e.target.value)
    }
    let handleParkingLotCodeName = (e: any) => {
        setParkingLotCodeName(e.target.value)
        CommonUtil.isMandatory(parkingLotCodeNameRef);
    }
    let onChangeAddress = (e: any) => {
        setLatitude('');
        setLongitude('');
        setAddress1(e.currentTarget.value);
        setRawAddress(e.currentTarget.value);
        //setPoiCapture(address1);
        CommonUtil.isMandatory(addressRef);
        // CommonUtil.isMandatory(poiCaptureRef);
    }
    let handleInputEmail = (e: any) => {
        setEmail(e.target.value)
        let flag: boolean = CommonUtil.isValidEmail(e.target.value);
        if (flag) {
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        } else {
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }
    }
    let handleInputInstruction = (e: any) => {
        setInstructions(e.target.value)
    }
    let handleParkingLotSubmit = () => {
        let isLotName: boolean = CommonUtil.isMandatory(lotNameRef)
        let isparkLotCodeName: boolean = CommonUtil.isMandatory(parkingLotCodeNameRef)
        let isEmail: boolean = true;
        let isHour: boolean = true;
        let isDaily: boolean = true;
        let isWeek: boolean = true;
        let isMonth: boolean = true;
        let isAddress: boolean = CommonUtil.isMandatory(addressRef);
        //let isPOICapture: boolean = CommonUtil.isMandatory(poicaptureRef);
        let isPhone: boolean = true;
        if (payToPark === 'Y') {
            isHour = CommonUtil.isMandatory(hourRef)
            isDaily = CommonUtil.isMandatory(dailyRefLot)
            isWeek = CommonUtil.isMandatory(weekRef)
            isMonth = CommonUtil.isMandatory(monthRef)
        }
        if (!longitude?.toString()?.trim() || !latitude?.toString()?.trim()) {
            ToastManager.showToast('Invalid location', 'Error');
            return;
        }
        // if (!phoneNumber.trim() || phoneNumber.length < 11) {
        //     isPhone = false
        //     setPhoneClass('is-invalid')
        // }
        if (email.length > 0) {
            isEmail = CommonUtil.isValidEmail(email);
        }
        var twicAccessRequired = twicRequire;
        if (isLotName && isparkLotCodeName && isEmail && isHour && isDaily && isWeek && isMonth && isAddress && isPhone) {
            let orgUrl = "api/parkinglot/";
            let newData = {
                dailyRate,
                email,
                hourlyRate,
                instructions,
                poiCapture,
                payToPark,
                is24hoursAccessAvailable,
                currency,
                monthlyRate,
                twicAccessRequired,
                contactName,
                parkingLotName: parkingLotName.trimStart().trimEnd(),
                parkingLotCodeName,
                phoneNumber,
                status,
                weeklyRate,
                address,
                city,
                country,
                latitude,
                longitude,
                postalCode,
                rawAddress,
                state,
                timeZone,
                utcOffsetMinutes
            };

            orgUrl = "api/parkinglot/?saasOrgId=" + CommonUtil.getSelectedOrgId();
            NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", true, onParkingLotSuccess, onLoginError);
        }
    }

    let onParkingLotSuccess = (dataObj: any) => {
        console.log("Success");
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Parking lot created successfully', 'Success');
                resetStates();
                handleCloseParkingLot();
                getPartkingLots();
            }
        }


        /*let lotObject:any = {};
        lotObject.parkingLotId = dataObj.parkingLotId;
        lotObject.parkingLotName = dataObj.parkingLotName;;

        let parkings:any = parkingLots;
        parkings.push(lotObject);

        setParkingLots(parkings);*/

    }
    const platformFeeMsgForPaidByRenter = () => {
        let msg: any = "";
        if (platformFeesPaidBy === "Renter") {
            const pf: any = Math.max(Number(movePrice) * 0.1, Constants.MIN_PLATFORMFEE);
            msg = `Availability will be listed for ${currencyObj?.value} ${(Number(movePrice) - pf)} and the owner will end up paying a total of ${currencyObj?.value} ${Number(movePrice)} that includes ${pf > Constants.MIN_PLATFORMFEE ? "10% of Platform Fee" : `Platform Fee of ${currencyObj?.value} ${pf}`}`
            if (Number(movePrice) < Constants.MIN_PLATFORMFEE)
                msg = `Please enter value greater than ${Constants.MIN_PLATFORMFEE}`;
        }

        if (platformFeesPaidBy === "Owner") {
            msg = `Availability will be listed for ${currencyObj?.value} ${Number(movePrice)} and the owner will end up paying ${currencyObj?.value} ${Number(movePrice) + Math.max((Number(movePrice) * 0.1), Constants.MIN_PLATFORMFEE)} that includes 10% Platform Fee`;
        }

        return msg;
    }


    let resetStates = () => {
        seTwicRequire("N");
        setDailyRate('');
        setEmail("");
        setHourlyRate("");
        setInstructions("");
        setPayToPark("N");
        setIs24hoursAccessAvailable("Y");
        setMonthlyRate("");
        setParkingLotName("");
        setParkingLotCodeName("");
        setPhoneNumber("");
        setStatus("Active");
        setWeeklyRate("");
        setAddress("");
        setCity("");
        setCountry("");
        setLatitude("");
        setLongitude("");
        setPostalCode("");
        setRawAddress("");
        setState("");
        setTimeZone("");
        setUtcOffsetMinutes("330");
        setMode('new')
        setAddress1('');
        setPoiCapture('');
        setContactName("");
        setCurrency("");
    }
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e)
        setPhoneClass('mandatory')
    }
    const handleInputChange24 = (event: any) => {
        setIs24hoursAccessAvailable(is24hoursAccessAvailable === 'N' ? 'Y' : 'N')
    }
    let handleInputHourRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: hourRef, setState: setHourlyRate })
    }
    let handleInputDailyRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: dailyRefLot, setState: setDailyRate })
    }
    let handleInputWeekRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: weekRef, setState: setWeeklyRate })
    }
    let handleInputMonthRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: monthRef, setState: setMonthlyRate })
    }
    const handleContactName = (e: any) => {
        setContactName(e.target.value)
    }
    const handlepayToPark = (e: any) => {
        setPayToPark(payToPark === 'N' ? 'Y' : "N");
        if (payToPark === 'Y') {
            setHourlyRate('')
            setDailyRate('')
            setWeeklyRate('')
            setMonthlyRate('')
        }
    }
    let handleTwicRequire = (e: any) => {
        seTwicRequire(twicRequire === 'N' ? 'Y' : "N");
        //console.log(twicRequire);
    }
    let onCreateParkingLot = () => {
        setModalParkingLot(true);
        setTimeout(() => {
            getGooglePlaces();
        }, 1000)
    }
    let onSelectPolicy = (data: any) => {
        setSelPolicyObj(data);
    }
    let onChangeLiability = (dataObj: any) => {
        setSelLiability(dataObj);
        (dataObj) ? setLiabilityClass('select-box-valid') : setLiabilityClass('select-box-invalid');
    }

    let onChangePhysicalDamage = (dataObj: any) => {
        setSelPhysicalDamage(dataObj);
        (dataObj) ? setPhysicalDamageClass('select-box-valid') : setPhysicalDamageClass('select-box-invalid');
    }

    let onChangeLiabilityPaidByOwner = (e: any) => {
        console.log(e.currentTarget.checked);
        if (e.currentTarget.checked) {
            setLiabilityPaidByOwner(true);
            setLiabilityPaidByRenter(false);
        }
    }

    let onChangeLiabilityPaidByRenter = (e: any) => {
        console.log(e.currentTarget.checked);
        if (e.currentTarget.checked) {
            setLiabilityPaidByOwner(false);
            setLiabilityPaidByRenter(true);
        }
    }
    let onChangePhysicalDamageOwner = (e: any) => {
        if (e.currentTarget.checked) {
            setPhysicalDamagePaidByOwner(true);
            setPhysicalDamagePaidByRenter(false);
        }
    }

    let onChangePhysicalDamageRenter = (e: any) => {
        if (e.currentTarget.checked) {
            setPhysicalDamagePaidByOwner(false);
            setPhysicalDamagePaidByRenter(true);
        }
    }
    let handleEstPopup = () => {
        setEstimatedValue("")
        setSowEstValuePopup(false);
        if (equipmentId) {
            enableAvailableForm();
            let equipmentAPI: string = "api/equipment/" + equipmentId;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipmentAPI, "", "", "GET", true, onGetEquipmentDetails, onLoginError);
        }
    }

    let handleSaveEstimatedValue = () => {
        if (estimatedValue) {
            let availCraateApi: string = "";
            let method: string = "PUT";
            availCraateApi = "api/equipment/updateEstimatedValue/" + equipmentId + "?estimatedValue=" + estimatedValue;
            NetworkManager.sendJsonRequest(availCraateApi, '', "", method, true, onEstimatedValueUpdated, onLoginError);
        }
    }

    let onEstimatedValueUpdated = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setEquipData([]);
                equips.forEach((item: any, idx: number) => {
                    if (item.isGeneric) {
                        // item.isGeneric = item.isGeneric == "Y"?CommonUtil.EQUIP_GENERIC:CommonUtil.EQUIP_SPECIFIC;
                    }
                    if (!item.estimatedValue && item.equipmentId == equipmentId) {
                        item.estimatedValue = estimatedValue;
                    }
                    item.UnitEquipName = (item.unitNumber ? item.unitNumber : item.genericEquipmentName);
                    //delete item['genericEquipmentName'];
                })

                setEquipData(equips);
                // handleEstPopup();
                setEstimatedValue("")
                setSowEstValuePopup(false);
                ToastManager.showToast(CommonUtil.getMessageText(Constants.SUCCESSFULLY_UPDATED_EST_VALUE, 'Successfully updated estimated value'), 'Success');
                enableAvailableForm();
                getEquipmentDetails(equipmentId);
            }
        }
    }
    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devEstPopup', 'devEstContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 400;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }
    let onCloseAvailPopupshow = () => {
        resetFromDetails();
        // CommonUtil.SELECT_EQUIP_ID = "";
        CommonUtil.SELECT_EQUIP_NAME = "";
        setEquipData([]);
        setDailyRate("");
        onCloseAvailPopup();
    }
    let onLoginError = (err: any) => {
        console.log(err)
    }
    let rateObj: any = {}
    try {
        if (stDateRef.current && endDateRef.current) {
            const startDate = stDateRef.current.flatpickr.selectedDates[0];
            const endDate = endDateRef.current.flatpickr.selectedDates[0];
            rateObj = getSelectedRangeRate(CommonUtil.getDays(startDate, endDate), Number(defDailyRate), Number(defWeeklyRate), Number(defMonthlyRate))
        }
    } catch (e) {
        rateObj = {};
    }

    let selectedAvailView: any = "";
    try {
        selectedAvailView = availableRental?.filter((ele: any) => ele.key == selectedViewType)[0];
    } catch (e) {
        selectedAvailView = "";
    }

    let closeNetworkListNotSelected: any = [...closeNetworkList];
    try {
        closeNetworkListNotSelected = closeNetworkList?.filter((ele: any) =>{
            const idx:any = specificCloseNetwork?.findIndex((item:any)=>{
                return item.orgCloseNetworkId == ele.orgCloseNetworkId 
            })
            console.log(idx,specificCloseNetwork);
            
            return idx == -1;
    });
    } catch (e) {
        closeNetworkListNotSelected = [...closeNetworkList];
    }


    return (<>
        <Modal show={showEstValuePopup} centered dialogAs={DraggableComponent} id="devEstPopup" onShow={onDevAvailShowPopup}>
            <div className="modal-content" id="devEstContentPopup" style={{ border: "1px solid green" }}>
                <Modal.Header className="modal-header" closeButton={false}>
                    <h5 className="modal-title mt-0" style={{ fontSize: "15px" }}>{CommonUtil.getMessageText(Constants.EST_EQUIP_VALUE, 'Equipment Estimated Value')}</h5>
                    <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close"
                        onClick={handleEstPopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.ENTER_ESTIMATED_VALUE, 'Enter Estimated Value')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" autoFocus={true} className="form-control clsFormInputControl" ref={estValueRef}
                                        placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_VALUE, 'Estimated Value')} value={estimatedValue}
                                        onChange={handleEstimatedValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={handleEstPopup}>
                        {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                    </button>
                    <button type="submit" className="btn btn-success" onClick={handleSaveEstimatedValue}>
                        {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                    </button>
                </div>
            </div>

        </Modal>
        <Modal show={modalParkingLot} centered dialogAs={DraggableComponent}>
            <div className="modal-content" style={{ "width": "650px", height: "650px" }}>
                <Modal.Header className="modal-header" closeButton={false}>
                    <h5 className="modal-title mt-0">{CommonUtil.getMessageText(Constants.CREATE_PARKING, 'Create Parking Lot')}</h5>
                    <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close"
                        onClick={handleCloseParkingLot}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <div className="modal-body" style={{ overflow: "auto" }}>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" autoFocus={true} name="parkingLotName"
                                        className="form-control clsFormInputControl" ref={lotNameRef}
                                        placeholder={CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} value={parkingLotName}
                                        onChange={handleParkingLotName}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.STATUS, 'Status')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <select name="status" className={'form-control'} onChange={handleInputStatus} value={status}>
                                        <option value="Active">Active</option>
                                        <option value="InActive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.PARKING_LOT_CODE_NAME, 'Parking Lot Code Name')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" ref={parkingLotCodeNameRef} name="parkingLotCodeName"
                                        className="form-control clsFormInputControl" placeholder="Parking Lot Code Name"
                                        maxLength={12}
                                        aria-label="parkingLotCodeName" value={parkingLotCodeName} onChange={(e) => handleParkingLotCodeName(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.LOCATION, 'Location')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" id={'address'} ref={addressRef}
                                        className="form-control googleAddress clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.LOCATION, 'Location')}
                                        aria-label="Address1"
                                        value={address1}
                                        onChange={(e) => onChangeAddress(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.POINT_OF_INTEREST, 'Point of Interest')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" ref={poicaptureRef}
                                        className="form-control googleAddress clsFormInputControl" placeholder='POI'
                                        aria-label="POICapture" value={poiCapture} readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}</label>
                                <div className="col-sm-12">
                                    <input className="form-control" ref={emailRef}
                                        placeholder="Email (Optional)" aria-label="Email"
                                        type="email" name="email" value={email}
                                        onChange={handleInputEmail}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row pr-0">
                            <div className="col-md-4 col-sm-12 pt-4">
                                <Form.Check
                                    type="switch"
                                    name={'twicRequire'}
                                    label={CommonUtil.getMessageText(Constants.TWIC_REQUIRE, 'TWIC Required')}
                                    checked={twicRequire === 'Y' ? true : false}
                                    // defaultChecked={false}
                                    value={twicRequire === "Y" ? 'Y' : "N"}
                                    onChange={handleTwicRequire}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 pt-2" style={{ display: (twicRequire == 'Y' ? "" : "none") }}>
                                <img src="../../images/twic.png" className="mx-2" style={{ width: "50px" }} />
                            </div>
                        </div>
                        <div className="row pr-0">
                            <div className="col-md-4 sm-col-12 pt-4">
                                <Form.Check
                                    type="switch"
                                    name={'payToPark'}
                                    label={CommonUtil.getMessageText(Constants.PAY_TO_PARK, 'Pay to Park')}
                                    checked={payToPark === 'N' ? false : true}
                                    defaultChecked={false}
                                    value={payToPark === "Y" ? 'Y' : "N"}
                                    onChange={handlepayToPark}
                                />
                            </div>
                            <div className="col-md-4  pt-4">
                                <Form.Check
                                    type="switch"
                                    name={'is24hoursAccessAvailable'}
                                    label={CommonUtil.getMessageText(Constants.HOURS_24, '24 hours')}
                                    checked={is24hoursAccessAvailable === 'N' ? false : true}
                                    // defaultChecked={false}
                                    value={is24hoursAccessAvailable === "N" ? 'N' : "Y"}
                                    onChange={handleInputChange24}
                                />
                            </div>
                            <div className="col-md-2  pt-4 col-form-label">
                                <label>{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                            </div>
                            <div className="col-md-2 col-sm-12 pt-4 p-0">
                                <input type="text"
                                    className="form-control"
                                    disabled value={currency} />
                            </div>

                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.HOURLY_RATE, "Hourly Rate")} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} maxLength={10}
                                        placeholder="0" ref={hourRef}
                                        type="text" name="hourlyRate" value={hourlyRate}
                                        onChange={handleInputHourRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        disabled={payToPark === 'N'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12" style={{ zIndex: 9 }}>
                                    <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                        name="dailyRate" ref={dailyRefLot} maxLength={10}
                                        value={dailyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        onChange={handleInputDailyRate}
                                        disabled={payToPark === 'N'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12" style={{ zIndex: 9 }}>
                                    <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                        name="weeklyRate" ref={weekRef} maxLength={10}
                                        value={weeklyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        onChange={handleInputWeekRate}
                                        disabled={payToPark === 'N'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12" style={{ zIndex: 9 }}>
                                    <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                        name="monthlyRate" ref={monthRef} maxLength={10}
                                        value={monthlyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        onChange={handleInputMonthRate}
                                        disabled={payToPark === 'N'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.CONTACT_NO, "Contact Number")} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12" style={{ zIndex: 9 }}>
                                    {/*<input className={'form-control'} placeholder="Contact Number" type="number"*/}
                                    {/*       name="phoneNumber"*/}
                                    {/*       value={phoneNumber} onChange={handleInputChange}/>*/}
                                    <PhoneInput
                                        country={'us'}
                                        countryCodeEditable={false}
                                        value={phoneNumber}
                                        containerClass={phoneClass}
                                        placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, 'Enter Mobile Number')}
                                        inputClass={`input-phone ${phoneClass}`}
                                        onChange={(e) => {
                                            handleInputPhone(e)
                                        }}
                                        containerStyle={{
                                            width: '100%',
                                        }}
                                        disableCountryGuess={true}
                                        disableDropdown={true}
                                        disableSearchIcon={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.CONTACT_NAME, "Contact Name")}
                            </label>
                            <input className="form-control" placeholder="Contact Name" type="text"
                                name="contactName" ref={contactRef}
                                value={contactName}
                                onChange={handleContactName}
                            />
                        </div>
                    </div>


                    <div className="col-md-12 col-12" style={{ paddingLeft: "0px" }}>
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.ACCESS_INSTRUCTION_OPTIONAL, 'Access Instruction (Optional)')}
                            </label>
                            <div className="col-sm-12">
                                <textarea className="form-control" style={{ height: "100px" }}
                                    placeholder="Access Instruction (Optional)"
                                    name="instructions" value={instructions}
                                    onChange={handleInputInstruction}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-dark"
                        data-dismiss="modal" onClick={handleCloseParkingLot}>
                        {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                    </button>
                    <button type="submit" className="btn btn-success"
                        onClick={handleParkingLotSubmit}>
                        {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                    </button>
                </div>
            </div>
        </Modal>
        <Modal show={showEquipPopup} dialogAs={DraggableComponent}>
            <div className="modal-content" style={{ width: '650px', height: '500px', border: "1px solid green" }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0 fw-bold text-center black-text" style={{ width: '97%', marginLeft: '3%' }}>{CommonUtil.getMessageText(Constants.NEW_EQUIPMENT, 'New Equipment')}</h5>
                    {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipPopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body color-black my-2" style={{ paddingTop: '10px' }}>
                    <div className="row fs-7 px-6">
                        <div className="col-md-6 text-center">
                            <div className="col-md-12 specific-equip p-3" onClick={onClickSpecific}>
                                <img src={"../../images/specific-equipment.png"} style={{ width: '110px' }} />
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    <strong>{CommonUtil.getMessageText(Constants.SPECIFIC_EQUIP, 'Specific Equipment')}</strong>{' '}
                                </p>
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    {CommonUtil.getMessageText(Constants.THE_SPECIFIC_EQUIP, 'This specific equipment')} <br />
                                    {CommonUtil.getMessageText(Constants.EQUIP_MADE_AVAIL, 'Will be the one made available')} <br />
                                    {CommonUtil.getMessageText(Constants.FOR_THE_RENTERS, 'for the renters.')}
                                </p>
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    {CommonUtil.getMessageText(Constants.NEED_TO_ADD, 'You will need to add')} <br />
                                    {CommonUtil.getMessageText(Constants.AVAIL_THIS_EQUIP, 'availabilities for this specific equipment to available for the renter.')}

                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="col-md-12 generic-equip p-3" onClick={onClickGeneric}>
                                <img src={"../../images/general-equipment.png"} style={{ width: '110px' }} />
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    <strong>{CommonUtil.getMessageText(Constants.GENERIC_EQUIP, 'Generic Equipment')} </strong>{' '}
                                </p>
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    {CommonUtil.getMessageText(Constants.SIMILAR_EQUIPS, 'Display multiple similar equipment in a single listing to\n' +
                                        'simplify the management of their availabilites.')}
                                </p>
                                <p style={{ marginTop: 0, marginBottom: '1rem', fontSize: "13px" }}>
                                    {CommonUtil.getMessageText(Constants.GENERIC_EQUIP_RENTED, 'When a generic equipment is rented, you will need to assign\n' +
                                        'a specific equipement before the start of the reservation.')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer clsModalPermissionsFootr">
                    {/* <button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal"
                                onClick={onCloseEquipPopup}>Cancel</button> */}
                    <button type="button" className="btn btn-warning waves-effect"

                        data-bs-dismiss="modal" onClick={onCloseEquipPopup}>
                        {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                    </button>
                </div>
            </div>
        </Modal>
        <Modal show={modalShow} dialogAs={DraggableComponent} id="devAvailPopup">
            <div className="modal-content" id="devAvailContentPopup" style={{ "width": "680px", "height": "650px" }}>
                <div className="modal-header">
                    <h6 className="modal-title mt-0 fw-bold text-center black-text">{(availabilityType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) ? CommonUtil.getMessageText(Constants.REPOSITION, "Reposition") : CommonUtil.getMessageText(Constants.RENTAL, "Rental")}</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={onCloseAvailPopupshow} style={{ marginLeft: "0px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: "0px", overflow: "auto" }}>
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SEL_EQUIPMENT, "Select Equipment")}</label>
                                <div className="col-sm-12 col-12">
                                    <SingleSelectComp dataSource={equipData} selectedValue={equipmentObj} handleChange={onChangeEquipment} width={"100%"}
                                        value={"equipmentId"} label={"UnitEquipName"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 text-right">
                            <label className="col-sm-12 col-form-label" style={{ visibility: "hidden" }}>{CommonUtil.getMessageText(Constants.SEL_EQUIPMENT, "Select Equipment")}</label>
                            <button className="btn btn-warning waves-effect"
                                onClick={onCreateEquipment}>{CommonUtil.getMessageText(Constants.CREATE_EQUIP, "Create Equipment")}</button>
                        </div>
                    </div>
                    <div className="container" id={"divContainer"} style={{ padding: "0px" }}>
                        <br />
                        <section style={{ boxShadow: "none", padding: "0px" }}>
                            <p style={{ textAlign: "center", marginBottom: "0px", fontSize: "12px" }}>{CommonUtil.getMessageText(Constants.AVAIL_DATES, "Availabilities will be automatically reallocated if a reservation is made on a subset of the dates")}</p>


                            <div className="row" style={{ paddingTop: "10px", display: (isAnyAvailable && CommonUtil.IS_GENERIC_EQUIP ? "" : "none") }}>
                                <div className="col-sm-12 col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-12  flex">
                                            <input ref={chkPickRef} type="checkbox" className="checkBox" />
                                            <label style={{ paddingLeft: "10px", display: "inline" }}>
                                                {CommonUtil.getMessageText(Constants.PICK_ANY_AVAILABLE_TRAILER, "Pick any available trailer")}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT, "Starts at")}:</label>
                                        <div className="col-sm-12">
                                            <Flatpickr placeholder="Start Date" ref={stDateRef} options={dateOptions}
                                                onChange={onChangeStDate} onOpen={onOpenStDate}
                                                className={startDateClass} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends at")}:</label>
                                        <div className="col-sm-12">
                                            <Flatpickr placeholder="End Date" ref={endDateRef} options={dateOptions}
                                                onChange={onChangeEdDate} onOpen={onOpenEDDate}
                                                className={endDateClass} />
                                        </div>
                                    </div>
                                </div>

                                {/*isSpecificRental || isGenericRental?
                                <div className="col-sm-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends at")}:</label>
                                    <div className="col-sm-12">
                                        <Flatpickr placeholder="End Date" ref={endDateRef} options={dateOptions}
                                                   onChange={onChangeEdDate} onOpen={onOpenEDDate}
                                                   className={endDateClass} />
                                    </div>
                                </div>
                            </div>
                                : <Flatpickr placeholder="End Date" ref={endDateRef} options={dateOptions}
                                onChange={onChangeEdDate} onOpen={onOpenEDDate}
                                className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker d-none" />*/
                                }

                                <div className="col-sm-6 col-6"
                                    style={{ display: ((isSpecificReposition || isSpecificRental) ? "none" : "") }}>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP, "Available equipment for this period")}</label>
                                        <div className="col-sm-12">
                                            <input ref={qtyRef} type="number" value={quantity} min={0}
                                                className="form-control clsStandardText clsFormInputControl"
                                                placeholder="" aria-label="trailer" pattern="\d+" onChange={(e) => {
                                                    setQuantity(e.currentTarget.value);
                                                    CommonUtil.isMandatory(qtyRef);
                                                }} />
                                        </div>
                                    </div>
                                </div>


                                <div className="col-sm-6 col-6"
                                    style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED, "Days allowed for repositioning")}</label>
                                        <div className="col-sm-12">
                                            <input ref={noOfDayRef} type="number" min={0} value={noOfDays}
                                                pattern="^[0-9]"
                                                className="form-control clsStandardText clsFormInputControl"
                                                placeholder="" aria-label="trailer" onChange={handleNoOfDays} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12"
                                    style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOAD_TYPE, "Load Type")}</label>
                                        <div className="col-sm-12">
                                            <SingleSelectComp dataSource={loadTypes} selectedValue={loadTypeObj}
                                                handleChange={onChangeLoadType} width={"100%"}
                                                value={"key"} label={"value"} className={"mandatory"}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 className="mt-2">{CommonUtil.getMessageText(Constants.LOCATION, "Location")}</h6>
                            <div className="row">
                                <div className="col-sm-6 col-12"></div>
                                <div className="col-sm-6 col-12" style={{ textAlign: "end" }}>
                                    <button className="btn btn-warning waves-effect"
                                        onClick={onCreateParkingLot}>{CommonUtil.getMessageText(Constants.CREATE_PARKING_LOT, "Create Parking Lot")}</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT, "Departure Parking Lot")}</label>
                                        <div className="col-sm-12 col-12">
                                            <SingleSelectComp dataSource={parkingLots} selectedValue={selDepartureObj}
                                                handleChange={onChangeDepartureLot} width={"100%"}
                                                value={"parkingLotId"} label={"parkingLotName"} className={arrivalAddrClass}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12">
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT, "Arrival Parking Lot")}</label>
                                        <div className="col-sm-12 ">
                                            <SingleSelectComp dataSource={parkingLots} selectedValue={selArrivalObj}
                                                handleChange={onChangeArrivalLot} width={"100%"}
                                                value={"parkingLotId"} label={"parkingLotName"}
                                                className={departureAddrClass}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: (isInuranceChecked ? "" : "none") }}>
                                    <h6 className="mt-2">Insurance</h6>
                                    <div className="row" style={{ display: (isLiabilityChecked ? "" : "none") }}>
                                        <div className="col-sm-6 col-12" >
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_PLAN, "Liability Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <SingleSelectComp dataSource={liabilityPolicies} selectedValue={selLiability}
                                                        handleChange={onChangeLiability} width={"100%"} value={"policyId"} label={"policy"}
                                                        className={liabilityClass}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12" style={{ paddingTop: "8px" }}>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ visibility: "hidden" }}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-6 col-6 flex" style={{ display: (isLiabilityPaidByOwner ? "" : "none") }}>
                                                    <input ref={rdLbtPaidByOwner} type="radio" name="liability" className="checkBox" checked={liabilityPaidByOwner}
                                                        onChange={onChangeLiabilityPaidByOwner} />
                                                    <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_OWNER, "Paid By Owner")}
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-6 flex" style={{ display: (isLiabilityPaidByRenter ? "" : "none") }}>
                                                    <input ref={rdLbtPaidByRenter} type="radio" name="liability" className="checkBox" checked={liabilityPaidByRenter}
                                                        onChange={onChangeLiabilityPaidByRenter} />
                                                    <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_RENTER, "Paid By Renter")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row" style={{ display: (isPhysicalDamageChecked ? "" : "none") }}>
                                        <div className="col-sm-6 col-12" >
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN, "Physical Damage Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <SingleSelectComp dataSource={physicalDamagePolicies} selectedValue={selPhysicalDamage}
                                                        handleChange={onChangePhysicalDamage} width={"100%"} value={"policyId"} label={"policy"}
                                                        className={physicalDamageClass}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12" style={{ paddingTop: "8px" }}>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ visibility: "hidden" }}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-6 col-6 flex" style={{ display: (isPhysicalDamagePaidByOwner ? "" : "none") }}>
                                                    <input ref={rdPDmgPaidByOwner} type="radio" name="physicalDamage" className="checkBox"
                                                        checked={physicalDamagePaidByOwner} onChange={onChangePhysicalDamageOwner} />
                                                    <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_OWNER, "Paid By Owner")}
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 col-6 flex" style={{ display: (isPhysicalDamagePaidByRenter ? "" : "none") }}>
                                                    <input ref={rdPDmgPaidByRenter} type="radio" name="physicalDamage" className="checkBox"
                                                        checked={physicalDamagePaidByRenter} onChange={onChangePhysicalDamageRenter} />
                                                    <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_RENTER, "Paid By Renter")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <h6 className="mt-1">{CommonUtil.getMessageText(Constants.PRICING, "Pricing")}</h6>
                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                        <label
                                            className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                                        <div className="col-sm-12 col-12">
                                            <SingleSelectComp dataSource={currencies} selectedValue={currencyObj}
                                                handleChange={onChangeCurrency} disabled={isCurrecyDisable ? true : false} width={"100%"}
                                                value={"key"} label={"value"} className={currencyClass} />
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="row">
                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="col-sm-12 col-12">

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12 col-md-6"
                                    style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE, "Paid Price to Carrier for Repositioning")}:</label>
                                        <div className="col-sm-6 col-12 col-md-12">
                                            <input ref={movePriceRef} type="text" required
                                                className="form-control clsStandardText clsFormInputControl"
                                                placeholder="" value={movePrice} min={0} aria-label="trailer"
                                                onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                    CommonUtil.handleNumbersDecimalInput({
                                                        event: e,
                                                        ref: movePriceRef,
                                                        setState: setMovePrice
                                                    })
                                                }} />
                                        </div>
                                        <p style={{ marginBottom: "0px", paddingTop: "5px", fontSize: "12px", color: "red", display: movePrice ? "" : "none" }}>
                                            {platformFeeMsgForPaidByRenter()}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 col-md-6"
                                    style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE, "Additional Price per Excess Days")}</label>
                                        <div className="col-sm-6 col-12 col-md-12">
                                            <input ref={priceRef} type="text" required
                                                className="form-control clsStandardText clsFormInputControl"
                                                placeholder="" aria-label="trailer" value={addtionalPrice} min={0}
                                                onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                    CommonUtil.handleNumbersDecimalInput({
                                                        event: e,
                                                        ref: priceRef,
                                                        setState: setAdditionalPrice
                                                    })
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: (isSpecificReposition ? "none" : "none") }}>
                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE, "Estimated distance in miles")}</label>
                                        <div className="col-sm-6 col-12 col-md-12">
                                            <input ref={eDisRef} type="text" min={0}
                                                className="form-control clsStandardText"
                                                value={eDisRate}
                                                placeholder=""
                                                aria-label="trailer"
                                                onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                    CommonUtil.handleNumbersDecimalInput({
                                                        event: e,
                                                        ref: eDisRef,
                                                        setState: setEDisRate
                                                    })
                                                }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE, "Price per mile")}</label>
                                        <div className="col-sm-6 col-12 col-md-12">
                                            <input ref={priceKmRef} type="text" min={0}
                                                className="form-control clsStandardText"
                                                placeholder="" aria-label="trailer" value={priceKm}
                                                onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                    CommonUtil.handleNumbersDecimalInput({
                                                        event: e,
                                                        ref: priceKmRef,
                                                        setState: setPriceKm
                                                    })
                                                }} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row" style={{ marginTop: "-20px", display: ((isSpecificReposition || isGenericReposition) ? "none" : "") }}>
                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="col-sm-12 col-12 col-md-12" style={{ visibility: "hidden" }}>
                                        <label className="col-sm-12 col-12 col-md-12 col-form-label"
                                            style={{ paddingLeft: "0px" }}>Default Price(s)</label>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12">
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={dailyRef} type="text" min={0} pattern="[0-9]*"
                                                    className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                    placeholder="" aria-label="trailer" value={dailyRateAvl}

                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                        CommonUtil.handleNumbersDecimalInput({
                                                            event: e,
                                                            ref: dailyRef,
                                                            setState: setDailyRateAvl
                                                        })
                                                    }} /> / {CommonUtil.getMessageText(Constants.DAY, "DAY")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12" style={{ display: !defWeeklyRate && !weeklyRateAvl ? "none" : "" }}>
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={weeklyRef} type="text" min={0} pattern="[0-9]*"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" aria-label="trailer" value={weeklyRateAvl}

                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                        CommonUtil.handleNumbersDecimalInput({
                                                            event: e,
                                                            ref: weeklyRef,
                                                            setState: setWeeklyRateAvl
                                                        })
                                                    }} /> / {CommonUtil.getMessageText(Constants.WEEK, "Week")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12" style={{ display: !defMonthlyRate && !monthlyRateAvl ? "none" : "" }}>
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={monthlyRef} type="text" min={0} pattern="[0-9]*"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" aria-label="trailer" value={monthlyRateAvl}

                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                        CommonUtil.handleNumbersDecimalInput({
                                                            event: e,
                                                            ref: monthlyRef,
                                                            setState: setMonthlyRateAvl
                                                        })
                                                    }} /> / {CommonUtil.getMessageText(Constants.MONTH, "Month")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12">
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE, "Distance Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={distanceRef} type="text" min={0}
                                                    className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                    value={distanceRate}
                                                    placeholder=""
                                                    aria-label="trailer"
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                        CommonUtil.handleNumbersDecimalInput({
                                                            event: e,
                                                            ref: distanceRef,
                                                            setState: setDistanceRate
                                                        })
                                                    }} /> / {CommonUtil.getMesurementByCountry()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12">
                                        <div className="form-group row"
                                            style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) ? "" : "none" }}>
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={engineRef} type="text" min={0}
                                                    className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                    placeholder=""
                                                    aria-label="trailer" value={engineRate}
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {
                                                        CommonUtil.handleNumbersDecimalInput({
                                                            event: e,
                                                            ref: engineRef,
                                                            setState: setEngineRate
                                                        })
                                                    }} /> / {CommonUtil.getMessageText(Constants.HOUR, "HOUR")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="col-sm-12 col-12 col-md-12" style={{ paddingTop: "10px" }}>
                                        <h6 className="col-sm-12 col-12 col-md-12 "
                                            style={{ paddingLeft: "0px", fontSize: "12px" }}>{CommonUtil.getMessageText(Constants.DEFAULT_PRICE, "Default Price(s)")}</h6>
                                    </div>
                                    <div className="col-sm-12 col-12 col-md-12">
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="number"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" min={0} aria-label="trailer" disabled={true}
                                                    value={defDailyRate} /> / {CommonUtil.getMessageText(Constants.DAY, "DAY")}
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{ display: !defWeeklyRate && !weeklyRateAvl ? "none" : "" }}>
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="number"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" min={0} aria-label="trailer" disabled={true}
                                                    value={defWeeklyRate} /> / {CommonUtil.getMessageText(Constants.WEEK, "Week")}
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{ display: !defMonthlyRate && !monthlyRateAvl ? "none" : "" }}>
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="number"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" min={0} aria-label="trailer" disabled={true}
                                                    value={defMonthlyRate} /> / {CommonUtil.getMessageText(Constants.MONTH, "Month")}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE, "Distance Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="number"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" min={0} aria-label="trailer" disabled={true}
                                                    value={defDistanceRate} /> / {CommonUtil.getMessageText(Constants.MILE, "MILE")}
                                            </div>
                                        </div>
                                        <div className="form-group row"
                                            style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) ? "" : "none" }}>
                                            <label
                                                className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="number"
                                                    className="form-control clsStandardText  avail-text-field"
                                                    placeholder="" min={0} aria-label="trailer" disabled={true}
                                                    value={defEngineRate} /> / {CommonUtil.getMessageText(Constants.HOUR, "HOUR")}
                                            </div>
                                        </div>







                                    </div>
                                </div>
                            </div>


                            {/* <div className="row" style={{ paddingTop: "10px" }}>

                                <div className="col-sm-12 col-lg-6 col-md-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAILABLE_VIEW_TYPE, "Availablity View Type")}</label>
                                        <div className="col-sm-6 col-12 col-md-12">
                                            <SingleSelectComp dataSource={availableRental} selectedValue={selectedAvailView}
                                                defaultValue={CommonUtil.AVAILABLE_RENTAL[0]}
                                                handleChange={onChangeAvailType} width={"100%"} value={"key"} label={"value"}
                                                className={avlTypeClass} disabled={isDisabled ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {selectedViewType === 'SPECIFIC_VIEW' ?
                                    <div className="col-sm-12 col-lg-6">
                                        <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.CARRIER, "Carrier")}</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-lg-12 col-md-12">
                                                <SingleSelectComp
                                                    dataSource={closeNetworkList} multiple={true}
                                                    handleChange={onChangeCloseNetwork} width={"100%"}
                                                    value={"orgCloseNetworkId"} label={"networkName"}
                                                    className={avlCarrierClass} disabled={isDisabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div> : ""
                                }

                            </div> */}


                                {
                                    closeNetworkList?.length > 0 ?
                                    <>
                                        <div className="row" >
                                            <div className="col-sm-12 col-12">
                                                <div className="form-group row">
                                                    <div className="col-sm-12 col-12  flex-wrap">
                                                        <div className="form-check">                                                   
                                                            <label className="form-check-label">
                                                            <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                                setSelectedViewType("PUBLIC_VIEW");
                                                                setSelectedcloseNetwork("")
                                                                setSpecificCloseNetwork([])
                                                            }}  checked={selectedViewType == "PUBLIC_VIEW" ? true : false} />
                                                                 Create a public availability (visible to all Renters & Carriers).
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                        
                                                            <label className="form-check-label" >
                                                            <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                                setSelectedViewType("SPECIFIC_VIEW")
                                                            }} checked={selectedViewType == "SPECIFIC_VIEW" ? true : false} />
                                                                Availability visible only to the equipment owner (Hidden from all Renters & carriers).
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                        
                                                            <label className="form-check-label" >
                                                            <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                                setSelectedViewType("PRIVATE_VIEW")
                                                                setSpecificCloseNetwork([])
                                                            }} checked={selectedViewType == "PRIVATE_VIEW" ? true : false} />
                                                                Availability visible only to the Private Network organizations.
                                                            </label>
                                                        </div>
                                                        
                                                        {!CommonUtil.IS_GENERIC_EQUIP &&
                                                        <>
                                                            <div className="form-check">
                                                            
                                                                <label className="form-check-label" >
                                                                <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                                    setSelectedViewType("ASSIGN_RENTER_VIEW")
                                                                    setSelectedcloseNetwork("");
                                                                }} checked={selectedViewType == "ASSIGN_RENTER_VIEW" ? true : false} />
                                                                    Assign the reservation to renter under private network.
                                                                </label>
                                                            </div>
                                                            {selectedViewType == "ASSIGN_RENTER_VIEW" && 
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-6">
                                                                        <SingleSelectComp
                                                                        selectedValue={selectedcloseNetwork}
                                                                            dataSource={closeNetworkListNotSelected} 
                                                                            handleChange={onChangeCloseNetwork} 
                                                                            value={"orgCloseNetworkId"} label={"orgName"}
                                                                            className={avlCarrierClass} 
                                                                            // disabled={isDisabled ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                        }
                                                       
                                                        <div className="col-sm-12 col-lg-6" style={{display:selectedViewType == "SPECIFIC_VIEW" ? "" : "none"}}>
                                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.CARRIER, "Carrier")}</label>
                                                            <div className="form-group row">
                                                                <div className="col-sm-12 col-lg-12 col-md-12 d-flex">
                                                                    <div style={{width:"80%"}}>
                                                                    <SingleSelectComp
                                                                        selectedValue={selectedcloseNetwork}
                                                                        dataSource={closeNetworkListNotSelected} 
                                                                        handleChange={onChangeCloseNetwork} 
                                                                        value={"orgCloseNetworkId"} label={"orgName"}
                                                                        disabled = {selectedViewType == "PUBLIC_VIEW"}
                                                                        className={avlCarrierClass} 
                                                                        // disabled={isDisabled ? true : false}
                                                                    />
                                                                    </div>

                                                                    <div style={{width:"20%"}}>
                                                                        <button type="button" className="btn btn-primary" style={{float:"left", marginLeft:"10px", marginTop:"3px"}} onClick={(e:any)=>{
                                                                        if(selectedcloseNetwork){
                                                                            const selectedarr:any= [...specificCloseNetwork];
                                                                            selectedarr.push(selectedcloseNetwork);
                                                                            setSelectedcloseNetwork("")
                                                                            setSpecificCloseNetwork(selectedarr);
                                                                        }
                                                                        }}
                                                                        disabled = {selectedViewType == "PUBLIC_VIEW"}
                                                                        >Add</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row" style={{display:selectedViewType == "SPECIFIC_VIEW" ? "" : "none"}}>
                                            <div className="col-sm-12 col-12">
                                                <div className="ag-theme-alpine gridHeight"
                                                    style={{ height: '200px', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                                                    <AGGrid id={`availtype${CommonUtil.EQUIPMENT_LIST ? '_a' : ''}`} cols={availviewTypeDef} hideToolbar={true}
                                                        rows={specificCloseNetwork} pageSize={500} headerHeight={30} rowHeight={40}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>:""
                                }   



                            <div className="row" style={{ paddingTop: "10px" }}>
                                <div className="col-sm-12 col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-12  flex">
                                            <input ref={chkAllowRef} type="checkbox" className="checkBox" disabled={isDisabled ? true : false} />
                                            <label style={{ paddingLeft: "10px", display: "inline" }}>
                                                {CommonUtil.getMessageText(Constants.ALLOW_RENTER, "Allow renter to make an offer for this availability")}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ border: "1px solid #999", height: "100px", margin: "1px" }}>
                                <div className="col-sm-12 col-12" style={{ display: "flex", alignItems: "center" }}>
                                    <div className="new-repositioning">
                                        <div className="form-group row" style={{ marginBottom: "0px" }}>
                                            <div id="repositioning" className="col-sm-1 col-2">
                                                <i className="fa fa-tag" style={{ fontSize: "24px" }}></i>
                                            </div>
                                            <div className="col-sm-11 col-10">
                                                <p style={{ marginBottom: "0px" }}>{CommonUtil.getMessageText(Constants.APPLY_RENTER, "These are the rates that will apply for this rental. vHub will\n" +
                                                    "                                                            charge a fee in addition to these rates. Please refer to our\n" +
                                                    "                                                            Payment Terms for full details.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" id={"divConditions"}>
                                <div className="col-sm-12 col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-12 checkbox flex" style={{ paddingTop: "10px" }}>
                                            <input ref={chkTerms} type="checkbox" id="vehicle1" name="vehicle1"
                                                className="checkBox"
                                                onChange={(e) => {
                                                    (e.currentTarget.checked ? $("#divTerms").hide() : $("#divTerms").show())
                                                }} /> &nbsp;
                                            <label
                                                style={{ display: "inline" }}> {CommonUtil.getMessageText(Constants.TAX_EXEMPTION_AVAIL, "I have filled my tax exemptions OR I don’t have tax exemptions")}</label>

                                        </div>
                                        <div className="col-sm-12 col-12" id={"divTerms"} style={{ display: "none" }}>
                                            <label
                                                style={{ color: 'red' }}>{CommonUtil.getMessageText(Constants.TAX_EXEMPTION_ACCEPT, "You must accept the vHub Tax Exemptions")}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 shadow-sm p-2 border border-danger" style={{ display: "none" }}>{CommonUtil.getMessageText(Constants.WILL_BE_CHARGED, "You will be charged for each excess day that the trailer is late.")}</div>
                                </div>

                            </div>

                        </section>
                    </div>

                </div>
                <div className="modal-footer">
                    <button className="btn btn-warning waves-effect"
                        onClick={onCloseAvailPopupshow}>{CommonUtil.getMessageText(Constants.CLOSE, "Close")}</button>
                    {!CommonUtil.IS_GENERIC_EQUIP && selectedViewType ==="ASSIGN_RENTER_VIEW" ?     
                    <button type="submit" id={"btnSubmit"} className="btn btn-primary waves-effect waves-light"
                        onClick={onClickSaveAvailabilityAndReservation}>{CommonUtil.getMessageText(Constants.CREATE_AND_BOOK, "Create and Book Reservation")}
                    </button>
                    :
                    <button type="submit" id={"btnSubmit"} className="btn btn-primary waves-effect waves-light"
                        onClick={()=>onClickSaveAvailability()}>{CommonUtil.getMessageText(Constants.CREATE, "Create")}
                    </button>
                    }

                </div>
            </div>
        </Modal>
    </>)
}
export default AvailabilityCreateModal;
