import React from "react";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";

const NotificationModal = ({
                               modalShow,
                               handleClose,
                               handleSubmit,
                               own,
                               notificationList,
                               selectedUsers,
                               onClickHandle,
                               setSelectedUsers
                           }: any) => {
    return (<>
        <Modal show={modalShow} centered dialogAs={DraggableComponent}>
            <div className="modal-content" style={{"width": "600px",height:"500px"}}>
                <Modal.Header className="modal-header" closeButton={false}>
                    <h5 className="modal-title mt-0">{CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <div className="modal-body"  style={{overflow:"auto"}}>
                    <div className="modal-body">
                        {own ? null : (<p>{CommonUtil.getMessageText(Constants.SELECTED_USERS, 'Selected Users:')} <b>{selectedUsers && selectedUsers.length >= 3 ? (selectedUsers[0]?.name + ',' + selectedUsers[1]?.name + ', +' + `${selectedUsers.length - 2}`) : selectedUsers.map((user: any, index: any) => {
                                return (<>{user.name}{index === 0 ? ',' : null}</>)
                            })}{}</b></p>)}
                        <div className="row">
                            <div className="col-6"><h6>{CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications')}</h6></div>
                            <div className="col-6 text-center"><h6>{CommonUtil.getMessageText(Constants.EMAIL_ALERT, 'Email Alert')}</h6></div>
                        </div>

                        {notificationList && notificationList.map((notification: any, index: any) => {
                            return (
                                <div className="row mt-2" key={notification.notificationId}>
                                    <div className="col-6"><strong>{notification.notificationName}</strong></div>
                                    <div className={'col-6 text-center'}>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input custom-form-switch" name={'isEmail'}
                                                   defaultChecked={notification.notificationAssocStatus === 'Y' ? true : false}
                                                   checked={notification.notificationAssocStatus === 'Y' ? true : false}
                                                   type="checkbox"
                                                   role="switch"
                                                   onClick={(e) => {
                                                       onClickHandle(e, index)
                                                   }} id="flexSwitchCheckDefault"/>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-dark"
                            data-dismiss="modal" onClick={handleClose}>
                        {CommonUtil.getMessageText(Constants.CANCEL, 'Cancel')}
                    </button>
                    <button type="submit" className="btn btn-success"
                            onClick={handleSubmit}
                    >
                        {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                    </button>
                </div>
            </div>
        </Modal>
    </>)
}
export default NotificationModal;