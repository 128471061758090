import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import {NetworkManager} from "../../Components/NetworkManager";
import {useNavigate} from "react-router-dom";

const LoginModal = ({showModal,onLogin,closeModal}:any) => {
    const { isMobile } = DetectDevice();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [userName, setUserName] = useState("");
    const [fuserName, setFUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);


    const fEmailRef = useRef<HTMLInputElement>(null);
    const passRef = useRef<HTMLInputElement>(null);

    useEffect(()=>{
        console.log(showModal);
    },[]);

    let onEmailChange = (e: any): void => {
        let uname = e.currentTarget.value;
        setUserName(uname);
        setErrorMsg("");
        CommonUtil.isEmailMandatory(emailRef);
    }

    let onFEmailChange = (e: any) => {
        let uname = e.currentTarget.value;
        setFUserName(uname);
        CommonUtil.isEmailMandatory(fEmailRef);
    }

    let onChangePass = (e: any): void => {
        setPassword(e.currentTarget.value);
        setErrorMsg("");
        CommonUtil.isMandatory(passRef);
    }

    let onClickLogin = () => {
        var isUNameMandatory = CommonUtil.isEmailMandatory(emailRef);
        var isPassMandatory = CommonUtil.isMandatory(passRef);
        if (isUNameMandatory && isPassMandatory) {
            emailRef.current?.classList.add("is-valid");
            var restObj: any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
            if (emailRef.current) {
                restObj.userName = CommonUtil.trim(emailRef.current.value);
            }
            if (passRef.current) {
                restObj.password = CommonUtil.trim(passRef.current.value);
            }

            NetworkManager.sendJsonRequest("api/auth/login", restObj, "", "POST", true, onLoginSuccess, onLoginError);
        } else {

        }
    }

    let onLoginError = (err: any) => {
        console.log(err);
    }

    let onLoginSuccess = (data: any) => {
        //console.log(data);
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                if (emailRef.current) {
                    localStorage.setItem("user", emailRef.current.value);
                }
                localStorage.setItem("token", restData.token);
                localStorage.setItem("refreshToken", restData.refreshToken);
                localStorage.setItem("orgId", restData.saasOrgId);
                localStorage.setItem("saasorgId", restData.saasOrgId);
                localStorage.setItem("orgName", restData.orgName);
                localStorage.setItem("root", restData.root);
                localStorage.setItem("userLogin", "false");
                localStorage.setItem("userId", restData.userId);
                localStorage.setItem("ViewTab", "false");
                localStorage.setItem("ViewDB", "true");
               // navigate("/signup");
                //localStorage.setItem("PublicSearch", "false");
               // CommonUtil.PUBLIC_SEARCH_DB = false;
               // CommonUtil.PRIVATE_SEARCH_DB = false;


                CommonUtil.LOGIN_USER_ID = restData.userId;
                CommonUtil.addScreensToLastAccessed("menu");

                var qParam = restData.saasOrgId;
                var orgUrl = 'api/organization/' + qParam;
                NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, (data: any)=>{
                    if (data) {
                        var restData = CommonUtil.parseData(data)
                        if (restData) {
                            CommonUtil.CUSTOMER_COUNTRY = restData.country;
                            CommonUtil.ORGANIZATION_TYPE = restData.organizationType;
                            localStorage['orgType'] = restData.organizationType;
                            localStorage.setItem('country', restData.country);
                            if (restData.ownerStatus) {
                                CommonUtil.OWNER_STATUS = restData.ownerStatus;
                            } else {
                                CommonUtil.OWNER_STATUS = "";
                            }
                            if (restData.renterStatus) {
                                CommonUtil.RENTER_STATUS = restData.renterStatus;
                            } else {
                                CommonUtil.RENTER_STATUS = "";
                            }
                        }
                        onLogin();
                        closeModal();
                    }
                }, onLoginError);


               // navigate("/menu");//,"_blank");

               // let equipAllAPI: string = 'api/reservation/getReservationProcessKey?driverId=51&reservationCode=UFOSGHD';// + qParams;
                //NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGenerateReservationKey, onLoginError);
                //getRefreshToeken();
            }
        }

    }

    return (<>
        <Modal show={showModal} dialogAs={DraggableComponent}>
            <div className="modal-content" style={{ "width": "500px", "height": "350px" }}>
                <div className="modal-header modalPopupHeader">
                    <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.LOGIN, "Login")}</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "0px" }}
                            onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>

                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: "10px" }}>
                    <div className={`container-login`} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <div className="row">
                            <div className="col-12">

                                <div className="mx-auto">
                                    <div className="row ms-4 mt-3 me-4">
                                        <form ref={formRef} className={`pb-4 needs-validation requires-validation`}
                                              noValidate>

                                            <div className="mb-3">
                                                <label className="form-label">{CommonUtil.getMessageText(Constants.EMAIL, "Email")}</label>
                                                <input ref={emailRef} type="email"
                                                       className="form-control login-input clsFormInputControl" id="inputEmail"
                                                       required={true} placeholder="Email"
                                                       onFocus={() => { setShowPassword(false) }}
                                                       value={userName} onChange={(e) => {
                                                    onEmailChange(e)
                                                }} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">{CommonUtil.getMessageText(Constants.PASS, "Password")}</label>
                                                <div className="input-group" >
                                                    <input ref={passRef} type={showPassword ? 'text' : 'password'}
                                                           className="form-control login-input clsFormInputControl "
                                                           id="inputPassword" placeholder="Password"
                                                           value={password} required={true} onChange={(e) => {
                                                        onChangePass(e)
                                                    }} />
                                                    <span className="input-group-text" onClick={() => { setShowPassword(!showPassword) }} style={{ cursor: 'pointer' }} >{showPassword ? <i className="fa-solid fa-eye-slash" style={{ color: '#3B71CA' }} aria-hidden="true" ></i> : <i className="fa fa-eye" style={{ color: '#3B71CA' }} aria-hidden="true"></i>}</span>
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                            onClick={closeModal}>{CommonUtil.getMessageText(Constants.CLOSE, "Close")}
                    </button>
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onClickLogin}>
                        {CommonUtil.getMessageText(Constants.LOGIN, "Login")}
                    </button>
                </div>
            </div>

        </Modal>
    </>)
}
export default LoginModal;
