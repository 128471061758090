import React from 'react'
import CarouselComponent, { InnerImage } from '../CarouselComponent'
import ImageLoader from '../ImageLoader'
import '../Interchange.css'
import { useState, useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'
import { Constants } from '../../Languages/Constants'


const ExitLightsInspection = () => {

    const [lightDetails, setLightDetails] = useState<any>([])
    const [attachments, setAttachments] = useState<any>([])
    const [galleryPop, setGalleryPop] = useState(false);
    const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);
    const [damageDes, setDamageDes] = useState("")
    const [damageMap, setDamageMap] = useState<any>([])

    const [fullDamageView, setFullDamageView] = useState<any>([]);
    const [entryDamage, setEntryDamage] = useState("");
    const [showMap, setShowMap] = useState(false);

    let getLightInspectionDetails = () => {

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetLightInspectionDetails, onLoginError);

    }

    useEffect(() => {
        getLightInspectionDetails();
    }, [])

    let onGetLightInspectionDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            setDamageDes(restData?.lightInspecton?.attachments?.attachmentDamageDescription)
            if (restData.lightInspecton?.taskDetailsAttachment) {
                var light = [];
                if (Array.isArray(restData?.lightInspecton?.taskDetailsAttachment)) {
                    light = restData?.lightInspecton?.taskDetailsAttachment;
                } else {
                    light.push(restData?.lightInspecton?.taskDetailsAttachment);
                }
                setLightDetails(light)
                setAttachments(restData?.lightInspecton?.attachments);
                let top = restData?.lightInspecton?.damageMap?.filter((view: any) => {
                    return view.position === 'fullDamageView'
                })

                setDamageMap(top)

            }
        }
    }



    let onLoginError = (err: any) => {
        console.log(err)
    }

    const handleData = (name: any, damageMap: any, attached: any, des: any) => {
        setShowMap(true)
        setEntryDamage(name)
        setFullDamageView(damageMap)
        setDamageMap(attached)
        setDamageDes(des)
    }

    let Capitalize = (str: any) => {
        str = String(str);
        let strText = str.charAt(0).toUpperCase() + str.slice(1);

        return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")

    }

    let attachImages: any = [];
    try {
        attachImages = attachments.attachmentUploads.map((ele: any) => ele.fullFilePath)
        console.log(attachImages)
    } catch (e) {
        attachImages = [];
    }

    return (
        <div>
            <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                    <div className="lights-details">
                        <div className="inner-table-data table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">INSPECTION ITEM</th>
                                        <th scope="col">PASS</th>
                                        <th scope="col">FAIL</th>
                                        <th scope="col">NOTE</th>
                                        <th scope="col">COUNT</th>
                                        <th scope="col">TYPE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lightDetails.map((data: any) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{Capitalize(data.name)}</td>
                                                    <td className={`${String(data.status).toUpperCase() === Constants.FAIL ? "text-danger text-uppercase table-danger" : "text-success text-uppercase"}`}>{String(data.status).toUpperCase() === Constants.FAIL ? "NO" : "YES"}</td>
                                                    <td className={`${String(data.status).toUpperCase() === Constants.OK ? "text-success text-uppercase" : "text-danger text-uppercase table-danger"}`}>{String(data.status).toUpperCase() === Constants.OK ? "NO" : (<><div style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }} onClick={() => { handleData(Capitalize(data.name), data?.damageMap?.map((img: any) => img.fullFilePath), data?.attachments?.attachmentUploads?.map((img: any) => img.fullFilePath), data?.attachments?.attachmentDamageDescription) }}>Yes</div></>)}</td>
                                                    <td>{data.note !== "" ? data.note : ""}</td>
                                                    <td>{data.count !== "" ? data.count : ""}</td>
                                                    <td>{data.selectionOption ? data.selectionOption : ""}</td>
                                                </tr>
                                            </>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {showMap ? (
                    <>
                        {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 text-center">
                            <h2 className="title-head my-3">{entryDamage} Damage View</h2>

                            <InnerImage path={fullDamageView} />

                        </div> */}
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="mx-auto my-auto justify-content-center">
                                <div className="gallery-carousel">
                                    <h2 className="title-head my-3">{entryDamage} Damage Photos</h2>

                                    <CarouselComponent images={damageMap} callBack={(idx: any) => {
                                        setGalleryPop(true);
                                        setGalleryPopStartIndex(idx);
                                    }} />
                                    <ReactImageGallery startIndex={galleryPopStartIndex} attachedImages={damageMap} showPop={galleryPop} handleClose={() => { setGalleryPop(false) }} />

                                </div>
                            </div>

                            <div className="gallery-form form">
                                <h2 className="title-head my-4 mb-2">{entryDamage} Damage Description</h2>
                                <div className="form-floating">
                                    <div className="form-control" placeholder="Leave a comment here"
                                        id="floatingTextarea2"
                                        style={{ minHeight: '100px', height: "auto" }}>{damageDes}</div>

                                </div>
                            </div>
                        </div>
                    </>) : ""}


            </div>
        </div>
    )
}

export default ExitLightsInspection