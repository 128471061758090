import React, {useEffect, useRef, useState} from "react";
import DraggableComponent from "../../Components/DraggableComponent";
import {getSelectedRangeRate,CommonUtil} from "../../utils/CommonUtil";
import Flatpickr from "react-flatpickr";
import {Modal} from "react-bootstrap";
import $ from "jquery";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import Confirm from "../../Components/confirm";
import { Constants } from "../Languages/Constants";

const OfferCreate = ({modalShow, handleClose, handleSubmit,response}: any)=>{

    const [equipmentData,setEquipmentData] = useState<any>(null);
    const [showOfferPopup,setShowOfferPopup] = useState(false);

    const [assetType,setAssetType] = useState("");
    const [equipType,setEquipType] = useState("");
    const [unitNumber,setUnitNumber] = useState("");
    const [vinNumber,setVinNumber] = useState("");
    const [brand,setBrand] = useState("");
    const [model,setModel] = useState("");
    const [year,setYear] = useState("");
    const [equipName,setEquipName] = useState("");

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);

    const stDateRef1 = useRef<Flatpickr>(null);
    const endDateRef1 = useRef<Flatpickr>(null);

    const stDateRef2 = useRef<Flatpickr>(null);
    const edDateRef2 = useRef<Flatpickr>(null);

    const numDayRef = useRef<HTMLInputElement>(null);
    const dailyRateRef = useRef<HTMLInputElement>(null);
    const distanceRef = useRef<HTMLInputElement>(null);

    const distanceToTravelRef = useRef<HTMLInputElement>(null);
    const engineHoursUnitRef = useRef<HTMLInputElement>(null);

    const [distanceToTravelPopup,setDistanceToTravelPopup] = useState("");
    const [engineHoursUnitPopup,setEngineHoursUnitPopup] = useState("");


    const [noOfDaysPopup,setNoOfDaysPopup] = useState("");
    const [dailyPricePopup,setDailyPricePopup] = useState("");
    const [weeklyPricePopup,setWeeklyPricePopup] = useState("");
    const [monthlyPricePopup,setMonthlyPricePopup] = useState("");
    const [disPricePopup,setDisPricePopup] = useState("");

    const [movePricePopup,setMovePricePopup] = useState("");
    const [additionalPricePopup,setAdditionalPricePopup] = useState("");

    const [stOfferDate,setStOfferDate] = useState("");
    const [edOfferDate,setEDOfferDate] = useState("");
    const [offerRightrateObj,setOfferRightrateObj] = useState<any>({});

    const [policyId,setPolicyId] = useState("");
    const [perDayRate,setPerDayRate] = useState("");


    const [numOfAxes,setNumOfAxes] = useState("");
    const [trailerLength,setTrailerLength] = useState("");
    const [license,setELicense] = useState("");
    const [expDate,setExpDate] = useState("");

    const [axelSpread,setAxelSpread] = useState("");
    const [tireInflation,setTireInflation] = useState("");
    const [skirts,setSkirts] = useState("");
    const [pintleHook,setPintleHook] = useState("");
    const [hazmat,setHazmat] = useState("");
    const [suspension,setSuspension] = useState("");

    const [rearDoor,setRearDoor] = useState("");
    const [sideDoor,setSideDoor] = useState("");
    const [roof,setRoof] = useState("");
    const [floor,setFloor] = useState("");
    const [floorType,setFloorType] = useState("");
    const [interior,setInterior] = useState("");
    const [logistics,setLogistics] = useState("");
    const [deckBeams,setDeckBeams] = useState("");

    const [liftType,setLiftType] = useState("");
    const [lift,setLift] = useState("");
    const [liftCap,setLiftCap] = useState("");

    const [unitCapacity,setUnitCapacity] = useState("");
    const [unitMake,setUnitMake] = useState("");
    const [unitModal,setUnitModal] = useState("");
    const [unitYear,setUnitYear] = useState("");
    const [multiTemp,setMultiTemp] = useState("");

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [emails,setEmails] = useState("");
    const [deposit,setDeposit] = useState("");
    const [imagePath,setImagePath] = useState("");
    const [availType,setAVailType] = useState("");

    const [stDate,setStDate] = useState("");
    const [endDate,setEndDate] = useState("");

    const [stDate1,setStDate1] = useState("");
    const [endDate1,setEndDate1] = useState("");

    const [stDate2,setStDate2] = useState("");
    const [endDate2,setEndDate2] = useState("");

    const [dateString,setDateString] = useState("");

    const [arrival,setArrival] = useState("");
    const [departure,setDeparture] = useState("");
    const [dailyRate,setDailyRate] = useState("");
    const [weeklyRate,setWeeklyRate] = useState("");
    const [monthlyRate,setMonthlyRate] = useState("");
    const [distanceRate,setDistanceRate] = useState("");
    const [distanceUnit,setDistanceUnit] = useState("km");
    const [engineRate,setEngineRate] = useState("");
    const [engineRatePopup,setEngineRatePopup] = useState("");


    const [quantity,setQuantity] = useState("");
    const [percentage,setPercentage] = useState("");
    const [days,setDays] = useState(0);
    const [durationPrice,setDurationPrice] = useState(0);
    const [movePrice,setMovePrice] = useState("");

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);

    const [distance,setDistance] = useState("");

    const [owner,setOwner] = useState("");
    const [renter,setRenter] = useState("");
    const [cad,setCAD] = useState("");
    const [percent,setPercent] = useState("");
    const [claims,setClaims] = useState("");
    const [allowOffer,setAllowOffer] = useState("");

    const [distancePrice,setDistancePrice] = useState(0);
    const [serviceFreePrice,setServiceFreePrice] = useState(0);
    const [totalAmout,setTotalAmount] = useState(0);
    const [addtionalPrice,setAdditionalPrice] = useState("");
    const [defPrice,setDefPrice] = useState("");
    const [isFavourExit,setFavour] = useState(false);

    const [parkingLots,setParkingLots] = useState<any>([]);
    const [selDepartureObj,setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj,setSelArrivalObj] = useState<any>(null);

    const [noofDays,setNoOfDays] = useState("");
    const [paidPrice,setPaidPrice] = useState("");
    //const [additionalPrice,setAdditionalPrice] = useState("");
    const [estimatedDistance,setEstimatedDistance] = useState("");
    const [pricepermile,setPricePerMile] = useState("");

    const numDayReposRef = useRef<HTMLInputElement>(null);
    const [numDayReposPopup,setNumDayReposPopup] = useState("");

    const [pricePerKmPopup,setPriceKmPopup] = useState("");



    const paidPricePopupRef = useRef<HTMLInputElement>(null);
    const [paidPricePopup,setPaidPricePopup] = useState("");

    const additionalPricePopupRef = useRef<HTMLInputElement>(null);
    const [qtyPopup,setQtyPopup] = useState("");

    const estimatedDisPopupRef = useRef<HTMLInputElement>(null);
    const [estimatedDisPopup,setEstimatedDistancePopup] = useState("");
    const [engineHoursPopup,setEngineHoursPopup] = useState("");

    const pricePerMilePopupRef = useRef<HTMLInputElement>(null);
    const [pricePerMilePopup,setPricePerMilePopup] = useState("");

    const [eDisRate,setEDisRate] = useState("");
    const [priceKm,setPriceKm] = useState("");

    const [policyIdOffer,setPolicyIdOffer] = useState("");
    const [perDayRateOffer,setPerDayRateOffer] = useState("");

    const [lPolicyId,setLPolicyId] = useState("");
    const [lPolicyIdRate,setLPolicyIdRate] = useState("");
    const [phyPolicyId,setPhyPolicyId] = useState("");
    const [phyPolicyRate,setPhyPolicyRate] = useState("");

    const [offerLPolicyId,setOfferLPolicyId] = useState("");
    const [offerLPolicyIdRate,setOfferLPolicyIdRate] = useState("");
    const [offerPhyPolicyId,setOfferPhyPolicyId] = useState("");
    const [offerPhyPolicyRate,setOfferPhyPolicyRate] = useState("");

    const [isLiabilityPaid,setLiabilityPaid] = useState(false);
    const [isPhysicalPolicyPaid,setIsPhysicalPolicyPaid] = useState(false);
    const [isInsuranceEnable,setIsInsuranceEnable] = useState(false);


    useEffect(()=>{
        //console.log( CommonUtil.SELECT_EQUIP_ID);
        setLiabilityPaid(false);
        setIsPhysicalPolicyPaid(false);
        setIsInsuranceEnable(false);
        console.log( response );
        getAvailabilityDetails();

    },[modalShow])

    let getAvailabilityDetails = ()=>{
        setPolicyId("");
        setPerDayRate("");
        if(CommonUtil.SELECT_EQUIP_AVAIL_ID){
            let parkingAPI:string = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetAvailabilityDetails,onLoginError);
        }
    }

    let onGetAvailabilityDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj){
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setAVailType(restData.listingType);

                setStDate(restData.startDate);
                setEndDate(restData.endDate);

                setStDate1(restData.startDate);
                setEndDate1(restData.endDate);


                setArrival(restData.arrivalParkingLotAddress);
                setDeparture(restData.departureParkingLotAddress);

                setDateString(restData.startDate+" - "+restData.endDate);
                setDailyRate(restData.dailyRate);
                setWeeklyRate(restData.weeklyRate);
                setMonthlyRate(restData.monthlyRate);
                //dailyPriceRate = Number(restData.dailyRate);
                setDistanceRate(restData.distanceRate);
               // distancePriceRate = Number(restData.distanceRate);
                setQuantity(restData.quantity);
                setAllowOffer(restData.allowOffer);
                //setMovePrice(restData.movePrice);
                setAdditionalPrice(restData.additionalPriceExcessDays);
                setDefPrice(restData.defaultCurrency);
                setNoOfDays(restData.noOfDaysAllowed);
                setMovePrice(restData.movePrice);
                setPriceKm(restData.pricePerKm);
                setEDisRate(restData.estimatedDistance);
                setEngineRate(restData.engineRate);

                setPolicyId(restData.policyName);
                setPerDayRate(restData.insurancePerDayAmount);

                setLPolicyId(restData.policyNameLiability);
                setPhyPolicyId(restData.policyNamePhysical);

                setLPolicyIdRate(restData.insurancePerDayAmountLiability);
                setPhyPolicyRate(restData.insurancePerDayAmountPhysical);

                if(restData.isInsurancePaidByRenterLiability == "Y" || restData.isInsurancePaidByRenterPhysical == "Y"){
                    setIsInsuranceEnable(true);
                }
                if(restData.isInsurancePaidByRenterLiability == "Y"){
                    setLiabilityPaid(true);
                }
                if(restData.isInsurancePaidByRenterPhysical == "Y"){
                    setIsPhysicalPolicyPaid(true);
                }

            }
        }

        showHideOfferFields();
        getOfferDetails();
    }

    let showHideOfferFields = ()=>{
        let arr = ['divNumOfDays','divAvailEquips','divDailyRate','divDistanceRate','divPaidPrice','divAdditionalPrice','divEstimatedDistance','divPricePerMail',
            'divNumOfDays1','divAvailEquips1','divDailyRate1','divWeeklyRate','divMonthlyRate','divWeeklyRate1','divMonthlyRate1','divDistanceRate1','divPaidPrice1','divAdditionalPrice1','divEstimatedDistance1','divPricePerMail1','divEngineHours','divEngineHours1'];
        arr.forEach((item:any,idx:number)=>{
            $("#"+item).hide();
        });

        if(CommonUtil.EQUIP_RESERVE_IS_GENERIC == "Y"){
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                $("#divAvailEquips").show();
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
                $("#divAvailEquips1").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                $("#divAvailEquips").show();
                $("#divDailyRate").show();
                $("#divDistanceRate").show();
                $("#divAvailEquips1").show();
                $("#divDailyRate1").show();
                $("#divWeeklyRate").show();
                $("#divMonthlyRate").show();
                $("#divWeeklyRate1").show();
                $("#divMonthlyRate1").show();
                $("#divDistanceRate1").show();
                //$("#divDistanceToTravel").show();
                if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    //$("#divEngineHoursUnit").show();
                }
            }
        }else{
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
               // $("#divEstimatedDistance").show();
               // $("#divPricePerMail").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
              //  $("#divEstimatedDistance1").show();
               // $("#divPricePerMail1").show();
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                $("#divDailyRate").show();
                $("#divWeeklyRate").show();
                $("#divMonthlyRate").show();
                $("#divDistanceRate").show();
                $("#divDailyRate1").show();
                $("#divWeeklyRate1").show();
                $("#divMonthlyRate1").show();
                $("#divDistanceRate1").show();
               // $("#divDistanceToTravel").show();
                if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    //$("#divEngineHoursUnit").show();
                }
            }
        }
    }

    let getOfferDetails = ()=>{//equipmentReservationOfferId
        if(response && response.equipmentReservationOfferId){
            let offerAPI:string = "api/offers/"+response.equipmentReservationOfferId+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(offerAPI,"","","GET",true,onGetOfferDetails,onLoginError);
        }
    }

    let onGetOfferDetails = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                //CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setStOfferDate(restData.startDate?restData.startDate:"");
                setEDOfferDate(restData.endDate?restData.endDate:"");
                setDailyPricePopup(restData.dailyRate?restData.dailyRate:"");
                setWeeklyPricePopup(restData.weeklyRate?restData.weeklyRate:"");
                setMonthlyPricePopup(restData.monthlyRate?restData.monthlyRate:"");
                setDisPricePopup(restData.distanceRate?restData.distanceRate:"");
                setMovePricePopup(restData.movePrice?restData.movePrice:"");
                setAdditionalPricePopup(restData.dailyRate?restData.dailyRate:"");
                setQtyPopup(restData.quantity?restData.quantity:"");
                setEstimatedDistancePopup(restData.estimatedEngineHoursPerDay?restData.estimatedEngineHoursPerDay:"");
                setEngineHoursPopup(restData.estimatedEngineHoursPerDay?restData.estimatedEngineHoursPerDay:"");
                setNumDayReposPopup(restData.noOfDaysAllowed?restData.noOfDaysAllowed:"");
                setPriceKmPopup(restData.distanceRate?restData.distanceRate:"");
                setEngineRatePopup(restData.engineRate?restData.engineRate:"");
                setEngineHoursUnitPopup(restData.estimatedEngineHoursPerDay?restData.estimatedEngineHoursPerDay:"");
                setDistanceToTravelPopup(restData.estimatedDistancePerDay?restData.estimatedDistancePerDay:"");

                setPolicyIdOffer(restData.policyName);
                setPerDayRateOffer(restData.insurancePerDayAmount);

                setOfferLPolicyId(restData.policyNameLiability);
                setOfferLPolicyIdRate(restData.insurancePerDayAmountLiability);
                setOfferPhyPolicyId(restData.policyNamePhysical);
                setOfferPhyPolicyRate(restData.insurancePerDayAmountPhysical);

                if(restData.offerStatus == "Withdrawn" || restData.offerStatus == "Approved" || restData.offerStatus == "Declined"){
                    $("#btnWithDrawn").hide();
                    $("#btnReject").hide();
                    $("#btnAccept").hide();
                }

                try{          
                    console.log(restData?.startDate || stOfferDate,restData?.endDate || edOfferDate);                            
                    const nDays = CommonUtil.getDays(restData?.startDate || stOfferDate,restData?.endDate || edOfferDate);
                    console.log(nDays);
                    
                    setOfferRightrateObj(getSelectedRangeRate(Number(nDays),Number(restData?.dailyRate||dailyRate),Number(restData?.weeklyRate||weeklyRate),Number(restData?.monthlyRate||monthlyRate)));
                }catch(e){
                    setOfferRightrateObj({type:Constants.DAY});
                }
            }
        }
    }

    let onDevOfferShowPopup = ()=>{
        setTimeout(function () {
            setPopupCenter('devOfferPopup', 'devOfferContentPopup');
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let onClickAccept = ()=>{
        if(response && response.equipmentReservationOfferId){
            let offerAPI:string = "api/offers/accept/"+response.equipmentReservationOfferId+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(offerAPI,"","","POST",true,onOfferAccept,onLoginError);
        }
    }

    let onOfferAccept = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                //ToastManager.showToast('Offer Accepted Successfully', 'Info');
                Confirm.show('Success',restData.status ? restData.status : "Offer Accepted Successfully", [{label: 'Close', callback: ()=>console.log("here in ok")}])
                handleClose();
            }
        }
    }

    let onClickReject = ()=>{
        if(response && response.equipmentReservationOfferId){
            let offerAPI:string = "api/offers/decline/"+response.equipmentReservationOfferId+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(offerAPI,"","","POST",true,onOfferReject,onLoginError);
        }
    }

    let onOfferReject = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast('Offer Rejected Successfully', 'Info');
                handleClose();
            }
        }
    }

    let onClickWithdraw = ()=>{
        if(response && response.equipmentReservationOfferId){
            let offerAPI:string = "api/offers/withdraw/"+response.equipmentReservationOfferId+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(offerAPI,"","","POST",true,onOfferWithdraw,onLoginError);
        }
    }

    let onOfferWithdraw = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast('Offer Withdraw Successfully', 'Info');
                handleClose();
            }
        }
    }

    let onLoginError = ()=>{

    }

    return(
        <>
            <Modal  show={modalShow} dialogAs={DraggableComponent} id="devOfferPopup" onShow={onDevOfferShowPopup}>
                <div className="modal-content" id="devOfferContentPopup" style={{"width": "800px","height":"650px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Offer Details</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginLeft:"0px"}} onClick={handleClose} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black" style={{overflow:"auto"}}>
                        <div className="row" style={{display:"none"}}>
                            <div className="col-md-12">
                                <p>Offer received from: <strong>{CommonUtil.getSelectedOrgName()}</strong></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 border-right" style={{paddingRight:"25px"}}>
                                <h6 className="text-center">Availability Details</h6>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Dates</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Starts at:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={stDate} readOnly/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Ends at:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={endDate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divNumOfDays"}>
                                            <label className="col-sm-12 col-form-label">Number of days allowed for
                                                repositioning</label>
                                            <div className="col-sm-12">
                                                <input type="text" disabled={true} className="form-control" required value={noofDays} />
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAvailEquips"}>
                                            <label className="col-sm-12 col-form-label">Number of available equipment for this period </label>
                                            <div className="col-sm-12">
                                                <input type="text" disabled={true} className="form-control" required value={quantity} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Location</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Departure Parking
                                                Lot</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required
                                                           value={departure} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Arrival Parking Lot</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={arrival} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",display:(isInsuranceEnable?"":"none")}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Insurance</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px",display:(isInsuranceEnable?"":"none")}}>
                                        <div className="form-group row" style={{display:(isLiabilityPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Liability PolicyId:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={lPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isLiabilityPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Per Day Rate:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={lPolicyIdRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isPhysicalPolicyPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Physical Damage PolicyId:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={phyPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isPhysicalPolicyPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Per Day Rate:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={phyPolicyRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Cost:</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.DAY ? '':'d-none'}`} id={"divDailyRate"}>
                                            <label className="col-sm-12 col-form-label">Daily Rate:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={dailyRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.WEEK ? '':'d-none'}`} id={"divWeeklyRate"}>
                                            <label className="col-sm-12 col-form-label">Weekly Rate:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={weeklyRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.MONTH ? '':'d-none'}`} id={"divMonthlyRate"}>
                                            <label className="col-sm-12 col-form-label">Monthly Rate:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={monthlyRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceRate"}>
                                            <label className="col-sm-12 col-form-label">Distance Rate:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={distanceRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHours"}>
                                            <label className="col-sm-12 col-form-label">Engine Hours:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={engineRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row" id={"divPaidPrice"}>
                                            <label className="col-sm-12 col-form-label">Paid price carrier for Repositioning:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={movePrice} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice"}>
                                            <label className="col-sm-12 col-form-label">Additional Price per Excess Days:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={addtionalPrice} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance"} style={{display:"none"}}>
                                            <label className="col-sm-12 col-form-label">Estimated distance in miles:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={eDisRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail"} style={{display:"none"}}>
                                            <label className="col-sm-12 col-form-label">Price per mile:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                            value={priceKm} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6 className="text-center">Offer Details</h6>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Dates</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className="form-group row input-highlighted">
                                            <label className="col-sm-12 col-form-label">Starts at:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={stOfferDate} readOnly
                                                           style={{backgroundColor:((stDate !=stOfferDate )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted">
                                            <label className="col-sm-12 col-form-label">Ends at:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={edOfferDate} readOnly
                                                           style={{backgroundColor:((endDate !=edOfferDate )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted" id={"divNumOfDays1"}>
                                            <label className="col-sm-12 col-form-label">Number of days allowed for repositioning</label>
                                            <div className="col-sm-12">
                                                <input type="text" className="form-control" required value={numDayReposPopup} readOnly
                                                       style={{backgroundColor:((noofDays !=numDayReposPopup )?"yellow":"")}}/>
                                            </div>

                                        </div>
                                        <div className="form-group row input-highlighted" id={"divAvailEquips1"}>
                                            <label className="col-sm-12 col-form-label">Number of Available equipment for this period</label>
                                            <div className="col-sm-12" >
                                                <input type="text" className="form-control" required value={qtyPopup} readOnly
                                                 style={{backgroundColor:((quantity !=qtyPopup )?"yellow":"")}}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Location</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Departure Parking
                                                Lot</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required
                                                           value={departure} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Arrival Parking Lot</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={arrival} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display:(isInsuranceEnable?"":"none")}}>
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Insurance</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px",display:(isInsuranceEnable?"":"none")}}>
                                        <div className="form-group row" style={{display:(isLiabilityPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Liability PolicyId:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={offerLPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isLiabilityPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Per Day Rate:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={offerLPolicyIdRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isPhysicalPolicyPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Physical Damage PolicyId:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={offerPhyPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display:(isPhysicalPolicyPaid?"":"none")}}>
                                            <label className="col-sm-12 col-form-label">Per Day Rate:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={offerPhyPolicyRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Cost</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.DAY ? '':'d-none'}`} id={"divDailyRate1"}>
                                            <label className="col-sm-12 col-form-label">Daily Rate:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={dailyPricePopup} readOnly
                                                           style={{backgroundColor:((dailyRate !=dailyPricePopup )?"yellow":"")}}/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.WEEK ? '':'d-none'}`} id={"divWeeklyRate1"}>
                                            <label className="col-sm-12 col-form-label">Weekly Rate:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={weeklyPricePopup} readOnly
                                                           style={{backgroundColor:((weeklyRate !=weeklyPricePopup )?"yellow":"")}}/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.MONTH ? '':'d-none'}`} id={"divMonthlyRate1"}>
                                            <label className="col-sm-12 col-form-label">Monthly Rate:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={monthlyPricePopup} readOnly
                                                           style={{backgroundColor:((monthlyRate !=monthlyPricePopup )?"yellow":"")}}/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted" id={"divDistanceRate1"}>
                                            <label className="col-sm-12 col-form-label">Distance Rate:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={disPricePopup} readOnly
                                                           style={{backgroundColor:((distanceRate !=disPricePopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row" id={"divEngineHours1"}>
                                            <label className="col-sm-12 col-form-label">Engine Hours:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={engineRatePopup} readOnly
                                                            style={{backgroundColor:((engineRate !=engineRatePopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row" id={"divPaidPrice1"}>
                                            <label className="col-sm-12 col-form-label">Paid price carrier for Repositioning:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={movePricePopup} readOnly
                                                           style={{backgroundColor:((movePrice !=movePricePopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice1"}>
                                            <label className="col-sm-12 col-form-label">Additional Price per Excess Days:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <input type="text" className="form-control" required value={additionalPricePopup} readOnly
                                                           style={{backgroundColor:((addtionalPrice !=additionalPricePopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance1"}>
                                            <label className="col-sm-12 col-form-label">Estimated distance in miles:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={estimatedDisPopup} readOnly
                                                            style={{backgroundColor:((estimatedDistance !=estimatedDisPopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail1"} style={{display:"none"}}>
                                            <label className="col-sm-12 col-form-label">Price per mile:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={pricePerKmPopup} readOnly
                                                            style={{backgroundColor:((priceKm !=pricePerKmPopup )?"yellow":"")}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceToTravel"} style={{display:"none"}}>
                                            <label className="col-sm-12 col-form-label" style={{paddingLeft:"5px"}}>Distance to travel:</label>
                                            <div className="col-sm-12" style={{paddingLeft:"5px"}}>
                                                <div><input ref={distanceToTravelRef} type="number" className="form-control" required min={0}
                                                            value={distanceToTravelPopup} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHoursUnit"} style={{display:"none"}}>
                                            <label className="col-sm-12 col-form-label" style={{paddingLeft:"5px"}}>Engine unit usage:</label>
                                            <div className="col-sm-12" style={{paddingLeft:"5px"}}>
                                                <div><input ref={engineHoursUnitRef} type="number" className="form-control" required min={0}
                                                            value={engineHoursUnitPopup} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        {
                            CommonUtil.SCREEN_FROM == CommonUtil.OFFER_RECEIVED? <div>
                                    <button type="button" id={"btnReject"} className="btn btn-warning waves-effect" data-dismiss="modal"   onClick={onClickReject}>Reject</button> &nbsp;
                                <button type="button" id={"btnAccept"} className="btn btn-primary waves-effect" data-dismiss="modal" onClick={onClickAccept}>Accept</button></div>:
                                <button type="button" id={"btnWithDrawn"} className="btn btn-warning waves-effect" data-dismiss="modal"   onClick={onClickWithdraw}>Withdraw</button>
                        }

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default OfferCreate;
