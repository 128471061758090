export class MapData {
    engine: string = "welcome";


    //constructor
    constructor() {
        //this.engine = engine
    }

    //function
    disp(): void {
        console.log("Engine is  :   " + this.engine)
    }

    static getMapData(): object[] {
        return [{"latitude": 52.373247, "longitude": 9.721119},
            {"latitude": 52.37413, "longitude": 9.727832,},
            {"latitude": 52.37191, "longitude": 9.723369,}
        ];
    }
}