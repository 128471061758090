import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../../Components/DraggableComponent";
import { CommonUtil } from "../../../utils/CommonUtil";
import { Constants } from "../../Languages/Constants";
import SingleSelectComp from "../../../Components/SingleSelectComp";
import CDTextarea from "../../Equipments/CDTextarea";
import { NetworkManager } from "../../../Components/NetworkManager";
import ToastManager from "../../../Components/ToastManager";
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from "../../../Components/IInterfaces";

const ResponseClaimRenderer = (props: any, getAllClaims: any) => {
  

    const rdescRef = useRef<HTMLTextAreaElement>(null);
    const [disLength] = useState(1000);
    const [statusObj, setStatusObj] = useState<any>(null);
    const [openRespClaim, setOpenRespClaim] = useState(false);
    const [rdescription, rRetDescription] = useState("");


    const [claimStatusClass, setClaimStatusClass] = useState(
        CommonUtil.DROPDOWN_MANDATORY_CLASS
      );

  const onClickSettleClaim = () => {
    CommonUtil.setClaimId(props?.data?.claimId)
    console.log(props);
    
        setOpenRespClaim(true)
    }
    const onCloseResponsePopup = () => {
        setOpenRespClaim(false)
    }
    let onChangeStatus = (data: any) => {
        setStatusObj(data);
        data
          ? setClaimStatusClass("select-box-valid")
          : setClaimStatusClass("select-box-invalid");
  };
  
  const onClickSubmitResponse = () => {
    console.log("SUN");
    let restObj: any = {};
    restObj.claimId = props?.data?.claimId;
    restObj.reservationCode = "";
    restObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
    restObj.responseMessage = rdescription;
    restObj.responseStatus = statusObj ? statusObj.key : "";
    restObj.saasOrgId = CommonUtil.getSaasOrgId();

    let method: string = "POST";
    let claimAPI: string = `api/claim/message/?isForInterchange=true`;

    NetworkManager.sendJsonRequest(claimAPI,restObj, "",method, true,onSaveResponse,onLoginError );
  }
  let onSaveResponse = (dataObj:any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        setOpenRespClaim(false)
         ToastManager.showToast("Response to claims are added successfully", "Info") 
          console.log(restData);
          getAllClaims();
      }
    }
  }
 
let onLoginError = (err: any) => {
  console.log(err);
};
    return (
        <div>
            <div className={"cellRenderHeader"}>
            <button className="btn btn-danger" onClick={onClickSettleClaim}>{"Settle"}</button>
            </div>

            {/* Response To Claim Popup */}
            <Modal show={openRespClaim} dialogAs={DraggableComponent}>
        <div
          className="modal-content"
          style={{ width: "500px", height: "500px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {CommonUtil.getMessageText(
                Constants.RESPOND_TO_CLAIM,
                "Respond to Claim"
              )}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginLeft: "0px" }}
              onClick={onCloseResponsePopup}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "auto" }}>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.STATUS, "Status")}
              </label>
              <SingleSelectComp
                dataSource={CommonUtil.CLAIM_STATUES}
                selectedValue={statusObj}
                handleChange={onChangeStatus}
                width={"100%"}
                value={"key"}
                label={"value"}
                className={claimStatusClass}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.DESCRIPTION,
                  "Description"
                )}
              </label>
              <CDTextarea
                maxLength={disLength}
                ref={rdescRef}
                value={rdescription}
                placeholder={CommonUtil.getMessageText(
                  Constants.ENTER_DESCRIPTION,
                  "Enter Description"
                )}
                rows={4}
                onChange={(e: any) => {
                  rRetDescription(e.currentTarget.value);
                  CommonUtil.isMandatory(rdescRef);
                }}
              />
            </div>

            <label style={{ display: "none" }}>Add Pictures</label>
            {/* <div
              style={{ display: "none" }}
              className="boxed"
              onClick={() => {
                onClickDocBrowse(CommonUtil.EQUIP_DOCS);
              }}
            >
              <div className="row">
                <div className="col-sm-12 col-12 text-center">
                  <form action="" className="dropzone">
                    <img
                      src="../../images/Documents-Upload.png"
                      className="mb-3"
                    />
                    <p className="text-center">
                      <strong>
                        Drag & Drop your Photos here or{" "}
                        <a href="#">Browse Photos</a>
                      </strong>
                    </p>
                    <div className="fallback">
                      <input
                        name="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={onCloseResponsePopup}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>
            {/* {claimMessageItem ? (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  onClickDeleteClaimMessage();
                }}
              >
                {CommonUtil.getMessageText(Constants.DELETE, "Delete")}
              </button>
            ) : (
              ""
            )} */}
            <button
              type="submit"
              className="btn btn-success"
              onClick={onClickSubmitResponse}
            >
              {CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}
            </button>
          </div>
        </div>
      </Modal>
            </div>

    )
}

export default ResponseClaimRenderer;