import React, {useEffect, useRef, useState} from "react";
import $ from "jquery";
import '../../dashboard.css'
import {loadMapApi} from "../../utils/GoogleMapsUtils";
import {NetworkManager} from "../../Components/NetworkManager";
import {CommonUtil} from "../../utils/CommonUtil";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import Flatpickr from "react-flatpickr";
import SearchDBCellRenderer from "./SearchDBCellRenderer";
import AGGrid from "../../Components/AGGrid";
import {IAGrid} from "../../Components/IInterfaces";
import {Constants} from "../Languages/Constants";
import BusyLoader from "../../Components/BusyLoader";
import StarRating from "../../Components/Rating";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import ToastManager from "../../Components/ToastManager";
import {Events} from "../Languages/Events";
import OfferCreateRenderer from "../Organization/OfferCreateRenderer";
import LoginHeader from "../Login/LoginHeader";
import RegistrationHeader from "../Login/RegistrationHeader";

var departureAddress:string = "";
var departureLatitude:any = "";
var departureLongitude:any = "";
var searchType = "Reposition";

var REPOSITION = "Reposition";
var RENTAL = "Rental";

var gMap:any = null;
var gMap1:any = null;
var gMap2:any = null;
var markers:any = [];
let renderer:any = null;
let marker:any = null;
var markerCluster:any = null;

var fromAddress:string = "";
var fromLatitude:any = "";
var fromLongitude:any = "";

var toAddress:string = "";
var toLatitude:any = "";
var toLongitude:any = "";

var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};
var copySearchData:any = [];
let filterData:any = [];
var equiptyes:any = [];
let selEquipTypes:any = [];

let date1:any = "";
let date2:any = "";
var newMatchesChecked:boolean = false;
var searchTextHere:string = "";

var rowExist:boolean = false;
var selectedTileId = "";
var currentTileId = "";

var latlngbounds:any = null;
var myLatlng:any = null;
var Is_FILTER:boolean = false;

var equipmentTypeFilter = false;
var modelYearFilter = false;
var axlesCountFilter = false;



var matchCount = 0;
var bestMatchCount = 0;

var defLat:number = 43.87066544557103;
var defLng:number = -80.63276494904989;
var availabilityId:string = "";
var isGenericEquip:string = "";
var availabilityData:any = null;

var infowindowEquipment:any = null;

var points:any = [];
var repPoints:any = [];

var nPoints:any = [];
var repNPoints:any = [];
var nMarkers:any = [];
var repNMarkers:any = [];

let zooms:any = [];//{zoom:1,dist:5000000},{zoom:2,dist:3000000},{zoom:3,dist:900000},{zoom:4,dist:500000},{zoom:8,dist:20000},{zoom:6,dist:30000}];

const SearchDashboardNew = ()=>{
    const { isMobile } = DetectDevice();
    const gridRef = useRef<IAGrid>(null);
    const gridRef1 = useRef<IAGrid>(null);

    const mapRef = useRef<HTMLDivElement>(null);
    const mapRef1 = useRef<HTMLDivElement>(null);
    const mapRef2 = useRef<HTMLDivElement>(null);
    const mapRef3 = useRef<HTMLDivElement>(null);

    const [showOfferPopup,setShowOfferPopup] = useState(false);
    const [isPublicSearch,setPublicSearch] = useState(false);

    const [repositionCount,setRepositionCount] = useState(0);
    const [renterCount,setRenterCount] = useState(0);

    const [repositionBestCount,setRepositionBestCount] = useState(0);
    const [renterBestCount,setRenterBestCount] = useState(0);

    const [allowedDeviation,setAllowedDeviation] = useState("100");

    const [totalDriveTime, setTotalDriveTime] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);

    const fromLocRef = useRef<HTMLInputElement>(null);
    const toLocRef = useRef<HTMLInputElement>(null);

    const fromLocation1Ref = useRef<HTMLInputElement>(null);
    const toLocation1Ref = useRef<HTMLInputElement>(null);

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);
    const [stDate,setStDate] = useState("");
    const [endDate,setEndDate] = useState("");
    const [fromCity,setFromCity] = useState("");
    const [toCity,setToCity] = useState("");
    const [dateOptions,setDateOptions] = useState(dateformat);//CommonUtil.AVAIL_DATE_FORMAT);

    const [owner,setOwner] = useState("");
    const [renter,setRenter] = useState("");
    const [cad,setCAD] = useState("");
    const [percent,setPercent] = useState("");
    const [claims,setClaims] = useState("");

    const [searchData,setSearchData] = useState<any>([]);

    const stDateRef1 = useRef<Flatpickr>(null);
    const endDateRef1 = useRef<Flatpickr>(null);

    const stDateRentalRef = useRef<Flatpickr>(null);
    const endDateRentalRef = useRef<Flatpickr>(null);

    const stDateRentalRef1 = useRef<Flatpickr>(null);
    const endDateRentalRef1 = useRef<Flatpickr>(null);

    const stDateRentRef1 = useRef<Flatpickr>(null);
    const endDateRentRef1 = useRef<Flatpickr>(null);

    const [stRentalDate,setStRentalDate] = useState("");
    const [endRentalDate,setEndRentalDate] = useState("");
    const [dbSearchType,setDBSearchType] = useState("");
    const [matchesCount,setMatchesCount] = useState("");

    const equipDropDown = useRef<HTMLDivElement>(null);
    const equipModalYear = useRef<HTMLDivElement>(null);
    const equipAxies = useRef<HTMLDivElement>(null);
    const equipLength = useRef<HTMLDivElement>(null);

    const chkSelectAll = useRef<HTMLInputElement>(null);
    const chkDryVan = useRef<HTMLInputElement>(null);
    const chkLog = useRef<HTMLInputElement>(null);
    const chkDropDeck = useRef<HTMLInputElement>(null);
    const chkFlatBed = useRef<HTMLInputElement>(null);
    const chkReeferVan = useRef<HTMLInputElement>(null);
    const chkStorageVan = useRef<HTMLInputElement>(null);
    const chkPlateDryVan = useRef<HTMLInputElement>(null);
    const chkHeatedVan = useRef<HTMLInputElement>(null);
    const chkScrapMetal = useRef<HTMLInputElement>(null);
    const chkRecycling = useRef<HTMLInputElement>(null);
    const chkChip = useRef<HTMLInputElement>(null);
    const chkDump = useRef<HTMLInputElement>(null);
    const chkLowbed = useRef<HTMLInputElement>(null);
    const chkChassis = useRef<HTMLInputElement>(null);
    const chkTank = useRef<HTMLInputElement>(null);
    const chkTankCrude = useRef<HTMLInputElement>(null);
    const chkTankDryBulk = useRef<HTMLInputElement>(null);
    const chkTankPetroleum = useRef<HTMLInputElement>(null);
    const chkTankPropane = useRef<HTMLInputElement>(null);
    const chkTankChemical = useRef<HTMLInputElement>(null);
    const chkOther = useRef<HTMLInputElement>(null);
    const chkSpotter = useRef<HTMLInputElement>(null);

    const minRef = useRef<HTMLInputElement>(null);
    const maxRef = useRef<HTMLInputElement>(null);

    const minAxies = useRef<HTMLInputElement>(null);
    const maxAxies = useRef<HTMLInputElement>(null);

    const [availType,setAVailType] = useState("");
    const [availStDate,seAvailtStDate] = useState("");
    const [availEndDate,setAvailEndDate] = useState("");
    const [stDate1,setStDate1] = useState("");
    const [endDate1,setEndDate1] = useState("");
    const [arrival,setArrival] = useState("");
    const [departure,setDeparture] = useState("");
    const [dailyRate,setDailyRate] = useState("");
    const [distanceRate,setDistanceRate] = useState("");
    const [distanceUnit,setDistanceUnit] = useState("km");
    const [engineRate,setEngineRate] = useState("");
    const [quantity,setQuantity] = useState("");
    const [dateString,setDateString] = useState("");
    const [allowOffer,setAllowOffer] = useState("");
    const [movePrice,setMovePrice] = useState("");
    const [noofDays,setNoOfDays] = useState("");
    const [paidPrice,setPaidPrice] = useState("");
    const [eDisRate,setEDisRate] = useState("");
    const [priceKm,setPriceKm] = useState("");
    const [addtionalPrice,setAdditionalPrice] = useState("");

    const stDateRef2 = useRef<Flatpickr>(null);
    const edDateRef2 = useRef<Flatpickr>(null);

    const numDayReposRef = useRef<HTMLInputElement>(null);
    const [numDayReposPopup,setNumDayReposPopup] = useState("");

    const paidPricePopupRef = useRef<HTMLInputElement>(null);
    const [paidPricePopup,setPaidPricePopup] = useState("");

    const additionalPricePopupRef = useRef<HTMLInputElement>(null);
    const [additionalPricePopup,setAdditionalPricePopup] = useState("");

    const estimatedDisPopupRef = useRef<HTMLInputElement>(null);
    const [estimatedDisPopup,setEstimatedDistancePopup] = useState("");

    const pricePerMilePopupRef = useRef<HTMLInputElement>(null);
    const [pricePerMilePopup,setPricePerMilePopup] = useState("");

    const [stDate2,setStDate2] = useState("");
    const [endDate2,setEndDate2] = useState("");

    const numDayRef = useRef<HTMLInputElement>(null);
    const dailyRateRef = useRef<HTMLInputElement>(null);
    const distanceRef = useRef<HTMLInputElement>(null);
    const engineRatePopupRef = useRef<HTMLInputElement>(null);
    const distanceToTravelRef = useRef<HTMLInputElement>(null);
    const engineHoursUnitRef = useRef<HTMLInputElement>(null);

    const [noOfDaysPopup,setNoOfDaysPopup] = useState("");
    const [dailyPricePopup,setDailyPricePopup] = useState("");
    const [disPricePopup,setDisPricePopup] = useState("");

    const [engineRatePopup,setEngineHoursPopup] = useState("");

    const [distanceToTravelPopup,setDistanceToTravelPopup] = useState("140");
    const [engineHoursUnitPopup,setEngineHoursUnitPopup] = useState("4");

    const [searchColumns] = useState([
        {field: 'BooK', headerName:  CommonUtil.getMessageText(Constants.BOOK_NOW,"Book Now"), resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter',cellRenderer: SearchDBCellRenderer},
        {field: 'Offer', headerName: CommonUtil.getMessageText(Constants.CREATE_OFFER,"Offer"), resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter',cellRenderer: OfferCreateRenderer},
        {field: 'departureDate', headerName: CommonUtil.getMessageText(Constants.DEPARTURE_DATE,"Departure Date"), tooltipField: 'departureDate',resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'bobtailToPuDistanceKM', headerName: CommonUtil.getMessageText(Constants.BOBTAIL_TO_PU,"Bobtail to Pu (KM)"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'noOfDaysAllowed', headerName: CommonUtil.getMessageText(Constants.DAYS_ALLOWED_REP,"Days Allowed for Reposition"), tooltipField: 'arrivalDate',resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'movePrice', headerName: CommonUtil.getMessageText(Constants.REVENUE,"Revenue"), tooltipField: 'movePrice',resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter'},
        {field: 'revenuePerHour', headerName: CommonUtil.getMessageText(Constants.REVENUE_PER_HOUR,"Rev Per Hour"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'hookDistanceKM', headerName: CommonUtil.getMessageText(Constants.HOOK_KM,"Hook (KM)"),resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'departureAddress', headerName: CommonUtil.getMessageText(Constants.DEPARTURE_ADDR,"Departure Address"), tooltipField: 'departureAddress',resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'dropToTargetDistanceKM', headerName: CommonUtil.getMessageText(Constants.DROP_TO_TARGET_KM,"Drop To Target (KM)"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'totalTravelTime', headerName: CommonUtil.getMessageText(Constants.EST_DRIVE_HRS,"Est. Drive Hours"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'arrivalDate', headerName: CommonUtil.getMessageText(Constants.ARRIVAL_DATE_DB,"Arrival Date"), tooltipField: 'arrivalDate',resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'arrivalAddress', headerName: CommonUtil.getMessageText(Constants.ARRIVAL_ADDRESS,"Arrival Address"),tooltipField: 'arrivalAddress', resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'totalDistanceKM', headerName: CommonUtil.getMessageText(Constants.TOTAL_DISTANCE_KM,"Total Distance (KM)"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), tooltipField: 'equipmentType',resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"), tooltipField: 'unitNumber',resizable: true, width: 250, sortable: true,  filter: 'agTextColumnFilter'},
        {field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN,"VIN"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'isGeneric', headerName: 'Generic/Specific', tooltipField: 'isGeneric',resizable: true, width: 200, sortable: true,  filter: 'agTextColumnFilter'},
    ]);

    const [rentersearchColumns] = useState([
        {field: 'BooK', headerName: 'BooK', resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter',cellRenderer: SearchDBCellRenderer},
        {field: 'Offer', headerName: 'Offer', resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter',cellRenderer: OfferCreateRenderer},
        {field: 'dailyRate', headerName: 'Daily Rate', resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter'},
        {field: 'distanceRate', headerName: 'Distance Rate', resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'engineRate', headerName: 'Engine Rate', resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'departureAddress', headerName: 'Departure Location',tooltipField: 'departureAddress',resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'arrivalAddress', headerName: 'Arrival Location',tooltipField: 'arrivalAddress', resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'securityDeposit', headerName: 'Security Deposit', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'rating', headerName: 'Ratings', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: 'Brand', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'equipmentType', headerName: 'Equipment Type', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},

    ]);


    let deselectTabs = ()=>{
        $("#RepositioningTab").removeClass("active");
        $("#RentalTab").removeClass("active");

        $("#lnkReposition").removeClass("active");
        $("#lnkRental").removeClass("active");
    }
    let onClickRepositionTab = ()=>{
        searchType = REPOSITION;
        setDBSearchType(searchType);
        deselectTabs();
        $("#lnkReposition").addClass("active");
        $("#RepositioningTab").addClass("active");
    }
    let onClickRenterTab = ()=>{
        searchType = RENTAL;
        setDBSearchType(searchType);
        deselectTabs();
        $("#lnkRental").addClass("active");
        $("#RentalTab").addClass("active");
    }
    useEffect(() => {
        if(localStorage.getItem("PublicSearch") && localStorage.getItem("PublicSearch") == "true"){
            setPublicSearch(true);
        }
        setTimeout(()=>{
            localStorage.setItem("PublicSearch","false");
        },1000);

        Is_FILTER = false;
        fromLatitude = "";
        fromLongitude = "";
        toLongitude = "";
        toLatitude = "";
        fromAddress = "";
        toAddress = "";
        equiptyes = [chkDryVan,chkLog,chkDropDeck,chkFlatBed,chkReeferVan,chkStorageVan,chkPlateDryVan,chkHeatedVan,chkScrapMetal,
            chkRecycling,chkChip,chkDump,chkLowbed,chkChassis,chkTank,chkTankCrude,chkTankDryBulk,chkTankPetroleum,
            chkTankPropane,chkTankChemical,chkOther,chkSpotter];

            searchType = REPOSITION;
            setDBSearchType(searchType);

            document.addEventListener('mousedown', handleClickOutside);

            //setSearchDBType(searchType);
            if (window.google) {
                loadGoogleMap();
            } else {
                const googleMapScript = loadMapApi();
                if (googleMapScript) {
                    googleMapScript.addEventListener('load', function () {
                        loadGoogleMap();
                    });
                }
            }
            function onClickBook(){
                console.log("ajsjdj");
            }
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                // Unbind the event listener on clean up
                //window.removeEventListener('resize', onWindowResize);
            }
        }, []);


    let showAvailableCount = (cnt?:number):any=>{
        var svgIcon:any = {};
        var template = [
            '<?xml version="1.0"?>',
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">'+
            '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>'+
            '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>'+
            '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>'+
            '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>'+
            '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>'+
            '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
            '<text x="15" y="25" fill="white" font-weight="bold">'+(cnt?cnt:"")+'</text></g></g>'+
            '</svg>'
        ].join('\n');

        if(cnt && cnt.toString().length>1){
            template = [
                '<?xml version="1.0"?>',
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">'+
                '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>'+
                '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>'+
                '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>'+
                '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>'+
                '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>'+
                '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
                '<text x="10" y="25" fill="white" font-weight="bold">'+(cnt?cnt:"")+'</text></g></g>'+
                '</svg>'
            ].join('\n');
        }

        svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(template);
        svgIcon.scaledSize = new google.maps.Size(50, 50);
        return svgIcon;
    }
    let loadGoogleMap = () => {
        //console.log(encodeURIComponent(template));


        //svgIcon.anchor = new google.maps.Point(16, 16);

        if(stDateRef.current){
            stDateRef.current.flatpickr.setDate(new Date());
            let strDate:string = CommonUtil.getReservationDateString(new Date());
            if(stDateRef1.current){
                stDateRef1.current.flatpickr.setDate(new Date());
            }
            if(stDateRentalRef.current){
                stDateRentalRef.current.flatpickr.setDate(new Date());
            }
            if(stDateRentalRef1.current){
                stDateRentalRef1.current.flatpickr.setDate(new Date());
            }
            date1 = strDate;
            setStRentalDate(strDate);
            setStDate(strDate);
        }
        if(endDateRef.current){
            let currTime:number = new Date().getTime();
            currTime = currTime+(24*60*60*1000);
            endDateRef.current.flatpickr.setDate(new Date(currTime));
            let strDate:string = CommonUtil.getReservationDateString(new Date(currTime));
            if(endDateRef1.current){
                endDateRef1.current.flatpickr.setDate(new Date(currTime));
            }
            if(endDateRentalRef.current){
                endDateRentalRef.current.flatpickr.setDate(new Date(currTime));
            }
            if(endDateRentalRef1.current){
                endDateRentalRef1.current.flatpickr.setDate(new Date(currTime));
            }
            date2 = strDate;
            setEndDate(strDate);
            setEndRentalDate(strDate);
        }

       setTimeout(()=>{
            getGooglePlaces();
            /*if(chkSelectAll.current){
                chkSelectAll.current.checked = true;
            }*/

        });

        if (mapRef.current) {
            gMap = new google.maps.Map(mapRef.current, {
                zoom: 15, center: new google.maps.LatLng(defLat, defLng),
                gestureHandling: 'greedy', streetViewControl: true, zoomControl: true, fullscreenControl: true,
                draggableCursor: 'pointer',
                fullscreenControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
                mapTypeControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
            });
            if(mapRef2.current){
                gMap2 = new google.maps.Map(mapRef2.current, {
                    zoom: 15, center: new google.maps.LatLng(defLat, defLng),
                    gestureHandling: 'greedy', streetViewControl: true, zoomControl: true, fullscreenControl: true,
                    draggableCursor: 'pointer',
                    fullscreenControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
                    mapTypeControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
                });
            }

            //console.log(gMap);
            if (gMap) {
               getRepositionSummary();
               gMap.addListener("idle", () => {
                    //google.maps.event.clearInstanceListeners(gMap);
                    setMapStyle();
                });
            }
        }
    }
    let getGooglePlaces = () => {
        const address = document.getElementById("fromLocation") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(address,(addr:any)=>{
            //console.log(result);
            fromAddress = addr['rawAddress'];
            fromLatitude = addr['lat'];
            fromLongitude = addr['lng'];
            setFromCity(addr['city']);

            $("#fromLocation1").val(fromAddress);
        });

        const toLocation = document.getElementById('toLocation') as HTMLInputElement
        CommonUtil.getPlaceAddressByMap(toLocation,(addr:any)=>{
            //console.log(result);
            toAddress = addr['rawAddress'];
            toLatitude = addr['lat'];
            toLongitude = addr['lng'];
            setToCity(addr['city']);

            $("#toLocation1").val(toAddress);
        });

        const fromLocation1 = document.getElementById("fromLocation1") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(fromLocation1,(addr:any)=>{
            //console.log(result);
            fromAddress = addr['rawAddress'];
            fromLatitude = addr['lat'];
            fromLongitude = addr['lng'];
            setFromCity(addr['city']);

            $("#fromLocation1").val(fromAddress);
        });

        const toLocation1 = document.getElementById('toLocation1') as HTMLInputElement
        CommonUtil.getPlaceAddressByMap(toLocation1,(addr:any)=>{
            //console.log(result);
            toAddress = addr['rawAddress'];
            toLatitude = addr['lat'];
            toLongitude = addr['lng'];
            setToCity(addr['city']);

            $("#fromLocation1").val(fromAddress);
        });

        const departureLocation = document.getElementById('departureLocation') as HTMLInputElement
        CommonUtil.getPlaceAddressByMap(departureLocation,(addr:any)=>{
            //console.log(result);
            toAddress = addr['rawAddress'];
            toLatitude = addr['lat'];
            toLongitude = addr['lng'];

            setFromCity(addr['city']);

            $("#departureLocation1").val(toAddress);
        });
        const departureLocation1 = document.getElementById('departureLocation1') as HTMLInputElement
        CommonUtil.getPlaceAddressByMap(departureLocation1,(addr:any)=>{
            //console.log(result);
            toAddress = addr['rawAddress'];
            toLatitude = addr['lat'];
            toLongitude = addr['lng'];

            setFromCity(addr['city']);
            $("#fromLocation1").val(fromAddress);
        });

    }
    let setMapStyle = () => {
        var styles = {
            default: [],
            minimum: [
                {
                    featureType: "poi", elementType: "labels.text",
                    stylers: [{visibility: "off"}]
                },
                {
                    featureType: "poi.business",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "transit",
                    stylers: [{visibility: "off"}],
                },
            ],
        };
        if (gMap) {
            gMap.setOptions({styles: styles["minimum"]});
        }
    }
    let getRepositionSummary = ()=>{
        clearConsolidatedMarkers();
        let reqObj:any = {};
        reqObj.allowedDeviation = allowedDeviation;
        reqObj.dropLocationNearToPickUpLocation = true;
        reqObj.searchType = REPOSITION;
        var summaryAPI:string = "api/session/search/public/summary/";//+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(summaryAPI,reqObj,"","POST",true,onGetSummary,onLoginError);
    }
    let onGetSummary = (dataObj:any)=> {
        //console.log(dataObj);
        //clearGoogleMarkers();
        let records:any = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    records = restData
                } else {
                    records.push(restData)
                }
            }
        }
        let count = 0;//records.length;
        for (let j = 0; j < records.length; j++) {
            count = count+Number(records[j].availableQuantity?records[j].availableQuantity:1);
        }
        setRepositionCount(count);
        //getRentalSummary();

        latlngbounds = new google.maps.LatLngBounds();
         myLatlng = null;
        markers = [];

        markerCluster = new MarkerClusterer({});
       // markerCluster.setMap(gMap);

        var iconColor = "#FF3B30";


        //var svg = template.replace('{{background}}', iconColor);

        let gIcon:any = null;//showAvailableCount();
        points = [];

         nPoints = [];
         nMarkers = [];
        for (let i = 0; i < records.length; i++) {
            /*console.log(records[i].departureLatitude+","+records[i].departureLongitude);
            gIcon = showAvailableCount(records[i].availableQuantity?records[i].availableQuantity:"1");
            myLatlng = new google.maps.LatLng(records[i].departureLatitude, records[i].departureLongitude);
            let marker = new google.maps.Marker({position: myLatlng, map: gMap, title:records[i].departureAddress});
            let marker1 = new google.maps.Marker({position: myLatlng, map: gMap2, title:records[i].departureAddress,icon:gIcon});
            markers.push(marker);*/
            myLatlng = new google.maps.LatLng(records[i].departureLatitude, records[i].departureLongitude);
            records[i].consolidate = false;
            records[i].lat =  records[i].departureLatitude;
            records[i].lng =  records[i].departureLongitude;
            records[i].availCount =  records[i].availableQuantity?records[i].availableQuantity:1;
            points.push(records[i]);
            latlngbounds.extend(myLatlng);
            // markerCluster.addMarker(marker);
        }

        getRentalSummary();


        /*points.push({availabilityId:"1",lat:37.7744438,lng:-122.3886096,availCount:1,count:0,consolidate:false});
        points.push({availabilityId:"2",lat:46.8212619,lng:-71.25662849999999,availCount:2,count:0,consolidate:false});
        points.push({availabilityId:"3",lat:52.9399159,lng:-106.4508639,availCount:3,count:0,consolidate:false});
        points.push({availabilityId:"4",lat:44.6743465,lng:-63.6783819,availCount:1,count:0,consolidate:false});
        points.push({availabilityId:"5",lat:50.4131692,lng:-73.8659237,availCount:5,count:0,consolidate:false});
        points.push({availabilityId:"6",lat:35.4367163,lng:-97.52169289999999,availCount:5,count:0,consolidate:false});
        points.push({availabilityId:"6",lat:41.8874455,lng:-87.6216614,availCount:5,count:0,consolidate:false});


        let point1 = new google.maps.LatLng(37.7744438, -122.3886096);
        let point2 = new google.maps.LatLng(46.8212619, -71.25662849999999);
        let point3 = new google.maps.LatLng(52.9399159, -106.4508639);
        let point4 = new google.maps.LatLng(44.6743465, -63.6783819);
        let point5 = new google.maps.LatLng(50.4131692, -73.8659237);
        let point6 = new google.maps.LatLng(35.4367163, -97.52169289999999);
        let point7 = new google.maps.LatLng(41.8874455, -87.6216614);

        //google.maps.geometry.spherical.computeDistanceBetween(point1,point2);

       // gIcon = showAvailableCount(1);


       // let marker1 = new google.maps.Marker({position: point1, map: gMap2,icon:gIcon});
        //let marker2 = new google.maps.Marker({position: point2, map: gMap2,icon:gIcon});

        latlngbounds.extend(point1);
        latlngbounds.extend(point2);
        latlngbounds.extend(point3);
        latlngbounds.extend(point4);
        latlngbounds.extend(point5);
        latlngbounds.extend(point6);
        latlngbounds.extend(point7);*/

        /*if (latlngbounds && records.length > 0) {
            if (gMap) {
                gMap.fitBounds(latlngbounds);
            }
            if (gMap2) {
                gMap2.fitBounds(latlngbounds);
            }
        }*/

        let dist = 0;
        //let distMeters = 300000;
       // let distMeters1 = 3000000;
        //let distMeters2 = 4300000;
       // let distMeters4 = 650000;





       // markerCluster.addMarkers(markers);
        /*if (latlngbounds && records.length > 0) {
            if (gMap) {
                //google.maps.event.clearInstanceListeners(gMap1);
                gMap.fitBounds(latlngbounds);
            }
        }*/
    }
    let getRentalSummary = ()=>{
        let reqObj:any = {};
        reqObj.allowedDeviation = allowedDeviation;
        reqObj.dropLocationNearToPickUpLocation = true;
        reqObj.searchType = RENTAL;
        var summaryAPI:string = "api/session/search/public/summary/";//+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(summaryAPI,reqObj,"","POST",true,onGetRentalSummary,onLoginError);
    }
    let onGetRentalSummary = (dataObj:any)=> {
        //console.log(dataObj);
        //clearGoogleMarkers();
        let records:any = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    records = restData
                } else {
                    records.push(restData)
                }
            }
        }
        //let count = records.length;
        let count1 = 0;//records.length;
        for (let k = 0; k < records.length; k++) {
           // console.log(records[k].availableQuantity);
            count1 = count1+Number(records[k].availableQuantity?records[k].availableQuantity:1);
        }
        setRenterCount(count1);

        let gIcon:any = null;//showAvailableCount();
        //points = [];
       // records = [];
        //records[0] = {"availabilityId":122,"equipmentId":42,"equipmentType":"REEFER VAN","genericEquipmentName":"","unitNumber":"DVLSpecificUnit103","vin":"DVLSpecificVIN103","departureAddress":"74 Mission Rock St, San Francisco, CA 94158, USA","departureLatitude":37.7744438,"departureLongitude":-122.3886096};
       // records[1] = {"availabilityId":141,"availableQuantity":3,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"421 Rue Soumande, Québec, QC G1M 2X6, Canada","departureLatitude":46.8212619,"departureLongitude":-71.25662849999999};
       // records[2] = {"availabilityId":146,"availableQuantity":1,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"Saskatchewan, Canada","departureLatitude":52.9399159,"departureLongitude":-106.4508639};
       // records[3] = {"availabilityId":147,"availableQuantity":44,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"NS-102, Halifax, NS, Canada","departureLatitude":44.6743465,"departureLongitude":-63.6783819};
       // records[4] = {"availabilityId":248,"equipmentId":167,"equipmentType":"PLATE DRY VAN","genericEquipmentName":"","unitNumber":"AvailabilityTest2","vin":"AvailabilityVIn2","departureAddress":"302 Queen St, Mistissini, QC G0W 1C0, Canada","departureLatitude":50.4131692,"departureLongitude":-73.8659237};
        //records[5] = {"availabilityId":296,"availableQuantity":1,"equipmentId":204,"equipmentType":"DROPDECK","genericEquipmentName":"Generic2","departureAddress":"2813 S Walker Ave, Oklahoma City, OK 73109, USA","departureLatitude":35.4367163,"departureLongitude":-97.52169289999999};
        //records[6] = {"availabilityId":329,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"151 E Wacker Dr, Chicago, IL 60601, USA","departureLatitude":41.8874455,"departureLongitude":-87.6216614};
        //records[7] = {"availabilityId":331,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"808 Howell St, Seattle, WA 98101, USA","departureLatitude":47.61438589999999,"departureLongitude":-122.3340182};
        //records[8] = {"availabilityId":359,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"808 Howell St, Seattle, WA 98101, USA","departureLatitude":47.61438589999999,"departureLongitude":-122.3340182};
       // records[9] = {"availabilityId":450,"availableQuantity":4,"equipmentId":5836,"equipmentType":"DRY VAN","genericEquipmentName":"abecdd","departureAddress":"wisgsxgshgeuehb","departureLatitude":43.6282065,"departureLongitude":-79.41548};
        //records[10] = {"availabilityId":545,"availableQuantity":3,"equipmentId":5889,"equipmentType":"DROPDECK","genericEquipmentName":"Truck 450","departureAddress":"1000 Palladium Dr, Ottawa, ON K2V 1A5, Canada","departureLatitude":45.2969151,"departureLongitude":-75.926823};
        //records[11] = {"availabilityId":569,"equipmentId":5890,"equipmentType":"TANK CHEMICAL","genericEquipmentName":"","unitNumber":"Truck1","vin":"Truck143434344444","departureAddress":"Hyderabad, Telangana, India","departureLatitude":17.385044,"departureLongitude":78.486671};
       // records[1] = {"availabilityId":590,"equipmentId":5912,"equipmentType":"DRY VAN","genericEquipmentName":"","unitNumber":"spec_27_01","vin":"spec_27_01","departureAddress":"San Jose, CA, USA","departureLatitude":37.33874,"departureLongitude":-121.8852525};
       // records[2] = {"availabilityId":622,"availableQuantity":1,"equipmentId":5925,"equipmentType":"DRY VAN","unitNumber":"1233445","vin":"GFGDF234","departureAddress":"San Jose, CA, USA","departureLatitude":37.33874,"departureLongitude":-121.8852525};

        /*for (let j = 0; j < records.length; j++) {
           // gIcon = showAvailableCount(records[j].availableQuantity?records[j].availableQuantity:"1");
            myLatlng = new google.maps.LatLng(records[j].departureLatitude, records[j].departureLongitude);
            //let marker = new google.maps.Marker({position: myLatlng, map: gMap, title:records[j].departureAddress});
           // let marker2 = new google.maps.Marker({position: myLatlng, map: gMap2, title:records[j].departureAddress,icon:gIcon});
           // markers.push(marker);
            records[j].consolidate = false;
            records[j].lat =  records[j].departureLatitude;
            records[j].lng =  records[j].departureLongitude;
            records[j].availCount =  records[j].availableQuantity?records[j].availableQuantity:1;
            points.push(records[j]);
            latlngbounds.extend(myLatlng);
            // markerCluster.addMarker(marker);
        }*/
      //  markerCluster.addMarkers(markers);
        if (latlngbounds && records.length > 0) {
            if (gMap) {
                gMap.fitBounds(latlngbounds);
            }
            if (gMap2) {
                gMap2.fitBounds(latlngbounds);
            }
        }
        zooms = [{minZoom:0,maxZoom:22,minDist:0,maxDist:500},
                {minZoom:0,maxZoom:12,minDist:0,maxDist:2000},
                {minZoom:0,maxZoom:11,minDist:0,maxDist:3000},
                 {minZoom:0,maxZoom:10,minDist:0,maxDist:4000},
                {minZoom:0,maxZoom:9,minDist:0,maxDist:10000},
                {minZoom:0,maxZoom:8,minDist:0,maxDist:23000},
                {minZoom:0,maxZoom:3,minDist:0,maxDist:650000},
                {minZoom:0,maxZoom:2,minDist:0,maxDist:1500000},
                {minZoom:0,maxZoom:6,minDist:0,maxDist:75000},
                {minZoom:0,maxZoom:1,minDist:0,maxDist:3000000}
            ];

        let point1 = new google.maps.LatLng(40.7094756, -74.0072955);
        let point2 = new google.maps.LatLng(40.70924009999999, -74.0068787);
       // let dist = google.maps.geometry.spherical.computeDistanceBetween(point1,point2)

        //let marker1 = new google.maps.Marker({position: point1, map: gMap2});
       // let marker2 = new google.maps.Marker({position: point2, map: gMap2});
        showSelectedTabMarkers();
        gMap2.addListener("idle", () => {
            console.log( google.maps.geometry.spherical.computeDistanceBetween(point1,point2));
            showSelectedTabMarkers();
        });

    }



    let onClickRentalSummary = ()=>{
        clearConsolidatedMarkers();
        let reqObj:any = {};
        reqObj.allowedDeviation = allowedDeviation;
        reqObj.dropLocationNearToPickUpLocation = true;
        reqObj.searchType = RENTAL;
        var summaryAPI:string = "api/session/search/public/summary/";//+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(summaryAPI,reqObj,"","POST",true,onGetRentalSummaryDetails,onLoginError);
    }

    let onGetRentalSummaryDetails = (dataObj:any)=> {
        //console.log(dataObj);
        //clearGoogleMarkers();
        let records: any = [];
        points = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    records = restData
                } else {
                    records.push(restData)
                }
            }
        }


        let gIcon: any = null;//showAvailableCount();
        //points = [];
        // records = [];
        //records[0] = {"availabilityId":122,"equipmentId":42,"equipmentType":"REEFER VAN","genericEquipmentName":"","unitNumber":"DVLSpecificUnit103","vin":"DVLSpecificVIN103","departureAddress":"74 Mission Rock St, San Francisco, CA 94158, USA","departureLatitude":37.7744438,"departureLongitude":-122.3886096};
        // records[1] = {"availabilityId":141,"availableQuantity":3,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"421 Rue Soumande, Québec, QC G1M 2X6, Canada","departureLatitude":46.8212619,"departureLongitude":-71.25662849999999};
        // records[2] = {"availabilityId":146,"availableQuantity":1,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"Saskatchewan, Canada","departureLatitude":52.9399159,"departureLongitude":-106.4508639};
        // records[3] = {"availabilityId":147,"availableQuantity":44,"equipmentId":31,"equipmentType":"HEATED VAN","genericEquipmentName":"E1JKKVP09","departureAddress":"NS-102, Halifax, NS, Canada","departureLatitude":44.6743465,"departureLongitude":-63.6783819};
        // records[4] = {"availabilityId":248,"equipmentId":167,"equipmentType":"PLATE DRY VAN","genericEquipmentName":"","unitNumber":"AvailabilityTest2","vin":"AvailabilityVIn2","departureAddress":"302 Queen St, Mistissini, QC G0W 1C0, Canada","departureLatitude":50.4131692,"departureLongitude":-73.8659237};
        //records[5] = {"availabilityId":296,"availableQuantity":1,"equipmentId":204,"equipmentType":"DROPDECK","genericEquipmentName":"Generic2","departureAddress":"2813 S Walker Ave, Oklahoma City, OK 73109, USA","departureLatitude":35.4367163,"departureLongitude":-97.52169289999999};
        //records[6] = {"availabilityId":329,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"151 E Wacker Dr, Chicago, IL 60601, USA","departureLatitude":41.8874455,"departureLongitude":-87.6216614};
        //records[7] = {"availabilityId":331,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"808 Howell St, Seattle, WA 98101, USA","departureLatitude":47.61438589999999,"departureLongitude":-122.3340182};
        //records[8] = {"availabilityId":359,"equipmentId":49,"equipmentType":"LOG","genericEquipmentName":"","unitNumber":"CanadaUnit102","vin":"CanadaVIN102","departureAddress":"808 Howell St, Seattle, WA 98101, USA","departureLatitude":47.61438589999999,"departureLongitude":-122.3340182};
        // records[9] = {"availabilityId":450,"availableQuantity":4,"equipmentId":5836,"equipmentType":"DRY VAN","genericEquipmentName":"abecdd","departureAddress":"wisgsxgshgeuehb","departureLatitude":43.6282065,"departureLongitude":-79.41548};
        //records[10] = {"availabilityId":545,"availableQuantity":3,"equipmentId":5889,"equipmentType":"DROPDECK","genericEquipmentName":"Truck 450","departureAddress":"1000 Palladium Dr, Ottawa, ON K2V 1A5, Canada","departureLatitude":45.2969151,"departureLongitude":-75.926823};
        //records[11] = {"availabilityId":569,"equipmentId":5890,"equipmentType":"TANK CHEMICAL","genericEquipmentName":"","unitNumber":"Truck1","vin":"Truck143434344444","departureAddress":"Hyderabad, Telangana, India","departureLatitude":17.385044,"departureLongitude":78.486671};
        // records[1] = {"availabilityId":590,"equipmentId":5912,"equipmentType":"DRY VAN","genericEquipmentName":"","unitNumber":"spec_27_01","vin":"spec_27_01","departureAddress":"San Jose, CA, USA","departureLatitude":37.33874,"departureLongitude":-121.8852525};
        // records[2] = {"availabilityId":622,"availableQuantity":1,"equipmentId":5925,"equipmentType":"DRY VAN","unitNumber":"1233445","vin":"GFGDF234","departureAddress":"San Jose, CA, USA","departureLatitude":37.33874,"departureLongitude":-121.8852525};

        for (let j = 0; j < records.length; j++) {
            // gIcon = showAvailableCount(records[j].availableQuantity?records[j].availableQuantity:"1");
            myLatlng = new google.maps.LatLng(records[j].departureLatitude, records[j].departureLongitude);
            //let marker = new google.maps.Marker({position: myLatlng, map: gMap, title:records[j].departureAddress});
            // let marker2 = new google.maps.Marker({position: myLatlng, map: gMap2, title:records[j].departureAddress,icon:gIcon});
            // markers.push(marker);
            records[j].consolidate = false;
            records[j].lat = records[j].departureLatitude;
            records[j].lng = records[j].departureLongitude;
            records[j].availCount = records[j].availableQuantity ? records[j].availableQuantity : 1;
            points.push(records[j]);
            latlngbounds.extend(myLatlng);
            // markerCluster.addMarker(marker);
        }
        //  markerCluster.addMarkers(markers);
        if (latlngbounds && records.length > 0) {
            if (gMap) {
                gMap.fitBounds(latlngbounds);
            }
            if (gMap2) {
                gMap2.fitBounds(latlngbounds);
            }
        }
        showSelectedTabMarkers();
    }

    let showSelectedTabMarkers = ()=>{
        let gIcon:any = null;
        let zoom = gMap2.getZoom();
        //console.log(zoom);
       // console.log(google.maps.geometry.spherical.computeDistanceBetween(point1,point2),zoom);
        let copyPoints = JSON.parse(JSON.stringify(points));//points.concat();
        let copyPoints1 = JSON.parse(JSON.stringify(points));;//points.concat();
        nPoints = [];
        let dist = 0;
        for(let k=0;k<copyPoints.length;k++){
            let availCount = 0;
            if(copyPoints[k] && !copyPoints[k].consolidate){
                availCount = copyPoints[k].availCount;
                let X1 = new google.maps.LatLng(copyPoints[k].lat, copyPoints[k].lng);
                for(let l=0;l<copyPoints1.length;l++){
                    if(!copyPoints1[l].consolidate && copyPoints[k].availabilityId != copyPoints1[l].availabilityId){
                        let X2 = new google.maps.LatLng(copyPoints1[l].lat, copyPoints1[l].lng);
                        dist = google.maps.geometry.spherical.computeDistanceBetween(X1,X2);

                        // if((zoom<3 && dist<distMeters4) || (zoom<4 && dist<distMeters) || (zoom<2 && dist<distMeters1) || (zoom<1 && dist<distMeters2)){
                        for(let y1=0;y1<zooms.length;y1++){
                            let y1Item = zooms[y1];
                            if(y1Item &&  y1Item.maxZoom &&  y1Item.maxDist){
                                if(zoom>=y1Item.minZoom && zoom<=y1Item.maxZoom){
                                    if(dist>=y1Item.minDist && dist<=y1Item.maxDist){
                                        availCount = availCount+copyPoints1[l].availCount;
                                        copyPoints1[l].consolidate = true;
                                        copyPoints[l].consolidate = true;
                                        break;
                                    }
                                }

                            }
                        }

                        // }
                    }
                }
                let newPoint:any = {};
                newPoint.latLng = X1;
                newPoint.lat = X1.lat();
                newPoint.lng = X1.lng();
                newPoint.availCount = availCount?availCount:1;
                newPoint.address =  copyPoints1[k].departureAddress;
                newPoint.availabilityId =  copyPoints1[k].availabilityId;
                copyPoints[k].consolidate = true;
                copyPoints1[k].consolidate = true;
                nPoints.push(newPoint);
            }
        }

        console.log(nPoints);
        for(let x1=0;x1<nMarkers.length;x1++){
            nMarkers[x1].setMap(null);
        }
        nMarkers = [];

        nPoints.forEach((nPoint:any,idx:number)=>{
            gIcon = showAvailableCount(nPoint.availCount);//nPoint.availabilityId);
            let marker1 = new google.maps.Marker({position: nPoint.latLng, map: gMap2,icon:gIcon,title:nPoint.address.toString()});
            nMarkers.push(marker1);
        })
    }

    let clearConsolidatedMarkers = ()=>{
        for(let z1=0;z1<nMarkers.length;z1++){
            nMarkers[z1].setMap(null);
        }
    }
    let onChangeStDate = (dateValue:any)=>{
        let strDate:string = CommonUtil.getDate(dateValue);
        setStDate(strDate);
        if(stDateRef1.current){
            //stDateRef1.current.flatpickr.setDate(new Date(dateValue));
        }
        let currTime:number = new Date(strDate).getTime();
        currTime = currTime+(24*60*60*1000);
        //endDateRef.current.flatpickr.setDate(new Date(currTime));
        //let strDate:string = CommonUtil.getReservationDateString(new Date(currTime));
        if(endDateRef1.current){
            endDateRef1.current.flatpickr.set("minDate",new Date(dateValue));
            endDateRef1.current.flatpickr.setDate(new Date(currTime));
        }
        onChangeDates();
    }
    let onChangeEdDate = (dataVal:any)=>{
        let strDate:string = CommonUtil.getDate(dataVal);
        setEndDate(strDate);
        if(endDateRef1.current){
            endDateRef1.current.flatpickr.setDate(new Date(dataVal));
        }
        onChangeDates();
    }
    let onChangeDates = ()=>{
        if(stDateRef.current && endDateRef.current){
            var startDate = stDateRef.current.flatpickr.selectedDates[0];
            var endDate = endDateRef.current.flatpickr.selectedDates[0];

            endDateRef.current.flatpickr.set("minDate",new Date(startDate));
            stDateRef.current.flatpickr.set("maxDate",new Date(endDate));

        }
    }
    let onOpenStDate = ()=>{
        if(stDateRef.current){
            stDateRef.current.flatpickr.set("minDate",new Date());
            if(endDateRef.current){
                var endDate = endDateRef.current.flatpickr.selectedDates[0];
                if(endDate){
                    stDateRef.current.flatpickr.set("maxDate",new Date(endDate));
                }else{
                    stDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{

            }
        }
    }

    let onOpenStDate1 = ()=>{
        if(stDateRef1.current){
            stDateRef1.current.flatpickr.set("minDate",new Date());
        }
    }

    let onOpenRentalSDData = ()=>{
        if(stDateRentalRef.current){
            if(endDateRentalRef.current){
                var endDate = endDateRentalRef.current.flatpickr.selectedDates[0];
                if(endDate){
                    stDateRentalRef.current.flatpickr.set("maxDate",new Date(endDate));
                }else{
                    stDateRentalRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                stDateRentalRef.current.flatpickr.set("minDate",new Date());
            }
        }
    }

    let onOpenRentalSDData1 = ()=>{
        if(stDateRentalRef1.current){
            stDateRentalRef1.current.flatpickr.set("minDate",new Date());
        }
    }

    let onOpenEDDate = ()=>{
        if(endDateRef.current){
            if(stDateRef.current){
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRef.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRef.current.flatpickr.set("minDate",new Date());
            }

        }
    }

    let onOpenEDDate1 = ()=>{
        if(endDateRef1.current){
            if(stDateRef1.current){
                var startDate = stDateRef1.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRef1.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRef1.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRef1.current.flatpickr.set("minDate",new Date());
            }
        }
    }



    let onOpenRentalEDData = ()=>{
        if(endDateRentalRef.current){
            if(stDateRentalRef.current){
                var startDate = stDateRentalRef.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRentalRef.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRentalRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRentalRef.current.flatpickr.set("minDate",new Date());
            }

        }
    }

    let onOpenRentalEDData1 = ()=>{
        if(endDateRentalRef1.current){
            if(stDateRentalRef1.current){
                var startDate = stDateRentalRef1.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRentalRef1.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRentalRef1.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRentalRef1.current.flatpickr.set("minDate",new Date());
            }

        }
    }


    let onClickSearchReposition = ()=>{
        $("#chkPanel").show();
        clearTextField();
        Is_FILTER = false;
        let stDate = null;
        let edDate = null;
        if(stDateRef1.current){
            stDate = stDateRef1.current.flatpickr.selectedDates[0];
            date1 = stDate;
        }
        if(endDateRef1.current){
            edDate = endDateRef1.current.flatpickr.selectedDates[0];
            date2 = edDate;
        }

        if(fromAddress){
            let reqObj:any = {};
            reqObj.allowedDeviation = (Number(allowedDeviation)*CommonUtil.MILES_TO_METERS);
            reqObj.arrivalDate = CommonUtil.getReservationDateString(edDate);
            reqObj.departureDate = CommonUtil.getReservationDateString(stDate);
            reqObj.dropLocationNearToPickUpLocation = true;
            reqObj.searchType = dbSearchType;
            reqObj.fromAddress = fromAddress;
            reqObj.fromLatitude = fromLatitude;
            reqObj.fromLongitude = fromLongitude
            reqObj.toAddress = toAddress;
            reqObj.toLatitude = toLatitude;
            reqObj.toLongitude = toLongitude;

            setStDate(CommonUtil.getReservationDateString(stDate));
            setEndDate(CommonUtil.getReservationDateString(edDate));

            var listAPI:string = "";
            if(CommonUtil.PUBLIC_SEARCH_DB){
                listAPI =  "api/session/search/public/";//+CommonUtil.getSaasOrgId();
            }else{
                listAPI =  "api/search/private?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            //listAPI =  "api/session/search/public/";

            NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onGetSearchRecords,onLoginError);
        }

    }
    let onGetSearchRecords = (dataObj:any)=>{
        let equips = []
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    equips = restData;
                } else {
                    equips.push(restData);
                }
                equips.forEach((eItem:any,eIdx:number)=>{
                    if(eItem.totalDistance){
                        eItem.totalDistanceKM = CommonUtil.convertMetersToKM(Number(eItem.totalDistance));
                    }
                    if(eItem.bobtailToPuDistance){
                        eItem.bobtailToPuDistanceKM = CommonUtil.convertMetersToKM(Number(eItem.bobtailToPuDistance));
                    }
                    if(!fromAddress){
                        eItem.bobtailToPuDistanceKM = 0;
                    }
                    if(eItem.hookDistance){
                        eItem.hookDistanceKM = CommonUtil.convertMetersToKM(Number(eItem.hookDistance));
                    }
                    if(eItem.dropToTargetDistance){
                        eItem.dropToTargetDistanceKM = CommonUtil.convertMetersToKM(Number(eItem.dropToTargetDistance));
                    }
                    if(!toAddress){
                        eItem.dropToTargetDistanceKM = 0;
                    }
                    if(eItem.totalTravelTime){
                        eItem.totalTravelTime = ((Number(eItem.totalTravelTime))/(60*60)).toFixed(0);
                    }
                    if(eItem.movePrice){
                        eItem.movePrice = eItem.currency+" "+ eItem.movePrice;
                    }

                    if(eItem.revenuePerHour){
                        eItem.revenuePerHour = eItem.currency+" "+ eItem.revenuePerHour;
                    }


                    eItem.unitNumber = eItem.genericEquipmentName?eItem.genericEquipmentName:eItem.unitNumber;
                    eItem.isGeneric = (eItem.isGenericEquipment == "Y")?"Generic":"Specific";

                });
                 matchCount = 0;
                 bestMatchCount = 0;
                equips.forEach((eItem:any,idx:number)=>{
                    if(!eItem.withInAllowedDeviation){
                        matchCount = matchCount+1;
                    }else{
                        bestMatchCount = bestMatchCount+1;
                    }
                })
                setRepositionBestCount(bestMatchCount);
                setMatchesCount(matchCount.toString());
                //setRepositionCount(equips.length);
                if(bestMatchCount>0){
                    var tempArray:any = [];
                    equips.forEach((eItem:any,idx:number)=>{
                        if(eItem.withInAllowedDeviation){
                            tempArray.push(eItem);
                        }else{
                           // bestMatchCount = bestMatchCount+1;
                        }
                    });
                    setSearchData(tempArray);
                }else{
                    setSearchData(equips);
                }

                copySearchData = [...equips];
                $("#chkMatch").prop( "checked", false );
                if(bestMatchCount == 0){
                    $("#chkMatch").prop( "checked", true );
                    //newMatchesChecked = true;
                    //onFilterData();
                }
            }
        }


        getDistanceBetweenLocations();
        //console.log(equips);
        $("#search-main-page").hide();
        $("#pnlSearchPage").hide();
        $("#pnlReposition").show();

        showRepositinMapData();

    }

    let onClickSearchRental = ()=>{
        clearTextField();
        Is_FILTER = false;
        searchTextHere = "";
        $("#chkPanel").hide();
        $("#chkMatch").prop( "checked", false );

        let sDate = null;
        let eDate = null;

        if(stDateRentalRef1.current){
            sDate = stDateRentalRef1.current.flatpickr.selectedDates[0];
            // date1 = stDate;
        }
        if(endDateRentalRef1.current){
            eDate = endDateRentalRef1.current.flatpickr.selectedDates[0];
            //date2 = edDate;
        }

        if(toAddress && toLatitude && toLongitude){
            let reqObj:any = {};
            reqObj.arrivalDate = eDate?CommonUtil.getReservationDateString(eDate):"";
            reqObj.departureDate = sDate?CommonUtil.getReservationDateString(sDate):"";
            reqObj.dropLocationNearToPickUpLocation = true;
            reqObj.searchType = dbSearchType;
            reqObj.fromAddress = toAddress;
            reqObj.fromLatitude = toLatitude;
            reqObj.fromLongitude = toLongitude;

            console.log(reqObj);

            //let listAPI =  "api/session/search/public/";

            setStRentalDate(sDate?CommonUtil.getReservationDateString(sDate):"");
            setEndRentalDate(eDate?CommonUtil.getReservationDateString(eDate):"");

            var listAPI:string = "";
            if(CommonUtil.PUBLIC_SEARCH_DB){
                listAPI =  "api/session/search/public/";//+CommonUtil.getSaasOrgId();
            }else{
                listAPI =  "api/search/private?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onGetSearchRentalRecords,onLoginError);
        }
    }
    let onGetSearchRentalRecords = (dataObj:any)=>{
        //console.log(dataObj);
        let equips = []
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    equips = restData;
                } else {
                    equips.push(restData);
                }
                equips.forEach((eItem:any,eIdx:number)=> {
                    if (eItem.dailyRate) {
                        eItem.dailyRate = eItem.currency+" "+eItem.dailyRate;
                    }
                    if (eItem.distanceRate) {
                        eItem.distanceRate = eItem.currency+" "+eItem.distanceRate;
                    }
                    if (eItem.engineRate) {
                        eItem.engineRate = eItem.currency+" "+eItem.engineRate;
                    }
                });

                /*let matchCount = 0;
                equips.forEach((eItem:any,idx:number)=>{
                    if(eItem.withInAllowedDeviation){
                        matchCount = matchCount+1;
                    }
                })
                setMatchesCount(matchCount.toString());*/
                setRenterBestCount(equips.length);
                setSearchData(equips);
                copySearchData = [...equips];
            }
        }
        $("#search-main-page").hide();
        $("#pnlSearchPage").hide();
        $("#pnlReposition").show();
        showRepositinMapData();
    }
    let showRepositinMapData = ()=>{
        clearAllFilters();

        let lat:number = 0;
        let lng:number = 0;
        filterData = [];
        if(searchType == REPOSITION){
            lat = fromLatitude;
            lng = fromLongitude;
        }else{
            lat = toLatitude;
            lng = toLongitude;
        }
        if(mapRef1.current){
            gMap1 = new google.maps.Map(mapRef1.current, {
                zoom: 15, center: new google.maps.LatLng(lat, lng),
                gestureHandling: 'greedy', streetViewControl: true, zoomControl: true, fullscreenControl: true,
                draggableCursor: 'pointer',
                fullscreenControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
                mapTypeControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
            });
            console.log(copySearchData);
            var latlngbounds = new google.maps.LatLngBounds();
            var myLatlng: any = null;
            repPoints = [];
            for (let x1 = 0; x1 < copySearchData.length; x1++) {
                let gItem: any = copySearchData[x1];
                myLatlng = new google.maps.LatLng(copySearchData[x1].departureLatitude, copySearchData[x1].departureLongitude);
                copySearchData[x1].consolidate = false;
                copySearchData[x1].lat =  copySearchData[x1].departureLatitude;
                copySearchData[x1].lng =  copySearchData[x1].departureLongitude;
                copySearchData[x1].availCount =  copySearchData[x1].availableQuantity?copySearchData[x1].availableQuantity:1;
                repPoints.push(copySearchData[x1]);
                latlngbounds.extend(myLatlng);

            }
            if (latlngbounds && copySearchData.length > 0) {
                if (gMap1) {
                    //google.maps.event.clearInstanceListeners(gMap1);
                    gMap1.fitBounds(latlngbounds);
                    //console.log(gMap1.getZoom());
                    setTimeout(()=>{
                        if(gMap1.getZoom()>12){
                            gMap1.setZoom(12);
                        }

                    },1000);
                }

                setTimeout(()=>{
                    Is_FILTER = true;
                },5000);
            }else{
                if(gMap1.getZoom()>10){
                    gMap1.setZoom(10);
                }
            }
            gMap1.addListener("idle", () => {
                //console.log(copySearchData);
                if(searchType == REPOSITION){
                    if(Is_FILTER){
                        getDataByBounds();
                    }else{
                        showRepMarkers();
                    }
                }else{
                    showRepMarkers();
                }
            });
            if(gMap1){
               // showMarkers();
            }
        }
    }

    let showRepMarkers = ()=>{
        let zoom = gMap1.getZoom();
        console.log(zoom);
        let copyPoints = JSON.parse(JSON.stringify(repPoints));//points.concat();
        let copyPoints1 = JSON.parse(JSON.stringify(repPoints));;//points.concat();
        repNPoints = [];
        let dist = 0;
        for(let k=0;k<copyPoints.length;k++){
            let availCount = 0;
            if(copyPoints[k] && !copyPoints[k].consolidate){
                availCount = copyPoints[k].availCount;
                let X1 = new google.maps.LatLng(copyPoints[k].lat, copyPoints[k].lng);
                for(let l=0;l<copyPoints1.length;l++){
                    if(!copyPoints1[l].consolidate && copyPoints[k].availabilityId != copyPoints1[l].availabilityId){
                        let X2 = new google.maps.LatLng(copyPoints1[l].lat, copyPoints1[l].lng);
                        dist = google.maps.geometry.spherical.computeDistanceBetween(X1,X2);

                        for(let y1=0;y1<zooms.length;y1++){
                            let y1Item = zooms[y1];
                            if(y1Item &&  y1Item.maxZoom &&  y1Item.maxDist){
                                if(zoom>=y1Item.minZoom && zoom<=y1Item.maxZoom){
                                    if(dist>=y1Item.minDist && dist<=y1Item.maxDist){
                                        availCount = availCount+copyPoints1[l].availCount;
                                        copyPoints1[l].consolidate = true;
                                        copyPoints[l].consolidate = true;
                                        break;
                                    }
                                }

                            }
                        }
                    }
                }
                let newPoint:any = {};
                newPoint.latLng = X1;
                newPoint.lat = X1.lat();
                newPoint.lng = X1.lng();
                newPoint.availCount = availCount?availCount:1;
                newPoint.address =  copyPoints1[k].departureAddress;
                newPoint.availabilityId =  copyPoints1[k].availabilityId;
                copyPoints[k].consolidate = true;
                copyPoints1[k].consolidate = true;
                repNPoints.push(newPoint);
            }
        }
        console.log(repNPoints);
        for(let x1=0;x1<repNMarkers.length;x1++){
            repNMarkers[x1].setMap(null);
        }
        repNMarkers = [];

        repNPoints.forEach((nPoint:any,idx:number)=>{
            let gIcon = showAvailableCount(nPoint.availCount);//nPoint.availabilityId);
            let marker1 = new google.maps.Marker({position: nPoint.latLng, map: gMap1,icon:gIcon,title:nPoint.address.toString()});
            repNMarkers.push(marker1);
        })
    }

    let matchCountChecked = ()=>{
        if ($('#chkMatch').is(':checked')) {
            return true;
        }else{
            return false;
        }
    }

    let getDataByBounds = ()=>{
        let items:any = [];
        //console.log(copySearchData);
        let chkMatch = matchCountChecked();
        let marksrsData:any = [];
        marksrsData = copySearchData;
        if(equipmentTypeFilter || modelYearFilter || axlesCountFilter){
            marksrsData = filterData;//copySearchData;
        }
        marksrsData.forEach((item:any,idx:number)=> {
            if (item) {
                let latLng:any = {};
                latLng.lat = item.departureLatitude;
                latLng.lng = item.departureLongitude;
                //console.log(gMap1.getBounds().contains(latLng),gMap1.getBounds())
                if(gMap1.getBounds().contains(latLng)){
                    // console.log(item);
                    if(bestMatchCount>0){
                        if(!chkMatch){
                            if(item.withInAllowedDeviation){
                                items.push(item);
                            }
                        }else{
                            items.push(item);
                        }
                    }else{
                        if(!chkMatch){
                            if(item.withInAllowedDeviation){
                                items.push(item);
                            }
                        }else{
                            items.push(item);
                        }
                    }
                }
            }
        });
        console.log(items);
        setSearchData(items);
        repPoints = [];
        for (let x1 = 0; x1 < items.length; x1++) {
            //let gItem: any = copySearchData[x1];
            //myLatlng = new google.maps.LatLng(copySearchData[x1].departureLatitude, copySearchData[x1].departureLongitude);
            items[x1].consolidate = false;
            items[x1].lat =  items[x1].departureLatitude;
            items[x1].lng =  items[x1].departureLongitude;
            items[x1].availCount =  items[x1].availableQuantity?items[x1].availableQuantity:1;
            repPoints.push(items[x1]);
            //latlngbounds.extend(myLatlng);
        }
        showRepMarkers();
    }
    let showMarkers = ()=>{
        clearGoogleMarkers();
        var latlngbounds = new google.maps.LatLngBounds();
        var myLatlng: any = null;
        markers = [];

        markerCluster = new MarkerClusterer({});
       // markerCluster.setMap(gMap1);
        let gIcon:any = null;
        for (let i = 0; i < copySearchData.length; i++) {
            let gItem:any = copySearchData[i];
            let marker:any = null;
            if(gItem){
                gIcon = showAvailableCount(gItem.availableQuantity?gItem.availableQuantity:"1");
                myLatlng = new google.maps.LatLng(gItem.departureLatitude, gItem.departureLongitude);
                if(searchType == REPOSITION){
                    if(bestMatchCount>0){
                        if(gItem.withInAllowedDeviation){
                             marker = new google.maps.Marker({
                                position: myLatlng,
                                map: gMap1,
                                title:gItem.departureAddress,
                                icon:gIcon
                            });
                            //markerCluster.addMarker(marker);
                            markers.push(marker);
                            latlngbounds.extend(myLatlng);
                        }
                    }else{
                         marker = new google.maps.Marker({
                            position: myLatlng,
                            map: gMap1,
                            title:gItem.departureAddress,
                            icon:gIcon
                        });
                        //markerCluster.addMarker(marker);
                        markers.push(marker);
                        latlngbounds.extend(myLatlng);
                    }
                }else{
                     marker = new google.maps.Marker({
                        position: myLatlng,
                        map: gMap1,
                        title:gItem.departureAddress,
                        icon:gIcon
                    });
                    //markerCluster.addMarker(marker);
                    markers.push(marker);
                    latlngbounds.extend(myLatlng);
                }
                if(marker){
                    marker.addListener('click', () => {
                        //console.log(gItem);
                        Is_FILTER = false;
                        displayInfoWindow(gItem,marker);
                        setTimeout(()=>{
                            Is_FILTER = true;
                        },5000);
                        //infowindowEquipment.setPosition(marker.getPosition());
                    });
                }
            }
        }
        var mapCenter = latlngbounds.getCenter();
        if (latlngbounds && copySearchData.length > 0) {
            if (gMap1) {
                //google.maps.event.clearInstanceListeners(gMap1);
                gMap1.fitBounds(latlngbounds);
                console.log(gMap1.getZoom());
                setTimeout(()=>{
                    if(gMap1.getZoom()>12){
                        gMap1.setZoom(12);
                    }

                },1000);
            }

            setTimeout(()=>{
                Is_FILTER = true;
            },5000);
        }else{
            if(gMap1.getZoom()>10){
                gMap1.setZoom(10);
            }
        }
    }

    let displayInfoWindow = (gItem:any,marker:any)=>{
        var newContentString = '<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">';
        if(dbSearchType == RENTAL){
            newContentString = newContentString+ '<section class="res" style="height: 200px">';
        }else{
            newContentString = newContentString+ '<section class="res" style="height: 260px">';
        }

        newContentString = newContentString+ '<div class="row">';
        newContentString = newContentString+'<div class="col-12 mb-2">';
        newContentString = newContentString+'<div class="row">';
        newContentString = newContentString+'<div class="col-6">';
        newContentString = newContentString+'<span class="details-head"><strong>'+gItem.equipmentType+'</strong> &nbsp;<br />'+(gItem.numberOfAxles?(gItem.numberOfAxles)+" AXLES" :"")+' | ';
        newContentString = newContentString+(gItem.lengthOfTrailer?(gItem.lengthOfTrailer):"") +' | '+ (gItem.year?(gItem.year):"")+'</span>';
        newContentString = newContentString+'</div>';

        newContentString = newContentString+'<div class="col-6 text-right">';
        newContentString = newContentString+'<div class="rating">';
        newContentString = newContentString+'<i class="fa-regular fa-star fa-star-half-o"></i>';
        newContentString = newContentString+'<i class="fa-regular fa-star fa-star-half-o"></i>';
        newContentString = newContentString+'<i class="fa-regular fa-star fa-star-half-o"></i>';
        newContentString = newContentString+'<i class="fa-regular fa-star fa-star-half-o"></i>';
        newContentString = newContentString+'<i class="fa-regular fa-star fa-star-half-o"></i>';
        newContentString = newContentString+'|'+(gItem.ratingCount?gItem.ratingCount:"")+' <br />Ratings';
        newContentString = newContentString+'</div>';
        newContentString = newContentString+"</div>";
        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';

        newContentString = newContentString+'<div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 text-center">';
        newContentString = newContentString+ '<div class="row">';
        newContentString = newContentString+ '<div class="col-12 location1 p-0">';
        newContentString = newContentString+' <i class="fa fa-map-marker"></i>';
        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';

        newContentString = newContentString+ '<div class="row">';
        newContentString = newContentString+ '<div class="col-12  p-0">';
        newContentString = newContentString+' <i class="fa fa-map-marker"></i>';
        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';

        newContentString = newContentString+'<div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">';
        newContentString = newContentString+'<div class="row">';
        newContentString = newContentString+'<div class="col-12 p-0">';
        newContentString = newContentString+'<span style="font-weight: bold">'+CommonUtil.getDBDate(gItem.departureDate)+'</span><br/>';
        newContentString = newContentString+'<span>'+gItem.departureCity+','+gItem.departureState+'</span>';
        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';

        newContentString = newContentString+'<div class="row" style="padding-top: 15px">';
        newContentString = newContentString+'<div class="col-8 p-0">';
        newContentString = newContentString+'<span style="font-weight: bold">'+CommonUtil.getDBDate(gItem.arrivalDate)+'</span><br/>';
        newContentString = newContentString+'<span>'+gItem.arrivalCity+','+gItem.arrivalState+'</span>';
        newContentString = newContentString+'</div>';


        if(dbSearchType == REPOSITION){
            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.REVENUE,"Revenue")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">$'+(gItem.movePrice?gItem.movePrice:"-")+'</div></div>';
        }
        newContentString = newContentString+'</div>';

        newContentString = newContentString+'</div>';
        newContentString = newContentString+'</div>';

        if(dbSearchType == RENTAL){
            newContentString =  newContentString+'<div class="row mt-2">';
            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white">'+CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">$'+ (gItem.dailyRate?gItem.dailyRate:"-")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white">'+CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">$'+(gItem.distanceRate?gItem.distanceRate:"-")+'</div>';
            newContentString = newContentString+'</div>';

            if(gItem.engineRate){
                newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
                newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white">'+CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate")+'</div>';
                newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">$'+(gItem.engineRate?gItem.engineRate:"-")+'</div>';
                newContentString = newContentString+'</div>';


            }

            newContentString = newContentString+'</div>';
        }else{
            newContentString = newContentString+'<div class="row mt-2">';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.REVENUE_PER_HOUR,"Rev per Hour")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">$'+(gItem.revenuePerHour?gItem.revenuePerHour:"-")+'</div></div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.EST_DRIVE_HRS,"Est. Drive Hrs")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">'+(gItem.totalTravelTime?gItem.totalTravelTime:"-")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.TOTAL_KM,"Tot Distance (KM)")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">'+(gItem.totalDistanceKM?gItem.totalDistanceKM:"-")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.BOBTAIL_TO_PU,"Bobtail to Pu (KM)")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">'+(gItem.bobtailToPuDistanceKM?gItem.bobtailToPuDistanceKM:"-")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.DROP_TO_TARGET_KM,"Drop to Target (KM)")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">'+(gItem.dropToTargetDistanceKM?gItem.dropToTargetDistanceKM:"")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">';
            newContentString = newContentString+'<div class="col-12 bg-danger p-1 text-center text-white tile-font-size">'+CommonUtil.getMessageText(Constants.HOOK_KM,"Hook (KM)")+'</div>';
            newContentString = newContentString+'<div class="col-12 border p-0 text-center box-height">'+(gItem.hookDistanceKM?gItem.hookDistanceKM:"")+'</div>';
            newContentString = newContentString+'</div>';

            newContentString = newContentString+'</div>';
        }

        newContentString = newContentString+"</section>";

       // if(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ||  CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER){
            newContentString = newContentString+'<div class="bottom-panel" style="padding-top: 3px;margin-left: 3px;margin-right:3px">';
            newContentString = newContentString+ '<button type="button" id="btnBookNow"  class="btn btn-primary waves-effect" data-dismiss="modal">'+CommonUtil.getMessageText(Constants.BOOK_NOW,"Book Now");
            if(gItem.availableQuantity){
                newContentString = newContentString+'<span style="margin-left: 20px;padding-left: 20px" class="available-count">'+gItem.availableQuantity+" "+CommonUtil.getMessageText(Constants.AVAILABLE,"Available")+"</span>";
                //<span style={{marginLeft:"20px"}} className="available-count">{item.availableQuantity+" "+CommonUtil.getMessageText(Constants.AVAILABLE,"Available")}</span>
            }
            newContentString = newContentString+"</button>";
            if(gItem.allowOffer == "Y"){
                newContentString = newContentString+ '<button type="button" id="btnCreateOffer" style="margin-left: 20px"  class="btn btn-warning waves-effect" data-dismiss="modal">'+CommonUtil.getMessageText(Constants.CREATE_OFFER,"Create Offer")+'</button>';
            }

            newContentString = newContentString+"</div>";
       // }


        newContentString = newContentString+"</div>";
        if(infowindowEquipment){
            infowindowEquipment.close();
        }
        infowindowEquipment = new google.maps.InfoWindow({minWidth: 420, maxWidth: 420});
        infowindowEquipment.setContent(newContentString);
        infowindowEquipment.open(gMap1, marker);

        setTimeout(()=>{
            $("#btnBookNow").on("click",()=>{
                console.log(gItem);
                onClickBookItem(gItem);
            })
            $("#btnCreateOffer").on("click",()=>{
                console.log(gItem);
                onClickShowAvailability(gItem);
            })
        },2000);
    }

    let getDistanceBetweenLocations = ()=>{
        const fromLatLng = { lat: fromLongitude, lng: fromLongitude };
        const toLatLng = { lat: toLatitude, lng: toLongitude };
        if(fromAddress || toAddress){
            CommonUtil.getDistanceBetweenPoints(fromAddress,toAddress,(dataObj:any)=>{
               // console.log(dataObj);
                let distanceInMeters = 0;
                let durationInMin = 0;
                if(dataObj && dataObj.distance && dataObj.distance.value){
                    distanceInMeters = dataObj.distance.value;
                    setTotalMiles(Number(CommonUtil.convertMetersToMiles(distanceInMeters)));
                }else{
                    setTotalMiles(0);
                }
                if(dataObj && dataObj.duration && dataObj.duration.text){
                    durationInMin = dataObj.duration.text;
                    setTotalDriveTime(durationInMin);
                }else{
                    setTotalDriveTime(0);
                }

            });
        }
    }


    let getSelectedRows = ()=>{
        if(gridRef.current && !rowExist){
            rowExist = true;
            let rows:any = gridRef.current.getSelectedRows();
            console.log(rows);
            let rowItem:any = null;
            if(rows && rows[0]){
                rowItem = rows[0];
            }
            if(rowItem){
                onClickRepItem(rowItem);
            }
        }
        setTimeout(()=>{
            rowExist = false;
        },100);
    }
    let onClickRepItem = (item:any,tileId?:any)=>{
        Is_FILTER = false;
        if(currentTileId){
            $("#"+currentTileId).removeClass("resactive");
        }
        if(tileId){
            currentTileId = tileId;
            if(currentTileId){
                $("#"+currentTileId).addClass("resactive");
            }
        }
        if(searchType == REPOSITION){
            clearGoogleMarkers();
            BusyLoader.showLoader();
            setTimeout(()=>{
                var latlngbounds = new google.maps.LatLngBounds();
                let fromLatLng = null;//new google.maps.LatLng(fromLatitude, fromLongitude);
                let fromMarker = null;
                if(fromAddress){
                    fromLatLng = new google.maps.LatLng(fromLatitude, fromLongitude);
                    fromMarker = new google.maps.Marker({position: fromLatLng, map: gMap1,label:"1",title:fromAddress,
                        icon:"../../images/NewIcons/r41rearloader.png"});
                }else{
                    fromLatLng = new google.maps.LatLng(item.departureLatitude, item.departureLongitude);
                    fromMarker = new google.maps.Marker({position: fromLatLng, map: gMap1,label:"1",title:item.departureAddress,
                        icon:"../../images/NewIcons/r41rearloader.png"});
                }

                markers.push(fromMarker);
                latlngbounds.extend(fromLatLng);

                let departureLatLng = new google.maps.LatLng(item.departureLatitude, item.departureLongitude);
                let departureMarker = new google.maps.Marker({position: departureLatLng, map: gMap1, label:"2",title:item.departureAddress,
                });
                markers.push(departureMarker);
                latlngbounds.extend(departureLatLng);

                let arrivalLatLng = new google.maps.LatLng(item.arrivalLatitude, item.arrivalLongitude);


                let toLatLng = null;
                console.log("to loc:"+toLocation1Ref.current?.value);
                if(toLocation1Ref.current?.value){
                    toLatLng = new google.maps.LatLng(toLatitude, toLongitude);
                    let toMarker = new google.maps.Marker({position: toLatLng, map: gMap1,label:"4",title:toAddress,
                        icon:"../../images/NewIcons/r4autoside.png"});
                    markers.push(toMarker);
                    latlngbounds.extend(toLatLng);
                }else{
                   // toLatLng = arrivalLatLng;
                   // toLatitude = item.arrivalLatitude;
                    //toLongitude = item.arrivalLongitude;
                   // toAddress = item.arrivalAddress;
                    let toLatLng1 = new google.maps.LatLng(item.arrivalLatitude, item.arrivalLongitude);
                    let toMarker1 = new google.maps.Marker({position: toLatLng1, map: gMap1,label:"4",title:item.arrivalAddress,
                        icon:"../../images/NewIcons/r4autoside.png"});
                    markers.push(toMarker1);
                    latlngbounds.extend(toLatLng1);
                    toLatLng = toLatLng1;
                }



                let arrivalMarker = new google.maps.Marker({position: arrivalLatLng, map: gMap1,label:"3",title:item.arrivalAddress,
                });
                markers.push(arrivalMarker);

                latlngbounds.extend(arrivalLatLng);




                var service = new google.maps.DirectionsService;
                var service_options:any = {origin: fromLatLng, destination: toLatLng,
                    waypoints: [{location: item.departureAddress}, {location: item.arrivalAddress}], travelMode: 'DRIVING'};

                //console.log("from:"+fromAddress,"to:"+toAddress,"depAddress:"+rowItem.departureAddress,"arrival address:"+rowItem.arrivalAddress);
                service.route(service_options, function (response, status) {
                    //console.log(response);
                    BusyLoader.hideLoader();
                    if (status != 'OK') {
                        return;
                    }
                    // var google = window.google;
                    renderer = new google.maps.DirectionsRenderer({});
                    renderer.setMap(gMap1);
                    renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
                    renderer.setDirections(response);
                    //renderDirectionsPolylines(response);
                    //allMintenanceTruckRoutes.push(renderer);
                });

                if (latlngbounds) {
                    if (gMap1) {
                        gMap1.fitBounds(latlngbounds);
                    }
                }
            },1000);

        }else{
            console.log(markers)
            console.log(item.departureLatitude, item.departureLongitude);
            //var marker = null;
            if(marker){
                marker.setAnimation(null);
            }
            for(var i=0;i<markers.length;i++){
                marker = markers[i];
                if(marker){

                    if(marker.getPosition().lat() == item.departureLatitude && marker.getPosition().lng() == item.departureLongitude){
                        marker.setAnimation(google.maps.Animation.BOUNCE);
                        break;
                    }
                }
            }
        }
    }
    let renderDirectionsPolylines = (response:any)=>{
        var polylineOptions = {
            strokeColor: '#C83939',
            strokeOpacity: 1,
            strokeWeight: 4
        };
        var colors = ["#FF0000", "#FF0000", "#FF0000", "#FF0000"];
        var polylines:any = [];
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < polylines.length; i++) {
            polylines[i].setMap(null);
        }
        var legs = response.routes[0].legs;
        for (i = 0; i < legs.length; i++) {
            var steps = legs[i].steps;
            for (var j = 0; j < steps.length; j++) {
                var nextSegment = steps[j].path;
                var stepPolyline = new google.maps.Polyline(polylineOptions);
                stepPolyline.setOptions({
                    strokeColor: colors[i]
                })
                for (var k = 0; k < nextSegment.length; k++) {
                    stepPolyline.getPath().push(nextSegment[k]);
                    bounds.extend(nextSegment[k]);
                }
                polylines.push(stepPolyline);
                stepPolyline.setMap(gMap1);
                // route click listeners, different one on each step

            }
        }
        gMap1.fitBounds(bounds);
    }
    let onClickBestMatches = ()=>{
        clearTextField();
        if(currentTileId){
            $("#"+currentTileId).removeClass("resactive");
            currentTileId = "";
        }
        if(searchType == REPOSITION){
            onClickSearchReposition();
        }else{
            onClickSearchRental();
        }
    }
    let onClickDown = ()=>{
        $("#sidebar").hide();
        $("#mapRef1").addClass("gmap-up-height");
        $("#mapRef1").removeClass("gmap-down-height");

        $("#gmap-responsive").addClass("gmap-up-height");
        $("#gmap-responsive").removeClass("gmap-down-height");

        $("#iconDown").hide();
        $("#iconUp").show();

    }
    let onClickUp = ()=>{
        $("#sidebar").show();
        $("#mapRef1").addClass("gmap-down-height");
        $("#mapRef1").removeClass("gmap-up-height");

        $("#gmap-responsive").addClass("gmap-down-height");
        $("#gmap-responsive").removeClass("gmap-up-height");

        $("#iconDown").show();
        $("#iconUp").hide();
    }

    let deselectTabButtons = ()=>{
        $("#lnkTileView").removeClass("btn-primary");
        $("#lnkGridView").removeClass("btn-primary");

       // $("#lnkTileView").addClass("sel-tile-button");
        //$("#lnkGridView").addClass("sel-tile-button");

        $("#pnlTileView").removeClass("active");
        $("#pnlGridView").removeClass("active");
    }
    let onClickTileView = ()=>{
        $("#pnlSearch").css("visibility","visible");
        deselectTabButtons();
        $("#lnkTileView").addClass("btn-primary");
        $("#pnlTileView").addClass("active");
    }
    let onClickGridView = ()=>{
        $("#pnlSearch").css("visibility","hidden");
        deselectTabButtons();
        $("#lnkGridView").addClass("btn-primary");
        $("#pnlGridView").addClass("active");
    }
    let onClickSearchBack = ()=>{
        $("#search-main-page").show();
        $("#pnlReposition").hide();
        $("#pnlSearchPage").hide();

        if(dbSearchType == REPOSITION){
           // onClickRepSearch();
        }else{
           // onClickRentalSearch();
        }
    }

    let deselectSearchTabs = ()=>{
        $("#navRepo").removeClass("active");
        $("#navRental").removeClass("active");

        $("#RepositioningSearch").removeClass("active");
        $("#RentalSearch").removeClass("active");
    }
    let onClickRepSearch = ()=>{
        searchType = REPOSITION;
        setDBSearchType(searchType);
        deselectSearchTabs();
        $("#navRepo").addClass("active");
        $("#RepositioningSearch").addClass("active");

        getRepositionSummary();
    }
    let onClickRentalSearch = ()=>{
        searchType = RENTAL;
        setDBSearchType(searchType);
        deselectSearchTabs();
        $("#navRental").addClass("active");
        $("#RentalSearch").addClass("active");

        onClickRentalSummary();
    }

    let onChangeRentalStDate = (dateValue:any)=>{
        let strDate:string = CommonUtil.getDate(dateValue);
        setStRentalDate(strDate);
        if(stDateRentalRef1.current){
           // stDateRentalRef1.current.flatpickr.setDate(new Date(dateValue));
            //stDateRef.current.flatpickr.setDate(new Date(restData.startDate));
            //stDateRef1.current.flatpickr.setDate(new Date(dateValue));
        }
        if(endDateRentalRef1.current){
            let currTime:number = new Date(dateValue).getTime();
            currTime = currTime+(24*60*60*1000);
            endDateRentalRef1.current.flatpickr.set("minDate",new Date(currTime));
            endDateRentalRef1.current.flatpickr.setDate(new Date(currTime));
            //stDateRef.current.flatpickr.setDate(new Date(restData.startDate));
            //stDateRef1.current.flatpickr.setDate(new Date(dateValue));
        }
    }

    let onChangeRentalEdDate = (dateValue:any)=>{
        let strDate:string = CommonUtil.getDate(dateValue);
        setEndRentalDate(strDate);
        if(endDateRentalRef1.current){
            endDateRentalRef1.current.flatpickr.setDate(new Date(dateValue));
            //stDateRef.current.flatpickr.setDate(new Date(restData.startDate));
            //stDateRef1.current.flatpickr.setDate(new Date(dateValue));
        }
    }


    let onRepSearch = ()=>{
        Is_FILTER = false;
        $("#chkPanel").show();
        let eDate = null;
        let sDate = null;
        let toLoc = "";
        let fromLoc = "";
        if(stDateRef1.current){
            sDate = stDateRef1.current.flatpickr.selectedDates[0];
           // date1 = stDate;
        }
        if(endDateRef1.current){
            eDate = endDateRef1.current.flatpickr.selectedDates[0];
            //date2 = edDate;
        }
        if(fromLocation1Ref.current && fromLocation1Ref.current?.value){
            fromLoc = fromLocation1Ref.current?.value;
        }

        if(toLocation1Ref.current && toLocation1Ref.current?.value){
            toLoc = toLocation1Ref.current?.value;
        }
        if(fromLoc){
        }else{
            fromAddress = "";
            fromLatitude = "";
            fromLongitude = "";
        }
        if(toLoc){
        }else{
            toAddress = "";
            toLatitude = "";
            toLongitude = "";
        }
        if((fromAddress || toAddress) && sDate && eDate){
            let reqObj:any = {};

            reqObj.allowedDeviation = (Number(allowedDeviation)*CommonUtil.MILES_TO_METERS);
            reqObj.arrivalDate = CommonUtil.getReservationDateString(eDate);//endDate;
            reqObj.departureDate = CommonUtil.getReservationDateString(sDate);//stDate;
            reqObj.dropLocationNearToPickUpLocation = true;
            reqObj.searchType = dbSearchType;



            reqObj.fromAddress = fromAddress;
            reqObj.fromLatitude = fromLatitude;
            reqObj.fromLongitude = fromLongitude;


            reqObj.toAddress = toAddress;
            reqObj.toLatitude = toLatitude;
            reqObj.toLongitude = toLongitude;


            console.log(reqObj);

            setStDate(CommonUtil.getReservationDateString(sDate));
            setEndDate(CommonUtil.getReservationDateString(eDate));

           // localStorage
            //var listAPI =  "api/session/search/public/";
            var listAPI:string = "";
            if(CommonUtil.PUBLIC_SEARCH_DB){
                listAPI =  "api/session/search/public/";//+CommonUtil.getSaasOrgId();
            }else{
                listAPI =  "api/search/private?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onGetSearchRecords,onLoginError);
        }

    }

    let onRentalSearch = ()=>{
        Is_FILTER = false;
        $("#chkPanel").hide();
        let eDate = null;
        let sDate = null;
        if(stDateRentalRef1.current){
            sDate = stDateRentalRef1.current.flatpickr.selectedDates[0];
            // date1 = stDate;
        }
        if(endDateRentalRef1.current){
            eDate = endDateRentalRef1.current.flatpickr.selectedDates[0];
            //date2 = edDate;
        }

        if(toAddress){
            let reqObj:any = {};
            reqObj.arrivalDate = eDate?CommonUtil.getReservationDateString(eDate):"";
            reqObj.departureDate = sDate?CommonUtil.getReservationDateString(sDate):"";
            reqObj.dropLocationNearToPickUpLocation = true;
            reqObj.searchType = dbSearchType;
            reqObj.fromAddress = toAddress;
            reqObj.fromLatitude = toLatitude;
            reqObj.fromLongitude = toLongitude
            // reqObj.toAddress = toAddress;
            // console.log(reqObj);

            // let listAPI =  "api/session/search/public/";

            setStRentalDate(sDate?CommonUtil.getReservationDateString(sDate):"");
            setEndRentalDate(eDate?CommonUtil.getReservationDateString(eDate):"");

            var listAPI:string = "";
            if(CommonUtil.PUBLIC_SEARCH_DB){
                listAPI =  "api/session/search/public/";//+CommonUtil.getSaasOrgId();
            }else{
                listAPI =  "api/search/private?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onGetSearchRentalRecords,onLoginError);
        }

    }
    let onClickEquipTypeDropdown = ()=>{
        if($("#equipDropDown").hasClass("show")){
            $("#equipDropDown").removeClass("show");
        }else{
            $("#equipDropDown").addClass("show");
        }
    }

    let onClickModalYearDropdown = ()=>{
        if($("#equipModalYear").hasClass("show")){
            $("#equipModalYear").removeClass("show");
        }else{
            $("#equipModalYear").addClass("show");
        }
    }

    let onClickAxiesDropdown = ()=>{
        if($("#equipAxies").hasClass("show")){
            $("#equipAxies").removeClass("show");
        }else{
            $("#equipAxies").addClass("show");
        }
    }

    let onClickLengthDropdown = ()=>{
        if($("#equipLength").hasClass("show")){
            $("#equipLength").removeClass("show");
        }else{
            $("#equipLength").addClass("show");
        }
    }

    let handleClickOutside = (event: any)=>{
        if (equipDropDown.current && !equipDropDown.current.contains(event.target)) {
            $("#equipDropDown").removeClass("show");
        }
        if (equipModalYear.current && !equipModalYear.current.contains(event.target)) {
            $("#equipModalYear").removeClass("show");
        }
        if (equipAxies.current && !equipAxies.current.contains(event.target)) {
            $("#equipAxies").removeClass("show");
        }
        if (equipLength.current && !equipLength.current.contains(event.target)) {
            $("#equipLength").removeClass("show");
        }
    }
    let onChangeEquipType = (chk:any)=>{
       /* equiptyes = [chkDryVan,chkLog,chkDropDeck,chkFlatBed,chkReeferVan,chkStorageVan,chkPlateDryVan,chkHeatedVan,chkScrapMetal,
            chkRecycling,chkChip,chkDump,chkLowbed,chkChassis,chkTank,chkTankCrude,chkTankDryBulk,chkTankPetroleum,
            chkTankPropane,chkTankChemical,chkOther,chkSpotter];*/

        let flag:boolean = false;
        if(chk && chk.current){
             flag = false;
            if(chk.current.checked){
                flag = true;
            }
        }
        equiptyes.forEach((cItem:any,cIdx:number)=>{
            if(cItem.current){
                cItem.current.checked = flag;
            }
        });
    }
    let onChangeEquipTypeDryVan = (chkDry:any)=>{
        if(chkDry && chkDry.current){
            if(chkSelectAll && chkSelectAll.current){
                chkSelectAll.current.checked = false;
            }
        }
    }
    let clearGoogleMarkers = ()=>{
        for(var j=0;j<markers.length;j++){
            markers[j].setMap(null);
        }
        for(let z1=0;z1<repNMarkers.length;z1++){
            repNMarkers[z1].setMap(null);
        }
        if(marker){
            marker.setMap(null);
        }
        if(markerCluster){
            markerCluster.setMap(null);
        }
        markerCluster = null;
        markers = [];
        if(renderer){
            renderer.setMap(null);
        }
        renderer = null;
    }

    let showFilterMarkers = (filterData:any)=>{
        clearGoogleMarkers();
        repPoints = [];
        for (let x1 = 0; x1 < filterData.length; x1++) {
            //let gItem: any = copySearchData[x1];
            //myLatlng = new google.maps.LatLng(copySearchData[x1].departureLatitude, copySearchData[x1].departureLongitude);

            //latlngbounds.extend(myLatlng);
        }


        var latlngbounds = new google.maps.LatLngBounds();
        var myLatlng: any = null;
        markers = [];

        markerCluster = new MarkerClusterer({});
       // markerCluster.setMap(gMap1);
        let gIcon:any = null;
        for (let x1 = 0; x1 < filterData.length; x1++) {
           // let tip = filterData[i].equipmentType;
            let gItem = filterData[x1];
            if(gItem){
               // gIcon = showAvailableCount(gItem.availableQuantity?gItem.availableQuantity:"1");
                let tip = gItem.departureAddress;
                myLatlng = new google.maps.LatLng(gItem.departureLatitude, gItem.departureLongitude);
               // let marker = new google.maps.Marker({position: myLatlng, map: gMap1, title:tip,icon:gIcon});
                //markerCluster.addMarker(marker);
               // markers.push(marker);
                latlngbounds.extend(myLatlng);
                filterData[x1].consolidate = false;
                filterData[x1].lat =  filterData[x1].departureLatitude;
                filterData[x1].lng =  filterData[x1].departureLongitude;
                filterData[x1].availCount =  filterData[x1].availableQuantity?filterData[x1].availableQuantity:1;
                repPoints.push(filterData[x1]);
            }
        }
        showRepMarkers();
        var mapCenter = latlngbounds.getCenter();
        if (latlngbounds && copySearchData.length > 0) {
            if (gMap1) {
                //google.maps.event.clearInstanceListeners(gMap1);
                gMap1.fitBounds(latlngbounds);
            }
            setTimeout(()=>{
                Is_FILTER = true;
            },5000);
        }
    }

    let onClickApplyEquipTypeClear = ()=>{
        equipmentTypeFilter = false;
        equiptyes.forEach((cItem:any,cIdx:number)=>{
            if(cItem.current){
                cItem.current.checked = false;
            }
        });
        if(chkSelectAll && chkSelectAll.current){
            chkSelectAll.current.checked = false;
        }
        equiptyes.forEach((cItem:any,cIdx:number)=>{
            if(cItem.current){
                selEquipTypes.push(cItem.current.value);
            }
        });
        $("#equipDropDown").removeClass("show");
        onFilterData();
    }
    let onClickApplyEquipType = ()=>{
        equipmentTypeFilter = true;
        $("#equipDropDown").removeClass("show");
        selEquipTypes = [];
        let selEquipType = "";
        onFilterData();
    }
    let onClickApplyAxies = ()=>{
        axlesCountFilter = true;
        $("#equipAxies").removeClass("show");
        onFilterData();
    }

    let onClickClearAxies = ()=>{
        axlesCountFilter = false;
        $("#equipAxies").removeClass("show");
        if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
            minAxies.current.value = "";
            maxAxies.current.value = "";
        }
        onFilterData();
    }

    let onClickApplyModal = ()=>{
        modelYearFilter = true;
        $("#equipModalYear").removeClass("show");
        onFilterData();
    }

    let onClickClearModal = ()=>{
        modelYearFilter = false;
        $("#equipModalYear").removeClass("show");
        if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value){
            minRef.current.value = "";
            maxRef.current.value = "";
        }
        onFilterData();
    }

    let clearAllFilters = ()=>{
        modelYearFilter = false;
        $("#equipModalYear").removeClass("show");
        if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value){
            minRef.current.value = "";
            maxRef.current.value = "";
        }
        axlesCountFilter = false;
        $("#equipAxies").removeClass("show");
        if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
            minAxies.current.value = "";
            maxAxies.current.value = "";
        }
        equipmentTypeFilter = false;
        equiptyes.forEach((cItem:any,cIdx:number)=>{
            if(cItem.current){
                cItem.current.checked = false;
            }
        });
        if(chkSelectAll && chkSelectAll.current){
            chkSelectAll.current.checked = false;
        }
        equiptyes.forEach((cItem:any,cIdx:number)=>{
            if(cItem.current){
                selEquipTypes.push(cItem.current.value);
            }
        });
        $("#equipDropDown").removeClass("show");
        filterData = [];
    }

    let onChangeMatches = (e:any)=>{
        //console.log(e.currentTarget.checked);
        newMatchesChecked = e.currentTarget.checked;
        onFilterData();
    }
    let onChangeSearch = (evt:any)=>{
        searchTextHere = evt.currentTarget.value;
        console.log(evt.currentTarget.value);
        onFilterData();
    }
    let onFilterData = ()=>{
        Is_FILTER = false;
        filterData = [];
        if(chkSelectAll.current){
            if(chkSelectAll.current.checked){
                equiptyes.forEach((cItem:any,cIdx:number)=>{
                    if(cItem.current){
                        selEquipTypes.push(cItem.current.value);
                    }
                });
            }else{
                equiptyes.forEach((cItem:any,cIdx:number)=>{
                    if(cItem.current && cItem.current.checked){
                        selEquipTypes.push(cItem.current.value);
                    }
                });
            }
        }

        let yearCondition:boolean = false;
        let equipTypeCondition:boolean = false;
        let axisCondition:boolean = false;
        if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value){
            yearCondition = true;
        }
        if(selEquipTypes.length>0){
            equipTypeCondition = true;
        }
        if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
            axisCondition = true;
        }

        copySearchData.forEach((item:any,idx:number)=>{
            if(item){
                if(equipTypeCondition && yearCondition && axisCondition){
                    let equipTypeExist:boolean = false;
                    for(let eq:number=0;eq<selEquipTypes.length;eq++){
                        if(item.equipmentType.toLowerCase() == selEquipTypes[eq].toLowerCase()){
                            equipTypeExist = true;
                            break;
                        }
                    }
                    if(equipTypeExist && minRef.current && minRef.current.value && maxRef.current && maxRef.current.value) {
                        if (Number(item.year) >= Number(minRef.current.value) && Number(item.year) <= Number(maxRef.current.value)) {
                            if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
                                if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                                    filterData.push(item);
                                }
                            }
                        }
                    }
                }else if(yearCondition && axisCondition){
                    if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value) {
                        if (Number(item.year) >= Number(minRef.current.value) && Number(item.year) <= Number(maxRef.current.value)) {
                            if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
                                if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                                    filterData.push(item);
                                }
                            }
                        }
                    }
                }else if(equipTypeCondition && yearCondition){
                    let equipTypeExist:boolean = false;
                    for(let eq:number=0;eq<selEquipTypes.length;eq++){
                        if(item.equipmentType.toLowerCase() == selEquipTypes[eq].toLowerCase()){
                            equipTypeExist = true;
                            break;
                        }
                    }
                    if(equipTypeExist && minRef.current && minRef.current.value && maxRef.current && maxRef.current.value) {
                        if (Number(item.year) >= Number(minRef.current.value) && Number(item.year) <= Number(maxRef.current.value)) {
                            filterData.push(item);
                        }
                    }
                }else if(equipTypeCondition && axisCondition){
                    let equipTypeExist:boolean = false;
                    for(let eq:number=0;eq<selEquipTypes.length;eq++){
                        if(item.equipmentType.toLowerCase() == selEquipTypes[eq].toLowerCase()){
                            equipTypeExist = true;
                            break;
                        }
                    }
                    if(equipTypeExist && minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value) {
                        if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                            filterData.push(item);
                        }
                    }
                }else if(yearCondition){
                    if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value) {
                        if (Number(item.year) >= Number(minRef.current.value) && Number(item.year) <= Number(maxRef.current.value)) {
                            filterData.push(item);
                        }
                    }
                }else if(axisCondition){
                    if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
                        if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                            filterData.push(item);
                        }
                    }
                }else if(equipTypeCondition){
                    let equipTypeExist:boolean = false;
                    for(let eq:number=0;eq<selEquipTypes.length;eq++){
                        if(item.equipmentType.toLowerCase() == selEquipTypes[eq].toLowerCase()){
                            equipTypeExist = true;
                            break;
                        }
                    }
                    if(equipTypeExist){
                        filterData.push(item);
                    }
                }else{
                    filterData.push(item);
                }
            }
        });

        if(dbSearchType == REPOSITION){
            let matchesFilter:any = [];
            newMatchesChecked = matchCountChecked();
            if(newMatchesChecked){
                filterData.forEach((fItem:any,fIdx:Number)=>{
                    //if(!fItem.withInAllowedDeviation){
                    matchesFilter.push(fItem);
                    //}
                });

                filterData = matchesFilter;
                //setMatchesCount(filterData.length);
            }else{
                filterData.forEach((fItem:any,fIdx:Number)=>{
                    if(fItem.withInAllowedDeviation){
                        matchesFilter.push(fItem);
                    }
                });

                filterData = matchesFilter;
            }
        }


        let beforeFilterData:any = [];
        let afterFilterData:any = [];
        beforeFilterData = [...filterData];



        /*if(searchTextHere){
            let filterSearchItems:any = [];
            beforeFilterData.forEach((fItem:any,fIdx:Number)=>{
                let isExist:boolean = false;
                searchTextHere = searchTextHere.toLowerCase();
                if(fItem.equipmentType && fItem.equipmentType.toString().toLowerCase().indexOf(searchTextHere)>=0){
                    //filterSearchItems.push(fItem);
                    isExist = true;
                }
                if(!isExist){
                    if(fItem.numberOfAxles && fItem.numberOfAxles.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.lengthOfTrailer && fItem.lengthOfTrailer.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.year && fItem.year.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureAddress && fItem.departureAddress.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureAddress && fItem.departureAddress.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureLatitude && fItem.departureLatitude.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.availabilityType && fItem.availabilityType.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.unitNumber && fItem.unitNumber.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.vin && fItem.vin.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.arrivalAddress && fItem.arrivalAddress.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureDate && fItem.departureDate.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.dropToTargetDistance && fItem.dropToTargetDistance.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.arrivalDate && fItem.arrivalDate.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureDate && fItem.departureDate.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(!isExist){
                    if(fItem.departureDate && fItem.departureDate.toString().toLowerCase().indexOf(searchTextHere)>=0){
                        isExist = true;
                    }
                }
                if(isExist){
                    filterSearchItems.push(fItem);
                }
            });
            filterData = filterSearchItems;
        }else{
            filterData = beforeFilterData;
        }*/

        setSearchData(filterData);
        //copySearchData = filterData;
        console.log(filterData);
        showFilterMarkers(filterData);

        /*let yearCondition:boolean = false;
        let axisCondition:boolean = false;
        if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value){
            yearCondition = true;
        }
        if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
            axisCondition = true;
        }



        copySearchData.forEach((item:any,idx:number)=>{
            if(item){
                selEquipTypes.forEach((eqType:any,eqIdx:number)=>{
                    if(item.equipmentType.toLowerCase() == eqType.toLowerCase()){
                        if(yearCondition && axisCondition){
                            if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value){
                                if(Number(item.year)>=Number(minRef.current.value) && Number(item.year)<=Number(maxRef.current.value)){
                                    if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
                                        if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                                            filterData.push(item);
                                        }
                                    }
                                }
                            }
                        }else if(yearCondition){
                            if(minRef.current && minRef.current.value && maxRef.current && maxRef.current.value) {
                                if (Number(item.year) >= Number(minRef.current.value) && Number(item.year) <= Number(maxRef.current.value)) {
                                    filterData.push(item);
                                }
                            }
                        }else if(axisCondition){
                            if(minAxies.current && minAxies.current.value && maxAxies.current && maxAxies.current.value){
                                if(Number(item.numberOfAxles)>=Number(minAxies.current.value) && Number(item.numberOfAxles)<=Number(maxAxies.current.value)){
                                    filterData.push(item);
                                }
                            }
                        }else{
                            filterData.push(item);
                        }
                    }
                });
            }
        });
        setSearchData(filterData);*/
    }

    let clearTextField = ()=>{
        $("#txtSearchPanel").val("");
        searchTextHere = "";
    }
    let onClickMakeOffer = ()=>{
        setShowOfferPopup(true);
        setNumDayReposPopup("");
        setDailyPricePopup("");
        setDisPricePopup("");
        setPaidPricePopup("");
        setAdditionalPricePopup("");
        setEstimatedDistancePopup("");
        setPricePerMilePopup("");
        setEngineHoursPopup("");
        setNoOfDaysPopup("");
        //getPartkingLots();
        setTimeout(()=>{
            if(stDateRef1.current && availabilityData){
                stDateRef1.current.flatpickr.setDate(new Date(availabilityData.startDate));
                stDateRef1.current.flatpickr.set("minDate",new Date(availabilityData.startDate));
                stDateRef1.current.flatpickr.set("maxDate",new Date(availabilityData.endDate));
            }
            if(endDateRef1.current && availabilityData){
                endDateRef1.current.flatpickr.setDate(new Date(availabilityData.endDate));
                endDateRef1.current.flatpickr.set("minDate",new Date(availabilityData.startDate));
                endDateRef1.current.flatpickr.set("maxDate",new Date(availabilityData.endDate));
            }
            showHideOfferFields();
        },500);

    }
    let showHideOfferFields = ()=>{
        let arr = ['divNumOfDays','divAvailEquips','divDailyRate','divDistanceRate','divPaidPrice','divAdditionalPrice','divEstimatedDistance','divPricePerMail',
            'divNumOfDays1','divAvailEquips1','divDailyRate1','divDistanceRate1','divPaidPrice1','divAdditionalPrice1','divEstimatedDistance1','divPricePerMail1','divEngineHours','divEngineHours1','divDistanceToTravel','divEngineHoursUnit'];
        arr.forEach((item:any,idx:number)=>{
            $("#"+item).hide();
        });

        if(CommonUtil.EQUIP_RESERVE_IS_GENERIC == "Y"){
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                $("#divAvailEquips").show();
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
                $("#divAvailEquips1").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                $("#divAvailEquips").show();
                $("#divDailyRate").show();
                $("#divDistanceRate").show();
                $("#divAvailEquips1").show();
                $("#divDailyRate1").show();
                $("#divDistanceRate1").show();
                $("#divDistanceToTravel").show();
                if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    $("#divEngineHoursUnit").show();
                }
            }
        }else{
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
                $("#divEstimatedDistance").show();
                $("#divPricePerMail").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
                $("#divEstimatedDistance1").show();
                $("#divPricePerMail1").show();
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                $("#divDailyRate").show();
                $("#divDistanceRate").show();
                $("#divDailyRate1").show();
                $("#divDistanceRate1").show();
                $("#divDistanceToTravel").show();
                if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    $("#divEngineHoursUnit").show();
                }
            }
        }

    }

    let onClickLink = (item:any)=>{
        console.log(item);
        /*availabilityId = data.availabilityId;//availId;
        isGenericEquip = data.isGenericEquipment;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = data.isGenericEquipment;
        CommonUtil.SELECT_EQUIPMENT_TYPE = data.equipmentType;
        getAvailabilityDetails();
        getOrgServiceFees();*/

        onClickShowAvailability(item);

    }

    let onClickBookItem = (item:any)=>{
        console.log(item);
        localStorage.setItem("PublicViewTab","true");
        localStorage.setItem("offerPopup","false");
        showAvailDetails(item);
        /*localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        let siteOrigin:string = window.location.origin;
        let equipId:string = item.equipmentId;
        let avaiId:string = item.availabilityId;
        let isGeneric:string = item.isGenericEquipment;
        let equipType:string =item.equipmentType;

        siteOrigin = siteOrigin+"/?requestor=equippublicview&equipId="+equipId;//+"&token="+ localStorage.getItem("token");
        siteOrigin = siteOrigin+"&avaiId="+ avaiId;
        siteOrigin = siteOrigin+"&isGeneric="+ isGeneric;
        siteOrigin = siteOrigin+"&equipType="+ equipType;
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();*/
    }

    let onClickShowAvailability = (item:any)=>{
        console.log(item);
        console.log(searchType);
        localStorage.setItem("PublicViewTab","true");
        localStorage.setItem("offerPopup","true");
        showAvailDetails(item);
        /*localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        let siteOrigin:string = window.location.origin;
        let equipId:string = item.equipmentId;
        let avaiId:string = item.availabilityId;
        let isGeneric:string = item.isGenericEquipment;
        let equipType:string =item.equipmentType;

        siteOrigin = siteOrigin+"/?requestor=equippublicview&equipId="+equipId;//+"&token="+ localStorage.getItem("token");
        siteOrigin = siteOrigin+"&avaiId="+ avaiId;
        siteOrigin = siteOrigin+"&isGeneric="+ isGeneric;
        siteOrigin = siteOrigin+"&equipType="+ equipType;
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();*/

    }
    let showAvailDetails = (item:any)=>{
        let stDate = null;
        let endDate = null;
        if(searchType == REPOSITION){
            if(stDateRef1.current){
                stDate = stDateRef1.current.flatpickr.selectedDates[0];
            }
            if(endDateRef1.current){
                endDate = endDateRef1.current.flatpickr.selectedDates[0];
            }
        }else{
            if(stDateRentalRef1.current){
                stDate = stDateRentalRef1.current.flatpickr.selectedDates[0];
            }
            if(endDateRentalRef1.current){
                endDate = endDateRentalRef1.current.flatpickr.selectedDates[0];
            }
        }
        localStorage.setItem("startDate",(stDate?CommonUtil.getReservationDateString(stDate):""));
        localStorage.setItem("endDate",(endDate?CommonUtil.getReservationDateString(endDate):""));

        localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        let siteOrigin:string = window.location.origin;
        let equipId:string = item.equipmentId;
        let avaiId:string = item.availabilityId;
        let isGeneric:string = item.isGenericEquipment;
        let equipType:string =item.equipmentType;

        siteOrigin = siteOrigin+"/?requestor=equippublicview&equipId="+equipId;//+"&token="+ localStorage.getItem("token");
        siteOrigin = siteOrigin+"&avaiId="+ avaiId;
        siteOrigin = siteOrigin+"&isGeneric="+ isGeneric;
        siteOrigin = siteOrigin+"&equipType="+ equipType;
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();
        return;

    }
    let getAvailabilityDetails = ()=>{
        CommonUtil.EQUIP_AVAIL_TYPE = dbSearchType;
        if(availabilityId){
            let parkingAPI:string = "api/availability/"+availabilityId+"?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetAvailabilityDetails,onLoginError);
        }
    }

    let onGetAvailabilityDetails = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj){
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                availabilityData = restData;
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;


                setAVailType(restData.listingType);

                seAvailtStDate(restData.startDate);
                setAvailEndDate(restData.endDate);

                setStDate1(restData.startDate);
                setEndDate1(restData.endDate);

                setArrival(restData.arrivalParkingLotAddress);
                setDeparture(restData.departureParkingLotAddress);

                setDateString(restData.startDate+" - "+restData.endDate);
                setDailyRate(restData.dailyRate);
                //dailyPriceRate = Number(restData.dailyRate);
                setDistanceRate(restData.distanceRate);
                //distancePriceRate = Number(restData.distanceRate);
                setQuantity(restData.quantity);
                setAllowOffer(restData.allowOffer);
                setMovePrice(restData.movePrice);

                //enginePrice = restData.engineRate;
                setEngineRate(restData.engineRate);

                setNoOfDays(restData.noOfDaysAllowed);
                setPaidPrice(restData.movePrice);

                setEDisRate(restData.estimatedDistance);
                setPriceKm(restData.pricePerKm);
                setAdditionalPrice(restData.dailyRate);

                onClickMakeOffer();

            }
        }

    }
    let getOrgServiceFees = ()=>{
        let listAPI:string = "api/organization/getServiceFees?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetEquipmentTypePricing,onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwner(restData.ownerRentalPercentage);
                setRenter(restData.renterRentalPercentage);
                setCAD(restData.ownerRepositioningMinimumCost);
                setPercent(restData.ownerRepositioningPercentage);
                setClaims(restData.claimFeePercentage);
            }
        }

    }
    let onDevOfferShowPopup = ()=>{
        setTimeout(function () {
            setPopupCenter('devOfferPopup', 'devOfferContentPopup');
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }
    let onCloseOfferPopup = ()=>{
        setShowOfferPopup(false);
    }
    let onClickAcceptOffer = ()=>{
        if(endDate2 && stDate2){
            let restObj:any = {};
            restObj.distanceUnit = "";
            restObj.endDate = endDate2;
            restObj.equipmentListingId = availabilityId;
            restObj.estimatedDistancePerDay = distanceToTravelPopup;
            restObj.estimatedEngineHoursPerDay = engineHoursUnitPopup;
            restObj.quantity = noOfDaysPopup;
            restObj.renterOrgId = CommonUtil.getSaasOrgId();
            restObj.serviceFeePercentage = renter;
            restObj.startDate = stDate2;
            restObj.movePrice = paidPricePopup;
            restObj.noOfDaysAllowed = numDayReposPopup;
            restObj.dailyRate = dailyPricePopup;
            restObj.distanceRate = disPricePopup;
            restObj.estimatedDistance = estimatedDisPopup;
            restObj.engineRate = engineRatePopup;

            if(additionalPricePopup){
                restObj.dailyRate = additionalPricePopup;
            }
            if(pricePerMilePopup){
                restObj.distanceRate = pricePerMilePopup;
            }

            let flag:boolean = true;
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                let days = CommonUtil.getDays(stDate2,endDate2);
                if(Number(numDayReposPopup)>days){
                    flag = false;
                }
            }

            if(flag){
                let parkingAPI:string = "api/offers/";//?saasOrgId="+CommonUtil.getSaasOrgId();
                //console.log("create offer");
                NetworkManager.sendJsonRequest(parkingAPI,restObj,"","POST",true,onOfferCreate,onLoginError);
            }else{
                let msg = CommonUtil.getMessageText(Constants.NUM_OF_DAY_ALLOWED, "Number of days allowed for repositioning is less than or equal to between days");
                ToastManager.showToast(msg, 'Error')
            }
        }
    }

    let onOfferCreate = (dataObj:any)=>{
        // console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setShowOfferPopup(false);
                let strOffer = CommonUtil.getMessageText(Constants.OFFER_CREATE_SUCCESS, "Offer created Successfully");
                ToastManager.showToast(strOffer, 'Info');
            }
        }
        Events.dispatchOfferEVent();
    }
    let onChangeStDate2 = (dateValue:any)=>{
        if(stDateRef1.current){
            let strDate:string = CommonUtil.getDate(dateValue);
            setStDate2(strDate);
            onChangeDates2();
        }
    }
    let onChangeEdDate2 = (dataVal:any)=>{
        let strDate:string = CommonUtil.getDate(dataVal);
        setEndDate2(strDate);
        onChangeDates1();
    }
    let onChangeDates1 = ()=>{
        if(stDateRef1.current && endDateRef1.current){
            var startDate1 = stDateRef1.current.flatpickr.selectedDates[0];
            var endDate1 = endDateRef1.current.flatpickr.selectedDates[0];

            if (endDate1) {
                endDate1 = new Date(endDate1);
                stDateRef1.current.flatpickr.set("maxDate",endDate1);
                // stDtOptions.maxDate = new Date(endDate);
            } if (startDate1) {
                endDateRef1.current.flatpickr.set("minDate",new Date(startDate1));
                //stDtOptions.minDate = new Date(startDate);
            }

            setTimeout(()=>{
                //var startDate1 = stDateRef.current.flatpickr.selectedDates[0];
                // var endDate1 = endDateRef.current.flatpickr.selectedDates[0];

                let days = CommonUtil.getDays(startDate1,endDate1);//endMs-stMs)/(24*3600*1000);
                //days = Math.ceil(days);
                //setDays(days);

                //calculatePrice();
            },1000);
        }
    }

    let onChangeDates2 = ()=>{
        if(stDateRef2.current && edDateRef2.current){
            // stDateRef.current.flatpickr.set("minDate",new Date());
            //endDateRef.current.flatpickr.set("minDate",new Date());

            var startDate = stDateRef2.current.flatpickr.selectedDates[0];
            var endDate = edDateRef2.current.flatpickr.selectedDates[0];

            edDateRef2.current.flatpickr.set("minDate",new Date(startDate));
            stDateRef2.current.flatpickr.set("maxDate",new Date(endDate));

            if (startDate) {

                //stDtOptions.minDate = new Date(startDate);
            }
            if (endDate) {
                // endDate = new Date(endDate);

                // stDtOptions.maxDate = new Date(endDate);
            }
        }
    }

    let onOpenStDateAvail = ()=>{
        if(stDateRef2.current){
            if(edDateRef2.current){
                var endDate = edDateRef2.current.flatpickr.selectedDates[0];
                if(endDate){
                    stDateRef2.current.flatpickr.set("maxDate",new Date(endDate));
                }else{
                    stDateRef2.current.flatpickr.set("minDate",new Date(stDate));
                }

            }else{
                stDateRef2.current.flatpickr.set("minDate",new Date(stDate));
            }

        }
    }
    let onOpenEDDateAvail = ()=>{
        if(edDateRef2.current){
            if(stDateRef2.current){
                var startDate = stDateRef2.current.flatpickr.selectedDates[0];
                if(startDate){
                    edDateRef2.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    edDateRef2.current.flatpickr.set("minDate",new Date(endDate));
                }

            }else{
                edDateRef2.current.flatpickr.set("minDate",new Date(endDate));
            }

        }
    }

    let onLoginError = (data:any)=>{
        console.log(data);
    }
    return(
        <>
            <Modal  show={showOfferPopup} dialogAs={DraggableComponent} id="devOfferPopup" onShow={onDevOfferShowPopup}>
                <div className="modal-content" id="devOfferContentPopup" style={{"width": "800px","height":"650px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?CommonUtil.getMessageText(Constants.OFFER_DETAILS_REPOSITION,"Offer Details - Reposition"):CommonUtil.getMessageText(Constants.OFFER_DETAILS_RENTAL,"Offer Details - Rental")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginLeft:"0px"}} onClick={onCloseOfferPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black" style={{overflow:"auto"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <p>{CommonUtil.getMessageText(Constants.OFFER_RECEIVED_FROM,"Offer received from")}: <strong>{CommonUtil.getSelectedOrgName()}</strong></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 border-right" style={{paddingRight:"25px"}}>
                                <h6 className="text-center">{CommonUtil.getMessageText(Constants.AVAILABILITY_DETAILS,"Availability Details")}</h6>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DATES,"Dates")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT,"Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={stDate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={endDate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divNumOfDays"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED,"Days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input type="text" disabled={true} className="form-control" required value={noofDays} />
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAvailEquips"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP,"Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input type="text" disabled={true} className="form-control" required value={quantity} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOCATION,"Location")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking")}
                                                Lot</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={departure} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT,"Arrival Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={arrival} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COST,"Cost")}:</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row" id={"divDailyRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={dailyRate} readOnly/></div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={distanceRate} readOnly/></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row" id={"divPaidPrice"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE,"Paid price carrier for Repositioning")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={paidPrice} readOnly/></div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE,"Additional Price per Excess Days")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={addtionalPrice} readOnly/></div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE,"Estimated distance in miles")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={eDisRate} readOnly/></div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE,"Price per mile")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={priceKm} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHours"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Hours")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required value={engineRate} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6 className="text-center">{CommonUtil.getMessageText(Constants.OFFER_DETAILS,"Offer Details")}</h6>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Dates</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className="form-group row input-highlighted">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT,"Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <Flatpickr  placeholder="Start Date" ref={stDateRef2} onChange={onChangeStDate2}
                                                                options={CommonUtil.DATE_FORMAT} className="form-control flat-picker"
                                                                onOpen={onOpenStDateAvail}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{display:"none"}}></i>
                                                    <Flatpickr  placeholder="End Date" ref={edDateRef2} onChange={onChangeEdDate2}
                                                                options={CommonUtil.DATE_FORMAT} className="form-control flat-picker"
                                                                onOpen={onOpenEDDateAvail}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted" id={"divNumOfDays1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED,"Number of days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input ref={numDayReposRef} type="number" id="startthree" name="trip-start"
                                                       className="form-control" required value={numDayReposPopup} min={0}
                                                       onChange={(e)=>{setNumDayReposPopup(e.currentTarget.value);CommonUtil.isMandatory(numDayReposRef);}}
                                                />
                                            </div>

                                        </div>
                                        <div className="form-group row input-highlighted" id={"divAvailEquips1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP,"Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input ref={numDayRef} type="number"
                                                       className="form-control" required value={noOfDaysPopup} min={0}
                                                       onChange={(e)=>{setNoOfDaysPopup(e.currentTarget.value);CommonUtil.isMandatory(numDayRef);}}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Location</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required
                                                           value={departure} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT,"Arrival Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{display:"none"}}></i>
                                                    <input type="text" className="form-control" required value={arrival} readOnly/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{paddingLeft:"20px",visibility:"hidden"}}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COST,"Cost")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"30px"}}>
                                        <div className="form-group row input-highlighted" id={"divDailyRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><input ref={dailyRateRef} type="number"
                                                            className="form-control" required value={dailyPricePopup} min={0}
                                                            onChange={(e)=>{setDailyPricePopup(e.currentTarget.value);CommonUtil.isMandatory(dailyRateRef);}}
                                                />


                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted" id={"divDistanceRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><input ref={distanceRef} type="number" min={0}
                                                            className="form-control" required value={disPricePopup}
                                                            onChange={(e)=>{setDisPricePopup(e.currentTarget.value);CommonUtil.isMandatory(distanceRef);}}
                                                />

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12" style={{paddingLeft:"40px"}}>
                                        <div className="form-group row" id={"divPaidPrice1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE,"Paid price carrier for Repositioning")}:</label>
                                            <div className="col-sm-12">
                                                <div><input ref={paidPricePopupRef} type="number" className="form-control" required min={0}
                                                            value={paidPricePopup} onChange={(e)=>{setPaidPricePopup(e.currentTarget.value);CommonUtil.isMandatory(paidPricePopupRef);}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE,"Additional Price per Excess Days")}:</label>
                                            <div className="col-sm-12">
                                                <div><input ref={additionalPricePopupRef} type="number" className="form-control" required min={0}
                                                            value={additionalPricePopup} onChange={(e)=>{setAdditionalPricePopup(e.currentTarget.value);CommonUtil.isMandatory(additionalPricePopupRef);}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE,"Estimated distance in miles")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="number" ref={estimatedDisPopupRef} className="form-control" required min={0}
                                                            value={estimatedDisPopup} onChange={(e)=>{setEstimatedDistancePopup(e.currentTarget.value);CommonUtil.isMandatory(estimatedDisPopupRef);}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE,"Price per mile")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="number" ref={pricePerMilePopupRef} className="form-control" required min={0}
                                                            value={pricePerMilePopup} onChange={(e)=>{setPricePerMilePopup(e.currentTarget.value);CommonUtil.isMandatory(pricePerMilePopupRef);}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHours1"}>
                                            <label className="col-sm-12 col-form-label" style={{paddingLeft:"5px"}}>{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Hours")}:</label>
                                            <div className="col-sm-12" style={{paddingLeft:"5px"}}>
                                                <div><input ref={engineRatePopupRef} type="number" className="form-control" required min={0}
                                                            value={engineRatePopup} onChange={(e)=>{setEngineHoursPopup(e.currentTarget.value);CommonUtil.isMandatory(engineRatePopupRef);}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceToTravel"}>
                                            <label className="col-sm-12 col-form-label" style={{paddingLeft:"5px"}}>{CommonUtil.getMessageText(Constants.DISTANCE_TO_TRAVEL,"Distance to travel")}:</label>
                                            <div className="col-sm-12" style={{paddingLeft:"5px"}}>
                                                <div><input ref={distanceToTravelRef} type="number" className="form-control" required min={0}
                                                            value={distanceToTravelPopup} onChange={(e)=>{setDistanceToTravelPopup(e.currentTarget.value);CommonUtil.isMandatory(distanceToTravelRef);}} style={{width:"80%",display:"inline"}}/> km/day
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHoursUnit"}>
                                            <label className="col-sm-12 col-form-label" style={{paddingLeft:"5px"}}>{CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE,"Engine unit usage")}:</label>
                                            <div className="col-sm-12" style={{paddingLeft:"5px"}}>
                                                <div><input ref={engineHoursUnitRef} type="number" className="form-control" required min={0}
                                                            value={engineHoursUnitPopup} onChange={(e)=>{setEngineHoursUnitPopup(e.currentTarget.value);CommonUtil.isMandatory(engineHoursUnitRef);}} style={{width:"80%",display:"inline"}}/> h/day
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={onCloseOfferPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Close")}</button>
                        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={onClickAcceptOffer}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>
                    </div>
                </div>
            </Modal>

            <div className="page-content-wrapper" style={{overflow:"hidden"}}>
                <div className="row ml-sm-4 mr-sm-4" style={{paddingTop:"41px",display:(isPublicSearch?"":"none")}}>
                    <RegistrationHeader isLogin={"true"} isSearchDB={"false"} isSignUp={"true"} />
                </div>
                <div style={{overflow:"hidden"}} className="container-fluid " id="search-main-page" >
                    <div className="row p-0 m-0">
                        <div className="col-sm-12 p-0 m-0">
                            <div className="alert cus-alert show text-center m-0" role="alert" style={{display:"none"}}>
                                <div className="shade1  p-3">
                                    <h5 className="m-0" >More Repositioning Trailers options can be viewed if the date
                                        range is expanded and allowed deviation is increased.
                                        <i className="fa fa-times" aria-hidden="true" data-dismiss="alert" aria-label="Close"></i>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bg-white se-new">
                        <div className="col-lg-3 col-xl-2 border p-0">
                            <ul className="nav nav-tabs searchnav border-0 p-0 w-100" role="tablist">
                                <li className="nav-item d-lg-block ">
                                    <a className="nav-link active border-0" id={"navRepo"} data-toggle="tab" onClick={onClickRepSearch}
                                       role="tab"><i className="fa fa-map-o" aria-hidden="true"></i> | {CommonUtil.getMessageText(Constants.REPOSITIONING, "Repositioning")}  {repositionCount}</a>
                                </li>
                                <li className="nav-item  d-lg-block">
                                    <a className="nav-link border-0" id={"navRental"} data-toggle="tab"  role="tab" onClick={onClickRentalSearch}>
                                        <i className="fa fa-map-pin" aria-hidden="true"></i> | {CommonUtil.getMessageText(Constants.RENTAL_DB, "Rental")}  {renterCount}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-9 col-xl-10">
                            <div className="tab-content text-left height100">
                                <div className="tab-pane  active height100" id="RepositioningSearch" role="tabpanel">
                                    <div className="row height100" style={{paddingRight:"10px"}}>
                                        <div className="col-lg-6 col-xl-4 border height100 p-0">
                                            <div className="row icon-size m-0  pt-2 pb-2">
                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0"> <i className="fa fa-bullseye"></i> {CommonUtil.getMessageText(Constants.FROM_LOCATION, "From Location")}</label>
                                                    <div className="col-1"></div>
                                                    <div className="col-12" style={{paddingLeft:"15px"}}>
                                                        <div className="dated-i1">
                                                            <input type="text" ref={fromLocation1Ref} id={'fromLocation1'} style={{border:"0px"}} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 border height100 p-0">
                                            <div className="row icon-size m-0 pt-2 pb-2">
                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0"><i className="fa fa-map-marker"></i> {CommonUtil.getMessageText(Constants.TO_LOCATION, "To Location")}</label>
                                                    <div className="dated-i1" style={{paddingLeft:"15px"}}>
                                                        <input type="text" ref={toLocation1Ref} id={'toLocation1'} style={{border:"0px"}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 col-lg-8 col-xl-3  height100">
                                            <div className="row height100">
                                                <div className="col-sm-6 border height100 p-0">
                                                    <div className="row  m-0 pt-2 pb-2 icon-size">
                                                        <div className="col-12 pr-1 pl-1">
                                                            <label className="m-0"><i
                                                                className="fa fa-calendar"></i> {CommonUtil.getMessageText(Constants.DEPARTURE_DATE_DB, "Departure Date")}</label>
                                                            <div className="dated-i2 date-align">
                                                                <Flatpickr  placeholder="Start Date" ref={stDateRef1} options={dateOptions} onChange={onChangeStDate}
                                                                            onOpen={onOpenStDate1} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 border height100 p-0">
                                                    <div className="row  m-0 pt-2 pb-2 icon-size">
                                                        <div className="col-12 pr-1 pl-1">
                                                            <label className="m-0">
                                                                <i className="fa fa-calendar"></i> {CommonUtil.getMessageText(Constants.ARRIVAL_DATE_DB, "Arrival Date")}</label>
                                                            <div className="dated-i2" style={{paddingLeft:"30px"}}>
                                                                <Flatpickr  placeholder="End Date" ref={endDateRef1} options={dateOptions}  onChange={onChangeEdDate}
                                                                            onOpen={onOpenEDDate1} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4 col-xl-1 border p-0">
                                            <div className="row  m-0 pt-2 pb-2 icon-size">
                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0">
                                                        <i className="fas fa-route"></i>  {CommonUtil.getMessageText(Constants.DEVIATION, "Deviation")}</label>
                                                    <div className="dated-inum" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                        <input type="number" value={allowedDeviation} className="form-control" required
                                                              style={{color:"#000",width:"50px",boxShadow:"none"}} onChange={(e)=>{setAllowedDeviation(e.currentTarget.value)}}/>
                                                        &nbsp;&nbsp;<span style={{fontSize:"10px"}}> &nbsp;/{CommonUtil.getMessageText(Constants.MI, "MI")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer1">
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-8 col-sm-12 col-xl-8 p-0">
                                                <div className="row">
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-2" ><h6
                                                        className="m-0"><span style={{display:"none"}}>{CommonUtil.getMessageText(Constants.TOTAL_DRIVE_TIME, "Total Drive Time")}</span> &nbsp;{}
                                                    </h6></div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-0 height50">
                                                        <a href="#" style={{textDecoration:"none"}}>
                                                            <div className="half-circle" onClick={onRepSearch}>{CommonUtil.getMessageText(Constants.SEARCH, "Search")}<h6 className="mt-2">
                                                                <i className="fa fa-search"></i></h6>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-2" >
                                                        <h6 className="m-0"><span style={{display:"none"}}>Total Miles</span>&nbsp;{}</h6>
                                                    </div>
                                                    <div className="col-md-1"></div>
                                                </div>

                                            </div>
                                            <div className="col-2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane   height100 p-0" id="RentalSearch" role="tabpanel">
                                    <div className="row height100" style={{paddingRight:"10px"}}>
                                        <div className="col-lg-12 col-xl-8 border  height100 p-0">
                                            <div className="row icon-size pt-2 pb-2 m-0">
                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0"><i className="fa fa-bullseye"></i> {CommonUtil.getMessageText(Constants.FROM_LOCATION, "From Location")}</label>
                                                    <div className="dated-i1" style={{paddingLeft:"25px"}}>
                                                        <input type="text" id={'departureLocation1'} style={{border:"0px"}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-2 border  height100 p-0">
                                            <div className="row  icon-size m-0 pt-2 pb-2">

                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0"><i className="fa fa-calendar"></i> {CommonUtil.getMessageText(Constants.DEPARTURE_DATE_DB, "Departure Date")}</label>
                                                    <div className="dated-i2 date-align">
                                                        <Flatpickr  placeholder="Start Date" ref={stDateRentalRef1} options={dateOptions} onChange={onChangeRentalStDate}
                                                                    onOpen={onOpenRentalSDData1} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl  flat-picker"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-2 border  height100 p-0">
                                            <div className="row icon-size  m-0 pt-2 pb-2">

                                                <div className="col-12 pr-1 pl-1">
                                                    <label className="m-0"><i className="fa fa-calendar"></i> {CommonUtil.getMessageText(Constants.ARRIVAL_DATE_DB, "Arrival Date")}</label>
                                                    <div className="dated-i2 date-align">
                                                        <Flatpickr  placeholder="End Date" ref={endDateRentalRef1} options={dateOptions}  onChange={onChangeRentalEdDate}
                                                                    onOpen={onOpenRentalEDData1} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer1">
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-8 col-sm-12 col-xl-8 p-0">

                                                <div className="row">
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-2">
                                                        <h6 className="m-0" style={{display:"none"}}><span>Total Drive Time</span>6 hrs 45 minutes</h6>
                                                    </div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-0 height50 ">
                                                        <a href="#" style={{textDecoration:"none"}}>
                                                            <div className="half-circle" onClick={onRentalSearch}>{CommonUtil.getMessageText(Constants.SEARCH, "Search")}<h6 className="mt-2"><i
                                                                className="fa fa-search"></i></h6></div>
                                                        </a></div>
                                                    <div className="col-md-3 bg-primary text-white text-center p-2">
                                                        <h6 className="m-0" style={{display:"none"}}><span>Total Miles</span>89</h6>
                                                    </div>
                                                    <div className="col-md-1"></div>
                                                </div>
                                            </div>
                                            <div className="col-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pt-1 pb-1" >
                            {
                                CommonUtil.PUBLIC_SEARCH_DB?(<div className="responsive-map" style={{height:"82vh"}}>
                                    <div className="map-container">
                                        <div ref={mapRef2} id="mapRef2" className="map-container__map" style={{height:"82vh"}}></div>
                                    </div>
                                </div>):(<div className="responsive-map" style={{height:"76vh"}}>
                                    <div className="map-container">
                                        <div ref={mapRef2} id="mapRef2" className="map-container__map" style={{height:"76vh"}}></div>
                                    </div>
                                </div>)
                            }

                        </div>
                        <div className="position" style={{display:"none"}}>
                            <div className="saf">
                                <div className="row m-0">
                                    <div className="col-md-12 col-12">
                                        <div className="row m-0">
                                            <div className="col-md-5 col-12 navbuttons p-0 mt-1 text-center">
                                                <div id="one">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn-sm btn-secondary1 dropdown-toggle"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Equipment Type
                                                        </button>
                                                        <div className="dropdown-menu exceed1 equip-type-show" >

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:"none",overflowX:"hidden",paddingLeft:"2px",paddingRight:"2px"}} id={"pnlReposition"} className="container-fluid">
                    <div className="row d-block d-md-none">
                        <div className="position2">
                            <div className="row m-0">
                                <div className="col-md-1"></div>
                                <div className="col-md-2 bg-primary text-white text-center p-2"><h6 className="m-0">
                                    <span>Total Drive Time</span> 6 hrs 45 minutes</h6></div>
                                <div className="col-md-6 bg-danger text-white text-center p-2 ml-1 mr-1"><a
                                    className="text-white" href="search-dashboard-3.html"><h6
                                    className="mt-2">Repositions: City 1 to City 2 on Oct-15-2022 to Oct-17-2022</h6>
                                </a></div>
                                <div className="col-md-2 bg-primary text-white text-center p-2"><h6 className="m-0">
                                    <span>Total Miles</span> 395</h6></div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                    <div id="custom-wrapper">
                        <div className="custom-container">
                            <div className="main-content">
                                <div className="position2">
                                    <div className="row">
                                        <div className="col-md-0"></div>
                                        <div className="col-md-3 bg-primary text-white text-center p-1">
                                            {dbSearchType == REPOSITION?<h6 className="m-0"><span>{CommonUtil.getMessageText(Constants.TOTAL_DRIVE_TIME, "Total Drive Time")}</span> &nbsp;{totalDriveTime}</h6>:""}
                                        </div>
                                        <div className="col-md-6 bg-danger text-white text-center p-0">

                                            <a className="text-white" onClick={onClickSearchBack}>
                                                {
                                                    dbSearchType == REPOSITION?<h6 className="mt-1" style={{fontSize:"14px",paddingTop:"3px"}}><i className={"fa fa-search"}>&nbsp;</i>
                                                        {CommonUtil.getMessageText(Constants.REPOSITIONS, "Repositions")} : {fromCity} {CommonUtil.getMessageText(Constants.TO, "to")} {toCity} on {stDate?CommonUtil.getSearchDBDate(stDate):""} to {endDate?CommonUtil.getSearchDBDate(endDate):""}
                                                    </h6>:<h6 className="mt-1"><i className={"fa fa-search"}>&nbsp;</i>
                                                        {CommonUtil.getMessageText(Constants.RENTAL, "Rental")}: {fromCity}  {CommonUtil.getMessageText(Constants.ON, "on")} {stRentalDate?CommonUtil.getSearchDBDate(stRentalDate):""} {CommonUtil.getMessageText(Constants.TO, "to")} {endRentalDate?CommonUtil.getSearchDBDate(endRentalDate):""}
                                                    </h6>
                                                }

                                            </a>
                                        </div>
                                        <div className="col-md-3 bg-primary text-white text-center p-1">
                                            {dbSearchType == REPOSITION?<h6 className="m-0"><span>{CommonUtil.getMessageText(Constants.TOTAL_MILES_DB, "Total Miles")}</span> &nbsp;{totalMiles}</h6>:""}
                                        </div>
                                        <div className="col-md-0"></div>
                                    </div>
                                </div>
                                    <div id={"gmap-responsive"} className="responsive-map gmap-down-height">
                                        {/*<iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359832.842849046!2d-113.72795382611162!3d36.24549287140904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1664868051370!5m2!1sen!2sin"
                                            width="100%" height="100%" loading="lazy" referrerPolicy="no-referrer-when-downgrade">

                                        </iframe>*/}
                                        <div className="map-container">
                                            <div ref={mapRef1} id="mapRef1" className="map-container__map gmap-down-height"></div>
                                        </div>
                                    </div>
                                <div id="resizer">
                                    <div className="position" style={{paddingBottom:"0px"}}>
                                        <div className="saf">
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <div>
                                                                <button className="cus">
                                                                    <i id={"iconDown"} className="fa fa-angle-double-down" onClick={onClickDown}></i>
                                                                    <i id={"iconUp"} className="fa fa-angle-double-up" onClick={onClickUp}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-12 navbuttons p-0 mt-1">
                                                            <div id="one">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn-sm btn-secondary1 dropdown-toggle" style={{border:"0px"}}
                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={onClickEquipTypeDropdown}>{CommonUtil.getMessageText(Constants.EQUIP_TYPE, "Equipment Type")} +
                                                                    </button>
                                                                    <div className="dropdown-menu exceed1 equip-type-show" id="equipDropDown" style={{paddingBottom:"0px"}} ref={equipDropDown}>
                                                                        <ul className="select-list">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkSelectAll} value="SelectAll"
                                                                                                   onChange={()=>{onChangeEquipType(chkSelectAll)}} />
                                                                                            <label className="form-check-label" htmlFor="flexCheckIndeterminate">{CommonUtil.getMessageText(Constants.SELECT_ALL,"Select All")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value="Dry Van" ref={chkDryVan}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkDryVan)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.DRY_VAN,"Dry Van")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkPlateDryVan} value="Plate Dry Van"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkPlateDryVan)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.PLATE_DRY_VAN,"Plate Dry Van")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value="Storage Van" ref={chkStorageVan}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkStorageVan)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.STORAGE_VAN,"Storage Van")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkReeferVan} value="Reefer Van"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkReeferVan)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.REEFER_VAN,"Reefer Van")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkHeatedVan} value="Heated Van"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkHeatedVan)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.HEATED_VAN,"Heated Van")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkFlatBed} value="Flatbed"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkFlatBed)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.FLAT_BED,"Flatbed")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkDropDeck} value="Dropdeck"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkDropDeck)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.DROP_DECK,"Dropdeck")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkLog} value="Log"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkLog)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.LOG,"Log")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value="SCRAP METAL" ref={chkScrapMetal}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkScrapMetal)}} />
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.SCRAP_METAL,"Scrap Metal")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value="Recycling" ref={chkRecycling}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkRecycling)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.RECYCLING,"Recycling")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkChip} value="Chip"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkChip)}} />
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.CHIP,"Chip")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkDump} value="Dump"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkDump)}} />
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.DUMP,"Dump")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkLowbed} value="Lowbed"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkLowbed)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.LOWBED,"Lowbed")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkChassis} value="Chassis"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkChassis)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.CHASIS,"Chassis")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkTank} value="Tank"
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTank)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.TANK,"Tank")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkTankCrude} value={"Tank Crude"}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTankCrude)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.TANK_CRUDE,"Tank Crude")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value={"Tank Dry Bulk"} ref={chkTankDryBulk}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTankDryBulk)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.TANK_DRY_BULK,"Tank Dry Bulk")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" ref={chkTankPetroleum} value={"Tank Petroleum"}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTankPetroleum)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.TANK_PETROLEUM,"Tank Petroleum")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value={"Tank Propane"} ref={chkTankPropane}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTankPropane)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.TANK_PROPANE,"Tank Propane")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value={"Tank Chemical"} ref={chkTankChemical}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkTankChemical)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck1">{CommonUtil.getMessageText(Constants.TANK_CHEMICAL,"Tank Chemical")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value={"Other"} ref={chkOther}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkOther)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.OTHER,"Other")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <li>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" value={"SPOTTER"} ref={chkSpotter}
                                                                                                   onChange={()=>{onChangeEquipTypeDryVan(chkSpotter)}}/>
                                                                                            <label className="form-check-label" htmlFor="defaultCheck2">{CommonUtil.getMessageText(Constants.SPOTTER,"Spotter")}</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{paddingTop:"10px"}}>
                                                                                <div className="col-6"></div>
                                                                                <div className="col-6 text-right">
                                                                                    <a id="clear" onClick={onClickApplyEquipTypeClear}>{CommonUtil.getMessageText(Constants.CLEAR,"Clear")}</a>
                                                                                    &nbsp;&nbsp;
                                                                                    <button className="btn-sm btn-primary" type="submit" onClick={onClickApplyEquipType}>{CommonUtil.getMessageText(Constants.APPLY_CLOSE,"Apply & Close")}</button>
                                                                                </div>
                                                                            </div>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn-sm btn-secondary1 dropdown-toggle" style={{border:"0px"}}
                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={onClickModalYearDropdown}>{CommonUtil.getMessageText(Constants.MODEL_YEAR,"Model Year")} +
                                                                    </button>
                                                                    <div className="dropdown-menu exceed2 modal-year-show" id="equipModalYear" ref={equipModalYear}>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MINIMUM,"Minimum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div>
                                                                                            <input type="number" ref={minRef} className="form-control d-inline-block w-100 font-weight-bold"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MAXIMUM,"Maximum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div>
                                                                                            <input type="number" ref={maxRef} className="form-control d-inline-block w-100 font-weight-bold"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-6"></div>
                                                                            <div className="col-6 text-right">
                                                                                <a id="button1" onClick={onClickClearModal}>{CommonUtil.getMessageText(Constants.CLEAR,"Clear")}</a>
                                                                                &nbsp;&nbsp;
                                                                                <button className="btn-sm btn-primary" type="submit" onClick={onClickApplyModal}>{CommonUtil.getMessageText(Constants.APPLY_CLOSE,"Apply & Close")}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn-sm btn-secondary1 dropdown-toggle" style={{border:"0px"}}
                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={onClickAxiesDropdown}>{CommonUtil.getMessageText(Constants.AXIES_COUNT,"Axles Count")} +
                                                                    </button>
                                                                    <div className="dropdown-menu exceed2 modal-year-show" id="equipAxies" ref={equipAxies}>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MINIMUM,"Minimum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div><input ref={minAxies} type="number" className="form-control w-100 font-weight-bold"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MAXIMUM,"Maximum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div>
                                                                                            <input type="number" ref={maxAxies} className="form-control w-100 font-weight-bold" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-6"></div>
                                                                            <div className="col-6 text-right">
                                                                                <a id="button2" onClick={onClickClearAxies}>{CommonUtil.getMessageText(Constants.CLEAR,"Clear")}</a>
                                                                                &nbsp;&nbsp;
                                                                                <button className="btn-sm btn-primary" type="submit" onClick={onClickApplyAxies}>{CommonUtil.getMessageText(Constants.APPLY_CLOSE,"Apply & Close")}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                                <div className="btn-group" style={{display:"none"}}>
                                                                    <button type="button" className="btn-sm btn-secondary1 dropdown-toggle" style={{border:"0px"}}
                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={onClickLengthDropdown}>{CommonUtil.getMessageText(Constants.LENGTH,"Length")} +
                                                                    </button>
                                                                    <div className="dropdown-menu exceed2 modal-year-show" id="equipLength" ref={equipLength}>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MINIMUM,"Minimum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div><input type="number" className="form-control w-100 font-weight-bold"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MAXIMUM,"Maximum")}</label>
                                                                                    <div className="col-sm-12">
                                                                                        <div>
                                                                                            <input type="number" className="form-control w-100 font-weight-bold" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-6"></div>
                                                                            <div className="col-6 text-right">
                                                                                <a id="button2">{CommonUtil.getMessageText(Constants.CLEAR,"Clear")}</a>
                                                                                &nbsp;&nbsp;
                                                                                <button className="btn-sm btn-primary" type="submit">{CommonUtil.getMessageText(Constants.APPLY_CLOSE,"Apply & Close")}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 flex"
                                                             style={{justifyContent: "center",height:"30px"}}>
                                                            <div className="bg-danger border border-white text-white text-center"
                                                                 style={{cursor:"pointer",width:("100%")}} onClick={onClickBestMatches}>
                                                                {
                                                                    (dbSearchType == REPOSITION)?<h6 className="m-0 p-2" style={{fontSize:"13px"}}>{repositionBestCount} {CommonUtil.getMessageText(Constants.BEST_MATCHES,"Best Matches")}</h6>
                                                                        :<h6 className="m-0 p-2" style={{fontSize:"13px"}}>{renterBestCount} {CommonUtil.getMessageText(Constants.BEST_MATCHES,"Best Matches")}</h6>
                                                                }
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <div className="bg-danger border border-white text-white text-center pt-1 flex" id={"chkPanel"}
                                                                 style={{paddingLeft:"5px",paddingRight:"5px",width:("60%")}}>
                                                                <input type="checkbox" className="checkBox" id="chkMatch" onChange={(e)=>{onChangeMatches(e)}}  />
                                                                <h6 style={{fontSize:"13px",marginLeft:"5px",marginTop:"3px"}}>{CommonUtil.getMessageText(Constants.NEAR_MATCHES,"Near Matches")} - {matchesCount}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 mt-0" style={{height:"30px"}}>
                                                            <button className="btn-sm btn-primary " id="lnkTileView" type="submit" style={{border:"1px solid"}} onClick={onClickTileView}>{CommonUtil.getMessageText(Constants.TILE_VIEW,"Tile View")}</button>
                                                            &nbsp;
                                                            <button className="btn-sm " type="submit" id="lnkGridView" onClick={onClickGridView}>{CommonUtil.getMessageText(Constants.GRID_VIEW,"Grid View")}</button>
                                                            {/*<ul className="nav nav-tabs d-inline-block bg-primary" role="tablist" style={{display:"none"}}>
                                                                <li className="nav-item d-inline-block">
                                                                    <a className="nav-link active" data-toggle="tab" role="tab" style={{height:"30px"}}
                                                                       data-placement="top" title="Tile View" id="lnkTileView" onClick={onClickTileView}>
                                                                        <label>Tile View</label>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item d-inline-block">
                                                                    <a className="nav-link"  role="tab" data-toggle="tooltip" style={{height:"30px"}}
                                                                       data-placement="top" title="Grid View" id="lnkGridView" onClick={onClickGridView}>
                                                                        <span>Grid View</span>
                                                                    </a>
                                                                </li>
                                                            </ul>d-inline-block*/}

                                                            <form role="search" className="app-table-search  " style={{display:"none"}}>
                                                                <div className="form-group mb-0" id="pnlSearch" style={{paddingLeft:"50px"}}>
                                                                    <input type="text" id={"txtSearchPanel"} className="form-control" placeholder="Search here" onChange={(event)=>{onChangeSearch(event)}} />
                                                                </div>
                                                            </form>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={CommonUtil.PUBLIC_SEARCH_DB?"sidebarPublic":"sidebar"} id="sidebar">
                                <div className="m-1">
                                    <div className="scrollbar-one">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tab-content">
                                                    <div className="tab-pane active p-1" id="pnlTileView" role="tabpanel">
                                                        <div className="row">
                                                            {searchData.map((item: any, idx: number) => {
                                                                let tileId = "tile"+idx;
                                                                var list = (

                                                                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-2" style={{paddingRight:"0px"}}>
                                                                        <section className="res" id={"tile"+idx} style={{height:(dbSearchType == RENTAL?"240px":"280px")}}  onClick={()=>{onClickRepItem(item,tileId)}}>
                                                                            <div className="row">
                                                                                <div className="col-12 mb-2">
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <span className="details-head" style={{fontSize:"12px"}}><strong>{item.equipmentType}</strong>&nbsp;<br />{item.numberOfAxles?(item.numberOfAxles)+" AXLES" :""}  | {item.lengthOfTrailer?(item.lengthOfTrailer):""}  | {item.year?(item.year):""} </span>
                                                                                        </div>

                                                                                        <div className="col-6 text-right">
                                                                                            <StarRating value={item.equipmentRating} />{item.equipmentRating} | {item.ratingCount} {CommonUtil.getMessageText(Constants.RATING,"Ratings")}
                                                                                            <div className="rating" style={{display:"none"}}>
                                                                                                4 &nbsp;
                                                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                                                <i className="fa fa-star-o" aria-hidden="true"></i>
                                                                                                <label className={"available-count"} style={{display:"none"}}>{item.availableQuantity?(item.availableQuantity)+" Available":""}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 text-center">
                                                                                    <div className="row">
                                                                                        <div className="col-12 location1 p-0 ">
                                                                                            <i className="fa fa-map-marker"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 p-0 "><i
                                                                                            className="fa fa-map-marker"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                                                                                    <div className="row">
                                                                                        <div className="col-12 p-0"><span style={{fontWeight:"bold"}}>{CommonUtil.getDBDate(item.departureDate)}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            <br/>
                                                                                            <span style={{display:"none"}} title={item.departureAddress}>{CommonUtil.displayAddress(item.departureAddress)}</span>
                                                                                            <span>{item.departureCity},{item.departureState}</span>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row" style={{paddingTop:"10px"}}>
                                                                                        <div className="col-8 p-0 "><span style={{fontWeight:"bold"}}>{CommonUtil.getDBDate(item.arrivalDate)}</span><br/>
                                                                                            <span style={{display:"none"}} title={item.arrivalAddress}>{CommonUtil.displayAddress(item.arrivalAddress)}</span>
                                                                                            <span>{item.arrivalCity},{item.arrivalState}</span>
                                                                                        </div>
                                                                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style={{paddingRight:"10px", display:dbSearchType == REPOSITION?"":"none"}}>
                                                                                            <div className="col-12 bg-danger p-1 text-center text-white tile-font-size">{CommonUtil.getMessageText(Constants.REVENUE,"Revenue")}</div>
                                                                                            <div className="col-12 border  text-center box-height">  {item.movePrice}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div className="row mt-2" style={{display:dbSearchType == RENTAL?"":"none"}}>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}</div>
                                                                                    <div className="col-12 border  text-center box-height"> {item.dailyRate}</div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white" style={{fontSize:"11px"}}>{CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate")}</div>
                                                                                    <div className="col-12 border  text-center box-height">  {item.distanceRate}</div>
                                                                                </div>
                                                                                {
                                                                                    (item.engineRate)? <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                        <div className="col-12 bg-danger p-1 text-center text-white">{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate")}</div>
                                                                                         <div className="col-12 border  text-center box-height"> {item.engineRate}</div>
                                                                                     </div>:""
                                                                                }

                                                                            </div>
                                                                            <div className="row mt-2" style={{display:dbSearchType == REPOSITION?"":"none"}}>

                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size">{CommonUtil.getMessageText(Constants.REVENUE_PER_HOUR,"Rev per Hour")}
                                                                                    </div>
                                                                                    <div className="col-12 border  text-center box-height">  {item.revenuePerHour}</div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size">{CommonUtil.getMessageText(Constants.EST_DRIVE_HRS,"Est. Drive Hrs")}</div>
                                                                                    <div className="col-12 border  text-center box-height">{item.totalTravelTime}</div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size">{CommonUtil.getMessageText(Constants.TOTAL_KM,"Tot. Distance (KM)")}</div>
                                                                                    <div className="col-12 border  text-center box-height">{item.totalDistanceKM}</div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size" style={{fontSize:"8px"}}>{CommonUtil.getMessageText(Constants.BOBTAIL_TO_PU,"Bobtail to Pu (KM)")}</div>
                                                                                    <div className="col-12 border  text-center box-height">{item.bobtailToPuDistanceKM}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size" style={{fontSize:"8px"}}>{CommonUtil.getMessageText(Constants.DROP_TO_TARGET_KM,"Drop to Target (KM)")}</div>
                                                                                    <div className="col-12 border  text-center box-height">{item.dropToTargetDistanceKM}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                                                                                    <div className="col-12 bg-danger p-1 text-center text-white tile-font-size" style={{fontSize:"8px"}}>{CommonUtil.getMessageText(Constants.HOOK_KM,"Hook (KM)")}</div>
                                                                                    <div className="col-12 border text-center box-height">{item.hookDistanceKM}</div>
                                                                                </div>
                                                                            </div>
                                                                        </section>

                                                                            <div className={"bottom-panel"}>
                                                                            <button type="button" onClick={()=>{onClickBookItem(item)}} className="btn btn-primary waves-effect" data-dismiss="modal"
                                                                                    style={{paddingLeft:(item.allowOffer == "Y")?"14px":"30px",fontSize:"10px",width:(item.allowOffer == "Y")?"65%":"100%"}} >
                                                                                {CommonUtil.getMessageText(Constants.BOOK_NOW,"Book Now")} {item.availableQuantity?<span style={{marginLeft:"20px"}} className="available-count">{item.availableQuantity+" "+CommonUtil.getMessageText(Constants.AVAILABLE,"Available")}</span>:""}
                                                                            </button>
                                                                            <div className="p-2 text-center text-white book-button" style={{display:"none",width:(item.allowOffer == "Y")?"65%":"100%"}}>
                                                                                <span className="m-0" onClick={()=>{onClickBookItem(item)}}>{CommonUtil.getMessageText(Constants.AVAILABLE,"Book Now")} </span>&nbsp;{item.availableQuantity?<span className={"available-count"}>{item.availableQuantity+" "+CommonUtil.getMessageText(Constants.AVAILABLE,"Available")}</span>:""}
                                                                            </div>
                                                                            &nbsp;
                                                                            <button type="button" className="btn btn-warning waves-effect"  data-dismiss="modal" style={{marginLeft:"25px",width:"43%",fontSize:"10px",display:(item.allowOffer == "Y")?"":"none"}}
                                                                                    onClick={()=>onClickShowAvailability(item)}>{CommonUtil.getMessageText(Constants.CREATE_OFFER,"Create Offer")}</button>
                                                                        </div>

                                                                    </div>
                                                                )
                                                                return list
                                                            })}



                                                        </div>
                                                    </div>
                                                    <div className="tab-pane p-1" id="pnlGridView" role="tabpanel">
                                                        {
                                                            dbSearchType == REPOSITION?(<>
                                                            {
                                                                isMobile ? (
                                                                    <MGrid id="OrgRepositionSearchGrid" cols={searchColumns}
                                                                        rows={searchData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                        ref={gridRef} addNew={false}  onRowSelected={getSelectedRows} />
                                                                ):(
                                                                    <div className="ag-theme-alpine gridHeight"
                                                                                            style={{height: 'calc(100vh - 570px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                                                                <AGGrid id="OrgRepositionSearchGrid" cols={searchColumns}
                                                                        rows={searchData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                        ref={gridRef} addNew={false} method={{onClickLink}} onRowSelected={getSelectedRows} />
                                                            </div>
                                                                )
                                                            }</>):(
                                                                <>
                                                                {
                                                                    isMobile ? (
                                                                        <MGrid id="OrgRentalSearchGrid" cols={rentersearchColumns}
                                                                        rows={searchData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                        ref={gridRef1} addNew={false}  onRowSelected={getSelectedRows} />
                                                                    ):(
                                                                        <div className="ag-theme-alpine gridHeight"
                                                                        style={{height: 'calc(100vh - 570px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                                                                <AGGrid id="OrgRentalSearchGrid" cols={rentersearchColumns}
                                                                        rows={searchData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                        ref={gridRef1} addNew={false}  method={{onClickLink}} onRowSelected={getSelectedRows} />
                                                            </div>
                                                                    )
                                                                }
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  style={{display:"none",overflow:"hidden"}} id={"pnlSearchPage"} className="container-fluid">
                    <div className="responsive-map" style={{height:"88vh",overflow:"none"}}>
                        <div className="map-container">
                            <div ref={mapRef} className="map-container__map" style={{height:"100vh"}}></div>
                        </div>
                        {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359832.842849046!2d-113.72795382611162!3d36.24549287140904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1664868051370!5m2!1sen!2sin"
                            width="100%" height="100%"  loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                    </div>
                    <div className="modal  bs-example-modal-center-new-equipment"  role="dialog"
                         aria-labelledby="mySmallModalLabel" aria-hidden="true" id="basicModal" style={{display:"block",zIndex:10}}>
                        <div className="modal-dialog modal-dialog-centered modal-lg tab-class">
                            <div className="modal-content bg-tran">
                                <div className="modal-body bg-dark">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <h3 className="modal-title mt-0 text-center text-white">Let’s find the right Truck for your need!</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="text-center">
                                                <ul className="nav nav-tabs searchnav navborder" role="tablist">
                                                    <li className="nav-item"  onClick={onClickRepositionTab}>
                                                        <a className="nav-link active text-white" id="lnkReposition" data-toggle="tab" role="tab">Repositioning - {repositionCount}</a>
                                                    </li>
                                                    <li className="nav-item" onClick={onClickRenterTab}>
                                                        <a className="nav-link text-white" id="lnkRental" data-toggle="tab" role="tab">Rental - {renterCount}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content text-left">
                                                <div className="tab-pane active pl-3 pr-3 pt-3" id="RepositioningTab" role="tabpanel">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="row justify-content-md-center m-0">
                                                                <div className="col-md-12 p-1 bg-white mb-3">
                                                                    <div className="row icon-size">
                                                                        <div className="col-1 col-md-1 p-0 mt-1 text-center">
                                                                            <i className="fa fa-bullseye"></i>
                                                                        </div>
                                                                        <div className="col-11 p-0">
                                                                            <label className="m-0">From Location</label>
                                                                            <div className="dated-i1">
                                                                                <input type="text"  ref={fromLocRef} id={'fromLocation'} style={{border:"0px"}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 p-1   bg-white">
                                                                    <div className="row icon-size">
                                                                        <div className="col-1  col-md-1 p-0 mt-1 text-center">
                                                                            <i className="fa fa-map-marker"></i>
                                                                        </div>
                                                                        <div className="col-11 p-0">
                                                                            <label className="m-0">To Location</label>
                                                                            <div className="dated-i1">
                                                                                <input type="text"  ref={toLocRef} id={'toLocation'} style={{border:"0px"}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-md-center m-0 mt-3 icon-size">
                                                                <div className="col-md-4 bg-white mb-3 p-1 ">
                                                                    <div className="row">
                                                                        <div className="col-2 col-md-3 p-0 text-center mt-1">
                                                                            <i className="fa fa-calendar"></i>
                                                                        </div>
                                                                        <div className="col-9  p-0">
                                                                            <label className="m-0">Departure Date</label>
                                                                            <div className="dated-i">
                                                                                <Flatpickr  placeholder="Departure Date" ref={stDateRef} options={dateOptions} onChange={onChangeStDate}
                                                                                            onOpen={onOpenStDate} className="form-control  box-shadow clsFormInputControl flat-picker"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 bg-white mb-3 p-1 ">
                                                                    <div className="row">
                                                                        <div className="col-2 col-md-3  p-0 text-center mt-1">
                                                                            <i className="fa fa-calendar"></i>
                                                                        </div>
                                                                        <div className="col-9  p-0">
                                                                            <label className="m-0">Arrival Date</label>
                                                                            <div className="dated-i">
                                                                                <Flatpickr  placeholder="Arrival Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                                                            onOpen={onOpenEDDate} className="form-control box-shadow clsFormInputControl flat-picker"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4  bg-white mb-3 p-1 ">
                                                                    <div className="row">
                                                                        <div className="col-2 col-md-3  p-0 text-center mt-1">
                                                                            <i className="fas fa-route"></i>
                                                                        </div>
                                                                        <div className="col-9  p-0">
                                                                            <label className="m-0">Allowed Deviation</label>
                                                                            <div className="dated-inum">
                                                                                <input type="text" value={allowedDeviation} onChange={(e)=>{setAllowedDeviation(e.currentTarget.value)}}
                                                                                       style={{color:"#000"}}/>
                                                                                <span>/ Miles</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 p-0 mb-3">
                                                            <div className="searchbtn" onClick={onClickSearchReposition}>
                                                                <a>Search</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane  pl-3 pr-3 pt-3" id="RentalTab" role="tabpanel">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="row bg-white justify-content-md-center m-0">
                                                                <div className="col-md-12  p-1">
                                                                    <div className="row icon-size">
                                                                        <div className="col-2 col-md-1 p-0 mt-1 text-center">
                                                                            <i className="fa fa-bullseye"></i>
                                                                        </div>
                                                                        <div className="col-10 p-0">
                                                                            <label className="m-0">From Location</label>
                                                                            <div className="dated-i1">
                                                                                <input type="text" id={"departureLocation"} style={{border:"0px"}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row justify-content-md-center m-0 mt-3 icon-size">
                                                                <div className="col-md-6  bg-white mb-3 p-1">
                                                                    <div className="row">
                                                                        <div
                                                                            className="col-2 col-md-2  p-0 text-center mt-1">
                                                                            <i className="fa fa-calendar"></i></div>
                                                                        <div className="col-9 p-0">
                                                                            <label className="m-0">Departure Date</label>
                                                                            <div className="dated-i">
                                                                                <Flatpickr  placeholder="Start Date" ref={stDateRentalRef} options={dateOptions} onChange={onChangeRentalStDate}
                                                                                            onOpen={onOpenRentalSDData} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6  bg-white mb-3 p-1">
                                                                    <div className="row">
                                                                        <div className="col-2 col-md-2  p-0 text-center mt-1">
                                                                            <i className="fa fa-calendar"></i></div>
                                                                        <div className="col-9 p-0">
                                                                            <label className="m-0">Arrival Date</label>
                                                                            <div className="dated-i">
                                                                                <Flatpickr  placeholder="End Date" ref={endDateRentalRef} options={dateOptions}  onChange={onChangeRentalEdDate}
                                                                                            onOpen={onOpenRentalEDData} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 p-0 mb-3">
                                                            <div className="searchbtn" onClick={onClickSearchRental}>
                                                                <a>Search</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchDashboardNew;
