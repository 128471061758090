import React, { useEffect, useRef, useState } from 'react'
import BusyLoader from '../../Components/BusyLoader';
import { NetworkManager } from '../../Components/NetworkManager';
import DetectDevice from '../../utils/DetectDevice'
import { DragNDrop } from '../../utils/DragDrop';
import ErrorRender from './ErrorRender';
import './master.css';
import ToastManger from '../../Components/ToastManager';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';

function MasterData() {
    const { isMobile } = DetectDevice();
    const equipDragRef = useRef<any>(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState(CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? 'Driver' : 'Equipment')
    const [errors, setErrors] = useState<any>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const dropFileObj = React.useRef<any>(null);

    const changeFileType = (type:any) => {
      setFileType(type)
      setFileName('')
      dropFileObj.current = ''
      setErrors([]);
    }

    const downLoadCSV = (path:any, name:any) => {
      let link = document.createElement("a");
          link.download = name;
          link.href = window.location.origin + "/" + path;
          link.click();
    }

    const handleDragEnter = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        let dropDiv = document.getElementById('dropzone-span');
        let browseButton = document.getElementById('drop-browse') as HTMLButtonElement;
        let dropMain = document.getElementById('dropzone-main') as HTMLDivElement;
        dropMain.style.backgroundColor = '#c0c0c0'
        if(browseButton){
         browseButton.classList.add('d-none')
        }
        if(dropDiv){
         dropDiv.innerHTML = 'Drop file.';
         dropDiv.style.color = '#fff'
        }
    }
    const handleDragLeave = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        resetDrag();
    }
    const resetDrag = () => {
      let dropDiv = document.getElementById('dropzone-span');
        if(dropDiv){
         dropDiv.innerHTML = 'Drag & Drop file here OR';
         dropDiv.style.color = 'black'
        }
        let browseButton = document.getElementById('drop-browse') as HTMLButtonElement;
        if(browseButton){
         browseButton.classList.remove('d-none')
        }
        let dropMain = document.getElementById('dropzone-main') as HTMLDivElement;
        dropMain.style.backgroundColor = '#fff'
    }
    const handleDragOver = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        const { files } = e.dataTransfer
        if (files && files.length) {
         let splitType = files?.[0]?.name?.split('.');
            if(splitType.pop() === 'csv'){
               dropFileObj.current = files[0];
               setFileName(files[0].name);
            }else{
               ToastManger.showToast('File type is not csv', 'Info');
               setFileName('')
            }
        }
        resetDrag();
    }

    useEffect(()=>{
      DragNDrop({drop:equipDragRef, handleDragOver, handleDrop, handleDragEnter, handleDragLeave })
    },[])

    const uploadDocument = () => {
      if (fileInputRef.current) {
            fileInputRef.current.value = "";
            fileInputRef.current.click();
        }
    }
    const onSelectFiles = (event:any) => {
        if (event && event.target && event.target.files[0]) {
            let files = event?.target?.files;
            let splitType = files?.[0]?.name?.split('.');
            if(splitType.pop() === 'csv'){
                dropFileObj.current = files[0];
                setFileName(files[0].name);
            }else{
                ToastManger.showToast(CommonUtil.getMessageText(Constants.FILE_NOT_CSV,'File type is not a csv'), 'Info');
                setFileName('')
            }
        }
    }
    const ImportFile = async () => {
      if(CommonUtil.isRootUser()) return ToastManger.showToast('Switch to customer.', 'Info')
      let token = 'Bearer ' + localStorage.getItem('token')
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var formdata = new FormData();
      formdata.append("file", dropFileObj.current, fileName);

            let requestOptions : any = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
            };
         BusyLoader.showLoader();
         fetch(NetworkManager.serverPath +`api/upload/importdata?importType=${fileType}&saasOrgId=${CommonUtil.getSaasOrgId()}`, requestOptions)
         .then(response => response.text())
         .then(result => {
            let restObj = JSON.parse(result);
            if(restObj.status === 'failed'){
               ToastManger.showToast(`${fileType} import failed`, 'Error')
               setErrors(restObj.rowList);
            }else{
               ToastManger.showToast(`${fileType} imported successfully`, 'Info')
               setErrors([]);
            }
         })
         .catch(error => {console.log('error', error)
         ToastManger.showToast(`${fileType} import failed`, 'Error')
      }).finally(()=>{
            BusyLoader.hideLoader();
         })
    }
      return (
                <div className="page-content-wrapper master-data" style={{ marginLeft:'5px', marginRight:'5px' }} >
                <input name="file" type="file" id='inputImport' ref={fileInputRef} style={{"display": "none"}} multiple={false} onChange={(e) => {onSelectFiles(e)}} accept=".csv" />
                  <div className="container-fluid p-2">
                     <div className="row mt-3">
                        <div className="col-12">
                           <section>
                              <div className="row">
                                 <div className="col-12">
                                    <ul className="nav nav-pills" role="tablist">
                                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                    (
                                          <>
                                             <li className="nav-item mr-3">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#" role="tab" onClick={() => { changeFileType('Equipment') }}>{CommonUtil.getMessageText(Constants.EQUIPMENT, 'Equipment')}</a>
                                             </li>
                                             <li className="nav-item mr-3">
                                                <a className="nav-link" data-bs-toggle="tab" href="#" role="tab" onClick={() => { changeFileType('Availability') }}>{CommonUtil.getMessageText(Constants.AVAILABILITIES, 'Availabilities')}</a>
                                             </li>
                                          </>
                                    ): null}
                                    {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                                       <>
                                          <li className="nav-item mr-3">
                                             <a className={`nav-link ${CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? 'active' : ''}`} data-bs-toggle="tab" href="#" role="tab" onClick={() => { changeFileType('Driver') }}>{CommonUtil.getMessageText(Constants.DRIVERS, 'Drivers')}</a>
                                          </li>
                                       </>
                                    ): null}
                                    <li className="nav-item mr-3 d-none">
                                       <a className="nav-link" data-bs-toggle="tab" href="#" role="tab" onClick={() => { changeFileType('ParkingLot') }}>{CommonUtil.getMessageText(Constants.PARKING_LOT, 'Parking Lot')}</a>
                                    </li>
                                    </ul>
                                    <div className="tab-content mt-3">
                                       <div className={`tab-pane p-3 active`} id="Equipment" role="tabpanel">
                                          <div className="row">
                                             <div className="col-12 mb-3">
                                                 {fileType === 'Equipment' ? <label className="col-sm-12 col-form-label"><a href="#" onClick={()=>{downLoadCSV('docs/Equipment.xlsx', 'Equipment')}} ><img src="../images/csv.png" alt="user" /> &nbsp;{CommonUtil.getMessageText(Constants.EQUIPMENT, 'Equipment')} &nbsp; - &nbsp; {CommonUtil.getMessageText(Constants.SAMPLE_CSV_FILE,'Sample xlsx file')}</a></label> : null}
                                                 {fileType === 'Availability' ? <label className="col-sm-12 col-form-label"><a href="#" onClick={()=>{downLoadCSV('docs/Availability.xlsx', 'Availability')}}><img src="../images/csv.png" alt="user" /> &nbsp;{CommonUtil.getMessageText(Constants.AVAILABILITY, 'Availability')} &nbsp; - &nbsp; {CommonUtil.getMessageText(Constants.SAMPLE_CSV_FILE,'Sample xlsx file')}</a></label> : null}
                                                 {fileType === 'ParkingLot' ? <label className="col-sm-12 col-form-label"><a href="#" onClick={()=>{downLoadCSV('docs/ParkingLot.csv', 'ParkingLot')}}>
                                                  <img src="../images/csv.png" alt="user" /> &nbsp;{CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, 'ParkingLot - Sample CSV file')}</a></label> : null}
                                                {fileType === 'Driver' ? <label className="col-sm-12 col-form-label"><a href="#" onClick={() => { downLoadCSV('docs/Drivers.csv', 'Driver')}}>
                                                <img src="../images/csv.png" alt="user" /> &nbsp;{CommonUtil.getMessageText(Constants.DRIVER_SAMPLE_CSV_FILE, 'Driver - Sample CSV file')}</a>
                                                </label> : null}
                                             </div>
                                          </div>
                                          <div className="row mb-3 my-3">
                                             <div className="col-lg-4 " ref={ equipDragRef } >
                                                <div className="dropzone dropzone-main" id='dropzone-main'>
                                                   <span id='dropzone-span'>{CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, 'Drag & Drop file here OR')}</span> &nbsp;&nbsp; <button className="drop-browse browse-btn" id='drop-browse' onClick={uploadDocument} style={isMobile ?{right:'5%', backgroundColor:'#fff'}:{backgroundColor:'#fff'}}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, 'Browse Files')}</button>
                                                   <div className="fallback"></div>
                                                </div>
                                             </div>
                                             <div className="col-sm-6 col-lg-6 pt-3">
                                                <div className="row m-0">
                                                   <div style={{ display:'block', width:'auto' }} >{CommonUtil.getMessageText(Constants.FILE_NAME, 'File Name:')} </div>
                                                   <div className="col-9 p-0 ml-3"><input type="text" className="d-inline-block pl-2 w-100" required value={fileName} contentEditable={false} readOnly /></div>
                                                </div>
                                             </div>
                                             <div className="col-sm-6 col-lg-2 text-center pt-3">
                                                <button type="button" onClick={ImportFile} className="btn btn-primary waves-effect waves-light" >{CommonUtil.getMessageText(Constants.IMPORT, 'Import')}</button>
                                             </div>
                                          </div>
                                       {(fileType === 'Equipment' || fileType === 'Availability' || fileType === 'ParkingLot' || fileType === 'Driver') && errors.length > 0 ? (
                                             <ErrorRender errors={errors} />
                                          ) : null}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </div>
                     </div>
                  </div>
               </div>
            )
}

export default MasterData
