import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import Draggable from "react-draggable";
import DraggableComponent from "./DraggableComponent";


const MGridToolBar = forwardRef((props: any, ref: any) => {
    let totalRows: number = 0;
    let pageSize: number = 500;
    let totalPages: number = 0;
    let currPage: number = 1;
    let totalRowsAfterFilter: number = 0;

    var pageindicator = "";


    const [pages, setPages] = useState('');
    const [showSortPopup, setShowPopup] = useState(false);
    const [showColumnPopup, setShowColumnPopup] = useState(false);
    const [columns, setColumns] = useState([]);
    const [sortColumns1, setSortColumns1] = useState<any>([]);
    const [page1, setPage1] = useState('');
    const [avlColumns, setAvlColumns] = useState([]);
    const [selColumns, setSelColumns] = useState([]);


    useEffect(() => {
                displayPageInformation();
    }, [props.rowData, props.pageNo]);

    let displayPageInformation = () => {
        currPage = props.pageNo;
        totalRows = props?.rowData?.length;
        pageSize = (props.pageSize) ? props.pageSize : 100;
        if (totalRows == 0) {
            totalPages = 0;
        } else {
            totalPages = Math.ceil(totalRows / pageSize);
        }
        if (totalRows == 0) {
            pageindicator = (0) + "-" + (0) + " of " + 0;
        } else {
            pageindicator = (((pageSize * currPage) - pageSize) + 1) + "-" + ((pageSize * currPage) > totalRows ? totalRows : (pageSize * currPage)) + " of " + totalRows;
        }
        setPages(pageindicator);
    };

    useImperativeHandle(ref, () => ({passRows}))

    const passRows = (params: any) => {
        console.log('Hello', params)
    }

    let gotoNextPage = () => {
        let total = Math.ceil(props.rowData.length / props.pageSize);
        if(props.pageNo < total){
            props.setPageNo((prev : any)=>{
            return prev + 1
        })
        }
        return null;
        }
    let gotoPreviousPage = () => {
        if(props.pageNo === 1) return null;
        props.setPageNo((prev : any)=>{
            return prev - 1
        })
    }
    let onSearchFilterChange = (e: any) => {
            props.setPageNo(1)
            props.setQuickFilter(e.currentTarget.value);
            displayPageInformation();
    }

    let onClickRemoveSearch = () => {
        props.api.setQuickFilter("");
        // console.log(props.api.rowModel.rootNode.childrenAfterFilter.length);
        displayPageInformation();
    }

    let onClosePopup = () => {
        setShowPopup(false);
    }

    let onClickAdd = () => {
        props.onClickAdd();
    }


    let onAddBtnClickHandler = () => {
        // var sArr = this.state.sortableColumns;
        if (document.getElementById("organizeColumnsSelect")) {
            //var sortColumns:any = sortColumns;
            var element: any = document.getElementById("organizeColumnsSelect")
            var field = element.value;
            if (field) {
                var text = element.options[element.selectedIndex].text;
                var colObj: any = {};
                colObj.text = text;
                colObj.field = field;
                colObj.sort = "asc";
                sortColumns1.push(colObj);
                setSortColumns1(sortColumns1);
                setPage1("" + Math.random() * 1000000);
            }
        }
    }

    let onDescSort = (e: any, field: any) => {
        //console.log(e,field);
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1[i].sort = e.target.checked ? "desc" : "asc"
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
    }
    let onDelSort = (field: any) => {
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1.splice(i, 1);
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
        // this.setState({sortableColumns:sArr});
    }
    let onSaveSort = () => {
        //console.log(sortColumns1);
        var sortArr: any = [];
        for (var i = 0; i < sortColumns1.length; i++) {
            var obj: any = {};
            obj.colId = sortColumns1[i].field;
            obj.sort = sortColumns1[i].sort;
            obj.sortIndex = i;
            sortArr.push(obj);
        }
        //sortArr.push({colId: 'model', sort: 'asc'});
        props.api.rowModel.columnApi.applyColumnState({
            state: sortArr,
            defaultState: {sort: null},
        });
        onClosePopup();
    }

    let onCloseColumnPopup = () => {
        setShowColumnPopup(false);
    }

    let onClickRight = () => {
        var items: any = document.getElementById("leftId");
        if (items && items.options) {
            var avlCols: any = [];
            var selectedCols: any = selColumns;

            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    selectedCols.push(colObj);
                } else {
                    avlCols.push(colObj);
                }
            }

            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
        }
    }

    let onClickUpRows = () => {

        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let findSelected = selColumns.findIndex((sel:any)=>sel.selected === true);

        if (findSelected === 0) {
            return;
        }

        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }
        var arrayTemp: [] = swapElement(selColumns, indexes, "dec");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);
    }
    let swapElement = (array: any, indexes: any, str: any): any => {
        var tmp = "";

        var arr: any = [];

        if (str == "inc") {
            for (var k = indexes.length - 1; k >= 0; k--) {
                for (var j = array.length - 1; j >= 0; j--) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j + 1);
                        break;
                    }
                }
            }
        } else {
            for (var k = 0; k < indexes.length; k++) {
                for (var j = 0; j < array.length; j++) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j - 1);
                        break;
                    }
                }
            }
        }

        return array;
    }

    let arraymove = (arr: any, fromIndex: number, toIndex: number) => {
        var element = arr[fromIndex];
        element.selected = true;
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        // return arr;
    }

    let onClickDown = () => {
        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let selected = selColumns.filter((sel:any)=>sel.selected === true) || [];

        let obj: any = selected.slice(-1)[0]
        let findSelected = selColumns.findIndex((sel:any)=>sel === obj);
        if (findSelected + 1 === selColumns.length) {
            return;
        }
        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }

        var arrayTemp: [] = swapElement(selColumns, indexes, "inc");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);

    }

    let onClickLeft = () => {
        var items: any = document.getElementById("rightId");
        if (items && items.options) {
            //console.log(items.options.length);
            var selItem = [];
            var avlCols: any = avlColumns;
            var selectedCols: any = [];
            //console.log(items);
            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    avlCols.push(colObj);
                } else {
                    selectedCols.push(colObj);
                }
            }
            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
            //console.log(selItem);
        }


        /*if(items){

        }*/
    }

    let onSaveShowHide = () => {
        // console.log("onSaveShowHide");
        //var columns:any = props.api.getColumnDefs();
        //props.api;
        //setSelColumns
        var cols: any = [];
        //if(avlColumns){
        if (selColumns.length > 0) {
            selColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, true);
        }
        props.api.columnModel.moveColumns(cols, 0);
        if (avlColumns.length > 0) {
            cols = [];
            avlColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, false);
        }


        //}


        // props.api.columnModel.moveColumns(cols,1);
        onCloseColumnPopup();
    }

    const selectedOption = (e:any, idx:number) => {
        let options : any = [...selColumns]

        if(e.ctrlKey){
            //console.log('17')
            if(options[idx].selected === true){
                options[idx].selected = false;
            }else{
                options[idx].selected = true;
            }
        }else{
            //console.log('no 17')
            options.map(function(x:any) {
                x.selected = false;
            });
            options[idx].selected = true;
        }

        setSelColumns(options);
    }


    return (
        // console.log(api);
        <div>

            <Draggable handle=".modal-header">
                <Modal show={showColumnPopup} dialogAs={DraggableComponent} bgSize='lg' className="fcPopup">
                    <div className="modal-content" style={{"width": "800px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{"Column Show/Hide"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    style={{marginLeft:"0px"}} onClick={onCloseColumnPopup}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px"
                                            }}>{"Available Columns"}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowY: "hidden", msOverflowX: 'hidden'}}>
                                                <select multiple={true} id="leftId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        avlColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn cbtn-success grid-button" style={{marginBottom:"5px"}}
                                                type="button" onClick={onClickLeft}>
                                            <i className="fa fa-arrow-left" style={{color:"#FFF"}}></i>
                                        </button>

                                        <button className="btn cbtn-success grid-button" type="button" onClick={onClickRight}>
                                            <i className="fa fa-arrow-right" style={{color:"#FFF"}}></i>
                                        </button>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px",
                                                fontWeight:"bold"
                                            }}>{"Selected Columns"}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowX: "hidden", overflowY: 'hidden'}}>
                                                <select multiple={true} id="rightId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        selColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field} onClick={(e)=>{selectedOption(e,idx)}}
                                                                               selected={item.selected ? true : false}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickUpRows}><i className="fa fa-arrow-up"></i></button>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickDown}><i className="fa fa-arrow-down"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onCloseColumnPopup}
                                    className="btn btn-outline-dark text-black cbtn" style={{ color:'black' }}>{"Close"}</button>
                            <button type="button" onClick={onSaveShowHide} className="btn cbtn-success cbtn">{"Save"}</button>
                        </div>
                    </div>
                </Modal>
            </Draggable>
            <Draggable handle=".modal-header">
                <Modal show={showSortPopup} bgSize='lg' dialogAs={DraggableComponent} className="fcPopup">
                    <div className="modal-content" style={{"width": "600px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{"Column Sort"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={onClosePopup} style={{marginLeft:"0px"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8">
                                        <select id="organizeColumnsSelect" className="form-select pull-right"
                                                style={{width: "100%", height: "34px"}}>
                                            {
                                                columns.map((item: any, idx: number) => {
                                                    var list: any = "";
                                                    var flag: boolean = false;
                                                    sortColumns1.map((sItem: any, sIdx: number) => {
                                                        if (sItem.field == item.field) {
                                                            flag = true;
                                                        }
                                                    })
                                                    if (!flag) {
                                                        list = <option value={item.field}>{item.headerName}</option>;
                                                        return list;
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <button type="button" className="btn cbtn-success cbtn"
                                                onClick={onAddBtnClickHandler}>{"Add"}</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="container" style={{height: "220px", overflow: "auto"}}>
                                        <table className="table table-bordered mt-1" style={{fontSize: "14px"}}>
                                            <thead>
                                            <tr>
                                                <th>Column Heading</th>
                                                <th>Desc</th>
                                                <th>Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody className="clickableRow">
                                            {
                                                sortColumns1.map((m: any, index: number) => {
                                                    if (m.text)
                                                        return <tr>
                                                            <td> {m.text}</td>
                                                            <td><input type="checkbox"
                                                                       checked={m.sort == "desc" ? true : false}
                                                                       style={{"width": "20px", "height": "20px"}}
                                                                       onClick={(e) => {
                                                                           onDescSort(e, m.field)
                                                                       }}/></td>
                                                            <td>
                                                                <button type="button" className="btn btn-default"
                                                                        onClick={(e) => {
                                                                            onDelSort(m.field)
                                                                        }}><i className="fas fa-remove"></i></button>
                                                            </td>
                                                        </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onClosePopup} className="btn btn-outline-dark text-black cbtn" style={{ color:'black' }}>{"Close"}</button>
                            <button type="button" onClick={onSaveSort}
                                    className="btn cbtn  btn-success">{"Save"}</button>
                        </div>

                    </div>
                </Modal>
            </Draggable>
            <div className="well well-sm paginationContainer" style={{backgroundColor: '#c1e4dc'}}>
                <div className="row">
                    <div className={"col-12 my-1"}>
                        <div className="paginationContainerGroup">
                            <div className="input-group" style={{minHeight: '25px', height: '25px'}}>
                                <span className="input-group-addon" style={{height: "25px", width:'6%'}}>
                                    <i className="fa fa-search fa-2xs"
                                       style={{fontSize: '12px', alignSelf: 'center', position:'absolute', top:'49%'}}></i>
                                </span>
                                <input type="text" placeholder={"Search Here"}
                                       style={{minHeight: '25px', height: '25px'}}
                                       className="form-control searchTextBox" onChange={onSearchFilterChange}/>
                                <span className="input-group-btn" style={{padding: "0px"}}>
                                    <button className="btn btn-default grid-right-button" type="button"
                                            onClick={onClickRemoveSearch} style={{height: "25px"}}>
                                        {/* <i className="fa fa-remove"></i> */}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={props.addNew ? 'col-12 my-1 row':'col-12 my-1'}>
                        <div className={props.addNew ? 'col-8':'col-12 p-0'} >
                        <div className="paginationContainerGroup">
                            <div className="input-group" style={{minHeight: '25px', height: '25px'}}>
                                <div className="input-group-btn" style={{minHeight: '25px', height: '25px'}}>
                                    <a className="btn btn-default grid-left-button" type="button"
                                       onClick={gotoPreviousPage} style={{height: "25px"}}>
                                        <i className="fa fa-angle-left fa-2xs"></i>
                                    </a>
                                </div>
                                <input type="text" className="form-control paginationText"
                                       style={{minHeight: '25px', height: '25px'}} value={pages}
                                       id="roles-table-pagination-count" readOnly/>
                                <div className="input-group-btn right-side" style={{minHeight: '25px', height: '25px'}}>
                                    <a className="btn btn-default grid-right-button" type="button"
                                       onClick={gotoNextPage} style={{height: '25px'}}>
                                        <i className="fa fa-angle-right fa-2xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className={props.addNew ? 'col-4':'d-none'} >
                                <div  className={"col-sm-12 col-lg-1 col-md-1 hidden-xs hidden-sm my-1"}>
                                    <div>
                                        <div className="btn-group btn-group-justified"
                                             style={{width: "100%", height: '25px'}}>
                                            <a className="btn btn-default login-btn" style={{background: "#EFC300", margin:'auto !important'}}
                                               onClick={onClickAdd} title={props.tip}><i
                                                className="fas fa-plus fa-sm  "></i><span style={{marginLeft:'8px'}}>Add</span></a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
});

export default MGridToolBar;
