import { useEffect, useState } from "react";
import { Constants } from "../../Pages/Languages/Constants";
import { CommonUtil } from "../../utils/CommonUtil";
import { event } from "jquery";
import { NetworkManager } from "../../Components/NetworkManager";
import { toast } from "react-toastify";
import ToastManager from "../../Components/ToastManager";
import PhoneInput from "react-phone-input-2";

const CarrierDetails = ({ equipData }: any) => {

  const [carrierInfoId, setCarrierInfoId] = useState("");
  const [carriername, setCarriername] = useState("");
  const [mcnumber, setMcnumber] = useState("");
  const [usdotnumber, setUsdotnumber] = useState("");
  const [drivername, setDrivername] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [licensenumber, setLicensenumber] = useState("");
  const [trucknumber, setTrucknumber] = useState("");
  const [truckplatenumber, setTruckplatenumber] = useState("");




  useEffect(()=>{
    getCarrierDetails();
   
  },[]);

  const getCarrierDetails = ()=>{
    let urlAPI:string = "api/reservation/"+CommonUtil.SELECT_RESERVATION_ID;
    NetworkManager.sendJsonRequest(urlAPI,"","","GET",true,(dataObj:any)=>{
      try{
        const restData = JSON.parse(dataObj);
        console.log(restData.carrierInformation);
        const {carrierInfoId,carrierNameInfo,driverEmailInfo,driverLicenseNumberInfo,driverNameInfo,driverPhoneNumberInfo,mcNumber,reservationId,truckLicensePlateNumberInfo,truckNumberInfo,usDotNumberInfo } = restData.carrierInformation;
        setEmail(driverEmailInfo)
        setDrivername(driverNameInfo)
        setPhonenumber(driverPhoneNumberInfo)
        setTrucknumber(truckNumberInfo)
        setCarriername(carrierNameInfo)
        setUsdotnumber(usDotNumberInfo)
        setMcnumber(mcNumber)
        setLicensenumber(driverLicenseNumberInfo)
        setTruckplatenumber(truckLicensePlateNumberInfo)
        setCarrierInfoId(carrierInfoId)
      }
      catch(e){
        console.log("error in json parsing...");
      }
    },onLoginError);

  }


const addCarrierDetails = (e:any) =>{
  let urlAPI:string = "api/carrier/info/";
  if(carrierInfoId)
    urlAPI = `api/carrier/info/update/${carrierInfoId}`
  let restData={  
    "reservationId": CommonUtil.SELECT_RESERVATION_ID,
    "carrierNameInfo": carriername,
    "mcNumber": mcnumber,
    "usDotNumberInfo": usdotnumber,
    "driverNameInfo": drivername,
    "driverPhoneNumberInfo": phonenumber,
    "driverEmailInfo": email,
    "driverLicenseNumberInfo": licensenumber,
    "truckNumberInfo": trucknumber,
    "truckLicensePlateNumberInfo": truckplatenumber,
  }
  NetworkManager.sendJsonRequest(urlAPI,restData,"",carrierInfoId?"PUT":"POST",true,(dataObj:any)=>{
    try{
      const restData = JSON.parse(dataObj);
      console.log(restData);
      setCarrierInfoId(restData.carrierInfoId);
      ToastManager.showToast(carrierInfoId?"Updated Successfully":"Saved Successfully", "Success");
    }
    catch(e){
      console.log("error in json parsing...");
    }
  },onLoginError);
} 








  const onLoginError = (err:any)=>{
      console.log(err);
  }

 const showToast = (type:any, msg:any) => {
    console.log("toast");
    switch (type) {
      case "SUCCESS":
        toast.success(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      case "ERROR":
        toast.error(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      case "WARNING":
        toast.warn(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      default:
        return false;
    }
  };
  

  return (<>
    <form className="is-readonly" style={{ marginTop: "10px" }}>
      <div className="row">
        <div className="col-md-12  col-12  mb-2">
          <h6>
            {CommonUtil.getMessageText(Constants.CARRIER_DETAILS, "Carrier Details")}
          </h6>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.CARRIER_NAME, "Carrier Name")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(Constants.CARRIER_NAME, "Carrier Name"
                )}
                value={carriername}
                onChange={(e) => {
                  setCarriername(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.CARRIER_MC_NUM, "MC Number")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.CARRIER_MC_NUM, "MC Number"
                )}
                value={mcnumber}
                onChange={(e) => {
                  setMcnumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.CARRIER_US_DOT, "US DOT Number")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.CARRIER_US_DOT, "US DOT Number"
                )}
                value={usdotnumber}
                onChange={(e) => {
                  setUsdotnumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <form className="is-readonly" >
      <div className="row">
        <div className="col-md-12  col-12  mb-2 mt-3">
          <h6>
            {CommonUtil.getMessageText(Constants.CARRIER_DETAILS, "Driver Details")}
          </h6>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.DRIVER_NAME, "Driver Name")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(Constants.DRIVER_NAME, "Driver Name"
                )}
                value={drivername}
                onChange={(e) => {
                  setDrivername(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.DRIVER_NUMBER, " Phone Number")}
            </label>
            <div className="col-sm-12">
            <PhoneInput
                                  country={'us'}
                                  countryCodeEditable={true}
                                  value={phonenumber}
                                  placeholder={CommonUtil.getMessageText(
                                    Constants.DRIVER_NUMBER, " Phone Number"
                                  )}
                                  inputClass={'input-phone'}
                                  onChange={(e) => {
                                    //console.log(e)
                                    if (phonenumber == '') {
                                      e = 1 + '' + e
                                    }
                                    setPhonenumber(e)
                                  }}
                                  disableCountryGuess={false}
                                  disableDropdown={true}
                                  disableSearchIcon={true}></PhoneInput>
              {/* <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.DRIVER_NUMBER, " Phone Number"
                )}
                value={phonenumber}
                onChange={(e) => {
                  setPhonenumber(e.target.value);
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.EMAIL, "Email")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.EMAIL, " Email"
                )}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.LICENSE_NUMBER, "License Number")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.LICENSE_NUMBER, "License Number"
                )}
                value={licensenumber}
                onChange={(e) => {
                  setLicensenumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.TRUCK_NUMBER, "Truck Number")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.TRUCK_NUMBER, "Truck Number"
                )}
                value={trucknumber}
                onChange={(e) => {
                  setTrucknumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ marginBottom: "2px" }}
            >
              {CommonUtil.getMessageText(Constants.TRUCK_LICENSE_NUMBER, "Truck License Plate Number")}
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder={CommonUtil.getMessageText(
                  Constants.TRUCK_LICENSE_NUMBER, "Truck License Plate Number"
                )}
                value={truckplatenumber}
                onChange={(e) => {
                  setTruckplatenumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"  style={{ textAlign: "right" }}>
     

        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"

        >{CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}</button>
        &nbsp;&nbsp;
         <button type="button" className="btn btn-success" onClick={addCarrierDetails}

      >{CommonUtil.getMessageText(Constants.SAVE, 'Save')}</button>
   
     </div>
    </form>
  </>);
};

export default CarrierDetails;
