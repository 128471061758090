import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";

const UpdateVisualInspection = () => {
  const [visualDetails, setVisualDetails] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);
  const [damageDes, setDamageDes] = useState("");
  const [damageMap, setDamageMap] = useState<any>([]);
  const [fullDamageView, setFullDamageView] = useState<any>([]);
  const [entryDamage, setEntryDamage] = useState("");
  const [showMap, setShowMap] = useState(false);

  let getVisualInspectionDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getVisualInspection?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetVisualInspectionDetails,
      onLoginError
    );
  };

  useEffect(() => {
    getVisualInspectionDetails();
  }, []);

  let onGetVisualInspectionDetails = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      //   setVisualDetails(restData[0].taskDetailsAttachment);
      let columns: any = [
        {
          field: "fullName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 400,
          sortable: true,
          cellStyle: {
            fontWeight: "600",
            letterSpacing: "0px",
            fontSize: "12px",
            fontFamily: "poppins",
            color: "black",
            paddingTop: "5px",
          },
          headerClass: "header-white-bg",
          pinned: "left",
        },
      ];
      let rowData: any = [];
      for (let i = 0; i < restData.length; i++) {
        const groupName: string = restData[i].eventCode;

        restData[i].taskDetailsAttachment.map((ele: any) => {
          let obj: any = { ...ele };
          obj[`status-${groupName}`] = obj.status;
          obj[`note-${groupName}`] = obj.note;
          obj[`attachments-${groupName}`] = obj.attachments;

          const index = rowData.findIndex((item: any) => {
            return item.name === obj.name;
          });
          if (index === -1) {
            rowData.push(obj);
          } else {
            rowData[index] = { ...rowData[index], ...obj };
          }
        });
        columns.push({
          field: restData[i].eventCode,
          date: restData[i].creationDate,
          headerGroupComponent: NewReleaseCellRender,
          children: [
            {
              field: `status-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.NEW_PASS, "PASS"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: (params: any) => {
                const status = String(
                  params.data[params.colDef.field]
                ).toUpperCase();
                return {
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                  background:
                    status === Constants.NO || status === Constants.FAIL
                      ? "rgba(242, 170, 176,0.5)"
                      : null,
                };
              },
              headerClass: "header-white-bg",

              valueGetter: (params: any) => {
                const status = String(
                  params.data[params.colDef.field]
                ).toUpperCase();
                return status === Constants.OK || status === Constants.PASS
                  ? "Yes"
                  : "No";
              },
            },
            {
              field: `status-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.FAIL, "FAIL"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: (params: any) => {
                const status = String(
                  params.data[params.colDef.field]
                ).toUpperCase();
                return {
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                  background:
                    status === Constants.NO || status === Constants.FAIL
                      ? "rgba(242, 170, 176,0.5)"
                      : null,
                };
              },
              headerClass: "header-white-bg",

              valueGetter: (params: any) => {
                const status = String(
                  params.data[params.colDef.field]
                ).toUpperCase();
                return status === Constants.NO || status === Constants.FAIL
                  ? "Yes"
                  : "No";
              },
            },
            {
              field: `note-${groupName}`,
              headerName: CommonUtil.getMessageText(Constants.NOTE, "NOTE"),
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass: "header-white-bg",
            },
            {
              field: `attachments-${groupName}`,
              headerName: "Attachments",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
                borderRight: "1px solid #d0d3d7",
              },
              headerClass: "header-white-bg",

              cellRenderer: AttachmentsCellRenderer,
            },
          ],
        });
      }
      setUserColumns(columns);
      setVisualDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const handleData = (name: any, damageMap: any, attached: any, des: any) => {
    setShowMap(true);
    setEntryDamage(name);
    setFullDamageView(damageMap);
    setDamageMap(attached);
    setDamageDes(des);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  let attachImages: any = [];
  try {
    attachImages = attachments.attachmentUploads.map(
      (ele: any) => ele.fullFilePath
    );
  } catch (e) {
    attachImages = [];
  }

  const [userColumns, setUserColumns] = useState<any>([]);
  return (
    <div className="containData">
      <div className="row">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight"
          style={{
            height: "calc(100vh - 330px)",
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          <InspectionGrid
            cols={userColumns}
            rows={visualDetails}
            pageSize={100}
            addNew={true}
            tooltip={"Add Company"}
            name={"Add Company"}
            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
            rowHeight={CommonUtil.GRID_ROW_HEIGHT}
            hideToolbar={true}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateVisualInspection;
