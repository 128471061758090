import React, {useEffect, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import StarRating from "../../Components/Rating";
import EquipGalleryModal from "./EquipGalleryModal";
import {Constants} from "../Languages/Constants";

var equipData:any = null;
var reservationData:any = null;
var attachmentList:any = [];
var attchIdx:number = 0;
var equiImages:any = [];
var equiInactiveImages:any = [];

var inactiveAttachments:any = [];
var inactiveIdx:number = 0;

const ReserveDeActivation = (props:any)=>{

    const [deActivationDate,setDeActivationDate] = useState("");
    const [deActivationAddress,setDeActivationAddress] = useState("");

    const [imageSrc,setImageSrc] = useState("");
    const [hodometerSrc,setHodometerSrc] = useState("");
    const [podSrc,setPODSrc] = useState("");
    const [licenseNumber,setLicenseNumber] = useState("");
    const [hodometerNumber,setHdodmeterNumber] = useState("");

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [equipImagesInactivePath, setEquipImagesInactivePath] = useState<any>([]);

    const [activationRating,setActivationRating] = useState("");
    const [deActivationRating,setDeActivationRating] = useState("");
    const [imageModalShow,setImageMoalShow] = useState(false);
    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imagePath,setImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");

    const [reeferEngineSrc,setReeferEngineSrc] = useState("");
    const [reeferEngineHours,setReeferEngineHours] = useState("");
    const [vinNumber,setVINNumber] = useState("");


    useEffect(()=>{
        equipData = props.equipData;
        reservationData = props.reservationData;
        console.log(equipData);
        console.log(reservationData);
        showEquipmentDetails();
        showReservationDetails();
    },[]);

    let showReservationDetails = ()=>{
        if(reservationData){
            setDeActivationDate(reservationData.deactivationDate);
            setDeActivationAddress(reservationData.deactivationAddress);

            setLicenseNumber(reservationData.deactivationLpn?reservationData.deactivationLpn:"");
            setHdodmeterNumber(reservationData.deactivationOdometer?reservationData.deactivationOdometer:"");
            setReeferEngineHours(reservationData.deactivationEngineHours?reservationData.deactivationEngineHours:"");
            setVINNumber(reservationData.deactivationUnitVin?reservationData.deactivationUnitVin:"");

            let aMap:string = `https://maps.google.com/maps?q=${reservationData.deactivationLatitude},${reservationData.deactivationLongitude}&hl=en&z=14&amp&output=embed`;
            //setDepartureMap(aMap);
            let idIframe = document.getElementById('deactivationiFRAME') as HTMLIFrameElement;
            //console.log(aMap);
            idIframe.setAttribute('src', aMap);

            setActivationRating(reservationData.activationRating);
            setDeActivationRating(reservationData.deactivationRating);
        }
    }
    let showEquipmentDetails = ()=>{
        //setEquipData([]);
        equiImages = [];
        let docs:any = [];
        attachmentList = [];
        attchIdx = 0;
        inactiveAttachments = [];
        inactiveIdx = 0;
        equiInactiveImages = [];
        if(equipData && equipData.attachments){
            let attchments = [];
            if(Array.isArray(equipData.attachments)){
                attchments = equipData.attachments;
            }else{
                attchments.push(equipData.attachments);
            }

            if(attchments.length>0){
                attchments.forEach((docItem:any,idx:number)=>{
                    if(docItem.attachmentCategory == CommonUtil.RESERVATION_IN_ACTIVATION){
                        if(docItem.documentType == CommonUtil.LICENSE_IMAGES && reservationData.reservationId == docItem.reservationId){
                            setLicenseNumber(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            if(docItem.fullFilePath){
                                //console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_POD && reservationData.reservationId == docItem.reservationId){
                            if(docItem.fullFilePath){
                                //console.log(docItem);
                                //setLicenseNumber(docItem.attachmentDescription);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadPODSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_HODOMETER && reservationData.reservationId == docItem.reservationId){
                            //console.log(docItem.fullFilePath);
                            setHdodmeterNumber(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            if(docItem.fullFilePath){
                                // console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadHodoMeterSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_REEFER_TYPE && reservationData.reservationId == docItem.reservationId){
                            //console.log(docItem.fullFilePath);
                            setReeferEngineHours(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            if(docItem.fullFilePath){
                                // console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadReeferSuccess,onLoginError);
                            }
                        }else if(reservationData.reservationId == docItem.reservationId && (docItem.documentType == CommonUtil.EQUIP_DRIVER_SIDE || docItem.documentType == CommonUtil.DRIVER_SIDE_EXTERIOR || docItem.documentType == CommonUtil.EQUIP_DRIVER_TIRES || docItem.documentType == CommonUtil.EQUIP_REAR_EXTERIOR || docItem.documentType == CommonUtil.VAN_INTERIOR || docItem.documentType == CommonUtil.PASSENGER_SIDE || docItem.documentType == CommonUtil.PASSENGER_SIDE_TIRE || docItem.documentType == CommonUtil.FRONT_EXTERIOR)){
                            //console.log(docItem);
                            inactiveAttachments.push(docItem);
                        }else if(docItem.documentType == CommonUtil.EQUIP_DAMAGES && reservationData.reservationId == docItem.reservationId){
                            // docs.push(docItem);
                            attachmentList.push(docItem);
                        }
                    }

                });

               // setEquipData(docs);
            }

        }
        //console.log(attachmentList);
        if (attachmentList.length > 0) {
            BusyLoader.showLoader()
            showAttachments()
        }
        if (inactiveAttachments.length > 0) {
            BusyLoader.showLoader();
            showInactiveAttachments();
        }
    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId;
                    obj.fullFilePath = attachmentList[attchIdx].fullFilePath;
                    obj.description = attachmentList[attchIdx].attachmentDescription;
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }

    let showInactiveAttachments = ()=>{
        if (inactiveAttachments.length > inactiveIdx) {
            let qParam: string = 'objectPath=' + inactiveAttachments[inactiveIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {};
                    obj.Type = inactiveAttachments[inactiveIdx].documentType
                    obj.Path = dataObj;
                    obj.attachementId = inactiveAttachments[inactiveIdx].attachementId;
                    obj.fullFilePath = inactiveAttachments[inactiveIdx].fullFilePath;
                    obj.description = inactiveAttachments[inactiveIdx].attachmentDescription;
                    equiInactiveImages.push(obj);
                    inactiveIdx = inactiveIdx + 1;
                    showInactiveAttachments();
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader();
            setEquipImagesInactivePath(equiInactiveImages);
        }
    }

    let onDownloadSuccess = (data:any)=>{
        //console.log(data);
        setImageSrc(data);
    }

    let onDownloadPODSuccess = (data:any)=>{
        //console.log(data);
        setPODSrc(data);
    }



    let onDownloadHodoMeterSuccess = (data:any)=>{
        setHodometerSrc(data);
    }

    let onDownloadReeferSuccess = (data:any)=>{
        setReeferEngineSrc(data);
    }

    let onClickDamageImage = (mTile:string,images:any,imgPath:string,idx:number)=>{
        if(images.length == 0){
            setImagePath(imgPath);
        }else{
            setImageIdx(idx);
        }
        setModalTile(mTile);
        setGallary(images);

        setImageMoalShow(true);
    }

    let onClickInspectionImage = (mTile:string,images:any,imgPath:string,idx:number,catagory:string)=>{
       // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
       // setGallary(images);
        let equipImages:any = [];

        images.forEach((item:any,eIdx:number)=>{
            if(item && item.Type == catagory){
                equipImages.push(item)
            }
        });
        for(let i=0;i<equipImages.length;i++){
            if(equipImages[i].Path == imgPath){
                idx =  i;
                break;
            }
        }
        setImageIdx(idx);
        setModalTile(mTile);
        setGallary(equipImages);
        setImageMoalShow(true);
    }



    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }


    return(
        <>
            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imagePath}/>
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6>{CommonUtil.getMessageText(Constants.LICENSE_PLATE,"License Plate")}</h6>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6>{licenseNumber}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {imageSrc?<img src={imageSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                       onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.LICENSE_PLATE,"License Plate"),[],imageSrc,0)}} />:""}
                                        {/*<img src="../../images/License-Plate.png" alt="" style={{width: "150px"}} />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6>{CommonUtil.getMessageText(Constants.HODOMETER,"Hubodometer")}</h6>
                                    </div>
                                    <div className="col-sm-6">

                                        <h6>{hodometerNumber} {CommonUtil.getMessageText(Constants.MILES,"miles")}</h6>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {hodometerSrc?<img src={hodometerSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                           onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.HODOMETER,"Hubodometer"),[],hodometerSrc,0)}}/> :""}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6>{CommonUtil.getMessageText(Constants.ENGINE_HOURS,"Engine Hours")}</h6>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6>{reeferEngineHours} {CommonUtil.getMessageText(Constants.HOURS,"Hours")}</h6>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {reeferEngineSrc?<img src={reeferEngineSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                              onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.ENGINE_HOURS,"Engine Hours"),[],reeferEngineSrc,0)}}/>:""}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>

            </div>
            <div className="row">

            </div>
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.PROFF_OF_DELIVERY,"Proof of Delivery")} </h6>
                                <div className="col-sm-12" style={{marginTop:"30px"}}>
                                    {podSrc?<img src={podSrc} className="upload-thumbnail" style={{cursor:"pointer"}}
                                                 onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.PROFF_OF_DELIVERY,"Proof of Delivery"),[],podSrc,0)}}/> :""}
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.CHRONOLOGY,"Chronology")}</h6>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="details-head">{CommonUtil.getMessageText(Constants.DEVICE_TIME,"Device Time")}</span><label className="m-0">{deActivationDate}</label>
                                    </div>
                                    <div className="col-sm-6" style={{display:"none"}}>
                                        <span className="details-head">Synchronized At</span><label className="m-0">June
                                        5th, 2021 | 22:23 IST</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3  mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.VIN_OR_TRUCK_NO,"VIN or Truck Number")}</h6>
                                <div className="col-sm-6">
                                    <label className="m-0">{vinNumber}</label>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 col-xl-3 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.REVIEW,"Review")}</h6>
                                <div className="rating row" >
                                    <StarRating value={Math.round(Number(deActivationRating))} />{deActivationRating}  {CommonUtil.getMessageText(Constants.RATINGS,"Ratings")}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section>
                <h6>{CommonUtil.getMessageText(Constants.DAMAGES,"Equipment Damages")}</h6>
                <div className="row">
                    {equipImagesPath.map((item: any, idx: number) => {
                        //if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                        var list = (
                            <div className="col-md-6 flex" style={{marginTop:"10px"}} >
                                <div className="col-md-4">
                                    <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                    onClick={()=>{
                                        //console.log(equipImagesPath,item.Path,idx);
                                        onClickDamageImage(CommonUtil.getMessageText(Constants.DAMAGES,"Equipment Damages"),equipImagesPath,item.Path,idx);
                                    }}/>
                                </div>
                                <div className="col-md-8">
                                    <span className="details-head">{item.description}</span>
                                </div>
                            </div>
                        )
                        return list
                        //}
                    })}
                </div>
            </section>
            <section>
            <div className="row">
                <div className="col-sm-12">
                    <h6>{CommonUtil.getMessageText(Constants.INSPECTION_PHOTOS,"Inspection Photos")}</h6>
                    <ul className="Damages-list">
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.DRIVER_SIDE_EXTERIOR,"Driver side Exterior")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE) {
                                    var list = (
                                        <div className="col-2 flex" >
                                            <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                 onClick={()=>{
                                                     //console.log(equipImagesPath,item.Path,idx);
                                                     onClickInspectionImage(CommonUtil.EQUIP_DRIVER_SIDE,equipImagesInactivePath,item.Path,idx,CommonUtil.EQUIP_DRIVER_SIDE);
                                                 }} />
                                        </div>
                                    )
                                    return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.DRIVER_SIDE_TIRES,"Driver side Tires")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DRIVER_TIRES) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.EQUIP_DRIVER_TIRES,equipImagesInactivePath,item.Path,idx,CommonUtil.EQUIP_DRIVER_TIRES);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.REAR_EXTERIOR,"Rear Exterior")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_REAR_EXTERIOR) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.EQUIP_REAR_EXTERIOR,equipImagesInactivePath,item.Path,idx,CommonUtil.EQUIP_REAR_EXTERIOR);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.VAN_INTERIOR,"Van Interior")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.VAN_INTERIOR) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.VAN_INTERIOR,equipImagesInactivePath,item.Path,idx,CommonUtil.VAN_INTERIOR);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.EXTERIOR_PASSENGER_SIDE,"Exterior Passenger Side")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.PASSENGER_SIDE) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.PASSENGER_SIDE,equipImagesInactivePath,item.Path,idx,CommonUtil.PASSENGER_SIDE);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.PASSENGER_SIDE_TIRE,"Passenger Side Tire")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.PASSENGER_SIDE_TIRE) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.PASSENGER_SIDE_TIRE,equipImagesInactivePath,item.Path,idx,CommonUtil.PASSENGER_SIDE_TIRE);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:"30px"}}>
                            <h6>{CommonUtil.getMessageText(Constants.FRONT_EXTERIOR,"Front Exterior")}</h6>
                            <div className="row" style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesInactivePath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.FRONT_EXTERIOR) {
                                        var list = (
                                            <div className="col-md-2 flex" >
                                                <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                                     onClick={()=>{
                                                         onClickInspectionImage(CommonUtil.FRONT_EXTERIOR,equipImagesInactivePath,item.Path,idx,CommonUtil.FRONT_EXTERIOR);
                                                     }}/>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
            <section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-4 col-12 p-4">
                        <h6>{CommonUtil.getMessageText(Constants.LOCATION,"Location")}</h6>
                        <span className="details-head">Driver's Position</span><label>{deActivationAddress}</label>
                    </div>
                    <div className="col-sm-8 col-12 p-0">
                        {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184693.82271653027!2d-79.83990028425029!3d43.672276945510944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3760d44c33c5%3A0x59e9c8bf2149d577!2sEtobicoke%2C%20Toronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sin!4v1664435425442!5m2!1sen!2sin"
                            width="100%" height="250" style={{border:"0", margin:"0px auto -7px"}} allowFullScreen
                            loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}

                        <iframe width="100%" height="450" id={"deactivationiFRAME"} loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReserveDeActivation;
