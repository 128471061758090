import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $ from 'jquery'
import '../../header.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import { CommonUtil } from '../../utils/CommonUtil'
import { NetworkManager } from '../../Components/NetworkManager'
import BusyLoader from '../../Components/BusyLoader'
import ToastManager from '../../Components/ToastManager'
import { Modal } from 'react-bootstrap'
import DraggableComponent from '../../Components/DraggableComponent'
import FavoritiesList from '../Equipments/FavoritiesList'
import SingleSelectComp from '../../Components/SingleSelectComp'
import { publish, subscribe } from './CustomEvents'
import NotificationModal from '../Notification/NotificationModal'
import { Events } from '../Languages/Events'
import { Constants } from '../Languages/Constants'
import AvailabilityModal from "../Equipments/AvailabilityModal";
import AvailabilityCreateModal from "../Equipments/AvailabilityCreateModal";
import DetectDevice from '../../utils/DetectDevice'

const MenuHeader = () => {
    const { isMobile } = DetectDevice();
    const navigate = useNavigate()
    const [open, setOpen] = useState<Boolean>(false)
    const [subMenuOpen, setSubMenuOpen] = useState<any>('')
    const [breadCrumb, setBreadCrumb] = useState<String>('')
    const divRef = useRef<HTMLDivElement>(null);
    const menuButtonRef: any = useRef(null);
    const btnRef = useRef<HTMLButtonElement>(null)

    const [breadCrumb1, setBreadCrumb1] = useState('')
    const [breadCrumb2, setBreadCrumb2] = useState('')
    const [isBredCrumb, setIsBreadCrumb] = useState(false)
    const [imageSrc, setImageSrc] = useState('') //../../NoLogo.jpg
    const [page, setPage] = useState(0)

    const [showChangeLanPopup, setShowChangeLanPopup] = useState(false)
    const [showChangePassPopup, setShowChangePassPopup] = useState(false)
    const [showMyProfilePopup, setShowMyProfilePopup] = useState(false)
    const [showFavoritiesPopup, setShowFavoritiesPopup] = useState(false)
    const [showSwitchOrgPopup, setShowSwitchOrgPopup] = useState(false)

    const [switchCustomer, setSwitchCustomer] = useState(false)

    const dbRef = useRef<HTMLUListElement>(null)
    const repRef = useRef<HTMLUListElement>(null)
    const adminRef = useRef<HTMLUListElement>(null)

    const engRef = useRef<HTMLLIElement>(null)
    const frRef = useRef<HTMLLIElement>(null)
    const spRef = useRef<HTMLLIElement>(null)

    const chkProMails = useRef<HTMLInputElement>(null)
    const fNameRef = useRef<HTMLInputElement>(null)
    const lNameRef = useRef<HTMLInputElement>(null)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailId, setEmailId] = useState('')

    const oldPassRef = useRef<HTMLInputElement>(null)
    const newPassRef = useRef<HTMLInputElement>(null)
    const confirmPassRef = useRef<HTMLInputElement>(null)

    const [orgList, setOrgList] = useState([])
    const [selOrg, setSelOrg] = useState<any>(null)

    const [isSearchDBVisible, setSearchDBVisible] = useState(false)
    const [isCompanyDBVisible, setCompanyDBVisible] = useState(false)
    const [isInvoiceDBVisible, setInvoiceDBVisible] = useState(false)
    const [isProcessReserveVisible, setProcessReserveVisible] = useState(false)
    const [isOrgVisible, setOrgVisible] = useState(false)
    const [isCustomerProfileVisible, setCustomerProfileVisible] = useState(false)
    const [isEquipmentVisible, setEquipmentVisible] = useState(false)
    const [isMatserDataVisible, setMasterDataVisible] = useState(false);

    const [isDriverVisible, setDriverVisible] = useState(false)
    const [isParkingLotVisible, setParkingLotVisible] = useState(false)
    const [isNotificationsVisible, setNotificationVisible] = useState(false)
    const [isEquipRepVisible, setEquipRepVisible] = useState(false)
    const [isAvailRepVisible, setAvailRepVisible] = useState(false)
    const [isReservRepVisible, setReservRepVisible] = useState(false)
    const [isRenterRepVisible, setRenterRepVisible] = useState(false)
    const [isOwnerRepVisible, setOwnerRepVisible] = useState(false)
    const [isOfferReceivedRepVisible, setOfferReceivedRepVisible] = useState(false)
    const [isOfferSentRepVisible, setOfferSentRepVisible] = useState(false)
    const [isFavoriteRepVisible, setFavoriteRepVisible] = useState(false)
    const [isInvoiceRepVisible, setInvoiceRepVisible] = useState(false)

    const [isDBVisible, setDBVisible] = useState(false)
    const [isReportsVisible, setReportsVisible] = useState(false)
    const [isAdminVisible, setAdminVisible] = useState(false)

    const [isInterchangeApp, setInterchangeApp] = useState(true);
    const [isInspectionCompanyVisible,setInspectionCompanyVisible] = useState(false);
    const [isInterchangeVisible,setInterchangeVisible] = useState(false)

    const [isProcessVisible, setProcessVisible] = useState(false)
    const [isInsurPlanVisible, setInsurPlansVisible] = useState(false)
    const [isInsurRateVisible, setInsurRatesVisible] = useState(false)
    const [isAPIHUBVisible, setAPIHUBVisible] = useState(false)
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [userDetails, setUserDetails] = useState<any>([
        { userId: 12, name: localStorage.getItem('user') },
    ])
    const [notificationList, setNotificationList] = useState([])
    const [organizationType, setOrganizationType] = useState('')

    const [ownerReservationCount, setOwnerReservationCount] = useState(0)
    const [renterReservationCount, setRenterReservationCount] = useState(0)

    const [ownerReceiveCount, setOwnerReceiveCount] = useState("")
    const [renterMadeCount, setRenterMadeCount] = useState(0)
    const [favoriteCount, setFavoriteCount] = useState(0)
    const [showAvailable, setShowAvailable] = useState(false);
    const [availabilityType, setAvailabilityType] = useState("");
    const [showCreateAvail, setShowCreateAvail] = useState(false);

    const [isAdminInspectorLogin,setIsAdminInspectorLogin] = useState(false);



    const MenuList = {
        REPORTS: 'reports',
        ADMINISTRATION: 'administration',
        PROCESS: 'process',
        INTERCHANGEMENU: 'interchangemenu',
    }
    let onClickLogout = () => {
        /*var lastAccessed = CommonUtil.getLastAccessScreens();
        localStorage.removeItem('country');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('orgId');
        localStorage.removeItem('saasorgId');
        localStorage.removeItem('root');
        localStorage.clear();
        sessionStorage.clear();
        //navigate("/");
        setTimeout(() => {
            if(lastAccessed && lastAccessed.length>0){
                localStorage.setItem("lastAccessed",JSON.stringify(lastAccessed));
            }
            //window.location.reload();
            let siteOrigin: string = window.location.origin;
            window.location.assign(siteOrigin)
        })*/
        CommonUtil.onLogout();
    }
    const handleMenu = () => {
        setOpen(!open)
    }
    const handleSubMenu = (title: any) => {
        if (subMenuOpen === title) {
            setSubMenuOpen('')
        } else {
            setSubMenuOpen(title)
        }
    }
    let gotoMenu = () => {
        setOpen(false)
        setSubMenuOpen('')
        navigate('/menu')
    }
    let onClickBreadCrumb = (link: string) => {
        //console.log(link)
        if (link == CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipments')) {
            //Events.EQUIPMENTS
            CommonUtil.EQUIPMENT_LIST = true
            setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'))
            setBreadCrumb1('')
            setBreadCrumb2(CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipments'))
            CommonUtil.addScreensToLastAccessed(Constants.EQUIPMENTS);
            navigateToEquipList();
            //navigate('equipments')
        } else if (link == CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard')) {
            setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard'))
            setBreadCrumb1('')
            setBreadCrumb2(CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard'))
            CommonUtil.addScreensToLastAccessed("searchdb");
            navigate('searchdb')
        } else if (link == CommonUtil.getMessageText(Constants.AVAILABILITIES, 'Availabilities')) {
            setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'))
            setBreadCrumb1('')
            setBreadCrumb2(CommonUtil.getMessageText(Constants.AVAILABILITIES, 'Availabilities'))
            CommonUtil.addScreensToLastAccessed(Constants.AVAILABILITIES);
            navigate('availabilities')
        }
    }
    let onClickSearchDB = () => {
        setOpen(!open)
        setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard'))
        setIsBreadCrumb(true)
        navigate('searchdashboard')
    }
    let onClickOrgList = () => {
        setOpen(!open)
        navigateToOrgList();
    }
    let navigateToOrgList = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1(''); // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.ORGANIZATIONS, 'Organizations')); // -> Organizations");
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.ORGANIZATIONS);
        addToLastAccessed(Constants.ORG_SCREEN, "orgList");
        navigate('orgList');
    }

    let onClickEqpList = () => {
        CommonUtil.EQUIPMENT_LIST = true;
        setOpen(!open)
        addToLastAccessed(Constants.EQUIPMENT_SCREEN, "equipments");
        navigateToEquipList();
    }
    let navigateToEquipList = () => {
        CommonUtil.dispatchEVents();
        CommonUtil.EQUIPMENT_LIST = true;
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipments')); // -> Organizations");
        // setIsBreadCrumb(true)
        navigate('equipments');
        CommonUtil.addScreensToLastAccessed(Constants.EQUIPMENTS);
        localStorage.setItem('ViewTab', 'false');
        showSwitchCustomer();

    }

    let addToLastAccessed = (sName: string, aName: string) => {
        CommonUtil.addScreensToCache(sName, aName, CommonUtil.getUserId());
        setPage(Math.random() * 100000);
    }
    let onClickEqpReport = () => {
        CommonUtil.EQUIPMENT_LIST = false;
        setOpen(!open)
        addToLastAccessed(Constants.EQUIPMENT_REPORT, "equipments");
        navigateToEquipReport();
    }
    let navigateToEquipReport = () => {
        CommonUtil.dispatchEVents();
        CommonUtil.EQUIPMENT_LIST = false;
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports')); // -> Organizations");
        setBreadCrumb1(CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipments')); // -> Organizations");
        setIsBreadCrumb(true);
        navigate('equipments');
        CommonUtil.addScreensToLastAccessed(Constants.EQUIP_REPORT);
        localStorage.setItem('ViewTab', 'false');
        showSwitchCustomer();
    }
    let onClickAvailableReport = () => {
        setOpen(!open)
        navigateToAvailabilityReport();
    }

    let navigateToAvailabilityReport = () => {
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports')); // -> Organizations");
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.AVAILABILITIES, 'Availabilities')); // -> Organizations");
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.AVAILABILITIES);
        addToLastAccessed(Constants.AVAILABILITY_SCREENS, "availabilities");
        navigate('availabilities');
    }

    let onClickAdminReservations = ()=>{
        setOpen(!open)
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'))
        setBreadCrumb1('')
        setBreadCrumb2(CommonUtil.getMessageText(Constants.ADMIN_RESERVATIONS, 'Reservations'))
        setIsBreadCrumb(true)
        CommonUtil.addScreensToLastAccessed(Constants.ADMIN_RESERVATIONS);
        addToLastAccessed(Constants.ADMIN_RESERVATION_SCREENS, "adminreservations");
        navigate('adminreservations');
    }
    let onClickReservations = () => {
        setOpen(!open)
        onClickReservationsHeader()
        /*setBreadCrumb('Reports')
        setBreadCrumb1('Owner Reservations')
        setIsBreadCrumb(true)
        navigate('ownerreservations')*/
        // navigate('reservations');
    }
    let onClickReservationsHeader = () => {
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'))
        setBreadCrumb1('')
        setBreadCrumb2(CommonUtil.getMessageText(Constants.OWNER_RESERVATIONS, 'Owner Reservations'))
        setIsBreadCrumb(true)
        CommonUtil.addScreensToLastAccessed(Constants.OWNER_RESERVATIONS);
        addToLastAccessed(Constants.OWNER_RESERVATION_SCREENS, "ownerreservations");
        navigate('ownerreservations');
    }
    let onClickRenterReservations = () => {
        setOpen(!open)
        onClickRenterReservationsHeader()
    }
    let onClickRenterReservationsHeader = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'))
        setBreadCrumb1('')
        setBreadCrumb2(CommonUtil.getMessageText(Constants.RENTER_RESERVATIONS, 'Renter Reservations'))
        setIsBreadCrumb(true)
        CommonUtil.addScreensToLastAccessed(Constants.RENTER_RESERVATIONS);
        addToLastAccessed(Constants.RENTER_RESERVATION_SCREENS, "renterreservations");
        navigate('renterreservations')
    }
    let onClickOffers = () => {
        setOpen(!open)
        onClickOfferHeader()
    }

    let onClickOfferHeader = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'));
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.OFFERS_SENT, 'Offers Sent'));
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.OFFERS_SENT);
        addToLastAccessed(Constants.OFFER_SENT_SCREENS, "offersmade");
        navigate('offersmade');
    }
    let onClickOffersReceived = () => {
        setOpen(!open);
        onClickOfferReceiveHeader();
    }
    let onClickOfferReceiveHeader = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports')); // -> Organizations");
        setBreadCrumb1(''); // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.OFFERS_RECEIVED, 'Offers received'));
        CommonUtil.addScreensToLastAccessed(Constants.OFFERS_RECEIVED);
        addToLastAccessed(Constants.OFFER_RECEIVED_SCREENS, "offersreceived");
        setIsBreadCrumb(true);
        navigate('offersreceived');
    }
    let onClickEqpView = (str: string) => {
        const params = new URLSearchParams(window.location.search)
        console.log(params)
        let screenType: string = params.get('screenType') as string
        CommonUtil.SCREEN_NAME = screenType
        console.log(screenType)
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1(screenType);
        setBreadCrumb2(CommonUtil.getMessageText(Constants.EQUIPMENT_VIEW, 'Equipment View')); // -> Organizations");
        setIsBreadCrumb(true)
        CommonUtil.SELECT_EQUIP_ID = params.get('equipid') as string
        navigate('equipview/' + str)
    }
    let onClickCustProfiles = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open)
        navigateToCustomerProfiles();
    }

    let onClickInsurancePlans = ()=>{
        CommonUtil.dispatchEVents();
        setOpen(!open);
        navigateToInsurancePlans();
    }

    let navigateToInsurancePlans = ()=>{
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.INSURANCE_PLANS, 'Insurance Plans')); // -> Organizations");
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.INSURANCE_PLANS);
        addToLastAccessed(Constants.INSURANCE_PLANS_SCREEN, "insurplans");
        navigate('insurplans');
    }

    let onClickInsuranceRateTable = ()=>{
        CommonUtil.dispatchEVents();
        setOpen(!open);
        navigateToInsuranceRatePlans();
    }

    let navigateToInsuranceRatePlans = ()=>{
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.INSURANCE1_PLANS_RATE, 'Insurance Rate Table')); // -> Organizations");
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.INSURANCE1_PLANS_RATE);
        addToLastAccessed(Constants.RATE_TABLE_SCREEN, "insurRatePlans");
        navigate('insurRatePlans');
    }

    let navigateToCustomerProfiles = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.CUSTOMER_PROFILES, 'Customer Profiles')); // -> Organizations");
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.CUSTOMER_PROFILES);
        addToLastAccessed(Constants.CUSTOMER_PROFILES_SCREEN, "custProfiles");
        navigate('custProfiles');
    }
    let onClickDiverList = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open);
        navigateToDriver();
    }
    let navigateToDriver = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1(''); // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.DRIVER, 'Driver')); // -> Organizations");
        CommonUtil.addScreensToLastAccessed(Constants.DRIVER);
        setIsBreadCrumb(true);
        addToLastAccessed(Constants.DRIVER_SCREEN, "driver");
        navigate('driver');
    }
    let onClickParkingLot = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open);
        navigateToParkingLot();
    }
    let navigateToParkingLot = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1(''); // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.PARKING_LOT, 'Parking Lot'));
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.PARKING_LOT);
        addToLastAccessed(Constants.PARKING_LOT_SCREEN, "parking");
        navigate('parking');
    }

    let onClicNotifications = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open);
        navigateToNotifications();
    }
    let navigateToNotifications = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')); // -> Organizations");
        setBreadCrumb1(''); // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications'));
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.NOTIFICATIONS);
        addToLastAccessed(Constants.NOTIFICATION_SCREEN, "notifications");
        navigate('notifications');
    }

    let onClickInspectionCompany = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open)
        navigateToInspectionCompany();
    }
    let navigateToInspectionCompany = ()=>{
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'));
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.INSPECTION_COMPANY, 'Inspection Company'));
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.INSPECTION_COMPANY);
        addToLastAccessed(Constants.INSPECTION_COMPANY_SCREEN, "InspectionCompany");
        navigate('InspectionCompany');
    }

    let onClickInterchangeApplication = () => {
        localStorage.setItem("DocumentStatus","SCHEDULED")
        CommonUtil.dispatchEVents();
        setOpen(!open)
        navigateToInterchangeApplication();
    }
    let navigateToInterchangeApplication = ()=>{
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'));
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.INTERCHANGE_APPLICATION, 'Interchange Dashboard'));
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.INTERCHANGE_APPLICATION);
        addToLastAccessed(Constants.INTERCHANGE_APPLICATION_SCREEN, "InterchangeApplication");
        navigate('InterchangeApplication');
    }

    let onClicMasterData = () => {
        setOpen(!open)
        navigateToMasterData();
    }
    let navigateToMasterData = () => {
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'));
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.MASTER_DATA, 'Master data import'));
        CommonUtil.addScreensToLastAccessed(Constants.MASTER_DATA);
        addToLastAccessed(Constants.MASTER_DATA_SCREEN, "masterdata");
        setIsBreadCrumb(true);
        navigate('masterdata');
    }



    let onClickProcessReservation = () => {
        localStorage.setItem("ShowReservation", "true");
        let siteOrigin: string = window.location.origin;
        siteOrigin = siteOrigin + "/?requestor=showReservation&equipId=";//+"&token="+ localStorage.getItem("token");
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();
    }

    let onNavigateToReservationProcess = () => {
        setBreadCrumb('Process') // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2('Process Reservations')
        setIsBreadCrumb(true)
        navigate('processReserve')
    }

    let onClickApiHub = () => {
        setOpen(!open)
        navigateToAPIHub();
    }

    let navigateToAPIHub = () => {
        setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'));
        setBreadCrumb1('');
        setBreadCrumb2(CommonUtil.getMessageText(Constants.API_HUB, 'API HUB'))
        setIsBreadCrumb(true);
        CommonUtil.addScreensToLastAccessed(Constants.API_HUB);
        addToLastAccessed(Constants.API_HUB_SCREEN, "apihub");
        navigate('apihub')
    }

    let onClickLeftBar = () => {
        if (divRef && divRef.current) {
            if (divRef.current?.classList.contains('show')) {
                // divRef.current?.classList.add("hide");
                divRef.current?.classList.remove('show')
                btnRef.current?.classList.add('active')
            } else {
                divRef.current?.classList.add('show')
                btnRef.current?.classList.remove('active')
                // divRef.current?.classList.remove("hide");
            }

            //console.log(divRef.current?.classList.contains("show1"));
        }
    }
    let onClickDB = () => {
        if ($('#imgDB').hasClass('icon-rotate-90')) {
            $('#imgDB').addClass('icon-rotate-180')
            $('#imgDB').removeClass('icon-rotate-90')
            $('#submenu1').addClass('show')
        } else {
            $('#imgDB').addClass('icon-rotate-90')
            $('#imgDB').removeClass('icon-rotate-180')
            $('#submenu1').removeClass('show')
        }
    }

    let onClickProcess = () => {
        if ($('#imgProcess').hasClass('icon-rotate-90')) {
            $('#imgProcess').addClass('icon-rotate-180')
            $('#imgProcess').removeClass('icon-rotate-90')
            $('#submenu4').addClass('show')
        } else {
            $('#imgProcess').addClass('icon-rotate-90')
            $('#imgProcess').removeClass('icon-rotate-180')
            $('#submenu4').removeClass('show')
        }
    }
    let onClickRep = () => {
        if ($('#imgRep').hasClass('icon-rotate-90')) {
            $('#imgRep').addClass('icon-rotate-180')
            $('#imgRep').removeClass('icon-rotate-90')
            $('#submenu2').addClass('show')
        } else {
            $('#imgRep').addClass('icon-rotate-90')
            $('#imgRep').removeClass('icon-rotate-180')
            $('#submenu2').removeClass('show')
        }
    }

    let onClickAdmin = () => {
        if ($('#imgAdmin').hasClass('icon-rotate-90')) {
            $('#imgAdmin').addClass('icon-rotate-180')
            $('#imgAdmin').removeClass('icon-rotate-90')
            $('#submenu3').addClass('show')
        } else {
            $('#imgAdmin').addClass('icon-rotate-90')
            $('#imgAdmin').removeClass('icon-rotate-180')
            $('#submenu3').removeClass('show')
        }
    }

    const breadCrumbMargin = () => {
        let height = document.getElementById('topbar');
        let breadCrumbMargin: any = document.getElementById('topbar-breadcrumbs');
        breadCrumbMargin.style.marginTop = `${height?.clientHeight}px`;
    }

    useEffect(() => {
        localStorage.setItem("PublicSearch", "false");
        setTimeout(() => {
            breadCrumbMargin();
        }, 500)
        //console.log(CommonUtil.getUserId(), 'hello user id')
        //  $(window).click(function() {});

        //console.log(localStorage.getItem("ViewTab"));
        // console.log(localStorage.getItem('root'));
        clearLanguageSelection()
        showSelectedLanguage()
        if (NetworkManager.serverPath) {
            getOrganizationPermissions()
        } else {
            getServerPath()
        }

        function handleClickOutside(event: any) {
            if (divRef.current && !divRef.current.contains(event.target) && !menuButtonRef.current?.contains(event.target)) {
                setOpen(false)
            }
        }

        subscribe(Events.EQUIPMENT_EVENT, (dataObj: any) => {
            //console.log(data.detail)
            showCreateEquipBreadCrumb(dataObj)
        })
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener(Events.BREAD_CRUMB_EVENT, onClearBreadCrubmb);
        document.addEventListener(Events.FAVORITE_EVENT, getFavoritiesCount);
        document.addEventListener(Events.FAVORITE_EVENT, getFavoritiesCount);
        document.addEventListener(Events.OFFER_EVENT, onGetAllOffers);
        document.addEventListener(Events.RESERVATION_EVENT, onGetAllReservations);
        document.addEventListener(Events.PROCESS_RESERVATION_EVENT, onLoadProcessReservationScreen);
        document.addEventListener(Events.CREATE_EQUIPMENT, onCreateEquipment);
        document.addEventListener(Events.SAVE_EQUIPMENT, onSaveEquipment);
        document.addEventListener(Events.HIDE_AVAILABILITY, onHideAvailability);
        document.addEventListener(Events.SHOW_AVAILABILITY, onShowAvailability);
        window.addEventListener('resize', breadCrumbMargin);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener(Events.BREAD_CRUMB_EVENT, onClearBreadCrubmb);
            document.removeEventListener(Events.FAVORITE_EVENT, getFavoritiesCount);
            document.removeEventListener(Events.OFFER_EVENT, onGetAllOffers);
            document.removeEventListener(Events.RESERVATION_EVENT, onGetAllReservations);
            document.removeEventListener(Events.CREATE_EQUIPMENT, onCreateEquipment);
            document.removeEventListener(Events.SAVE_EQUIPMENT, onSaveEquipment);
            document.removeEventListener(Events.HIDE_AVAILABILITY, onHideAvailability);
            document.removeEventListener(Events.SHOW_AVAILABILITY, onShowAvailability);
            window.removeEventListener('resize', breadCrumbMargin);
        }
    }, [divRef])

    let onClearBreadCrubmb = () => {
        /*setBreadCrumb("");
        setBreadCrumb1(""); // -> Organizations");
        setBreadCrumb2("");
        setIsBreadCrumb(false);*/
        onNavigateToSearchDB();
    }

    let onGetAllOffers = () => {
        getOfferReceivers();
        getOffersMade();
    }

    let onGetAllReservations = () => {
        getOwnerReservations();
        getRenterReservations();
    }

    let onLoadProcessReservationScreen = () => {
        $("#wrapper").hide();
    }

    let onCreateEquipment = () => {
        console.log("create equipment");
        closeAvailCreatePopup();
        onNavigateToCreateEquipmentScreen();
    }

    let onSaveEquipment = () => {
        $("#linkAvailability").removeClass("disable-form");
        $("#linkAvailability1").removeClass("disable-form");
        setShowCreateAvail(true);
    }

    let onShowAvailability = () => {
        // alert("show");
        $("#linkAvailability").removeClass("disable-form");
        $("#linkAvailability1").removeClass("disable-form");
    }

    let onHideAvailability = () => {
        // alert("hide");
        $("#linkAvailability").addClass("disable-form");
        $("#linkAvailability1").addClass("disable-form");
    }

    let showCreateEquipBreadCrumb = (dataObj: any) => {
        if (dataObj && dataObj.detail) {
            if (dataObj.detail.data == Events.CREATE_EQUIPMENT) {
                setBreadCrumb1(CommonUtil.SCREEN_NAME)
                setBreadCrumb2(Events.CREATE_EQUIPMENT) //  breadCrumb2
            } else if (dataObj.detail.data == Events.EDIT_EQUIPMENT) {
                setBreadCrumb1(CommonUtil.SCREEN_NAME)
                setBreadCrumb2(Events.EDIT_EQUIPMENT) //  breadCrumb2
            } else if (dataObj.detail.data == Events.EQUIPMENTS) {
                setBreadCrumb2(CommonUtil.SCREEN_NAME)
                setBreadCrumb1('') //  breadCrumb2
            } else if (dataObj.detail.data == Events.CONFIRM_RESERVATION) {
                setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard'));
                setBreadCrumb1(CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard')); // -> Organizations");
                setBreadCrumb2(CommonUtil.getMessageText(Constants.RESERVATION_DETAILS, 'Reservation Details'))
                setIsBreadCrumb(true)
            } else if (dataObj.detail.data == Events.EQUIPMENTS_VIEW) {
                if (CommonUtil.SCREEN_NAME == CommonUtil.AVAILABILITIES) {
                    setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'));
                } else {
                    setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration'))
                }
                setBreadCrumb1(CommonUtil.SCREEN_NAME)
                setBreadCrumb2(Events.EQUIPMENTS_VIEW) //  breadCrumb2
            }
        }
    }

    let getServerPath = () => {
        $.ajax({
            url: 'server.xml',
            beforeSend: function () {
            },
            success: function (data, statusCode, jqXHR) {
                var xmlResponse = $(data).find('Server')
                for (var i = 0; i < xmlResponse.length; i++) {
                    var item = xmlResponse[i]
                    let routeUrl = $(item).find('URL').text()
                    //console.log(routeUrl)
                    NetworkManager.serverPath = routeUrl
                    getOrganizationPermissions()
                }
            },
            error: function () {
            },
        })
    }
    let onGetNotifications = (data: any) => {
        if (data) {
            let restData = JSON.parse(data)
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    console.log(restData, 'restData')
                    if (restData?.notificationList.length > 0) {
                        setNotificationList(restData.notificationList)
                        setModalShow(true)
                    } else {
                        getAllAssociations()
                    }
                }
            }
        }
    }
    let onGetMasters = (data: any) => {
        if (data) {
            let restData = JSON.parse(data)
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    console.log(restData, 'restData')
                    setNotificationList(restData.notificationList)
                    setModalShow(true)
                }
            }
        }
    }
    let getAllAssociations = () => {
        NetworkManager.sendJsonRequest('api/notification/all', '', '', 'GET', true, onGetMasters, onLoginError);
    }
    let getUserAssociation = () => {
        let userId = CommonUtil.getUserId();
        NetworkManager.sendJsonRequest('api/notification/userAssociations?userId=' + userId, '', '', 'GET', true, onGetNotifications, onLoginError);
    }
    let getOrganizationPermissions = () => {
        var qParam = CommonUtil.getSaasOrgId()
        var orgUrl = 'api/organization/' + qParam;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError);
        console.log(localStorage);
        if (localStorage.getItem('userLogin') == 'true') {
            //onClickCustProfiles();
            localStorage.setItem('userLogin', 'false')
            setBreadCrumb(CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')) // -> Organizations");
            setBreadCrumb1(CommonUtil.getMessageText(Constants.CUSTOMER_PROFILES, 'Customer Profiles')) // -> Organizations");
            setIsBreadCrumb(true)
            //  setBreadCrumb('Administration/Customer Profiles')
            navigate('custProfiles')
        } else if (localStorage.getItem('ViewTab') == 'true') {
            localStorage.setItem('ViewTab', 'false');
            localStorage.setItem('ViewTab1', 'true');
            onClickEqpView(window.location.search)

            /*const params = new URLSearchParams(window.location.search)

            let screenType:string = params.get('screenType') as string;
            setBreadCrumb('Administration') // -> Organizations");
            setBreadCrumb1(screenType);
            setBreadCrumb2('Equipment View') // -> Organizations");
            setIsBreadCrumb(true)


            CommonUtil.SELECT_EQUIP_ID = params.get('equipid') as string
            navigate('equipview/' + params);*/
        } else if (localStorage.getItem('ReservationTab') == 'true') {
            localStorage.setItem('ReservationTab', 'false');
            localStorage.setItem('ReservationTab1', 'true');
            // onClickEqpView(window.location.search);

            const params = new URLSearchParams(window.location.search) // id=123
            CommonUtil.SELECT_RESERVATION_ID = params.get('reservationId') as string
            CommonUtil.SELECT_EQUIP_ID = params.get('equipid') as string
            CommonUtil.EQUIP_RESERVE_ASSET_TYPE = params.get('assetType') as string
            CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = params.get('equipType') as string
            CommonUtil.EQUIP_RESERVE_IS_GENERIC = params.get('generic') as string
            CommonUtil.SELECT_EQUIP_AVAIL_ID = params.get('availId') as string

            setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports')); // -> Organizations");
            setBreadCrumb1(CommonUtil.getMessageText(Constants.VIEW_RESERVATION, 'View Reservation')); // -> Organizations");
            setIsBreadCrumb(true);
            navigate('viewreservation');
        } else if (localStorage.getItem('BillingTab') == 'true') {
            localStorage.setItem('BillingTab', 'false');
            localStorage.setItem('BillingTab1', 'true');
            // onClickEqpView(window.location.search);

            const params = new URLSearchParams(window.location.search) // id=123
            CommonUtil.SELECT_RESERVATION_ID = params.get('reservationId') as string
            CommonUtil.SELECT_EQUIP_ID = params.get('equipid') as string
            CommonUtil.EQUIP_RESERVE_ASSET_TYPE = params.get('assetType') as string
            CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = params.get('equipType') as string
            CommonUtil.EQUIP_RESERVE_IS_GENERIC = params.get('generic') as string
            CommonUtil.SELECT_EQUIP_AVAIL_ID = params.get('availId') as string

            setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports')); // -> Organizations");
            setBreadCrumb1(CommonUtil.getMessageText(Constants.VIEW_RESERVATION, 'View Reservation')); // -> Organizations");
            setIsBreadCrumb(true);
            navigate('invoice');
        }else if (localStorage.getItem('PublicViewTab') == 'true') {
            setSwitchCustomer(false);
            localStorage.setItem('PublicViewTab', 'false');
            localStorage.setItem('PublicViewTab1', 'true');
            // onClickEqpView(window.location.search);
            const params = new URLSearchParams(window.location.search) // id=123
            //CommonUtil.SELECT_RESERVATION_ID = params.get('reservationId') as string
            CommonUtil.SELECT_EQUIP_ID = params.get('equipId') as string
            //CommonUtil.EQUIP_RESERVE_ASSET_TYPE = params.get('assetType') as string
            CommonUtil.SELECT_EQUIPMENT_TYPE = params.get('equipType') as string
            CommonUtil.EQUIP_RESERVE_IS_GENERIC = params.get('isGeneric') as string
            CommonUtil.SELECT_EQUIP_AVAIL_ID = params.get('avaiId') as string

            setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
            setBreadCrumb1(CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard'));
            setBreadCrumb2(CommonUtil.getMessageText(Constants.EQUIP_PUBLIC_VIEW, 'Equipment Public View')); // -> Organizations");
            setIsBreadCrumb(true)
            navigate('equippublic')
        } else if (localStorage.getItem('CustomerViewTab') == 'true') {
            localStorage.setItem('CustomerViewTab', 'false')
            setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
            setIsBreadCrumb(true)
            navigate('custProfiles')
        } else if (localStorage.getItem('ShowReservation') == 'true') {
            onNavigateToReservationProcess();
            localStorage.setItem("ShowReservation", "false");
            localStorage.setItem('ShowReservation1', 'true');
        } else if (localStorage.getItem('CreateEquip') == 'true') {
            const params = new URLSearchParams(window.location.search);
            let strEquipId = params.get('equipGenType') as string;
            if (strEquipId) {
                CommonUtil.IS_GENERIC_EQUIP = true;
            } else {
                CommonUtil.IS_GENERIC_EQUIP = false;
            }
            //CommonUtil.IS_GENERIC_EQUIP = params.get('equipGenType') as boolean;
            onNavigateToCreateEquipmentScreen();
            localStorage.setItem("CreateEquip", "false");
            localStorage.setItem('CreateEquip1', 'true');
        } else if (sessionStorage.getItem('LAS1')) {
            let requestor: string = sessionStorage.getItem("LAS1") as string;
            if (requestor.indexOf("searchdb") >= 0) {
                sessionStorage.setItem('LAS1', "");
                onNavigateToSearchDB();
            } else if (requestor.indexOf(Constants.EQUIPMENTS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToEquipList();
            } else if (requestor.indexOf(Constants.EQUIP_REPORT) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToEquipReport();
            } else if (requestor.indexOf(Constants.ORGANIZATIONS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToOrgList();
            } else if (requestor.indexOf(Constants.AVAILABILITIES) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToAvailabilityReport();
            } else if (requestor.indexOf(Constants.OFFERS_RECEIVED) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickOfferReceiveHeader();
            } else if (requestor.indexOf(Constants.OFFERS_SENT) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickOfferHeader();
            } else if (requestor.indexOf(Constants.RENTER_RESERVATIONS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickRenterReservationsHeader();
            } else if (requestor.indexOf(Constants.OWNER_RESERVATIONS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickReservationsHeader();
            } else if (requestor.indexOf(Constants.ADMIN_RESERVATIONS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickAdminReservations();
            } else if (requestor.indexOf(Constants.DRIVER) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToDriver();
            } else if (requestor.indexOf(Constants.PARKING_LOT) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToParkingLot();
            } else if (requestor.indexOf(Constants.MASTER_DATA) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToMasterData();
            }else if (requestor.indexOf(Constants.INSPECTION_COMPANY) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToInspectionCompany();
            }else if (requestor.indexOf(Constants.INTERCHANGE_APPLICATION) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToInterchangeApplication();
            }
            else if (requestor.indexOf(Constants.API_HUB) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToAPIHub();
            } else if (requestor.indexOf(Events.FAVORITIES) >= 0) {
                sessionStorage.setItem('LAS1', "");
                onClickFavoritiesReport();
            } else if (requestor.indexOf(Constants.CUSTOMER_PROFILES) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToCustomerProfiles();
            }else if (requestor.indexOf(Constants.INSURANCE_PLANS) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToInsurancePlans();
            }else if (requestor.indexOf(Constants.INSURANCE1_PLANS_RATE) >= 0) {
                sessionStorage.setItem('LAS1', "");
                navigateToInsuranceRatePlans();
            }

        }
    }

    let onNavigateToCreateEquipmentScreen = () => {
        navigate('equipDetails');
    }

    let getUserPermissions = () => {
        var permissionUrl = 'api/organization/user/permissions'
        NetworkManager.sendJsonRequest(permissionUrl, '', '', 'GET', true, onGetUserPermissions, onLoginError)
    }

    let onGetUserPermissions = (dataObj: any) => {
        //console.log(dataObj)
        let prmissions: any = []
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    prmissions = restData
                } else {
                    prmissions.push(restData)
                }
            }
        }

        prmissions.forEach((item: any, idx: number) => {
            if (item.permissionType == 'Web') {
                if (item.permissionGroup == 'Configuration') {
                    setAdminVisible(true)
                } else if (item.permissionGroup == 'Dashboard') {
                    setDBVisible(true)
                } else if (item.permissionGroup == 'Reports') {
                    setReportsVisible(true)
                }
            }
        })

        prmissions.forEach((pItem: any, idx: number) => {

            if (pItem.permissionType == 'Web') {
                if (pItem.permissionCode == 'ORGANIZATIONS') {
                    setOrgVisible(true);
                } else if (pItem.permissionCode == 'DRIVERS') {
                    setDriverVisible(true);
                } else if (pItem.permissionCode == 'PARKING_LOTS') {
                    setParkingLotVisible(true);
                } else if (pItem.permissionCode == 'EQUIPMENT') {
                    setEquipmentVisible(true);
                } else if (pItem.permissionCode == 'MATSER_DATA') {
                    setMasterDataVisible(true);
                }else if (pItem.permissionCode == 'INSPECTION_COMPANY') {
                    setInterchangeApp(true);
                    setInspectionCompanyVisible(true);
                }else if (pItem.permissionCode == 'INTERCHANGE_APPLICATION') {
                    setInterchangeApp(true);
                    setInterchangeVisible(false);
                }
                else if (pItem.permissionCode == 'RP_EQUIPMENT') {
                    setEquipRepVisible(true);
                } else if (pItem.permissionCode == 'RP_AVAILABILITIES') {
                    setAvailRepVisible(true);
                } else if (pItem.permissionCode == 'RP_RESERVATIONS') {
                    setReservRepVisible(true);
                } else if (pItem.permissionCode == 'INVOICES') {
                    setInvoiceRepVisible(true);
                } else if (pItem.permissionCode == 'RESERVATIONS') {
                    setProcessVisible(false);
                    setProcessReserveVisible(true);
                } else if (pItem.permissionCode == 'NOTIFICATIONS') {
                    setNotificationVisible(true);
                } else if (pItem.permissionCode == 'INVOICES_DASHBOARD') {
                    setInvoiceDBVisible(true);
                } else if (pItem.permissionCode == '360_VIEW') {
                    setCompanyDBVisible(true);
                } else if (pItem.permissionCode == 'SEARCH_DASHBOARD') {
                    setSearchDBVisible(true);
                    if (localStorage.getItem('ViewDB') == 'true') {
                        localStorage.setItem('ViewDB', 'false');
                        onNavigateToSearchDB();
                    }
                } else if (pItem.permissionCode == 'OWNER_RESERVATION') {
                    setOwnerRepVisible(true);
                } else if (pItem.permissionCode == 'RENTER_RESERVATION') {
                    setRenterRepVisible(true);
                } else if (pItem.permissionCode == 'OFFERS_RECEIVE') {
                    setOfferReceivedRepVisible(true);
                } else if (pItem.permissionCode == 'OFFERS_SENT') {
                    setOfferSentRepVisible(true);
                } else if (pItem.permissionCode == 'FAVORITIES') {
                    setFavoriteRepVisible(true);
                } else if (pItem.permissionCode == 'API_HUB') {
                    setAPIHUBVisible(true);
                }else if (pItem.permissionCode == 'INSURANCE_PLANS') {
                    setInsurPlansVisible(true);
                }else if (pItem.permissionCode == 'INSURANCE_RATES') {
                    setInsurRatesVisible(true);
                }
            }
        })
    }

    let showSelectedLanguage = () => {
        if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.ENG_LANGUAGE) {
            engRef.current?.classList.add('language-sel-item')
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.FR_LANGUAGE) {
            frRef.current?.classList.add('language-sel-item')
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.SP_LANGUAGE) {
            spRef.current?.classList.add('language-sel-item')
        }
    }
    let onChangeLan = (lan: string) => {
        if (lan == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.SELECTED_LANGUAGE = CommonUtil.ENG_LANGUAGE
        } else if (lan == CommonUtil.FR_LANGUAGE) {
            CommonUtil.SELECTED_LANGUAGE = CommonUtil.FR_LANGUAGE
        } else if (lan == CommonUtil.SP_LANGUAGE) {
            CommonUtil.SELECTED_LANGUAGE = CommonUtil.SP_LANGUAGE
        }
        localStorage.setItem("language", CommonUtil.SELECTED_LANGUAGE);
        publish(Events.LANGUAGE_CHANGE_EVENT, { data: lan })
        Events.dispatchChangeLanguage();
        setPage(Math.random() * 100000)
        clearLanguageSelection()
        showSelectedLanguage()
    }
    let clearLanguageSelection = () => {
        engRef.current?.classList.remove('language-sel-item')
        frRef.current?.classList.remove('language-sel-item')
        spRef.current?.classList.remove('language-sel-item')
    }
    let onOrgDetailsSuccess = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data)
            if (restData) {
                CommonUtil.CUSTOMER_COUNTRY = restData.country;
                CommonUtil.ORGANIZATION_TYPE = restData.organizationType;
                localStorage['orgType'] = restData.organizationType;
                localStorage.setItem('country', restData.country);
                setOrganizationType(restData.organizationType);
                if (restData.ownerStatus) {
                    CommonUtil.OWNER_STATUS = restData.ownerStatus;
                }else{
                    CommonUtil.OWNER_STATUS = "";
                }
                if (restData.renterStatus) {
                    CommonUtil.RENTER_STATUS = restData.renterStatus;
                }else{
                    CommonUtil.RENTER_STATUS = "";
                }
                if (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER) {
                    getOwnerReservations();
                    getOfferReceivers();
                } else if (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER) {
                    getRenterReservations();
                    getOffersMade();
                } else {
                    getOwnerReservations();
                    getRenterReservations();
                    getOfferReceivers();
                    getOffersMade();
                }
                getFavoritiesCount();
                setEquipmentVisible(false);
                setAvailRepVisible(false);
                setDriverVisible(false);
                setParkingLotVisible(false);
                setEquipRepVisible(false);
                setMasterDataVisible(false);
                setInsurPlansVisible(false);
                setInsurRatesVisible(false);
                setInspectionCompanyVisible(false);
                setInterchangeVisible(false);
                setInterchangeApp(false);

                if(restData.isInterchangeMenuEnabled && restData.isInterchangeMenuEnabled  == "Y"){
                    setInterchangeApp(true);
                    setInspectionCompanyVisible(true);
                }

                if(localStorage.getItem('orgType')=="INSPECTION_COMPANY"){
                    setInterchangeApp(true);
                }

                if (CommonUtil.OWNER_STATUS == CommonUtil.APPROVED) {
                    setEquipmentVisible(true);
                    setAvailRepVisible(true);
                    //setDriverVisible(fa);
                    setParkingLotVisible(true);
                    setEquipRepVisible(true);


                }
                if (CommonUtil.RENTER_STATUS == CommonUtil.APPROVED) {
                    setDriverVisible(true);
                }

                if (CommonUtil.isRootUser()) {
                    setSwitchCustomer(true)
                    getUserPermissions()
                    // setSearchDBVisible(true)
                    // setCompanyDBVisible(true)
                } else {
                    let interchangeAPI: string = 'api/organization/interchangeMenuEnabled?saasOrgId='+CommonUtil.getSelectedOrgId();

                    NetworkManager.sendJsonRequest(interchangeAPI, '', '', 'GET', true,(dataObj:any)=>{
                        if (dataObj) {
                            var orgData = CommonUtil.parseData(dataObj);
                            if (orgData) {
                                console.log(orgData);
                                if(orgData.isInterchangeMenuEnabled && orgData.isInterchangeMenuEnabled  == "Y"){
                                    setInterchangeApp(true);
                                    setInspectionCompanyVisible(true);
                                }
                                //setIntechangeMenu(restData.isInterchangeMenuEnabled == "Y"?true:false);
                                //setPage(""+Math.random()*100000);
                            }
                        }
                    },onLoginError);

                    setSwitchCustomer(false);
                    setSearchDBVisible(true);
                    setCompanyDBVisible(true);
                    setInvoiceDBVisible(true);
                    setProcessReserveVisible(true);
                    setMasterDataVisible(true);
                    setInspectionCompanyVisible(true);
                    if(localStorage.getItem('orgType')=="INSPECTION_COMPANY")
                    {
                        setInterchangeVisible(false);
                        setIsAdminInspectorLogin(true);
                    }else{
                        setInterchangeVisible(true);
                    }

                    // setEquipmentVisible(true);
                    // setDriverVisible(true);
                    //setParkingLotVisible(true);

                    //setEquipRepVisible(true);
                    // setAvailRepVisible(true);
                    setNotificationVisible(true);
                    setReservRepVisible(true);
                    setInvoiceRepVisible(true);
                    setDBVisible(true);
                    setReportsVisible(true);
                    setAdminVisible(true);
                    setProcessVisible(false);
                    setAPIHUBVisible(true);
                    setRenterRepVisible(true);
                    setOwnerRepVisible(true);
                    setOfferReceivedRepVisible(true);
                    setOfferSentRepVisible(true);
                    setFavoriteRepVisible(true);
                    setInvoiceRepVisible(true);
                }
                //console.log(localStorage.getItem('ViewTab1'));
                if(localStorage.getItem('ViewTab1') == "true"){
                    setSwitchCustomer(false);
                    localStorage.setItem('ViewTab1',"false");
                }else if(localStorage.getItem('ReservationTab1') == "true"){
                    setSwitchCustomer(false);
                    localStorage.setItem('ReservationTab1',"false");
                }else if(localStorage.getItem('PublicViewTab1') == "true"){
                    setSwitchCustomer(false);
                    localStorage.setItem('PublicViewTab1',"false");
                }else if(localStorage.getItem('BillingTab1') == "true"){
                    setSwitchCustomer(false);
                    localStorage.setItem('BillingTab1',"false");
                }else if (localStorage.getItem("switchCustomer") && localStorage.getItem("switchCustomer") == "true") {
                    setSwitchCustomer(true);
                }


                //(organizationType == CommonUtil.ORG_TYPE_OWNER || CommonUtil.RENTER_STATUS == CommonUtil.PENDING)

                if (localStorage.getItem('ViewDB') == 'true' && (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) && (CommonUtil.RENTER_STATUS == CommonUtil.APPROVED || CommonUtil.OWNER_STATUS == CommonUtil.APPROVED)) {
                    localStorage.setItem('ViewDB', 'false');
                    onNavigateToSearchDB();
                }

                if (restData.logoPath) {
                    var qParam: string = 'objectPath=' + restData.logoPath
                    NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, onDownloadSuccess, onLoginError);
                }
            }
        }
    }

    let showSwitchCustomer = ()=>{
        if ((localStorage.getItem("switchCustomer") && localStorage.getItem("switchCustomer") == "true") || CommonUtil.isRootUser()) {
            setSwitchCustomer(true);
        }
    }

    let getFavoritiesCount = () => {
        let equipAllAPI: string = 'api/favorite/all?';// + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllFavors, onLoginError);
    }
    let onGetAllFavors = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                setFavoriteCount(equips.length);
                // setEquipData(equips);
            }
        }
    }

    let getOwnerReservations = () => {
        let payload = { ownerOrgId: CommonUtil.getSelectedOrgId() }
        let usrUrl = "api/reservation/ownercount?saasOrgId=" + CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(usrUrl, '', '', 'GET', true, onSuccessOwnerReservations, onLoginError);
    }
    let onSuccessOwnerReservations = (dataObj: any) => {
        let count: number = 0
        let owners = []
        if (dataObj) {
            let restData = CommonUtil.parseData(dataObj)
            /*if (restData) {
                if (Array.isArray(restData)) {
                    owners = restData
                } else {
                    owners.push(restData)
                }
            }*/
            count = restData.numValue;//owners.length
        }
        setOwnerReservationCount(count)
    }
    let getRenterReservations = () => {
        let payload = { renterOrgId: CommonUtil.getSelectedOrgId() }
        let usrUrl = 'api/reservation/rentercount?saasOrgId=' + CommonUtil.getSelectedOrgId()
        NetworkManager.sendJsonRequest(usrUrl, '', '', 'GET', true, onSuccessRenterReservations, onLoginError);
    }
    let onSuccessRenterReservations = (dataObj: any) => {
        let count: number = 0
        let owners = []
        if (dataObj) {
            let restData = CommonUtil.parseData(dataObj)
            if (restData) {
                /*if (Array.isArray(restData)) {
                    owners = restData
                } else {
                    owners.push(restData)
                }*/
            }
            count = restData.numValue;//owners.length
        }
        setRenterReservationCount(count)
    }
    let getOfferReceivers = () => {
        let qParam = CommonUtil.getSelectedOrgId()
        let usrUrl = 'api/offers/ownercount?saasOrgId=' + CommonUtil.getSelectedOrgId();
        let payload = { saasOrgId: qParam }
        NetworkManager.sendJsonRequest(usrUrl, '', '', 'GET', true, onSuccessOffersReceive, onLoginError);
        setOwnerReceiveCount("");
    }
    let onSuccessOffersReceive = (dataObj: any) => {
        let count: number = 0
        let owners = []
        if (dataObj) {
            let restData = CommonUtil.parseData(dataObj)
            if (restData) {
                /*if (Array.isArray(restData)) {
                    owners = restData
                } else {
                    owners.push(restData)
                }*/
            }
            count = restData.numValue;//owners.length
            setOwnerReceiveCount(restData.numValue);
        }


    }
    let getOffersMade = () => {
        let qParam = CommonUtil.getSelectedOrgId()
        let usrUrl = 'api/offers/rentercount?saasOrgId=' + CommonUtil.getSelectedOrgId();
        //let payload = {saasOrgId: qParam}
        NetworkManager.sendJsonRequest(usrUrl, '', '', 'GET', true, onSuccessOffersMade, onLoginError)
    }
    let onSuccessOffersMade = (dataObj: any) => {
        let count: number = 0
        let owners = []
        if (dataObj) {
            let restData = CommonUtil.parseData(dataObj)
            if (restData) {
                /*if (Array.isArray(restData)) {
                    owners = restData
                } else {
                    owners.push(restData)
                }*/
            }
            count = restData.numValue;// owners.length
        }
        setRenterMadeCount(count)
    }
    let onDownloadSuccess = (data: any) => {
        //console.log(data);
        setImageSrc(data)
    }
    let onCloseResetPassPopup = () => {
        setShowChangeLanPopup(false)
    }
    let onClickChangeLan = () => {
    }
    let onClickLanguage = () => {
        setShowChangeLanPopup(true)
        setTimeout(() => {
            clearLanguageSelection()
            showSelectedLanguage()
        })
    }

    let onCloseChangePassPopup = () => {
        setShowChangePassPopup(false)
    }

    let onClickChangePass = () => {
        setShowChangePassPopup(true)
    }
    let onClickNotification = () => {
    }

    let onChangePass = () => {
        var isOldPass: boolean = CommonUtil.isMandatory(oldPassRef)
        var isNewPass: boolean = CommonUtil.isMandatory(newPassRef)
        var isConfirmPass: boolean = CommonUtil.isMandatory(confirmPassRef)

        let newPass: string = ''
        let confirmPass: string = ''
        let oldPass: string = ''
        if (newPassRef.current) {
            newPass = newPassRef.current.value
        }

        if (oldPassRef.current) {
            oldPass = oldPassRef.current.value
        }
        if (confirmPassRef.current) {
            confirmPass = confirmPassRef.current.value
        }
        if (isOldPass && isNewPass && isConfirmPass && newPass == confirmPass) {
            let reqObj: any = {}
            reqObj.newPassword = newPass
            reqObj.oldPassword = oldPass
            reqObj.token = localStorage.getItem('token')

            let changePassapi: string = 'api/organization/updatePassword?saasOrgId=' + CommonUtil.getSaasOrgId()
            NetworkManager.sendJsonRequest(changePassapi, reqObj, '', 'POST', false, onUserChangePassSuccess, onLoginError,)
        }
    }
    let onUserChangePassSuccess = (dataObj: any) => {
        // console.log(dataObj)
        BusyLoader.hideLoader()
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
            }
        } else {
            onCloseChangePassPopup()
            let strInfo: string = CommonUtil.getMessageText(Constants.INFO, 'Info')
            ToastManager.showToast(CommonUtil.getMessageText(Constants.SUCCESS_PASS_UPDATE, 'Password updated successfully'), "Info");
        }
    }
    let onCloseMyProfilePopup = () => {
        setShowMyProfilePopup(false)
    }
    let onClickMyProfile = () => {
        setShowMyProfilePopup(true)
        getUserDetails()
    }

    let getUserDetails = () => {
        let getUserAPI: string = 'api/organization/user/' + CommonUtil.getUserId() + '?saasOrgId=' + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetUserDetails, onLoginError)
    }

    let onGetUserDetails = (dataObj: any) => {
        //console.log(dataObj)
        BusyLoader.hideLoader()
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setFirstName(restData.firstName)
                setLastName(restData.lastName)
                setEmailId(restData.loginId)

                if (restData.sendPromotionalMails == 'Y') {
                    if (chkProMails.current) {
                        chkProMails.current.checked = true
                    }
                }
            }
        }
    }

    let onClickSaveMyProfile = () => {
        var fName: boolean = CommonUtil.isMandatory(fNameRef)
        var lName: boolean = CommonUtil.isMandatory(lNameRef)

        if (fName && lName) {
            let restObj: any = {}
            restObj.firstName = firstName
            restObj.lastName = lastName
            restObj.sendPromotionalMails = chkProMails.current?.checked ? 'Y' : 'N'
            restObj.userId = CommonUtil.LOGIN_USER_ID
            restObj.lastName = lastName
            restObj.lastName = lastName
            restObj.loginId = emailId

            let updateUserAPI: string = 'api/organization/updateUserProfile?saasOrgId=' + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(updateUserAPI, restObj, '', 'POST', true, onUpdateUserDetails, onLoginError);
        }
    }

    let onUpdateUserDetails = (dataObj: any) => {
        BusyLoader.hideLoader()
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strInfo: string = CommonUtil.getMessageText(Constants.INFO, 'Info')
                ToastManager.showToast(CommonUtil.getMessageText(Constants.MY_PROFILE_UPDATE, 'My Profile Updated Successfully'), "Info");
            }
        }
    }

    let onClickFavorities = () => {
        //setShowFavoritiesPopup(true);
        CommonUtil.dispatchEVents();
        setOpen(!open)
        /*setBreadCrumb(Constants.REPORTS);
        setBreadCrumb1("");
        setBreadCrumb2(Events.FAVORITIES);
        navigate('favorities')*/
        onClickFavoritiesReport();
    }

    let onClickFavoritiesReport = () => {
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.REPORTS, 'Reports'));
        setBreadCrumb1("");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.FAVORITIES, 'favourites'));//Events.FAVORITIES);
        CommonUtil.addScreensToLastAccessed(Events.FAVORITIES);
        addToLastAccessed(Constants.FAVORITIES_SCREENS, "favorities");
        navigate('favorities')
        showSwitchCustomer();
    }

    let onCloseFavorPopup = () => {
        setShowFavoritiesPopup(false)
    }

    let onDevFavourShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devFavourPopup', 'devFavourContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 600
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 1300
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let onOrgChange = (dataObj: any) => {
        setSelOrg(dataObj)
    }

    let onCloseSwichOrgPopup = () => {
        setShowSwitchOrgPopup(false)
    }
    let onClickSwitchCustomer = () => {
        setShowSwitchOrgPopup(true);
        getCustOrgList()
    }
    let getCustOrgList = () => {
        var qParam = null //localStorage.getItem("orgId");
        if (CommonUtil.isRootUser()) {
            qParam = CommonUtil.getSaasOrgId()
            var orgUrl = 'api/organization/listNames'
            NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgCustOrgList, onLoginError)
        }
    }
    let onOrgCustOrgList = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                var orgs = []
                if (Array.isArray(restData.organizations)) {
                    orgs = restData.organizations
                } else {
                    orgs.push(restData.organizations)
                }
                setOrgList(orgs)
            }
        }
    }
    let onClickGoOrg = () => {
        // console.log(selOrg);
        if (selOrg) {
            localStorage.setItem('orgId', selOrg.orgId);
            localStorage.setItem('saasorgId', selOrg.orgId);
            localStorage.setItem('orgName', selOrg.orgName);
            localStorage.setItem("switchCustomer", "true");
            localStorage.setItem('root', (selOrg.orgId == "1" ? "true" : "false"));
            CommonUtil.ORGANIZATION_TYPE = selOrg.usageType;
            setSwitchCustomer(true);
            dispatchCustomerSwichEvent();
            getOrganizationPermissions();
            onCloseSwichOrgPopup();

        }

    }
    let onClickBackRootOrg = () => {
        var orgUrl = 'api/organization/returnTovHub';
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onReturnToHub, onLoginError);
    }

    let onReturnToHub = (dataObj: any) => {
        // console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                localStorage.setItem('orgId', restData.orgId)
                localStorage.setItem('saasorgId', restData.orgId)
                localStorage.setItem('orgName', restData.orgName)
                localStorage.setItem('root', 'true');
                localStorage.setItem("switchCustomer", "false");
                setSwitchCustomer(true);
                // publish(Events.SWITCH_CUSTOMER_EVENT, () => {});
                getOrganizationPermissions();
                dispatchCustomerSwichEvent();
                onCloseSwichOrgPopup();
                if (restData.logoPath) {
                    var qParam: string = 'objectPath=' + restData.logoPath
                    NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, onDownloadSuccess, onLoginError);
                }
            }
        }
    }
    let dispatchCustomerSwichEvent = () => {
        const event = new CustomEvent(Events.SWITCH_CUSTOMER_EVENT, { detail: {} });
        document.dispatchEvent(event);
    }
    let onLoginError = (err: any) => {
        console.log(err)
    }
    let handleClose = () => {
        setModalShow(false)
    }
    let onClickHandle = (e: any, index: any) => {
        let rawdata: any = [...notificationList]
        let checkStatus = rawdata[index].notificationAssocStatus
        rawdata[index].notificationAssocStatus = checkStatus === 'Y' ? 'N' : 'Y'
        setNotificationList(rawdata)
    }
    let onSuccess = () => {
        let strSuccess: string = CommonUtil.getMessageText(Constants.SUCCESS, 'Success');
        ToastManager.showToast(CommonUtil.getMessageText(Constants.NOTIFICATION_CONFIG, 'Notification configured successfully',), strSuccess);
        setModalShow(false)
    }
    let handleSubmit = () => {
        let payload: any = {}
        payload.userIds = [CommonUtil.getUserId()]
        payload.notifications = notificationList
        let qParam = CommonUtil.getSaasOrgId()
        let saveAssociation = 'api/notification/saveUserAssociations?saasOrgId=' + qParam;
        NetworkManager.sendJsonRequest(saveAssociation, payload, '', 'POST', true, onSuccess, onLoginError);
    }
    let onClickNotificationModal = () => {
        getUserAssociation()
    }
    let onClickCompanyDB = () => {
        CommonUtil.dispatchEVents();
        setOpen(!open)
        setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.COMPANY_DB, 'Company Dashboard'))
        setIsBreadCrumb(true);
        // navigate('offers');
        navigate('companydb')
        showSwitchCustomer();
    }

    let onClickInvoicesDB = () => {
        CommonUtil.PUBLIC_SEARCH_DB = true;
        CommonUtil.PRIVATE_SEARCH_DB = false;
        setOpen(!open)
        onNavigateToSearchDB();
    }

    let onClickPrivateNewSearchDB = ()=>{
        CommonUtil.PUBLIC_SEARCH_DB = false;
        CommonUtil.PRIVATE_SEARCH_DB = true;
        setOpen(!open);
        /*CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.SEARCH_DB1, "Search Dashboard"));
        setIsBreadCrumb(true)
        // navigate('offers');
        CommonUtil.addScreensToLastAccessed("newsearchdb");
        addToLastAccessed(Constants.NEW_SEARCH_DASHBOARD, "newsearchdb");
        navigate('newsearchdb');
        showSwitchCustomer();*/
        onNavigateToSearchDB();
    }
    let onClickPrivateSearchDB = () => {
        CommonUtil.PUBLIC_SEARCH_DB = false;
        CommonUtil.PRIVATE_SEARCH_DB = true;
        setOpen(!open)
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.SEARCH_DB1, "Search Dashboard"));
        setIsBreadCrumb(true)
        // navigate('offers');
        CommonUtil.addScreensToLastAccessed("newsearchdb");
        addToLastAccessed(Constants.NEW_SEARCH_DASHBOARD, "newsearchdb");
        navigate('newsearchdb');
        showSwitchCustomer();
    }
    let onNavigateToSearchDB = () => {
        CommonUtil.dispatchEVents();
        setBreadCrumb(CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')) // -> Organizations");
        setBreadCrumb1('') // -> Organizations");
        setBreadCrumb2(CommonUtil.getMessageText(Constants.SEARCH_DB1, "Search Dashboard"));
        setIsBreadCrumb(true)
        // navigate('offers');
        CommonUtil.addScreensToLastAccessed("newsearchdb");
        addToLastAccessed(Constants.SEARCH_DASHBOARD, "newsearchdb");
        navigate('newsearchdb');
        showSwitchCustomer();
    }



    let onClickLastAccessScreen = (item: any) => {
        console.log(item);
        if (item) {
            if (item.screenName == Constants.EQUIPMENT_REPORT) {
                CommonUtil.EQUIPMENT_LIST = false;
                navigateToEquipReport();
            } else if (item.screenName == Constants.EQUIPMENT_SCREEN) {
                CommonUtil.EQUIPMENT_LIST = true;
                navigateToEquipList();
            } else if (item.screenName == Constants.SEARCH_DASHBOARD) {
                onNavigateToSearchDB();
            } else if (item.screenName == Constants.DRIVER_SCREEN) {
                navigateToDriver();
            } else if (item.screenName == Constants.PARKING_LOT_SCREEN) {
                navigateToParkingLot();
            } else if (item.screenName == Constants.MASTER_DATA_SCREEN) {
                navigateToMasterData();
            }else if (item.screenName == Constants.INSPECTION_COMPANY_SCREEN) {
                navigateToInspectionCompany();
            }else if (item.screenName == Constants.INTERCHANGE_APPLICATION_SCREEN) {
                navigateToInterchangeApplication();
            }
            else if (item.screenName == Constants.CUSTOMER_PROFILES_SCREEN) {
                navigateToCustomerProfiles();
            } else if (item.screenName == Constants.ORG_SCREEN) {
                navigateToOrgList();
            } else if (item.screenName == Constants.FAVORITIES_SCREENS) {
                onClickFavoritiesReport();
            } else if (item.screenName == Constants.AVAILABILITY_SCREENS) {
                navigateToAvailabilityReport();
            } else if (item.screenName == Constants.OWNER_RESERVATION_SCREENS) {
                onClickReservationsHeader();
            } else if (item.screenName == Constants.RENTER_RESERVATION_SCREENS) {
                onClickRenterReservationsHeader();
            } else if (item.screenName == Constants.OFFER_SENT_SCREENS) {
                onClickOfferHeader();
            } else if (item.screenName == Constants.OFFER_RECEIVED_SCREENS) {
                onClickOfferReceiveHeader();
            } else if (item.screenName == Constants.API_HUB_SCREEN) {
                navigateToAPIHub();
            } else if (item.screenName == Constants.NOTIFICATION_SCREEN) {
                navigateToNotifications();
            }else if (item.screenName == Constants.INSURANCE_PLANS_SCREEN) {
               navigateToInsurancePlans();
            }else if (item.screenName == Constants.RATE_TABLE_SCREEN) {
                navigateToInsuranceRatePlans();
            }
        }

    }
    let onNavigateToAvailability = () => {
        setShowAvailable(true);
    }

    let onCloseAvailPopup = () => {
        setShowAvailable(false);
    }
    let onClickRenter = () => {
        console.log("Renter");
        setAvailabilityType(CommonUtil.EQUIP_AVAIL_RENTAL_TYPE);
        CommonUtil.EQUIP_AVAIL_TYPE = CommonUtil.EQUIP_AVAIL_RENTAL_TYPE;
        setShowCreateAvail(true);
        onCloseAvailPopup();
    }

    let onClickReposition = () => {
        console.log("Reposition");
        CommonUtil.EQUIP_AVAIL_TYPE = CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE;
        setAvailabilityType(CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE);
        setShowCreateAvail(true);
        onCloseAvailPopup();
    }

    let closeAvailCreatePopup = () => {
        setShowCreateAvail(false);
    }

    return (
        <React.Fragment>
            <AvailabilityCreateModal modalShow={showCreateAvail} onCloseAvailPopup={closeAvailCreatePopup}
                availabilityType={availabilityType} />
            <AvailabilityModal modalShow={showAvailable} onCloseAvailPopup={onCloseAvailPopup} onClickRenter={onClickRenter} onClickReposition={onClickReposition} />
            <NotificationModal modalShow={modalShow} own
                notificationList={notificationList} handleClose={handleClose} onClickHandle={onClickHandle} handleSubmit={handleSubmit} />
            <Modal show={showSwitchOrgPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '550px', height: '500px' }}>
                    <div className="modal-header">
                        <h6 className="modal-title">
                            {CommonUtil.getMessageText(Constants.SWITCH_ORG, 'Switch Ogranization')}
                        </h6>
                        <button type="button" className="close" data-dismiss="modal"
                            aria-label="Close" style={{ marginLeft: '0px' }} onClick={onCloseSwichOrgPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <div className="col-md-12" style={{ display: CommonUtil.isRootUser() ? 'none' : '', textAlign: 'right' }}>
                                <button type="button"
                                    className="btn btn-primary waves-effect waves-light" onClick={onClickBackRootOrg}>
                                    {CommonUtil.getMessageText(Constants.HOME_ORG, 'Return to vHub Organization')}
                                </button>
                            </div>
                            <div className="col-md-12">
                                <label>Organization</label>
                                <div className="input-group mb-3">
                                    <SingleSelectComp dataSource={orgList} selectedValue={selOrg}
                                        handleChange={onOrgChange} value={'orgId'} label={'orgName'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button"
                            className="btn btn-outline-dark" data-bs-dismiss="modal"
                            onClick={onCloseSwichOrgPopup}>
                            {CommonUtil.getMessageText(Constants.CANCEL, 'Cancel')}
                        </button>
                        &nbsp;&nbsp;
                        <button type="button"
                            className="btn btn-primary waves-effect waves-light" onClick={onClickGoOrg}>
                            {CommonUtil.getMessageText(Constants.SWITCH, 'Switch')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showMyProfilePopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '500px', height: '450px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {CommonUtil.getMessageText(Constants.MY_PROFILE, 'My Profile')}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseMyProfilePopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.EMAIL_ID, 'Email Id')}</label>
                            <div>
                                <input type="email" className="form-control" required parsley-type="email"
                                    value={emailId} disabled={true} placeholder="Email ID *" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.FIRST_NAME, 'First Name')}{' '}</label>
                            <input ref={fNameRef} type="text" value={firstName} className="form-control"
                                required placeholder="First Name"
                                onChange={(e) => { setFirstName(e.currentTarget.value); CommonUtil.isMandatory(fNameRef) }}
                            />
                        </div>

                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.LAST_NAME, 'Last Name')}{' '}</label>
                            <input ref={lNameRef} type="text" value={lastName} className="form-control" required
                                placeholder="Last Name" onChange={(e) => {
                                    setLastName(e.currentTarget.value)
                                    CommonUtil.isMandatory(lNameRef)
                                }}
                            />
                        </div>

                        <div className="custom-control custom-checkbox">
                            <input ref={chkProMails} type="checkbox" className="custom-control-input"
                                id="customCheck1" data-parsley-multiple="groups" data-parsley-mincheck="2"
                            />
                            <label className="custom-control-label" htmlFor="customCheck1">
                                {CommonUtil.getMessageText(Constants.AUTHORIZE, 'I authorize vHub to send me promotional emails')}
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark"
                            data-dismiss="modal" onClick={onCloseMyProfilePopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Close')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={onClickSaveMyProfile}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showChangePassPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '500px !important', height: '400px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{CommonUtil.getMessageText(Constants.CHANGE_PASSWORD, 'Change Password')}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseChangePassPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.OLD_PASSWORD, 'Old Password')}</label>
                            <input ref={oldPassRef} type="password" className="form-control clsFormInputControl"
                                required placeholder={CommonUtil.getMessageText(Constants.OLD_PASSWORD, 'Old Password')}
                            />
                        </div>
                        <hr />
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.NEW_PASSWORD, 'New Password')}</label>
                            <div>
                                <input ref={newPassRef} type="password"
                                    className="form-control clsFormInputControl" required
                                    parsley-type="email" placeholder={CommonUtil.getMessageText(Constants.NEW_PASSWORD, 'New Password')}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.CONFIRM_NEW_PASSWORD, 'Confirm New Password')}</label>
                            <div>
                                <input ref={confirmPassRef} type="password"
                                    className="form-control clsFormInputControl" required parsley-type="email"
                                    placeholder={CommonUtil.getMessageText(Constants.CONFIRM_NEW_PASSWORD, 'Confirm New Password')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={onCloseChangePassPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Close')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={onChangePass}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showChangeLanPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '500px', height: '300px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title  fw-600" style={{ margin: 'auto' }}>
                            {CommonUtil.getMessageText(Constants.CHANGE_LANGUAGE, 'Change Language')}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseResetPassPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: '10px' }}>
                        <ul className="list-group no-bullets ">
                            <li ref={engRef} className={'language-item  py-2 mx-2'}
                                onClick={() => { onChangeLan('en') }}>
                                <a className="language-item-left grey-text  " href="#">
                                    {CommonUtil.getMessageText(Constants.ENGLISH, 'English')}
                                </a>
                                <i><img src="../../images/circle.svg" /></i>
                            </li>

                            <li ref={frRef} className={' language-item py-2 mx-2'} onClick={() => { onChangeLan('fr') }}>
                                <a className=" language-item-left grey-text " href="#">
                                    {CommonUtil.getMessageText(Constants.FRENCH, 'French')}
                                </a>
                                <i><img src="../../images/circle.svg" /></i>
                            </li>
                            <li ref={spRef} className={'language-item py-2 mx-2'} onClick={() => { onChangeLan('sp') }}>
                                <a className="language-item-left grey-text " href="#">
                                    {CommonUtil.getMessageText(Constants.SPANISH, 'Spanish')}
                                </a>
                                <i><img src="../../images/circle.svg" /></i>
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={onCloseResetPassPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Close')}
                        </button>
                        {/*<button type="button" className="btn clsBoldText driver-btn fw-600" onClick={onClickChangeLan}>Save</button>*/}
                    </div>
                </div>
            </Modal>
            <Modal show={showFavoritiesPopup} id="devFavourPopup" dialogAs={DraggableComponent} onShow={onDevFavourShowPopup}>
                <div className="modal-content" id="devFavourContentPopup" style={{ width: '1300px', height: '600px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Favorities</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '0px' }}
                            onClick={onCloseFavorPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ padding: '0px' }}>
                        <FavoritiesList />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal" onClick={onCloseFavorPopup}>
                            Close
                        </button>
                    </div>
                </div>
            </Modal>

            <div id="wrapper" className="enlarged">
                {
                    isAdminInspectorLogin?(
                    <>
                    <div ref={divRef} className="left side-menu" style={open ? { marginLeft: '0px', zIndex: '99999' } : { zIndex: '99999' }}>
                    <div className="slimScrollDivStyle">
                        <div className="sidebar-inner slimscrollleft sidebar-inner-style">
                            <div id="sidebar-menu">
                                <ul className="side-menu-ul">
                                    <h6 style={{ display: isDBVisible ? '' : 'none' }}>
                                        {CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')}
                                    </h6>


                                    <li className="has_sub nav-active" style={{ display: isInterchangeApp ? '' : 'none' }}>
                                        <a href="#" className="waves-effect"
                                           style={subMenuOpen === MenuList.INTERCHANGEMENU ? { backgroundColor: '#eff3f6', color: '#373935' } : {}}
                                           onClick={() => { handleSubMenu(MenuList.INTERCHANGEMENU) }}>
                                            <i className="fa fa-briefcase"></i>{' '}
                                            <span>{CommonUtil.getMessageText(Constants.INTERCHANGE_APP_MENU, 'Interchange Dashboard')}{' '}</span>{' '}
                                            <span className="menu-arrow float-right">
                                                {subMenuOpen === MenuList.INTERCHANGEMENU ? (
                                                    <i className="fa fa-chevron-down fa-2xs"></i>
                                                ) : (
                                                    <i className="fa fa-chevron-right fa-2xs"></i>
                                                )}
                                            </span>
                                        </a>
                                        <ul className="list-unstyled" style={subMenuOpen === MenuList.INTERCHANGEMENU ? { display: 'block' } : {}}>
                                            <li style={{ display: isInspectionCompanyVisible ? '' : 'none' }} onClick={onClickInspectionCompany}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-user-check"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INSPECTION_COMPANY, 'Inspection Company')}</span>
                                                </a>
                                            </li>
                                            <li style={{ display: isInterchangeVisible ?'' : 'none' }} onClick={onClickInterchangeApplication}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-arrow-down-up-across-line"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INTERCHANGE_APPLICATION, 'Interchange Dashboard')}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr style={{ display: isInterchangeApp ? '' : 'none' }} />
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    </>):(
                    <>
                    <div ref={divRef} className="left side-menu" style={open ? { marginLeft: '0px', zIndex: '99999' } : { zIndex: '99999' }}>
                    <div className="slimScrollDivStyle">
                        <div className="sidebar-inner slimscrollleft sidebar-inner-style">
                            <div id="sidebar-menu">
                                <ul className="side-menu-ul">
                                    <h6 style={{ display: isDBVisible ? '' : 'none' }}>
                                        {CommonUtil.getMessageText(Constants.DASHBOARD, 'Dashboard')}
                                    </h6>
                                    {/* <li onClick={onClickSearchDB} style={{display: (organizationType == CommonUtil.ORG_TYPE_OWNER || CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : ''}}>
                                        <a href="#" className="waves-effect">
                                            <i className="fa fa-street-view"></i>
                                            <span>{CommonUtil.getMessageText(Constants.SEARCH_DB, 'Search Dashboard')}
                                            </span>
                                        </a>
                                    </li>*/}
                                    {/*<li onClick={onClickInvoicesDB} style={{display: (organizationType == CommonUtil.ORG_TYPE_OWNER || CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : ''}}>
                                        <a href="#" className="waves-effect">
                                            <i className="fa fa-street-view"></i>
                                            <span>{CommonUtil.getMessageText(Constants.SEARCH_DB_PUBLIC, 'Search Dashboard Public')}</span>
                                        </a>
                                    </li>*/}
                                    {isSearchDBVisible ? (
                                        <li onClick={onClickPrivateSearchDB} style={{ display: (CommonUtil.OWNER_STATUS == CommonUtil.PENDING && CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : 'none' }}>
                                            <a href="#" className="waves-effect">
                                                <i className="fa fa-street-view"></i>
                                                <span>{CommonUtil.getMessageText(Constants.SEARCH_DB1, 'Search Dashboard')}</span>
                                            </a>
                                        </li>
                                    ) : null}
                                    {isSearchDBVisible ? (
                                        <li onClick={onClickPrivateNewSearchDB} style={{ display: (CommonUtil.OWNER_STATUS == CommonUtil.PENDING && CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : '' }}>
                                            <a href="#" className="waves-effect">
                                                <i className="fa fa-street-view"></i>
                                                <span>{CommonUtil.getMessageText(Constants.SEARCH_DB1, 'Search Dashboard')}</span>
                                            </a>
                                        </li>
                                    ) : null}
                                    {isCompanyDBVisible ? (
                                        <li onClick={onClickCompanyDB}>
                                            <a href="#" className="waves-effect">
                                                <i className="fa fa-map"></i>
                                                <span>{CommonUtil.getMessageText(Constants.COMPANY_DB, 'Company Dashboard')}</span>
                                            </a>
                                        </li>
                                    ) : null}
                                    {/*<li style={{display: isInvoiceDBVisible ? '' : 'none'}}>
                                        <a href="#" className="waves-effect">
                                            <i className="fa fa-file-text"></i>
                                            <span>{' '}
                                                {CommonUtil.getMessageText(Constants.INVOICES, 'Invoices')}
                                            </span>
                                        </a>
                                    </li>*/}

                                    <hr style={{ display: isDBVisible ? '' : 'none' }} />

                                    <li className="has_sub nav-active" style={{ display: isReportsVisible ? '' : 'none' }}>
                                        <a href="#;" className="waves-effect"
                                            style={subMenuOpen === MenuList.REPORTS ? { backgroundColor: '#eff3f6', color: '#373935' } : {}}
                                            onClick={() => { handleSubMenu(MenuList.REPORTS) }}>
                                            <i className="fa fa-briefcase"></i>{' '}
                                            <span>
                                                {' '}
                                                {CommonUtil.getMessageText(Constants.REPORTS, 'Reports')}{' '}
                                            </span>{' '}
                                            <span className="menu-arrow float-right">
                                                {subMenuOpen === MenuList.REPORTS ? (
                                                    <i className="fa fa-chevron-down fa-2xs"></i>
                                                ) : (
                                                    <i className="fa fa-chevron-right fa-2xs"></i>
                                                )}
                                            </span>
                                        </a>
                                        <ul className="list-unstyled" style={subMenuOpen === MenuList.REPORTS ? { display: 'block' } : {}}>
                                            <li style={{ display: isEquipRepVisible ? '' : 'none' }} onClick={onClickEqpReport}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-truck"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipment')}</span>
                                                </a>
                                            </li>
                                            <li style={{ display: isAvailRepVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickAvailableReport}>
                                                    <i className="fa fa-file-text"></i>
                                                    <span>{' '}{CommonUtil.getMessageText(Constants.AVAILABILITIES, 'Availabilities')}</span>
                                                </a>
                                            </li>
                                            {isOwnerRepVisible ? (
                                                <li onClick={onClickReservations}
                                                    style={{ display: (organizationType == CommonUtil.ORG_TYPE_RENTER || CommonUtil.OWNER_STATUS == CommonUtil.PENDING) ? 'none' : '' }}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-vcard"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.OWNER_RESERVATIONS, 'Owner Reservations')}
                                                        </span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            {isOwnerRepVisible ? (
                                                <li onClick={onClickAdminReservations}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-vcard"></i>
                                                        <span>Reservations</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            {isRenterRepVisible ? (
                                                <li onClick={onClickRenterReservations}
                                                    style={{ display: (organizationType == CommonUtil.ORG_TYPE_OWNER || CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : '' }}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-vcard"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.RENTER_RESERVATIONS, 'Renter Reservations')}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            {isOfferSentRepVisible ? (
                                                <li onClick={onClickOffers} style={{ display: (organizationType == CommonUtil.ORG_TYPE_OWNER || CommonUtil.RENTER_STATUS == CommonUtil.PENDING) ? 'none' : '' }}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-file-text"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.OFFERS_SENT, 'Offers Sent')}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            {isOfferReceivedRepVisible ? (
                                                <li onClick={onClickOffersReceived} style={{ display: (organizationType == CommonUtil.ORG_TYPE_RENTER || CommonUtil.OWNER_STATUS == CommonUtil.PENDING) ? 'none' : '' }}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-file-text"></i>
                                                        <span>{' '}{CommonUtil.getMessageText(Constants.OFFERS_RECEIVED, 'Offers Received')}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            <li style={{ display: isInvoiceRepVisible ? 'none' : 'none' }}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-file-text"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INVOICES, 'Invoices')}</span>
                                                </a>
                                            </li>
                                            {isFavoriteRepVisible ? (
                                                <li onClick={onClickFavorities}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-vcard"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.FAVORITIES, 'Favorites')}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </li>
                                    <h6></h6>

                                    <hr style={{ display: isReportsVisible ? '' : 'none' }} />

                                    <li className="has_sub nav-active" style={{ display: isAdminVisible ? '' : 'none' }}>
                                        <a href="#" className="waves-effect"
                                            style={subMenuOpen === MenuList.ADMINISTRATION ? { backgroundColor: '#eff3f6', color: '#373935' } : {}}
                                            onClick={() => { handleSubMenu(MenuList.ADMINISTRATION) }}>
                                            <i className="fa fa-briefcase"></i>{' '}
                                            <span>{CommonUtil.getMessageText(Constants.ADMINISTRATION, 'Administration')}{' '}</span>{' '}
                                            <span className="menu-arrow float-right">
                                                {subMenuOpen === MenuList.ADMINISTRATION ? (
                                                    <i className="fa fa-chevron-down fa-2xs"></i>
                                                ) : (
                                                    <i className="fa fa-chevron-right fa-2xs"></i>
                                                )}
                                            </span>
                                        </a>
                                        <ul className="list-unstyled" style={subMenuOpen === MenuList.ADMINISTRATION ? { display: 'block' } : {}}>
                                            {CommonUtil.isRootUser() ? (
                                                <li style={{ display: isOrgVisible ? '' : 'none' }}>
                                                    <a href="#" className="waves-effect" onClick={onClickOrgList}>
                                                        <i className="fa-regular fa-sitemap"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.ORGANIZATIONS, 'Organizations')}
                                                        </span>
                                                    </a>
                                                </li>
                                            ) : (
                                                <li>
                                                    <a href="#" className="waves-effect" onClick={onClickCustProfiles}>
                                                        <i className="fa fa-check-circle"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.CUSTOMER_PROFILES, 'Customer Profile')}
                                                        </span>
                                                    </a>
                                                </li>
                                            )}

                                            <li style={{ display: isEquipmentVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickEqpList}>
                                                    <i className="fa fa-cogs"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.EQUIPMENTS, 'Equipment')}
                                                    </span>
                                                </a>
                                            </li>
                                            <li style={{ display: isDriverVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickDiverList}>
                                                    <i className="fa fa-user"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.DRIVER, 'Drivers')}</span>
                                                </a>
                                            </li>

                                            <li style={{ display: isParkingLotVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickParkingLot}>
                                                    <i className="fa fa-truck"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.PARKING_LOT, 'Parking Lots')}
                                                    </span>
                                                </a>
                                            </li>
                                            <li style={{ display: isInsurPlanVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickInsurancePlans}>
                                                    <i className="fa fa-cogs"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INSURANCE_PLANS, 'Insurance Plans')}
                                                    </span>
                                                </a>
                                            </li>
                                            <li style={{ display: isInsurRateVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickInsuranceRateTable}>
                                                    <i className="fa fa-cogs"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INSURANCE1_PLANS_RATE, 'Insurance Rate Table')}
                                                    </span>
                                                </a>
                                            </li>
                                            {CommonUtil.isRootUser() ? (
                                                <li style={{ display: isNotificationsVisible ? '' : 'none' }} onClick={onClicNotifications}>
                                                    <a href="#" className="waves-effect">
                                                        <i className="fa fa-th-list"></i>
                                                        <span>{CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications')}
                                                        </span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            <li style={{ display: isMatserDataVisible ? '' : 'none' }} onClick={onClicMasterData}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-th-list"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.MASTER_DATA, 'Master data')}</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <hr style={{ display: isAdminVisible ? '' : 'none' }} />

                                    <li className="has_sub nav-active" style={{ display: isInterchangeApp ? '' : 'none' }}>
                                        <a href="#" className="waves-effect"
                                           style={subMenuOpen === MenuList.INTERCHANGEMENU ? { backgroundColor: '#eff3f6', color: '#373935' } : {}}
                                           onClick={() => { handleSubMenu(MenuList.INTERCHANGEMENU) }}>
                                            <i className="fa fa-briefcase"></i>{' '}
                                            <span>{CommonUtil.getMessageText(Constants.INTERCHANGE_APP_MENU, 'Interchange Application')}{' '}</span>{' '}
                                            <span className="menu-arrow float-right">
                                                {subMenuOpen === MenuList.INTERCHANGEMENU ? (
                                                    <i className="fa fa-chevron-down fa-2xs"></i>
                                                ) : (
                                                    <i className="fa fa-chevron-right fa-2xs"></i>
                                                )}
                                            </span>
                                        </a>
                                        <ul className="list-unstyled" style={subMenuOpen === MenuList.INTERCHANGEMENU ? { display: 'block' } : {}}>
                                            <li style={{ display: isInspectionCompanyVisible ? '' : 'none' }} onClick={onClickInspectionCompany}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-user-check"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INSPECTION_COMPANY, 'Inspection Company')}</span>
                                                </a>
                                            </li>
                                            <li style={{ display: isInterchangeVisible ? '' : 'none' }} onClick={onClickInterchangeApplication}>
                                                <a href="#" className="waves-effect">
                                                    <i className="fa fa-arrow-down-up-across-line"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.INTERCHANGE_APPLICATION, 'Interchange Dashboard')}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr style={{ display: isInterchangeApp ? '' : 'none' }} />

                                    <li className="has_sub nav-active" style={{ display: isProcessVisible ? '' : 'none' }}>
                                        <a className="waves-effect"
                                            style={subMenuOpen === MenuList.PROCESS ? { backgroundColor: '#eff3f6', color: '#373935' } : {}}
                                            onClick={() => { handleSubMenu(MenuList.PROCESS) }}>
                                            <i className="fa fa-briefcase"></i> <span> {CommonUtil.getMessageText(Constants.PROCESS, 'Process')} </span>{' '}
                                            <span className="menu-arrow float-right">
                                                {subMenuOpen === MenuList.PROCESS ? (
                                                    <i className="fa fa-chevron-down fa-2xs"></i>
                                                ) : (
                                                    <i className="fa fa-chevron-right fa-2xs"></i>
                                                )}
                                            </span>
                                        </a>

                                        <ul className="list-unstyled"
                                            style={subMenuOpen === MenuList.PROCESS ? { display: 'block' } : {}}>
                                            <li style={{ display: isProcessReserveVisible ? '' : 'none' }}>
                                                <a href="#" className="waves-effect" onClick={onClickProcessReservation}>
                                                    <i className="fa fa-truck"></i>
                                                    <span>{CommonUtil.getMessageText(Constants.PROCESS_RESERVATIONS, 'Process Reservations')}
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                    <hr style={{ display: isProcessVisible ? '' : 'none' }} />
                                    {isAPIHUBVisible ? (
                                        <li>
                                            <a href="#" className="waves-effect" onClick={onClickApiHub}>
                                                <i className="fa fa-code"></i>
                                                <span>{CommonUtil.getMessageText(Constants.API_HUB, 'API HUB')}</span>
                                            </a>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                    </>)
                }


                {/* <div className="content-page" style={{ position:'absolute', width:'100%' }} > */}
                <div className="topbar col-12" id='topbar'>
                    {isMobile ? (
                        <div className="topbar-left mobile-purpose d-md-none">
                            <div className="row col-12">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-3 text-center">
                                            <button type="button" className="button-menu-mobile open-left waves-effect"
                                                style={{ alignSelf: 'center' }} onClick={handleMenu} ref={menuButtonRef} >
                                                {open ? (<i className="fa fa-times fa-2xs" />) : (<i className="fa fa-bars fa-2xs" />)}
                                            </button>
                                        </div>

                                        <div className="col-9 text-center">
                                            <a href="#" className="logo" onClick={gotoMenu}>
                                                <img src={'../../images/logo.png'} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 author-list" style={{ paddingTop: '10px' }}>
                                    <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user "
                                        data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                                        aria-expanded="false" style={{ position: 'absolute', right: '35px' }}>
                                        {imageSrc ? <img src={imageSrc} alt="" /> : ''}
                                        <i className="fa fa-chevron-down" style={{ paddingLeft: '5px', paddingTop: '16px' }}></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right profile-dropdown dropdownstyle"
                                        x-placement="bottom-end" aria-labelledby="dropDownProfile">
                                        <a className="dropdown-item myProfilePadding" data-toggle="modal"
                                            data-target=".bs-example-modal-center-profile" onClick={onClickMyProfile}>
                                            <i className="fa-solid fa-user m-r-5"></i>
                                            {CommonUtil.getMessageText(Constants.MY_PROFILE, 'My Profile')}
                                        </a>
                                        <a className="dropdown-item myProfilePadding" data-toggle="modal" onClick={onClickChangePass}
                                            data-target=".bs-example-modal-center-password-change">
                                            <i className="fa fa-lock m-r-5 "></i>
                                            {CommonUtil.getMessageText(Constants.CHANGE_PASSWORD, 'Change Password')}
                                        </a>
                                        <a className="dropdown-item myProfilePadding" data-toggle="modal" style={{display:"none"}}
                                            onClick={() => { onClickNotificationModal() }}
                                            data-target=".bs-example-modal-center-notification">
                                            <i className="fa-solid fa-bell m-r-5 "></i>
                                            {CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications')}
                                        </a>
                                        <a className="dropdown-item myProfilePadding" data-toggle="modal" style={{display:"none"}} onClick={onClickLanguage}
                                            data-target=".bs-example-modal-center-change-language">
                                            <i className="fa fa-language m-r-5 "></i>
                                            {CommonUtil.getMessageText(Constants.CHANGE_LANGUAGE, 'Change Language')}
                                        </a>
                                        <a className="dropdown-item myProfilePadding" data-toggle="modal"
                                            onClick={onClickSwitchCustomer}
                                            style={{ display: switchCustomer ? '' : 'none' }}
                                            data-target=".bs-example-modal-center-search">
                                            <i className="fa fa-refresh m-r-5 "></i>
                                            {CommonUtil.getMessageText(Constants.SWITCH_CUST, 'Switch Customer')}
                                        </a>
                                        <a className="dropdown-item myProfilePadding" href="#" style={{ textDecoration: 'none', color: '#000' }} onClick={onClickLogout}>
                                            <i className="fa-solid fa-right-from-bracket "></i>
                                            {CommonUtil.getMessageText(Constants.LOGOUT, 'Logout')}
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="row col-12" style={{ marginLeft: 0 }}>
                                <div className="col-md-12 col-lg-12">
                                    <nav className="navbar-custom">
                                        <ul className="list-inline mb-0 navi">
                                            {/* <li className="list-inline-item dropdown d-inline-block d-md-none" id={'mobile-search'}>
                                            <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                                               data-bs-toggle="dropdown" href="#" role="button"
                                               aria-haspopup="false" aria-expanded="false">
                                                <i className="fa fa-search noti-icon text-white"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right profile-dropdown dropdownsearch"
                                                 aria-labelledby={'mobile-search'}>
                                                <form role="search" className="app-search" style={{display:"none"}}>
                                                    <div className="form-group mb-0">
                                                        <button type="submit">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                        <input type="text" className="form-control" placeholder="Search here"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </li> */}


                                            <li className="list-inline-item dropdown notification-list d-sm-inline-block calendar1"
                                                onClick={onNavigateToSearchDB}>
                                                <a href="#">
                                                    <i className="fa-regular fa-calendar-days noti-icon"></i>
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list">
                                                <a className="nav-link arrow-none waves-effect"
                                                    href="#" id="messagesdDropDown" data-bs-toggle="dropdown"
                                                    role="button" aria-haspopup="false" aria-expanded="false">
                                                    <i className="fa fa-ticket noti-icon"></i>
                                                    <span className="badge badge-danger badge-pill noti-icon-badge"
                                                        style={{ display: ownerReservationCount + renterReservationCount > 0 ? '' : 'none' }}>
                                                        {ownerReservationCount + renterReservationCount}
                                                    </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdownstylemobile"
                                                    aria-labelledby={'messagesdDropDown'}>
                                                    <div className="dropdown-item noti-title">
                                                        <h5>Reservations</h5>
                                                        <span className="badge badge-danger float-right">
                                                            {ownerReservationCount + renterReservationCount}
                                                        </span>

                                                    </div>
                                                    <div className="slimscroll" style={{ maxHeight: '230px' }}>
                                                        {CommonUtil.ORGANIZATION_TYPE ==
                                                            CommonUtil.ORG_TYPE_OWNER ||
                                                            CommonUtil.ORGANIZATION_TYPE ==
                                                            CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                                                            <a href="#;" className="dropdown-item notify-item" onClick={onClickReservationsHeader}>
                                                                <div className="notify-icon bg-primary">
                                                                    <i className="fa fa-2xs">
                                                                        {ownerReservationCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">
                                                                    <b>{CommonUtil.getMessageText(Constants.OWNER_RESERVATIONS, 'Owner Reservations')}</b>
                                                                    <span className="text-muted">
                                                                        Reservations made by Renters
                                                                    </span>
                                                                </p>
                                                            </a>) : ('')}

                                                        {CommonUtil.ORGANIZATION_TYPE ==
                                                            CommonUtil.ORG_TYPE_RENTER ||
                                                            CommonUtil.ORGANIZATION_TYPE ==
                                                            CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                                                            <a href="#;" className="dropdown-item notify-item" onClick={onClickRenterReservationsHeader}>
                                                                <div className="notify-icon bg-success">
                                                                    <i className="fa fa-2xs">
                                                                        {renterReservationCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">
                                                                    <b>{CommonUtil.getMessageText(Constants.RENTER_RESERVATIONS, 'Renter Reservations')}
                                                                    </b>
                                                                    <span className="text-muted">
                                                                        {CommonUtil.getMessageText(Constants.RESERVATIONS_MADE, 'Reservations made',)}{' '}
                                                                    </span>
                                                                </p>
                                                            </a>) : ('')}
                                                    </div>

                                                    <a href="#;" className="dropdown-item notify-all" style={{ display: 'none' }}>
                                                        View All
                                                    </a>
                                                </div>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list">
                                                <a className="nav-link arrow-none waves-effect" data-bs-toggle="dropdown"
                                                    id={'notificationsDropDown'} href="#"
                                                    role="button" aria-haspopup="false" aria-expanded="false">
                                                    {/* <i className="fa-regular fa-bell" color="white"></i> */}
                                                    <i className="fa fa-handshake noti-icon" style={{ color: 'white' }}></i>
                                                    <span className="badge badge-success badge-pill text-bg-success noti-icon-badge"
                                                        style={{ display: (Number(ownerReceiveCount) + Number(renterMadeCount)) > 0 ? '' : 'none', }}>
                                                        {(ownerReceiveCount + renterMadeCount)}
                                                    </span>
                                                </a>
                                                <div aria-labelledby={'notificationsDropDown'}
                                                    className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdownstylemobile">
                                                    <div className="dropdown-item noti-title">
                                                        <span className="badge badge-danger float-right">
                                                            {(ownerReceiveCount + renterMadeCount)}
                                                        </span>
                                                        <h5>{CommonUtil.getMessageText(Constants.OFFERS, 'Offers')}
                                                        </h5>
                                                    </div>

                                                    <div className="slimscroll" style={{ maxHeight: '230px' }}>
                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                                            (<a href="#!;" className="dropdown-item notify-item" onClick={onClickOfferReceiveHeader}>
                                                                <div className="notify-icon bg-primary">
                                                                    <i className="fa fa-xs">
                                                                        {ownerReceiveCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">{CommonUtil.getMessageText(Constants.OFFERS_RECEIVED, 'Offers Received')}
                                                                </p>
                                                            </a>) : ('')}

                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                                                            <a href="#" className="dropdown-item notify-item" onClick={onClickOfferHeader}>
                                                                <div className="notify-icon bg-success">
                                                                    <i className="fa  fa-xs">
                                                                        {renterMadeCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">{CommonUtil.getMessageText(Constants.OFFERS_SENT, 'Offers Sent')}</p>
                                                            </a>) : ('')}
                                                    </div>


                                                </div>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list d-sm-inline-block logo-right" onClick={onClickFavoritiesReport}>
                                                <a href="#">

                                                </a>
                                                <a className="nav-link arrow-none waves-effect"
                                                    data-bs-toggle="dropdown" id={'notificationsDropDown'}
                                                    href="#" role="button" aria-haspopup="false" aria-expanded="false">

                                                    <i className="fa-regular fa-heart noti-icon"></i>
                                                    <span className="badge badge-success badge-pill text-bg-success noti-icon-badge">
                                                        {favoriteCount > 0 ? favoriteCount : ""}
                                                    </span>
                                                </a>

                                            </li>
                                            {(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) && CommonUtil.OWNER_STATUS === CommonUtil.APPROVED ?
                                                <li id={"linkAvailability"} className="list-inline-item dropdown notification-list d-sm-inline-block calendar1"
                                                    onClick={onNavigateToAvailability}>
                                                    <a href="#" style={{ color: 'white', textDecoration: 'none', cursor: "pointer" }}>
                                                        <button className="btn btn-warning waves-effect">{CommonUtil.getMessageText(Constants.CREATE_AVAILABILITY, "Create Availability")}</button>
                                                    </a>
                                                </li> : ""}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="ml-0 d-none d-md-block">
                            <div className={'col-12 row p-0 m-0'}>
                                <div className="col-md-3 col-lg-2">
                                    <div className="topbar-left">
                                        <div className="row">
                                            <div className="p-0 col-sm-2 col-md-3 col-lg-3 col-xl-2 text-left align-content-center"
                                                style={{ justifyContent: 'center', alignContent: 'center', alignSelf: 'center', zIndex: 20 }}>
                                                <button type="button" className="button-menu-mobile open-left waves-effect" style={{ alignSelf: 'center' }}
                                                    onClick={handleMenu} ref={menuButtonRef} data-bs-toggle="tooltip" title="Menu" >
                                                    {open ? (<i className="fa fa-times fa-2xs" />) : (<i className="fa fa-bars fa-2xs" />)}
                                                </button>
                                            </div>

                                            <div className="col-sm-10 col-md-9 col-lg-9 col-xl-10">
                                                {/* <a href="index.html" className="logo color"><img src="assets/images/logo.png"  alt="vHub" /></a> */}
                                                <a href="#" className="logo" onClick={gotoMenu}>
                                                    <img src={'../../images/logo.png'} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-9 col-lg-10 align-self-center">
                                    <nav className="navbar-custom">
                                        <ul className="list-inline mb-0 navi">
                                            <li className="list-inline-item dropdown notification-list d-inline-block d-md-none search">
                                                <form role="search" className="app-search" style={{ display: "none" }}>
                                                    <div className="form-group mb-0">
                                                        <button type="submit">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                        <input type="text" className="form-control search" placeholder="Search here" />
                                                    </div>
                                                </form>
                                            </li>

                                            {(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) && CommonUtil.OWNER_STATUS === CommonUtil.APPROVED ?
                                                <li id={"linkAvailability1"} className="list-inline-item dropdown notification-list d-sm-inline-block calendar1"
                                                    onClick={onNavigateToAvailability}>
                                                    <a href="#" style={{ color: 'white', textDecoration: 'none', cursor: "pointer" }}>
                                                        {/*<i className="fa fa-truck"></i>*/}
                                                        <button className="btn btn-warning waves-effect">{CommonUtil.getMessageText(Constants.CREATE_AVAILABILITY, "Create Availability")}</button>
                                                    </a>
                                                </li> : ""}

                                            <li className="list-inline-item dropdown notification-list d-sm-inline-block calendar1"
                                                onClick={onNavigateToSearchDB} data-bs-toggle="tooltip"  title="Calendar">
                                                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                                                 <i className="fa-regular fa-calendar-days noti-icon"></i>  
                                                </a>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list" data-bs-toggle="tooltip"  title="Reservations">
                                                <a className="nav-link arrow-none waves-effect" href="#" id="messagesdDropDown"
                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                                                    <i className="fa fa-ticket noti-icon"></i>
                                                    <span className="badge badge-danger badge-pill noti-icon-badge"
                                                        style={{ display: ownerReservationCount + renterReservationCount > 0 ? '' : 'none' }}>
                                                        {ownerReservationCount + renterReservationCount}
                                                    </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdownstyle"
                                                    aria-labelledby={'messagesdDropDown'}>
                                                    <div className="dropdown-item noti-title" style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h5>Reservations</h5>
                                                        <span className="badge badge-danger float-right">
                                                            {ownerReservationCount + renterReservationCount}
                                                        </span>

                                                    </div>
                                                    <div className="slimscroll" style={{ maxHeight: '230px' }}>
                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                                            (<a href="#;" className="dropdown-item notify-item"
                                                                onClick={onClickReservationsHeader}>
                                                                <div className="notify-icon bg-primary">
                                                                    <i className="fa fa-2xs">
                                                                        {ownerReservationCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">
                                                                    <b>{CommonUtil.getMessageText(Constants.OWNER_RESERVATIONS, 'Owner Reservations')}
                                                                    </b>
                                                                    <span className="text-muted">
                                                                        Reservations made by Renters
                                                                    </span>
                                                                </p>
                                                            </a>
                                                            ) : ('')}

                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                                            (<a href="#;" className="dropdown-item notify-item" onClick={onClickRenterReservationsHeader}>
                                                                <div className="notify-icon bg-success">
                                                                    <i className="fa fa-2xs">
                                                                        {renterReservationCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">
                                                                    <b>{CommonUtil.getMessageText(Constants.RENTER_RESERVATIONS, 'Renter Reservations')}
                                                                    </b>
                                                                    <span className="text-muted">
                                                                        {CommonUtil.getMessageText(Constants.RESERVATIONS_MADE, 'Reservations made')}{' '}
                                                                    </span>
                                                                </p>
                                                            </a>
                                                            ) : ('')}
                                                    </div>


                                                </div>
                                            </li>

                                            <li className="list-inline-item dropdown notification-list" data-bs-toggle="tooltip"  title="Offers">
                                                <a className="nav-link arrow-none waves-effect"
                                                    data-bs-toggle="dropdown" id={'notificationsDropDown'}
                                                    href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                                    {/* <i className="fa-regular fa-bell" color="white"></i> */}
                                                    <i className="fa fa-handshake noti-icon" style={{ color: 'white' }}></i>
                                                    <span className="badge badge-success badge-pill text-bg-success noti-icon-badge"
                                                        style={{ display: (Number(ownerReceiveCount) + Number(renterMadeCount)) > 0 ? '' : 'none' }}>
                                                        {(ownerReceiveCount + renterMadeCount)}
                                                    </span>
                                                </a>
                                                <div aria-labelledby={'notificationsDropDown'}
                                                    className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                                    <div className="dropdown-item noti-title" style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h5>{CommonUtil.getMessageText(Constants.OFFERS, 'Offers')}</h5>
                                                        <span className="badge badge-danger float-right">
                                                            {(ownerReceiveCount + renterMadeCount)}
                                                        </span>

                                                    </div>

                                                    <div className="slimscroll" style={{ maxHeight: '230px' }}>
                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                                            (<a href="#!;" className="dropdown-item notify-item" onClick={onClickOfferReceiveHeader}>
                                                                <div className="notify-icon bg-primary">
                                                                    <i className="fa fa-xs">
                                                                        {ownerReceiveCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">{CommonUtil.getMessageText(Constants.OFFERS_RECEIVED, 'Offers Received')}
                                                                </p>
                                                            </a>
                                                            ) : ('')}

                                                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ?
                                                            (<a href="#" className="dropdown-item notify-item" onClick={onClickOfferHeader}>
                                                                <div className="notify-icon bg-success">
                                                                    <i className="fa  fa-xs">
                                                                        {renterMadeCount}
                                                                    </i>
                                                                </div>
                                                                <p className="notify-details">
                                                                    {CommonUtil.getMessageText(Constants.OFFERS_SENT, 'Offers Sent')}
                                                                </p>
                                                            </a>
                                                            ) : ('')}
                                                    </div>


                                                </div>
                                            </li>
                                                <li className="list-inline-item dropdown notification-list d-sm-inline-block logo-right"
                                                    onClick={onClickFavoritiesReport} data-bs-toggle="tooltip"  title="Favorites">
                                                <a href="#">

                                                </a>
                                                <a className="nav-link arrow-none waves-effect"
                                                    data-bs-toggle="dropdown" id={'notificationsDropDown'}
                                                    href="#" role="button" aria-haspopup="false" aria-expanded="false">

                                                    <i className="fa-regular fa-heart noti-icon"></i>
                                                    <span className="badge badge-success badge-pill text-bg-success noti-icon-badge">
                                                        {favoriteCount > 0 ? favoriteCount : ""}
                                                    </span>
                                                </a>

                                            </li>

                                            <li className="list-inline-item dropdown author-list d-none d-md-inline-block">
                                                <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                                                    id="dropDownProfile" data-bs-toggle="dropdown" aria-expanded="false"
                                                    href="#" role="button" aria-haspopup="false">
                                                    {imageSrc ? <img src={imageSrc} alt="" /> : ''}
                                                    <div className="user-info d-none d-lg-inline-block">
                                                        <p className="user-name mb-0 mr-2">
                                                            {localStorage.getItem('user')}{' '}
                                                        </p>
                                                        <p className="user-name mb-0 mr-2" style={{ textAlign: 'right' }}>
                                                            {localStorage.getItem('orgName')}
                                                        </p>
                                                    </div>
                                                    <i className="fa fa-chevron-down" style={{ paddingLeft: '5px', paddingTop: '16px' }}></i>
                                                </a>

                                                <div className="dropdown-menu dropdown-menu-right profile-dropdown dropdownstyle"
                                                    x-placement="bottom-end" aria-labelledby="dropDownProfile">
                                                    <a className="dropdown-item myProfilePadding" data-toggle="modal"
                                                        data-target=".bs-example-modal-center-profile" onClick={onClickMyProfile}>
                                                        <i className="fa-solid fa-user m-r-5"></i>
                                                        {CommonUtil.getMessageText(Constants.MY_PROFILE, 'My Profile')}
                                                    </a>
                                                    <a className="dropdown-item myProfilePadding" data-toggle="modal"
                                                        onClick={onClickChangePass} data-target=".bs-example-modal-center-password-change">
                                                        <i className="fa fa-lock m-r-5 "></i>
                                                        {CommonUtil.getMessageText(Constants.CHANGE_PASSWORD, 'Change Password')}
                                                    </a>
                                                    <a className="dropdown-item myProfilePadding" data-toggle="modal" style={{display:"none"}}
                                                        onClick={() => { onClickNotificationModal() }}
                                                        data-target=".bs-example-modal-center-notification">
                                                        <i className="fa-solid fa-bell m-r-5 "></i>
                                                        {CommonUtil.getMessageText(Constants.NOTIFICATIONS, 'Notifications')}
                                                    </a>
                                                    <a className="dropdown-item myProfilePadding" data-toggle="modal" style={{display:"none"}} onClick={onClickLanguage}
                                                        data-target=".bs-example-modal-center-change-language">
                                                        <i className="fa fa-language m-r-5 "></i>
                                                        {CommonUtil.getMessageText(Constants.CHANGE_LANGUAGE, 'Change Language')}
                                                    </a>
                                                    <a className="dropdown-item myProfilePadding"
                                                        data-toggle="modal" onClick={onClickSwitchCustomer}
                                                        style={{ display: switchCustomer ? '' : 'none' }}
                                                        data-target=".bs-example-modal-center-search">
                                                        <i className="fa fa-refresh m-r-5 "></i>
                                                        {CommonUtil.getMessageText(Constants.SWITCH_CUST, 'Switch Customer')}
                                                    </a>
                                                    <a className="dropdown-item myProfilePadding" href="#"
                                                        style={{ textDecoration: 'none', color: '#000' }}
                                                        onClick={onClickLogout}>
                                                        <i className="fa-solid fa-right-from-bracket "></i>
                                                        {CommonUtil.getMessageText(Constants.LOGOUT, 'Logout')}
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="clearfix"></div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="topbar-breadcrumbs" id='topbar-breadcrumbs'>
                    <div className="col-12 row p-0 m-0" style={{ flexDirection: 'row' }} >
                        <div className="align-content-center justify-content-center p-0" style={{ width: '70%' }} >

                            <span className="breadcrumbs" style={{ fontWeight: '600' }}>
                                <span className="active">{breadCrumb}</span>
                                &nbsp;
                                {breadCrumb1 ? (<span>/{' '}
                                    <a style={{ color: 'blue', cursor: 'pointer' }}
                                        onClick={() => { onClickBreadCrumb(breadCrumb1) }}>
                                        {breadCrumb1}
                                    </a>
                                </span>) : ('')}
                                {breadCrumb2 ? <span> / {breadCrumb2 === "Interchange Dashboard"?<a onClick={()=>{navigate("/menu/InterchangeApplication")}}>{breadCrumb2}</a>:breadCrumb2}</span> : ''}
                            </span>
                        </div>
                        <div className="dropdown text-right last-accessed breadcrumbs p-0" style={{ width: '30%' }} >
                            <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                                data-bs-toggle="dropdown" id="lastAccessed"
                                role="button" aria-haspopup="false" aria-expanded="false">
                                <>{CommonUtil.getMessageText(Constants.LAST_ACCESSED, 'Last Accessed')}{' '}
                                </>
                                <i className="fa fa-chevron-down fa-xs mt-2" style={{ fontSize: '12px' }}></i>
                            </a>
                            <div aria-labelledby="lastAccessed" className="dropdown-menu dropdown-menu-right lastAccessedStyle">
                                {CommonUtil.getLastAccessScreensByUser() ? CommonUtil.getLastAccessScreensByUser().map((item: any, idx: number) => {
                                    //if (item && item.userName == CommonUtil.getUserId()) {
                                    let icon = "fa fa-file-text";
                                    let scrnName = "";
                                    if (item.screenName == Constants.EQUIPMENT_REPORT) {
                                        scrnName = CommonUtil.getMessageText(Constants.EQUIPMENT_REPORT1, 'Equipment Report');
                                        icon = "fa fa-truck";
                                    } else if (item.screenName == Constants.AVAILABILITY_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.AVAILABILITY_SCREENS1, 'Availabilities');
                                        icon = "fa fa-file-text";
                                    } else if (item.screenName == Constants.OWNER_RESERVATION_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.OWNER_RESERVATION_SCREENS1, 'Owner Reservation');
                                        icon = "fa fa-vcard";
                                    } else if (item.screenName == Constants.ADMIN_RESERVATION_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.ADMIN_RESERVATION_SCREENS1, 'Reservations');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.RENTER_RESERVATION_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.RENTER_RESERVATION_SCREENS1, 'Renter Reservation');
                                        icon = "fa fa-vcard";
                                    } else if (item.screenName == Constants.OFFER_SENT_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.OFFER_SENT_SCREENS1, 'Offers Sent');
                                        icon = "fa fa-file-text";
                                    } else if (item.screenName == Constants.OFFER_RECEIVED_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.OFFER_RECEIVED_SCREENS1, 'Offers Received');
                                        icon = "fa fa-file-text";
                                    } else if (item.screenName == Constants.CUSTOMER_PROFILES_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.CUSTOMER_PROFILES_SCREEN1, 'Customer Profiles');
                                        icon = "fa fa-check-circle";
                                    } else if (item.screenName == Constants.DRIVER_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.DRIVER_SCREEN1, 'Driver');
                                        icon = "fa fa-user";
                                    } else if (item.screenName == Constants.PARKING_LOT_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.PARKING_LOT_SCREEN1, 'Parking Lot');
                                        icon = "fa fa-truck";
                                    } else if (item.screenName == Constants.NOTIFICATION_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.NOTIFICATION_SCREEN1, 'Notifications');
                                        icon = "fa fa-th-list";
                                    } else if (item.screenName == Constants.SEARCH_DASHBOARD) {
                                        scrnName = CommonUtil.getMessageText(Constants.SEARCH_DASHBOARD1, 'Search Dashboard');
                                        icon = "fa fa-street-view";
                                    } else if (item.screenName == Constants.EQUIPMENT_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.EQUIPMENT_SCREEN1, 'Equipments');
                                        icon = "fa fa-cogs";
                                    } else if (item.screenName == Constants.MASTER_DATA_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.MASTER_DATA_SCREEN1, 'Master data import');
                                        icon = "fa fa-th-list";
                                    }else if (item.screenName == Constants.INSPECTION_COMPANY_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.INSPECTION_COMPANY1,"Inspection Company")
                                        icon = "fa fa-user-check";
                                    }else if (item.screenName == Constants.INTERCHANGE_APPLICATION_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.INTERCHANGE_APPLICATION1,"Interchange Dashboard")
                                        icon = "fa fa-arrow-down-up-across-line";
                                    }
                                     else if (item.screenName == Constants.API_HUB_SCREEN) {
                                    } else if (item.screenName == Constants.API_HUB_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.API_HUB_SCREEN1, 'API HUB');
                                        icon = "fa fa-code";
                                    } else if (item.screenName == Constants.FAVORITIES_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.FAVORITIES_SCREENS1, 'Favorities');
                                        icon = "fa fa-vcard";
                                    } else if (item.screenName == Constants.NOTIFICATION_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.NOTIFICATION_SCREEN1, 'Notifications');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.INSURANCE_PLANS_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.INSURANCE_PLANS_SCREEN1, 'Insurance Plans');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.ORG_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.ORG_SCREEN1, 'Organizations');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.OFFER_RECEIVED_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.OFFER_RECEIVED_SCREENS1, 'Offers received');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.OFFER_SENT_SCREENS) {
                                        scrnName = CommonUtil.getMessageText(Constants.OFFER_SENT_SCREENS1, 'Offers Sent');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.PARKING_LOT_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.PARKING_LOT_SCREEN1, 'Parking Lot');
                                        icon = "fa fa-vcard";
                                    }else if (item.screenName == Constants.RATE_TABLE_SCREEN) {
                                        scrnName = CommonUtil.getMessageText(Constants.RATE_TABLE_SCREEN1, 'Rate Table');
                                        icon = "fa fa-vcard";
                                    }
                                    var list = (
                                        <a className="dropdown-item myProfilePadding" href="#" onClick={() => { onClickLastAccessScreen(item) }} key={idx} >
                                            <i className={icon} style={{ marginRight: "0px", fontSize: "13px" }}></i> &nbsp;&nbsp; {scrnName}
                                        </a>
                                    )
                                    return list
                                    //}
                                }) : null
                                }
                                {
                                    CommonUtil.getLastAccessScreensByUser().length == 0 ? <div><a className="dropdown-item" href="#" style={{ visibility: "hidden" }}>
                                        <i className="fa fa-file-text"></i> Invoices
                                    </a>
                                        <a className="dropdown-item" href="#" style={{ visibility: "hidden" }}>
                                            <i className="fa fa-tags"></i> Assets for Sale
                                        </a></div> : null
                                }
                                {
                                    CommonUtil.getLastAccessScreensByUser().length == 1 ? <div><a className="dropdown-item" href="#" style={{ visibility: "hidden" }}>
                                        <i className="fa fa-file-text"></i> Invoices
                                    </a>
                                    </div> : null
                                }


                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </React.Fragment>
    )
}

export default MenuHeader
