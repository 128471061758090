import React, {useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import $ from "jquery";
import {NetworkManager} from "../../Components/NetworkManager";
import Flatpickr from "react-flatpickr";
import {Constants} from "../Languages/Constants";

const EquipAvailTypeRenderer = (props:any)=>{

    const chkAllowRef = useRef<HTMLInputElement>(null);
    const [showAvailPopup,setShowAvailPopup] = useState(false);
    const [stDate,setStDate] = useState("");
    const [endDate,setEndDate] = useState("");

    const [policyId,setPolicyId] = useState("");
    const [perDayRate,setPerDayRate] = useState("");

    const [addtionalPrice,setAdditionalPrice] = useState("");
    const [dailyRate,setDailyRate] = useState("");
    const [distanceRate,setDistanceRate] = useState("");
    const [engineRate,setEngineRate] = useState("");
    const [movePrice,setMovePrice] = useState("");
    const [noOfDays,setNoOfDays] = useState("");
    const [eDisRate,setEDisRate] = useState("");
    const [priceKm,setPriceKm] = useState("");
    const [quantity,setQuantity] = useState("");

    const [isGenericReposition,setGenericReposition] = useState(false);
    const [isGenericRental,setGenericRental] = useState(false);
    const [isSpecificReposition,setSpecificReposition] = useState(false);
    const [isSpecificRental,setSpecificRental] = useState(false);

    const [selDepartureObj,setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj,setSelArrivalObj] = useState<any>(null);

    const [departure,setDeparture] = useState("");
    const [arrival,setArrival] = useState("");
    const [currency,setCurrency] = useState("");
    const [loadType,setLoadType] = useState("");

    const [lPolicyId,setLPolicyId] = useState("");
    const [lPolicyIdRate,setLPolicyIdRate] = useState("");
    const [phyPolicyId,setPhyPolicyId] = useState("");
    const [phyPolicyRate,setPhyPolicyRate] = useState("");

    const priceRef = useRef<HTMLInputElement>(null);

    const [currencies,setCurrencies] = useState(CommonUtil.DEF_CURRENCY);
    const [currencyObj,setCurrencyObj] = useState<any>(null);

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);

    let onCloseEquipAvailPopup = ()=>{
        setShowAvailPopup(false);
    }
    let setAvailabilityType = ()=>{
        setGenericReposition(false);
        setGenericRental(false);
        setSpecificReposition(false);
        setSpecificRental(false);

        setQuantity("");
        setPriceKm("");
        setEDisRate("");
        setDailyRate("");
        setDistanceRate("");
        setEngineRate("");
        if(chkAllowRef.current){
            chkAllowRef.current.checked = false;
        }
        setMovePrice("");
        setAdditionalPrice("");
        setCurrencyObj("");
        setSelDepartureObj("");
        setSelArrivalObj("");
        setLoadType("");
        setPolicyId("");
        setPerDayRate("");


        //CommonUtil.IS_GENERIC_EQUIP = true;

        if(CommonUtil.IS_GENERIC_EQUIP){
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                setGenericReposition(true);
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                setGenericRental(true);
            }
        }else{
            if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                setSpecificReposition(true);
            }else if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){
                setSpecificRental(true);
            }
        }
    }

    let onClickLinkDetails = (data:any) =>{
        //console.log(data);
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
        CommonUtil.SELECT_EQUIP_AVAIL_ID = data.equipmentListingId;
        CommonUtil.EQUIP_AVAIL_TYPE = data.listingType;
        CommonUtil.SELECT_EQUIPMENT_TYPE = data.equipmentType;

        setAvailabilityType();

        setShowAvailPopup(true);

        let parkingAPI:string = "api/availability/"+data.equipmentListingId+"?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetAvailabilityDetails,onLoginError);
    }
    let onGetAvailabilityDetails = (dataObj:any)=>{
        setAvailabilityType();
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(stDateRef.current){
                    stDateRef.current.flatpickr.setDate(new Date(restData.startDate));
                }
                if(endDateRef.current){
                    endDateRef.current.flatpickr.setDate(new Date(restData.endDate));
                }

                //setSelDepartureObj({parkingLotId:"",address:"");
                setStDate(restData.startDate);
                setEndDate(restData.endDate);
                setQuantity(restData.quantity);
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setPriceKm(restData.pricePerKm);
                setEDisRate(restData.estimatedDistance);
                setDailyRate(restData.dailyRate);
                setDistanceRate(restData.distanceRate);
                setEngineRate(restData.engineRate);

                if(chkAllowRef.current){
                    chkAllowRef.current.checked = false;
                    if(restData.allowOffer == "Y") {
                        chkAllowRef.current.checked = true;
                    }
                }

                setMovePrice(restData.movePrice);
                setAdditionalPrice(restData.additionalPriceExcessDays);
                setCurrencyObj({key:restData.defaultCurrency,value:restData.defaultCurrency});
                setCurrency(restData.defaultCurrency);

                setDeparture(restData.departureParkingLotAddress);
                setArrival(restData.arrivalParkingLotAddress);
                setNoOfDays(restData.noOfDaysAllowed);
                setLoadType(restData.loadType);
                setMovePrice(restData.movePrice);

                setPolicyId(restData.policyName);
                setPerDayRate(restData.insurancePerDayAmount);

                setSelDepartureObj({parkingLotId:restData.departureParkingLotId,address:restData.departureParkingLotAddress});
                setSelArrivalObj({parkingLotId:restData.arrivalParkingLotId,address:restData.arrivalParkingLotAddress});

                setLPolicyId(restData.policyNameLiability);
                setPhyPolicyId(restData.policyNamePhysical);

                setLPolicyIdRate(restData.insurancePerDayAmountLiability);
                setPhyPolicyRate(restData.insurancePerDayAmountPhysical);
            }
        }

    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devAvailPopup', 'devAvailContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let onLoginError = ()=>{

    }

    const onClickUser = ()=>{
        if(props.context){
            onClickLinkDetails(props.data);
            //props.context.onClickLinkDetails(props.data);
        }
    }
    return(
        <>
            <Modal  show={showAvailPopup} dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{"width": "600px","height":"650px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?CommonUtil.getMessageText(Constants.REPOSITION,"Reposition"):CommonUtil.getMessageText(Constants.RENTAL,"Rental")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginLeft:"0px"}} onClick={onCloseEquipAvailPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{paddingTop:"0px",overflow:"auto"}}>
                        <div className="container" style={{padding:"0px"}}>
                            <br />
                            <section style={{boxShadow:"none",padding:"0px"}}>
                                {/*<h5 style={{textAlign:"center"}}>New Repositioning</h5>*/}
                                <p style={{textAlign: "center",display:"none"}}>{CommonUtil.getMessageText(Constants.AVAIL_DATES,"Availabilities will be automatically reallocated if a reservation is made on a subset of the dates")}</p>
                                <div className="row">
                                    <div className="col-sm-6 col-6" style={{display:(lPolicyId?"":"none")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_PLAN,"Liability Plan")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={lPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6" style={{display:(lPolicyIdRate?"":"none")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY,"Per Day Rate")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={lPolicyIdRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6" style={{display:(phyPolicyId?"":"none")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN,"Physical Damage PolicyId")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={phyPolicyId} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6" style={{display:(phyPolicyRate?"":"none")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY,"Per Day Rate")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={phyPolicyRate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT,"Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={stDate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={endDate} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6" style={{display:((isSpecificReposition || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP,"Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input  type="text" value={quantity} className="form-control"
                                                        placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"> {CommonUtil.getMessageText(Constants.DAYS_ALLOWED,"Days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input   type="text" value={noOfDays} className="form-control"
                                                         placeholder="" aria-label="trailer" disabled={true}  />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12" style={{display:((isGenericRental || isSpecificRental) ?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOAD_TYPE,"Load Type")}</label>
                                            <div className="col-sm-12">
                                                <input   type="text" value={loadType} className="form-control"
                                                         placeholder="" aria-label="trailer" disabled={true}  />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <h6 className="mt-2">{CommonUtil.getMessageText(Constants.LOCATION,"Location")}</h6>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                            <div className="col-sm-12 col-12">
                                                <input   type="text" value={departure} className="form-control"
                                                         placeholder="" aria-label="trailer" disabled={true}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT,"Arrival Parking Lot")}</label>
                                            <div className="col-sm-12 ">
                                                <input   type="text" value={arrival} className="form-control"
                                                         placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <h6 className="mt-2" style={{paddingBottom:"10px"}}>{CommonUtil.getMessageText(Constants.PRICING,"Pricing")}</h6>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12 checkbox flex">
                                                <input  ref={chkAllowRef} type="checkbox" disabled={true} className="checkBox" />
                                                <label htmlFor="vehicle1" style={{paddingLeft:"10px",display:"inline"}}>
                                                    {CommonUtil.getMessageText(Constants.ALLOW_RENTER,"Allow renter to make an offer for this availability")}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY,"Currency")}</label>
                                            <div className="col-sm-12 col-12">
                                                <input   type="text" value={currency} className="form-control"
                                                         placeholder="" aria-label="trailer" disabled={true}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE,"Paid Price to Carrier for Repositioning")}:</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input   type="text" className="form-control"
                                                         placeholder="" value={movePrice} aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE,"Additional Price per Excess Days")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input   type="text" className="form-control"
                                                         placeholder="" aria-label="trailer" value={addtionalPrice} disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row" style={{display:((isSpecificReposition || isGenericReposition)?"none":"")}}>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input   type="text" className="form-control  avail-text-field"
                                                         placeholder="" aria-label="trailer" value={dailyRate}
                                                         disabled={true} /> / {CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Distance Rate</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control  avail-text-field" value={distanceRate}
                                                       placeholder="" aria-label="trailer" disabled={true}  /> / {CommonUtil.getMessageText(Constants.MILE,"MILE")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6" style={{display:(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE)?"":"none"}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control  avail-text-field" value={engineRate}
                                                       placeholder="" aria-label="trailer" disabled={true}  /> / {CommonUtil.getMessageText(Constants.HOUR,"HOUR")}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row" style={{border:"1px solid #999",height:"100px",margin:"1px",display:"none"}}>
                                    <div className="col-sm-12 col-12" style={{display:"flex",alignItems:"center"}}>
                                        <div className="new-repositioning">
                                            <div className="form-group row" style={{marginBottom:"0px"}}>
                                                <div id="repositioning" className="col-sm-1 col-2">
                                                    <i className="fa fa-tag" style={{fontSize:"24px"}}></i>
                                                </div>
                                                <div className="col-sm-11 col-10">
                                                    <p style={{marginBottom:"0px"}}>These are the rates that will apply for this rental. vHub will
                                                        charge a fee in addition to these rates. Please refer to our
                                                        Payment Terms for full details.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-outline-dark" onClick={onCloseEquipAvailPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Close")}</button>
                    </div>
                </div>
            </Modal>

            <div className={"cellRenderHeader"}>
                <a><span onClick={onClickUser} className={"cellRender"}>{CommonUtil.getMessageText(Constants.DETAILS,"Details")}</span></a>
            </div>
        </>


    )
}

export default EquipAvailTypeRenderer;
