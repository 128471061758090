import {parseString} from 'xml2js'
import React, {useEffect, useRef, useState} from "react";
import './api.css';
import Overview from "./Overview";
import Authentication from "./Authentication";
import ListDriver from "./ListDriver";
import ApiOverview from "./ApiOverview";
import BusyLoader from "../../Components/BusyLoader";
import DetectDevice from '../../utils/DetectDevice';

// const path = require('path');
// const fs = require('fs');

const ApiHub = () => {
    const { isMobile } = DetectDevice();
    const [page, setPage] = useState('overview');
    const [apiList, setApiList] = useState([]);
    const [xml, setXml] = useState([]);
    const [apiIndex, setApiIndex] = useState<any>('');
    const [pageTab, setPageTab] = useState({});
    const [child, setChild] = useState<boolean>(false);
    const [subIndex, setSubIndex] = useState<any>('')
    const [isClicked, setIsClicked] = useState<boolean>(false);

    let onClick = (value: any) => {
        setSubIndex('')
        setPage(value)
        setApiIndex('')
    }
    const onClickApi = async (index: any, key: any) => {
        setSubIndex('')
        setPage(key);
        setChild(false);
        if (index === apiIndex) {
            setApiIndex(99999)
            // if(pageTab.length)
        } else {
            setApiIndex(index)
            await LoadXML(`../API/${key}/overview.xml`).then((data: any) => {
                parseString(data, function (err: any, result: any) {
                    setPageTab(result?.Index);
                })
            }).catch((err: any) => {
                console.log(err)
            })
        }
    }
    const onClickApiList = async (path: any, index: any) => {
        setSubIndex(index)
        await LoadXML(path).then((data: any) => {
            parseString(data, function (err: any, result: any) {
                setChild(true)
                setXml(result?.Api?.Headers || []);
            })
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const LoadXML = async (path: any) => {
        BusyLoader.showLoader();
        return await fetch(path)
            .then(res => res.text())
            .then(data => {
                setTimeout(() => {
                    BusyLoader.hideLoader()
                }, 500)
                return data;
            })
            .catch(err => {
                setTimeout(() => {
                    BusyLoader.hideLoader()
                }, 500)
                console.log(err);
            });
    }

    useEffect(() => {
        fetch('../API/root.xml')
            .then(res => res.text())
            .then(data => {
                parseString(data, function (err: any, result: any) {
                    setApiList(result?.Roots?.Root || []);
                });
            })
            .catch(err => {
                console.log(err);
            });
    }, [])

    function remove_linebreaks(str: any) {
        return str.replace(/[\r\n]+/gm, "");
    }

    return (
        <div className="page-content-wrapper ">

            <div className="api-container">

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 bg-gray p-4">
                        <form role="search" className="app-search mb-3">
                            <div className="form-group mb-0">
                                <button type="submit"><i className="fa fa-search"></i></button>
                                <input type="text" className="form-control"
                                       placeholder="Search API Docs"/>

                            </div>
                        </form>

                        <div className="accordion" id="accordionExample">
                            <h5 className={`accor mb-2 mt-4 ${isMobile ? `d-block ${isClicked ? 'upArrow' : 'downArrow'}` : ''}`} onClick={()=>{setIsClicked(!isClicked)}} data-bs-toggle="dropdown"
                                id="collapseOne" aria-haspopup="false" aria-expanded="false" role={'button'}
                                aria-controls="collapseOne">API Endpoints List</h5>
                            <div className={`collapse d-md-block ${isClicked ? 'show' : ''}`}
                                 aria-labelledby="collapseOne">
                                <ul className="sidebar-list">

                                    <li><a className={`${page === 'overview' ? 'active' : null}`} onClick={() => {
                                        onClick('overview')
                                    }}>Overview</a></li>
                                    <li><a className={`${page === 'authentication' ? 'active' : null}`} onClick={() => {
                                        onClick('authentication')
                                    }}>Authentication Overview</a></li>
                                    {apiList && apiList.map((api: any, index: any) => {
                                        let child = api?.Child;
                                        return (
                                            <div key={index} >
                                                <li className={`accordion plus collapsed ${apiIndex === index ? 'accor-plus' : ''}`}
                                                style={{ position:'relative' }}
                                                    onClick={() => {
                                                        onClickApi(index, remove_linebreaks(api?.RootKey?.[0]).trim())
                                                    }}>
                                                    <a className={`${page === remove_linebreaks(api?.RootKey?.[0]).trim() ? 'active' : ''}`}>
                                                        {api?.RootName?.[0]}
                                                    </a>
                                                </li>
                                                <div id={`API${index}`}
                                                     className={`collapse ${apiIndex === index ? 'show' : ''}`}
                                                >
                                                    <ul>
                                                        {child && child.length > 0 ? (
                                                            child.map((chi: any, chiIndex: any) => <li key={chiIndex} ><a
                                                                className={`${subIndex === chiIndex ? 'active' : ''}`}
                                                                href="#" onClick={() => {
                                                                onClickApiList(`../API/${remove_linebreaks(api?.RootKey?.[0]).trim()}/${remove_linebreaks(chi?.Key[0]).trim()}`, chiIndex)
                                                            }}>{chi?.Value[0]}</a></li>)
                                                        ) : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {page === 'overview' ? <Overview/> : null}
                    {page === 'authentication' ? <Authentication/> : null}
                    {apiIndex !== "" ? child ? <ListDriver xml={xml}/> : <ApiOverview xml={pageTab}/> : null}
                </div>


            </div>


        </div>
    )
}
export default ApiHub;