import React from "react";

const equipDocumentStatus = (props: any) => {

    let onClickRemove = () => {
        // console.log(props.data);
        if (props.context) {
            props.context.onClickRemoveDoc(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}
             style={{paddingTop: "10px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <i className="fa fa-download"></i>
            &nbsp;&nbsp;&nbsp;
            <i style={{color: "red", cursor: "pointer"}} className=" remove-btn fa fa-remove" onClick={onClickRemove}/>

        </div>

    )
}

export default equipDocumentStatus;