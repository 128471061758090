import React from 'react'
import CarouselComponent from '../CarouselComponent'
import '../Interchange.css'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'
import ProgressBar from 'react-bootstrap/ProgressBar';
import ImageLoader from '../ImageLoader'
import { Constants } from '../../Languages/Constants'



const ExitReeferInspection = () => {

    const [reeferDetails,setReeferDetails] = useState<any>([])
    const [attachments,setAttachments] = useState<any>([])
    const [galleryPop,setGalleryPop] = useState(false);
    const [galleryPopStartIndex,setGalleryPopStartIndex] = useState(0);
    const [damageDes,setDamageDes] = useState("") 

    let getReeferInspectionDetails = () =>{

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetReeferInspectionDetails,onLoginError);
      }

      useEffect(()=>{
        getReeferInspectionDetails();
      },[])

      let onGetReeferInspectionDetails = (dataObj:any)=>{
        if(dataObj){
            var restData = JSON.parse(dataObj);
            console.log(restData)
            setDamageDes(restData?.reeferInspection?.attachments?.attachmentDamageDescription)
            if(restData?.reeferInspection?.taskDetailsAttachment){
                var reefer = [];
            if(Array.isArray(restData?.reeferInspection?.taskDetailsAttachment)){
                reefer = restData?.reeferInspection?.taskDetailsAttachment;
            }else{
                reefer.push(restData?.reeferInspection?.taskDetailsAttachment); 
            }
            setReeferDetails(reefer)
            setAttachments(restData?.reeferInspection?.attachments);
        }
        }
      }
      let onLoginError = (err:any)=>{
        console.log(err)
  }
  console.log(reeferDetails);

  let filteredFirstTablesub1 = reeferDetails.filter((task:any)=>{
    if(task.name === "switchStatus" || task.name === "presets" ){
           return task
    } 
})

let filteredFirstTablesub2 = reeferDetails.filter((task:any)=>{
    if(task.name === "runMode" || task.name === "tempControl"){
           return task
    } 
})

let filteredSecondTable = reeferDetails.filter((task:any)=>{
    if(task.name === "presetsValue" || task.name === "engineHours" || task.name === "engineTemprature"){
           return task
    } 
})

let reeferFiltered2 = reeferDetails.filter((task:any)=>{
    if(task.name === "temprature"|| task.name === "existingFaultCode" || task.name === "pretrip" || task.name === "beltsAndBattery" || task.name === "oilLevel" || task.name === "fuelTank"){
           return task
    } 
})

  let fuelData = reeferDetails.filter((task:any)=>{
    if(task.name === "fuelLevel"){
        return task
    }
})

let Capitalize = (str:any)=>{
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);
    
    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
    
    }  
    
    let attachImages:any = [];
        try{
            attachImages = attachments?.attachmentUploads?.map((ele:any)=> ele.fullFilePath)
            console.log(attachImages)
        }catch(e){
            attachImages = [];
        }

  return (
    <div>
         <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-table-data table-responsive mb-2">
                                <table className="table table-bordered">
                                <thead>
                                        <tr>
                                            <th scope="col">INSPECTION ITEM</th>
                                            <th scope="col">PASS</th>
                                            <th scope="col">FAIL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                      filteredFirstTablesub1.map((data:any)=>{
                                        return(
                                        <>
                                        <tr>    
                                                <td>{Capitalize(data.name)}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.NO?"text-danger text-uppercase table-danger":"text-success text-uppercase"}`}>{String(data.status).toUpperCase() === Constants.NO?"NO":"YES"}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.YES?"text-success text-uppercase ":"text-danger text-uppercase table-danger"}`}>{String(data.status).toUpperCase() === Constants.YES?"NO":"YES"}</td>
                                                </tr>
                                        </>)
                                      })  
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="inner-table-data table-responsive mb-2">
                                <table className="table table-bordered">
                                    <tbody>
                                    {
                                      filteredFirstTablesub2.map((data:any)=>{
                                        return(
                                        <>
                                        <tr>    
                                                <td>{Capitalize(data.name)}</td>
                                                {/* <td className={`${data.status === "No"?"text-danger text-uppercase":"text-success text-uppercase"}`}>{data.status }</td> */}
                                                {/* <td className={`${data.status === "Yes"?"text-success text-uppercase":"text-danger text-uppercase"}`}>{data.status }</td> */}
                                                <td>{String(data.status).toUpperCase()}</td>
                                                </tr>
                                        </>)
                                      })  
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className="inner-table-data table-responsive mb-2">
                                <table className="table table-bordered">
                                    <tbody>
                                    {filteredSecondTable.map((data:any)=>{
                                            return(
                                            <>
                                             <tr>
                                                <td>{Capitalize(data.name)}</td>
                                                <td>{String(data.status).toUpperCase()}</td>
                                            </tr>
                                            </>)
                                        })}                                      
                                    </tbody>
                                </table>
                            </div>

                            <div className="inner-table-data table-responsive mb-2">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">INSPECTION ITEM</th>
                                            <th scope="col">PASS</th>
                                            <th scope="col">FAIL</th>
                                            <th scope="col">NOTE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {reeferFiltered2.map((data:any)=>{
                                                return(
                                                <>
                                                <tr>    
                                                <td>{Capitalize(data.name)}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.FAIL?"text-danger text-uppercase table-danger":"text-success text-uppercase"}`}>{String(data.status).toUpperCase() === Constants.FAIL?"NO":"YES"}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.OK?"text-success text-uppercase ":"text-danger text-uppercase table-danger"}`}>{String(data.status).toUpperCase() === Constants.OK?"NO":"YES"}</td>
                                                <td>{data.note !== ""?data.note:""}</td>
                                                </tr>
                                                </>)
                                            })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="fuel-section">
                          
                                <div className="card">
                                    <div className="card-body">                                        
                                            <div className="feature-icon float-start">
                                            <ImageLoader path={attachImages}/>
                                            </div>
                                            <div className="text-wrapper mt-3">
                                                        <p className="level text-muted">Fuel Level <span className="km-range">{fuelData[0]?.status}%</span></p>
                                                        <ProgressBar striped variant="danger" now={fuelData[0]?.status}/>
                                                    </div>                                       
                                    </div>
                                </div>
                           
                </div>


                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">

                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="gallery-form form">
                                        <h2 className="title-head my-4 mb-2">Exit Fuel Tank Damage Description</h2>
                                        <div className="form-floating">
                                            <div className="card p-3" placeholder="Leave a comment here"
                                                id="floatingTextarea2"
                                                style={{minHeight:'100px',height:"auto"}}>{damageDes}</div>
                                        </div>
                                    </div>
                        </div>
                    </div>
    </div>
  )
}

export default ExitReeferInspection