import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";

const ImageUpload = () => {

    const fileref = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [fileNmae, setFileName] = useState("");
    const [imgSource, setImgSource] = useState("");


    useEffect(() => {
        // toast.configure();
    }, [])

    let onSelectFiles = (e: any) => {
        console.log(e);
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name;
            setFileName(fileName);
            //self.setState({importFileName:fileName});
        }
    }

    let onClickBrowse = () => {
        inputRef.current?.classList.remove("is-invalid");
        if (fileref.current) {
            fileref.current.value = "";
            // fileref.current.onclick();
            $("#inputGroupFile02").click();
        }
        setFileName("");
    }

    let onClickDownload = () => {
        var qParam: string = "objectPath=images/organizations/clock.png";
        NetworkManager.sendJsonRequest("api/session/downloadUrl", {}, qParam, "GET", true, onDownloadSuccess, onUploadError);
    }
    let onDownloadSuccess = (data: any) => {
        console.log(data);
        setImgSource(data);
    }

    let onClickImport = () => {
        if (fileNmae === "") {
            inputRef.current?.classList.add("is-invalid");
        } else {
            inputRef.current?.classList.remove("is-invalid");
            if (fileref.current && fileref.current.files) {
                var qParam: string = "objectPath=images/organizations/" + fileref.current.files[0].name;
                NetworkManager.sendJsonRequest("api/session/uploadUrl", {}, qParam, "GET", true, onUploadSuccess, onUploadError);
            }
        }
    }

    let onUploadSuccess = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {
                var strUploadPath = dataObj;
                if (fileref.current && fileref.current.files) {

                    // const formData = new FormData();
                    //formData.append('username', 'abc123');
                    //formData.append('data', fileref.current.files[0]);

                    var myHeaders = new Headers();
                    //myHeaders.append("Content-Type", "application/json");

                    var file = fileref.current.files[0];

                    var requestOptions: any = { method: 'PUT', headers: myHeaders, body: file, redirect: 'follow' };

                    fetch(strUploadPath, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            ToastManager.showToast("Image Uploaded Successfully", "Info");
                        })
                        .catch(error => console.log('error', error));

                }
            }
        }
    }

    let onUploadError = (err: any) => {
        console.log(err);
    }

    return (
        <>
            <div className="custom-form">
                <div className="row">
                    <img src={imgSource} style={{ "width": "100px" }} alt='.' />
                </div>
                <div className="row">
                    <label
                        className="col-form-label col-form-label-sm col-md-12 col-lg-12 col-xl-3 text-lg-right text-xl-right required text-right"
                        style={{ textAlign: 'right' }}>{'Document Type'}</label>
                    <div className="col-md-4 col-lg-4 col-xl-4">
                        <div className="input-group input-group-sm">
                            <select className="form-select" required={true}>
                                <option>equipment</option>
                                <option>organizations</option>
                                <option>reservations</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row pt-2">
                    <label
                        className="col-form-label col-form-label-sm col-md-12 col-lg-12 col-xl-3 text-lg-right text-xl-right required text-right"
                        style={{ textAlign: 'right' }}>{'Select Image'}</label>
                    <div className="col-md-4 col-lg-4 col-xl-4">
                        <div className="input-group input-group-sm">
                            <input ref={inputRef} type="text" className="form-control form-control-sm" disabled={true}
                                value={fileNmae} />
                        </div>
                        <input type="file" ref={fileref} className="custom-file-input " id="inputGroupFile02"
                            accept="image/*"
                            style={{ "display": "none" }} onChange={(e) => {
                                onSelectFiles(e)
                            }}></input>
                    </div>
                </div>

                <div className="row pt-2">
                    <label
                        className="col-form-label col-form-label-sm col-md-12 col-lg-12 col-xl-3 text-lg-right text-xl-right"></label>
                    <div className="form-group col-md-6 col-lg-6 col-xl-6">
                        <button className="btn btn-success mr-10" style={{ marginRight: "10px" }}
                            onClick={onClickBrowse}>Browse
                        </button>
                        <button className="btn btn-success" style={{ marginRight: "10px" }}
                            onClick={onClickImport}>Import
                        </button>
                        <button className="btn btn-success" onClick={onClickDownload}>Download</button>
                    </div>
                </div>

            </div>

        </>
    );
}

export default ImageUpload;