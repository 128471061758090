import React, { useEffect, useState, useRef } from "react";
import { Table } from "react-bootstrap";
import NewReleaseEntryValues from "./NewReleaseEntryValues";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import Form from "react-bootstrap/Form";
import { initState } from "../../../Components/ReleaseContextInit";
import { Modal } from "react-bootstrap";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";

const NewEntryVisualInspection = ({ inspectionItem }: any) => {
  const fileref = useRef<HTMLInputElement>(null);
  let fileType: string = "";
  const qPath: any = CommonUtil.getInterchangeDocumentId();
  const { state, dispatch } = useReleaseInspectorContext();
  const visualInspection: any = state?.masterData?.visualInspection?.task
    ? [...state?.masterData?.visualInspection?.task]
    : [];

  const submitHandler = (event: any) => {
    event.preventDefault();
    const getImageData = state?.visualInspection?.task.filter(
      (items: any) => items.taskStatus === "fail"
    );
    const chekImage = getImageData?.filter(
      (items: any) => items.attachments?.attachmentUploads.length === 0
    );

    if (chekImage?.length > 0) {
      ToastManager.showToast("Please upload the images", "Error");
    } else {
      dispatch({
        type: "visualInspection",
        payload: {
          isCompleted: true,
        },
      });
      ToastManager.showToast("Successfully saved Visual inspection", "Success");
    }
  };

  const onAddPictures = (cId: string, fType: string) => {
    fileType = fType;
    if (fileref.current) {
      fileref.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile").click();
    }
  };

  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };

  let onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/${qPath}/${state?.eventId}/${state?.taskId}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;

              const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "addattachments",
                    payload: {
                      taskId: state?.taskID,
                      category: "visualInspection",
                      attachments: {
                        attachmentUploads: {
                          fullFilePath: imagePath,
                        },
                      },
                    },
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div
          className="section"
          style={{
            paddingRight: "12px",
            height: "420px",
            minHeight: "420px",
            overflowY: "scroll",
          }}
        >
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>INSPECTION ITEM</th>
                <th style={{ width: "120px" }}>
                  <div className="d-flex">
                    <div>PASS</div>
                    <div style={{ marginLeft: "32px" }}>FAIL</div>
                  </div>
                </th>
                <th style={{ width: "250px" }}>NOTE</th>
                <th style={{ width: "250px" }}>ATTACHMENTS</th>
              </tr>
            </thead>
            <tbody>
              {visualInspection?.map((data: any, i: number) => {
                return (
                  <NewReleaseEntryValues
                    key={i}
                    data={data}
                    inspectionItem={inspectionItem}
                    fileref={fileref}
                    onAddPictures={onAddPictures}
                  />
                );
              })}
            </tbody>
            <input
              type="file"
              ref={fileref}
              className="custom-file-doc-input"
              multiple={false}
              id="inputGroupFile"
              accept="image/*;pdf/*"
              style={{ display: "none" }}
              onChange={(e) => {
                onSelectFiles(e);
              }}
            />
          </Table>

          <div className="text-right">
            <button type="submit" className="btn btn-success btn-lg">
              Save
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default NewEntryVisualInspection;
