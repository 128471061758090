import { useEffect, useState } from "react";
import UpdatedVisualCompare from "./UpdatedVisualCompare";
import UpdatedLightsCompare from "./UpdateLightsCompare";
import UpdatedTiresCompare from "./UpdateTiresCompare";
import UpdatedPictureCompare from "./UpdatePictureCompare";
import UpdatedRefeerCompare from "./UpdateRefeerCompare";
import UpdatedCTPATCompare from "./UpdateCTPATCompare";
import UpdateCommentsAndSignatureCompare from "./UpdateCommentsAndSignatureCompare";
import { CommonUtil } from "../../../utils/CommonUtil";
import { NetworkManager } from "../../../Components/NetworkManager";
import ToastManager from "../../../Components/ToastManager";

const MultiReleaseCompare = () => {

    const [selecetedItem,setSelectedItem] = useState("Visual Inspection");
    const [refStatus, setRefStatus] = useState<any>({});

    const showCompareItem = (item:string)=>{
        switch(item){
            case "Visual Inspection":
                return <UpdatedVisualCompare />;
            case "Lights":
                return <UpdatedLightsCompare />;
            case "Tires":
                return <UpdatedTiresCompare />;
            case "Pictures":
                return <UpdatedPictureCompare />;
            case "Reefer":
                return <UpdatedRefeerCompare />;
            case "CTPAT Confirmation":
                return <UpdatedCTPATCompare />;            
            case "Comments":
                return <UpdateCommentsAndSignatureCompare/>;
            default:
                return <UpdatedVisualCompare />;
        }
    }

    const getGeneralDocDetails = () => {
      var qparam = CommonUtil.getInterchangeDocumentId();
      var orgUrl = `api/webInspectionDetails/getGeneralDocDetails?docId=${qparam}`;
      NetworkManager.sendJsonRequest(
        orgUrl,
        "",
        "",
        "GET",
        true,
        onGetGeneralDocDetails,
        onLoginError
      );
    };

    const onLoginError = () => {
      console.log("Someting went wrong");
      ToastManager.showToast("Someting went wrong! Please Try again.", "Error");
    };
  
    const onGetGeneralDocDetails = (dataObj: any) => {
      try {
        if (dataObj) {
          let restData: any = JSON.parse(dataObj);
          setRefStatus(restData);
        }
      } catch (e) {}
    };
  
    useEffect(() => {   
      CommonUtil.setSelectedEventForCompare("");
      getGeneralDocDetails();
    }, []);

  return (
    <div className="containData">
      <style>
        {`
          .splitter-layout .layout-pane {
            overflow: hidden;
          }
        `}
      </style>
      <div className="admin-wrapper container-fluid p-2">
        <div className="container sec-wrapper">
          <div className="row">
          

            <div className="col-xl-12 col-lg-12 col-md-12">
            <hr className="mb-3" style={{ width: "98%" }} />
                <div className="compare-buttons align-items-center my-1">
                    <span className="px-2 title-txt"> Release Inspection</span>
                     {<button onClick={()=>{setSelectedItem("Visual Inspection")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Visual Inspection" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Visual Inspection</button>}
                     {<button onClick={()=>{setSelectedItem("Lights")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Lights" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Lights</button>}
                     {<button onClick={()=>{setSelectedItem("Tires")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Tires" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Tires</button>}
                     {<button onClick={()=>{setSelectedItem("Pictures")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Pictures" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Pictures </button>}
                     {refStatus?.isReefer && <button onClick={()=>{setSelectedItem("Reefer")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Reefer" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Reefer</button>}
                     {<button onClick={()=>{setSelectedItem("CTPAT Confirmation")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "CTPAT Confirmation" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> CTPAT Confirmation</button>}
                     {<button onClick={()=>{setSelectedItem("Comments")}} type="button" className={`btn btn-compare mb-2 mb-xl-0 ${selecetedItem === "Comments" ?'active':''}`}><i className="fa-sharp fa-solid fa-circle-check text-success"></i> Comments</button>}
                  </div>
                  <hr className="mb-3" style={{ width: "98%" }} />
            </div>
           
            <div><b>{`${selecetedItem} Comparison`}</b></div>
            {
                showCompareItem(selecetedItem)
            }
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiReleaseCompare;
