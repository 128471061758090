import React, {useRef} from 'react';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options: Highcharts.Options = {
    title: {
        text: 'My chart'
    },
    series: [{
        type: 'line',
        data: [1, 2, 3]
    }]
};

const Page3 = (props: HighchartsReact.Props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    return (
        <React.Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}

            />

        </React.Fragment>

    )
}

export default Page3;
