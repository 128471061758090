import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {Constants} from "../Languages/Constants";

var equipData:any = null;
const EquipmentPrices = (props:any)=>{
    const dailyRateRef = useRef<HTMLInputElement>(null);
    const [dailyRate, setDailyRate] = useState("");
    const [weeklyRate, setWeeklyRate] = useState("");
    const [monthlyRate,setMonthlyRate] = useState("");

    const distanceRef = useRef<HTMLInputElement>(null);
    const [distanceRate,setDistanceRate] = useState("");

    const hourRef = useRef<HTMLInputElement>(null);
    const [hourRate,setHourlyRate] = useState("");

    useEffect(()=>{
        equipData = props.equipData;
        if(equipData){
            getEquipPrices();
            updatePricingValues(equipData.equipmentType);
        }
        
    },[]);

    const updatePricingValues = (type:string)=>{
        let qParam:string = `saasOrgId=${localStorage.getItem('saasorgId')}&equipmentType=${type}`;
        NetworkManager.sendJsonRequest("api/organization/getDefaultEquipmentTypePricing","",qParam,"GET",true,(response:any)=>{
            let restData:any = JSON.parse(response);
            console.log(restData);            
            setDailyRate(equipData?.pricing?.dailyRate ? equipData?.pricing?.dailyRate : restData?.dailyRate ? restData.dailyRate :'')
            setWeeklyRate(equipData?.pricing?.weeklyRate ? equipData?.pricing?.weeklyRate :restData?.weeklyRate? restData.weeklyRate :'')
            setMonthlyRate(equipData?.pricing?.monthlyRate ? equipData?.pricing?.monthlyRate :restData?.monthlyRate? restData.monthlyRate :'' )
            setDistanceRate(equipData?.pricing?.distanceRate ? equipData?.pricing?.distanceRate : restData?.distanceRate? restData.distanceRate :'')
            setHourlyRate(equipData?.pricing?.engineRate ? equipData?.pricing?.engineRate : restData?.engineRate? restData.engineRate : '')
        },onLoginError)
    }

    let getEquipPrices = ()=>{
        setDailyRate(equipData.pricing ? equipData.pricing.dailyRate : '')
        console.log(equipData.pricing);
        
        setWeeklyRate(equipData.pricing ? equipData.pricing.weeklyRate : '')
        setMonthlyRate(equipData.pricing ? equipData.pricing.monthlyRate : '')
        setDistanceRate(equipData.pricing ? equipData.pricing.distanceRate : '')
        setHourlyRate(equipData.pricing ? equipData.pricing.engineRate : '')
    }
    const onClickSavePrice = ()=>{
        let restObj:any = {};
        restObj.pricing = {};
        restObj.pricing.dailyRate = dailyRate;
        restObj.pricing.distanceRate = distanceRate;
        restObj.pricing.engineRate = hourRate;

        let method:string = 'PUT'
        let equipUrl:string = 'api/equipment/' + CommonUtil.SELECT_EQUIP_ID;

        NetworkManager.sendJsonRequest(equipUrl, restObj, '', method, true, onEquipCreation, onLoginError)
    }
    let onEquipCreation = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Equipment Prices are updated Successfully', 'Info')
            }
        }
    }

    let onLoginError = (err: any) => {
        console.log(err)
    }

    return(
        <>
            <div  className="container">
                <section className="pricing">

                    <p className="text-danger"><i
                        className="fa fa-exclamation-circle "></i> {CommonUtil.getMessageText(Constants.PRICING_UPDATE,"Pricing Updates will only affect new reservations.")}</p>
                    <div className="row">
                        <div className="col-sm-4 col-12 text-center">
                            <div className="shadow p-3 mb-3 bg-white border">
                                <img src="../../images/Daily-Rate.png" alt="Daily Rate" />
                                <span className="pricing-text">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}</span>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">{CommonUtil.getCurrency()} {dailyRate}</button> / {CommonUtil.getMessageText(Constants.DAY,"Day")}

                            </div>
                        </div>
                        <div className="col-sm-4 col-12 text-center">
                            <div className="shadow p-3 mb-3 bg-white border">
                                <img src="../../images/Daily-Rate.png" alt="Weekly Rate" />
                                <span className="pricing-text">{CommonUtil.getMessageText(Constants.WEEKLY_RATE,"Weekly Rate")}</span>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">{CommonUtil.getCurrency()} {weeklyRate}</button> / {CommonUtil.getMessageText(Constants.WEEK,"Week")}

                            </div>
                        </div>
                        <div className="col-sm-4 col-12 text-center">
                            <div className="shadow p-3 mb-3 bg-white border">
                                <img src="../../images/Daily-Rate.png" alt="Monthly Rate" />
                                <span className="pricing-text">{CommonUtil.getMessageText(Constants.MONTHLY_RATE,"Monthly Rate")}</span>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">{CommonUtil.getCurrency()} {monthlyRate}</button> / {CommonUtil.getMessageText(Constants.MONTH,"Month")}

                            </div>
                        </div>
                        <div className="col-sm-4 col-12 text-center">
                            <div className="shadow p-3 mb-3 bg-white border">
                                <img src="../../images/Distance-Rate.png"
                                     alt="Distance Rate" />
                                <span className="pricing-text">{CommonUtil.getMessageText(Constants.DISTANCE_RATE,"Distance Rate")}</span>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">{CommonUtil.getCurrency()} {distanceRate}</button> / {CommonUtil.getMessageText(Constants.MI,"MI")}
                            </div>
                        </div>
                        <div className="col-sm-4 col-12 text-center">
                            <div className="shadow p-3 mb-3 bg-white border">
                                <img src="../../images/Engine-Rate.png"
                                     alt="Engine Rate" />
                                <span className="pricing-text">Engine Rate</span>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">{CommonUtil.getCurrency()} {hourRate}</button> / {CommonUtil.getMessageText(Constants.HOUR,"HOUR")}
                            </div>
                        </div>
                    </div>
                    <p style={{display:"none"}}>These are the rates that will apply for this rental. vHub will charge a
                        fee in addition to these rates. Please refer to our <a
                            href="#"><u><strong>Payment Terms</strong></u></a> for full details.
                    </p>
                </section>
            </div>
            <section  className="bottom" style={{display:"none"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-right">
                            <button type="button" className="btn  btn-outline-dark" data-dismiss="modal">Discard Changes</button>
                            <button type="button" className="btn btn-success" onClick={onClickSavePrice}>Save</button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default EquipmentPrices;
