import { Constants } from "../Languages/Constants";
import { getSelectedRangeRate, CommonUtil } from "../../utils/CommonUtil";

export const AvailabilityItem = ({ item, onRowClick }: any) => {
  const { startAvailablity } = item;
  const rateObj: any = getSelectedRangeRate(
    CommonUtil.getDays(item.startDate, item.endDate),
    item.dailyRate,
    item.weeklyRate,
    item.monthlyRate
  );
  const showRelaventDate = (item: any) => {
    let sdate: any = CommonUtil.getDay(item.startDate);
    let edate: any = CommonUtil.getDay(item.endDate);
    let smonth: any = CommonUtil.getMonth(item.startDate);
    let emonth: any = CommonUtil.getMonth(item.endDate);
    let titleStartDate: string = CommonUtil.getMessageText(
      Constants.START_DATE,
      "Start Date"
    );
    let titleEndDate: string = CommonUtil.getMessageText(
      Constants.END_DATE,
      "End Date"
    );
    if (item.activationDate && item.deactivationDate) {
      titleStartDate = CommonUtil.getMessageText(
        Constants.ACTIVATION_DATE,
        "Activation Date"
      );
      titleEndDate = CommonUtil.getMessageText(
        Constants.DE_ACTIVATION_DATE,
        "Deactivation Date"
      );
      sdate = CommonUtil.getDay(item.activationDate);
      edate = CommonUtil.getDay(item.deactivationDate);
      smonth = CommonUtil.getMonth(item.activationDate);
      emonth = CommonUtil.getMonth(item.deactivationDate);
    } else if (
      item.reservationCode &&
      item.reservationEndDate &&
      item.reservationStartDate
    ) {
      titleStartDate = CommonUtil.getMessageText(
        Constants.RESERVATIONS_START_DATE,
        "Resetvation Start Date"
      );
      titleEndDate = CommonUtil.getMessageText(
        Constants.RESERVATIONS_END_DATE,
        "Resetvation End Date"
      );
      sdate = CommonUtil.getDay(item.reservationStartDate);
      edate = CommonUtil.getDay(item.reservationEndDate);
      smonth = CommonUtil.getMonth(item.reservationStartDate);
      emonth = CommonUtil.getMonth(item.reservationEndDate);
    }
    return (
      <>
        <div className="from" title={titleStartDate}>
          <h4>{sdate}</h4>
          <span> {smonth} </span>
        </div>
        <div className="from" title={titleEndDate}>
          <h4>{edate}</h4>
          <span> {emonth} </span>
        </div>
      </>
    );
  };

  const showAddress = (item: any) => {
    let startAddress: any = item.departureParkingLotAddress;
    let endAddress: any = item.arrivalParkingLotAddress;
    if (item.activationDate) {
      startAddress = item.activationAddress || item.departureParkingLotAddress;
    }
    if (item.deactivationDate) {
      endAddress = item.deactivationAddress || item.arrivalParkingLotAddress;
    }
    return (
      <>
        <div className="address">{startAddress}</div>
        <div className="address-1">{endAddress}</div>
      </>
    );
  };
  const tabelTag: any = (
    <table className="res-table">
      <tbody>
        <tr
          onClick={(e) =>
            onRowClick(
              item.equipmentListingId,
              item.listingType,
              item.reservationCode,
              item.reservationId,
              item
            )
          }
        >
          <td
            className="reservation-title"
            style={{ paddingLeft: "15px", height: "110px" }}
          >
            <div>{`${
              item.reservationCode ? "Reservation" : item.listingType
            }`}</div>
            {item.isForPrivateCarriers ? (
              <div style={{ color: "#0a58ca", fontSize: "10px" }}>
                (Private Network)
              </div>
            ) : (
              ""
            )}
          </td>
          <td className="r-date">{showRelaventDate(item)}</td>
          <td colSpan={2} style={{ borderRight: "1px solid #ccc" }}></td>
          <td>
            <div style={{ marginLeft: "20px" }}>
              <i className="fa fa-map-marker"></i>
              <br />
              <i className="fa fa-map-marker"></i>
            </div>
          </td>
          <td>
            {/* <div className="address">
            {item.departureParkingLotAddress}
            </div>
            <div className="address-1">
            {item.arrivalParkingLotAddress}
            </div> */}
            {showAddress(item)}
          </td>
          <td style={{ width: "250px", textAlign: "center" }}>
            {item.listingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE && (
              <span className="text-primary d-block text-right  px-3 my-2">
                <i className="fa fa-usd"></i> {item.defaultCurrency}{" "}
                {item.movePrice}
              </span>
            )}
            <div className="d-flex justify-content-between px-2">
              <div className="">
                {item.reservationCode && (
                  <button
                    type="submit"
                    style={{ height: "30px" }}
                    className="btn-primary waves-effect waves-light  mb-2 btn-sm"
                  >
                    Booked - {item.reservationCode}
                  </button>
                )}
              </div>
              <div className="">
                {item.listingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE && (
                  <button
                    type="button"
                    style={{ height: "30px" }}
                    className="btn-warning waves-effect waves-light mb-2  btn-sm"
                  >
                    {" "}
                    {item.loadType}{" "}
                  </button>
                )}
              </div>
            </div>

            {item.listingType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE ? (
              <div>
                {item.reservationStatus ? (
                  <button
                    type="submit"
                    className="btn-primary waves-effect waves-light  mb-1 btn-sm"
                  >
                    {item.reservationStatus}
                  </button>
                ) : (
                  ""
                )}
                {!item.reservationCode ? (
                  <span className="d-block text-center">
                    <i className="fa fa-calendar"></i> {item.defaultCurrency}{" "}
                    {
                      // `${rateObj.type === Constants.MONTH ? rateObj.NoofMonths:rateObj.type === Constants.WEEK ? rateObj.NoofWeeks:rateObj.Noofdays} / ${rateObj.type} `
                      `${rateObj.rate} / ${rateObj.type} `
                    }
                    {/* {item.dailyRate} /d {JSON.stringify(getSelectedRangeRate(CommonUtil.getDays(item.startDate,item.endDate),item.dailyRate,item.weeklyRate,item.monthlyRate))} &nbsp; &nbsp; */}
                    <i className="fa fa fa-map-signs"></i> {item.distanceRate} /{" "}
                    {CommonUtil.getMesurementByCountry()}
                  </span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="avl-date ">
      {startAvailablity ? (
        <>
          <div className="table-heading">
            {item.FullMonth} {item.FullYear}
          </div>
          {tabelTag}
        </>
      ) : (
        <div className="res-date reserve">
          <div className="table-heading">
            {item.FullMonth} {item.FullYear}
          </div>
          {tabelTag}
        </div>
      )}
    </div>
  );
};
