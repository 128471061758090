import React from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";

const ExitVisualInspection = () => {
  const [visualDetails, setVisualDetails] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);
  const [damageDes, setDamageDes] = useState("");
  const [damageMap, setDamageMap] = useState<any>([]);

  const [fullDamageView, setFullDamageView] = useState<any>([]);
  const [entryDamage, setEntryDamage] = useState("");
  const [showMap, setShowMap] = useState(false);

  let getVisualInspectionDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetVisualInspectionDetails,
      onLoginError
    );
  };

  useEffect(() => {
    getVisualInspectionDetails();
  }, []);

  let onGetVisualInspectionDetails = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);
      setDamageDes(
        restData?.visualInspection?.attachments?.attachmentDamageDescription
      );
      if (restData.visualInspection.taskDetailsAttachment) {
        var visual = [];
        if (Array.isArray(restData.visualInspection.taskDetailsAttachment)) {
          visual = restData.visualInspection.taskDetailsAttachment;
        } else {
          visual.push(restData.visualInspection.taskDetailsAttachment);
        }
        setVisualDetails(visual);
        setAttachments(restData?.visualInspection?.attachments);
        let top = restData.visualInspection.damageMap.filter((view: any) => {
          return view.position === "fullDamageView";
        });

        setDamageMap(top);
        console.log(top);
      }
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const handleData = (name: any, damageMap: any, attached: any, des: any) => {
    setShowMap(true);
    setEntryDamage(name);
    setFullDamageView(damageMap);
    setDamageMap(attached);
    setDamageDes(des);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  let attachImages: any = [];
  try {
    attachImages = attachments.attachmentUploads.map(
      (ele: any) => ele.fullFilePath
    );
    console.log(attachImages);
  } catch (e) {
    attachImages = [];
  }

  return (
    <div className="containData">
      <div className="row">
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
          <div className="inner-table-data table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">INSPECTION ITEM</th>
                  <th scope="col">PASS</th>
                  <th scope="col">FAIL</th>
                  <th scope="col">NOTE</th>
                </tr>
              </thead>
              <tbody>
                {visualDetails.map((data: any) => {
                  return (
                    <>
                      <tr>
                        <td>{Capitalize(data.name)}</td>
                        <td
                          className={`${
                            String(data.status).toUpperCase() === Constants.FAIL
                              ? "text-danger text-uppercase table-danger"
                              : "text-success text-uppercase"
                          }`}
                        >
                          {String(data.status).toUpperCase() === Constants.FAIL
                            ? "NO"
                            : "YES"}
                        </td>
                        <td
                          className={`${
                            String(data.status).toUpperCase() === Constants.OK
                              ? "text-success text-uppercase"
                              : "text-danger text-uppercase table-danger"
                          }`}
                        >
                          {String(data.status).toUpperCase() ===
                          Constants.OK ? (
                            "NO"
                          ) : (
                            <>
                              <div
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                                onClick={() => {
                                  handleData(
                                    Capitalize(data.name),
                                    data?.damageMap?.map(
                                      (img: any) => img.fullFilePath
                                    ),
                                    data?.attachments?.attachmentUploads?.map(
                                      (img: any) => img.fullFilePath
                                    ),
                                    data?.attachments
                                      ?.attachmentDamageDescription
                                  );
                                }}
                              >
                                Yes
                              </div>
                            </>
                          )}
                        </td>
                        <td>{data.note !== "" ? data.note : ""}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {showMap ? (
          <>
            {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 text-center">
              <h2 className="title-head my-3">{entryDamage} Damage View</h2>
              {fullDamageView.length > 0 && (
                <InnerImage path={fullDamageView} />
              )}
            </div> */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="mx-auto my-auto justify-content-center">
                <div className="gallery-carousel">
                  <h2 className="title-head my-3">
                    {entryDamage} Damage Photos
                  </h2>
                  <CarouselComponent
                    images={damageMap}
                    callBack={(idx: any) => {
                      setGalleryPop(true);
                      setGalleryPopStartIndex(idx);
                    }}
                  />
                  <ReactImageGallery
                    startIndex={galleryPopStartIndex}
                    attachedImages={damageMap}
                    showPop={galleryPop}
                    handleClose={() => {
                      setGalleryPop(false);
                    }}
                  />
                </div>
              </div>

              <div className="gallery-form form">
                <h2 className="title-head my-4 mb-2">
                  {entryDamage} Damage Description
                </h2>
                <div className="form-floating">
                  <div
                    className="card p-3"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ minHeight: "100px", height: "auto" }}
                  >
                    {damageDes}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ExitVisualInspection;
