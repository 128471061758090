export const initState = {
  eventId: null,
  eventCode:null,
  position: "",
  fileName: "",
  comments: "",
  signature: [],
  signType:null,
  isOpen: false,
  loadStatus: false,
  isCompleted: false,
  visualInspection: {
    task: [
      {
        taskId: 1,
        taskName: "Trailer Dot & Registration",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 4,
        taskName: "Trailer Spare",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 5,
        taskName: "Trailer Slider Box",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 6,
        taskName: "Trailer Doors And Hinges",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 7,
        taskName: "Trailer Door Seal Or Gaskets",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 11,
        taskName: "Trailer Hinges",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 12,
        taskName: "Glad Hand Rubber Seals",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 14,
        taskName: "Air LINES",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 15,
        taskName: "Pintle Hook",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 16,
        taskName: "Dolly Crank",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 17,
        taskName: "Mud Flaps",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 18,
        taskName: "Brake Chambers",
        taskStatus: "",
        taskNote: null,
        countRequired: false,
        selectionRequired: false,
        count: null,
        selectionOption: null,
        selectionOptions: null,
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
    ],
    longitude: "",
    latitude: "",
    endTime: "2024-03-19 09:27:58.499422Z",
    startTime: "2024-03-19 09:27:50.947636Z",
    isCompleted: false,
  },
  lightInspection: {
    task: [
      {
        taskId: 20,
        taskName: "License Plate Light",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 21,
        taskName: "Clearance lights(LED/Regular)",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 22,
        taskName: "Front Clearance",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 23,
        taskName: "Front Markers",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 24,
        taskName: "Rear Clearance",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 25,
        taskName: "Rear Markers",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 27,
        taskName: "Side Markers or Turn",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
      {
        taskId: 110,
        taskName: "Stop Clearance",
        taskStatus: "",
        taskNote: null,
        countRequired: true,
        selectionRequired: true,
        count: null,
        selectionOption: "",
        selectionOptions: ["LED", "REGULAR"],
        damageMap: [
          {
            fullFilePath: null,
            position: null,
            status: false,
          },
        ],
        attachments: {
          attachmentUploads: [],
          attachmentDamageDescription: null,
        },
      },
    ],
    longitude: "",
    latitude: "",
    endTime: "2024-03-19 09:28:32.855052Z",
    startTime: "2024-03-19 09:28:02.398604Z",
    isCompleted: false,
  },
  tyresInspection: {
    trailer: [],
    truck: [],
    startTime: "2024-03-19 09:28:35.685303Z",
    endTime: null,
    latitude: null,
    longitude: null,
    isCompleted: false,
  },
  picturesInspection: {
    startTime: "2024-03-19 09:26:40.473574Z",
    endTime: "2024-03-19 09:27:47.364152Z",
    positionOfTrailer: [],
    latitude: "",
    longitude: "",
    isCompleted: false,
  },
  ctpatInspection: {},
  reeferInspection: {
    task: [],
    isCompleted: false,
  },
};
