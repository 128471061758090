import React from 'react'
import { Link } from 'react-router-dom';
import { CommonUtil } from '../../utils/CommonUtil';

const InspectionRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickUserDetails(props.data);
        }
        CommonUtil.setDocumentCode(props.data.documentCode)
    }
  return (
    <div>
        <div className={"inspectionRenderHeader"}>
            <a><span onClick={onClickUser} className={"cellRender"} style={{fontSize:'13px'}}>{props.data.documentCode}</span></a>
        </div>
    </div>
  )
}

export default InspectionRenderer