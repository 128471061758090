import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Constants } from "../Languages/Constants";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import NewEntryLightsInsp from "./NewEntries/NewEntryLightsInsp";
import NewEntryTiresInsp from "./NewEntries/NewEntryTiresInsp";
import NewEntryPicturesInsp from "./NewEntries/NewEntryPicturesInsp";
import NewEntryCTPATInsp from "./NewEntries/NewEntryCTPATInsp";
import NewEntryComments from "./NewEntries/NewEntryComments";
import NewEntryVisualInspection from "./NewEntries/NewEntryVisualInspection";
import { useReleaseInspectorContext } from "../../Components/ReleaseContext";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import NewEntryReeferInsp from "./NewEntries/NewEntryReeferInsp";

const AddNewReleaseModal = ({ show, onCloseModal, onSubmitDocument }: any) => {
  const [tabIndex, setTabIndex] = useState(5);
  const { state, dispatch } = useReleaseInspectorContext();
  var qparam = CommonUtil.getInterchangeDocumentId();
  const onLoginError = (error: any) => {
    ToastManager.showToast(error, "Error");
  };

  //useEffect(() => {}, [state, state?.reeferInspection?.isCompleted]);

  const onMasterTasks = (dataObj: any) => {
    dispatch({ type: "isOpen", payload: true });
    if (dataObj) {
      let restData: any = CommonUtil.parseData(dataObj);
      if (restData) {
        dispatch({ type: "UPDATEMASTERDATA", payload: restData });
      }
    }
  };

  const getMasterData = () => {
    NetworkManager.sendJsonRequest(
      `api/webInspectionDetails/getLastReleasePayload?docId=${qparam}`,
      "",
      "",
      "GET",
      true,
      onMasterTasks,
      onLoginError
    );
  };

  useEffect(() => {
    if (!state?.isOpen) {
      //getMasterData();
    }
    if (show) {
      setTabIndex(5);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      // dialogAs={DraggableComponent}
      centered
      size="xl"
    >
      <div className="modal-content">
        <Modal.Header className="modal-header" closeButton={false}>
          <h5 className="modal-title mt-0">
            {CommonUtil.getMessageText(
              Constants.RELEASE_INSPECTION,
              "New Release Inspection"
            )}
          </h5>
          <button
            type="button"
            className="close"
            style={{ marginLeft: "0px" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={onCloseModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <div className="modal-body">
          <div className="container">
            <div className="col-lg-12 new-release-body new-release-tabs mt-2">
              <div className="row">
                <div
                  className="col-lg-3 col-md-4 new-release-tabs"
                  style={{
                    borderRight: "1px solid #ccc",
                    height: "440px",
                    minHeight: "440px",
                  }}
                >
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item "
                      role="presentation"
                      onClick={(e: any) => setTabIndex(5)}
                    >
                      <button
                        className="btn btn-lg text-center d-flex align-item-center active "
                        id="ctpat-tab"
                        data-bs-toggle="tab"
                        aria-selected="false"
                      >
                        {state?.ctpatInspection?.isCompleted && (
                          <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                        )}{" "}
                        &nbsp;{" "}
                        <span className="d-flex justify-content-center w-100">
                          CTPAT Confirmation
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e: any) => setTabIndex(4)}
                    >
                      <button
                        className="btn btn-lg text-center d-flex align-item-center"
                        id="pictures-tab"
                        data-bs-toggle="tab"
                        aria-controls="pictures-tab-pane"
                        aria-selected="false"
                      >
                        {state?.picturesInspection?.isCompleted && (
                          <i className="fa-solid fa-circle-check align-left mt-1"></i>
                        )}{" "}
                        &nbsp;{" "}
                        <span className="d-flex justify-content-center w-100">
                          Pictures
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e: any) => setTabIndex(1)}
                    >
                      <button
                        className="btn btn-lg text-center d-flex align-item-center"
                        id="visuval-tab"
                        data-bs-toggle="tab"
                        aria-controls="visuval-tab-pane"
                        aria-selected="true"
                      >
                        {state?.visualInspection?.isCompleted && (
                          <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                        )}
                        &nbsp;{" "}
                        <span className="d-flex justify-content-center w-100">
                          Visual Inspection
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e: any) => setTabIndex(2)}
                    >
                      <button
                        className="btn btn-lg text-center  d-flex align-item-center"
                        id="lights-tab"
                        data-bs-toggle="tab"
                        aria-controls="lights-tab-pane"
                        aria-selected="false"
                      >
                        {state?.lightInspection?.isCompleted && (
                          <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                        )}{" "}
                        &nbsp;{" "}
                        <span className="d-flex justify-content-center w-100">
                          Lights
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e: any) => setTabIndex(3)}
                    >
                      <button
                        className="btn btn-lg text-center d-flex align-item-center"
                        id="tires-tab"
                        data-bs-toggle="tab"
                        aria-selected="false"
                      >
                        {state?.tyresInspection?.isCompleted && (
                          <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                        )}
                        <span className="d-flex justify-content-center w-100">
                          Tires
                        </span>
                      </button>
                    </li>

                    {state?.reeferInspectionRquired && (
                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={(e: any) => setTabIndex(7)}
                      >
                        <button
                          className="btn btn-lg text-center d-flex align-item-center"
                          id="ctpat-tab"
                          data-bs-toggle="tab"
                          aria-selected="false"
                        >
                          {state?.reeferInspection?.isCompleted && (
                            <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                          )}{" "}
                          &nbsp;{" "}
                          <span className="d-flex justify-content-center w-100">
                            Reefer Inspection
                          </span>
                        </button>
                      </li>
                    )}
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={(e: any) => setTabIndex(6)}
                    >
                      <button
                        className="btn btn-lg text-center d-flex align-item-center"
                        id="comments-tab"
                        data-bs-toggle="tab"
                        aria-selected="false"
                      >
                        {state?.comments && state?.signature?.length > 0 && (
                          <i className="fa-solid fa-circle-check align-self-start mt-1"></i>
                        )}{" "}
                        &nbsp;{" "}
                        <span className="d-flex justify-content-center w-100">
                          Comments
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-9 col-md-8"
                  style={{ overflowX: "auto" }}
                >
                  <div
                    className="tab-content mt-3"
                    style={{ paddingRight: "12px" }}
                  >
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 1 ? "show active" : ""
                      }`}
                      id="visuval-tab-pane"
                      role="tabpanel"
                      aria-labelledby="visuval-tab"
                    >
                      {tabIndex === 1 ? <NewEntryVisualInspection /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 2 ? "show active" : ""
                      }`}
                      id="lights-tab-pane"
                      role="tabpanel"
                      aria-labelledby="lights-tab"
                    >
                      {tabIndex === 2 ? <NewEntryLightsInsp /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 3 ? "show active" : ""
                      }`}
                      id="tires-tab-pane"
                      role="tabpanel"
                      aria-labelledby="tires-tab"
                    >
                      {tabIndex === 3 ? <NewEntryTiresInsp /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 4 ? "show active" : ""
                      }`}
                      id="pictures-tab-pane"
                      role="tabpanel"
                      aria-labelledby="pictures-tab"
                    >
                      {tabIndex === 4 ? <NewEntryPicturesInsp /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 5 ? "show active" : ""
                      }`}
                      id="cargo-tab-pane"
                      role="tabpanel"
                      aria-labelledby="cargo-tab"
                    >
                      {tabIndex === 5 ? <NewEntryCTPATInsp /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 6 ? "show active" : ""
                      }`}
                      id="reefer-tab-pane"
                      role="tabpanel"
                      aria-labelledby="reefer-tab"
                    >
                      {tabIndex === 6 ? <NewEntryComments /> : ""}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        tabIndex === 7 ? "show active" : ""
                      }`}
                      id="cargo-tab-pane"
                      role="tabpanel"
                      aria-labelledby="cargo-tab"
                    >
                      {tabIndex === 7 ? <NewEntryReeferInsp /> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-dark"
            data-dismiss="modal"
            onClick={onCloseModal}
          >
            {CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
          </button>
          {state?.visualInspection?.isCompleted &&
            state?.tyresInspection?.isCompleted &&
            state?.picturesInspection?.isCompleted &&
            state?.lightInspection?.isCompleted &&
            state?.ctpatInspection?.isCompleted &&
            (state?.reeferInspectionRquired
              ? state?.reeferInspection?.isCompleted
              : true) &&
            state?.comments &&
            state?.signature?.length <= 2 && (
              <button
                type="button"
                className="btn btn-success"
                onClick={(e) => {
                  onSubmitDocument();
                }}
              >
                {CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}
              </button>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default AddNewReleaseModal;
