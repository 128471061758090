import React from 'react'

const InterchangeEditRenderer = (props: any) => {
    const onClickEditDocument = () => {
        if (props.context) {
            props.context.onClickEditDocument(props.data);
        }
    }
    const onClickDeleteDocument = ()=>{
        if (props.context) {
            props.context.deleteDocument(props.data);
        }
    }
    return (
        <div className={"interchangeRender"}>
            <i className="fa-sharp fa-solid fa-pen" onClick={onClickEditDocument}></i> &nbsp;&nbsp;&nbsp;
            <i className="fa fa-trash" onClick={onClickDeleteDocument}></i>
        </div>

    )
  return (
    <div>InterchangeEditRenderer</div>
  )
}

export default InterchangeEditRenderer