import React from "react";

const ParkingRender = (props: any) => {
    const onClickParking = () => {
        if (props.context) {
            props.context.onClickEditParking(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickParking} className={"cellRender"}>{props.value}</span></a>
        </div>

    )
}

export default ParkingRender;