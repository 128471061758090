import React from 'react'
import CarouselComponent from '../CarouselComponent'
import ImageLoader from '../ImageLoader'
import '../Interchange.css'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'

const ExitPicturesInspection = () => {

    const [pictureDetails,setPictureDetails] = useState<any>([])
    const [attachments,setAttachments] = useState<any>([])
 
    let getPicturesInspectionDetails = () =>{

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetPicturesInspectionDetails,onLoginError);
      
      }

      useEffect(()=>{
        getPicturesInspectionDetails();
      },[])

      let onGetPicturesInspectionDetails = (dataObj:any)=>{
        if(dataObj){
            var visual = [];
            var restData = JSON.parse(dataObj);
            console.log(restData?.picturesInspection)
            if(restData?.picturesInspection?.positionOfTrailer){
                visual = [];
            if(Array.isArray(restData?.picturesInspection?.positionOfTrailer)){
                visual = restData?.picturesInspection?.positionOfTrailer;
            }else{
                visual.push(restData?.picturesInspection?.positionOfTrailer); 
            }
            setPictureDetails(visual)
            setAttachments(restData?.picturesInspection?.positionOfTrailer);
        }
        }
      }

    let onLoginError = (err:any)=>{
        console.log(err)
  }

    let attachImages:any = [];
        try{
            attachImages = attachments?.attachmentUploads?.map((ele:any)=> ele.fullFilePath)
            console.log(attachImages)
        }catch(e){
            attachImages = [];
        }

  return (
    <div>
    <div className="row">
                            <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                <div className="tire-details">
                                    <div className="picture-tab">
                                       
                                            <div className="row">
                                              
                                            {pictureDetails.map((data:any)=>{
                                                       return(
                                                        <>
                                                        <PictureComponent data={data}/>
                                                        </>
                                                       ) 
                                                    })}


                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-6 col-sm-12 text-center">
                            </div>
                        </div>
</div>
  )
}

export default ExitPicturesInspection

const PictureComponent = ({data}:any)=>{

    const [pictureImages,setPictureImages] = useState([])
    const [galleryPop,setGalleryPop] = useState(false);
    const [galleryPopStartIndex,setGalleryPopStartIndex] = useState(0); 
    
    useEffect(()=>{
    
        let arr:any = []
    
        for(let i=0;i<data?.images?.length;i++){
            arr.push(data?.images[i]?.fullFilePath)
        }
        setPictureImages(arr)
    
        
    
    },[data])
    const findImg = (index:any)=>{
        console.log(index);
        setGalleryPop(true)
        console.log(pictureImages)
        // callBack(index);
      }
    
    
                                                        return(
                                                            <>
                                                            <ReactImageGallery startIndex={galleryPopStartIndex} attachedImages={pictureImages} showPop={galleryPop} handleClose={()=>{setGalleryPop(false)}} />
                                                            <div className="col-lg-3 col-md-4 mb-3">
                                                                <a href="#" className="link">
                                                                    <div className="card shadow-sm rounded" style={{minHeight:"200px"}}>
                                                                        <div className="card-body p-1 text-center">
                                                                        <ImageLoader path={data?.images ? data?.images[0]?.fullFilePath : ''} findImg={findImg} index={1}/>
                                                                            <div className="py-1 pick-area">{data?.position}</div>
                                                                            <div className="picture">
                                                                                <span className="picture-pop-label">{data?.images?.length}</span>
                                                                            </div>
                                                                            </div>
                                                                            <div className='mx-2'>
                                                                                <p style={{fontWeight:'600'}}>Damage Type : {(data?.type === 'C'?"Cut":"")||(data?.type === 'H'?"Hole":"")||(data?.type === 'B'?"Bruise":"")}</p>
                                                                             <p>{data?.description}</p>
                                                                           </div>
                                                                        </div>
                                                                     
                                                                </a>
                                                            </div></>
                                                           ) 
                                                       
                                                      
    
    
    
    }