import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useStateWithCallback from 'use-state-with-callback'
import AGGrid from '../../Components/AGGrid'
import DraggableComponent from '../../Components/DraggableComponent'
import { IAGrid } from '../../Components/IInterfaces'
import { NetworkManager } from '../../Components/NetworkManager'
import SingleSelectComp from '../../Components/SingleSelectComp'
import { CommonUtil } from '../../utils/CommonUtil'

import $ from "jquery"
import Flatpickr from "react-flatpickr"
import MGrid from '../../Components/MGrid'
import DetectDevice from '../../utils/DetectDevice'
import { Events } from "../Languages/Events"
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer"
import EquipCellRenderer from "../Organization/EquipCellRenderer"
import {Constants} from "../Languages/Constants";
import {loadMapApi} from "../../utils/GoogleMapsUtils";
import EquipAvailTypeDetailsRenderer from "../Organization/EquipAvailTypeDetailsRenderer";
import ToastManager from "../../Components/ToastManager";
import BusyLoader from "../../Components/BusyLoader";
import Confirm from "../../Components/confirm";

let defObj = {orgId:"",orgName:"All"};

var reservationId = "";
var insurPolicies:any = [];
var physicalDmgPolicies = [];
var liabilityPolicies = [];
var bankAccounts:any = [];
var bank1Currency = "";
var bank2Currency = "";
var isTaxExcemptionAvail:boolean = false;

let equipListingStatus:string = "";
let saasOrg:string = "";
let avlStDate:any = null;
let avlEndDate:any = null;

let parkingLotArray:any = [];
var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};

let isLiabilityInsuranceEnabled = false;
let isPhysicalInsuranceEmabled = false;

const AvailabilityReport = () => {
    const [dateOptions,setDateOptions] = useState(dateformat);
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {
    })

    const [showAvailPopup, setShowAvailPopup] = useState(false);
    const [showAvailabilityPopup, setShowAvailabilityPopup] = useState(false);


    const gridRef = useRef<IAGrid>(null);

    const chkAllowRef = useRef<HTMLInputElement>(null);
    const chkPickRef = useRef<HTMLInputElement>(null);
    //const [noOfDays,setNoOfDays] = useState("");

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);

    const [defDailyRate,setDefDailyRate] = useState("");
    const [defDistanceRate,setDefDistanceRate] = useState("");
    const [defEngineRate,setDefEngineRate] = useState("");
    const [equipmentType,setEquipmentType] = useState("");

    const [isAnyAvailable, setIsAnyAvailable] = useState(false);
    const [isCurrecyDisable,setCurrencyDisable] = useState(false);
    const [currencyClass, setCurrencyClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [currencyObj,setCurrencyObj] = useState<any>(null);
    const [selPolicyObj,setSelPolicyObj] = useState<any>(null);
    const [parkingLots,setParkingLots] = useState<any>([]);

    const [isDisabled,setDisabled] = useState(false);
    const [isRepDisabled,setRepDisabled] = useState(false);
    const qtyRef = useRef<HTMLInputElement>(null);
    const noOfDayRef = useRef<HTMLInputElement>(null);



    const navigate = useNavigate()

    const departureRender = (propData: any) => {
        return (
            <div>
                <p><span >{`${propData.data.departureCity},${propData.data.departureState}`}</span></p>
            </div>
        )
    }

    const arrivalRender = (propData: any) => {
        return (
            <div>
                <p><span>{`${propData.data.arrivalCity},${propData.data.arrivalState}`}</span></p>
            </div>
        )
    }

    const [eqpColumnDefs] = useState([
        { field: 'listingType', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TYPE,"Availability Type"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'departureParkingLotAddress', headerName: CommonUtil.getMessageText(Constants.DEPARTURE_ADDR,"Departure Address"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: departureRender },
        { field: 'arrivalParkingLotAddress', headerName: CommonUtil.getMessageText(Constants.ARRIVAL_ADDRESS,"Arrival Address"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: arrivalRender },
        { field: 'startDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_FROM_DATE,"Availability From Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'endDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TO_DATE,"Availability To Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'price', headerName: "Price", resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'quantity', headerName: "Equipment Count", resizable: true, width: 190, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'UnitEquipName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"), resizable: true, width: 250, sortable: true, cellRenderer: EquipCellRenderer, filter: 'agTextColumnFilter' },
        { field: 'damagesCount', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_DETAILS,"Availability Details"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: EquipAvailTypeDetailsRenderer },
        { field: 'model', headerName: CommonUtil.getMessageText(Constants.MODEL,"Model"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'isGeneric', headerName: CommonUtil.getMessageText(Constants.IS_GENERIC,"Is Generic"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'listingStatus', headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'orgName', headerName: CommonUtil.getMessageText(Constants.CONTACT_NAME,"Company Name"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'policyNameLiability', headerName: CommonUtil.getMessageText(Constants.LIABILITY_PLAN,"Liability Plan"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'insurancePerDayAmountLiability', headerName: CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN_PER_DAY,"Liability Insurance Per Day"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'policyNamePhysical', headerName: CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN,"Physical Damage Plan"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'insurancePerDayAmountPhysical', headerName: CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN_PER_DAY,"Damage Insurance Per Day"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },

        { field: 'excessDaysRate', headerName: CommonUtil.getMessageText(Constants.EXCESS_DAYS_RATE,"Excess Days Rate"), resizable: true, width: 150, sortable: true,  filter: 'agNumberColumnFilter' },
        { field: 'defaultCurrency', headerName: CommonUtil.getMessageText(Constants.CURRENCY,"Default Curreny"), resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter' },
        
        { field: 'assetType', headerName: CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type"), resizable: true, width: 150, sortable: true, hide: true, filter: 'agTextColumnFilter' },
        { field: 'brand', headerName: CommonUtil.getMessageText(Constants.BRAND,"Brand"), resizable: true, width: 150, sortable: true, hide: true, filter: 'agNumberColumnFilter' },
    ])

    const [selCompanyObj, setSelCompanyObj] = useState<any>(defObj)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null)

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [stDate, setStDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [addtionalPrice, setAdditionalPrice] = useState("");
    const [dailyRate, setDailyRate] = useState("");
    const [distanceRate, setDistanceRate] = useState("");
    const [movePrice, setMovePrice] = useState("");
    const [noOfDays, setNoOfDays] = useState("");
    const [eDisRate, setEDisRate] = useState("");
    const [priceKm, setPriceKm] = useState("");
    const [quantity, setQuantity] = useState("");

    const [isGenericReposition, setGenericReposition] = useState(false);
    const [isGenericRental, setGenericRental] = useState(false);
    const [isSpecificReposition, setSpecificReposition] = useState(false);
    const [isSpecificRental, setSpecificRental] = useState(false);

    const [selDepartureObj, setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj, setSelArrivalObj] = useState<any>(null);

    const [departure, setDeparture] = useState("");
    const [arrival, setArrival] = useState("");
    const [currency, setCurrency] = useState("");
    const [loadType, setLoadType] = useState("");

    const [insurPlans, setInsurPlans] = useState<any>([]);

    const [isInuranceChecked, setInsuranceChecked] = useState(false);
    const [isLiabilityChecked,setLiabilityChecked] = useState(false);
    const [isPhysicalDamageChecked,setPhysicalDamageChecked] = useState(false);

    const [liabilityPaidByOwner,setLiabilityPaidByOwner] = useState<any>(false);
    const [liabilityPaidByRenter,setLiabilityPaidByRenter] = useState<any>(false);
    const [physicalDamagePaidByOwner,setPhysicalDamagePaidByOwner] = useState<any>(false);
    const [physicalDamagePaidByRenter,setPhysicalDamagePaidByRenter] = useState<any>(false);

    const [isLiabilityPaidByOwner,setIsLiabilityPaidByOwner] = useState<any>(false);
    const [isLiabilityPaidByRenter,setIsLiabilityPaidByRenter] = useState<any>(false);
    const [isPhysicalDamagePaidByOwner,setIsPhysicalDamagePaidByOwner] = useState<any>(false);
    const [isPhysicalDamagePaidByRenter,setIsPhysicalDamagePaidByRenter] = useState<any>(false);

    const [isInuranceOwnerChecked, setInsuranceOwnerChecked] = useState(false);
    const [isInuranceRenterChecked, setInsuranceRenterChecked] = useState(false);
    const [physicalDamagePolicies,setPhysicalDamagePolicies] = useState([]);
    const [liabilityPolicies,setLiabilityPolicies] = useState([]);

    const [startDateClass,setStartDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    const [endDateClass,setEndDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    const [loadTypes,setLoadTypes] = useState(CommonUtil.LOAD_TYPE);
    const [loadTypeObj,setLoadTypeObj] = useState<any>(null);
    const [loadTypeClass, setLoadTypeClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [departureAddrClass, setDepartureAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [arrivalAddrClass, setArrivalAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [selLiability,setSelLiability] = useState<any>(null);

    const [liabilityClass, setLiabilityClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [physicalDamageClass, setPhysicalDamageClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [isTermsDisable,setTermsDisable] = useState(false);
    const chkTerms = useRef<HTMLInputElement>(null);
    const rdPDmgPaidByOwner = useRef<HTMLInputElement>(null);
    const rdPDmgPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByOwner = useRef<HTMLInputElement>(null);
    const [currencies,setCurrencies] = useState(CommonUtil.DEF_CURRENCY);
    const [selPhysicalDamage,setSelPhysicalDamage] = useState<any>(null);
    const [engineRate,setEngineRate] = useState("");
    const movePriceRef = useRef<HTMLInputElement>(null);
   // const [movePrice,setMovePrice] = useState("");
    const eDisRef = useRef<HTMLInputElement>(null);
    const priceRef = useRef<HTMLInputElement>(null);
    const priceKmRef = useRef<HTMLInputElement>(null);
    const dailyRef = useRef<HTMLInputElement>(null);
    const distanceRef = useRef<HTMLInputElement>(null);
    const engineRef = useRef<HTMLInputElement>(null);
    const [perdayRate,setRatePerDay] = useState("");

    useEffect(() => {
        CommonUtil.SCREEN_NAME = CommonUtil.AVAILABILITIES;
        CommonUtil.dispatchEVents();
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {

                data.unshift(defObj);
                setOrgData(data)
            })
        } else {
            // getAvailabilityList();
        }

        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data
            setAssetTypes(data)
        })

        equipListingStatus = "";
        reservationId = "";
        bankAccounts = [];
        bank1Currency = "";
        bank2Currency = "";
        isTaxExcemptionAvail = false;
        isLiabilityInsuranceEnabled = false;
         isPhysicalInsuranceEmabled = false;
        saasOrg = CommonUtil.isRootUser()?"":CommonUtil.getSaasOrgId();
        loadDirectionService();
        getAvailabilityList();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }
        
    }, [])

    let onSwitchCustomer = () => {
       /* if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        setEquipData([]);
        //setCompanyVisible(CommonUtil.isRootUser());
        if (CommonUtil.isRootUser()) {
            setSelCompanyObj(null);
        } else {
            getAvailabilityList();
        }*/
        Events.dispatchBreadCrumbEvent();
    }

    let loadDirectionService = () => {
        if (window.google) {
            //setScriptLoaded(true);
            // getGoogleDirection();
            loadGoogleServices();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    // setScriptLoaded(true);
                    // getGoogleDirection();
                    loadGoogleServices();
                })
            }
        }
    }
    let loadGoogleServices = ()=>{
        if(!CommonUtil.isRootUser()){
            getDefaultPrices();
            getPartkingLots();
        }

    }

    let getOrgInsurancePlanList = ()=>{
        let equipAllAPI: string = 'api/insurance/orgPolicies?orgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,onGetOrgInsurancePolicy,onLoginError);
    }
    let onGetOrgInsurancePolicy = (dataObj:any)=>{
        insurPolicies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsuranceChecked((restData.isInsuranceEnabled == "Y"?true:false));
                setInsuranceOwnerChecked((restData.isInsurancePaidByOwner == "Y"?true:false));
                setInsuranceRenterChecked((restData.isInsurancePaidByRenter == "Y"?true:false));
                setLiabilityChecked((restData.isLiabilityEnabled == "Y"?true:false));
                setPhysicalDamageChecked((restData.isPhysicalDamageEnabled == "Y"?true:false));


                if(restData.organizationInsuranceAssociation){
                    let arr = [];
                    if(Array.isArray(restData.organizationInsuranceAssociation)){
                        arr = restData.organizationInsuranceAssociation;
                    }else{
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item:any,idx:number)=>{
                        insurPolicies.push(item);
                    })
                }
                if(restData.isInsuranceEnabled == "Y"){
                    if(restData.isLiabilityEnabled == "Y"){
                        if(restData.isInsurancePaidByOwner == "Y"){
                            setLiabilityPaidByOwner(true);
                            setLiabilityPaidByRenter(false);

                            setIsLiabilityPaidByOwner(true);
                        }if(restData.isInsurancePaidByRenter == "Y"){
                            setIsLiabilityPaidByRenter(true);

                            if(restData.isInsurancePaidByOwner == "N"){
                                setLiabilityPaidByOwner(false);
                                setLiabilityPaidByRenter(true);
                            }

                        }
                    }
                    if(restData.isPhysicalDamageEnabled == "Y"){
                        if(restData.isInsurancePaidByOwner == "Y"){
                            setPhysicalDamagePaidByOwner(true);
                            setPhysicalDamagePaidByRenter(false);
                            setIsPhysicalDamagePaidByOwner(true);
                        }if(restData.isInsurancePaidByRenter == "Y"){
                            setIsPhysicalDamagePaidByRenter(true);

                            if(restData.isInsurancePaidByOwner == "N"){
                                setPhysicalDamagePaidByOwner(false);
                                setPhysicalDamagePaidByRenter(true);
                            }

                        }
                    }
                }

            }
        }

        setInsurPlans(insurPolicies);

        if(restData.isInsuranceEnabled == "Y"){
            if(restData.isLiabilityEnabled == "Y"){
                isLiabilityInsuranceEnabled = true;
                if(CommonUtil.isRootUser()){
                    getLiabilityPlans();
                }

            }
            if(restData.isPhysicalDamageEnabled == "Y"){
                isPhysicalInsuranceEmabled = true;
                if(CommonUtil.isRootUser()) {
                    getPhysicalDamagePlans();
                }
            }
        }
        getOrgBanks();
    }

    let getLiabilityPlans = ()=>{
        let liabilityAPI: string = 'api/insurance/getRatePlanByLiability?equipmentId='+CommonUtil.SELECT_EQUIP_ID+'&saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(liabilityAPI, '', '', 'GET', true,onGetOrgLiabilityPlans,onLoginError);
    }

    let onGetOrgLiabilityPlans = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let liabilityPlans:any = [];
                if(Array.isArray(restData.ratePlan)){
                    liabilityPlans = restData.ratePlan;
                }else{
                    liabilityPlans.push(restData.ratePlan);
                }

                setLiabilityPolicies(liabilityPlans);
            }
        }
    }

    let getPhysicalDamagePlans = ()=>{
        let physicalDamageAPI: string = 'api/insurance/getRatePlanByPhysicalDamage?equipmentId='+CommonUtil.SELECT_EQUIP_ID+'&saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(physicalDamageAPI, '', '', 'GET', true,onGetOrgPhysicalDamagePlans,onLoginError);
    }

    let onGetOrgPhysicalDamagePlans = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let physicalDamagePlans:any = [];
                if(Array.isArray(restData.ratePlan)){
                    physicalDamagePlans = restData.ratePlan;
                }else{
                    physicalDamagePlans.push(restData.ratePlan);
                }

                setPhysicalDamagePolicies(physicalDamagePlans);
            }
        }
    }

    let getPartkingLots = ()=>{
        parkingLotArray = [];
        let parkingAPI:string = "api/parkinglot/all?saasOrgId="+saasOrg;//CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetParkingLots,onLoginError);
    }

    let onGetParkingLots = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData.parkingLots)){
                    parkingLotArray = restData.parkingLots;
                }else{
                    parkingLotArray.push(restData.parkingLots);
                }
                setParkingLots(parkingLotArray);
            }
        }

        getOrgInsurancePlanList();
    }

    let getOrganizationInfo = ()=>{
        var qParam = saasOrg;//CommonUtil.getSaasOrgId();
        let avalAPI: string = 'api/organization/anyAvailbleEnabled?saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, onGetAnyAvailableEnabled, onLoginError)
    }
    let onGetAnyAvailableEnabled = (dataObj: any) => {
        //console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                setIsAnyAvailable(restData.isEnableAnyAvailble == "Y"?true:false);
                //setPage(""+Math.random()*100000);
            }
        }
        if(CommonUtil.isRootUser()){
            getEquipAvailabilityDetails();
        }

    }

    let getTaxInfo = ()=>{
        let getCrediDetailsAPI: string = "api/organization/getFinance?saasOrgId=" +saasOrg;// CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getCrediDetailsAPI, "", "", "GET", true, onGetTaxInfo, onLoginError);
    }
    let onGetTaxInfo = (dataObj: any) => {
        if (dataObj) {
            var restData: any = CommonUtil.parseData(dataObj);
            if (restData) {
                if(restData.taxExemptionReason && restData.taxState && restData.taxStatus){
                    isTaxExcemptionAvail = true;

                }
            }
        }
        getOrganizationInfo();
    }

    let getOrgBanks = ()=>{
        bankAccounts = [];
        let bankAPI: string = 'api/organization/finance/getBankAccounts?saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(bankAPI, '', '', 'GET', true,onGetAllBanks,onLoginError);
    }

    let onGetAllBanks = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    bankAccounts = restData;
                }else{
                    bankAccounts.push(restData);
                }
            }
        }
        setCurrencyDropdownDisable();
        getTaxInfo();
    }

    let setCurrencyDropdownDisable = ()=>{
        if(bankAccounts.length>0){
            bank1Currency = bankAccounts[0].currency;
            if(bankAccounts.length>1){
                bank2Currency = bankAccounts[1].currency;
                setCurrencyDisable(false);
                setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
            }else{
                setCurrencyDisable(true);
                setCurrencyObj({key:bank1Currency,value:bank1Currency});
            }
        }else{
            setCurrencyDisable(false);
            setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        }

        if(bank2Currency && bank1Currency == bank2Currency){
            setCurrencyDisable(true);
            setCurrencyObj({key:bank1Currency,value:bank1Currency});
        }
    }

    let getDefaultPrices = ()=>{
        //props.equipData
        //console.log(props.equipData);
        //setDefDailyRate(props.equipData.pricing.dailyRate);
        //setDefDistanceRate(props.equipData.pricing.distanceRate);
        //setDefEngineRate(props.equipData.pricing.engineRate);
        //setEquipmentType(props.equipData.equipmentType);
    }

    let getAvailabilityList = () => {
        let orgId: any = "";
        if (CommonUtil.isRootUser()) {
            orgId = selCompanyObj ? selCompanyObj.orgId : '';
        } else {
            orgId = CommonUtil.getSelectedOrgId();
        }
        let qParams: string = 'saasOrgId=' + orgId;
        qParams = qParams + '&assetTypeId=' + (selAssetObj ? selAssetObj.key : '')
        qParams = qParams + '&equipmentTypeId=' + (selEquipObj ? selEquipObj.key : '')

        setEquipData([])
        let equipAllAPI: string = 'api/availability/all?' + qParams
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                var equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                equips.forEach((item: any, idx: number) => {
                    if (item.isGeneric) {
                        item.isGeneric = item.isGeneric === "Y" ? CommonUtil.EQUIP_GENERIC : CommonUtil.EQUIP_SPECIFIC;
                        if(item.isEnableAnyAvailble === "Y"){
                            item.isGeneric = item.isGeneric+" - "+CommonUtil.ANY_AVAILABLE;
                        }
                       // item.isGeneric = item.isGeneric+" - "+(item.isEnableAnyAvailble === "Y" ? CommonUtil.ANY_AVAILABLE : CommonUtil.ANY_NOT_AVAILABLE);
                    }

                    item.UnitEquipName = (item.unitNumber ? item.unitNumber : item.genericEquipmentName);
                    //delete item['genericEquipmentName'];
                })

                setEquipData(equips);
            }
        }

    }

    let onClickLink = (data: any) => {
        navigate('equippublic');
    }

    let setAvailabilityType = () => {
        setGenericReposition(false);
        setGenericRental(false);
        setSpecificReposition(false);
        setSpecificRental(false);

        setQuantity("");
        setPriceKm("");
        setEDisRate("");
        setDailyRate("");
        setDistanceRate("");
        if (chkAllowRef.current) {
            chkAllowRef.current.checked = false;
        }
        setMovePrice("");
        setAdditionalPrice("");
        setSelDepartureObj("");
        setSelArrivalObj("");
        setLoadType("");



        //CommonUtil.IS_GENERIC_EQUIP = true;

        if (CommonUtil.IS_GENERIC_EQUIP) {
            if (CommonUtil.EQUIP_AVAIL_TYPE === CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setGenericReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE === CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setGenericRental(true);
            }
        } else {
            if (CommonUtil.EQUIP_AVAIL_TYPE === CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setSpecificReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE === CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setSpecificRental(true);
            }
        }
    }

    let getInsurPolicyById = (pId:string)=>{
        for(let i=0;i<insurPolicies.length;i++){
            if(insurPolicies[i].id == pId){
                return insurPolicies[i].policyId;
            }
        }
        return "";
    }

    let onClickLinkDetails = (data: any) => {
        //console.log(data);
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
        CommonUtil.SELECT_EQUIP_AVAIL_ID = data.equipmentListingId;
        CommonUtil.EQUIP_AVAIL_TYPE = data.listingType;
        CommonUtil.IS_GENERIC_EQUIP = false;

        if(data.isGeneric == CommonUtil.EQUIP_GENERIC){
            CommonUtil.IS_GENERIC_EQUIP = true;
        }

        setAvailabilityType();

        //setShowAvailPopup(true);
        setShowAvailabilityPopup(true);

        if(CommonUtil.isRootUser()){
            saasOrg = data.saasOrgId;
            getPartkingLots();
        }else{
            //let isLiabilityInsuranceEnabled = false;
           // let isPhysicalInsuranceEmabled = false;
            if(isLiabilityInsuranceEnabled){
                let liabilityAPI: string = 'api/insurance/getRatePlanByLiability?equipmentId='+CommonUtil.SELECT_EQUIP_ID+'&saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
                let obj: any = {};
                NetworkManager.sendJsonRequest(liabilityAPI, '', '', 'GET', true,(dataObj:any)=>{
                    if(dataObj) {
                        var restData = CommonUtil.parseData(dataObj);
                        if (restData) {
                            let liabilityPlans:any = [];
                            if(Array.isArray(restData.ratePlan)){
                                liabilityPlans = restData.ratePlan;
                            }else{
                                liabilityPlans.push(restData.ratePlan);
                            }

                            setLiabilityPolicies(liabilityPlans);

                        }
                    }
                    if(isPhysicalInsuranceEmabled){
                        equipPhysicalInsurancePlans();
                    }
                },onLoginError);
            }else if(isPhysicalInsuranceEmabled){
                equipPhysicalInsurancePlans();
            }else{
                getEquipAvailabilityDetails();
            }

        }
    }

    let equipPhysicalInsurancePlans = ()=>{
        let physicalDamageAPI: string = 'api/insurance/getRatePlanByPhysicalDamage?equipmentId='+CommonUtil.SELECT_EQUIP_ID+'&saasOrgId='+saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(physicalDamageAPI, '', '', 'GET', true,onGetOrgEquipPhysicalDamagePlans,onLoginError);
    }

    let onGetOrgEquipPhysicalDamagePlans = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let physicalDamagePlans:any = [];
                if(Array.isArray(restData.ratePlan)){
                    physicalDamagePlans = restData.ratePlan;
                }else{
                    physicalDamagePlans.push(restData.ratePlan);
                }

                setPhysicalDamagePolicies(physicalDamagePlans);
            }
        }
        getEquipAvailabilityDetails();
    }
    let getEquipAvailabilityDetails = ()=>{
        let parkingAPI: string = "api/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + saasOrg;
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetAvailabilityDetails, onLoginError);
    }
    let onGetAvailabilityDetails = (dataObj: any) => {
       // setAvailabilityType();
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(stDateRef.current && restData.startDate){
                    let stDate = CommonUtil.getServerDate(restData.startDate);
                    if(stDate){
                        avlStDate = stDate;

                        stDateRef.current.flatpickr.setDate(stDate);

                        stDateRef.current.flatpickr.set("enable",[ stDate,function(date:any) {
                            // return true to enable
                            //console.log(date);
                            return (date.getTime()>=(new Date().getTime()-(24*60*60*1000)));

                        }]);
                        //stDateRef.current.flatpickr.set("minDate",new Date());
                        setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
                        //setStDate(stDate);
                    }

                }
                if(endDateRef.current && restData.endDate){
                    let edDate = CommonUtil.getServerDate(restData.endDate);
                    if(edDate){
                        avlEndDate = edDate;
                        endDateRef.current.flatpickr.setDate(edDate);
                        endDateRef.current.flatpickr.set("enable",[ edDate,function(date:any) {
                            // return true to enable
                            //console.log(date);
                            return (date.getTime()>=(new Date().getTime()-(24*60*60*1000)));

                        }]);
                        setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
                        //setEndDate(edDate);
                    }
                }
                if(stDateRef.current){
                    //stDateRef.current.flatpickr.set("minDate",new Date());
                    //stDateRef.current.flatpickr
                }
                if(endDateRef.current){
                    endDateRef.current.flatpickr.set("minDate",new Date());
                }

                onChangeDates();
                setQuantity(restData.quantity);
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setEDisRate(restData.estimatedDistance);
                if(restData.dailyRate == 0){
                    setDailyRate(restData.dailyRate.toString());
                }else{
                    setDailyRate(restData.dailyRate);
                }
                if(restData.distanceRate == 0){
                    setDistanceRate(restData.distanceRate.toString());
                }else{
                    setDistanceRate(restData.distanceRate);
                }
                if(restData.engineRate == 0){
                    setEngineRate(restData.engineRate.toString());
                }else{
                    setEngineRate(restData.engineRate);
                }

                setNoOfDays(restData.noOfDaysAllowed);
                if(restData.movePrice == 0){
                    setMovePrice(restData.movePrice.toString());
                }else{
                    setMovePrice(restData.movePrice);
                }
                if(restData.distanceRate == 0){
                    setPriceKm(restData.distanceRate.toString());
                }else{
                    setPriceKm(restData.distanceRate);
                }
                if(restData.dailyRate == 0){
                    setAdditionalPrice(restData.dailyRate.toString());

                }else{
                    setAdditionalPrice(restData.dailyRate);
                }

                if(restData.additionalPriceExcessDays){
                    setAdditionalPrice(restData.additionalPriceExcessDays.toString());
                }

                setTimeout(()=>{
                    if(restData.noOfDaysAllowed){
                        CommonUtil.isMandatory(noOfDayRef);
                    }
                    if(restData.dailyRate){
                        CommonUtil.isMandatory(dailyRef);
                        CommonUtil.isMandatory(priceRef);
                    }
                    if(restData.distanceRate){
                        CommonUtil.isMandatory(distanceRef);
                    }
                    if(restData.engineRate){
                        CommonUtil.isMandatory(engineRef);
                    }
                    if(restData.movePrice){
                        CommonUtil.isMandatory(movePriceRef);
                    }
                    if(restData.quantity){
                        CommonUtil.isMandatory(qtyRef);
                    }



                },100);


                if(restData.allowOffer == "Y"){
                    if(chkAllowRef.current){
                        chkAllowRef.current.checked = true;
                    }
                }
                if(restData.isEnableAnyAvailble == "Y"){
                    if(chkPickRef.current){
                        chkPickRef.current.checked = true;
                        onChangePickAnyAvailable();
                    }
                }
                if(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                    setCurrencyDropdownDisable();
                }

                setSelLiability({insuranceRateId:restData.ratePlanIdLiability,policy:restData.policyNameLiability});
                setSelPhysicalDamage({insuranceRateId:restData.ratePlanIdPhysical,policy:restData.policyNamePhysical});

                setLiabilityPaidByOwner((restData.isInsurancePaidByOwnerLiability == "Y")?true:false);
                setLiabilityPaidByRenter((restData.isInsurancePaidByRenterLiability == "Y")?true:false);
                setPhysicalDamagePaidByOwner((restData.isInsurancePaidByOwnerPhysical == "Y")?true:false);
                setPhysicalDamagePaidByRenter((restData.isInsurancePaidByRenterPhysical == "Y")?true:false);


                setCurrencyObj({key:restData.defaultCurrency,value:restData.defaultCurrency});
                setSelPolicyObj({id:restData.policyId,policyId:getInsurPolicyById(restData.policyId)});
                setRatePerDay(restData.insurancePerDayAmount);

                let dLotName:string = CommonUtil.getDropdownText(parkingLotArray,restData.departureParkingLotId,"parkingLotId","parkingLotName");
                let aLotName:string = CommonUtil.getDropdownText(parkingLotArray,restData.arrivalParkingLotId,"parkingLotId","parkingLotName");

                setSelDepartureObj({parkingLotId:restData.departureParkingLotId,parkingLotName:dLotName,address:restData.departureParkingLotAddress});
                setSelArrivalObj({parkingLotId:restData.arrivalParkingLotId,parkingLotName:aLotName,address:restData.arrivalParkingLotAddress});
                setLoadTypeObj({key:restData.loadType,value:restData.loadType});

                (restData.loadType?setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS):setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.arrivalParkingLotId?setArrivalAddrClass(CommonUtil.DROPDOWN_VALID_CLASS):setArrivalAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.departureParkingLotId?setDepartureAddrClass(CommonUtil.DROPDOWN_VALID_CLASS):setDepartureAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.defaultCurrency?setCurrencyClass(CommonUtil.DROPDOWN_VALID_CLASS):setCurrencyClass(CommonUtil.DROPDOWN_INVALID_CLASS));


                CommonUtil.EQUIP_AVAILABILITY_STATUS = restData.listingStatus;
                setDisabled(false);
                setTermsDisable(false);
                setRepDisabled(false);
                if(chkTerms && chkTerms.current){
                    chkTerms.current.checked = true;
                }

                equipListingStatus = restData.listingStatus;

                if(!CommonUtil.IS_GENERIC_EQUIP){
                    setDisabled(true);
                    setCurrencyDisable(true);
                    setRepDisabled(true);
                }else if((restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_COMPLETED || restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_IN_PROGRESS)){
                    setDisabled(true);
                    setCurrencyDisable(true);
                    if(CommonUtil.IS_GENERIC_EQUIP && CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE){
                        setRepDisabled(false);
                    }else{
                        setRepDisabled(true);
                    }

                }
            }
        }

    }

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj);
        if(!dataObj){
            setSelCompanyObj(defObj);
        }
    }

    let onAssetTypeChange = (dataObj: any) => {
        setSelAssetObj(dataObj)
        setSelEquipObj(null);
        setEquipTypes([])
        if (dataObj) {
            CommonUtil.getEquipmentTypes(dataObj.key, (data: any) => {
                setEquipTypes(data)
            })

        }


    }



    let onEquipmentTypeChange = (dataObj: any) => {
        setSelEquipObj(dataObj)
    }

    // let onClickGeneric = () => {
    //     CommonUtil.IS_GENERIC_EQUIP = true
    //     navigate('equipDetails')
    //     onCloseEquipPopup()
    // }

    // let onClickSpecific = () => {
    //     CommonUtil.IS_GENERIC_EQUIP = false
    //     navigate('equipDetails')
    //     onCloseEquipPopup()
    // }

    let onCloseEquipAvailPopup = () => {
        setShowAvailabilityPopup(false);
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devAvailPopup', 'devAvailContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let onChangePickAnyAvailable = ()=>{
        let checked:boolean = false;
        if(chkPickRef.current){
            checked = chkPickRef.current.checked;
        }
        setParkingLots([]);
        // setSelDepartureObj({});
        // setSelArrivalObj({});

        let parkLots:any = [];
        parkingLotArray.forEach((pItem:any,pIdx:number)=>{
            if(checked){
                if(pItem.isDummy == "N"){
                    parkLots.push(pItem);
                }
            }else{
                parkLots.push(pItem);
            }

        });
        setParkingLots(parkLots);
    }

    let onChangeStDate = (dateValue:any)=>{
        if(stDateRef.current){
            let strDate:string = CommonUtil.getDate(dateValue);
            setStDate(strDate);
            if(strDate){
                setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
            }else{
                setStartDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
            }
            onChangeDates();
            setTimeout(()=>{
                // updateEndDate();
            })
        }
    }

    let onChangeDates = ()=>{
        if(stDateRef.current && endDateRef.current){
            // stDateRef.current.flatpickr.set("minDate",new Date());
            //endDateRef.current.flatpickr.set("minDate",new Date());

            var startDate = stDateRef.current.flatpickr.selectedDates[0];
            var endDate = endDateRef.current.flatpickr.selectedDates[0];

            endDateRef.current.flatpickr.set("minDate",new Date(startDate));
            stDateRef.current.flatpickr.set("maxDate",new Date(endDate));

            if (startDate) {

                //stDtOptions.minDate = new Date(startDate);
            }
            if (endDate) {
                // endDate = new Date(endDate);

                // stDtOptions.maxDate = new Date(endDate);
            }
        }
    }

    let onOpenStDate = ()=>{
        if(stDateRef.current){
            // stDateRef.current.flatpickr.set("minDate",new Date());
            if(endDateRef.current){
                var endDate = endDateRef.current.flatpickr.selectedDates[0];
                if(endDate){
                    stDateRef.current.flatpickr.set("maxDate",new Date(endDate));
                }else{
                    stDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                stDateRef.current.flatpickr.set("minDate",new Date());
            }

        }
    }

    let onCloseStDate = ()=>{
        if(stDateRef.current && avlStDate) {

            //stDateRef.current.flatpickr.set("minDate",avlStDate);
            //stDateRef.current.flatpickr.setDate(avlStDate);
        }
    }

    let onOpenEDDate = ()=>{
        if(endDateRef.current){
            if(stDateRef.current){
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRef.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRef.current.flatpickr.set("minDate",new Date());
            }

        }
    }

    let onChangeEdDate = (dataVal:any)=>{
        let strDate:string = CommonUtil.getDate(dataVal);
        if(strDate){
            setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
        }else{
            setEndDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
        }
        onChangeDates();
    }

    let handleNoOfDays = (e: any) => {
        setNoOfDays(e.currentTarget.value);
        CommonUtil.isMandatory(noOfDayRef);

        var newNofDaysMiilis = e.currentTarget.value*24*60*60*1000;
        setTimeout(()=>{
            // updateEndDate();
        })
        //console.log(noOfDays)

    }

    let onChangeLoadType = (dataObj:any)=>{
        setLoadTypeObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj,setLoadTypeClass);
        // (dataObj) ? setLoadTypeClass('select-box-valid') : setLoadTypeClass('select-box-invalid');
    }

    let onChangeDepartureLot = (dataObj:any)=>{
        setSelDepartureObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj,setDepartureAddrClass);
    }

    let onChangeArrivalLot = (dataObj:any)=>{
        setSelArrivalObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj,setArrivalAddrClass);
        //  (dataObj) ? setArrivalAddrClass('select-box-valid') : setArrivalAddrClass('select-box-invalid');
    }

    let onChangeLiability = (dataObj:any)=>{
        setSelLiability(dataObj);
        (dataObj) ? setLiabilityClass('select-box-valid') : setLiabilityClass('select-box-invalid');
    }

    let onChangeLiabilityPaidByOwner = (e:any)=>{
        console.log(e.currentTarget.checked);
        if(e.currentTarget.checked){
            setLiabilityPaidByOwner(true);
            setLiabilityPaidByRenter(false);
        }
    }

    let onChangeLiabilityPaidByRenter = (e:any)=>{
        console.log(e.currentTarget.checked);
        if(e.currentTarget.checked){
            setLiabilityPaidByOwner(false);
            setLiabilityPaidByRenter(true);
        }
    }

    let onChangePhysicalDamageOwner = (e:any)=>{
        if(e.currentTarget.checked){
            setPhysicalDamagePaidByOwner(true);
            setPhysicalDamagePaidByRenter(false);
        }
    }

    let onChangePhysicalDamageRenter = (e:any)=>{
        if(e.currentTarget.checked){
            setPhysicalDamagePaidByOwner(false);
            setPhysicalDamagePaidByRenter(true);
        }
    }

    let onChangePhysicalDamage = (dataObj:any)=>{
        setSelPhysicalDamage(dataObj);
        (dataObj) ? setPhysicalDamageClass('select-box-valid') : setPhysicalDamageClass('select-box-invalid');
    }

    let onChangeCurrency = (dataObj:any)=>{
        setCurrencyObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj,setCurrencyClass);
    }

    let onClickSaveAvailability = ()=>{

        /*if(equipListingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_IN_PROGRESS){
            if(CommonUtil.IS_GENERIC_EQUIP){
                let isQty = CommonUtil.isMandatory(qtyRef);
               let availCraateApi = "api/availability/updateQuantity?equipmentListingId="+CommonUtil.SELECT_EQUIP_AVAIL_ID+"&quantity="+quantity;
                NetworkManager.sendJsonRequest(availCraateApi,"","","POST",true,onUpdateQuantity,onLoginError);
            }
        }else{*/
        let flag:boolean = false;
        let stDate = null;
        let edDate = null;
        if(stDateRef.current){
            stDate = stDateRef.current.flatpickr.selectedDates[0];
        }
        if(endDateRef.current){
            edDate = endDateRef.current.flatpickr.selectedDates[0];
        }

        (stDate?setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS):setStartDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (edDate?setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS):setEndDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (selArrivalObj?setArrivalAddrClass(CommonUtil.DROPDOWN_VALID_CLASS):setArrivalAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selDepartureObj?setDepartureAddrClass(CommonUtil.DROPDOWN_VALID_CLASS):setDepartureAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (currencyObj?setCurrencyClass(CommonUtil.DROPDOWN_VALID_CLASS):setCurrencyClass(CommonUtil.DROPDOWN_INVALID_CLASS));

        $("#divTerms").hide();

        if(stDate && edDate && selArrivalObj && selDepartureObj && selArrivalObj.parkingLotName && selDepartureObj.parkingLotName && currencyObj) {
            if((isGenericRental || isGenericReposition)){
                if(isGenericRental){
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if(equipmentType == CommonUtil.REEFER_EQUIP_TYPE){
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if(quantity && dailyRate && distanceRate && engineRate){
                            flag = true;
                        }
                    }else{
                        if(quantity && dailyRate && distanceRate){
                            flag = true;
                        }else{
                            // displayErrorMessage();
                        }
                    }


                }else if(isGenericReposition){
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);
                    (loadTypeObj?setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS):setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    if(quantity && noOfDays && movePrice && addtionalPrice && loadTypeObj){
                        flag = true;
                    }else{
                        // displayErrorMessage();
                    }
                }
            }else if(isSpecificRental || isSpecificReposition){
                if(isSpecificRental){
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if(equipmentType == CommonUtil.REEFER_EQUIP_TYPE){
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if(dailyRate && distanceRate && engineRate){
                            flag = true;
                        }
                    }else{
                        if(dailyRate && distanceRate){
                            flag = true;
                        }else{
                            // displayErrorMessage();
                        }
                    }

                }else if(isSpecificReposition){
                    (loadTypeObj?setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS):setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);

                    if(loadTypeObj && noOfDays && movePrice && addtionalPrice){
                        flag = true;
                    }else{
                        // displayErrorMessage();
                    }
                }
            }

            if(isGenericReposition || isSpecificReposition){
                let days = CommonUtil.getDays(stDate,edDate);
                if(Number(noOfDays)>days){
                    flag = false;
                    let msg = CommonUtil.getMessageText(Constants.NUM_OF_DAY_ALLOWED, "Number of days allowed for repositioning is less than or equal to between days");
                    ToastManager.showToast(msg, 'Error');
                    return;
                }
            }
            if(isLiabilityChecked){
                if(!selLiability){
                    (selLiability?setLiabilityClass(CommonUtil.DROPDOWN_VALID_CLASS):setLiabilityClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }

            }
            if(isPhysicalDamageChecked){
                if(!selPhysicalDamage && flag){
                    (selPhysicalDamage?setPhysicalDamageClass(CommonUtil.DROPDOWN_VALID_CLASS):setPhysicalDamageClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }
            }

            if(flag){
                //var startDate = stDateRef.current.flatpickr.selectedDates[0];
                //var endDate = endDateRef.current.flatpickr.selectedDates[0];

                if(chkTerms.current){
                    if(!chkTerms.current.checked){
                        $("#divTerms").show();
                        ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_SELECT_TERMS_CONDITIONS,"Please select terms & conditions"),"Error");
                        return;
                    }else{

                    }
                }

                let reqObj:any = {};
                reqObj.policyId = selPolicyObj?selPolicyObj.id:"";
                reqObj.endDate = CommonUtil.getReservationDateString(edDate);
                reqObj.startDate = CommonUtil.getReservationDateString(stDate);
                reqObj.additionalPriceExcessDays = addtionalPrice;
                reqObj.allowOffer = ((chkAllowRef.current?.checked)?"Y":"N");
                reqObj.isEnableAnyAvailble = ((chkPickRef.current?.checked)?"Y":"N");
                reqObj.arrivalParkingLotId = selArrivalObj?selArrivalObj.parkingLotId:"";
                reqObj.departureParkingLotId = selDepartureObj?selDepartureObj.parkingLotId:"";
                reqObj.dailyRate = dailyRate;
                reqObj.defaultCurrency = currencyObj?currencyObj.key:"";

                /*if(distanceRate){
                    reqObj.distanceRate = distanceRate;
                }else{
                    reqObj.distanceRate = "";
                }*/

                reqObj.engineRate = engineRate;
                reqObj.equipmentId = CommonUtil.SELECT_EQUIP_ID;
                reqObj.estimatedDistance = eDisRate;
                reqObj.listingType = CommonUtil.EQUIP_AVAIL_TYPE;
                reqObj.loadType = loadTypeObj?loadTypeObj.key:"";
                reqObj.movePrice = movePrice;
                reqObj.noOfDaysAllowed = noOfDays;
                reqObj.pricePerKm = priceKm;

                /* if(priceKm){
                     reqObj.distanceRate = priceKm;
                 }else if(distanceRate){
                     reqObj.distanceRate = distanceRate;
                 }else{
                     reqObj.distanceRate = "";
                 }*/
                if(isSpecificReposition){
                    if(priceKm){
                        reqObj.distanceRate = priceKm;
                    }
                }else if(isGenericRental || isSpecificRental){
                    if(distanceRate){
                        reqObj.distanceRate = distanceRate;
                    }
                }else{
                    reqObj.distanceRate = "";
                }
                if(addtionalPrice){
                    reqObj.dailyRate = addtionalPrice;
                }

                reqObj.quantity = quantity;
                reqObj.useEquipmentPricing = "";

                reqObj.isInsurancePaidByOwnerLiability = "N";
                reqObj.isInsurancePaidByOwnerPhysical = "N";
                reqObj.isInsurancePaidByRenterLiability = "N";
                reqObj.isInsurancePaidByRenterPhysical = "N";
                reqObj.ratePlanIdLiability = "";
                reqObj.ratePlanIdPhysical = "";

                if(isLiabilityChecked){
                    reqObj.isInsurancePaidByOwnerLiability = (rdLbtPaidByOwner.current && rdLbtPaidByOwner.current.checked?"Y":"N");
                    reqObj.isInsurancePaidByRenterLiability = (rdLbtPaidByRenter.current && rdLbtPaidByRenter.current.checked?"Y":"N");
                    reqObj.ratePlanIdLiability = selLiability?selLiability.insuranceRateId:"";
                }

                if(isPhysicalDamageChecked){
                    reqObj.isInsurancePaidByOwnerPhysical = (rdPDmgPaidByOwner.current && rdPDmgPaidByOwner.current.checked?"Y":"N");
                    reqObj.isInsurancePaidByRenterPhysical = (rdPDmgPaidByRenter.current && rdPDmgPaidByRenter.current.checked?"Y":"N");
                    reqObj.ratePlanIdPhysical = selPhysicalDamage?selPhysicalDamage.insuranceRateId:"";
                }

                let distanceInMeters = 0;
                let durationInMin = 0;
                console.log(reqObj);
                BusyLoader.showLoader();
                /* let availCraateApi:string = "";
                 let method:string = "POST";
                 if(CommonUtil.SELECT_EQUIP_AVAIL_ID == ""){
                     availCraateApi = "api/availability/?saasOrgId="+CommonUtil.getSaasOrgId();
                 }else{
                     method = "PUT";
                     availCraateApi = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
                 }
                 NetworkManager.sendJsonRequest(availCraateApi,reqObj,"",method,false,onCreateAvailable,onLoginError);*/

                CommonUtil.getDistanceBetweenPoints(selDepartureObj.address,selArrivalObj.address,(dataObj:any)=>{
                    console.log(dataObj);
                    if(dataObj && dataObj.distance && dataObj.distance.value){
                        distanceInMeters = dataObj.distance.value;
                    }
                    if(dataObj && dataObj.distance && dataObj.duration.value){
                        durationInMin = dataObj.duration.value;///60;
                    }

                    reqObj.distanceBetweenLots = distanceInMeters;
                    reqObj.travelTimeBetweenLots = durationInMin;

                    let availCraateApi:string = "";
                    let method:string = "PUT";
                    availCraateApi = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+saasOrg;
                    NetworkManager.sendJsonRequest(availCraateApi,reqObj,"",method,false,onCreateAvailable,onLoginError);
                });
            }else{
                displayErrorMessage();
            }
        }else{
            displayErrorMessage();
        }
        // }

    }
    let displayErrorMessage = ()=>{
        ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS,"Please enter mandatory fields"),"Error");
    }
    let onCreateAvailable = (dataObj:any)=>{
        // console.log(dataObj);
        BusyLoader.hideLoader();
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                onCloseEquipAvailPopup();
                getAvailabilityList();
                ToastManager.showToast(CommonUtil.getMessageText(Constants.AVAIL_UPDATED,"Availability Updated Successfully"),"Info");
            }
        }

    }
    let onClickDeleteAvailability = ()=>{
        let strDel = CommonUtil.getMessageText(Constants.DO_U_WANT_DELETE,"Do you want delete availability?");
        if( CommonUtil.SELECT_EQUIP_AVAIL_ID){
            Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,"Confirm"), strDel, [{
                label: "Yes", callback: function () {
                    if( CommonUtil.SELECT_EQUIP_AVAIL_ID){
                        let delAvailAPI =  "api/availability/"+ CommonUtil.SELECT_EQUIP_AVAIL_ID;
                        NetworkManager.sendJsonRequest(delAvailAPI,'',"","DELETE",true,onDeleteAvailable,onLoginError);
                    }
                }
            }, {label: "No"}]);
        }
    }

    let onDeleteAvailable = (dataObj:any)=>{
        let strDel = CommonUtil.getMessageText(Constants.AVAIL_DELETE,"Availability Deleted Successfully");
        if(dataObj) {
            if(dataObj == "success"){
                onCloseEquipAvailPopup();
                getAvailabilityList();

                ToastManager.showToast(strDel,"Info");
            }else{
                var restData = CommonUtil.parseData(dataObj);
                if (restData) {
                    onCloseEquipAvailPopup();
                    getAvailabilityList();
                    ToastManager.showToast(strDel,"Info");
                }
            }

        }
    }
    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <Modal show={showAvailPopup} dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{ "width": "800px", "height": "650px" }}>
                    <div className="modal-header">
                        <h6 className="modal-title">{(CommonUtil.EQUIP_AVAIL_TYPE === CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) ? "Equipment Reposition" : "Equipment Rental"}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "0px" }} onClick={onCloseEquipAvailPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: "0px", overflow: "auto" }}>
                        <div className="container" style={{ padding: "0px" }}>
                            <br />
                            <section style={{ boxShadow: "none", padding: "0px" }}>
                                {/*<h5 style={{textAlign:"center"}}>New Repositioning</h5>*/}
                                <p style={{ textAlign: "center" }}>Availabilities will be automatically reallocated if a reservation is made on a subset of the dates</p>
                                <div className="row">
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Starts at:</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={stDate} className="form-control"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Ends at:</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={endDate} className="form-control"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6" style={{ display: ((isSpecificReposition || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Number of available equipment for this period</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={quantity} className="form-control"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Number of days allowed for repositioning</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={noOfDays} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Load Type</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={loadType} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <h6 className="mt-2">Location</h6>
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Departure Parking Lot</label>
                                            <div className="col-sm-12 col-12">
                                                <input type="text" value={departure} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Arrival Parking Lot</label>
                                            <div className="col-sm-12 ">
                                                <input type="text" value={arrival} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <h6 className="mt-2" style={{ paddingBottom: "10px" }}>Pricing</h6>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12 checkbox flex">
                                                <input ref={chkAllowRef} type="checkbox" disabled={true} className="checkBox" />
                                                <label htmlFor="vehicle1" style={{ paddingLeft: "10px", display: "inline" }}>
                                                    Allow renter to make an offer for this availability
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Currency</label>
                                            <div className="col-sm-12 col-12">
                                                <input type="text" value={currency} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Paid Price to Carrier for Repositioning:</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" value={movePrice} aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Additional Price per Excess Days</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" value={addtionalPrice} disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: (isSpecificReposition ? "" : "none") }}>
                                    <div className="col-sm-6 col-12 col-md-6" >
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Estimated distance in miles</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control clsStandardText clsFormInputControl" value={eDisRate}
                                                    placeholder="" aria-label="trailer" disabled={true} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Price per mile</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" value={priceKm} disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row" style={{ display: ((isSpecificReposition || isGenericReposition) ? "none" : "") }}>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Daily Rate</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control  avail-text-field"
                                                    placeholder="" aria-label="trailer" value={dailyRate}
                                                    disabled={true} /> / DAY
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">Distance Rate</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input type="text" className="form-control  avail-text-field" value={distanceRate}
                                                    placeholder="" aria-label="trailer" disabled={true} /> / MILE
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row" style={{ border: "1px solid #999", height: "100px", margin: "1px" }}>
                                    <div className="col-sm-12 col-12" style={{ display: "flex", alignItems: "center" }}>
                                        <div className="new-repositioning">
                                            <div className="form-group row" style={{ marginBottom: "0px" }}>
                                                <div id="repositioning" className="col-sm-1 col-2">
                                                    <i className="fa fa-tag" style={{ fontSize: "24px" }}></i>
                                                </div>
                                                <div className="col-sm-11 col-10">
                                                    <p style={{ marginBottom: "0px" }}>These are the rates that will apply for this rental. vHub will
                                                        charge a fee in addition to these rates. Please refer to our
                                                        Payment Terms for full details.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-outline-dark" onClick={onCloseEquipAvailPopup}>Cancel</button>

                    </div>
                </div>
            </Modal>
            <Modal  show={showAvailabilityPopup} dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{"width": "680px","height":"650px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title mt-0 fw-bold text-center black-text">{(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?CommonUtil.getMessageText(Constants.REPOSITION,"Reposition"):CommonUtil.getMessageText(Constants.RENTAL,"Rental")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipAvailPopup} style={{marginLeft:"0px"}} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{paddingTop:"0px",overflow:"auto"}}>
                        <div className="container" style={{padding:"0px"}}>
                            <br />
                            <section style={{boxShadow:"none",padding:"0px"}}>
                                {/*<h5 style={{textAlign:"center"}}>New Repositioning</h5>*/}
                                <p style={{textAlign: "center",marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.AVAIL_DATES,"Availabilities will be automatically reallocated if a reservation is made on a subset of the dates")}</p>

                                <div className="row" style={{paddingTop:"10px",display:(isAnyAvailable && CommonUtil.IS_GENERIC_EQUIP?"":"none")}}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12  flex">
                                                <input ref={chkPickRef}  type="checkbox" className="checkBox"  disabled={isDisabled?true:false}
                                                       onChange={onChangePickAnyAvailable}/>
                                                <label  style={{paddingLeft:"10px",display:"inline"}}>
                                                    {CommonUtil.getMessageText(Constants.PICK_ANY_AVAILABLE_TRAILER,"Pick any available trailer")}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT,"Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <Flatpickr  placeholder="Start Date" ref={stDateRef} options={dateOptions} onChange={onChangeStDate}
                                                            onOpen={onOpenStDate} className={startDateClass} onClose={onCloseStDate}
                                                            disabled={isRepDisabled?true:false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <Flatpickr  placeholder="End Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                            onOpen={onOpenEDDate} className={endDateClass}
                                                            disabled={isRepDisabled?true:false} />
                                            </div>
                                        </div>
                                    </div>
                                    {/*islabelRental ?
                                      <div className="col-sm-6 col-6">
                                      <div className="form-group row">
                                          <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                          <div className="col-sm-12">
                                              <Flatpickr  placeholder="End Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                          onOpen={onOpenEDDate} className={endDateClass}
                                                          disabled={isDisabled?true:false}/>
                                          </div>
                                      </div>
                                  </div> :  <Flatpickr  placeholder="End Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                          onOpen={onOpenEDDate} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker d-none"
                                                          disabled={isDisabled?true:false}/>
                                    */ }

                                    <div className="col-sm-6 col-6" style={{display:((isSpecificReposition || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP,"Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input ref={qtyRef} type="number" value={quantity} min={0} className="form-control clsStandardText clsFormInputControl"
                                                       placeholder="" aria-label="trailer" pattern="\d+" onChange={(e)=>{setQuantity(e.currentTarget.value);CommonUtil.isMandatory(qtyRef);}}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED,"Days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input ref={noOfDayRef}  type="number" min={0} value={noOfDays} pattern="^[0-9]" className="form-control clsStandardText clsFormInputControl"
                                                       placeholder="" aria-label="trailer" onChange={handleNoOfDays}
                                                       disabled={isRepDisabled?true:false}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12" style={{display:((isGenericRental || isSpecificRental) ?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOAD_TYPE,"Load Type")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={loadTypes} selectedValue={loadTypeObj}
                                                                  handleChange={onChangeLoadType} width={"100%"} value={"key"} label={"value"}
                                                                  className={loadTypeClass} disabled={isDisabled?true:false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mt-2">Location</h6>
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                            <div className="col-sm-12 col-12">
                                                <SingleSelectComp dataSource={parkingLots} selectedValue={selDepartureObj}
                                                                  handleChange={onChangeDepartureLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                                                  className={departureAddrClass} disabled={isRepDisabled?true:false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT,"Arrival Parking Lot")}</label>
                                            <div className="col-sm-12 ">
                                                <SingleSelectComp dataSource={parkingLots} selectedValue={selArrivalObj}
                                                                  handleChange={onChangeArrivalLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                                                  className={arrivalAddrClass} disabled={isRepDisabled?true:false}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{display:(isInuranceChecked?"":"none")}}>
                                        <h6 className="mt-2">{CommonUtil.getMessageText(Constants.INSURANCE,"Insurance")}</h6>
                                        <div  className="row" style={{display:(isLiabilityChecked?"":"none")}}>
                                            <div className="col-sm-6 col-12" >
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_PLAN,"Liability Plan")}</label>
                                                    <div className="col-sm-12 col-12">
                                                        <SingleSelectComp dataSource={liabilityPolicies} selectedValue={selLiability}
                                                                          handleChange={onChangeLiability} width={"100%"} value={"insuranceRateId"} label={"policy"}
                                                                          className={liabilityClass} disabled={isDisabled?true:false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12" style={{paddingTop:"8px"}}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{visibility:"hidden"}}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY,"Rate per day")}</label>
                                                    <div className="col-sm-6 col-6 flex" style={{display:(isLiabilityPaidByOwner?"":"none")}}>
                                                        <input ref={rdLbtPaidByOwner}  type="radio" name="liability" className="checkBox" checked={liabilityPaidByOwner}
                                                               disabled={isDisabled?true:false} onChange={onChangeLiabilityPaidByOwner} />
                                                        <span  style={{paddingLeft:"10px",display:"inline"}}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_OWNER,"Paid By Owner")}
                                                    </span>
                                                    </div>
                                                    <div className="col-sm-6 col-6 flex" style={{display:(isLiabilityPaidByRenter?"":"none")}}>
                                                        <input  ref={rdLbtPaidByRenter} type="radio" name="liability" className="checkBox" checked={liabilityPaidByRenter}
                                                                disabled={isDisabled?true:false} onChange={onChangeLiabilityPaidByRenter} />
                                                        <span  style={{paddingLeft:"10px",display:"inline"}}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_RENTER,"Paid By Renter")}
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div  className="row" style={{display:(isPhysicalDamageChecked?"":"none")}}>
                                            <div className="col-sm-6 col-12" >
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN,"Physical Damage Plan")}</label>
                                                    <div className="col-sm-12 col-12">
                                                        <SingleSelectComp dataSource={physicalDamagePolicies} selectedValue={selPhysicalDamage}
                                                                          handleChange={onChangePhysicalDamage} width={"100%"} value={"insuranceRateId"} label={"policy"}
                                                                          className={physicalDamageClass} disabled={isDisabled?true:false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12" style={{paddingTop:"8px"}}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{visibility:"hidden"}}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY,"Rate per day")}</label>
                                                    <div className="col-sm-6 col-6 flex" style={{display:(isPhysicalDamagePaidByOwner?"":"none")}}>
                                                        <input ref={rdPDmgPaidByOwner}  type="radio" name="physicalDamage" className="checkBox" checked={physicalDamagePaidByOwner}
                                                               disabled={isDisabled?true:false} onChange={onChangePhysicalDamageOwner} />
                                                        <span  style={{paddingLeft:"10px",display:"inline"}}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_OWNER,"Paid By Owner")}
                                                    </span>
                                                    </div>
                                                    <div className="col-sm-6 col-6 flex" style={{display:(isPhysicalDamagePaidByRenter?"":"none")}}>
                                                        <input ref={rdPDmgPaidByRenter} type="radio" name="physicalDamage" className="checkBox" checked={physicalDamagePaidByRenter}
                                                               onChange={onChangePhysicalDamageRenter} disabled={isDisabled?true:false} />
                                                        <span  style={{paddingLeft:"10px",display:"inline"}}>
                                                        {CommonUtil.getMessageText(Constants.PAID_BY_RENTER,"Paid By Renter")}
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <h6 className="mt-1">{CommonUtil.getMessageText(Constants.PRICING,"Pricing")}</h6>
                                    <div className="col-sm-6 col-12">
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY,"Currency")}</label>
                                            <div className="col-sm-12 col-12">
                                                <SingleSelectComp dataSource={currencies} selectedValue={currencyObj}
                                                                  disabled={isCurrecyDisable?true:false} handleChange={onChangeCurrency} width={"100%"}
                                                                  value={"key"} label={"value"} className={currencyClass} />
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE,"Paid Price to Carrier for Repositioning")}:</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={movePriceRef}  type="text" className="form-control clsStandardText clsFormInputControl"
                                                       placeholder="" value={movePrice} min={0} aria-label="trailer" disabled={isDisabled?true:false}
                                                       onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:movePriceRef,setState:setMovePrice})}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6" style={{display:((isGenericRental || isSpecificRental)?"none":"")}}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE,"Additional Price per Excess Days")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={priceRef}  type="text" className="form-control clsStandardText clsFormInputControl"
                                                       placeholder="" aria-label="trailer" value={addtionalPrice} min={0} disabled={isDisabled?true:false}
                                                       onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:priceRef,setState:setAdditionalPrice})}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "none"}}>
                                    <div className="col-sm-6 col-12 col-md-6" >
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE,"Estimated excess days mileage")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={eDisRef}  type="text" min={0} className="form-control clsStandardText" value={eDisRate}
                                                       disabled={isDisabled?true:false} placeholder="" aria-label="trailer"
                                                       onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setEDisRate})}} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE,"Price per mile")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={priceKmRef}  type="text" min={0} className="form-control clsStandardText"
                                                       placeholder="" aria-label="trailer" value={priceKm}
                                                       disabled={isDisabled?true:false}
                                                       onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setPriceKm})}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{marginTop:"-20px",display:((isSpecificReposition || isGenericReposition)?"none":"")}}>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12 col-md-12" style={{visibility:"hidden"}}>
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label" style={{paddingLeft:"0px"}}>Default Price(s)</label>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={dailyRef}  type="text" min={0} pattern="[0-9]*" className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                           placeholder="" aria-label="trailer" value={dailyRate} disabled={isDisabled?true:false}
                                                           onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:dailyRef,setState:setDailyRate})}} /> / {CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE,"Distance Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={distanceRef} type="text" min={0} className="form-control clsStandardText clsFormInputControl avail-text-field" value={distanceRate}
                                                           disabled={isDisabled?true:false} placeholder="" aria-label="trailer"
                                                           onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:distanceRef,setState:setDistanceRate})}} /> / {CommonUtil.getMesurementByCountry()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row" style={{display:(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE)?"":"none"}}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={engineRef}  type="text" min={0} className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                           disabled={isDisabled?true:false} placeholder="" aria-label="trailer"  value={engineRate}
                                                           onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:engineRef,setState:setEngineRate})}}/> / {CommonUtil.getMessageText(Constants.HOUR,"HOUR")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <h6 className="col-sm-12 col-12 col-md-12 " style={{paddingLeft:"0px"}}>{CommonUtil.getMessageText(Constants.DEFAULT_PRICE,"Default Price(s)")}</h6>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                           placeholder="" min={0} aria-label="trailer" disabled={true} value={defDailyRate}  /> / {CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE,"Distance Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                           placeholder="" min={0} aria-label="trailer" disabled={true} value={defDistanceRate}  /> / {CommonUtil.getMesurementByCountry()}
                                                </div>
                                            </div>
                                            <div className="form-group row" style={{display:(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE)?"":"none"}}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText avail-text-field"
                                                           placeholder="" min={0} aria-label="trailer" disabled={true} value={defEngineRate}  /> / {CommonUtil.getMessageText(Constants.HOUR,"HOUR")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{paddingTop:"10px"}}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12  flex">
                                                <input ref={chkAllowRef}  type="checkbox" className="checkBox" disabled={isDisabled?true:false} />
                                                <label  style={{paddingLeft:"10px",display:"inline"}}>
                                                    {CommonUtil.getMessageText(Constants.ALLOW_RENTER,"Allow renter to make an offer for this availability")}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row" style={{border:"1px solid #999",height:"100px",margin:"1px"}}>
                                    <div className="col-sm-12 col-12" style={{display:"flex",alignItems:"center"}}>
                                        <div className="new-repositioning">
                                            <div className="form-group row" style={{marginBottom:"0px"}}>
                                                <div id="repositioning" className="col-sm-1 col-2">
                                                    <i className="fa fa-tag" style={{fontSize:"24px"}}></i>
                                                </div>
                                                <div className="col-sm-11 col-10">
                                                    <p style={{marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.APPLY_RENTER,"These are the rates that will apply for this rental. vHub will\n" +
                                                        "                                                            charge a fee in addition to these rates. Please refer to our\n" +
                                                        "                                                            Payment Terms for full details.")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id={"divConditions"}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12 checkbox flex" style={{paddingTop:"10px"}}>
                                                <input ref={chkTerms} type="checkbox" id="vehicle1" name="vehicle1" className="checkBox"
                                                       disabled={isTermsDisable?true:false} onChange={(e) => {(e.currentTarget.checked ? $("#divTerms").hide() : $("#divTerms").show())}}/> &nbsp;
                                                <label  style={{display:"inline"}}> {CommonUtil.getMessageText(Constants.TAX_EXEMPTION_AVAIL,"I have filled my tax exemptions OR I don’t have tax exemptions")}</label>

                                            </div>
                                            <div className="col-sm-12 col-12" id={"divTerms"} style={{display:"none"}}>
                                                <label style={{color: 'red'}}>{CommonUtil.getMessageText(Constants.TAX_EXEMPTION_ACCEPT,"You must accept the vHub Tax Exemptions")}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 shadow-sm p-2 border border-danger" style={{display:"none"}}>{CommonUtil.getMessageText(Constants.WILL_BE_CHARGED,"You will be charged for each excess day that the trailer is late.")}</div>
                                    </div>

                                </div>

                            </section>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-warning waves-effect" onClick={onCloseEquipAvailPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Close")}</button>
                        {(CommonUtil.IS_GENERIC_EQUIP  && CommonUtil.EQUIP_AVAILABILITY_STATUS != CommonUtil.EQUIP_AVAILABILITY_STATUS_COMPLETED) ?
                            <div>
                                <button type="submit" className="btn btn-warning waves-effect waves-light"
                                        onClick={onClickDeleteAvailability}
                                        style={{display: (CommonUtil.SELECT_EQUIP_AVAIL_ID == "" ? "none" : "")}}>{CommonUtil.getMessageText(Constants.DELETE,"Delete")}
                                </button>
                            </div>:""
                        }
                        {(CommonUtil.IS_GENERIC_EQUIP && CommonUtil.EQUIP_AVAILABILITY_STATUS != "")? <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickSaveAvailability}>
                            {CommonUtil.SELECT_EQUIP_AVAIL_ID?CommonUtil.getMessageText(Constants.EDIT,"Update"):CommonUtil.getMessageText(Constants.CREATE,"Create")}</button>:""}


                    </div>
                </div>
            </Modal>
            <div className="container-fluid container-fluid-padding" style={{ paddingTop: '1px' }}>
                <div className="row">
                    <div className="col-md-3 col-12 pt-2"
                        style={{ paddingLeft: '5px', zIndex: 990, display: CommonUtil.isRootUser() ? "" : "none" }}>
                        <div className="ml-8">
                            <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY,"Company")}</span>
                            <SingleSelectComp dataSource={orgData} selectedValue={selCompanyObj}
                                width={'100%'} handleChange={onCompanyChange}
                                value={'orgId'} label={'orgName'} />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 pt-2" style={{ zIndex: '990' }}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type")}</span>
                        <SingleSelectComp dataSource={assetTypes} selectedValue={selAssetObj}
                            width={'100%'} handleChange={onAssetTypeChange}
                            value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-3 col-6 pt-2" style={{ zIndex: '990' }}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type")}</span>
                        <SingleSelectComp dataSource={equipTypes} selectedValue={selEquipObj}
                            width={'100%'} handleChange={onEquipmentTypeChange}
                            value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-3 col-lg-1">
                        <div className="col-md-1" style={{ paddingTop: '25px' }}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height" onClick={getAvailabilityList}>
                                {CommonUtil.getMessageText(Constants.SEARCH,"Search")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`row ${isMobile ? '' : 'pt-3'}`}>
                    {
                        isMobile ? (
                            <MGrid id="OrgEquipAvailGrid" cols={eqpColumnDefs}
                                rows={equipData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                ref={gridRef} addNew={false} method={{ onClickLink, onClickLinkDetails }} />
                        ) : (
                            <div className="ag-theme-alpine gridHeight"
                                style={{ height: 'calc(100vh - 180px)', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                                <AGGrid id="OrgEquipAvailGrid" cols={eqpColumnDefs}
                                    rows={equipData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                    ref={gridRef} addNew={false} method={{ onClickLink, onClickLinkDetails }} />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default AvailabilityReport;
