
import React, { useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Inspection.css'
import MaskGroup20 from './MaskGroup20.svg';
import MaskGroup21 from './MaskGroup21.svg';
import MaskGroup22 from './MaskGroup22.svg';
import MaskGroup23 from './MaskGroup23.svg';
import Card from 'react-bootstrap/Card';
import DataTable from "react-data-table-component";
import { useState,useEffect } from 'react';
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { Button,Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import reactSelect from 'react-select';
import Select, { components } from 'react-select'
import AssignedOrganizationsGrid from './AssignedOrganizationsGrid';
import deleteAssignedOrganization from '../InspectionGrid/InspectionDeleteRenderer';
import {Constants} from "../Languages/Constants";
import useStateWithCallback from 'use-state-with-callback';
import { IAGrid } from '../../Components/IInterfaces';
import { data } from 'jquery';
import ToastManager from '../../Components/ToastManager';
import Confirm from '../../Components/confirm';
import InspectionDeleteRenderer from '../InspectionGrid/InspectionDeleteRenderer';


const AssignedOrganization = () => {


  const [users,setUsers] = useStateWithCallback([],()=>{
    if(userGridRef1.current){
        userGridRef1.current.columnFittoSize();
    }
});
 

const [optionsData,setOptionsData] = useState<any[]>([])
const [selectedValue,setSelectedValue] = useState("");
const [isClearable, setIsClearable] = useState(true);
const [isSearchable, setIsSearchable] = useState(true);
const [isDisabled, setIsDisabled] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [isRtl, setIsRtl] = useState(false);
const [assOrgId,setAssOrgId] = useState<any>(null);
const [selected, setSelected] = useState("");

const[buttonEnable,setButtonEnable] = useState(false);



const inputRef = useRef<any>(null);


const userGridRef1 = useRef<IAGrid>(null);




const [userColumns] = useState([

  { field: 'orgName', headerName:CommonUtil.getMessageText(Constants.ORGANISATION,'Organizations'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'2px'},headerClass: 'header-white'},
  { field: 'createdBy', headerName:CommonUtil.getMessageText(Constants.ASSIGNED_BY,'Assigned By'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'2px' },headerClass: 'header-white'},
  { field: 'creationDate', headerName:CommonUtil.getMessageText(Constants.ASSIGNED_DATE,'Assigned Date'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'2px' },headerClass: 'header-white'},
  { field: 'actions', headerName:CommonUtil.getMessageText(Constants.ACTIONS,'Actions'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'2px' },headerClass: 'header-white',cellRenderer: deleteAssignedOrganization},
]);

let getAllOrganizations = ()=>{
  var orgUrl = "api/organization/allOwnerOrganizations"
  NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetAllOrgs,onLoginError);
}




let onClickAddOrganization = ()=>{

  
  var restObj:any = {};

  restObj.ownerOrgId = selected
  restObj.saasOrgId = CommonUtil.getInspectionSaasOrgId();
  restObj.associationStatus = "ACTIVE";

  var userUrl = "api/ownerInspectionOrgAssociation/";
  var method = "POST";

  NetworkManager.sendJsonRequest(userUrl,restObj,"",method,true,onAssignedOrgCreate,onLoginError);
  console.log(restObj)

}



let onAssignedOrgCreate = (data:any)=>{
  if (data) {
    var restData = CommonUtil.parseData(data);
    console.log(restData)
    if (restData) {
        let strSuccess = CommonUtil.getMessageText(Constants.ORGANIZATION_ADDED_SUCCESSFULLY,"Organization Created Successfully");
        ToastManager.showToast(strSuccess, "Info");
        getAllUsers();
    }else{
      ToastManager.showToast("Error","Please select Organization");
    }
}
}


let getAllUsers = ()=>{
  setUsers([]);
  var qParam = CommonUtil.getInspectionSaasOrgId();
  var orgUrl = "api/ownerInspectionOrgAssociation/associatedOrgs?inspectionOrgId="+qParam
  NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
}


useEffect(()=>{
  getAllUsers();
},[])

let onGetAllOrgs = (data: any) => {
  if (data) {
      var restData = CommonUtil.parseData(data);
      if (restData.organizations) {
        setOptionsData(restData.organizations.map((d:any)=>{
          return {value:d.orgName,label:d.orgName,id:d.orgId}
        }))
      }
  }
}

let onOrgGetAllUsers = (dataObj:any)=>{
  console.log(dataObj);
  if(dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData)
      if(restData){
          restData.map((org:any)=>{
              console.log(org.inspectionOrgId)
          })
      }
      setUsers(restData)
      if(restData){
          var users = [];
          if(Array.isArray(restData)){
              users = restData;
          }else{
              users.push(restData); 
          }
          setUsers(restData)    
          console.log(users)    
      }

}
  

}

let removeOrganization = (data:any)=>{
  console.log(data)
  Confirm.show('Confirm', 'Do you want delete Assigned Organization?', [{label: 'Yes', callback: function () {
    let qParam = data.assocId
    let removeApi = "api/ownerInspectionOrgAssociation/?assocId="+qParam
    NetworkManager.sendJsonRequest(removeApi, '', '', 'DELETE', true, onDeleteAssignedOrgs, onLoginError);
  
  },
  },
  {label: 'No'},
  ])
}


let onDeleteAssignedOrgs = (dataObj:any)=>{
  console.log(dataObj)
  if(dataObj == "Deleted"){
    let strRemove = CommonUtil.getMessageText(Constants.REMOVED_SUCCESS,'Removed Successfully');
    ToastManager.showToast(strRemove, 'Info');
  }else{
    ToastManager.showToast("Error", 'Error')
  }
  getAllUsers();
  
}


let onhandleChange = (dataObj:any)=>{
  console.log(dataObj)
  if(dataObj){
    setButtonEnable(true)
    setSelected(dataObj.id)
  }else{
    setSelected("");
    setButtonEnable(false);
  }
}

console.log(selected)


let onLoginError = (err:any)=>{
  console.log(err);
}
useEffect(()=>{
  getAllOrganizations();
},[])
    
  return (
    <div  className="container-fluid container-fluid-padding">
                <div className="row fs-7 pt-1">
                        <div className="ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 200px)", width: "99%"}}>
                            <AssignedOrganizationsGrid id="CustOrgUserGrid"  cols={userColumns} rows={users} pageSize={500} addNew={true} tooltip={"Add Organization"} name={"Add Organization"}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} addNewRecord={onClickAddOrganization} method={{removeOrganization}} placeholder="Select Organization" defaultValue={optionsData[0]} dropdown = {optionsData} onSelectChange = {onhandleChange}/>
                        </div>
                </div>
    </div>
  )
}

export default AssignedOrganization