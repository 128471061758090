import React, { useEffect, useRef, useState } from 'react';
import ParkingLotList from './ParkingLotList/ParkingLotList'
import { NetworkManager } from "../../../Components/NetworkManager";
import { loadMapApi } from "../../../utils/GoogleMapsUtils";
import { CommonUtil } from "../../../utils/CommonUtil";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import './parking.css'
import PhoneInput from "react-phone-input-2";
import ToastManager from "../../../Components/ToastManager";
import SingleSelectComp from "../../../Components/SingleSelectComp";
import { subscribe } from "../../Login/CustomEvents";
import { Events } from "../../Languages/Events";
import DraggableComponent from "../../../Components/DraggableComponent";
import { Constants } from '../../Languages/Constants';
import $ from 'jquery';
import CDTextarea from '../../Equipments/CDTextarea';

var selOrgKey: string = "";
var defCurrencyObj = { Key: '', Value: '' };
var selOrgId:string = "";
const ParkingLot = () => {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [dailyRate, setDailyRate] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [payToPark, setPayToPark] = useState<any>('N');
    const [twicRequire, seTwicRequire] = useState<any>('N');
    const [dummyLot, setDummyLot] = useState<any>('N');
    const [disLength] = useState(1000);
    const [hourlyRate, setHourlyRate] = useState<any>('');
    const [instructions, setInstructions] = useState<any>('');
    const [is24hoursAccessAvailable, setIs24hoursAccessAvailable] = useState<any>('Y');
    const [monthlyRate, setMonthlyRate] = useState<any>('');
    const [parkingLotName, setParkingLotName] = useState<any>('');
    const [parkingLotCodeName, setParkingLotCodeName] = useState<any>('');
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [contactName, setContactName] = useState<any>('');
    const [status, setStatus] = useState<any>('Active');
    const [weeklyRate, setWeeklyRate] = useState<any>('');
    const [address, setAddress] = useState<any>('');
    const [city, setCity] = useState<any>('');
    const [country, setCountry] = useState<any>('');
    const [currencies, setCurrencies] = useState(CommonUtil.currencyList);
    const [currency, setCurrency] = useState<any>("");
    const [latitude, setLatitude] = useState<any>('');
    const [longitude, setLongitude] = useState<any>('');
    const [postalCode, setPostalCode] = useState<any>('');
    const [rawAddress, setRawAddress] = useState<any>('');
    const [state, setState] = useState<any>('');
    const [timeZone, setTimeZone] = useState<any>('');
    const [utcOffsetMinutes, setUtcOffsetMinutes] = useState<any>('');
    const [mode, setMode] = useState<any>('new');
    const [parkingList, setParkingList] = useState<any>([]);
    const [address1, setAddress1] = useState('');
    const [poiCapture, setPoiCapture] = useState<any>('');
    const [parkingLotId, setParkingLotId] = useState<any>('')
    const [isCompanyVisible, setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [orgData, setOrgData] = useState(null)
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null);
    const [selLotCompanyObj, setSelLotCompanyObj] = useState<any>(null)
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const lotNameRef = useRef<HTMLInputElement>(null);
    const companyRef = useRef<HTMLInputElement>(null);
    const parkingLotCodeNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const poicaptureRef = useRef<HTMLInputElement>(null);
    const addressRef = useRef<HTMLInputElement>(null);
    const hourRef = useRef<HTMLInputElement>(null);
    const dailyRef = useRef<HTMLInputElement>(null);
    const weekRef = useRef<HTMLInputElement>(null);
    const monthRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const contactRef = useRef<HTMLInputElement>(null);
    const [page, setPage] = useState("");


    const handleShow = () => {
        setModalShow(true);
    }
    let onGetParkingLot = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error');
                } else {
                    setMode('edit');
                    setParkingLotName(restData.parkingLotName?restData.parkingLotName:"");
                    setParkingLotCodeName(restData.parkingLotCodeName?restData.parkingLotCodeName:"");
                    setStatus(restData.status?restData.status:"");
                    setAddress1(restData.address?restData.address:"");
                    setPoiCapture(restData.poiCapture?restData.poiCapture:"");
                    setEmail(restData.email?restData.email:"");
                    setHourlyRate(restData.hourlyRate?restData.hourlyRate:"");
                    setDailyRate(restData.dailyRate?restData.dailyRate:"");
                    setMonthlyRate(restData.monthlyRate?restData.monthlyRate:"");
                    setWeeklyRate(restData.weeklyRate?restData.weeklyRate:"");
                    setPhoneNumber(restData.phoneNumber?restData.phoneNumber:"");
                    setCurrency(restData.currency?restData.currency:"");
                    setContactName(restData.contactName?restData.contactName:"");
                    setInstructions(restData.instructions?restData.instructions:"");
                    setPayToPark(restData.payToPark?restData.payToPark:"N");
                    seTwicRequire(restData.twicAccessRequired?restData.twicAccessRequired:"N")
                    setDummyLot(restData.isDummy?restData.isDummy:"N")
                    setIs24hoursAccessAvailable(restData.is24hoursAccessAvailable);
                    setAddress(restData.address?restData.address:"");
                    setCity(restData.city?restData.city:"");
                    setCountry(restData.country?restData.country:"");
                    setLatitude(restData.latitude?restData.latitude:"");
                    setLongitude(restData.longitude?restData.longitude:"");
                    setPostalCode(restData.postalCode?restData.postalCode:"")
                    setRawAddress(restData.rawAddress?restData.rawAddress:"")
                    setState(restData.state?restData.state:"")
                    setTimeZone(restData.timeZone);
                    setUtcOffsetMinutes(restData.utcOffsetMinutes)
                }
            }
        }
    }

    const onClickEditParking = (data: any) => {
        handleShow();
        setMode('edit')
        let orgUrl = "api/parkinglot/" + data?.parkingLotId;
        setParkingLotId(data?.parkingLotId)
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetParkingLot, onLoginError);
    }

    const handleClose = () => {
        setModalShow(false);
        setTimeout(() => {
            resetStates();
        }, 250)
    }

    const onGetAllParkingLots = (data: any) => {
        if (data) {
            let restData = CommonUtil.parseData(data);
            if (restData) {
                let lots = [];
                if (Array.isArray(restData.parkingLots)) {
                    lots = restData.parkingLots;
                } else {
                    lots.push(restData.parkingLots);
                }
                /* if (restData.statusCode) {
                     console.log('error');
                 } else {
                     setParkingList(restData);
                 }*/
                setParkingList(lots);
            }
        }
    }

    const getAllParkingLots = () => {
        if (CommonUtil.isRootUser()) {
            if(selOrgKey){
                let qParam = selOrgKey;//CommonUtil.getSelectedOrgId();
                let orgUrl = "api/parkinglot/all?saasOrgId=" + qParam;
                NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllParkingLots, onLoginError);
            }

        } else {
            let qParam = CommonUtil.getSelectedOrgId();
            let orgUrl = "api/parkinglot/all?saasOrgId=" + qParam;
            NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllParkingLots, onLoginError);
        }

    }

    const onLoginError = (err: any) => {
        console.log(err);
    }

    const onSuccess = (dataObj:any) => {
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast(mode === 'edit' ? 'Parking lot updated successfully' : 'Parking lot created successfully', 'Success')
                getAllParkingLots();
                setMode('new');
                handleClose();
            }
        }

    }
    const resetStates = () => {
        //selOrgId = "";
        setDailyRate('');
        setEmail("");
        setHourlyRate("");
        setInstructions("");
        setPayToPark("N");
        seTwicRequire("N");
        setDummyLot("N");
        setIs24hoursAccessAvailable("Y");
        setMonthlyRate("");
        setParkingLotName("");
        setParkingLotCodeName("");
        setPhoneNumber("");
        setContactName("");
        setCurrency("");
        setStatus("Active");
        setWeeklyRate("");
        setAddress("");
        setCity("");
        setCountry("");
        setLatitude("");
        setLongitude("");
        setPostalCode("");
        setRawAddress("");
        setState("");
        setTimeZone("");
        setUtcOffsetMinutes("330");
        setMode('new')
        setAddress1('');
        setPoiCapture('');
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        let isLotName: boolean = CommonUtil.isMandatory(lotNameRef)
        //let isCompName: boolean = CommonUtil.isMandatory(companyRef)
        let isparkLotCodeName: boolean = CommonUtil.isMandatory(parkingLotCodeNameRef)
        let isEmail: boolean = true;
        let isHour: boolean = true;
        let isDaily: boolean = true;
        let isWeek: boolean = true;
        let isMonth: boolean = true;
        let isAddress: boolean = CommonUtil.isMandatory(addressRef);
        //let isPOICapture: boolean = CommonUtil.isMandatory(poicaptureRef);
        let isPhone: boolean = true;
        if (payToPark === 'Y') {
            isHour = CommonUtil.isMandatory(hourRef)
            isDaily = CommonUtil.isMandatory(dailyRef)
            isWeek = CommonUtil.isMandatory(weekRef)
            isMonth = CommonUtil.isMandatory(monthRef)
        }
        if (!longitude?.toString()?.trim() || !latitude?.toString()?.trim()) {
            ToastManager.showToast('Invalid location', 'Error');
            return;
        }
        // if (!phoneNumber.trim() || phoneNumber.length < 11) {
        //     isPhone = false
        //     setPhoneClass('is-invalid')
        // }
        if (email.length > 0) {
            isEmail = CommonUtil.isValidEmail(email);
        }
        //"isDummy": "N",
        var twicAccessRequired = twicRequire;
        if (isLotName && isparkLotCodeName && isEmail && isHour && isDaily && isWeek && isMonth && isAddress && isPhone) {
            let orgUrl = "api/parkinglot/";
            let newData = {
                dailyRate,
                email,
                hourlyRate,
                instructions,
                poiCapture,
                payToPark,
                is24hoursAccessAvailable,
                currency,
                monthlyRate,
                twicAccessRequired,
                isDummy:dummyLot,
                parkingLotName: parkingLotName.trimStart().trimEnd(),
                parkingLotCodeName,
                phoneNumber,
                contactName,
                status,
                weeklyRate,
                address,
                city,
                country,
                latitude,
                longitude,
                postalCode,
                rawAddress,
                state,
                timeZone,
                utcOffsetMinutes
            };
            if(selOrgId){
                if (mode === 'new') {
                    orgUrl = "api/parkinglot/?saasOrgId=" + selOrgId;
                    NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", true, onSuccess, onLoginError);
                }else if (mode === 'edit') {
                    orgUrl = orgUrl + parkingLotId + "?saasOrgId=" + selOrgId;//CommonUtil.getSelectedOrgId();
                    NetworkManager.sendJsonRequest(orgUrl, newData, "", "PUT", true, onSuccess, onLoginError);
                }
            }

            //setSelCompanyObj(null);
           // handleClose();
        }
    }
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e)
        setPhoneClass('mandatory')
    }
    const handleInputChange24 = (event: any) => {
        setIs24hoursAccessAvailable(is24hoursAccessAvailable === 'N' ? 'Y' : 'N')
    }
    const handleContactName = (e: any) => {
        setContactName(e.target.value)
    }
    const handlepayToPark = (e: any) => {
        setPayToPark(payToPark === 'N' ? 'Y' : "N");
        if (payToPark === 'Y') {
            setHourlyRate('')
            setDailyRate('')
            setWeeklyRate('')
            setMonthlyRate('')
        }
    }

    let handleTwicRequire = (e:any)=>{
        seTwicRequire(twicRequire === 'N' ? 'Y' : "N");
        console.log(twicRequire);
    }

    let handleDummyLot = (e:any)=>{
        setDummyLot(dummyLot === 'N' ? 'Y' : "N");
        console.log(dummyLot);
    }


    // let onCurrencyChange = (data: any) => {
    //     setCurrency(data);
    // }
    let onChangeAddress = (e: any) => {
        setLatitude('');
        setLongitude('');
        setAddress1(e.currentTarget.value);

        CommonUtil.isMandatory(addressRef);
    }


    let handleInputStatus = (e: any) => {
        setStatus(e.target.value)
    }
    let handleParkingLotName = (e: any) => {
        setParkingLotName(e.target.value)
        CommonUtil.isMandatory(lotNameRef);
    }
    let handleParkingLotCodeName = (e: any) => {
        setParkingLotCodeName(e.target.value)
        CommonUtil.isMandatory(parkingLotCodeNameRef);
    }
    let handleInputEmail = (e: any) => {
        setEmail(e.target.value)
        let flag: boolean = CommonUtil.isValidEmail(e.target.value);
        if (flag) {
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        } else {
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }
    }
    let handleInputHourRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: hourRef, setState: setHourlyRate })
    }
    let handleInputDailyRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: dailyRef, setState: setDailyRate })
    }
    let handleInputWeekRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: weekRef, setState: setWeeklyRate })
    }
    let handleInputMonthRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: monthRef, setState: setMonthlyRate })
    }
    let handleInputInstruction = (e: any) => {
        setInstructions(e.target.value)
    }
    let onCompanyChange = (dataObj: any) => {
       // CommonUtil.isMandatory(companyRef);
        if (dataObj) {
            //localStorage.setItem("orgId", dataObj.orgId);
            selOrgKey = dataObj.orgId;
            selOrgId = dataObj.orgId;
            setSelCompanyObj(dataObj);
            setSelLotCompanyObj(dataObj);
            getParkingList();
        } else {
            selOrgId = "";
            setSelCompanyObj(null);
            setSelLotCompanyObj(null);
            setParkingList([]);
        }
    }

    let onCompanyLotChange = (dataObj: any)=>{
        if (dataObj) {
            //localStorage.setItem("orgId", dataObj.orgId);
           // selOrgKey = dataObj.orgId;
            selOrgId = dataObj.orgId;
            setSelLotCompanyObj(dataObj);
           // getParkingList();
        } else {
            selOrgId = "";
            setSelLotCompanyObj(null);
            //setParkingList([]);
        }
    }
    useEffect(() => {
        selOrgKey = "";
        seTwicRequire('N');
        setDummyLot("N");
        getAllParkingLots();
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {//Trailer Hub Provider
                const filterData = data.filter((org: any) => org?.organizationType === CommonUtil.ORG_TYPE_OWNER || org?.organizationType === CommonUtil.ORG_TYPE_OWNER_RENTER ||  org?.organizationType === CommonUtil.TRAILER_HUB_PROVIDER);
                setOrgData(filterData)
            })
        }else{
            selOrgId = CommonUtil.getSelectedOrgId();
        }
    }, [])

    let onSwitchCustomer = () => {
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        setCompanyVisible(CommonUtil.isRootUser());
        setParkingList([]);
        if (CommonUtil.isRootUser()) {
            setSelCompanyObj(null);
            setPage("" + Math.random() * 10000);
        } else {
            getAllParkingLots();
        }

    }

    useEffect(() => {
        // subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        loadGooglePlaces();
    }, [modalShow]);

    let loadGooglePlaces = () => {

        if (window.google) {
            getGooglePlaces();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    getGooglePlaces();
                })
            }
        }
    }
    let getGooglePlaces = () => {
        const input = document.getElementById('address') as HTMLInputElement
        const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: ['us', 'ca', 'in'] }
        })
        autocomplete.addListener('place_changed', () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete);
            if (addr) {
                const typedPOI = $('#address').val();
                setPoiCapture(typedPOI);
                let str: any = typedPOI?.toString();
                if (str.includes("USA")) {
                    setCurrency(CommonUtil.US_CURRENCY);
                }
                if (str.includes("Canada")) {
                    setCurrency(CommonUtil.CANADA_CURRENCY);
                }

                setTimeout(() => {
                    setAddress(addr['rawAddress'])
                    setAddress1(addr['rawAddress'])
                    setCity(addr['city'])
                    setCountry(addr['country'])
                    setLatitude(addr['lat'])
                    setLongitude(addr['lng'])
                    setPostalCode(addr['postalcode'])
                    setRawAddress(addr['rawAddress'])
                    setState(addr['state'])
                    setTimeZone('');
                    setUtcOffsetMinutes('330')
                    CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    })
                })
            }
        })
    }
    let onSuccessParkingGet = (data: any) => {
        if (data) {
            let restData = CommonUtil.parseData(data)
            if (restData) {
                let parkings = []
                if (Array.isArray(restData.parkingLots)) {
                    parkings = restData.parkingLots
                } else {
                    parkings.push(restData.parkingLots)
                }
                setParkingList(parkings)
            }
        }
    }
    let getParkingList = () => {
        let qParams: string = "";
        if (CommonUtil.isRootUser()) {
            qParams = 'saasOrgId=' + selOrgKey;
        } else {
            qParams = 'saasOrgId=' + CommonUtil.getSelectedOrgId();

        }
        let parkingAllAPI: string = 'api/parkinglot/all?' + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(parkingAllAPI, '', '', 'GET', true, onSuccessParkingGet, onLoginError);
//onHide={() => { setMode('new') }}

    }
   
    return (
        <>
            <Modal show={modalShow} centered dialogAs={DraggableComponent} >
                <div className="modal-content" style={{ "width": "600px", height: "640px" }}>
                    <Modal.Header className="modal-header" closeButton={false}>
                        <h5 className="modal-title mt-0">{mode === 'new' ? CommonUtil.getMessageText(Constants.CREATE_PARKING, 'Create Parking Lot') : CommonUtil.getMessageText(Constants.UPDATE_PARKING, 'Edit Parking Lot')}</h5>
                        <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>

                    <div className="modal-body" style={{ overflowY: "auto" }}>
                        <div className="row">
                            <div className="col-md-12 col-12 pb-3" style={{ display: (isCompanyVisible ? "" : "none") }}>
                                <div className="col-md-12 col-12 pt-1">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COMPANY, 'Company')} <span className="text-danger"></span></label>
                                    <SingleSelectComp
                                        ref={companyRef}
                                        dataSource={orgData}
                                        selectedValue={selLotCompanyObj}
                                        width={'100%'}
                                        handleChange={onCompanyLotChange}
                                        value={'orgId'} label={'orgName'}
                                        disabled={mode == "edit"?true:false}
                                    />

                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" autoFocus={true} name="parkingLotName"
                                            className="form-control clsFormInputControl" ref={lotNameRef}
                                            placeholder={CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} value={parkingLotName}
                                            onChange={handleParkingLotName}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.STATUS, 'Status')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <select name="status" className={'form-control'} onChange={handleInputStatus} value={status}>
                                            <option value="Active">Active</option>
                                            <option value="InActive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.PARKING_LOT_CODE_NAME, 'Parking Lot Code Name')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" ref={parkingLotCodeNameRef} name="parkingLotCodeName"
                                            className="form-control clsFormInputControl" placeholder="Parking Lot Code Name"
                                            maxLength={12}
                                            aria-label="parkingLotCodeName" value={parkingLotCodeName} onChange={(e) => handleParkingLotCodeName(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.LOCATION, 'Location')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" id={'address'} ref={addressRef}
                                            className="form-control googleAddress clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.LOCATION, 'Location')}
                                            aria-label="Address1"
                                            value={address1}
                                            onChange={(e) => onChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.POINT_OF_INTEREST, 'Point of Interest')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" ref={poicaptureRef}
                                            className="form-control googleAddress clsFormInputControl" placeholder='POI'
                                            aria-label="POICapture" value={poiCapture} readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}</label>
                                    <div className="col-sm-12">
                                        <input className="form-control" ref={emailRef}
                                            placeholder="Email (Optional)" aria-label="Email"
                                            type="email" name="email" value={email}
                                            onChange={handleInputEmail}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row pr-0">
                                <div className="col-md-5 col-sm-12 pt-4" style={{display:""}}>
                                    <Form.Check
                                        type="switch"
                                        name={'dummyLot'}
                                        label="Dummy Parking Lot"
                                        checked={dummyLot === 'Y' ? true : false}
                                        // defaultChecked={false}
                                        value={dummyLot === "Y" ? 'Y' : "N"}
                                        onChange={handleDummyLot}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12 pt-4">
                                    <Form.Check
                                        type="switch"
                                        name={'twicRequire'}
                                        label={CommonUtil.getMessageText(Constants.TWIC_REQUIRE, 'TWIC Required')}
                                        checked={twicRequire === 'Y' ? true : false}
                                        // defaultChecked={false}
                                        value={twicRequire === "Y" ? 'Y' : "N"}
                                        onChange={handleTwicRequire}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 pt-2" style={{display:(twicRequire == 'Y'?"":"none")}}>
                                    <img src="../../images/twic.png" className="mx-2" style={{width:"50px"}} />
                                </div>
                            </div>
                            <div className="row pr-0">
                                <div className="col-md-5 col-sm-12 pt-4">
                                    <Form.Check
                                        type="switch"
                                        name={'payToPark'}
                                        label={CommonUtil.getMessageText(Constants.PAY_TO_PARK, 'Pay to Park')}
                                        checked={payToPark === 'Y' ? true : false}
                                        // defaultChecked={false}
                                        value={payToPark === "Y" ? 'Y' : "N"}
                                        onChange={handlepayToPark}
                                    />
                                </div>
                                <div className="col-md-3  pt-4">
                                    <Form.Check
                                        type="switch"
                                        name={'is24hoursAccessAvailable'}
                                        //  label={CommonUtil.getMessageText(Constants.HOURS_ACCESS_AVAILABLE, "24 hours")}
                                        label={CommonUtil.getMessageText(Constants.HOURS_24, '24 hours')}
                                        checked={is24hoursAccessAvailable === 'N' ? false : true}
                                        // defaultChecked={false}
                                        value={is24hoursAccessAvailable === "N" ? 'N' : "Y"}
                                        onChange={handleInputChange24}
                                    />
                                </div>
                                <div className="col-md-2  pt-4 col-form-label">
                                    <label>{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                                </div>
                                <div className="col-md-2 col-sm-12 pt-4 p-0">
                                    <input type="text"
                                        className="form-control"
                                        disabled value={currency} />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.HOURLY_RATE, 'Hourly Rate')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} maxLength={10}
                                            placeholder="0" ref={hourRef}
                                            type="text" name="hourlyRate" value={hourlyRate}
                                            onChange={handleInputHourRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                            disabled={payToPark === 'N'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.DAILY_RATE, 'Daily Rate')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{ zIndex: 9 }}>
                                        <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                            name="dailyRate" ref={dailyRef} maxLength={10}
                                            value={dailyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                            onChange={handleInputDailyRate}
                                            disabled={payToPark === 'N'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.WEEKLY_RATE, 'Weekly Rate')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{ zIndex: 9 }}>
                                        <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                            name="weeklyRate" ref={weekRef} maxLength={10}
                                            value={weeklyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                            onChange={handleInputWeekRate}
                                            disabled={payToPark === 'N'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.MONTHLY_RATE, 'Monthly Rate')}  <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{ zIndex: 9 }}>
                                        <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="0" type="text"
                                            name="monthlyRate" ref={monthRef} maxLength={10}
                                            value={monthlyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                            onChange={handleInputMonthRate}
                                            disabled={payToPark === 'N'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.CONTACT_NO, 'Contact Number')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{ zIndex: 9 }}>
                                        {/*<input className={'form-control'} placeholder="Contact Number" type="number"*/}
                                        {/*       name="phoneNumber"*/}
                                        {/*       value={phoneNumber} onChange={handleInputChange}/>*/}
                                        <PhoneInput
                                            country={'us'}
                                            countryCodeEditable={false}
                                            value={phoneNumber}
                                            containerClass={phoneClass}
                                            placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, 'Enter Mobile Number')}
                                            inputClass={`input-phone ${phoneClass}`}
                                            onChange={(e) => {
                                                handleInputPhone(e)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            disableCountryGuess={true}
                                            disableDropdown={true}
                                            disableSearchIcon={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.CONTACT_NAME, 'Contact Name')}
                                </label>
                                <input className="form-control" placeholder="Contact Name" type="text"
                                    name="contactName" ref={contactRef}
                                    value={contactName}
                                    onChange={handleContactName}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-12" style={{ paddingLeft: "0px" }}>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.ACCESS_INSTRUCTION_OPTIONAL, 'Access Instructions (Optional)')}
                                </label>
                                <div className="col-sm-12">
                                    {/* <textarea className="form-control" style={{ height: "100px" }}
                                        placeholder="Access Instruction (Optional)"
                                        name="instructions" value={instructions}
                                        onChange={handleInputInstruction}
                                    /> */}
                                     <CDTextarea
                                        maxLength={disLength}
                                        //ref={descRef}
                                        value={instructions}
                                        placeholder=""
                                        rows={4}
                                        onChange={handleInputInstruction}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark"
                            data-dismiss="modal" onClick={handleClose}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success"
                            onClick={handleSubmit}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>
            </Modal>

            <div className={'container-fluid px-3'} >
                <div className="row" style={{ display: (isCompanyVisible ? "" : "none") }}>
                    <div className="col-md-3 col-12 pt-2" style={{ paddingLeft: '5px', zIndex: 999 }}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY, 'Company')}</span>
                        <SingleSelectComp
                            dataSource={orgData}
                            selectedValue={selCompanyObj}
                            width={'100%'}
                            handleChange={onCompanyChange}
                            value={'orgId'} label={'orgName'}
                        />

                    </div>

                    <div className="col-md-12 col-lg-1 row" style={{ display: "none" }}>
                        <div className="col-md-1" style={{ paddingTop: '22px' }}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                                onClick={getParkingList}>
                                {CommonUtil.getMessageText(Constants.SEARCH, 'Search')}
                            </button>
                        </div>
                    </div>

                </div>

                <ParkingLotList
                    onClickEditParking={onClickEditParking}
                    // setParking={setParking}
                    // setAddress={setAddress}
                    setAddress1={setAddress1}
                    handleShow={handleShow}
                    parkingList={parkingList}
                />
            </div>
            {/*<ParkingLotModal*/}
            {/*    show={modalShow}*/}
            {/*    onHide={handleClose}*/}
            {/*    mode={mode}*/}
            {/*    parking={parking}*/}
            {/*    getAllParkingLots={getAllParkingLots}*/}
            {/*/>*/}
        </>


    );
}

export default ParkingLot;
