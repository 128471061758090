import React, {useEffect, useRef, useState} from 'react';
import {CommonUtil} from "../../utils/CommonUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {NetworkManager} from "../../Components/NetworkManager";
import OffersList from './OffersList';
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import ReservationList from "./ReservationList";
import AGGrid from "../../Components/AGGrid";
import Flatpickr from "react-flatpickr";
import ReservationEquipCellRenderer from "../Organization/ReservationEquipCellRenderer";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import ReservationDetailsCellRenderer from "../Organization/ReservationDetailsCellRenderer";
import {data} from "jquery";
import {subscribe} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import FromTo from './FromTo';
import DetectDevice from '../../utils/DetectDevice';
import MGrid from '../../Components/MGrid';
import {Constants} from "../Languages/Constants";
import $ from 'jquery'

var defObj = {key:'',value:'All'};


let isUpcoming:boolean = true;
let isProgress:boolean = true;
let isActivationDue:boolean = false;
let isDeActivationDue:boolean = false;
let isLateActivationDue:boolean = false;
let isLateDeActivationDue:boolean = false;
let isDeleted:boolean = false;
let isDone:boolean = false;


const ReservationReport = () => {
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState<any>(null)
    const [isCompanyVisible, setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [selCompanyObj, setSelCompanyObj] = useState<any>({orgId:null,orgName:"All"})
    const [reservationList, setReservationList] = useState<any>([])
    const [modalShow, setModalShow] = useState<boolean>(false);

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null);

    const [reserveObj,setReservationObj] = useState<any>(defObj);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(CommonUtil.DATE_FORMAT);
    const [dates,setDates] = useState(CommonUtil.CUSTOM_DATES);
    const [dateObj,setDateObj] = useState<any>(null);
    const [page,setPage] = useState("");

    const [reservationCode,setReservationCode] = useState("");



    const [reservationsColumns] = useState([
        {
            field: 'reservationStatus',
            headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'},
        {
            field: 'reservationCode',
            headerName: CommonUtil.getMessageText(Constants.RESERVATION_ID,"Reservation Id"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer:ReservationDetailsCellRenderer
        },
        {
            field: 'UnitEquipName',
            headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"),
            resizable: true,
            width: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: EquipCellRenderer
        },
        {
            field: 'equipmentType',
            headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'brand',
            headerName: CommonUtil.getMessageText(Constants.BRAND,"Brand"),
            resizable: true,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'reservationType',
            headerName: CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'VIN',
            headerName: CommonUtil.getMessageText(Constants.VIN,"VIN"),
            resizable: true,
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'driverName',
            headerName: CommonUtil.getMessageText(Constants.DRIVER,"Driver"),
            resizable: true,

            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'renterName',
            headerName: CommonUtil.getMessageText(Constants.RESERVATIONS_MADE_BY,"Reservation made by"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter'},
        {
            field: 'scheduledStartDate',
            headerName: CommonUtil.getMessageText(Constants.RESERVATIONS_START_DATE,"Reservation Start Date"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter',

        },
        {
            field: 'scheduledEndDate',
            headerName: CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation End Date"),
            resizable: true,
            width: 200,
            sortable: true,
            filter: 'agTextColumnFilter',

        },
        { field: 'isGeneric', headerName: CommonUtil.getMessageText(Constants.IS_GENERIC,"Is Generic"), resizable: true, width: 250, sortable: true, hide: true, filter: 'agNumberColumnFilter' },
        { field: 'policyNameLiability', headerName: CommonUtil.getMessageText(Constants.LIABILITY_PLAN,"Liability Plan"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'insurancePerDayAmountLiability', headerName: CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN_PER_DAY,"Liability Insurance Per Day"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'liabilityPaidBy', headerName: CommonUtil.getMessageText(Constants.LIABILITY_PLAN_PAID_BY,"Liability Insurance Paid By"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'policyNamePhysical', headerName: CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN,"Physical Damage Plan"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'insurancePerDayAmountPhysical', headerName: CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN_PER_DAY,"Damage Insurance Per Day"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'damageInsurancePaidBy', headerName: CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN_PAID_BY,"Damage Insurance Paid By"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },



    ])

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }
    let onStatusChange = (dataObj:any)=>{
        if(dataObj){
            setReservationObj(dataObj);
        }else{
            setReservationObj(defObj);
        }
    }

    let onReservationCodeChange = (e: any): void => {
        let uname = e.currentTarget.value;
        setReservationCode(e.currentTarget.value);
    }

    let onSuccessOffers = (dataObj:any) => {
        var equips = []
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                equips.forEach((item: any, idx: number) => {
                    if (item.isGeneric) {
                        item.isGeneric = item.isEquipmentGeneric === "Y" ? CommonUtil.EQUIP_GENERIC : CommonUtil.EQUIP_SPECIFIC;
                        if(item.isEquipmentGeneric === "Y" && item.isEnableAnyAvailble === "Y"){
                            item.isGeneric = item.isGeneric+" - "+CommonUtil.ANY_AVAILABLE;
                        }
                        //item.isGeneric = item.isGeneric+" - "+(item.isEnableAnyAvailble === "Y" ? CommonUtil.ANY_AVAILABLE : CommonUtil.ANY_NOT_AVAILABLE);
                    }
                    item.UnitEquipName = (item.unitNumber ? item.unitNumber : item.genericEquipmentName);

                    if (item.isInsurancePaidByRenterLiability === "Y") {
                        item.liabilityPaidBy = CommonUtil.RENTER;
                    }else if(item.isInsurancePaidByOwnerLiability === "Y"){
                        item.liabilityPaidBy = CommonUtil.OWNER;
                    }

                    if (item.isInsurancePaidByRenterPhysical === "Y") {
                        item.damageInsurancePaidBy = CommonUtil.RENTER;
                    }else if(item.isInsurancePaidByOwnerPhysical === "Y"){
                        item.damageInsurancePaidBy = CommonUtil.OWNER;
                    }
                    //delete item['genericEquipmentName'];
                })

                setReservationList(equips);
            }
        }
    }
    let onFailOffers = (data:any) => {
        console.log(data.errorMessage);

    }

    let onClickUpcoming = (btnId:string)=>{
        $("#"+btnId).toggleClass("select-reservation");
        $("#"+btnId).blur();
    }
    let getAllReservations = () => {
        let qParam = selCompanyObj?.orgId ? selCompanyObj.orgId : "";
        if(CommonUtil.isRootUser()){
            qParam = selCompanyObj?.orgId ? selCompanyObj.orgId : "";
        }else{
            qParam = CommonUtil.getSaasOrgId();
        }
        let usrUrl = "api/reservation/all";
        let status = "";
        let statusList:string[];
        statusList = [];
        if(isUpcoming){
            status = "Upcoming";
            statusList.push("Upcoming");
        }
        if(isProgress){
            status = status+",In progress";
            statusList.push("In progress");
        }
        if(isActivationDue){
            status = status+",Activation Due";
            statusList.push("Activation Due");
        }
        if(isDone){
            status = status+",Done";
            statusList.push("Done");
        }
        if(isDeleted){
            status = status+",Deleted";
            statusList.push("Deleted");
        }
        if(isDeActivationDue){
            status = status+",Deactivation Due";
            statusList.push("Deactivation Due");
        }
        if(isLateActivationDue){
            status = status+",Late Activation";
            statusList.push("Late Activation");
        }
        if(isLateDeActivationDue){
            status = status+",Late Deactivation";
            statusList.push("Late Deactivation");
        }
        console.log(selCompanyObj);
        

        let payload = {isAll:qParam ? false:true,companyId:qParam,reservationCode: reservationCode,multipleStatus:statusList};
        if(qParam === "")
            delete payload.companyId
        NetworkManager.sendJsonRequest(usrUrl, payload,'', "POST", true, onSuccessOffers, onFailOffers);
    }
    let filterReservations = () => [

    ]
    let handleModalOpen = () => {
        setModalShow(true)
    }
    let handleModalClose = () => {
        setModalShow(false)
    }
    let handleSubmit = () => {

    }
    const onClickDetail = (data:any) => {

    }
    let onEquipmentTypeChange = (dataObj: any) => {
        setSelEquipObj(dataObj)
    }
    useEffect(()=>{
        isUpcoming = true;
        isProgress = true;
        isActivationDue = false;
        isDeActivationDue = false;
        isLateActivationDue = false;
        isLateDeActivationDue = false;
        isDeleted = false;
        isDone = false;
        CommonUtil.SCREEN_NAME = CommonUtil.OWNER_RESERVATION;
       // subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        getAllReservations();
        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data;
            setAssetTypes(data)
        })
        if (CommonUtil.isRootUser()) {
            CommonUtil.getAllOrgs((data: any) => {
                let arr = [{orgId:null,orgName:"All"}];
                setOrgData([...arr,...data]);
            })
        }
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
            // document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        }
    },[]);

    let onSwitchCustomer = ()=>{
        //console.log("swich customer");
        setCompanyVisible(CommonUtil.isRootUser());
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                let arr = [{orgId:null,orgName:"All"}];
                setOrgData([...arr,...data]);
            })
            setSelCompanyObj(null);
        }else{
            getAllReservations();
        }
        setPage(""+Math.random()*10000);
        Events.dispatchBreadCrumbEvent();
    }

    let onAssetTypeChange = (dataObj: any) => {
        setSelAssetObj(dataObj)
        // getEquipmentTypes(dataObj);
        setSelEquipObj(null);
        setEquipTypes([]);

        if (dataObj) {
            CommonUtil.getEquipmentTypes(dataObj.key, (data: any) => {
                setEquipTypes(data)
            })

        }
    }

    return (
        <>
            <div className={'container-fluid'} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                <div className="row">
                    <div className="col-md-2 col-12 pt-2" style={{paddingLeft: '5px', zIndex: 999,display:(isCompanyVisible?"":"none")}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY,"Company")}</span>
                        <SingleSelectComp dataSource={orgData} selectedValue={selCompanyObj}
                                          width={'100%'} handleChange={onCompanyChange}
                                          value={'orgId'} label={'orgName'}/>

                    </div>
                    <div className="col-md-2 col-6 pt-1" style={{zIndex: '98',display:"none"}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.STATUS,"Status")}</span>
                        <SingleSelectComp dataSource={CommonUtil.RESERVATION_STATUSES} selectedValue={reserveObj}
                                          handleChange={onStatusChange} width={'100%'} value={'key'} label={'value'} />
                    </div>

                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '97'}}>
                        <span className="black-text css-16ihq8s">Reservation Code</span>
                        <input  type="text" className="form-control "
                               required={true} placeholder="Reservation Code"
                               value={reservationCode} onChange={(e) => {
                            onReservationCodeChange(e)
                        }} />
                    </div>

                    <div className="col-md-12 col-lg-1 row">
                        <div className="col-md-1" style={{paddingTop: (isCompanyVisible?"7px":'7px')}}>
                            <span className="black-text css-16ihq8s" style={{visibility:"hidden"}}> dsdd</span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                                // onClick={filterReservations}
                                    onClick={getAllReservations}>
                                {CommonUtil.getMessageText(Constants.SEARCH,"Search")}
                            </button>

                        </div>
                    </div>

                </div>
                <div className="row" style={{paddingTop:"15px"}}>
                    <button type="button" id={"btnUpcoming"} className="btn  button-height reservation select-reservation" onClick={()=>{
                        onClickUpcoming("btnUpcoming");
                        isUpcoming = !isUpcoming;
                    }}>
                        {"Upcoming"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnActDue"} className="btn button-height reservation " onClick={()=>{
                        onClickUpcoming("btnActDue");
                        isActivationDue = !isActivationDue;
                    }}>
                        {"Activation Due"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnInProgress"} className="btn btn-warning cus-btn btn-sm button-height reservation select-reservation" onClick={()=>{
                        onClickUpcoming("btnInProgress");
                        isProgress = !isProgress;
                    }}>
                        {"In progress"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnDeactivationDue"} className="btn btn-warning cus-btn btn-sm button-height reservation" style={{width:"180px"}} onClick={()=>{
                        onClickUpcoming("btnDeactivationDue");
                        isDeActivationDue = !isDeActivationDue;
                    }}>
                        {"Deactivation Due"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnLateActivation"} className="btn btn-warning cus-btn btn-sm button-height reservation" style={{width:"180px"}} onClick={()=>{
                        onClickUpcoming("btnLateActivation");
                        isLateActivationDue = !isLateActivationDue;

                    }}>
                        {"Late Activation"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnLateDeActivation"} className="btn btn-warning cus-btn btn-sm button-height reservation" style={{width:"180px"}} onClick={()=>{
                        onClickUpcoming("btnLateDeActivation");
                        isLateDeActivationDue = !isLateDeActivationDue;
                    }}>
                        {"Late Deactivation"}
                    </button>
                    &nbsp;

                    <button type="button" id={"btnDeleted"} className="btn btn-warning cus-btn btn-sm button-height reservation" onClick={()=>{
                        onClickUpcoming("btnDeleted");
                        isDeleted = !isDeleted;
                    }}>
                        {"Deleted"}
                    </button>
                    &nbsp;
                    <button type="button" id={"btnDone"} className="btn btn-warning cus-btn btn-sm button-height reservation" onClick={()=>{
                        onClickUpcoming("btnDone");
                        isDone = !isDone;
                    }}>
                        {"Done"}
                    </button>
                    &nbsp;


                </div>
                <div className={`row ${isMobile ? '' : 'pt-3'}`}>
                    {
                        isMobile ? (
                        <MGrid id="ownerReservationList" cols={reservationsColumns} rows={reservationList} pageSize={10}
                                addNew={false} tooltip={"Offers"} //addNewRecord={onClickNewNotification}
                                headerHeight={35} rowHeight={40} method={{onClickDetail}}
                                suppressRowClickSelection={true}/>
                        ):(
                        <div className="ag-theme-alpine gridHeight" style={{height: (isCompanyVisible?"calc(100vh - 240px)":"calc(100vh - 210px)"), width: "100%"}}>
                        <AGGrid id="ownerReservationReportList" cols={reservationsColumns} rows={reservationList} pageSize={500}
                                addNew={false} tooltip={"Offers"} //addNewRecord={onClickNewNotification}
                                headerHeight={35} rowHeight={40} method={{onClickDetail}}
                                suppressRowClickSelection={true}/>
                    </div>
                        )
                    }
                </div>
            </div>
            {/*<ReservationList handleModalOpen={handleModalOpen} handleModalClose={handleModalClose} reservationList={reservationList} />*/}


        </>
    )
}
export default ReservationReport
