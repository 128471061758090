import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import { NetworkManager } from '../../Components/NetworkManager';


var counter = 0;
var imageUrls:any = [];
const ReactImageGallery = ({startIndex,attachedImages,showPop,handleClose,title="Damage Photos",description}:any) => {

  const [images,setImages] = useState<any>([]);
 

  useEffect(()=>{
    // console.log()
    counter = 0;
    imageUrls = [];
    if(showPop)
      loadAll();
  },[showPop]);

  const loadAll = async ()=>{
    imageUrls = [];
    for(let i=0;i<attachedImages.length;i++){
      const result = await loadImage(attachedImages[i])
      // console.log(result);
      imageUrls.push(result);
    }
    console.log(imageUrls);
    const newimgurls = imageUrls.map((item:any,index:number)=>{
      let desc = description;
      if(Array.isArray(description))
        desc = description[index];
      return {original:item,thumbnail:item,originalHeight:500,description:desc}
    })
    setImages(newimgurls);
  }

  const loadUrl:any = async (url: string) =>{
    return new Promise(function(resolve, reject) {
      var qParam: string = "objectPath=" + url;
      NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, (res: any) => { 
        return resolve(res);
      },(err: any) => { 
        return reject(err);
      } );  
       
    });
}

  const loadImage:any = async(path:any)=>{
    var result = "";
    try{
      result = await loadUrl(path)
    }
    catch(e){
      result = "";
      console.log("error")
    } 
    return result;
  }
    
  return (
    <Modal show={showPop} onHide={handleClose} className={'modal-dialog-centered modal-lg'}>  
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>       
        <ImageGallery items={images} startIndex={startIndex} />
    </Modal>
  )
};

export default ReactImageGallery