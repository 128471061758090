import React, { useEffect, useRef } from "react";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import ImageLoader from "../ImageLoader";

const NewEntryComments = () => {
  const { state, dispatch } = useReleaseInspectorContext();
  const fileref = useRef<HTMLInputElement>(null);
  const qPath: any = CommonUtil.getInterchangeDocumentId();
  let fileType: string = "";

  const onAddPictures = (type: number, fType: string) => {
    dispatch({ type: "signType", payload: type });
    fileType = fType;
    if (fileref.current) {
      fileref.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile").click();
    }
  };
  useEffect(() => {}, [state.signature]);

  let onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/signature/${qPath}/${state?.eventId}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;

              const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "addSignature",
                    payload: {
                      path: { filePath: imagePath },
                      type: state?.signType,
                    },
                  });
                })
                .catch((error) => {
                  //  BusyLoader.hideLoader();
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };

  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };
  const imageRomoveHandler = (index: number) => {
    //state?.signature?.splice(index, 1);
    dispatch({
      type: "deleteSignature",
      payload: { index: index, signature: state?.signature[index] },
    });
  };

  return (
    <div>
      <textarea
        className="form-control"
        placeholder="Add Comments..."
        style={{ height: "100px" }}
        value={state?.comments}
        onChange={(e) =>
          dispatch({ type: "addComments", payload: e.target.value })
        }
      ></textarea>
      <div className="d-flex justify-content-between">
        <span className="attach">
          <span className="attachments">Inspector Signature</span> &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onAddPictures(0, CommonUtil.RELEASES_PICS)}
          >
            <i className="fa-solid fa-cloud-arrow-up"></i>
          </span>
        </span>
        <span className="attach">
          <span className="attachments">Driver Signature</span> &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onAddPictures(1, CommonUtil.RELEASES_PICS)}
          >
            <i className="fa-solid fa-cloud-arrow-up"></i>
          </span>
        </span>
      </div>

      <div className="row mt-3">
        {/* <div className="d-flex">
          {state?.signature[0] ? (
            <div className="col-6 p-2 text-center">Signature</div>
          ) : null}
          {state?.signature[1] ? (
            <div className="col-6 p-2 text-center">Driver Signature</div>
          ) : null}
        </div> */}
        <div className="d-flex justify-content-start align-items-senter">
          {state?.signature?.map((ele: any, i: number) => (
            <div
              key={i}
              className="col-6 text-center"
              style={{ position: "relative" }}
            >
              {ele?.filePath ? (
                <>
                  <i
                    className=" remove-btn fa fa-remove upload-thumbnail-remove"
                    onClick={() => {
                      imageRomoveHandler(i);
                    }}
                    style={{ position: "absolute", right: "15px" }}
                  ></i>
                  <ImageLoader path={ele?.filePath || ""} />
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <input
        type="file"
        ref={fileref}
        className="custom-file-doc-input"
        multiple={false}
        id="inputGroupFile"
        accept="image/*;pdf/*"
        style={{ display: "none" }}
        onChange={(e) => {
          onSelectFiles(e);
        }}
      />
    </div>
  );
};

export default NewEntryComments;
