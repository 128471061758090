import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import DraggableComponent from "../../Components/DraggableComponent";
import CDTextarea from "../Equipments/CDTextarea";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import Confirm from "../../Components/confirm";
import { useReleaseInspectorContext } from "../../Components/ReleaseContext";

const AddClaimModal = ({
  show,
  handleClose,
  mode,
  onSave,
  onDelete,
  carrier=null,
  getAllClaims,
}: any) => {
  const [disLength] = useState(1000);
  const countryRef = useRef<HTMLSelectElement>(null);
  const provinceRef = useRef<HTMLSelectElement>(null);
  const claimReasonRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);
  const claimAmountRef = useRef<HTMLInputElement>(null);
  const taxAmountRef = useRef<HTMLInputElement>(null);

  const [claimReason, setClaimReason] = useState("");
  const [description, setDescription] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [recipientDisabled, setRecipientDisabled] = useState<any>(false);
  const [recipient, setRecipient] = useState<any>({ Key: "owner" });
  const [claimObj, setClaimObj] = useState<any>(null);
  const [countryObj, setCountryObj] = useState<any>(null);
  const [stateObj, setStateObj] = useState<any>(null);
  const [states, setStates] = useState<any>(CommonUtil.canadaProvinces);
  const [fileNmae, setFileName] = useState("");
  const [users, setUsers] = useState([]);
  const [claimId, setClaimId] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [carrierId, setCarrierId] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [otherCarrier, setOtherCarrier] = useState<any>("");
  const { state, dispatch } = useReleaseInspectorContext();

  useEffect(() => {
    if (carrier && carrierList?.length > 0) {
      try{
        const cObj:any = carrierList.filter((item: any) => {
          return carrier === item.carrierName;
        })
        if(cObj.length>0){
          setCarrierId(cObj[0].carrierId);
          setCarrierName(cObj[0].carrierName);
        }else{
          setOtherCarrier(carrier);
        }
      }catch(e){
        console.log(e);
      }
    }
  }, [carrier,carrierList]);

  let onChangeClaim = (dataObj: any) => {
    setClaimObj(dataObj);
  };
  let onCountryChange = (data: any) => {
    setCountryObj(data);
    setStateObj(null);
    if (data) {
      if (data.Key == CommonUtil.CANADA) {
        setStates(CommonUtil.canadaProvinces);
      } else {
        setStates(CommonUtil.usStates);
      }
    } else {
      setCountryObj(null);
      setStates(CommonUtil.canadaProvinces);
    }
  };
  let onStateChange = (data: object) => {
    setStateObj(data);
    //(data) ? setProvinceClass('select-box-valid') : setProvinceClass('select-box-invalid');
  };
  let onClickDocBrowse = (fType: string) => {
    setFileName("");
  };
  let onCloseDocPopup = () => {
    // setShowDocPopup(false);
    handleClose();
  };
  const onGetClaimDetails = (data: any) => {
    if (data) {
      let restData = JSON.parse(data);
      if (restData && Array.isArray(restData)) restData = restData[0];
      setClaimReason(restData.claimReason);
      setDescription(restData.claimDescription);
      setClaimObj({ key: restData.claimType, value: restData.claimType });
      setCountryObj({ Key: restData.country, Value: restData.country });
      setStateObj({ Key: restData.city, Value: restData.city });
      setClaimAmount(restData.claimAmount);
      setTaxAmount(restData.taxAmount);
      setCarrierName(restData.carrierName);
      setCarrierId(restData.carrierId);
    }
  };

  const closeModel = () => {
    handleClose();
    resetData();
  };

  const resetData = () => {
    setClaimReason("");
    setDescription("");
    setClaimObj(null);
    setCountryObj(null);
    setStateObj(null);
    setClaimAmount("");
    setTaxAmount("");
    setCarrierName("");
    setCarrierId("");
    setOtherCarrier("");
  };

  useEffect(() => {
    if (mode === "edit") {
      let editClaimUrl = `api/claim/interchange/${CommonUtil.getClaimId()}`;
      NetworkManager.sendJsonRequest(
        editClaimUrl,
        "",
        "",
        "GET",
        true,
        onGetClaimDetails,
        onLoginError
      );
    }
  }, [mode]);

  useEffect(() => {
    getCareerList();
  }, []);

  const onClickSubmit = (e: any) => {
    e.preventDefault();

    let restObj: any = {};
    //restObj.city = "";
    restObj.claimAmount = claimAmount;
    restObj.claimDateString = CommonUtil.getReservationDateString(new Date());
    restObj.claimDescription = description;
    restObj.carrierName = carrierName === "Others" ? otherCarrier : carrierName;
    restObj.carrierId = carrierName === "Others" ? "" : carrierId;
    restObj.claimReason = claimReason;
    restObj.eventId = state?.eventId;
    restObj.eventCode = state?.eventCode;
    restObj.claimType = claimObj ? claimObj.key : "";
    restObj.country = countryObj ? countryObj.Key : "";
    restObj.city = stateObj ? stateObj.Key : "";
    restObj.reservationCode = "";
    restObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
    restObj.inspectionDocumentId = CommonUtil.getInterchangeDocumentId();
    restObj.saasOrgId = CommonUtil.getSaasOrgId();
    restObj.taxAmount = taxAmount;

    if (
      restObj.claimAmount === "" ||
      restObj.claimDescription === "" ||
      restObj.carrierName === "" ||
      restObj.claimReason === "" ||
      restObj.country === "" ||
      restObj.state === "" ||
      restObj.claimType === "" ||
      restObj.taxAmount === ""
    ) {
      ToastManager.showToast("Please fill all mandatory fields", "Info");
      return;
    }

    console.log(restObj, "SUBMIT");

    if (recipientDisabled) restObj.recipient = recipient?.Key;
    else {
      restObj.recipient = recipient?.Key === "owner" ? "renter" : "owner";
    }
    let method: string;
    let claimAPI: string;
    if (mode === "edit") {
      method = "PUT";
      claimAPI = `api/claim/${CommonUtil.getClaimId()}?isForInterchange=true`;
    } else {
      method = "POST";
      claimAPI = `api/claim/?isForInterchange=true`;
    }

    if (!recipient?.Key) {
      return;
    }

    let isClaim: boolean = CommonUtil.isMandatory(claimReasonRef);
    let isDesc: boolean = CommonUtil.isMandatory(descRef);

    if (isClaim && isDesc) {
      NetworkManager.sendJsonRequest(
        claimAPI,
        restObj,
        "",
        method,
        true,
        onSaveClaims,
        onLoginError
      );
    } else if (mode === "edit") {
      NetworkManager.sendJsonRequest(
        claimAPI,
        restObj,
        "",
        method,
        true,
        onSaveClaims,
        onLoginError
      );
    }
  };

  let onSaveClaims = (dataObj: any, data: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        onCloseDocPopup();
        if (mode === "edit") {
          ToastManager.showToast("Claim Updated Successfully", "Info");
        } else {
          ToastManager.showToast("Claim Created Successfully", "Info");
          //dispatch({ type: "clearData" });
        }
        resetData();
        getAllClaims();
      }
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let onClickDeleteClaim = (data: any) => {
    // claimId  = cid;
    if (mode === "edit") {
      Confirm.show("Confirm", "Do you want delete claim?", [
        {
          label: "Yes",
          callback: function () {
            let removeAPI: string = `api/claim/${CommonUtil.getClaimId()}?isForInterchange=true`;
            NetworkManager.sendJsonRequest(
              removeAPI,
              "",
              "",
              "DELETE",
              true,
              onDeleteClaim,
              onLoginError
            );
          },
        },
        { label: "No" },
      ]);
    }
  };

  const getCareerList = () => {
    var orgUrl = `api/webInspectionDetails/carriers?saasOrgId=${CommonUtil.getSaasOrgId()}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetAllCareers,
      onLoginError
    );
  };

  const onGetAllCareers = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);

      if (restData) {
        setCarrierList(restData);
      }
    }
  };

  let onDeleteClaim = (dataObj: any) => {
    if (dataObj == "success") {
      onCloseDocPopup();
      ToastManager.showToast("Claim deleted successfully", "Info");
      getAllClaims();
    } else {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
      }
    }
  };

  const handleCarrierChange = (value: any) => {
    const { carrierName, carrierId } = value;
    setCarrierName(carrierName);
    setCarrierId(carrierId);
  };

  return (
    <>
      <Modal show={show} dialogAs={DraggableComponent}>
        <div
          className="modal-content"
          style={{ width: "500px", height: "600px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {mode === "edit"
                ? CommonUtil.getMessageText(Constants.EDIT_CLAIM, "Edit Claim")
                : CommonUtil.getMessageText(Constants.ADD_CLAIM, "Add Claim")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginLeft: "0px" }}
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "auto" }}>
            <p>
              {CommonUtil.getMessageText(
                Constants.ENTER_CLAIM_AMOUNT,
                "Please Enter claim amount including tax"
              )}
            </p>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.CLAIM_REASON,
                  "Claim Reason"
                )}
              </label>
              <input
                type="text"
                ref={claimReasonRef}
                value={claimReason}
                className="form-control clsStandardText  clsFormInputControl"
                placeholder="Claim Reason"
                onChange={(e) => {
                  CommonUtil.isMandatory(claimReasonRef);
                  setClaimReason(e.currentTarget.value);
                }}
              />
            </div>
            <div className="form-group">
              <label className="col-sm-12 col-form-label">Carrier Name</label>
              <SingleSelectComp
                dataSource={carrierList}
                label="carrierName"
                value="carrierId"
                selectedValue={{
                  carrierId: carrierId,
                  carrierName: carrierName,
                }}
                handleChange={handleCarrierChange}
              />
              <br />
              {carrierName == "Others" && (
                <input
                  type="text"
                  className="form-control"
                  value={otherCarrier}
                  onChange={(e) => setOtherCarrier(e.target.value)}
                  placeholder="Others Career"
                />
              )}
            </div>

            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.DESCRIPTION,
                  "Description"
                )}
              </label>
              <CDTextarea
                maxLength={disLength}
                ref={descRef}
                value={description}
                placeholder={CommonUtil.getMessageText(
                  Constants.ENTER_DESCRIPTION,
                  "Enter Description"
                )}
                rows={4}
                onChange={(e: any) => {
                  CommonUtil.isMandatory(descRef);
                  setDescription(e.currentTarget.value);
                }}
              />
            </div>
            {/* <div className={`form-group ${recipientDisabled ? "d-none" : ""}`} >
              <label>
                {CommonUtil.getMessageText(
                  Constants.ON_BEHALF_OF,
                  "This transaction is performed on behalf of"
                )}
              </label>
              <SingleSelectComp
                dataSource={[
                  { Key: "", Value: "Select" },
                  { Key: "owner", Value: "Owner" },
                  { Key: "renter", Value: "Renter" },
                ]}
                selectedValue={recipient}
                handleChange={(e: any) => {
                  setRecipient(e ? e : "");
                }}
                className={
                  recipient?.Key ? "select-box-valid" : "select-box-invalid"
                }
                disabled={recipientDisabled}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div> */}
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.CLAIM_TYPE, "Claim Type")}
              </label>
              <SingleSelectComp
                dataSource={CommonUtil.CLAIM_TYPES}
                selectedValue={claimObj}
                handleChange={onChangeClaim}
                width={"100%"}
                value={"key"}
                label={"value"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.COUNTRY, "Country")}
              </label>
              <SingleSelectComp
                ref={countryRef}
                dataSource={CommonUtil.countryList}
                selectedValue={countryObj}
                handleChange={onCountryChange}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.STATE, "State")}
              </label>
              <SingleSelectComp
                ref={provinceRef}
                dataSource={states}
                selectedValue={stateObj}
                handleChange={onStateChange}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.CLAIM_AMOUNT,
                  "Claim Amount"
                )}
              </label>
              <input
                type="text"
                ref={claimAmountRef}
                value={claimAmount}
                className="form-control"
                required
                placeholder="Claim Amount"
                onKeyPress={CommonUtil.allowNumbersDecimal}
                onChange={(e) => {
                  CommonUtil.handleNumbersDecimalInput({
                    event: e,
                    ref: null,
                    setState: setClaimAmount,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.TAX_AMOUNT, "Tax Amount")}
              </label>
              <input
                type="number"
                ref={taxAmountRef}
                value={taxAmount}
                onKeyPress={CommonUtil.allowNumbersDecimal}
                onChange={(e) => {
                  CommonUtil.handleNumbersDecimalInput({
                    event: e,
                    ref: null,
                    setState: setTaxAmount,
                  });
                }}
                className="form-control"
                required
                placeholder="Tax Amount"
              />
            </div>

            <label style={{ display: "none" }}>Add Pictures</label>
            <div
              style={{ display: "none" }}
              className="boxed"
              onClick={() => {
                onClickDocBrowse(CommonUtil.EQUIP_DOCS);
              }}
            >
              <div className="row">
                <div className="col-sm-12 col-12 text-center">
                  <form action="" className="dropzone">
                    <img
                      src="../../images/Documents-Upload.png"
                      className="mb-3"
                    />
                    <p className="text-center">
                      <strong>
                        Drag & Drop your Photos here or{" "}
                        <a href="#">Browse Photos</a>
                      </strong>
                    </p>
                    <div className="fallback">
                      <input
                        name="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={closeModel}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>

            {/* V20-2687--------------------V20-2687 */}
            {/* {CommonUtil.getSaasOrgId() == claimData?.claimedByOrgId || CommonUtil.getSaasOrgId() == 1 ? (<span>
                {claimId ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      onClickDeleteClaim(claimId);
                    }}
                  >
                    {CommonUtil.getMessageText(Constants.DELETE, "Delete")}
                  </button>
                ) : (
                  ""
                )}</span>):("")
            } */}
            {mode === "edit" ? (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onClickDeleteClaim}
              >
                {CommonUtil.getMessageText(Constants.DELETE, "Delete")}
              </button>
            ) : (
              ""
            )}

            <button
              type="button"
              className="btn btn-success"
              onClick={onClickSubmit}
            >
              {CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddClaimModal;
