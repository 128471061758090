import React, { useState } from 'react';
import AddClaimModal from './AddClaimModal';
import { NetworkManager } from '../../Components/NetworkManager';
import { CommonUtil } from '../../utils/CommonUtil';

const EditClaimModalRenderer = (props: any, getAllClaims:any) => {
    

    const [openEditDoc, setOpenEditDoc] = useState(false)
    const [mode, setMode] = useState("new")

    const onClickEditClaimId = (data:any) => {
        
        // if (props.context?.onReceiveData) {
        //     props.context.onReceiveData(props.data);
        // }
        setMode("edit");
        setOpenEditDoc(true);
        CommonUtil.setClaimId(props.data.claimId);
    }
    let onGetClaimDetails = (data:any) => {
        console.log("Hii");
    }

    const onSaveClaimId = () => {
        console.log("onsave");
        
    }
    const onDeleteClaimId = () => {
        // console.log("onDeleteClaimId");
        // let deleteClaimUrl = `api/claim/${CommonUtil.getSaasOrgId()}?isForInterchange=true`
        // NetworkManager.sendJsonRequest(deleteClaimUrl, "", "", "DELETE", true, onDeleteClaim, onLoginError);
    }
    const onDeleteClaim = () => {
        console.log("Delete");
        
    }

    const onCloseEditModal = () => {
        setOpenEditDoc(false)
    }
    let onLoginError = (err: any) => {
        console.log(err);
    };
    return (
        <div>
        <div className={"cellRenderHeader"}>
            <a onClick={onClickEditClaimId}><span  className={"cellRender"}>
                    {props.data.claimCode}             
            </span></a>
            </div>
            {openEditDoc && <AddClaimModal getAllClaims={getAllClaims} show={true}  handleClose={onCloseEditModal} mode={mode} onSave={onSaveClaimId} onDelete={onDeleteClaimId} />}
        </div>
    )
}

export default EditClaimModalRenderer;
