import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";


const UserStatusRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickActiveUser(props.data);
        }
    }

    const onClickResend = () => {
        if (props.context) {
            props.context.onClickResend(props.data);
        }
    }


    return (
        <div className={"cellRenderHeader"} style={{marginTop: props.isMobile ? "2px" : "-3px"}}>
            {
                (props.value == CommonUtil.ACTIVE) ?
                    <button className="btn btn-success active-status" onClick={onClickUser}>{(props.value)}</button> :
                    <button className="btn btn-success in-active-status" onClick={onClickUser}>{props.value ? props.value : 'Inactive'}</button>

            }

        </div>

    )
}

export default UserStatusRenderer;