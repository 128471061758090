import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import {Constants} from "../Languages/Constants";
import Flatpickr from "react-flatpickr";
import SingleSelectComp from "../../Components/SingleSelectComp";
import $ from "jquery";
import {NetworkManager} from "../../Components/NetworkManager";
import {useNavigate} from "react-router-dom";
import {Events} from "../Languages/Events";
import BusyLoader from "../../Components/BusyLoader";
import ToastManager from "../../Components/ToastManager";
import {loadMapApi} from "../../utils/GoogleMapsUtils";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";


let gMap:any = null;
let markers:any = [];
let selectItem:any = null;
let REPOSITION = "Reposition";
let RENTAL = "Rental";

const DashboardPopupModal = ({modalShow,handleClosePopup,markerData,isSearchClicked}: any) => {

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);
    const [stDate, setStDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const gMapPopupRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [fromLocation,setFromLocation] = useState("");
    const [toLocation,setToLocation] = useState("");
    const [departureDate,setDepartureDate] = useState("");
    const [arrivalDate,setArrivalDate] = useState("");
    const [equipType,setEquipType] = useState("");
    const [revenue,setRevenue] = useState("");
    const [revenuePerHour,setRevenuePerHour] = useState("");
    const [axles,setAxles] = useState("");
    const [modalYear,setModalYear] = useState("");
    const [equipLength,setEquipLength] = useState("");
    const [estDrive,setEstDrive] = useState("");
    const [totalDist,setTotalDist] = useState("");
    const [bobtail,setBobTail] = useState("");
    const [hook,setHookType] = useState("");
    const [dropToTarget,setDropToTarget] = useState("");
    const [availCount,setAvailCount] = useState("1");
    const [availabilityType,setAvailabilityType] = useState("");
    const [dailyRate, setDailyRate] = useState("");
    const [weeklyRate, setWeeklyRate] = useState("");
    const [monthlyRate, setMonthlyRate] = useState("");
    const [distanceRate,setDistanceRate] = useState("");
    const [engineRate,setEngineRate] = useState("");
    const [allowOffer,setAllowOffer] = useState("");
    const [daysAllowed,setDaysAllowed] = useState("");
    const [unitNumber,setUnitNumber] = useState("");
    const [vin,setVIN] = useState("");
    const [isGeneric,setGeneric] = useState("");
    const [sericeType,setServiceType] = useState("");
    const [excessDays,setExcessDays] = useState("");
    const [summary,setSummary] = useState("");


    useEffect(()=>{
        //console.log("Use effect1"+CommonUtil.SELECT_EQUIP_ID);
        if (window.google) {
            loadGoogleMap();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    loadGoogleMap();
                });
            }
        }

    },[modalShow])

    let loadGoogleMap = () => {
        console.log(markerData);
        if(markerData){
            let lat = Number(markerData.lat);
            let lng = Number(markerData.lng);
            if (gMapPopupRef.current) {
                gMap = new google.maps.Map(gMapPopupRef.current, {
                    zoom: 15, center: new google.maps.LatLng(lat, lng),
                    gestureHandling: 'greedy', streetViewControl: true, zoomControl: true, fullscreenControl: true,
                    draggableCursor: 'pointer',
                    fullscreenControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
                    mapTypeControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
                });

                if (gMap) {
                    setTimeout(() => {
                        showMarkers();
                    });
                }
            }
        }

    }

    let showMarkers = ()=>{
        let latlngbounds1 = new google.maps.LatLngBounds();
        let lat = markerData.lat;
        let lng = markerData.lng;

        let latLng = new google.maps.LatLng(lat, lng);
        let avlQty = "";
        let deptAddr = "";
        let arrivalAddr = "";
        if(markerData.latlngs && markerData.latlngs.length>0){
            avlQty = markerData.latlngs[0].availCount;
            deptAddr = markerData.latlngs[0].departureAddress;
            arrivalAddr = markerData.latlngs[0].arrivalAddress;

            setFromLocation(deptAddr);
            setToLocation(arrivalAddr);
            setDepartureDate(markerData.latlngs[0].departureDate);
            setArrivalDate(markerData.latlngs[0].arrivalDate);
            setEquipType(markerData.latlngs[0].equipmentType);
            setRevenue(markerData.latlngs[0].movePrice);
            setRevenuePerHour(markerData.latlngs[0].revenuePerHour);
            setAxles(markerData.latlngs[0].numberOfAxles);
            setModalYear(markerData.latlngs[0].year);
            setEquipLength(markerData.latlngs[0].lengthOfTrailer);
            setEstDrive(markerData.latlngs[0].totalTravelTime);
            setTotalDist(markerData.latlngs[0].totalDistanceKM);
            setBobTail(markerData.latlngs[0].bobtailToPuDistanceKM);
            setHookType(markerData.latlngs[0].hookDistanceKM);
            setDropToTarget(markerData.latlngs[0].dropToTargetDistanceKM);
            setAvailCount(markerData.latlngs[0].availCount);
            setAvailabilityType(markerData.latlngs[0].availabilityType);
            setDailyRate(markerData.latlngs[0].dailyRate);
            setWeeklyRate(markerData.latlngs[0].weeklyRate);
            setMonthlyRate(markerData.latlngs[0].monthlyRate);
            setDistanceRate(markerData.latlngs[0].distanceRate);
            setEngineRate(markerData.latlngs[0].engineRate);
            setAllowOffer(markerData.latlngs[0].allowOffer);
            setDaysAllowed(markerData.latlngs[0].noOfDaysAllowed);
            setUnitNumber(markerData.latlngs[0].unitNumber);
            setVIN(markerData.latlngs[0].vin);
            setGeneric(markerData.latlngs[0].isGeneric);
            setServiceType(markerData.latlngs[0].loadType);
            setExcessDays(markerData.latlngs[0].dailyRate);

            selectItem = markerData.latlngs[0];

            let strSummary = "Reposition "+markerData.latlngs[0].loadType;
            strSummary = strSummary+ " "+markerData.latlngs[0].equipmentType+" "+markerData.latlngs[0].lengthOfTrailer;
            strSummary = strSummary+ " "+"from city - "+markerData.latlngs[0].departureCity+" to city "+markerData.latlngs[0].arrivalCity;
            strSummary = strSummary+ " "+"paying "+markerData.latlngs[0].movePrice+" and allowing "+markerData.latlngs[0].noOfDaysAllowed+" Days";
            strSummary = strSummary+ " "+"Expected delivery date is "+markerData.latlngs[0].arrivalDate+" excessed will incur $"+markerData.latlngs[0].dailyRate+" per day penalty";

            setSummary(strSummary);
        }
        let gIcon = CommonUtil.showAvailableCount(Number(avlQty));
        let tip = "Departure: "+deptAddr;//markerData.address.toString()+"\n";

        let marker = new google.maps.Marker({position: latLng, map: gMap,icon:gIcon,title:tip});
        markers.push(marker);
        latlngbounds1.extend(latLng);

        latLng = new google.maps.LatLng(markerData.arrivalLat, markerData.arrivalLng);
        tip = "Arrival: "+arrivalAddr;//markerData.arrivaladdress.toString();
         marker = new google.maps.Marker({position: latLng, map: gMap,title:tip});
        markers.push(marker);
        latlngbounds1.extend(latLng);


        const lineSymbol = {path: "M 0,-1 0,1", strokeOpacity: 1, scale: 4,};
        const line = new google.maps.Polyline({
            path: [
                { lat: markerData.lat, lng:markerData.lng },
                { lat: markerData.arrivalLat, lng: markerData.arrivalLng },
            ],
            strokeOpacity: 0,
            icons: [{icon: lineSymbol, offset: "0", repeat: "20px"}],
            map: gMap,
        });
        markers.push(line);
        if(latlngbounds1){
            gMap.fitBounds(latlngbounds1);
        }
    }



    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 1200;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }
    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devEstPopup', 'devEstContentPopup')
        }, 100)
    }

    let clearMarkers = ()=>{
        for(let x1=0;x1<markers.length;x1++){
            markers[x1].setMap(null);
        }
        markers = [];
    }

    let closePopup = ()=>{
        clearMarkers();
        setTimeout(()=>{
            handleClosePopup();
        })

    }

    let onClickBookItem = ()=>{
        console.log(selectItem);
        localStorage.setItem("PublicViewTab","true");
        localStorage.setItem("offerPopup","false");
        showAvailDetails();

    }

    let onClickShowAvailability = ()=>{
        console.log(selectItem);
       // console.log(searchType);
        localStorage.setItem("PublicViewTab","true");
        localStorage.setItem("offerPopup","true");
        showAvailDetails();
    }
    let showAvailDetails = ()=>{
        let stDate = null;
        let endDate = null;
        stDate = selectItem.departureDate;
        endDate = selectItem.arrivalDate;

        localStorage.setItem("startDate",(stDate?CommonUtil.getReservationDateString(stDate):""));
        localStorage.setItem("endDate",(endDate?CommonUtil.getReservationDateString(endDate):""));

        localStorage.setItem("PublicSearch",CommonUtil.PUBLIC_SEARCH_DB?"true":"false");
        let siteOrigin:string = window.location.origin;
        let equipId:string = selectItem.equipmentId;
        let avaiId:string = selectItem.availabilityId;
        let isGeneric:string = selectItem.isGenericEquipment;
        let equipType:string =selectItem.equipmentType;

        siteOrigin = siteOrigin+"/?requestor=equippublicview&equipId="+equipId;//+"&token="+ localStorage.getItem("token");
        siteOrigin = siteOrigin+"&avaiId="+ avaiId;
        siteOrigin = siteOrigin+"&isGeneric="+ isGeneric;
        siteOrigin = siteOrigin+"&equipType="+ equipType;
        var newTab = document.createElement('a');
        newTab.href = siteOrigin;
        newTab.setAttribute('target', '_blank');
        newTab.click();
       // return;

    }

    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (<>
        <Modal show={modalShow} centered dialogAs={DraggableComponent} id="devEstPopup" onShow={onDevAvailShowPopup}>
            <div className="modal-content" id="devEstContentPopup" style={{border:"1px solid green",width:"1200px",height:"650px"}}>
                <Modal.Header className="modal-header" closeButton={false} style={{padding:"5px",backgroundColor:"aliceblue"}}>
                    <h5 className="modal-title mt-0" style={{fontSize:"15px"}}>{CommonUtil.getMessageText(Constants.AVL_DETAILS, 'Availability Details')}</h5>
                    <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close"
                            onClick={closePopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <div className="modal-body" style={{padding:"5px"}}>
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12 m-m-b-15 float-end map-grid" style={{paddingRight:"1px"}}>
                            <div className="f-vh-85">
                                <div className="map-container">
                                    <div ref={gMapPopupRef} id="mapPopupRef" className="map-container__map" style={{height:"75vh"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 m-m-b-15 float-end map-grid" style={{borderLeft:"1px solid #e9e1e1",height:"75vh"}}>
                            <div style={{height:"550px",overflow:"auto"}}>
                                <table className="table table-bordered" >
                                    <tr>
                                        <td className={"tip-align"}>Departure:</td>
                                        <td className={"tip-padding"}>{fromLocation}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Arrival:</td>
                                        <td className={"tip-padding"}>{toLocation}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Departure Date:</td>
                                        <td className={"tip-padding"}>{departureDate}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Arrival Date:</td>
                                        <td className={"tip-padding"}>{arrivalDate}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Service Type:</td>
                                        <td className={"tip-padding"}>{sericeType}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Days Allowed for Reposition:</td>
                                        <td className={"tip-padding"}>{daysAllowed}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Excess Days Penalty:</td>
                                        <td className={"tip-padding"}>{excessDays}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Total Distance (KM):</td>
                                        <td className={"tip-padding"}>{totalDist}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Revenue:</td>
                                        <td className={"tip-padding"}>{revenue}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Revenue Per Hour:</td>
                                        <td className={"tip-padding"}>{revenuePerHour}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Bobtail to PU (KM):</td>
                                        <td className={"tip-padding"}>{bobtail}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Hook (KM):</td>
                                        <td className={"tip-padding"}>{hook}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Drop to Target:</td>
                                        <td className={"tip-padding"}>{dropToTarget}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Est. Drive (HRS):</td>
                                        <td className={"tip-padding"}>{estDrive}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Equipment Type:</td>
                                        <td className={"tip-padding"}>{equipType}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Equipment Number / Name:</td>
                                        <td className={"tip-padding"}>{unitNumber}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>VIN:</td>
                                        <td className={"tip-padding"}>{vin}</td>
                                    </tr>
                                    <tr>
                                        <td className={"tip-align"}>Generic/Specific:</td>
                                        <td className={"tip-padding"}>{isGeneric}</td>
                                    </tr>

                                    <tr style={{display:"none"}}>
                                        <td className={"tip-align"}>Availabilities:</td>
                                        <td className={"tip-padding"}>{availCount}</td>
                                    </tr>
                                    <tr style={{display:"none"}}>
                                        <td className={"tip-align"}>Axles:</td>
                                        <td className={"tip-padding"}>{axles}</td>
                                    </tr>
                                    <tr style={{display:"none"}}>
                                        <td className={"tip-align"}>Modal Year:</td>
                                        <td className={"tip-padding"}>{modalYear}</td>
                                    </tr>
                                    <tr style={{display:"none"}}>
                                        <td className={"tip-align"}>Length:</td>
                                        <td className={"tip-padding"}>{equipLength}</td>
                                    </tr>

                                    <tr style={{display:(availabilityType == REPOSITION)?"":"none"}}>
                                        <td className={"tip-align"}>Summary:</td>
                                        <td className={"tip-padding"}>{summary}</td>
                                    </tr>


                                    <tr style={{display:(availabilityType == REPOSITION)?"none":""}}>
                                        <td className={"tip-align"}>Daily Rate:</td>
                                        <td className={"tip-padding"}>{dailyRate}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"none":""}}>
                                        <td className={"tip-align"}>Weekly Rate:</td>
                                        <td className={"tip-padding"}>{weeklyRate}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"none":""}}>
                                        <td className={"tip-align"}>Monthly Rate:</td>
                                        <td className={"tip-padding"}>{monthlyRate}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"none":""}}>
                                        <td className={"tip-align"}>Distance Rate:</td>
                                        <td className={"tip-padding"}>{distanceRate}</td>
                                    </tr>
                                    <tr style={{display:(availabilityType == REPOSITION)?"none":""}}>
                                        <td className={"tip-align"}>Engine Rate:</td>
                                        <td className={"tip-padding"}>{engineRate}</td>
                                    </tr>

                                </table>

                            </div>
                            <div style={{paddingTop:"10px",textAlign:"center",display:(isSearchClicked?"":"none")}}>
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={onClickBookItem}>
                                    Book Now
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-success" data-dismiss="modal" style={{display:(allowOffer == "Y"?"":"none")}} onClick={onClickShowAvailability}>
                                    Create Offer
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{paddingTop:"1px",display:"none"}}>
                    <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={closePopup}>
                        {CommonUtil.getMessageText(Constants.CLOSE, 'Close')}
                    </button>

                </div>
            </div>

        </Modal>

    </>)
}
export default DashboardPopupModal;
