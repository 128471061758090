import React, { useState, useRef } from "react";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import ToastManager from "../../../Components/ToastManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import AttachmentsViewer from "./AttachmentsViewer";

const NewEntryLightsValues = ({ data, inspectionItem, onAddPictures }: any) => {
  const { state, dispatch } = useReleaseInspectorContext();
  const [selectedValue, setSelectedValue] = useState("");
  const [selType, setSelType] = useState("");
  const [selCount, setSelCount] = useState("");
  const [selNote, setSelNote] = useState("");

  let fileType: string = "";
  let imagePath: string = "";
  let taskItem: any = {
    taskId: data.taskId,
    taskStatus: "",
    taskNote: "",
    selectionOption: "",
    count: "",
  };
  let attachments: any = {
    attachmentUploads: [],
    attachmentDamageDescription: null,
  };

  try {
    const item = state?.lightInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    taskItem = {
      taskId: item.taskId,
      taskStatus: item?.taskStatus,
      taskNote: item?.taskNote,
      selectionOption: item?.selectionOption,
      count: item?.count,
    };
  } catch (e) {
    taskItem = {
      taskId: data.taskId,
      taskStatus: "",
      taskNote: "",
      selectionOption: "",
      count: "",
    };
  }

  try {
    const item = state?.lightInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    attachments = {
      attachmentUploads: { fullFilePath: item?.attachments.attachmentUploads },
      attachmentDamageDescription: item?.attachents.attachmentDamageDescription,
    };
  } catch (e) {
    attachments = {
      attachmentUploads: [],
      attachmentDamageDescription: "",
    };
  }
  const handleChange = (value: any) => {
    dispatch({
      type: "lightInspection",
      payload: {
        taskId: value.taskId,
        taskStatus: value.taskStatus,
        taskNote: value.taskNote,
        selectionOption: value?.selectionOption,
        count: value?.count,
      },
    });
    dispatch({
      type: "lightInspection",
      payload: {
        isCompleted: false,
      },
    });
  };

  const typeSoruce = data?.selectionOptions?.map((ele: any) => ({ type: ele }));

  const selectionOptions = (
    <select
      name={data?.taskId}
      required={true}
      className={"form-control"}
      onChange={(e) =>
        handleChange({
          ...taskItem,
          selectionOption: e.target.value,
          taskId: data?.taskId,
        })
      }
      value={taskItem?.selectionOption}
    >
      <option selected value="">
        Type
      </option>
      {typeSoruce &&
        typeSoruce.map((item: any, i: number) => (
          <option key={i} value={item.type} onChange={(e) => handleChange("#")}>
            {item.type}
          </option>
        ))}
    </select>
  );

  const selectionCount = (
    <input
      className="textarea"
      type="text"
      value={taskItem?.count}
      onChange={(e) =>
        handleChange({
          ...taskItem,
          count: e.target.value,
          taskId: data?.taskId,
        })
      }
      required
    />
  );
  const selectionNote = (
    <input
      className="textarea"
      type="text"
      value={taskItem?.taskNote}
      onChange={(e) =>
        handleChange({
          ...taskItem,
          taskNote: e.target.value,
          taskId: data?.taskId,
        })
      }
    />
  );

  return (
    <>
      <tr>
        <td>{data?.taskName}</td>
        <td>
          <div className="d-flex text-center" style={{ marginLeft: "10px" }}>
            <div>
              <input
                className="radio-size"
                required
                type="radio"
                name={data?.taskId}
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "ok"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "ok",
                    taskId: data?.taskId,
                  })
                }
              />
            </div>
            <div>
              <input
                className="radio-size"
                required
                name={data?.taskId}
                type="radio"
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "fail"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "fail",
                    taskId: data?.taskId,
                  })
                }
                style={{ marginLeft: "45px" }}
              />
            </div>
          </div>
        </td>
        <td>{selectionNote}</td>
        <td>{selectionCount}</td>
        <td>{selectionOptions}</td>
        {taskItem?.taskStatus === "fail" && (
          <td>
            <div className="d-flex ">
              {state?.lightInspection?.task?.filter(
                (item: any) => item.taskId === data.taskId
              )[0].attachments?.attachmentUploads?.length ? (
                <div
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    padding: "4px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    borderRadius: "3px",
                    marginRight: "5px",
                    height: "25px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    AttachmentsViewer.show({
                      ...state?.lightInspection?.task?.filter(
                        (item: any) => item.taskId === data.taskId
                      )[0].attachments,
                      isDeleteButton: true,
                      resetData: "lightInspection",
                    })
                  }
                >
                  {
                    state?.lightInspection?.task?.filter(
                      (item: any) => item.taskId === data.taskId
                    )[0].attachments?.attachmentUploads?.length
                  }
                </div>
              ) : null}
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onAddPictures(data?.taskId, CommonUtil.RELEASES_PICS);
                  dispatch({ type: "setTaskId", payload: data?.taskId });
                }}
              >
                <i className="fa-solid fa-cloud-arrow-up"></i>
              </span>
              &nbsp;
              {state?.lightInspection?.task?.filter(
                (item: any) => item.taskId === data.taskId
              )[0].attachments?.attachmentUploads?.length ? (
                <>
                  <input
                    className="textarea"
                    type="text"
                    required
                    style={{ width: "70%" }}
                    placeholder="Add Description"
                    value={
                      state?.lightInspection?.task?.filter(
                        (item: any) => item.taskId === data.taskId
                      )[0].attachments?.attachmentDamageDescription
                    }
                    onChange={(event: any) => {
                      dispatch({
                        type: "addattachments",
                        payload: {
                          taskId: data?.taskId,
                          category: "lightInspection",
                          attachments: {
                            attachmentDamageDescription: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default NewEntryLightsValues;
