import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DraggableComponent from "../../Components/DraggableComponent";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { CommonUtil } from "../../utils/CommonUtil";
import DetectDevice from "../../utils/DetectDevice";
import { Constants } from "../Languages/Constants";
import { Events } from "../Languages/Events";
import { subscribe, unsubscribe } from "./CustomEvents";
import LoginHeader from "./LoginHeader";
import LoginModal from "./LoginModal";

const LoginPageModal = () => {
    const [showModalPopup,setShowModalPopup] = useState(true);
    const [emailText, setEmailText] = useState("");

    useEffect(() => {


    }, []);

    let closeLoginPopup = ()=>{
        setShowModalPopup(false);
    }



    return (
        <React.Fragment>
            {/*<LoginModal modalShow={showModalPopup} closeModal={closeLoginPopup} />*/}

        </React.Fragment>
    );
}

export default LoginPageModal;
