import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const insurDocRender = (props: any) => {
    const onDownloadDoc = () => {
        if (props.context) {
            props.context.onDownloadDoc(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <img src="../../images/document.png" alt="" style={{width:"50px"}} className={"financial-download-image"}
                 onClick={onDownloadDoc} />
        </div>

    )
}

export default insurDocRender;
