import React, { useState } from 'react'
import AddBrokerDetailsModal from './AddBrokerDetailsModal'
import { CommonUtil } from '../../utils/CommonUtil'

const EditBrokerDetails = (props: any, getAllBrokerDetails:any) => {

    const [openEditDoc, setOpenEditDoc] = useState(false)
    const [mode, setMode] = useState("new")

    const onClickEditBroker = () => {
        setMode("edit");
        setOpenEditDoc(true);
        CommonUtil.setBrokerId(props.data.brokerId);
    }
    const onCloseEditBroker = () => {
        setOpenEditDoc(false)
    }

    return (
        <div>
            <div className={"inspectionRenderHeader"} onClick={onClickEditBroker}>{props.data.brokerName}</div>
            {openEditDoc && <AddBrokerDetailsModal mode={mode} open={true} handleClose={onCloseEditBroker} getAllBrokerDetails={getAllBrokerDetails} />}
            </div>
    )
 
}

export default EditBrokerDetails
