import React, { useState } from "react";
import { CommonUtil } from "../utils/CommonUtil";
const Star = ({ marked, starId }: any) => {
  return (
    <span data-star-id={starId} className="star" role="button">
      {/* {marked ? "\u2605" : "\u2606"} */}
      {marked ?
        <i className="fa fa-star"></i> :
        <i className="fa-regular fa-star"></i>
      }
    </span>
  );
};

const StarRating = ({ value }: any) => {
  const [rating, setRating] = React.useState(parseInt(value) || 0);
  const [selection, setSelection] = React.useState(0);
  const [equipImagesPath, setEquipPath] = useState([1, 2, 3, 4, 5]);

  //console.log(parseInt(value));

  const hoverOver = (event: any) => {
    let val = 0;
    if (event && event.target && event.target.getAttribute("data-star-id"))
      val = event.target.getAttribute("data-star-id");
    setSelection(val);
  };
  return (
    <div onMouseOut={() => hoverOver(null)} onClick={(e: any) => { setRating(e.target.getAttribute("data-star-id") || rating) }} style={{ width: 'auto' }}>
      {equipImagesPath.map((item: any, idx: number) => {
        var list = null;
        if (item && item <= value) {
          list = (<i className="fa fa-star" key={idx} ></i>)
        } else {
          list = (<i className="fa-regular fa-star fa-star-half-o" aria-hidden="true" key={idx} ></i>)
        }
        return list
      })}
    </div>
  );
};

export default StarRating;
