import React, {useEffect, useRef, useState} from 'react'
import SingleSelectComp from '../../Components/SingleSelectComp'
import AGGrid from '../../Components/AGGrid'
import OrgCellRenderer from '../Organization/OrgCellRenderer'
import orgUserStatucRenderer from '../Organization/OrgUserStatucRenderer'
import {IAGrid} from '../../Components/IInterfaces'
import useStateWithCallback from 'use-state-with-callback'
import {useNavigate} from 'react-router-dom'
import {CommonUtil} from '../../utils/CommonUtil'
import {NetworkManager} from '../../Components/NetworkManager'
import ToastManager from '../../Components/ToastManager'
import {StatusCodeUtil} from '../../utils/StatusCodeUtil'
import {CloseButton, Modal} from 'react-bootstrap'
import DraggableComponent from '../../Components/DraggableComponent'
//import specific from '../../images/specific-equipment.png'
//import generic from '../../images/general-equipment.png'

import dummyData from './dummy.json'
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {publish, subscribe, unsubscribe} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import DetectDevice from '../../utils/DetectDevice'
import MGrid from '../../Components/MGrid'
import {Constants} from "../Languages/Constants";
import $ from "jquery";

var defObj = {key:'',value:'All'};
var equipmentId = "";
const EquipmentList = () => {
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [showEstValuePopup,setSowEstValuePopup] = useState(false);
    const [estimatedValue,setEstimatedValue] = useState("");
    const estValueRef =  useRef<HTMLInputElement>(null);
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{
            if(gridRef.current){
                gridRef.current.columnFittoSize();
            }
        },1000);
    })

    const [showEquipPopup, setShowEquipPopup] = useState(false)

    const gridRef = useRef<IAGrid>(null)

    const navigate = useNavigate();
    const [isCompanyVisible,setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [page,setPage] = useState("");

    const [eqpColumnDefs] = useState([
        {field: 'UnitEquipName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,'Equipment Number / Name'), resizable: true, width: 250, sortable: true, cellRenderer: EquipCellRenderer, filter: 'agTextColumnFilter'},
        {field: 'VIN', headerName: CommonUtil.getMessageText(Constants.VIN_NO,'VIN'), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'isGeneric', headerName: CommonUtil.getMessageText(Constants.GENERIC_SPECIFIC,'Generic / Specific'), resizable: true, width: 180, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,'Equipment Type'), resizable: true, width: 180, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'estimatedValue', headerName: CommonUtil.getMessageText(Constants.ESTIMATED_VALUE,'Estimated Value'), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: CommonUtil.getMessageText(Constants.BRAND,'Brand'), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'model', headerName: CommonUtil.getMessageText(Constants.MODEL,'Model'), resizable: true, width: 100, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'rating', headerName: CommonUtil.getMessageText(Constants.RATING,'Rating'),resizable: true, width: 100,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'availabilityCount', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY,'Availability'), resizable: true, width: 130,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'damagesCount', headerName: CommonUtil.getMessageText(Constants.DAMAGES,'Damages'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'createdBy', hide:true,headerName: CommonUtil.getMessageText(Constants.CREATED_BY,'Created By'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'creationDate',hide:true, headerName: CommonUtil.getMessageText(Constants.CREATION_DATE,'Create On'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'lastUpdatedBy', hide:true,headerName: CommonUtil.getMessageText(Constants.LAST_UPDATED_BY,'Last Updated By'), resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'lastUpdateDate', hide:true,headerName: CommonUtil.getMessageText(Constants.LAST_UPDATED_ON,'Last Updated On'), resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'equipmentStatus',headerName: CommonUtil.getMessageText(Constants.STATUS,'Status'),resizable: true,width: 100,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const [companies, setCompanies] = useState([])
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null)

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [brands, setBrands] = useState<any>([]) //CommonUtil.TRAILER_BRAND
    const [selBrandObj, setSelBransObj] = useState<any>(null)

    const [modals, setModals] = useState<any>([]) //CommonUtil.EQUiP_MODELS
    const [selModalObj, setSetModalObj] = useState<any>(null)

    const [equipStatusObj,setEquipmentStatusObj] = useState<any>(defObj);

    //const [rowData,setRowData] = useState([]);

    useEffect(() => {
        equipmentId = "";
        CommonUtil.dispatchEVents();
        CommonUtil.SCREEN_NAME = CommonUtil.EQUIPMENTS;

        publish(Events.EQUIPMENT_EVENT,{data:Events.EQUIPMENTS})

        setEquipmentStatusObj(defObj);
        setSelEquipObj(null);
        setEquipTypes([]);
        setSelBransObj(null);
        setBrands([]);
        setSelAssetObj(null);
        setEquipData([]);
        setSelEquipObj(null);
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data);
            })
        }else{
            setTimeout(()=>{
               // getEquipmentList();
                let qParams: string = "";
                if(CommonUtil.isRootUser()){
                    qParams =  'saasOrgId=' + (selCompanyObj ? selCompanyObj.orgId : '');
                }else{
                    qParams =  'saasOrgId=' + CommonUtil.getSaasOrgId();
                }
                qParams = qParams + '&equipmentTypeId=';// + (selEquipObj ? selEquipObj.key : '');
                qParams = qParams + '&brand=';// + (selBrandObj ? selBrandObj.Key : '');
                qParams = qParams + '&assetTypeId=';// + (selAssetObj ? selAssetObj.key : '');
                qParams = qParams + '&status=';// + (equipStatusObj ? equipStatusObj.key : '');

                setEquipData([]);
                let equipAllAPI: string = 'api/equipment/all?' + qParams;
                let obj: any = {};
                NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
            },500)
        }

        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data;
            setAssetTypes(data)
        })
        CommonUtil.getAllModels((data: any) => {
            CommonUtil.EQUiP_MODELS = data;
            setModals(data)
        })

        document.addEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
           // alert("Equipments")
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
            document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
            setEquipmentStatusObj(defObj);
            setSelEquipObj(null);
            setEquipTypes([]);
            setSelBransObj(null);
            setBrands([]);
            setSelAssetObj(null);
            setEquipData([]);
        }

    }, [CommonUtil.EQUIPMENT_LIST])

    let onSwitchCustomer = (event: any)=>{
        //console.log(divRef, 'divRef');
       /* if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        //getEquipmentList();
        setEquipData([]);
        setCompanyVisible(CommonUtil.isRootUser());
        if(CommonUtil.isRootUser()){
            setSelCompanyObj(null);
        }else{
            getEquipmentList();
        }
        setPage(""+Math.random()*10000);*/
        Events.dispatchBreadCrumbEvent();
    }

    let onLanguageChange = ()=>{
        setPage(""+Math.random()*100000);
    }




    let getEquipmentList = () => {
        let qParams: string = "";
        if(CommonUtil.isRootUser()){
            qParams =  'saasOrgId=' + (selCompanyObj ? selCompanyObj.orgId : '');
        }else{
            qParams =  'saasOrgId=' + CommonUtil.getSaasOrgId();
        }
        qParams = qParams + '&equipmentTypeId=' + (selEquipObj ? selEquipObj.key : '');
        qParams = qParams + '&brand=' + (selBrandObj ? selBrandObj.Key : '');
        qParams = qParams + '&assetTypeId=' + (selAssetObj ? selAssetObj.key : '');
        qParams = qParams + '&status=' + (equipStatusObj ? equipStatusObj.key : '');

        setEquipData([]);
        let equipAllAPI: string = 'api/equipment/all?' + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }

                equips.forEach((item:any,idx:number)=>{
                    if(item.isGeneric){
                        item.isGeneric = item.isGeneric == "Y"?CommonUtil.EQUIP_GENERIC:CommonUtil.EQUIP_SPECIFIC;
                    }
                    item.UnitEquipName = (item.unitNumber?item.unitNumber:item.genericEquipmentName);
                    //delete item['genericEquipmentName'];
                })


                setEquipData(equips);
            }
        }
    }

    let onClickLink = (data: any) => {
        equipmentId = data.equipmentId;
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
        navigate('equipviewdetails');
        // console.log(data);
        //navigate('equipviewdetails');
        //navigate('equippublic');
    }
    let onClickAddEquipment = () => {
        CommonUtil.SELECT_EQUIP_ID = ''

        setShowEquipPopup(true)
        // navigate("equipDetails");
    }

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }

    let onAssetTypeChange = (dataObj: any) => {
        setSelAssetObj(dataObj)
        // getEquipmentTypes(dataObj);
        setSelEquipObj(null);
        setEquipTypes([]);
        setSelBransObj(null);
        setBrands([]);

        if (dataObj) {
            CommonUtil.getEquipmentTypes(dataObj.key, (data: any) => {
                setEquipTypes(data)
            })
            if(dataObj.value == CommonUtil.ASSET_TYPE_TRAILER){
                setBrands(CommonUtil.TRAILER_BRAND);
            }else{
                setBrands(CommonUtil.TRUCK_BRAND);
            }
            CommonUtil.getBrands(dataObj.key, (data: any) => {
               // setBrands(data)
                //console.log(data);
            })
        }
    }

    let onEquipmentTypeChange = (dataObj: any) => {
        setSelEquipObj(dataObj)
    }

    let onModalChange = (dataObj: any) => {
        setSetModalObj(dataObj)
    }

    let onBrandChange = (dataObj: any) => {
        setSelBransObj(dataObj)
    }

    let onCloseEquipPopup = () => {
        setShowEquipPopup(false)
    }

    let onClickGeneric = () => {
        publish(Events.EQUIPMENT_EVENT,{data:Events.CREATE_EQUIPMENT})
        CommonUtil.IS_GENERIC_EQUIP = true
        navigate('equipDetails')
        onCloseEquipPopup()
    }

    let onClickSpecific = () => {
        publish(Events.EQUIPMENT_EVENT,{data:Events.CREATE_EQUIPMENT})
        CommonUtil.IS_GENERIC_EQUIP = false
        navigate('equipDetails')
        onCloseEquipPopup()
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devEstPopupEquip', 'devEstContentPopupEquip')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 400;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let handleEstPopup = ()=>{
        setEstimatedValue("")
        setSowEstValuePopup(false);
        navigate('equipviewdetails');
    }

    let handleEstimatedValue = (e: any) => {
        setEstimatedValue(e.target.value)
        // CommonUtil.isMandatory(estValueRef);
    }


    let handleSaveEstimatedValue = ()=>{
        let availCraateApi:string = "";
        let method:string = "PUT";
        availCraateApi = "api/equipment/updateEstimatedValue/"+equipmentId+"?estimatedValue="+estimatedValue;
        NetworkManager.sendJsonRequest(availCraateApi,'',"",method,true,onEstimatedValueUpdated,onLoginError);
    }

    let onEstimatedValueUpdated = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setEstimatedValue("")
                setSowEstValuePopup(false);
                ToastManager.showToast(CommonUtil.getMessageText(Constants.SUCCESSFULLY_UPDATED_EST_VALUE, 'Successfully updated estimated value'), 'Success');
                navigate('equipviewdetails');
            }
        }
    }

    let onClickExportList = ()=>{
        let orgId = "";
        orgId = CommonUtil.getSelectedOrgId();
        let equipId = "";
        let brand = "";
        let assetType = "";
        let status = "";

       // qParams = qParams + '&status=' + (equipStatusObj ? equipStatusObj.key : '');
        if(CommonUtil.isRootUser()){
            orgId = selCompanyObj?selCompanyObj.orgId:"";
        }
        if(orgId){
            assetType = selAssetObj?selAssetObj.key:"";
            equipId = selEquipObj?selEquipObj.key:"";
            brand = selBrandObj?selBrandObj.Key:"";
            status = equipStatusObj?equipStatusObj.key:"";
            let availCraateApi:string = "";
            let method:string = "GET";
            availCraateApi = "api/equipment/equipmentExport?assetTypeId="+assetType+"&brand="+brand+"&equipmentTypeId="+equipId+"&saasOrgId="+orgId+"&status="+status;
            NetworkManager.sendJsonRequest(availCraateApi,'',"",method,true,onExportEquipments,onLoginError);
        }
    }

    let onExportEquipments = (dataObj:any)=>{
        //console.log(dataObj);
        try {
            const blob = new Blob([dataObj], { type: 'text/csv' });

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob)

            // Creating an anchor(a) tag of HTML
            const a = document.createElement('a')

            // Passing the blob downloading url
            a.setAttribute('href', url)

            // Setting the anchor tag attribute for downloading
            // and passing the download file name
            a.setAttribute('download', 'equipments.csv');

            // Performing a download with click
            a.click()
        }catch (ex){}

    }

    let onStatusChange = (dataObj:any)=>{
        if(dataObj){
            setEquipmentStatusObj(dataObj);
        }else{
            setEquipmentStatusObj(defObj);
        }
    }


    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <Modal show={showEstValuePopup} centered dialogAs={DraggableComponent} id="devEstPopupEquip" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devEstContentPopupEquip" style={{border:"1px solid green"}}>
                    <Modal.Header className="modal-header" closeButton={false}>
                        <h5 className="modal-title mt-0" style={{fontSize:"15px"}}>{CommonUtil.getMessageText(Constants.EST_EQUIP_VALUE, 'Equipment Estimated Value')}</h5>
                        <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close"
                                onClick={handleEstPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ENTER_ESTIMATED_VALUE, 'Enter Estimated Value')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" autoFocus={true} className="form-control" ref={estValueRef}
                                               placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_VALUE, 'Estimated Value')} value={estimatedValue}
                                               onChange={handleEstimatedValue}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={handleEstPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={handleSaveEstimatedValue}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>

            </Modal>

            <Modal show={showEquipPopup} dialogAs={DraggableComponent} >
                <div className="modal-content" style={{width:'650px',height: '500px'}}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0 fw-bold text-center black-text" style={{width: '97%', marginLeft: '3%'}}>{CommonUtil.getMessageText(Constants.NEW_EQUIPMENT,'New Equipment')}</h5>
                        {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black my-2" style={{paddingTop: '10px'}}>
                        <div className="row fs-7 px-6">
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 specific-equip p-3"  onClick={onClickSpecific}>
                                    <img src={"../../images/specific-equipment.png"} style={{width: '110px'}}/>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        <strong>{CommonUtil.getMessageText(Constants.SPECIFIC_EQUIP,'Specific Equipment')}</strong>{' '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        {CommonUtil.getMessageText(Constants.THE_SPECIFIC_EQUIP,'This specific equipment')} <br/>
                                        {CommonUtil.getMessageText(Constants.EQUIP_MADE_AVAIL,'Will be the one made available')} <br/>
                                        {CommonUtil.getMessageText(Constants.FOR_THE_RENTERS,'for the renters.')}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        {CommonUtil.getMessageText(Constants.NEED_TO_ADD,'You will need to add')} <br/>
                                        {CommonUtil.getMessageText(Constants.AVAIL_THIS_EQUIP,'availabilities for this specific equipment to available for the renter.')}

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 generic-equip p-3"  onClick={onClickGeneric}>
                                    <img src={"../../images/general-equipment.png"} style={{width: '110px'}}/>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        <strong>{CommonUtil.getMessageText(Constants.GENERIC_EQUIP,'Generic Equipment')} </strong>{' '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        {CommonUtil.getMessageText(Constants.SIMILAR_EQUIPS,'Display multiple similar equipment in a single listing to\n' +
                                            'simplify the management of their availabilites.')}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem',fontSize:"13px"}}>
                                        {CommonUtil.getMessageText(Constants.GENERIC_EQUIP_RENTED,'When a generic equipment is rented, you will need to assign\n' +
                                            'a specific equipement before the start of the reservation.')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        {/* <button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal"
                                onClick={onCloseEquipPopup}>Cancel</button> */}
                        <button type="button" className="btn btn-outline-dark btn-sm"
                            style={{backgroundColor: '#00CFB4', width: isMobile ? 'fit-content': '14%', color: '#fff'}}
                            data-bs-dismiss="modal" onClick={onCloseEquipPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className="row">
                    <div className="col-md-2 col-12 pt-2" style={{paddingLeft: '5px', zIndex: 99,display:(isCompanyVisible?"":"none")}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY,'Company')}</span>
                        <SingleSelectComp dataSource={orgData} selectedValue={selCompanyObj}
                                          width={'100%'} handleChange={onCompanyChange}
                                          value={'orgId'} label={'orgName'}/>

                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: 98}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.ASSET_TYPE,'Asset Type')}</span>
                        <SingleSelectComp dataSource={assetTypes} selectedValue={selAssetObj}
                                          width={'100%'} handleChange={onAssetTypeChange}
                                          value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: 97}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.EQUIP_TYPE,'Equipment Type')}</span>
                        <SingleSelectComp dataSource={equipTypes} selectedValue={selEquipObj}
                                          width={'100%'} handleChange={onEquipmentTypeChange}
                                          value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: 96}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.BRAND,'Brand')}</span>
                        <SingleSelectComp dataSource={brands} selectedValue={selBrandObj}
                                          width={'100%'} handleChange={onBrandChange}
                                          value={'Key'} label={'Value'} />
                    </div>
                    <div className="col-md-2 col-6 pt-1" style={{zIndex: '98'}}>
                        <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.STATUS,"Status")}</span>
                        <SingleSelectComp dataSource={CommonUtil.EQUIPMENT_STATUSES} selectedValue={equipStatusObj}
                                          handleChange={onStatusChange} width={'100%'} value={'key'} label={'value'} />
                    </div>
                    {/*<div className="col-md-2 col-6 pt-2" style={{zIndex: 990}}>
                        <span className="black-text css-16ihq8s">Model</span>
                        <SingleSelectComp dataSource={modals} selectedValue={selModalObj}
                                          width={'100%'} handleChange={onModalChange} value={'key'} label={'value'} />
                    </div>*/}

                    <div className="col-md-2 col-6 col-lg-1 row">
                        <div className="col-md-1" style={{paddingTop: '22px'}}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height" style={{width:"100px"}} onClick={getEquipmentList}>
                                {CommonUtil.getMessageText(Constants.SEARCH,'Search')}
                            </button>
                        </div>

                    </div>
                    <div className="col-md-2 col-6 col-lg-1 row">
                        <div className="col-md-1" style={{paddingTop: '22px'}}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height" style={{width:"100px"}} onClick={onClickExportList}>
                                {CommonUtil.getMessageText(Constants.EXPORT_LIST,'Export')}
                            </button>
                        </div>

                    </div>

                </div>
                    {
                        isMobile ? (
                        <MGrid id="OrgEquipsGrid" cols={eqpColumnDefs}
                            rows={equipData} pageSize={10} headerHeight={30} rowHeight={40}
                            ref={gridRef} addNew={CommonUtil.EQUIPMENT_LIST?true:false} tooltip={CommonUtil.getMessageText(Constants.ADD_EQUIPMENT,'Add Equipment')} addNewRecord={onClickAddEquipment}
                            method={{onClickLink}} />
                        ):(
                <div className="row fs-7 pt-3">
                    <div className="ag-theme-alpine gridHeight"
                        style={{height: 'calc(100vh - 170px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                        <AGGrid id={`OrgEquipsGrid${CommonUtil.EQUIPMENT_LIST?'_a':''}`} cols={eqpColumnDefs}
                            rows={equipData} pageSize={500} headerHeight={30} rowHeight={40}
                            ref={gridRef} addNew={CommonUtil.EQUIPMENT_LIST?true:false} tooltip={CommonUtil.getMessageText(Constants.ADD_EQUIPMENT,'Add Equipment')} addNewRecord={onClickAddEquipment}
                            method={{onClickLink}} />
                    </div>
                </div>
                        )
                    }
            </div>
        </>
    )
}

export default EquipmentList
