import React, {useEffect, useRef, useState} from 'react';
import DriverList from './DriverList/DriverList'
import {NetworkManager} from "../../../Components/NetworkManager";
import {Modal} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import ToastManager from "../../../Components/ToastManager";
import {CommonUtil} from "../../../utils/CommonUtil";
import SingleSelectComp from "../../../Components/SingleSelectComp";
import {subscribe} from "../../Login/CustomEvents";
import {Events} from "../../Languages/Events";
import DetectDevice from '../../../utils/DetectDevice';
import { Constants } from '../../Languages/Constants';
import $ from 'jquery';

var selOrgKey:string = "";
const Driver = () => {
    const { isMobile } = DetectDevice();
    const [modalShow, setModalShow] = useState(false);
    const [mode, setMode] = useState('new');
    const [name, setName] = useState<any>('');
    const [status, setStatus] = useState('Active');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [truckNumber, setTruckNumber] = useState('');
    const [driversList, setDriversList] = useState([]);
    const [driverId, setDriverId] = useState('')
    const [isCompanyVisible,setCompanyVisible] = useState(CommonUtil.isRootUser());
    const [orgData, setOrgData] = useState(null)
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null);
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const [page,setPage] = useState<any>("");

    const[carrierName,setCarrierName] = useState("");
    const[usdotNumber,setUSDOTNumber] = useState("");



    const handleShow = () => {
        setModalShow(true);
    }
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const handleClose = () => {
        setMode('new')
        setModalShow(false);
        setStatus('Active')
        setName('')
        setPhoneNumber('')
        setTruckNumber('')
        setEmail('');
        setCarrierName("");
        setUSDOTNumber("");
    }

    let onGetAllDrivers = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    setDriversList(restData);
                }
            }
        }
    }

    let onLoginError = (err: any) => {
        console.log(err);
    }

    let getAllDrivers = () => {
        if(CommonUtil.isRootUser()){
            let qParam = CommonUtil.getSelectedOrgId();
            let orgUrl = "api/driver/all?saasOrgId=" + qParam;
           // NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDrivers, onLoginError);
        }else{
            let qParam = CommonUtil.getSelectedOrgId();
            let orgUrl = "api/driver/all?saasOrgId=" + qParam;
            NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDrivers, onLoginError);
        }

    }

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        setCompanyVisible(CommonUtil.isRootUser());
        setDriversList([]);
        if(CommonUtil.isRootUser()){
            setSelCompanyObj(null);
            setPage(""+Math.random()*10000);
        }else{
            getDriverList();
        }

    }

    useEffect(() => {
        //subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        getAllDrivers();
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }
    }, []);
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e)
    }
    const handleInputName = (e: any) => {
        setName(e.target.value);
        CommonUtil.isMandatory(nameRef);
    }
    const handleInputEmail = (e: any) => {
        setEmail(e.target.value);
        let flag:boolean = CommonUtil.isValidEmail(e.target.value);
        if(flag){
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        }else{
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }

    }
    const handleInputStatus = (e: any) => {
        setStatus(e.target.value)
    }

    const handleInputNumber=(e: any)=>{
        setTruckNumber(e.target.value)
     }

     let handleCarrierName = (e:any)=>{
        setCarrierName(e.target.value);
     }

    let handleUSDOTNumber = (e:any)=>{
        setUSDOTNumber(e.target.value);
    }



    let onSuccess = (dataObj:any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                getDriverList();
                ToastManager.showToast(mode === 'edit' ? 'Driver updated.' : 'Driver created.', 'Success')
                handleClose();
            }
        }

    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        let isName: boolean = CommonUtil.isMandatory(nameRef);
        let isPhone: boolean = true;
        let isEmail: boolean = true;
        if (email && email.length > 0){
            isEmail = CommonUtil.isValidEmail(email);
        }
        if(!phoneNumber.trim() || phoneNumber.length < 11){
            isPhone = false
            setPhoneClass('is-invalid')
        }
        if( isName && isEmail && isPhone) {

            let newData = {name:name.trimStart().trimEnd(), phoneNumber, status, email,truckNumber,carrierName,usdot:usdotNumber}
            if (mode === 'new') {
                let orgUrl = "api/driver/?saasOrgId="+CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", true, onSuccess, onLoginError);
            }
            if (mode === 'edit') {
                let orgUrl = "api/driver/";
                NetworkManager.sendJsonRequest(orgUrl + driverId, newData, "", "PUT", true, onSuccess, onLoginError);
            }
            handleClose();
        }
    }
    const onClickEditDriver = (data: any) => {
        setDriverId(data.driverId);
        setName(data.name);
        setPhoneNumber(data.phoneNumber);
        setStatus(data.status);
        setEmail(data.email);
        setTruckNumber(data.truckNumber);
        setUSDOTNumber(data.usdot);
        setCarrierName(data.carrierName);
        handleShow()
        setMode('edit')
    }
    let onCompanyChange = (dataObj: any) => {
        if(dataObj){
            selOrgKey = dataObj.orgId;
            setSelCompanyObj(dataObj);
            getDriverList();
        }else{
            setSelCompanyObj(null);
            setDriversList([]);
        }

    }
    let onSuccessDriversGet = (data:any)=>{
        if (data) {
            let restData = CommonUtil.parseData(data)
            if (restData) {
                let drivers:any = []
                if (Array.isArray(restData)) {
                    drivers = restData
                } else {
                    drivers.push(restData)
                }
               setDriversList(drivers);
            }
        }
    }
    let getDriverList = () => {
        let qParams: string = "";//
        if(CommonUtil.isRootUser()){
            qParams =  'saasOrgId=' + selOrgKey;
        }else{
            qParams =  'saasOrgId=' + CommonUtil.getSelectedOrgId();
        }

        let driverAllAPI: string = 'api/driver/all?' + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(driverAllAPI, '', '', 'GET', true, onSuccessDriversGet, onLoginError);
    }
    return (
        <>
            {/* <div className={'container-fluid px-1'} >
                    <div className="row col-12 m-0" style={{display:(isCompanyVisible?"":"none")}}>*/}
            <div className={'container-fluid'} style={{paddingLeft:"10px",paddingRight:"15px"}} >
                    <div className="row" style={{display:(isCompanyVisible?"":"none")}}>
                        <div className="col-md-2 col-12 pt-2" style={{paddingLeft: '10px', zIndex: 999}}>
                            <span className="black-text css-16ihq8s">{CommonUtil.getMessageText(Constants.COMPANY, 'Company')}</span>
                            <SingleSelectComp
                                dataSource={orgData}
                                selectedValue={selCompanyObj}
                                width={'100%'}
                                handleChange={onCompanyChange}
                                value={'orgId'} label={'orgName'}
                            />

                        </div>

                        <div className="col-md-12 col-lg-1 row" style={{display:"none"}}>
                            <div className="col-md-1" style={{paddingTop: '22px'}}>
                                <span className="black-text css-16ihq8s"> </span>
                                <button type="button" className="btn btn-warning cus-btn btn-sm button-height" onClick={getDriverList}>
                                    {CommonUtil.getMessageText(Constants.SEARCH, 'Search')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <DriverList
                            onClickEditDriver={onClickEditDriver}
                            handleShow={handleShow}
                            driversList={driversList}
                        />
                </div>



            <Modal show={modalShow} centered onHide={() => {setMode('new')}}>
                <div className="modal-content" style={{"width": "600px"}}>
                    <Modal.Header className="modal-header" closeButton={false}>
                        <h5 className="modal-title mt-0">{mode === 'new' ? CommonUtil.getMessageText(Constants.CREATE_DRIVER, 'Create Driver') : CommonUtil.getMessageText(Constants.UPDATE_DRIVER, 'Edit Driver')}</h5>
                        <button type="button" className="close" style={{marginLeft:"0px"}} data-dismiss="modal" aria-label="Close"
                                onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.DRIVER_NAME, 'Driver Name')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <input type="text" ref={nameRef} name="parkingLotName"
                                           className="form-control clsFormInputControl"
                                           placeholder={CommonUtil.getMessageText(Constants.ENTER_DRIVER_NAME,"Enter Drive Name")} value={name}
                                           onChange={handleInputName}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.STATUS,'Status')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12">
                                    <select name="status" className={'form-control'} onChange={handleInputStatus} value={status}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.MOBILE_NUMBER, 'Mobile Number')} <span className="text-danger"></span>
                                </label>
                                <div className="col-sm-12" style={{zIndex: 9}}>

                                    <PhoneInput
                                        country={'us'}
                                        countryCodeEditable={false}
                                        containerClass={phoneClass}
                                        value={phoneNumber}
                                        placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, "Enter Phone Number")}
                                        inputClass={`input-phone ${phoneNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                        onChange={(e) => {
                                            handleInputPhone(e)
                                        }}
                                        containerStyle={{
                                            width: '100%',
                                        }}
                                        disableCountryGuess={true}
                                        disableDropdown={true}
                                        disableSearchIcon={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}
                                </label>
                                <div className="col-sm-12">
                                    <input className="form-control" ref={emailRef}
                                           placeholder={CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}
                                           type={'email'} name="email"
                                           value={email}
                                           onChange={handleInputEmail}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.TRUCK_NUMBER, 'Truck Number')}
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control"
                                          placeholder="Enter Truck Number"
                                          type='text' maxLength={20}
                                          value={truckNumber}
                                          onChange={handleInputNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {"Carrier Name"}
                                </label>
                                <div className="col-sm-12">
                                    <input className="form-control"
                                           placeholder="Carrier Name"
                                           type='text' maxLength={20}
                                           value={carrierName}
                                           onChange={handleCarrierName}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">
                                    {"USDOT Number"}
                                </label>
                                <div className="col-sm-12">
                                    <input className="form-control"
                                           placeholder="USDOT Number"
                                           type='text' maxLength={20}
                                           value={usdotNumber}
                                           onChange={handleUSDOTNumber}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark"
                                data-dismiss="modal" onClick={handleClose}>
                            {CommonUtil.getMessageText(Constants.CANCEL, 'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default Driver;
