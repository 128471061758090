import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import {CommonUtil} from "../../utils/CommonUtil";
import {Constants} from "../Languages/Constants";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import DraggableComponent from "../../Components/DraggableComponent";
import {Modal} from "react-bootstrap";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import Flatpickr from "react-flatpickr";
import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import Confirm from "../../Components/confirm";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Events} from "../Languages/Events";

var insurancePolacyId = "";
var operation = "";
var fileType = "";
var imagePath = "";
var insurDocs:any = [];

const ActionItemRender = (props: any) => {
    const onClickLink = () => {

    }
    return (
        <div className={"cellRenderHeader"}>
            {
                (props.value && props.value.toLowerCase() == CommonUtil.ACTIVE.toLowerCase()) ?
                    <button className="btn btn-success active-status">{(props.value)}</button> :
                    <button className="btn btn-success in-active-status">{props.value ? props.value : 'Inactive'}</button>
            }
        </div>

    )
}

const insurPolacyRender = (propData:any) => {

    let onClickLink = ()=>{
        if(propData.context){
            propData.context.onClickLink(propData.data);
        }
    }
    return(
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickLink} className={"cellRender"}>{propData.data.policyId?propData.data.policyId:""}</span></a>
        </div>
    )
}
const InsurancePlans = ()=>{

    const [insurColumnDefs] = useState([
        {field: 'policyId', headerName: CommonUtil.getMessageText(Constants.INSUR_POLICY_ID,'Policy Id'), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter',cellRenderer:insurPolacyRender},
        {field: 'insuranceCompanyName', headerName: CommonUtil.getMessageText(Constants.INSURENCE_NAME,'Company Name'), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'insurancePlanType', headerName: CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE,'Plan Type'), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'deductible', headerName: CommonUtil.getMessageText(Constants.DEDUCTABLE,'Deductible'), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'limit', headerName: CommonUtil.getMessageText(Constants.LIMIT,'Limit'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'taxPercentage', headerName: CommonUtil.getMessageText(Constants.TAX_PERCENTAGE,'Tax Percentage'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'isDefault', headerName: "Is Default", resizable: true, width: 120,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'isTermsAndConditionsPresnt', headerName: CommonUtil.getMessageText(Constants.INSUR_COVERAGE_DOC,'Terms & Conditions'),resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'isBrochurePresent', headerName: CommonUtil.getMessageText(Constants.BROCHURE_DOC,'Brochure Document'), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'insuranceStatus', headerName: CommonUtil.getMessageText(Constants.STATUS,'Status'), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter',cellRenderer:ActionItemRender},

    ]);
    const policyRef = useRef<HTMLInputElement>(null);
    const insurNameRef = useRef<HTMLInputElement>(null);
    const deductRef = useRef<HTMLInputElement>(null);
    const limitRef = useRef<HTMLInputElement>(null);
    const fileref = useRef<HTMLInputElement>(null);
    const taxPercentRef = useRef<HTMLInputElement>(null);
    const trailerEstValRef = useRef<HTMLInputElement>(null);
    const ratePerDayRef = useRef<HTMLInputElement>(null);



    const chkDefault = useRef<HTMLInputElement>(null);
    const [policyId,setPolicyId] = useState("");
    const [insuranceName,setInsuranceName] = useState("");
    const [deductable,setDeductable] = useState("");
    const [limit,setLimit] = useState("");
    const [description,setDescription] = useState("");
    const [fileNmae, setFileName] = useState('');
    const [page, setPage] = useState(0);
    const [insurDocPath, setInsurDocPath] = useState<any>([]);
    const [insurPlanType, setInsurPlanType] = useState<any>(null);
    const [insurPlanStatus, setInsurPlanStatus] = useState<any>(CommonUtil.ACTIVE);
    const [equipClass, setEquipClass] = useState('mandatory');
    const [statusClass, setStatusClass] = useState('mandatory');

    const [taxPercent, setTaxPercent] = useState('5');

    const [trailerEstValue, setTrailerEstValue] = useState("");
    const [ratePerDay, setRatePerDay] = useState("");




    const [isDefaultChecked, setDefaultChecked] = useState(false);


    const gridRef = useRef<IAGrid>(null);
    const [showInsurPlanPopup,setShowInsurPlanPopup] = useState(false);
    const [insurData, setInsurData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{
            if(gridRef.current){
                gridRef.current.columnFittoSize();
            }
        },1000);
    })

    let resetForm = ()=>{
        setPolicyId("");
        setInsuranceName("");
        setDeductable("");
        setDescription("");
        setLimit("");
        setTaxPercent("5");
        setInsurPlanStatus(null);
        setInsurPlanType(null);
        insurDocs = [];
        setDefaultChecked(false);
        setRatePerDay("");
        setTrailerEstValue("");
        setInsurDocPath([]);
    }

    let onClickAddInsurancePlan = ()=>{
        operation = CommonUtil.CREATE_MODE;
        insurancePolacyId = "";
        resetForm();
        setShowInsurPlanPopup(true);
    }
    let handleCloseInsurPlan = ()=>{
        setShowInsurPlanPopup(false);
        getInsurancePolacies();
    }
    let onClickLink = (data: any) => {
        resetForm();
        operation = CommonUtil.UPDATE_MODE;
        console.log(data);
        insurancePolacyId = data.id;
        let qParams: string = "";
        getInsurancePolicyDetails();
    }

    let getInsurancePolicyDetails = ()=>{
        let polacyAPI: string = 'api/insurance/?policyId='+insurancePolacyId;
        NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetPolicyDetails, onLoginError);
    }

    let onGetPolicyDetails = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
               setPolicyId(restData.policyId);
               setInsurPlanType({key:restData.insurancePlanType,value:restData.insurancePlanType});
                setInsurPlanStatus({Key:restData.insuranceStatus,Value:restData.insuranceStatus});
                setInsuranceName(restData.insuranceCompanyName);
                setDeductable(restData.deductible);
                setDescription(restData.detailedDesciption);
                setLimit(restData.limit);
                setTaxPercent(restData.taxPercentage);
                setDefaultChecked((restData.isDefault == "Y")?true:false);
                setTrailerEstValue(restData.estimatedValue);
                setRatePerDay(restData.perDayInsuranceValue);

                if(restData.isDefault == "N"){
                    setTrailerEstValue("");
                    setRatePerDay("");
                }

                let attachments = [];//restData.insuranceAttachmentDTO;
                if(restData.insuranceAttachmentDTO){
                    if(Array.isArray(restData.insuranceAttachmentDTO)){
                        attachments = restData.insuranceAttachmentDTO;
                    }else{
                        attachments.push(restData.insuranceAttachmentDTO);
                    }
                }
                insurDocs = [];
                if(attachments){
                    attachments.forEach((item:any,idx:number)=>{
                        item.Path = item.url;
                        item.fullFilePath = item.url;
                        item.attachementId = item.insuranceAttachmentId;
                        insurDocs.push(item);
                    });
                }

                setInsurDocPath(insurDocs);

                setShowInsurPlanPopup(true);
            }
        }
    }
    let onChangePolicyId = (e: any)=>{
        setPolicyId(e.target.value)
        CommonUtil.isMandatory(policyRef);
    }
    let onChangeInsuranceName = (e:any)=>{
        setInsuranceName(e.target.value)
        CommonUtil.isMandatory(insurNameRef);
    }
    let onChangeDeductable = (e:any)=>{
        setDeductable(e.target.value)
        CommonUtil.isMandatory(deductRef);
    }
    let onChangeLimit = (e:any)=>{
        setLimit(e.target.value)
        CommonUtil.isMandatory(limitRef);
    }
    let onChangeTaxPercent = (e:any)=>{
        setTaxPercent(e.target.value)
        CommonUtil.isMandatory(taxPercentRef);
    }

    let onChangeTrailerEstValue = (e:any)=>{
        setTrailerEstValue(e.target.value)
        CommonUtil.isMandatory(trailerEstValRef);
    }

    let onChangeRatePerDay = (e:any)=>{
        setRatePerDay(e.target.value)
        CommonUtil.isMandatory(ratePerDayRef);
    }







    let onChangeDesc = (e:any)=>{
        setDescription(e.target.value);
    }

    let handleSaveInsurace = ()=>{
        (insurPlanType) ? setEquipClass('select-box-valid') : setEquipClass('select-box-invalid');
        (insurPlanStatus) ? setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
        let isPolicy: boolean = CommonUtil.isMandatory(policyRef);
        let isInsurName: boolean = CommonUtil.isMandatory(insurNameRef);
        let isDeductable: boolean = CommonUtil.isMandatory(deductRef);
        let isLimit: boolean = CommonUtil.isMandatory(limitRef);
        let isTax: boolean = CommonUtil.isMandatory(taxPercentRef);

        if(isPolicy && isInsurName && isDeductable && isLimit && insurPlanType && insurPlanStatus && taxPercent){
            if(isDefaultChecked){
                let isEstValue: boolean = CommonUtil.isMandatory(trailerEstValRef);
                let isRatePerDay: boolean = CommonUtil.isMandatory(ratePerDayRef);
                if(isEstValue && isRatePerDay){

                }else{
                    return;
                }
            }
            let restObj:any = {};
            restObj.policyId = policyId;
            restObj.insuranceCompanyName = insuranceName;
            restObj.insurancePlanType = insurPlanType?insurPlanType.key:"";
            restObj.insuranceStatus = (insurPlanStatus.Key == CommonUtil.ACTIVE)?"Y":"N";
            restObj.deductible = deductable;
            restObj.limit = limit;
            restObj.taxPercentage = taxPercent;
            restObj.detailedDesciption = description;
            restObj.isDefault = isDefaultChecked?"Y":"N";
            restObj.estimatedValue = trailerEstValue;
            restObj.perDayInsuranceValue = ratePerDay;

            let insurAPI: string = 'api/insurance/';
            let method:string = "POST";
            if(operation == CommonUtil.UPDATE_MODE){
                insurAPI= 'api/insurance/updatePolicy/?policyId='+insurancePolacyId;
                method = "PUT";
            }

            NetworkManager.sendJsonRequest(insurAPI, restObj, '', method, true, onCreatePolicy, onLoginError);
        }

    }

    let onCreatePolicy = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strmessage:string = CommonUtil.getMessageText(Constants.INSURANCE_POLICY_CREATED,'Insurance Policy Created Successfully');//"Insurance Policy Created Successfully";
                if(insurancePolacyId){
                    strmessage = CommonUtil.getMessageText(Constants.INSURANCE_POLICY_UPDATED,'Insurance Policy Updated Successfully');//"Insurance Policy Updated Successfully";
                    ToastManager.showToast(strmessage, 'Info');
                    getInsurancePolacies();
                    setShowInsurPlanPopup(false);
                }else{
                    insurancePolacyId = restData.id;
                    if(insurDocs.length>0){
                        uploadAllInsurDocs();
                    }else{
                        setShowInsurPlanPopup(false);
                        ToastManager.showToast(strmessage, 'Info')
                        getInsurancePolacies();
                    }

                }
                //
                //
            }
        }
    }
    let uploadAllInsurDocs = ()=>{
        let arr: any = insurDocs;
        arr.forEach((item:any,idx:number)=>{
            item.policyId = insurancePolacyId;
        });
        var reqObj:any = {};
        reqObj.insuranceAttachmentDTO = arr;
        if(insurancePolacyId){
            let attachmentUrl: string = 'api/insurance/saveAttachment?policyId=' + insurancePolacyId;//localStorage.getItem('saasorgId');
            NetworkManager.sendJsonRequest(attachmentUrl, reqObj, '', 'POST', false, (dataObj:any)=>{
                BusyLoader.hideLoader();
                let strmessage:string = "Insurance Policy Created Successfully";
                ToastManager.showToast(strmessage, 'Info')
                getInsurancePolacies();
                setShowInsurPlanPopup(false);

            }, onLoginError);
        }else{
            BusyLoader.hideLoader();
            let strmessage:string = "Insurance Policy Created Successfully";
            ToastManager.showToast(strmessage, 'Info')
            getInsurancePolacies();
            setShowInsurPlanPopup(false);
        }
    }
    let handleCloseInsurance = ()=>{

    }

    useEffect(()=>{
        insurancePolacyId = "";
        resetForm();
        operation = CommonUtil.CREATE_MODE;
        CommonUtil.dispatchEVents();
        CommonUtil.SCREEN_NAME = CommonUtil.INSURANCE_PLANS;
        getInsurancePolacies();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }
    },[]);

    let onSwitchCustomer = ()=>{
        Events.dispatchBreadCrumbEvent();
    }


    let getInsurancePolacies = () => {
        let qParams: string = "";
        setInsurData([]);
        let equipAllAPI: string = 'api/insurance/allPolicy';
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllPlacies, onLoginError);
    }

    let onGetAllPlacies = (dataObj:any)=>{
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let polacies = []
                if (Array.isArray(restData)) {
                    polacies = restData
                } else {
                    polacies.push(restData)
                }

                setInsurData(polacies);
            }
        }
    }

    let onClickBrowse = (fType: string) => {
        fileType = fType
        if (fileref.current) {
            fileref.current.value = ''
            $('#inputGroupFile').click()
        }
        setFileName('')
    }
    let onSelectFiles = (e: any) => {
        // console.log(e)
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name
            setFileName(fileName)
            BusyLoader.showLoader()
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.INSURANCE_TERMS_CONDITIONS) {
                imagePath = 'documents/insurance/terms/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime();
            } else {
                imagePath = 'documents/insurance/broucher/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime();
            }
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }
    const onUploadImage = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader()
                var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
                ToastManager.showToast(errMsg, 'Error')
            } else {
                var strUploadPath = dataObj;
                if (fileType == CommonUtil.INSURANCE_TERMS_CONDITIONS || fileType == CommonUtil.INSURANCE_BROUCHER) {
                    if (fileref.current && fileref.current.files) {
                        const formData = new FormData();
                        var myHeaders = new Headers();
                        var file = fileref.current.files[0];
                        var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                        fetch(strUploadPath, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                //BusyLoader.hideLoader();
                                uploadInsuranceDocs();
                            })
                            .catch((error) => {
                                //  BusyLoader.hideLoader();
                                console.log('error', error)
                            })
                    }
                }else{

                }

            }
        }
    }

    let uploadInsuranceDocs = ()=>{
        if ((fileref.current && fileref.current.files && fileref.current.files[0])) {
            let reqObj: any = {};
           // reqObj.insuranceAttachmentDTO = {};
            let obj: any = {};
            if(fileref.current && fileref.current.files){
                obj.documentName = fileref.current.files[0].name;
            }
            obj.category = fileType;
            obj.documentType = fileType;
            obj.Type = fileType;
            obj.url = imagePath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            obj.policyId = insurancePolacyId;
            obj.insuranceAttachmentId = "";
            let arr: any = [];
            arr.push(obj);
            reqObj.insuranceAttachmentDTO = arr;

            if(insurancePolacyId){
                let attachmentUrl: string = 'api/insurance/saveAttachment?policyId=' + insurancePolacyId;//localStorage.getItem('saasorgId');
                NetworkManager.sendJsonRequest(attachmentUrl, reqObj, '', 'POST', false, onUpdateEquipImageSuccess, onLoginError);
            }else{
                let qParam: string = 'objectPath=' + imagePath;
                NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                        //obj.documentName = docuName;
                        obj.Path = dataObj;
                        insurDocs.push(obj);
                        setInsurDocPath(insurDocs);
                        BusyLoader.hideLoader();
                        setPage(Math.random()*100000);
                    },
                    onLoginError,
                )
            }
        }
    }


    let onUpdateEquipImageSuccess = (dataObj: any) => {
        console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){//restData[0].insuranceAttachmentId
                getInsurancePolicyDetails();
            }
        }

    }

    let onDownloadDoc = (filePath:string)=>{
        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }
    let onDownloadFP2506Success = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }
    let onRemoveDoc = (attachId: string) => {
        //console.log(attachId)
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE,'Do you want remove document?'), [{label: 'Yes', callback: function () {
                if (attachId) {
                    //fileAttachmentId = attachId;
                    let removeAPI: string = 'api/insurance/deleteAttachment?insuranceAttachmentId=' + attachId;// + '?saasOrgId=' + localStorage.getItem('saasorgId')
                    //console.log(removeAPI)
                    NetworkManager.sendJsonRequest(removeAPI, '', '', 'DELETE', true, onDeleteInsuranceDoc, onLoginError);
                }
            },
        },
            {label: 'No'},
        ])
    }
    let onDeleteInsuranceDoc = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj == "Attachment deleted successfully"){
            let strRemove = CommonUtil.getMessageText(Constants.REMOVED_SUCCESS,'Removed Successfully');
            ToastManager.showToast(strRemove, 'Info');
            // removeSelectedImage(fileAttachmentId);
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.ERROR,'Error'), 'Error')
        }
        getInsurancePolicyDetails();
    }

    let onChangePlanType = (dataObj:any)=>{
        setInsurPlanType(dataObj);
        (dataObj) ? setEquipClass('select-box-valid') : setEquipClass('select-box-invalid');
    }

    let onChangePlanStatus = (dataObj:any)=>{
        setInsurPlanStatus(dataObj);
        (dataObj) ? setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
    }
    let onDefaultChanged = (e:any)=>{
        setDefaultChecked(e.currentTarget.checked);
    }



    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <input type="file" ref={fileref} className="custom-file-input" id="inputGroupFile"
                   accept="image/*;application/pdf" style={{display: 'none'}} onChange={(e) => {onSelectFiles(e)}} ></input>
            <Modal show={showInsurPlanPopup} centered dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ "width": "650px", height: "600px" }}>
                    <div className="modal-header">
                        <h6 className="modal-title mt-0 fw-bold text-center black-text">{CommonUtil.getMessageText(Constants.CREATE_INSURANCE_PLAN, 'Create Insurance Plan')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleCloseInsurPlan} style={{marginLeft: "0px"}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{overflowY:"auto"}}>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, 'Policy Id')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" autoFocus={true} className="form-control clsFormInputControl" ref={policyRef}
                                               placeholder={CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, 'Policy Id')} value={policyId}
                                               onChange={onChangePolicyId}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.INSURENCE_NAME, 'Insurance Company Name')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={insurNameRef}
                                               placeholder={CommonUtil.getMessageText(Constants.INSURENCE_NAME, 'Insurance Company Name')} value={insuranceName}
                                               onChange={onChangeInsuranceName}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE, 'Insurance Plan Type')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp dataSource={CommonUtil.INSUR_PLAN_TYPES} width={"100%"} selectedValue={insurPlanType}
                                                          value={"key"} label={"value"} handleChange={onChangePlanType} className={equipClass} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.STATUS, 'Status')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp dataSource={CommonUtil.statuses} width={"100%"} selectedValue={insurPlanStatus}
                                                          value={"Key"} label={"Value"} handleChange={onChangePlanStatus} className={statusClass} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.DEDUCTABLE, 'Deductible')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={deductRef} maxLength={10}
                                               placeholder={CommonUtil.getMessageText(Constants.DEDUCTABLE, 'Deductible')} value={deductable}
                                               onChange={onChangeDeductable} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.LIMIT, 'Limit')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={limitRef} maxLength={10}
                                               placeholder={CommonUtil.getMessageText(Constants.LIMIT, 'Limit')} value={limit}
                                               onChange={onChangeLimit} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.TAX_PERCENTAGE, 'Tax Percentage')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={taxPercentRef} maxLength={10}
                                               placeholder={CommonUtil.getMessageText(Constants.TAX_PERCENTAGE, 'Tax Percentage')} value={taxPercent}
                                               onChange={onChangeTaxPercent} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-md-2 col-2">
                                <label className="col-sm-12 col-form-label" style={{visibility:"hidden"}}>
                                    {"dhdh"} <span className="text-danger"></span>
                                </label>
                                <div className="flex col-sm-12">
                                    <input ref={chkDefault} type="checkbox" className="checkBox"
                                           onChange={(e) => {
                                               onDefaultChanged(e);
                                           }} checked={isDefaultChecked ? true : false}
                                    />&nbsp;&nbsp;
                                    <label>Default</label>
                                </div>

                            </div>
                            <div className="col-md-5 col-5" style={{display:(isDefaultChecked?"":"none")}}>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {"Trailer Estimated Value"} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={trailerEstValRef} maxLength={10}
                                               placeholder={"Trailer Estimated Value"} value={trailerEstValue}
                                               onChange={onChangeTrailerEstValue} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-5" style={{display:(isDefaultChecked?"":"none")}}>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {"Rate Per Day"} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={ratePerDayRef} maxLength={10}
                                               placeholder={"Rate Per Day"} value={ratePerDay}
                                               onChange={onChangeRatePerDay} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.INSURANCE_DESC, 'Description')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" style={{ height: "100px" }}
                                                  placeholder={CommonUtil.getMessageText(Constants.INSURANCE_DESC, 'Description')}
                                                  name="instructions" value={description} maxLength={1000}
                                                  onChange={onChangeDesc}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.TERMS_CONDITIONS, 'Terms & Conditions')}
                                </label>
                            </div>
                            <div className="col-md-12 col-12 flex">
                                <div className="col-md-4 col-12" >
                                    <div className="col-sm-12 cus-input-file-text financial-browse-button">
                                        <button type="button" className="btn btn-outline-primary financial-button" onClick={() => {onClickBrowse(CommonUtil.INSURANCE_TERMS_CONDITIONS)}}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                    </div>
                                    <div className="col-sm-12 cus-input-file p-0">

                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="form-group row">

                                        <div className="col-sm-12">
                                            <div className="gallery flex documents-gallary">

                                                <div style={{display: 'flex',overflow:"auto"}}>
                                                    {insurDocPath.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.INSURANCE_TERMS_CONDITIONS)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                             onClick={() => { onDownloadDoc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a  style={{"visibility":insurancePolacyId?"visible":"hidden"}}><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                  onClick={() => { onRemoveDoc(item.attachementId) }} ></i></a>
                                                                        </span>

                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadDoc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="col-sm-12 col-form-label">
                                    {CommonUtil.getMessageText(Constants.BROCHURE_DOC, 'Brochure')}
                                </label>
                            </div>
                            <div className="col-md-12 col-12 flex">
                                <div className="col-md-4 col-12" >
                                    <div className="col-sm-12 cus-input-file-text financial-browse-button">
                                        <button type="button" className="btn btn-outline-primary financial-button" onClick={() => {onClickBrowse(CommonUtil.INSURANCE_BROUCHER)}}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                    </div>
                                    <div className="col-sm-12 cus-input-file p-0">

                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="form-group row">

                                        <div className="col-sm-12">
                                            <div className="gallery flex documents-gallary">

                                                <div style={{display: 'flex',overflow:"auto"}}>
                                                    {insurDocPath.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.INSURANCE_BROUCHER)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                             onClick={() => { onDownloadDoc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a  style={{"visibility":insurancePolacyId?"visible":"hidden"}}><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                                                                               onClick={() => { onRemoveDoc(item.attachementId) }} ></i></a>
                                                                        </span>

                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadDoc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal-footer" style={{padding:"5px"}}>
                        <button type="button" className="btn btn-outline-dark"
                                data-dismiss="modal" onClick={handleCloseInsurPlan}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Close')}
                        </button>
                        <button type="submit" className="btn btn-success"
                                onClick={handleSaveInsurace}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className="row"></div>
                <div className="row fs-7 pt-3">
                    <div className="ag-theme-alpine gridHeight"
                         style={{height: 'calc(100vh - 120px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                        <AGGrid id="OrgInsurPlansGrid" cols={insurColumnDefs}
                                rows={insurData} pageSize={500} headerHeight={30} rowHeight={40}
                                ref={gridRef} addNew={true} tooltip={CommonUtil.getMessageText(Constants.ADD_INSURANCE_PLAN,'Create Insurance Plan')} addNewRecord={onClickAddInsurancePlan}
                                method={{onClickLink}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InsurancePlans;
