import React from "react";

const equipDocumentRender = (props: any) => {

    return (
        <div className={"cellRenderHeader"}>
            <img src="../../images/document.png" className="upload-thumbnail" style={{width: "50px", height: "50px"}}/>
            <span>{props.value}</span>
        </div>

    )
}

export default equipDocumentRender;