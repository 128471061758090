import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";
import { TopEventSelector } from "./UpdatedVisualCompare";
import { PictureComponent } from "../EntryInspectionDetails/EntryPicturesInspection";
let eventSelected: any = [];
const UpdatedPictureCompare = () => {
  const [pictureDetails, setPictureDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>(eventSelected);

  const comparePictureInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/comparePictureInspection?docId=${qparam}&eventId=${selectedEvents}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onComparePictureInspection,
      onLoginError
    );
  };

  useEffect(() => {
    // getEventsOnlyForIdAndStatus();
    comparePictureInspection();
  }, [selectedEvents]);

  let onComparePictureInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i][0];
        const groupName: string = inspectionData.entryEventCode;
        rowData.push(inspectionData);
      }
      // if (rowData.length > 0) {
      //   let exitEntry: any = { ...rowData[0] };
      //   exitEntry.entryEventCode = "Receipt";
      //   exitEntry.entryEndDate = exitEntry.exitEndDate;
      //   exitEntry.pictureInspection.entryTask =
      //     exitEntry?.pictureInspection?.entryTask || [];
      //   rowData.push(exitEntry);
      // }
      if (rowData.length > 0) {
        let exitObj: any = { ...rowData[0] };
        let exitEntry: any = {};
        exitEntry.entryEventCode = "Receipt";
        exitEntry.entryStartDate = exitObj.exitStartDate;
        exitEntry.entryEventId = exitObj.exitEventId;
        exitEntry.pictureInspection = {};
        exitEntry.pictureInspection.entryTask = exitObj?.pictureInspection
          ?.exitTask
          ? exitObj?.pictureInspection?.exitTask?.map((item: any) => {
              let obj = {
                ...item,
                entryImages: item.exitImages,
                entryTaskFullName: item.exitTaskFullName,
                entryTaskName: item.exitTaskName,
              };
              return obj;
            })
          : [];
        rowData.push(exitEntry);
      }
      setPictureDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }
    eventSelected = checkboxCheck.join(",");

    setSelectedEvents(eventSelected);
  };
  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }

  return (
    <div className="containData">
      <TopEventSelector
        releases={releases}
        checkboxCheck={checkboxCheck}
        setReleases={setReleases}
        eventSelected={eventSelected}
        // updateSelction={updateSelction}
        setSelectedEvents={setSelectedEvents}
        selectedEvents={selectedEvents}
      />
      <hr className="mb-3" style={{ width: "98%" }} />

      <div className="row">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight"
          style={{
            height: "calc(100vh - 330px)",
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          <div className="row">
            {
              <div className="tire-details">
                <div className="picture-tab">
                  <div
                    className="row"
                    style={{
                      height: `${window.innerHeight - 330}px`,
                      overflowY: "auto",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {pictureDetails.map((data: any, i: number) => {
                      const picdetails: any =
                        data?.pictureInspection?.entryTask;
                      return (
                        <div style={{ width: "250px" }} key={i}>
                          <div style={{ height: "35px" }}>
                            <b>{data.entryEventCode}</b>
                            {` Date: ${data.entryStartDate}`}
                          </div>
                          {picdetails?.map((ele: any, i: number) => {
                            let imgData: any = {
                              ...ele,
                              images: [...ele?.entryImages?.attachmentUploads],
                              position: ele.entryTaskFullName,
                            };
                            return (
                              <PictureComponent
                                key={i}
                                data={imgData}
                                classStyle="col-xs-12 mb-2"
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedPictureCompare;
