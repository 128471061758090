import React, { useEffect, useRef, useState } from 'react'
import SingleSelectComp from '../../Components/SingleSelectComp'
import { CommonUtil } from '../../utils/CommonUtil'
import Select from 'react-select'
import Contacts from './Contacts'
import DNUDetails from './DNUDetails'
import BlockedOrgs from './BlockedOrgs'
import { NetworkManager } from '../../Components/NetworkManager'
import $ from 'jquery'
import ToastManager from '../../Components/ToastManager'
import { StatusCodeUtil } from '../../utils/StatusCodeUtil'
import FinanceInfo from './FinanceInfo'
import BusyLoader from '../../Components/BusyLoader'
import OwnerServiceFree from './OwnerServiceFree'
import { loadMapApi } from '../../utils/GoogleMapsUtils'
import PhoneInput from 'react-phone-input-2'
import DraggableComponent from '../../Components/DraggableComponent'
import { Modal } from 'react-bootstrap'
import AGGrid from '../../Components/AGGrid'
import OrgCellRenderer from '../Organization/OrgCellRenderer'
import { IAGrid } from '../../Components/IInterfaces'
import useStateWithCallback from 'use-state-with-callback'
import {Constants} from "../Languages/Constants";
import {Events} from "../Languages/Events";
import {useNavigate} from "react-router-dom";
import DetectDevice from '../../utils/DetectDevice'
import MGrid from '../../Components/MGrid'
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";


var defCountryObj = { Key: 'Canada', Value: 'Canada' }
const options = {
  fields: ['formatted_address', 'geometry', 'name'],
  strictBounds: false,
  types: ['establishment'],
}

const CustomerProfiles = () => {

  const navigate = useNavigate();
  const { isMobile } = DetectDevice();
  const [genInfo, setGenInfo] = useState(true)
  const [finance, setFinance] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [dnuInfo, setDnuInfo] = useState(false)
  const [blockOrg, setBlockOrg] = useState(false)
  const [services, setServices] = useState(false)
  const [genConfig, setGenConfig] = useState(false)

  const genref = useRef<HTMLButtonElement>(null)
  const financeref = useRef<HTMLButtonElement>(null)
  const userref = useRef<HTMLButtonElement>(null)
  const priceref = useRef<HTMLButtonElement>(null)
  const blockOrgref = useRef<HTMLButtonElement>(null)
  const servicesOrgref = useRef<HTMLButtonElement>(null)
  const genConfigref = useRef<HTMLButtonElement>(null)

  const companyRef = useRef<HTMLInputElement>(null)
  const loginRef = useRef<Select>(null)
  const statusRef = useRef<HTMLSelectElement>(null)
  const countryRef = useRef<HTMLSelectElement>(null)
  const provinceRef = useRef<HTMLSelectElement>(null)
  const depRef = useRef<HTMLSelectElement>(null)
  const oprUS = useRef<HTMLInputElement>(null)
  const oprCanada = useRef<HTMLInputElement>(null)
  const oprBorder = useRef<HTMLInputElement>(null);
  const oprInterchange = useRef<HTMLInputElement>(null);



  const legalRef = useRef<HTMLInputElement>(null);
  const dbaRef = useRef<HTMLInputElement>(null);
  const uuidRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null)
  const trnNoRef = useRef<HTMLInputElement>(null)
  const postalCodeRef = useRef<HTMLInputElement>(null)
  const phnoRef = useRef<HTMLInputElement>(null)
  const cityRef = useRef<HTMLInputElement>(null);

  const cmnRef = useRef<HTMLInputElement>(null)
  const cmeRef = useRef<HTMLInputElement>(null)
  const sanRef = useRef<HTMLInputElement>(null)
  const saeRef = useRef<HTMLInputElement>(null)

  const [companyName, setCompanyName] = useState('')
  const [companyType, setCompanyType] = useState('')
  const [loginData, setLoginData] = useState(CommonUtil.loginAsArr)
  const [statues, setStatues] = useState(CommonUtil.statuses)
  const [countries, setCountries] = useState(CommonUtil.countryList)
  const [states, setStates] = useState<any>([])
  const [postalcode, setPostalCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [departments, setDepartments] = useState(CommonUtil.onlyAdminDepartment)
  const [ownerType, setOwnerType] = useState(false)
  const [renterType, setRenterType] = useState(false)

  const [imageSrc, setImageSrc] = useState('../../NoLogo.jpg')
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const [legalName, setLegalName] = useState('')
  const [address1, setAddress1] = useState('');
  const [rawAddress, setRawAddress] = useState('')
  const [ctq, setCTQ] = useState('')
  const [city, setCity] = useState('')

  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')

  const [ownerStatus, setOwnerStatus] = useState('')
  const [isAutoApproved, setIsAutoApproved] = useState('')
  const [renterStatus, setRenterStatus] = useState('')

  const [loginAsObj, selLoginAsObj] = useState<any>(null)
  const [statusObj, setStatusObj] = useState<any>(null)
  const [countryObj, setCountryObj] = useState<any>(null)
  const [stateObj, setStateObj] = useState<any>(null)
  const [depObj, setDepObj] = useState<any>(null)

  const fileref = useRef<HTMLInputElement>(null)
  const [fileNmae, setFileName] = useState('')
  const [imagePath, setImagePath] = useState<any>(null)
  const [addLogo, setAddLogo] = useState(true)
  const [companyLogo, setCompanyLogo] = useState(false)

  const [transText, setTransText] = useState('Transport Commission No.')
  const [transTextPlace, setTransTextPlace] = useState('CTQ, CVOR, NSC etc.')

  const [provinceText, setProvinceText] = useState('Province')

  const [postalCodeText, setPostalCodeText] = useState('Postal code')
  const [pcTextPlace, setPCTextPlace] = useState('Enter Postal Code')

  const [loginClass, setLoginClass] = useState('mandatory')
  const [statusClass, setStatusClass] = useState('mandatory')
  const [countryClass, setCountryClass] = useState('mandatory')
  const [provinceClass, setProvinceClass] = useState('mandatory')

  const [isBorderCheckEnable, setBorderCheckEnable] = useState(true)
  const [oprCanadaChecked, setoprCanaraChecked] = useState(false)
  const [oprUSChecked, setOprUSaChecked] = useState(false)
  const [oprBorderChecked, setOprBorderChecked] = useState(false);
  const [interchangeMenu, setIntechangeMenu] = useState(false)
  const [isAnyAvailable, setIsAnyAvailable] = useState(false);



  const [showHistoryPopup, setShowHistoryPopup] = useState(false);
  const [page,setPage] = useState("");
  const [vhubUUID,setVHUBUUID] = useState("");
  const [isUUIDDisable,setUUIDDisable] = useState(false);

  const [showHighwayPopup,setShowHighwayPopup] = useState(false);
  const [results,setResults] = useState("");

  const [cmn,setCMN] = useState("");
  const [cme,setCME] = useState("");
  const [san,setSAN] = useState("");
  const [sae,setSAE] = useState("");


  const [historyColumns] = useState([
    {
      field: 'changedField',
      headerName: CommonUtil.getMessageText(Constants.FIELD,"Field"),
      resizable: true,
      width: 100,
      flex: 1,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'fromValue',
      headerName: CommonUtil.getMessageText(Constants.FROM_VALUE,"From Value"),
      resizable: true,
      width: 100,
      flex: 1,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'toValue',
      headerName: CommonUtil.getMessageText(Constants.TO_VALUE,"To Value"),
      resizable: true,
      width: 100,
      flex: 1,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'createdBy',
      headerName: CommonUtil.getMessageText(Constants.PERFOMED_BY,"Performed By"),
      resizable: true,
      width: 100,
      flex: 1,
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'creationDate',
      headerName: CommonUtil.getMessageText(Constants.PERFOMED_DATE,"Performed Date"),
      resizable: true,
      width: 100,
      flex: 1,
      sortable: true,
      filter: 'agTextColumnFilter',
    },



  ])

  const gridRef = useRef<IAGrid>(null)

  const [historyData, setHistoryData] = useStateWithCallback<any>([{}], () => {
    setTimeout(()=>{
      if (gridRef.current) {
        gridRef.current.columnFittoSize();
        ///alert("loaded");
      }
    },1000);

  })

  let onCompanyChange = (e: any) => {
    setCompanyName(e.currentTarget.value)
    CommonUtil.isMandatory(companyRef)
  }
  let onLoginAsChange = (data: any) => {
    selLoginAsObj(data);
    (data)?setLoginClass('select-box-valid') : setLoginClass('select-box-invalid');
  }
  let onStatusChange = (data: any) => {
    setStatusObj(data);
    (data)?setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
  }
  let onChangeLegalName = (e: any) => {
    setLegalName(e.currentTarget.value)
    CommonUtil.isMandatory(legalRef)
  }

  let onChangeDBAName = (e: any) => {
    setCompanyName(e.currentTarget.value)
    CommonUtil.isMandatory(dbaRef)
  }



  let onChangeUUID = (e: any) => {
    setVHUBUUID(e.currentTarget.value);
    CommonUtil.isMandatory(uuidRef)
  }


  let onChangeAddress = (e: any) => {
    setAddress1(e.currentTarget.value);
    setRawAddress(e.currentTarget.value);
    CommonUtil.isMandatory(addressRef)
  }
  let onChangeCTQ = (e: any) => {
    setCTQ(e.currentTarget.value)
    CommonUtil.isMandatory(trnNoRef)
  }
  let onStateChange = (data: object) => {
    setStateObj(data);
    (data)?setProvinceClass('select-box-valid'):setProvinceClass('select-box-invalid');
  }
  let onCountryChange = (data: any) => {
    setCountryObj(data)
    setStateObj(null)
    if (data) {
      if (data.Key == CommonUtil.CANADA) {
        setStates(CommonUtil.canadaProvinces)
      } else {
        setStates(CommonUtil.usStates)
      }
      setTextByCountry(data.Key);
      (data) ? setCountryClass('select-box-valid') : setCountryClass('select-box-invalid');
    } else {
      setCountryObj(defCountryObj)
      setStates(CommonUtil.canadaProvinces)
      setTextByCountry(defCountryObj.Key)
      defCountryObj ? setCountryClass('select-box-valid') : setCountryClass('select-box-invalid');
    }
  }

  let setTextByCountry = (country: string) => {
    let strTransText = CommonUtil.getMessageText(Constants.TRANS_COMM_NO,"Transport Commission No");//'Transport Commission No.'
    let strTransTextPlace = CommonUtil.getMessageText(Constants.CTQ,"CTQ, CVOR, NSC etc.");//'CTQ, CVOR, NSC etc.'

    let strProvinceText = CommonUtil.getMessageText(Constants.PROVINCE,"Province");//'Province'

    let strPostalCodeText = CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code");//'Postal code'
    let strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code");//'Enter Postal Code'

    if (country == 'United States') {
      strTransText = CommonUtil.getMessageText(Constants.US_DOT_NO,"USDOT number");//'USDOT number'
      strTransTextPlace = CommonUtil.getMessageText(Constants.ENTER_US_DOT_NO,"You must enter an USDOT number");//'You must enter an USDOT number'

      strProvinceText = CommonUtil.getMessageText(Constants.STATE,"State");//'State'

      strPostalCodeText = CommonUtil.getMessageText(Constants.ZIP_CODE,"ZIP code");//'ZIP code'
      strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_ZIP_CODE,"Enter ZIP Code");//'Enter ZIP Code'
    }
    setTransText(strTransText)
    setTransTextPlace(strTransTextPlace)
    setProvinceText(strProvinceText)
    setPostalCodeText(strPostalCodeText)
    setPCTextPlace(strPostalCodePlace)
  }

  let deSelectButtons = () => {
    setGenInfo(false);
    setFinance(false);
    setContacts(false);
    setDnuInfo(false);
    setBlockOrg(false);
    setServices(false);
    setGenConfig(false);

    let links = ['navOrg','navFinance','navUsers','navService','navDefPrice','navBOrgs'];
    links.forEach((item:any,idx:number)=>{
      $("#"+item).removeClass("active");
    });
  }

  let onClickOrgInfo = () => {
    deSelectButtons()
    setGenInfo(true);
    setTimeout(()=>{
      loadGooglePlaces();
    },1000);
    $("#navOrg").addClass("active");
  }
  let onClickFinance = () => {
    deSelectButtons()
    setFinance(true)
    $("#navFinance").addClass("active");
  }
  let onClickUserInfo = () => {
    deSelectButtons()
    setContacts(true)
    $("#navUsers").addClass("active");
  }
  let onClickPriceInfo = () => {
    deSelectButtons()
    setDnuInfo(true)
    $("#navDefPrice").addClass("active");
  }

  let onClickBlockOrgs = () => {
    deSelectButtons()
    setBlockOrg(true)
    $("#navBOrgs").addClass("active");
  }
  let onClickServices = () => {
    deSelectButtons()
    setServices(true)
    $("#navService").addClass("active");
  }

  let onClickGenConfig = () => {
    deSelectButtons()
    setGenConfig(true)
    genConfigref.current?.classList.add('clsTabSelected')
  }

  useEffect(() => {
    loadGooglePlaces();
    reAdjust();
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    document.addEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
    window.addEventListener('resize',function(e){
            reAdjust();
        })
    return () => {
      document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
      document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
      window.removeEventListener('resize', function(){
                reAdjust();
            });
    }
  }, []);

  let onSwitchCustomer = ()=>{
    /*if(CommonUtil.isRootUser()){
      Events.dispatchBreadCrumbEvent();
      //navigate("orgList1");
    }else{
      getOrganizationInfo();
      //navigate("orgList1");
    }*/
    Events.dispatchBreadCrumbEvent();
  }

  let onLanguageChange = ()=>{
    setPage(""+Math.random()*100000);
  }

  let loadGooglePlaces = () => {
    if (window.google) {
      // console.log(google.maps.MapTypeId.ROADMAP);
      setScriptLoaded(true)
      getOrganizationInfo()
      getGooglePlaces()
    } else {
      const googleMapScript = loadMapApi()
      // console.log(googleMapScript);
      if (googleMapScript) {
        googleMapScript.addEventListener('load', function () {
          //console.log("Map loaded");
          // console.log(window.google);
          setScriptLoaded(true)
          getOrganizationInfo()
          getGooglePlaces();
        })
      }
    }
  }

  let getGooglePlaces = () => {
    const address = document.getElementById("address") as HTMLInputElement;
    CommonUtil.getPlaceAddressByMap(address,(addr:any)=>{
      //console.log(result);
      setRawAddress(addr['rawAddress']);
      setAddress1(addr['rawAddress']);
      setCity(addr['city'])
      setPostalCode(addr['postalcode'])
      setCountryObj({ Key: addr['country'], Value: addr['country'] })
      setStateObj({ Key: addr['state'], Value: addr['state'] })
      onCountryChange({ Key: addr['country'], Value: addr['country'] })
      setStateObj({ Key: addr['state'], Value: addr['state'] })
      setLatitude(addr['lat'])
      setLongitude(addr['lng'])
    });

    /*const input = document.getElementById('address') as HTMLInputElement
    const autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry', 'formatted_address', 'name'],
      types: ['address'],
    })

    autocomplete.addListener('place_changed', () => {
      let addr: any = CommonUtil.getGoogleMapAddress(autocomplete)
      //console.log(addr)
      if (addr) {
        setAddress1(addr['rawAddress'])
        setCity(addr['city'])
        setPostalCode(addr['postalcode'])
        setCountryObj({ Key: addr['country'], Value: addr['country'] })
        setStateObj({ Key: addr['state'], Value: addr['state'] })
        onCountryChange({ Key: addr['country'], Value: addr['country'] })
        setStateObj({ Key: addr['state'], Value: addr['state'] })
        setLatitude(addr['lat'])
        setLongitude(addr['lng'])

        CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
          //console.log(data);
        })
      }


    })*/
  }
  let getOrganizationInfo = () => {
    var qParam = CommonUtil.getSaasOrgId();
    var orgUrl = 'api/organization/' + qParam;
    NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError)
  }

  let onOrgDetailsSuccess = (dataObj: any) => {
    //console.log(dataObj)
    if (dataObj) {
      var restData = JSON.parse(dataObj)
      if (restData) {
        if (restData.statusCode) {
          ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), 'Error');
        } else {
          var orgTypeObj = null
          setUUIDDisable(false);
          setVHUBUUID(restData.orgUUID);
          setIntechangeMenu((restData.isInterchangeMenuEnabled == "Y"?true:false));
          setIsAnyAvailable((restData.isEnableAnyAvailble == "Y"?true:false));

          if (CommonUtil.loginAsArr) {
            for (var i = 0; i < CommonUtil.loginAsArr.length; i++) {
              if (CommonUtil.loginAsArr[i].Key == restData.organizationType) {
                setCompanyType(CommonUtil.loginAsArr[i].Value);
                selLoginAsObj({Key: restData.organizationType, Value: CommonUtil.loginAsArr[i].Value});
                if (restData.organizationType == CommonUtil.ORG_TYPE_OWNER) {
                     setOwnerType(true);
                     if(restData.ownerStatus == CommonUtil.ORG_APPROVED){
                       setUUIDDisable(true);
                     }
                } else if (restData.organizationType == CommonUtil.ORG_TYPE_RENTER) {
                    setRenterType(true);
                  if(restData.renterStatus == CommonUtil.ORG_APPROVED){
                    setUUIDDisable(true);
                  }
                } else if (restData.organizationType == CommonUtil.ORG_TYPE_OWNER_RENTER) {
                  setRenterType(true);
                  setOwnerType(true);
                  if(restData.ownerStatus == CommonUtil.ORG_APPROVED && restData.renterStatus == CommonUtil.ORG_APPROVED){
                    setUUIDDisable(true);
                  }
                }
                CommonUtil.SELECT_ORG_TYPE = restData.organizationType
                break;
              }
            }
          }
          CommonUtil.ORGANIZATION_TYPE = restData.organizationType;
          setStatusObj({ Key: restData.status, Value: restData.status });
          setOwnerStatus(restData.ownerStatus);
          setIsAutoApproved(restData.isAutoApproved);
          setRenterStatus(restData.renterStatus);
          setCompanyName(restData.orgName);
          setLegalName(restData.legalName);
          setAddress1(restData.address1);
          setRawAddress(restData.address1);
          setCity(restData.city);
          setCountryObj({ Key: restData.country, Value: restData.country });
          localStorage.setItem('country', restData.country);
          CommonUtil.CUSTOMER_COUNTRY = restData.country;
          setTextByCountry(restData.country);
          if (restData.country == CommonUtil.CANADA) {
            setStates(CommonUtil.canadaProvinces);
          } else {
            setStates(CommonUtil.usStates);
          }
          setStateObj({ Key: restData.province, Value: restData.province});
          setPostalCode(restData.postalCode);
          setPhoneNumber(restData.phoneNumber);
          setCTQ(restData.transportCommissionNumber);
          if (restData.canOperateInCanada == 'Y') {
            setoprCanaraChecked(true)
            if (oprCanada.current) {
              oprCanada.current.checked = true
            }
          }
          if (restData.canOperateInUSA == 'Y') {
            setOprUSaChecked(true);
            if (oprUS.current) {
              oprUS.current.checked = true;
            }
          }

          if (restData.canOperateInCrossBorder == 'Y') {
            setOprBorderChecked(true);
            if (oprBorder.current) {
              oprBorder.current.checked = true;
            }
          }

          //onChangeOprBorder();
          setBorderCheckEnable(true);
          if (oprCanada.current?.checked && oprUS.current?.checked) {
            setBorderCheckEnable(false);
          }

          setCMN(restData.customerManagerName);
          setCME(restData.customerManagerEmail);
          setSAN(restData.salesAgentName);
          setSAE(restData.salesAgentEmail);

          if (restData.logoPath) {
            var qParam: string = 'objectPath=' + restData.logoPath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, onDownloadSuccess, onLoginError);
          }
        }
      }
    }
  }
  let onDownloadSuccess = (data: any) => {
    //console.log(data)
    setImageSrc(data)
  }
  let onSelectFiles = (e: any) => {
    //console.log(e);
    if (e && e.target && e.target.files[0]) {
      let fileName = e.target.files[0].name
      var oFReader = new FileReader()
      oFReader.readAsDataURL(e.target.files[0])
      oFReader.onload = function (oFREvent) {
        // console.log(oFREvent.target?.result);
        setAddLogo(false)
        setCompanyLogo(true)
        setImagePath(oFREvent.target?.result)
        setFileName(fileName)

        BusyLoader.showLoader()
        var qParam: string = 'objectPath=images/organizations/' + fileName
        NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadSuccess, onLoginError);
      }
    }
  }
  let onUploadSuccess = (dataObj: any) => {
    if (dataObj) {
      //  var restData = JSON.parse(dataObj);
      if (dataObj.statusCode) {
        BusyLoader.hideLoader()
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
        ToastManager.showToast(errMsg, 'Error')
      } else {
        var strUploadPath = dataObj
        if (fileref.current && fileref.current.files) {
          const formData = new FormData()
          var myHeaders = new Headers()
          var file = fileref.current.files[0]
          var requestOptions: any = {
            method: 'PUT',
            headers: myHeaders,
            body: file,
            redirect: 'follow',
          }
          fetch(strUploadPath, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              updateOrgLogo()
              //BusyLoader.hideLoader();
              //console.log(result);
              // gotoLoginPage();
            })
            .catch((error) => {
              BusyLoader.hideLoader()
              console.log('error', error)
            })
        }
      }
    }
  }

  let updateOrgLogo = () => {
    if (fileref.current && fileref.current.files && fileref.current.files[0]) {
      var qParam: string = 'logoPath=images/organizations/' + fileref.current.files[0].name + '&saasOrgId=' + CommonUtil.getSaasOrgId();
      NetworkManager.sendJsonRequest('api/organization/updateOrganizationLogo', '', qParam, 'PUT', false, onUpdateOrgLogoSuccess, onLoginError);
    }
  }

  let onUpdateOrgLogoSuccess = (dataObj: any) => {
    BusyLoader.hideLoader()
    let strInfo = CommonUtil.getMessageText(Constants.INFO,"Info");
    let strError = CommonUtil.getMessageText(Constants.ERROR,"Error");
    let strLogoUpdated = CommonUtil.getMessageText(Constants.LOGO_UPDATED,"Logo updated successfully");
    if (dataObj) {
      var restData = JSON.parse(dataObj)
      if (restData.statusCode) {
        BusyLoader.hideLoader()
        var errMsg = StatusCodeUtil.getCodeMessage(restData.statusCode)
        ToastManager.showToast(errMsg, strError)
      } else {
        ToastManager.showToast(strLogoUpdated, strInfo)
      }
    } else {
      ToastManager.showToast(strLogoUpdated, strInfo)
    }
  }
  let onClickBrowse = () => {
    //inputRef.current?.classList.remove("is-invalid");
    if (fileref.current) {
      fileref.current.value = ''
      // fileref.current.onclick();
      $('#inputGroupFile').click()
    }
    setFileName('')
  }


  let onClickSave = () => {
   // var isOrg: boolean = CommonUtil.isMandatory(companyRef);
    //var isLogin:boolean = CommonUtil.isMandatory(legalRef);
    var isLegal: boolean = CommonUtil.isMandatory(legalRef);
    var isDBA: boolean = CommonUtil.isMandatory(dbaRef);
    var isAddress: boolean = CommonUtil.isMandatory(addressRef);
    var isCTQ: boolean = CommonUtil.isMandatory(trnNoRef);
    var isCity: boolean = CommonUtil.isMandatory(cityRef);
    var isPostalCode: boolean = CommonUtil.isMandatory(postalCodeRef);

    var isUUID: boolean = CommonUtil.isMandatory(uuidRef);


    //var isPhone:boolean =  CommonUtil.isMandatory(phnoRef);

    (loginAsObj) ? setLoginClass('select-box-valid') : setLoginClass('select-box-invalid');
    (countryObj) ? setCountryClass('select-box-valid') : setCountryClass('select-box-invalid');
   (stateObj) ? setProvinceClass('select-box-valid') : setProvinceClass('select-box-invalid');
    (statusObj) ? setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');

    if (isDBA && isLegal && isAddress && isCTQ && isCity && isPostalCode && loginAsObj && countryObj && stateObj ) {//&& isUUID
      var obj: any = {}
      obj.address1 = address1;
      obj.canOperateInCanada = oprCanada.current?.checked ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
      obj.canOperateInCrossBorder = oprBorder.current?.checked ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
      obj.canOperateInUSA = oprUS.current?.checked ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
      obj.enableInterchangeMenu = oprInterchange.current?.checked ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
      obj.city = city
      obj.legalName = legalName;
      obj.country = countryObj ? countryObj.Key : ''
      obj.status = statusObj ? statusObj.Key : ''
      //obj.department = depObj?depObj.Key:"";
      /*if(fileNmae){
                obj.logoPath = "images/organizations/"+fileNmae;
            }else{
                obj.logoPath = "";
            }*/

      let valDBA = companyName;
      if(String(valDBA).toLowerCase() === "n/a"){
        valDBA = legalName;
      }
      obj.organizationName = valDBA;
      obj.orgId = CommonUtil.getSaasOrgId();
      obj.orgName = valDBA;
      obj.organizationType = loginAsObj ? loginAsObj.Key : '';
      obj.phoneNumber = phoneNumber;
      obj.postalCode = postalcode;
      obj.province = stateObj ? stateObj.Key : '';
      obj.transportCommissionNumber = ctq;

      obj.latitude = latitude;
      obj.longitude = longitude;
      obj.rawAddress = rawAddress;
      obj.orgUUID = vhubUUID;
      obj.isInterchangeMenuEnabled = interchangeMenu?"Y":"N";
      obj.isEnableAnyAvailable = isAnyAvailable?"Y":"N";
      obj.timeZone = '';

      obj.customerManagerName = cmn;
      obj.customerManagerEmail = cme;      
      obj.salesAgentName = san;      
      obj.salesAgentEmail = sae;

      // console.log(obj);

      BusyLoader.showLoader();
      var orgUrl: string = 'api/organization/saveOrganizationInformation?saasOrgId=' + CommonUtil.getSaasOrgId();
      NetworkManager.sendJsonRequest(orgUrl, obj, '', 'POST', false, onOrgUpdateSuccess, onLoginError);
    }
  }

  let onOrgUpdateSuccess = (dataObj: any) => {
    BusyLoader.hideLoader()
    let strInfo = CommonUtil.getMessageText(Constants.INFO,"Info");
    let strOrgUpdated = CommonUtil.getMessageText(Constants.ORG_UPDATED,"Organization updated successfully");
    let strError = CommonUtil.getMessageText(Constants.ERROR,"Error");
    //console.log(dataObj)
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        CommonUtil.ORGANIZATION_TYPE = loginAsObj ? loginAsObj.Key : '';
        CommonUtil.CUSTOMER_COUNTRY = countryObj ? countryObj.Key : '';
        ToastManager.showToast(strOrgUpdated,"Info");
      }
    }
  }
  let onClickOwnerApprove = () => {
    let status = ''
    status = ownerStatus == 'Pending' ? 'Approved' : 'Pending';
    var orgUrl: string = 'api/organization/changeOrganizationOwnerProfile?ownerStatus=' + status + '&saasOrgId=' + CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(orgUrl, '', '', 'PUT', false, onOrgOwnerApproveSuccess, onLoginError);
  }
  let onOrgOwnerApproveSuccess = (dataObj: any) => {
    let strError = CommonUtil.getMessageText(Constants.ERROR,"Error");
    if (dataObj) {
      if (dataObj.statusCode) {
        BusyLoader.hideLoader()
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
        ToastManager.showToast(errMsg, strError)
      } else {
        showOwnerStatusMsg()
      }
    } else {
      showOwnerStatusMsg()
    }
  }
  let onClickRenterApprove = () => {
    let status =  renterStatus == 'Pending' ? 'Approved' : 'Pending';
    var orgUrl: string = 'api/organization/changeOrganizationRenterProfile?renterStatus=' + status + '&saasOrgId=' + CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(orgUrl, '', '', 'PUT', false, onOrgRenterApproveSuccess, onLoginError);
  }
  let onOrgRenterApproveSuccess = (dataObj: any) => {
    let strError = CommonUtil.getMessageText(Constants.ERROR,"Error");
    if (dataObj) {
      if (dataObj.statusCode) {
        BusyLoader.hideLoader()
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
        ToastManager.showToast(errMsg, strError);
      } else {
        showRenterStatusMsg()
      }
    } else {
      showRenterStatusMsg()
    }
  }
  let showRenterStatusMsg = () => {
    let status = renterStatus == 'Pending' ? 'Approved' : 'Pending'
    setRenterStatus(status)

    let toastMsg = ''
    if (status == 'Approved') {
      toastMsg = 'Renter Approved successfully'
      setUUIDDisable(true);
    } else {
      setUUIDDisable(false);
      toastMsg = 'Renter Dis Approved successfully'
    }
    ToastManager.showToast(toastMsg, 'Info')
  }
  let showOwnerStatusMsg = () => {
    let status = ownerStatus == 'Pending' ? 'Approved' : 'Pending'
    setOwnerStatus(status)
    let toastMsg = ''
    if (status == 'Approved') {
      toastMsg = CommonUtil.getMessageText(Constants.OWNER_APPROVE_SUCCESS,"Owner Approved successfully");
      setUUIDDisable(true);
    } else {
      setUUIDDisable(false);
      toastMsg = CommonUtil.getMessageText(Constants.OWNER_DIS_APPROVE_SUCCESS,"Owner DisApproved successfully");//'Owner DisApproved successfully'
    }
    let strInfo = CommonUtil.getMessageText(Constants.INFO,"Info");
    ToastManager.showToast(toastMsg, strInfo);
  }
  let onChangeOprCanada = (e: any) => {
    setoprCanaraChecked(e.currentTarget.checked)
    onChangeOprBorder()
  }
  let onChangeOprUS = (e: any) => {
    setOprUSaChecked(e.currentTarget.checked)
    onChangeOprBorder()
  }

  let onChangeOprBorderChecked = (e: any) => {
    setOprBorderChecked(e.currentTarget.checked)
    //onChangeOprBorder();
  }



  let onChangeAnyAvailable = (e:any)=>{
    setIsAnyAvailable(e.currentTarget.checked)
  }

  let onChangeInterchangeMenu = (e:any)=>{
    setIntechangeMenu(e.currentTarget.checked)
  }
  let onChangeOprBorder = () => {
    if (oprBorder.current) {
      oprBorder.current.checked = false
      setOprBorderChecked(false)
    }
    setBorderCheckEnable(true)
    if (oprCanada.current?.checked && oprUS.current?.checked) {
      setBorderCheckEnable(false)
    }
  }

  let onClickHistory = () => {
    setShowHistoryPopup(true);
    setHistoryData([]);
    getOrgHistory();
  }
  let getOrgHistory = ()=>{
    var listAPI:string = "api/history/org/"+CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetCompanyHistory,onLoginError);
  }

  let onGetCompanyHistory = (dataObj:any)=>{
    let history = [];
    if(dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if(Array.isArray(restData)){
          history = restData;
        }else{
          history.push(restData);
        }
        setHistoryData(history);
      }
    }
  }

  let onCloseHistoryPopup = () => {
    setShowHistoryPopup(false)
  }

  let onDevHistoryShowPopup = () => {
    setTimeout(function () {
      setPopupCenter('devHistoryPopup', 'devHistoryContentPopup')
    }, 100)
  }
  let setPopupCenter = (popId: string, cId: string) => {
    let windowHeight = window.innerHeight
    let windowWidth = window.innerWidth
    //windowHeight = windowHeight - 170

    if (cId) {
      let popupTop = windowHeight - 650;
      popupTop = popupTop / 2
      let popupLeft = windowWidth - 1000;
      popupLeft = popupLeft / 2
      //if(popupTop<50){
      //popupTop = 10
      //}
      $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
    }
  }

  let onClickShowHighwayAPI = ()=>{

    getHighwayDetails();
  }

  let getHighwayDetails = ()=>{
    var listAPI:string = "api/organization/getCarrierDetailsHighway?dotNumber="+ctq+"&saasOrgId="+CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetHighwayDetails,onLoginError);
  }

  let onGetHighwayDetails = (dataObj:any)=>{
    if(dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        console.log(restData);
        setShowHighwayPopup(true);
        if(restData.carrierDetailsPayload){
          var strResult =JSON.stringify(restData.carrierDetailsPayload);
         // var result =  parseResult(strResult);
          try{
            setResults(JSON.stringify(restData.carrierDetailsPayload, null, 4));
          }catch(err){}
        }
      }
    }
  }

  let parseResult = (res:any):any=>{
    try{
      //JSON.stringify(obj, null, 4))
      var mystring1 = res.replace(/\\/g , "");
      var strObj = mystring1 = mystring1.substring(1,mystring1.length-1);
      var obj = JSON.parse(strObj);

      return JSON.stringify(obj, null, 4);
    }catch(err){
    }
    return "";

  }

  let onCloseHighwayAPIPopup = ()=>{
    setShowHighwayPopup(false);
  }
  let onLoginError = (err: any) => {
    console.log(err)
  }

  return (
    <>
      <input type="file" ref={fileref} className="custom-file-input " id="inputGroupFile"
        accept="image/*" style={{ display: 'none' }} onChange={(e) => {onSelectFiles(e)}} ></input>

      <Modal show={showHistoryPopup} id="devHistoryPopup" dialogAs={DraggableComponent} onShow={onDevHistoryShowPopup}>
        <div className="modal-content" id="devHistoryContentPopup" style={{ width: "1000px", height: '650px' }}>
          <div className="modal-header">
            <h6 className="modal-title">{'Company History'}</h6>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginLeft:"0px"}} onClick={onCloseHistoryPopup}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            {
              isMobile ? (
                <MGrid id="OrgHistorylGrid" cols={historyColumns} rows={historyData}
                  pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={gridRef} addNew={false} />
              ):(
                <div className="row fs-7 pt-1">
              <div className="ag-theme-alpine gridHeight"
                style={{height: 'calc(100vh - 200px)', width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
                <AGGrid id="OrgHistorylGrid" cols={historyColumns} rows={historyData}
                  pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={gridRef} addNew={false} />
              </div>
            </div>
              )
            }
          </div>
          <div className="modal-footer clsModalPermissionsFootr" style={{ paddingTop: '1px' }}>
            <button type="button" className="btn btn-warning waves-effect"
              data-bs-dismiss="modal" onClick={onCloseHistoryPopup}>
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={showHighwayPopup} id="devHighwayAPIPopup" dialogAs={DraggableComponent}>
        <div className="modal-content" id="devHighwayAPIContentPopup" style={{ width: "800px", height: '650px' }}>
          <div className="modal-header">
            <h6 className="modal-title">{'Highway API Details'}</h6>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginLeft:"0px"}} onClick={onCloseHighwayAPIPopup}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <textarea rows={20} readOnly={true} value={results} style={{width:"100%",height:"500px",border:"1px solid #f4efef"}} />
          </div>
          <div className="modal-footer clsModalPermissionsFootr" style={{ paddingTop: '1px' }}>
            <button type="button" className="btn btn-warning waves-effect"
                    data-bs-dismiss="modal" onClick={onCloseHighwayAPIPopup}>
              Close
            </button>
          </div>
        </div>
      </Modal>

      <div className="page-content-wrapper" style={{paddingLeft:"5px",paddingRight:"5px"}}>
        <div className="container-fluid container-fluid-padding" style={{background:"#f9f9f9"}}>
          {/* <div className="list-details-tabs mt-3"> */}
            <div className={`profile-tabs row`} style={{ justifyContent:'center' }}>

                              <div className="scroller scroller-left float-left" onClick={leftClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                                <div className="wrapper-nav m-0" id='wrapper-nav'>
                                    <nav className="nav nav-tabs list col-12 px-3" id="myTab" role="tablist">
                                      <a id={"navOrg"} className={`nav-item nav-link active ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={onClickOrgInfo}>{CommonUtil.getMessageText(Constants.ORGANISATION_INFO,"Organization Info.")}</a>
                                      <a id={"navFinance"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={onClickFinance}>{CommonUtil.getMessageText(Constants.FINANCE_INSURANCE,"Finance & Insurance")}</a>
                                      <a id={"navUsers"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={onClickUserInfo}>{CommonUtil.getMessageText(Constants.CONTACTS,"Contacts")}</a>
                                      {CommonUtil.ORG_TYPE_RENTER === CommonUtil.ORGANIZATION_TYPE ? null : <a id={"navService"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={onClickServices}>{CommonUtil.getMessageText(Constants.OWNER_SERVICE_FEES,"Owner Service Fees")}</a>}
                                      {CommonUtil.ORG_TYPE_RENTER === CommonUtil.ORGANIZATION_TYPE ? null :                                       <a id={"navDefPrice"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={onClickPriceInfo}>{CommonUtil.getMessageText(Constants.DEFAULT_PRICING,"Default Pricing")}</a>}
                                      <a id={"navBOrgs"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={onClickBlockOrgs}>{CommonUtil.getMessageText(Constants.DNU_BLOCKED,"General Configuration")}</a>
                                    </nav>
                                </div>
                                <div className="scroller scroller-right float-right" onClick={rightClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
              {/* <ul className="nav nav-tabs nav-justified bg-primary pl-3 pr-3 row" role="tablist">
                <li className="nav-item waves-effect waves-light">
                  <a id={"navOrg"} className="nav-link active" data-toggle="tab"  role="tab" onClick={onClickOrgInfo}>{CommonUtil.getMessageText(Constants.ORGANISATION_INFO,"Organization Info.")}</a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a id={"navFinance"} className="nav-link " data-toggle="tab"  role="tab"  onClick={onClickFinance}>{CommonUtil.getMessageText(Constants.FINANCE_INSURANCE,"Finance & Insurance")}</a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a id={"navUsers"} className="nav-link" data-toggle="tab"  role="tab"  onClick={onClickUserInfo}>{CommonUtil.getMessageText(Constants.CONTACTS,"Contacts")}</a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a id={"navService"} className="nav-link" data-toggle="tab"  role="tab" onClick={onClickServices}>{CommonUtil.getMessageText(Constants.OWNER_SERVICE_FEES,"Owner Service Fees")}</a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a id={"navDefPrice"} className="nav-link" data-toggle="tab"  role="tab" onClick={onClickPriceInfo}>{CommonUtil.getMessageText(Constants.DEFAULT_PRICING,"Default Pricing")}</a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a id={"navBOrgs"} className="nav-link" data-toggle="tab"  role="tab"  onClick={onClickBlockOrgs}>{CommonUtil.getMessageText(Constants.DNU_BLOCKED,"DNU & Blocked Orgs.")}</a>
                </li>

              </ul> */}
            </div>
          {/* </div> */}
        </div>
      </div>

      <div style={{display:"none"}} className="container-fluid">
        <div style={{display:"none"}} className="list-details-tabs">
          <div className="d-none d-md-block">
            <ul className="nav nav-tabs nav-justified bg-custom pl-3 pr-3" role="tablist">
              <li className="nav-item waves-effect waves-light">
                <button className="nav-link active" data-toggle="tab" role="tab">
                  Users
                </button>
              </li>
              <li className="nav-item waves-effect waves-light">
                <button
                  className="nav-link"
                  data-toggle="tab"
                  // href="#Roles-and-Permissions"
                  role="tab"
                  // ref={roleref}
                  // onClick={onClickRoles}
                >
                  Roles & Permissions
                </button>
              </li>
              <li className="nav-item waves-effect waves-light">
                <button
                  className="nav-link"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="public"
                  aria-expanded="true"
                  // onClick={onClickGeneral}
                  ref={genref}
                >
                  General Information
                </button>
              </li>
            </ul>
          </div>
        </div>
         <div className="row fs-7" style={{ paddingTop: '16px' }}>
          <div className="col clsNoPaddingLeft clsNoPaddingRight">
            <button
              ref={genref}
              className="btn clsTabButton clsTabSelected"
              onClick={onClickOrgInfo}
            >
              <img src="../../images/circle.svg" />
              Organization Info.
            </button>
          </div>
          <div className="col clsNoPaddingLeft clsNoPaddingRight">
            <button ref={financeref} className="btn clsTabButton" onClick={onClickFinance}>
              <img src="../../images/circle.svg" />
              Finanace & Insurance
            </button>
          </div>
          <div className="col-md-2 clsNoPaddingLeft clsNoPaddingRight">
            <button
              ref={userref}
              className="btn clsTabButton"
              onClick={onClickUserInfo}
            >
              <img src="../../images/circle.svg" />
              Contacts
            </button>
          </div>
          <div className="col clsNoPaddingLeft clsNoPaddingRight">
            <button
              ref={servicesOrgref}
              className="btn clsTabButton"
              onClick={onClickServices}
            >
              <img src="../../images/circle.svg" />
              Owner Service Fees
            </button>
          </div>
          <div className="col clsNoPaddingLeft clsNoPaddingRight">
            <button
              ref={priceref}
              className="btn clsTabButton"
              onClick={onClickPriceInfo}
            >
              <img src="../../images/circle.svg" />
              Default Pricing & DNU
            </button>
          </div>
          <div className="col clsNoPaddingLeft clsNoPaddingRight">
            <button
              ref={blockOrgref}
              className="btn clsTabButton"
              onClick={onClickBlockOrgs}
            >
              <img src="../../images/circle.svg" />
              Blocked Orgs.
            </button>
          </div>

          <div
            className="col clsNoPaddingLeft clsNoPaddingRight"
            style={{ display: 'none' }}
          >
            <button
              ref={genConfigref}
              className="btn clsTabButton"
              onClick={onClickGenConfig}
            >
              <img src="../../images/circle.svg" />
              General Configuration
            </button>
          </div>
        </div>
      </div>

      <div className="tab-content p-0" id="myTabContent" style={{background:"#FFF",width:"100%"}}>
          {genInfo && (
              <div className="container">                
                <section className="mt-3" style={{width:"90%"}}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 text-center">
                      <div className="row">
                        <div className="col-lg-2 col-md-12">
                          <div className="organization-picture-container">
                            <div className="picture">
                              {addLogo && (
                                  <img className="clsCompanyLogo companyLogo" src={imageSrc} onClick={onClickBrowse} />
                              )}
                              {companyLogo && (
                                  <img className="clsCompanyLogo companyLogo" src={imagePath} onClick={onClickBrowse} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 text-center px-sm-4 pt-2">
                          <h6>{companyName}<small style={{display:"block"}}>{companyType} </small></h6>
                          <a data-toggle="modal" data-target=".bs-example-modal-center-company-history"
                             onClick={onClickHistory} style={{color:"blue"}}>{CommonUtil.getMessageText(Constants.VIEW_HISTORY,"View History")}</a>
                        </div>
                        <div className="col-lg-7 col-md-12  pt-2">
                          <div className="row">
                            {ownerType && (
                                <div className="col-md-12 col-lg-8 p-0">
                                  <div className={`${ownerStatus == 'Approved' ? 'accountApprBg' : 'accountPendingBg'}`}>
                                    <p className={`dark-grey fw-600 py-2 mb-0`} style={isMobile ?  { width:'fit-content', textAlign:'left', flexDirection:'column' }:{}} >
                                    {ownerStatus == 'Approved' ? <img src="../../images/circle.svg" className="mx-2" /> :
                                           <img src="../../images/circle-yellow.svg" className="mx-2" />
                                      }
                                      <span>
                                        {CommonUtil.getMessageText(Constants.OWNER_ACC_STATUS,"Owner Account ")} {ownerStatus}
                                    </span>
                                      {/*<span style={{display: CommonUtil.isRootUser() ? '' : 'none'}}>
                                        {CommonUtil.getMessageText(Constants.OWNER_ACC_APPROVED,"Owner Account")} {ownerStatus}
                                    </span>*/}
                                      <button className={`btn float-end ${ownerStatus == 'Approved' ? 'accountApprBtnRed' : 'accountApprBtnAprove'} ${isMobile ? 'p-0 my-0 m-2' : ''}`}
                                              style={{display: CommonUtil.isRootUser() ? '' : 'none', width:isMobile ? 'fit-content' :'', position:isMobile ? 'absolute':'relative' }} onClick={onClickOwnerApprove} >
                                        {ownerStatus == 'Pending' ? 'Approve' : 'Disapprove'}
                                      </button>
                                    </p>
                                  </div>                                  
                                </div>
                            )}
                          </div>
                          <div className="row">
                            {renterType && (
                                <div className="col-md-12 col-lg-8 p-0">
                                  <div className={`${renterStatus == 'Approved' ? 'accountApprBg' : 'accountPendingBg'}`}>
                                    <p className="dark-grey fw-600  py-2 mb-0" style={isMobile ?  { width:'fit-content', textAlign:'left', flexDirection:'column' }:{}}>
                                      {renterStatus == 'Approved' ? <img src="../../images/circle.svg" className="mx-2" /> :
                                           <img src="../../images/circle-yellow.svg" className="mx-2" />
                                      }

                                      <span>
                                          {CommonUtil.getMessageText(Constants.RENTER_ACC_STATUS,"Renter Account ")} {renterStatus}
                                      </span>
                                      {/*<span style={{display: CommonUtil.isRootUser() ? '' : 'none'}}>
                                      {CommonUtil.getMessageText(Constants.RENTER_ACC_APPROVED,"Renter Account ")} {renterStatus}
                                    </span>*/}
                                      <button className={`btn float-end ${renterStatus == 'Approved' ? 'accountApprBtnRed' : 'accountApprBtnAprove'}`}
                                              style={{display: CommonUtil.isRootUser() ? '' : 'none', width:isMobile ? 'fit-content' :'', position:isMobile ? 'absolute':'relative'}} onClick={onClickRenterApprove}>
                                        {renterStatus == 'Pending' ? 'Approve' : 'Disapprove'}
                                      </button>
                                    </p>
                                  </div>                                  
                                </div>
                            )}
                          </div>
                          <div className='dark-grey fw-600 text-right'>
                            {isAutoApproved &&
                              <span>
                                Auto Approved <i className="fa-regular fa-thumbs-up" style={{fontSize:"20px"}}></i>
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mt-3">
                      <hr />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-4"  style={{display:CommonUtil.isRootUser()?"":"none"}}>
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.VHUB1_0_UUID,"vHub1.0 UUID")}</label>
                            <div className="col-sm-12">
                              <input type="text" disabled={isUUIDDisable}  ref={uuidRef} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl"   value={vhubUUID}
                                     placeholder={CommonUtil.getMessageText(Constants.VHUB1_0_UUID,"vHub1.0 UUID")}
                                     onChange={(e) => {onChangeUUID(e)}}/>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COMPANY_NAME_DBA,"Company Name (DBA)")}</label>
                            <div className="col-sm-12">
                              <input type="text"  disabled={CommonUtil.isRootUser()?false:true} className="form-control" ref={dbaRef}  value={companyName} placeholder=""
                                     onChange={(e) => {
                                       onChangeDBAName(e)
                                     }}/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.JOIN_VHUB_AS,"Join vHub as A")}</label>
                            <div className="col-sm-12">
                              <SingleSelectComp ref={loginRef} dataSource={loginData} selectedValue={loginAsObj}
                                  handleChange={onLoginAsChange} value={'Key'} label={'Value'} className={loginClass} disabled={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATUS,"Status")}</label>
                            <div className="col-sm-12">
                              <SingleSelectComp ref={statusRef} dataSource={statues}
                                  selectedValue={statusObj} handleChange={onStatusChange} value={'Key'}
                                  label={'Value'} disabled={true} className={statusClass}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{`${CommonUtil.getMessageText(Constants.COMPANY,"Company")} ${CommonUtil.getMessageText(Constants.LEGAL_NAME,"Legal Name")}`} </label>
                            <div className="col-sm-12">
                              <input
                                  ref={legalRef}
                                  type="text"
                                  className="form-control clsStandardText  css-2rsrq9 clsFormInputControl"
                                  placeholder={CommonUtil.getMessageText(Constants.ENTER_LEGAL_NAME,"You must enter a legal name")}
                                  value={legalName}
                                  aria-label="Legal Name"
                                  disabled={CommonUtil.isRootUser()?false:true}
                                  onChange={(e) => {
                                    onChangeLegalName(e)
                                  }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDRESS1,"Address 1")}</label>
                            <div className="col-sm-12">
                              <input
                                  id={'address'}
                                  ref={addressRef}
                                  type="text"
                                  className="form-control css-2rsrq9 clsStandardText clsFormInputControl"
                                  placeholder={CommonUtil.getMessageText(Constants.ENTER_ADDRESS1,"Enter Address")}
                                  aria-label="Address1"
                                  value={address1}
                                  onChange={(e) => {
                                    onChangeAddress(e)
                                  }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COUNTRY,"Country")}</label>
                            <div className="col-sm-12">
                              <SingleSelectComp
                                  ref={countryRef}
                                  dataSource={countries}
                                  selectedValue={countryObj}

                                  handleChange={onCountryChange}
                                  value={'Key'}
                                  label={'Value'}
                                  className={countryClass}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{transText}</label>
                            <div className="col-sm-12">
                              <input
                                  ref={trnNoRef}
                                  type="text"
                                  className="form-control css-2rsrq9 clsStandardText  clsFormInputControl"
                                  placeholder={transTextPlace}
                                  value={ctq}
                                  onChange={(e) => {
                                    onChangeCTQ(e)
                                  }}
                                  aria-label="Transport Commission No. (CTQ,CVOR etc.)"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CITY,"City")}</label>
                            <div className="col-sm-12">
                              <input
                                  ref={cityRef}
                                  type="text"
                                  className="form-control css-2rsrq9 clsStandardText clsFormInputControl"
                                  placeholder="Enter City"
                                  aria-label="City"
                                  value={city}
                                  onChange={(e) => {
                                    setCity(e.currentTarget.value)
                                    CommonUtil.isMandatory(cityRef)
                                  }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{provinceText}</label>
                            <div className="col-sm-12">
                             <SingleSelectComp
                                  ref={provinceRef}
                                  dataSource={states}
                                  selectedValue={stateObj}
                                  handleChange={onStateChange}
                                  value={'Key'}
                                  label={'Value'}
                                  className={provinceClass}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{postalCodeText}</label>
                            <div className="col-sm-12">
                              <input
                                  ref={postalCodeRef}
                                  type="text"
                                  className="form-control clsStandardText css-2rsrq9 clsFormInputControl"
                                  placeholder={pcTextPlace}
                                  aria-label="Postal Code"
                                  value={postalcode}
                                  onChange={(e) => {
                                    setPostalCode(e.currentTarget.value)
                                    CommonUtil.isMandatory(postalCodeRef)
                                  }}
                              />
                            </div>
                            </div>
                          </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label"> {CommonUtil.getMessageText(Constants.PHONE_NO,"Phone Number")} </label>
                            <div className="col-sm-12">
                              <PhoneInput
                                  country={'us'}
                                  countryCodeEditable={true}
                                  value={phoneNumber}
                                  placeholder="Enter Phone Number"
                                  inputClass={'input-phone'}
                                  onChange={(e) => {
                                    //console.log(e)
                                    if (phoneNumber == '') {
                                      e = 1 + '' + e
                                    }
                                    setPhoneNumber(e)
                                  }}
                                  disableCountryGuess={false}
                                  disableDropdown={true}
                                  disableSearchIcon={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <hr/>
                          {/* V20-320 */}
                          <div className='row'>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CUSTOMER_MANAGER_NAME,"Customer Manager Name")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={cmnRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Manager Name"
                                      aria-label="cmn"
                                      value={cmn}
                                      onChange={(e) => {
                                        setCMN(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CUSTOMER_MANAGER_EMAIL,"Customer Manager Email")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={cmeRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Manager Email"
                                      aria-label="Manager Email"
                                      value={cme}
                                      onChange={(e) => {
                                        setCME(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SALES_AGENT_NAME,"Sales Agent Name")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={sanRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Sales Agent Name"
                                      aria-label="SAN"
                                      value={san}
                                      onChange={(e) => {
                                        setSAN(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SALES_AGENT_EMAIL,"Sales Agent Email")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={saeRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Sales Agent Email"
                                      aria-label="sae"
                                      value={sae}
                                      onChange={(e) => {
                                        setSAE(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr/>
                        </div>
                        <div className="col-md-4 col-12" style={{display:(CommonUtil.isRootUser()?"none":"")}}>

                        </div>

                        <div style={{paddingTop:"15px"}} className="col-md-4 col-12">
                          <div className="flex">
                            <input
                                ref={oprCanada}
                                type="checkbox"
                                className="checkBox"
                                id="chkOperateCanada"
                                name="OperateCanada"
                                value="OperateCanada"
                                onChange={(e) => {
                                  onChangeOprCanada(e)
                                }}
                                checked={oprCanadaChecked ? true : false}
                            />&nbsp;
                              <label className="" htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.OPR_CANADA,"Can Operate in Canada")}</label>
                          </div>
                        </div>

                        <div style={{paddingTop:"15px"}} className="col-md-4 col-12">
                          <div className="flex">
                            <input
                                ref={oprUS}
                                type="checkbox"
                                className="checkBox"
                                id="chkOperateUS"
                                name="OperateUS"
                                value="OperateUS"
                                onChange={onChangeOprUS}
                                checked={oprUSChecked ? true : false}
                            />
                            &nbsp;
                            <label className="" htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.OPR_US,"Can Operate in US")}</label>
                          </div>
                        </div>

                        <div style={{paddingTop:"15px"}} className="col-md-4 col-12">
                          <div className="flex">
                            <input
                                ref={oprBorder}
                                type="checkbox"
                                className="checkBox"
                                id="chkOperateCrossBorder"
                                name="OperateCrossBorder"
                                value="OperateCrossBorder"
                                checked={oprBorderChecked ? true : false}
                                disabled={isBorderCheckEnable}
                                onChange={onChangeOprBorderChecked}
                            />
                            &nbsp;
                            <label className="" htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.CROSS_BORDER,"Cross Border")}</label>
                          </div>
                        </div>
                        <div style={{paddingTop:"15px",display:CommonUtil.isRootSwitchUser()?"none":"none"}} className="col-md-4 col-12">
                          <div className="flex">
                            <input
                                ref={oprInterchange}
                                type="checkbox"
                                className="checkBox"
                                id="chkInterchangeMenu"
                                name="InterchangeMenu"
                                value="InterchangeMenu"
                                checked={interchangeMenu ? true : false}
                                onChange={onChangeInterchangeMenu}
                            />
                            &nbsp;
                            <label className="" htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.ENABLE_INTERCHANGE_MENU,"Enabled Interchange Menu")}</label>
                          </div>
                        </div>
                        {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ?null:
                            (<div style={{paddingTop:"15px",display:"none"}} className="col-md-4 col-12">
                          <div className="flex">
                            <input disabled={CommonUtil.isRootUser()?false:true}

                                type="checkbox"
                                className="checkBox"
                                id="chkAnyAvailable"
                                name="AnyAvailable"
                                value="AnyAvailable"
                                checked={isAnyAvailable ? true : false}
                                onChange={onChangeAnyAvailable}
                            />
                            &nbsp;
                            <label className="" htmlFor="customCheck1">{CommonUtil.getMessageText(Constants.ANY_AVAILABLE,"Any Available")}</label>
                          </div>
                        </div>)}
                        <div style={{paddingTop:"15px"}} className="col-md-4 col-12">
                        </div>
                        <div className="col-md-12 col-12 text-left pt-4 pb-2" style={{display:CommonUtil.isRootSwitchUser()?"":"none"}}>
                          <button type="submit" className="btn btn-info" onClick={onClickShowHighwayAPI}>{CommonUtil.getMessageText(Constants.HIGHWAY_DETAILS_API,"Highway API Details")}</button>
                        </div>


                        <div className="col-md-12 col-12 text-right pt-4 pb-2">
                          <button type="button" style={{display:"none"}}  className="btn btn-outline-dark" data-dismiss="modal">{CommonUtil.getMessageText(Constants.DISCARD_CHANGES,"Discard Changes")}
                          </button>
                          &nbsp;&nbsp;
                          <button type="submit" className="btn btn-success" onClick={onClickSave}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>

                        </div>

                      </div>
                    </div>

                  </div>
                </section>
              </div>
          )}
          {finance && (
              <div className="container-fluid canvasBg" style={{background:"#FFF"}}>
                <FinanceInfo />
              </div>
          )}
          {contacts && (
              <div className="container-fluid" style={{ padding: '0px' }}>
                <Contacts />
              </div>
          )}
          {dnuInfo && (
              <div className="container-fluid" style={{ padding: '0px' }}>
                <DNUDetails />
              </div>
          )}
          {blockOrg && (
              <div className="container-fluid" style={{ padding: '0px' }}>
                <BlockedOrgs />
              </div>
          )}
          {services && (
              <div className="container-fluid">
                <OwnerServiceFree />
              </div>
          )}

      </div>


    </>
  )
}

export default CustomerProfiles;
